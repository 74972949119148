Airzone.controller('zoneRowController',
  ['$scope', '$rootScope', '$stateParams', 'zoneService', 'eventsService', '$timeout', 'SystemUtilsService',
    function ($scope, $rootScope, $stateParams, zoneService, eventsService, $timeout, SystemUtilsService) {

      // Constants
      $scope.modes = ["stop", "cool-air", "heat-radiant", "ventilate", "heat-air", "heat-both", "dehumidify", "emergency-heat", "cool-radiant", "cool-both", "not_exit", "not_exit", "auto"];
      $scope.scenes = ["stop", "confort", "unocupied", "night", "eco", "vacation"];
      $scope.velocities = ["0", "1", "2", "3"];
      $scope.sleep_modes = ['sleep-off', 'sleep30', 'sleep60', 'sleep90'];
      // Sleep for ZBS System
      if ($scope.zone.scene)
        $scope.sleep_modes = ['timer-off', 'timer30', 'timer60', 'timer90'];

      $scope.modes_matching = $scope.modes;
      $scope.consign_timeout = null;
      $scope.state_timeout = null;
      $scope.name_timeout = null;
      $scope.vla = null;
      $scope.show_vla = false;

      $scope.class_mode = getClassFromMode($scope.zone.mode);
      if ($scope.zone.scene)
        $scope.class_scene = getClassFromScene($scope.zone.scene);

      $scope.available_modes = [];
      $scope.showOptions = true;
      $scope.zone.velocity_broadcast = false;
      $scope.zone.air_flow_broadcast = false;
      $scope.class_modal_auto = false;

      // show mode and velocity flags
      $scope.show_mode = $scope.zone.master;

      $scope.show_velocity = $scope.zone.show_velocity == '1';
      $scope.show_air_flow = $scope.zone.air_flow != null;
      $scope.zone.show_auto_modes = false;

      initialize();

      function initialize() {
        if ($scope.modes && $scope.zone.modes) {
          initializeSetpoint();

          // Get system firmware
          $rootScope.firm_ws = $scope.system.firm_ws;
          if (parseFloat($rootScope.firm_ws) >= 3.0) {
            $scope.modes_matching = ["stop", "cool", "heat", "ventilate", "heat", "heat", "dehumidify", "emergency-heat", "cool", "cool", "not_exit", "not_exit", "not_exit", "auto"];
            $scope.modes = $scope.modes_matching;
            angular.forEach([0, 3, 1, 8, 9, 4, 2, 5, 6, 13, 7], function (value, index) {
              if ($scope.zone && $scope.zone.modes[value] == 1 && $scope.available_modes.indexOf($scope.modes[value]) < 0) {
                $scope.available_modes.push($scope.modes[value]);
              }
            });
            if (parseFloat($rootScope.firm_ws) >= 3.17) {
              $scope.firmws_317 = true;
            }
            if (parseFloat($rootScope.firm_ws) >= 3.33) {
              $scope.firmws_333 = true;
            }
          } else {
            $scope.zone.offset_consign = $scope.zone.consign;
            for (var i = 0; i <= $scope.modes.length; i++) {
              if ($scope.zone.modes && $scope.zone.modes[i] == 1 && i != 7) {
                $scope.available_modes.push($scope.modes[i]);
              }
            }
          }
          $scope.class_mode = $scope.modes_matching[parseInt($scope.zone.mode)];
        }
        // Demo
        if ($rootScope.demo)
          $scope.zone.updated = true;

        // Put var to hide warnings
        $scope.zone.show_error = true;
        $scope.isMobile = $rootScope.isMobile;

        // Get local ventilation flag
        if ($scope.vla != null) {
          $scope.show_vla = true;
          $scope.vla = parseInt($scope.config.vla) == 1;
        }

        // Solve bug, when eco is manual access to mobile screen of master
        // change consign and need update others zones limits and consigns
        if ($scope.zone.updated && $scope.zone.master == '1') {
          updateSlavesLimits();
        }
      }

      // Change to auto heat if ZBS system is in auto heat mode
      if ($scope.zone.auto == "1") {
        if ($scope.zone.mode == "5")
          $scope.class_modal_auto = true;
      }

      $scope.showHelp = false;
      $scope.propertySleep = false;
      $scope.propertyModeCold = false;
      $scope.propertyModeHeat = false;
      $scope.propertyAirDirectionCold = false;
      $scope.propertyAirDirectionHeat = false;
      $scope.propertyAirDirectionHorizontal = false;
      $scope.propertyAirDirectionVertical = false;

      /*
       * Initialize Setpoints for Lite and Auto Mode
       */
      function initializeSetpoint() {

        // Lite Setpoint
        if (($scope.system && $scope.system.temperature_unit == "1") || ($scope.zone.offset != null)) {
          $scope.zone.offset_consign = parseInt($scope.zone.consign);
          $scope.go_up_consign = 1;
          $scope.go_down_consign = -1;
        } else {
          $scope.zone.offset_consign = (parseFloat($scope.zone.consign)).toFixed(1);
          $scope.go_up_consign = 0.5;
          $scope.go_down_consign = -0.5;
        }

        // Auto Setpoint
        if ($scope.zone.auto == "1") {
          $scope.class_mode = 'auto';
          $scope.zone.show_auto_modes = true;

          // Cold and Heat Setpoint
          if (!$scope.class_modal_auto)
            $scope.zone.offset_consign = $scope.zone.coldConsign;
          else
            $scope.zone.offset_consign = $scope.zone.heatConsign;

          if ($scope.zone.offset != null) {
            if ($scope.system && $scope.system.temperature_unit == "1") {
              if (!$scope.class_modal_auto)
                $scope.zone.offset_consign = parseInt($scope.zone.coldConsign + ($scope.zone.cold_offset * 2));
              else
                $scope.zone.offset_consign = parseInt($scope.zone.heatConsign + ($scope.zone.heat_offset * 2));
            } else {
              if (!$scope.class_modal_auto)
                $scope.zone.offset_consign = parseFloat(parseFloat($scope.zone.coldConsign) + $scope.zone.cold_offset).toFixed(1);
              else
                $scope.zone.offset_consign = parseFloat(parseFloat($scope.zone.heatConsign) + $scope.zone.heat_offset).toFixed(1);
            }
          }
        }
      };

      /*
       * Toggle mobile warning view
       */
      $scope.toggleMobileWarning = function () {
        $scope.zone.show_error = !$scope.zone.show_error;
      };

      /*
       * Close warning text
       */
      $scope.closeWarning = function () {
        $scope.zone.show_error = false;
      };

      /*
       * Show modal with Mode Options buttons
       */
      $scope.showModeOptions = function () {
        if (!$scope.system.auto_mode_battery_temperature) {
          $scope.zone.show_VAF_supply_heat = false;
          $scope.zone.show_dehumidifier = false;
          $rootScope.mode_target = $scope.zone;
        } else {
          $rootScope.show_alerts = true;
          $rootScope.auto_mode_battery_temperature = true;
        }
      };

      /*
       * Show modal with Velocity Options buttons
       */
      $scope.showVelocityOptions = function () {
        $rootScope.velocity_target = $scope.zone;
      };

      /*
       * Show modal with AirFlow Options buttons
       */
      $scope.showAirFlowOptions = function () {
        $rootScope.air_flow_target = $scope.zone;
      };

      /*
       * Show modal with Scene Options buttons
       */
      $scope.showSceneOptions = function () {
        $rootScope.scene_target = $scope.zone;
      };

      /*
       * Show modal with Adjust Options
       */
      $scope.showModalAdjust = function () {
        $rootScope.zone_config_target = $scope.zone;
      };

      /*
       * Show modal Schedules
       */
      $scope.showModalSchedules = function () {
        $rootScope.schedules_target = $scope.zone;
      };

      /*
       * Define the warning messages
       */
      $scope.getWarningTexts = function (warnings) {
        var text_warnings = [];

        if (warnings != null) {
          if (warnings[8] == '1')
            text_warnings.push($scope.i18n('zones.warnings.presence'));
          if (warnings[9] == '1')
            text_warnings.push($scope.i18n('zones.warnings.window'));
          if (warnings[10] == '1')
            text_warnings.push($scope.i18n('zones.warnings.antifreeze'));
          if (warnings[11] == '1')
            text_warnings.push($scope.i18n('zones.warnings.thermostat'));
          // Add the missing valves
          if (warnings[12] == '1'){
            text_warnings.push($scope.i18n('adjust_zone.error') + ' v02 ' + $scope.zone.errorV02);
          }
          // Add the valves with low battery
          if (warnings[13] == '1'){
            text_warnings.push($scope.i18n('zones.warnings.low_valve_battery') + " " + $scope.zone.low_valves_battery);
          }
          // Add the valves with NTC alarm
          if (warnings[14] == '1'){
            text_warnings.push($scope.i18n('zones.warnings.valve_NTC_alarm') + " " + $scope.zone.valve_NTC_alarm);
          }
          // Add the F05-C warning
          if (warnings[15] == '1'){
            text_warnings.push($scope.i18n('zones.warnings.F05C'));
          }
          // Add the F06-C warning
          if (warnings[16] == '1'){
            text_warnings.push($scope.i18n('zones.warnings.F06C'));
          }
          // Add the F05-H warning
          if (warnings[17] == '1'){
            text_warnings.push($scope.i18n('zones.warnings.F05H'));
          }
          // Add the F06-H warning
          if (warnings[18] == '1'){
            text_warnings.push($scope.i18n('zones.warnings.F06H'));
          }
          if (warnings[19] == '1')
            text_warnings.push($scope.i18n('zones.warnings.dehumidifier_activated'));
          if (warnings[25] == '1')
            text_warnings.push($scope.i18n('zones.warnings.dew'));
          if (warnings[26] == '1')
            text_warnings.push($scope.i18n('zones.warnings.dew_protection'));
          if (warnings[28] == '1')
            text_warnings.push($scope.i18n('zones.warnings.heat_supply_activated'));

          return text_warnings;
        }
      };

      /*
       * Check if show warnings of presence, window, antifreeze, zone without thermosthat, error V02 valves missing, low valve battery, valve NTC valve, F05-H, F05-C, F06-H, F06-C, dew, dew protection and heat supply actived
       */
      $scope.hasWarning = function () {
        //el aviso de antihielo solo salta si no soy vaf, o si lo soy pero no estoy en escena vacation
        return ($scope.zone.warning != null && (($scope.zone.warning[8] == '1' || $scope.zone.warning[9] == '1' || $scope.zone.warning[11] == '1' || $scope.zone.warning[12] == '1' || $scope.zone.warning[13] == '1' || $scope.zone.warning[14] == '1' || $scope.zone.warning[15] == '1' || $scope.zone.warning[16] == '1' || $scope.zone.warning[17] == '1' || $scope.zone.warning[18] == '1' || $scope.zone.warning[19] == '1' || $scope.zone.warning[25] == '1' || $scope.zone.warning[26] == '1' || $scope.zone.warning[28] == '1') || ($scope.system.setup_type !== '6' && $scope.zone.warning[10] == '1') || ($scope.zone.warning[10] == '1' && $scope.system.setup_type === '6' && $scope.system.scene !== "5")));
      };

      /*
       * Update consign of zone (add or dedduct value)
       * Activate watch function
       */
      $scope.updateConsign = function (val) {
        var heat_mode = [2, 4, 5].indexOf(parseInt($scope.zone.mode)) > -1;
        var cool_mode = [1, 6, 8, 9].indexOf(parseInt($scope.zone.mode)) > -1;
        var new_consign, max, min;

        if ($scope.system && $scope.system.temperature_unit == "1") {
          new_consign = parseInt($scope.zone.offset_consign) + parseInt(val);
        } else {
          new_consign = (parseFloat($scope.zone.offset_consign) + parseFloat(val)).toFixed(1);
        }

        var maxMin;
        if ($scope.system.scene && $scope.zone.auto == "1") {
          maxMin = SystemUtilsService.getMaxMinSystemSetpoint($scope.system, $scope.zone, ($scope.class_modal_auto) ? true : false);
        } else {
          maxMin = SystemUtilsService.getMaxMinSystemSetpoint($scope.system, $scope.zone, (heat_mode) ? true : false);
        }

        max = maxMin.max;
        min = maxMin.min;

        if ($scope.zone.offset !== null) {
          //Lite ZBS
          if ($scope.system.scene) {
            if ($scope.system && $scope.system.temperature_unit == "1") {
              new_consign = parseInt($scope.zone.offset_consign) + parseInt(val * 2);
              $scope.zone.lower_limit = (parseInt($scope.zone.consign) - 6);
              $scope.zone.upper_limit = (parseInt($scope.zone.consign) + 6);
              if ($scope.zone.auto == "1") {
                if (!$scope.class_modal_auto) {
                  $scope.zone.lower_limit = (parseInt($scope.zone.coldConsign) - 6);
                  $scope.zone.upper_limit = (parseInt($scope.zone.coldConsign) + 6);
                } else {
                  $scope.zone.lower_limit = (parseInt($scope.zone.heatConsign) - 6);
                  $scope.zone.upper_limit = (parseInt($scope.zone.heatConsign) + 6);
                }
              }
            }
            else if ($scope.system && $scope.system.temperature_unit == "0") {
              new_consign = (parseFloat($scope.zone.offset_consign) + parseFloat(val)).toFixed(1);
              $scope.zone.lower_limit = (parseFloat($scope.zone.consign) - 3.0);
              $scope.zone.upper_limit = (parseFloat($scope.zone.consign) + 3.0);
              if ($scope.zone.auto == "1") {
                if (!$scope.class_modal_auto) {
                  $scope.zone.lower_limit = (parseInt($scope.zone.coldConsign) - 3);
                  $scope.zone.upper_limit = (parseInt($scope.zone.coldConsign) + 3);
                } else {
                  $scope.zone.lower_limit = (parseInt($scope.zone.heatConsign) - 3);
                  $scope.zone.upper_limit = (parseInt($scope.zone.heatConsign) + 3);
                }
              }
            }
          } else {
            if ($scope.zone.eco == '0' || $scope.zone.eco === null || (!cool_mode && !heat_mode)) {
              $scope.zone.lower_limit = (parseFloat($scope.zone.consign) - 3.0);
              $scope.zone.upper_limit = (parseFloat($scope.zone.consign) + 3.0);
              if (heat_mode && parseFloat($scope.zone.upper_conf_limit) < $scope.zone.upper_limit && $scope.zone.upper_conf_limit != '18.0')
                $scope.zone.upper_limit = $scope.zone.upper_conf_limit;
              if (cool_mode && parseFloat($scope.zone.lower_conf_limit) > $scope.zone.lower_limit && $scope.zone.lower_conf_limit != '27.0')
                $scope.zone.lower_limit = $scope.zone.lower_conf_limit;
            } else {
              if (heat_mode && (parseFloat($scope.zone.upper_limit) > parseFloat($scope.zone.consign) + 3.0) && ($scope.zone.lower_conf_limit != '27.0'))
                $scope.zone.upper_limit = (parseFloat($scope.zone.consign) + 3.0);
              else if (heat_mode && (parseFloat($scope.zone.upper_limit) > parseFloat($scope.zone.consign) + 2.0) && ($scope.zone.lower_conf_limit != '27.0'))
                $scope.zone.upper_limit = (parseFloat($scope.zone.consign) + 2.0);
              else if (heat_mode && (parseFloat($scope.zone.upper_limit) > parseFloat($scope.zone.consign) + 1.0) && ($scope.zone.lower_conf_limit != '27.0'))
                $scope.zone.upper_limit = (parseFloat($scope.zone.consign) + 1.0);
              else if (cool_mode && (parseFloat($scope.zone.lower_limit) < parseFloat($scope.zone.consign) - 3.0) && ($scope.zone.upper_conf_limit != '18.0'))
                $scope.zone.lower_limit = (parseFloat($scope.zone.consign) - 3.0);
              else if (cool_mode && (parseFloat($scope.zone.lower_limit) < parseFloat($scope.zone.consign) - 2.0) && ($scope.zone.upper_conf_limit != '18.0'))
                $scope.zone.lower_limit = (parseFloat($scope.zone.consign) - 2.0);
              else if (cool_mode && (parseFloat($scope.zone.lower_limit) < parseFloat($scope.zone.consign) - 1.0) && ($scope.zone.upper_conf_limit != '18.0'))
                $scope.zone.lower_limit = (parseFloat($scope.zone.consign) - 1.0);
              else if (cool_mode)
                $scope.zone.upper_limit = (parseFloat($scope.zone.consign) + 3.0);
              else if (heat_mode)
                $scope.zone.lower_limit = (parseFloat($scope.zone.consign) - 3.0);
            }
          }
        }

        if ($scope.system && $scope.system.temperature_unit == "1") {
          var zone_upper_limit = parseInt($scope.zone.upper_limit);
          var zone_lower_limit = parseInt($scope.zone.lower_limit);
        } else {
          var zone_upper_limit = parseFloat($scope.zone.upper_limit);
          var zone_lower_limit = parseFloat($scope.zone.lower_limit);
        }

        if (new_consign >= min && new_consign <= max) {
          if (!heat_mode && !cool_mode ||
            heat_mode && new_consign <= zone_upper_limit ||
            cool_mode && new_consign >= zone_lower_limit) {

            // init the timeout to update the system data
            $scope.system.initTimeoutUpdate();

            if (parseFloat($rootScope.firm_ws) >= 3.0 && ($scope.zone.offset !== null)) {
              if (new_consign >= $scope.zone.lower_limit && new_consign <= $scope.zone.upper_limit) {
                // add create events action to timeout

                if ($scope.consign_timeout) {
                  $timeout.cancel($scope.consign_timeout);
                }

                $scope.consign_timeout = $timeout(function () {
                  if ($scope.system && $scope.system.temperature_unit != "1") {
                    new_consign = (parseFloat(new_consign)).toFixed(1);
                    var zone_consign = (parseFloat($scope.zone.consign)).toFixed(1);
                  } else {
                    new_consign = parseInt(new_consign);
                    var zone_consign = parseInt($scope.zone.consign);
                  }
                  if ($scope.zone.auto == '1') {
                    if (!$scope.class_modal_auto)
                      zone_consign = parseInt($scope.zone.coldConsign);
                    else
                      zone_consign = parseInt($scope.zone.heatConsign);
                    var offset_update = new_consign - zone_consign;
                    if ($scope.system.temperature_unit == "1")
                      offset_update = parseInt(new_consign - zone_consign) / 2;

                    if (!$scope.class_modal_auto) {
                      eventsService.createEvent('modzona', $scope.zone, 'cold_offset', parseInt(offset_update));
                    } else {
                      eventsService.createEvent('modzona', $scope.zone, 'heat_offset', parseInt(offset_update));
                    }
                  } else {
                    if ($scope.system.temperature_unit == "1") {
                      var offset_update = parseInt(new_consign - zone_consign) / 2;
                      eventsService.createEvent('modzona', $scope.zone, 'offset', offset_update);
                    } else {
                      eventsService.createEvent('modzona', $scope.zone, 'offset', new_consign - zone_consign);
                    }
                  }
                  $scope.zone.reject = false;
                }, 1000);

                $scope.zone.offset_consign = new_consign;
                $scope.zone.reject = true; // block replace zone until send event
              }
            } else if (parseFloat($rootScope.firm_ws) >= 3.0 && ($scope.zone.auto == '1')) {
              if ($scope.class_modal_auto) {

                if ($scope.consign_timeout) {
                  $timeout.cancel($scope.consign_timeout);
                }

                $scope.consign_timeout = $timeout(function () {
                  eventsService.createEvent('modzona', $scope.zone, 'heat_consign', new_consign);
                  $scope.zone.reject = false;
                }, 1000);
              } else if (!$scope.class_modal_auto) {

                if ($scope.consign_timeout) {
                  $timeout.cancel($scope.consign_timeout);
                }

                $scope.consign_timeout = $timeout(function () {
                  eventsService.createEvent('modzona', $scope.zone, 'cold_consign', new_consign);
                  $scope.zone.reject = false;
                }, 1000);
              }
              $scope.zone.offset_consign = new_consign;
              $scope.zone.reject = true; // block replace zone until send event
            } else {
              if ($scope.system && $scope.system.temperature_unit == "1")
                new_consign = parseInt(new_consign);
              // add create events action to timeout

              if ($scope.consign_timeout) {
                $timeout.cancel($scope.consign_timeout);
              }

              $scope.consign_timeout = $timeout(function () {
                eventsService.createEvent('modzona', $scope.zone, 'consign', new_consign);
                $scope.zone.reject = false;
              }, 1000);
              $scope.zone.offset_consign = new_consign;
              $scope.zone.reject = true; // block replace zone until send event
            }

          }
        }
      };

      /*
       * Toggle zone status
       * Activate watch function
       */
      $scope.toggleStatus = function () {
        // init the timeout to update the system data
        $scope.system.initTimeoutUpdate();

        if ($scope.zone.state == 1) {
          $scope.zone.state = 0;
        }
        else {
          $scope.zone.state = 1;
        }

        // set the system power
        $scope.system.power = SystemUtilsService.setSystemPower($scope.system);

        if ($scope.state_timeout) {
          $timeout.cancel($scope.state_timeout);
        }

        // add create events action to timeout
        $scope.state_timeout = $timeout(function () {
          eventsService.createEvent('modzona', $scope.zone, 'state', $scope.zone.state);
          $scope.zone.reject = false;
        }, 100);

        $scope.zone.reject = true; // block replace zone until send event
      };

      /*
       * Update system power when it's changed in websockets
       */
      $scope.$watch('zone.state', function (new_val, old_val, scope) {
        if (new_val != old_val) {
          //console.log("Zone: ", $scope.zone.name);
          //console.log("Watch: ", $scope.zone.state);
          $scope.system.power = SystemUtilsService.setSystemPower($scope.system)
        }
      });

      /*
       * Transform state value to boolean
       */
      $scope.booleanState = function () {
        return $scope.zone.state == '1';
      };

      /*
       * Translate strint 't'
       * Solved bug (can't call $rootScope directly)
       */
      $scope.i18n = function (t) {
        return $rootScope.i18n(t);
      };

      /*
       * Turn off sleep
       */
      $scope.offSleep = function () {
        $scope.zone.sleep = '0';
        eventsService.createEvent('modzona', $scope.zone, 'sleep', '0');
      };

      /*
       * Update zone.master when websockets update master
       */
      $scope.$watch('zone.master', function (new_val, old_val, scope) {
        if ($scope.zone.master !== null) {
          $scope.show_mode = new_val;
        } else {
          $scope.show_mode = false;
        }
      });

      /*
       * Update offset when websockets update consign with offset
       */
      $scope.$watch('zone.offset', function (new_val, old_val, scope) {
        if ($scope.zone.offset !== null && $scope.zone.auto != "1") {
          if ($scope.system && $scope.system.temperature_unit == "1") {
            $scope.zone.offset_consign = (parseInt($scope.zone.consign) + (parseInt($scope.zone.offset) * 2));
          } else
            $scope.zone.offset_consign = (parseFloat($scope.zone.consign) + parseFloat($scope.zone.offset)).toFixed(1);
        }
      });

      /*
       * Update cold offset when websockets update consign with cold offset
       */
      $scope.$watch('zone.cold_offset', function (new_val, old_val, scope) {
        if ((new_val != old_val && $scope.zone.auto != "1") || ($scope.zone.auto == "1" && !$scope.class_modal_auto)) {
          if ($scope.system && $scope.system.temperature_unit == "1")
            $scope.zone.offset_consign = parseInt($scope.zone.coldConsign + (new_val * 2));
          else
            $scope.zone.offset_consign = (parseFloat($scope.zone.coldConsign) + new_val).toFixed(1);
        }
      });

      /*
       * Update heat offset when websockets update consign with heat offset
       */
      $scope.$watch('zone.heat_offset', function (new_val, old_val, scope) {
        if ((new_val != old_val && $scope.zone.auto != "1") || ($scope.zone.auto == "1" && $scope.class_modal_auto)) {
          if ($scope.system && $scope.system.temperature_unit == "1")
            $scope.zone.offset_consign = parseInt($scope.zone.heatConsign + (new_val * 2));
          else
            $scope.zone.offset_consign = (parseFloat($scope.zone.heatConsign) + new_val).toFixed(1);
        }
      });

      /*
       * Update heatConsign when websockets update consign with offset
       */
      $scope.$watch('zone.heatConsign', function (new_val, old_val, scope) {
        if ($scope.zone.heatConsign !== null && $scope.zone.auto == '1' && $scope.class_modal_auto) {
          if ($scope.system && $scope.system.temperature_unit == "1") {
            if ($scope.zone.offset !== null)
              $scope.zone.offset_consign = parseInt($scope.zone.heatConsign + ($scope.zone.heat_offset * 2));
            else
              $scope.zone.offset_consign = parseInt($scope.zone.heatConsign);
          } else {
            if ($scope.zone.offset !== null)
              $scope.zone.offset_consign = parseFloat(parseFloat($scope.zone.heatConsign) + $scope.zone.heat_offset).toFixed(1);
            else
              $scope.zone.offset_consign = parseFloat($scope.zone.heatConsign).toFixed(1);
          }
        }
      });

      /*
       * Update coldConsign when websockets update consign with offset
       */
      $scope.$watch('zone.coldConsign', function (new_val, old_val, scope) {
        if ($scope.zone.coldConsign !== null && $scope.zone.auto == '1' && !$scope.class_modal_auto) {
          if ($scope.system && $scope.system.temperature_unit == "1") {
            if ($scope.zone.offset !== null)
              $scope.zone.offset_consign = parseInt($scope.zone.coldConsign + ($scope.zone.cold_offset * 2));
            else
              $scope.zone.offset_consign = parseInt($scope.zone.coldConsign);
          } else {
            if ($scope.zone.offset !== null)
              $scope.zone.offset_consign = parseFloat(parseFloat($scope.zone.coldConsign) + $scope.zone.cold_offset).toFixed(1);
            else
              $scope.zone.offset_consign = parseFloat($scope.zone.coldConsign).toFixed(1);
          }
        }
      });

      /*
       * Set system auto mode
       */
      $scope.$watch('zone.auto', function (new_val, old_val, scope) {
        if (new_val != old_val) {
          if ($scope.zone.auto == "1") {
            if ($scope.zone.mode == "5")
              $scope.class_modal_auto = true;
            else
              $scope.class_modal_auto = false;
            $scope.zone.mode = "13";
          }
        }
      });

      /*
       * Create event when mode changes
       */
      $scope.$watch('zone.new_mode', function (new_val, old_val, scope) {
        if (new_val !== old_val) {
          var new_class = getClassFromMode(new_val);

          if (new_class && $scope.available_modes.indexOf(new_class.toString()) > -1) {
            // Disable old value if it is auto mode
            if ($scope.zone.auto == '1') {
              $scope.system.zones.forEach(function (zone, index) {
                zone.show_auto_modes = false;
              });
              eventsService.createEvent('modzona', $scope.zone, 'auto', 0);
            }

            $scope.class_mode = new_class;

            // Show button before to send
            if ($scope.class_mode == 'auto')
              $scope.zone.show_auto_modes = true;
            else
              $scope.zone.show_auto_modes = false;

            if ($scope.system.eco) {
              $scope.system.new_mode = new_val;
            }
            else {
              $scope.zone.mode = new_val;
              $scope.zone.updated = false;
              if (parseFloat($rootScope.firm_ws) >= 3.0) {
                if (new_val == 1)
                  new_val = 9;
                else if (new_val == 2)
                  new_val = 5;
              }

              if (new_val != 13) {
                if ($scope.mode_timeout) {
                  $timeout.cancel($scope.mode_timeout);
                }
                // Set timeout for solve error when auto = 0 arrive after to backend
                $scope.mode_timeout = setTimeout(function () {
                  eventsService.createEvent('modzona', $scope.zone, 'mode', new_val);
                }, 100);
              } else
                eventsService.createEvent('modzona', $scope.zone, 'auto', 1);
              if (!$scope.system.setup_type == '3') {
                $scope.system.new_mode = $scope.zone.mode;
              }
            }

          }

          // Ext temp values for demo
          if ($rootScope.demo) {
            if (new_val == '1' || new_val == '8' || new_val == '9')
              $scope.zone.temp = ((Math.random() * (27.5 - 24.0) + 24.0).toFixed(1)).toString();
            else if (new_val == '2' || new_val == '4' || new_val == '5')
              $scope.zone.temp = ((Math.random() * (21.9 - 17.0) + 17.0).toFixed(1)).toString();

            $scope.system.mode = new_val;
            $scope.system.new_mode = new_val;

            $scope.zone.updated = true;
            var heat_mode = [2, 4, 5].indexOf(parseInt($scope.zone.new_mode)) > -1;
            var cool_mode = [1, 6, 8, 9].indexOf(parseInt($scope.zone.new_mode)) > -1;
          }
        }
      });

      /*
       * Update class_mode when websockets update zone mode
       */
      $scope.$watch('zone.mode', function (new_val, old_val, scope) {
        // Fixed error when new_val = 0 (mode = stop)
        if (new_val != old_val)
          $scope.class_mode = getClassFromMode($scope.zone.mode);

        if ($scope.zone.auto == '1') {
          $scope.zone.show_auto_modes = true;
          if ($scope.zone.mode == "5")
            $scope.class_modal_auto = true;
          else if ($scope.zone.mode == "9")
            $scope.class_modal_auto = false;
        } else
          $scope.zone.show_auto_modes = false;
      });

      /*
       * Update show_velocity when websockets update zone show_velocity
       */
      $scope.$watch('zone.show_velocity', function (new_val, old_val, scope) {
        if (new_val && new_val !== old_val) {
          $scope.show_velocity = new_val;
        }
      });

      /*
       * Update show_air_flow when websockets update zone show_air_flow
       */
      $scope.$watch('zone.new_air_flow', function (new_val, old_val, scope) {
        if (new_val && new_val !== old_val) {
          $scope.new_air_flow = new_val;
        }
      });

      /*
       * Update scene when websockets update zone scene
       */
      $scope.$watch('zone.scene', function (new_val, old_val, scope) {
        if (new_val && new_val !== old_val) {
          $scope.class_scene = getClassFromScene(new_val);
        }
      });

      /*
       * Create event when velocity changes
       */
      $scope.$watch('zone.new_velocity', function (new_val, old_val) {
        if (new_val && new_val !== old_val) {
          $scope.class_vel = new_val;
          $scope.zone.velocity = new_val;
          if (!$scope.zone.velocity_broadcast)
            eventsService.createEvent('modzona', $scope.zone, 'velocity', new_val);
          $scope.zone.velocity_broadcast = false;
        }
      });

      /*
       * Create event when air_flow changes
       */
      $scope.$watch('zone.new_air_flow', function (new_val, old_val) {
        if (new_val && new_val !== old_val) {
          $scope.class_vel = new_val;
          $scope.zone.air_flow = new_val;
          if (!$scope.zone.air_flow_broadcast)
            eventsService.createEvent('modzona', $scope.zone, 'air_flow', new_val);
          $scope.zone.air_flow_broadcast = false;
        }
      });

      /*
       * Create event when scene changes
       */
      $scope.$watch('zone.new_scene', function (new_val, old_val) {
        if (new_val && new_val !== old_val) {
          $scope.class_scene = new_val;
          $scope.system.scene = $scope.scenes.indexOf(new_val);
          eventsService.createEvent('modsistema', $scope.zone, 'scene', $scope.scenes.indexOf(new_val));
        }
      });

      /*
       * Update consign when websockets update zone consign
       */
      $scope.$watch('zone.consign', function (new_val, old_val, scope) {
        if ($scope.zone.offset !== null) {
          if ($scope.system && $scope.system.temperature_unit == "1")
            new_val = parseInt($scope.zone.consign) + (parseInt($scope.zone.offset) * 2);
          else
            new_val = (parseFloat($scope.zone.consign) + parseInt($scope.zone.offset)).toFixed(1);
        }

        if (new_val != old_val && new_val && $scope.system && ($scope.system.eco || $scope.system.setup_type == '3' || ($scope.system.setup_type === '6' && $scope.zone.auto !== "1"))) {
          var heat_mode = [2, 4, 5].indexOf(parseInt($scope.system.mode)) > -1;
          var cool_mode = [1, 6, 8, 9].indexOf(parseInt($scope.system.mode)) > -1;
          $scope.zone.offset_consign = parseFloat(new_val).toFixed(1);

          if ($rootScope.update_system_consign === true) {
            $scope.system.zones.forEach(function (value) {
              if (value.master == "1") {
                $scope.system.server_consign = value.consign;
                $rootScope.update_for_limits = true;
              }
            });
            $rootScope.update_system_consign = false;
          }

          if ($scope.system && $scope.system.temperature_unit == "1")
            $scope.zone.offset_consign = parseInt(new_val);

          // Check if system need to update its consign
          if (heat_mode && $scope.system.consign > $scope.zone.upper_limit ||
            cool_mode && $scope.system.consign < $scope.zone.lower_limit) {
            $scope.zone.offset_consign = parseFloat(new_val).toFixed(1);
            if ($scope.system && $scope.system.temperature_unit == "1")
              $scope.zone.offset_consign = parseInt(new_val);
          }

          // Update slaves limits
          if ($scope.zone.master == '1') {
            updateSlavesLimits();
          }
        };
      });

      /*
       * Update offset_consign for demo when update zone offset_consign with eco
       */
      $scope.$watch('zone.offset_consign', function (new_val, old_val, scope) {
        // Update slaves limits for demo
        if ($scope.zone.master == '1' && $rootScope.demo) {
          updateSlavesLimits();
        }
      });

      /*
       * Return class for a mode (integer)
       */
      function getClassFromMode(mode) {
        return $scope.modes[parseInt(mode)];
      }

      /*
       * Return class for a scene (integer)
       */
      function getClassFromScene(scene) {
        return $scope.scenes[parseInt(scene)];
      }

      /*
       * Update other zones of system limits when eco is manual
       * Master consign is the limit of slaves zones
       */
      function updateSlavesLimits() {
        if (parseInt($scope.system.eco) == 1) {
          $scope.system.zones.forEach(function (zone) {
            if (zone.master != '1') {
              var heat_mode = [2, 4, 5].indexOf(parseInt($scope.system.mode)) > -1;
              var cool_mode = [1, 6, 8, 9].indexOf(parseInt($scope.system.mode)) > -1;

              if (heat_mode) {
                zone.upper_limit = $scope.zone.offset_consign;

                if (zone.offset_consign > $scope.zone.offset_consign)
                  zone.offset_consign = $scope.zone.offset_consign;
              }
              else if (cool_mode) {
                zone.lower_limit = $scope.zone.offset_consign;

                if (zone.offset_consign < $scope.zone.offset_consign)
                  zone.offset_consign = $scope.zone.offset_consign;
              }
            }
          });
        }
      }

      /*
       * Get the ext temp
       */
      $scope.getTemp = function () {
        if ($scope.zone && $scope.zone.temp) {
          if ($scope.system && $scope.system.temperature_unit == "1") {
            if (parseInt($scope.zone.temp) <= 50)
              return "< 50";
            else if (parseInt($scope.zone.temp) >= 95)
              return "> 95";
            else
              return parseInt($scope.zone.temp);
          } else {
            if (parseFloat($scope.zone.temp) <= 10)
              return "< 10";
            else if (parseFloat($scope.zone.temp) >= 34.9)
              return "> 35";
            else
              return $scope.zone.temp;
          }
        }
      };

      /*
       * Get the humidity
       */
      $scope.getHumidity = function () {
        if ($scope.zone && $scope.zone.humidity)
          return $scope.zone.humidity;
      }

      /*
       * Turn auto mode
       */
      $scope.changeModeState = function () {
        $scope.class_modal_auto = !$scope.class_modal_auto;
        console.log($scope.class_modal_auto);
        $scope.zone.updated = false;
        $scope.consign_timeout = $timeout(function () {
          initializeSetpoint();
          $scope.zone.updated = true;
        }, 1000);
      };

      $scope.getIconVelocity = function () {
        if ($scope.zone.velMax !== null && $scope.zone.velocity !== '0') {
          //iconos nuevos si es mlp y no es auto
          return "icon-vel-mlp-" + $scope.zone.velocity + " btn-large";
        } else {
          return ("icon-velocity" + $scope.zone.velocity);
        }

      };

      /*
       * Check auto mode for show icon
       */
      $scope.checkAutoIcon = function () {
        if ($scope.zone.auto == "1") {
          if ($scope.zone.mode == "0" || $scope.zone.mode == "13") {
            $scope.class_mode = "auto";
            return $scope.class_mode;
          } else {
            $scope.class_mode = getClassFromMode($scope.zone.mode);
            return $scope.class_mode + "-air";
          }
        } else
          return $scope.class_mode;
      };

      /*
       * Update vla zone when it's changed in websockets or zone adjust (infouser)
       */
      $scope.$watch('zone.vla', function (new_val, old_val, scope) {
        if (new_val != old_val) {
          console.log("Zone vla: ", $scope.zone.vla);
          //console.log("Watch: ", $scope.zone.state);
          $scope.zone.val = new_val;
        }
      });

      /*
       * Change value of vla and create event
       */
      $scope.turnOnOffVentilation = function () {
        if ($scope.zone.vla === '0')
          $scope.zone.vla = "1";
        else
          $scope.zone.vla = "0";

        eventsService.createEvent('moduser', $scope.zone, 'vla', $scope.zone.vla);
      };

      /*
       * Show the VAF supply heat
       */
      $scope.showSupplyHeat = function () {
        $scope.zone.show_VAF_supply_heat = true;
        $rootScope.mode_target = $scope.zone;
      };

      /*
       * Show the dehumidifier
       */
      $scope.showDehumidifier = function () {
        $scope.zone.show_dehumidifier = true;
        $rootScope.mode_target = $scope.zone;
      };
    }]);
