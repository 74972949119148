Airzone.controller('systemRowController',
  ['$scope', '$rootScope', '$stateParams', 'systemService', 'zoneService', 'eventsService', 'deviceService', '$timeout', '$state', '$window', 'SystemUtilsService', 'DEFAULT_CONSIGN_SERVER',
    function ($scope, $rootScope, $stateParams, systemService, zoneService, eventsService, deviceService, $timeout, $state, $window, SystemUtilsService, DEFAULT_CONSIGN_SERVER) {

      var checkFocus = function () {
        $window.onblur = function () {
          if (!$scope.sys.updated && navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/))
            $state.go('home.servers');
        };
      };

      //$scope.intervalFocus = setInterval( checkFocus, 5000);

      // Constants
      if (parseFloat($scope.sys.firm_ws) >= 3.0)
        $scope.modes = ["stop", "cool", "heat", "ventilate", "heat", "heat", "dehumidify", "emergency-heat", "cool", "cool", "not_exit", "not_exit", "not_exit", "auto"];
      else
        $scope.modes = ["stop", "cool-air", "heat-radiant", "ventilate", "heat-air", "heat-both", "dehumidify", "not_exit", "cool-radiant", "cool-both"];
      $scope.scenes = ["stop", "confort", "unocupied", "night", "eco", "vacation"];
      $scope.ecos = ["eco-off", "eco-m", "eco-a", "eco-aa", "eco-aaa"];
      $scope.velocities = ["0", "1", "2", "3"];
      $scope.air_flows = ["0", "1", "2"];

      $scope.zones = [];
      $scope.expand = false;

      // show mode and velocity flags
      $scope.show_mode = ($scope.sys.mode !== null);
      $scope.show_velocity = $scope.sys.has_velocity;
      $scope.show_air_flow = $scope.sys.has_air_flow;
      $scope.show_eco = ($scope.sys.eco != null);
      $scope.show_scene = ($scope.sys.scene != null);
      $scope.show_consumption = ($scope.sys.connMC != null) && ($scope.sys.setup_type !== "4");
      $scope.class_mode = getClassFromMode($scope.sys.mode);
      $scope.class_eco = getClassFromEco($scope.sys.eco);
      $scope.class_scene = getClassFromScene($scope.sys.scene);
      $scope.class_vel = $scope.sys.velocity;
      $scope.consign_timeout = null;
      $scope.available_modes = [];
      $scope.polling_time = 30000;
      $scope.is_ACS_warning = false;

      $scope.DEFAULT_CONSIGN_SERVER = DEFAULT_CONSIGN_SERVER;

      initialize();

      function initialize() {
        $scope.relation = $rootScope.relation;
        $scope.device = $rootScope.server;

        if ($scope.device)
          $rootScope.system_country_code = $scope.device.country_code;
        else if (!$rootScope.system_country_code)
          $rootScope.system_country_code = 'ES';

        if ($scope.device && $scope.device.firm_ws && parseFloat($scope.device.firm_ws) >= 3.08)
          $scope.polling_time = 15000;

        // Check if system error is ACS warning
        check_acs_warning();

        zoneService.getZones($scope.sys.id).then(function (zones) {
          $scope.zones = zones;
          $scope.sys.zones = zones;
          $scope.expand = ($scope.ind == 0);
          // set initial the system power and consign
          $scope.sys.power = SystemUtilsService.setSystemPower($scope.sys);
          $scope.sys.consign = DEFAULT_CONSIGN_SERVER;

          // Set limits for zones when go back from zone mobile screen
          // Solve bug when change mode in zone mobile screen
          if ($scope.sys.updated) {
            updateZonesLimits();
          }
        });

        // Filter modes and returns only system modes
        for (var i = 0; i <= $scope.modes.length; i++) {
          if ($scope.sys.modes && $scope.sys.modes[i] == 1 && i != 7)
            $scope.available_modes.push($scope.modes[i]);
        }

        // Demo
        if ($rootScope.demo)
          $scope.sys.updated = true;
      }

      // Return if WS has firmare equal o greater than 3.20
      $scope.firm_ws_greater_equal_320 = function () {
        return ($scope.device && $scope.device.firm_ws && parseFloat($scope.device.firm_ws) >= 3.20)
      }

      /*
       * Show modal with Mode Options buttons
       */
      $scope.showModeOptionsRow = function () {
        if ($scope.checkZonesPermissions()) {
          if (!$scope.sys.auto_mode_battery_temperature) {
            $scope.sys.show_VAF_supply_heat = false;
            $scope.sys.show_dehumidifier = false;
            $rootScope.mode_target = $scope.sys;
          } else {
            $rootScope.show_alerts = true;
            $rootScope.auto_mode_battery_temperature = true;
          }
        }
      };

      /*
       * Show modal with Eco Options buttons
       */
      $scope.showEcoOptionsRow = function () {
        if ($scope.checkZonesPermissions()) {
          $rootScope.eco_target = $scope.sys;
        }
      };

      /*
       * Show modal with Scene Options buttons
       */
      $scope.showSceneOptionsRow = function () {
        if ($scope.checkZonesPermissions()) {
          $rootScope.scene_target = $scope.sys;
        }
      };

      /*
       * Show modal with Velocity Options buttons
       */
      $scope.showVelocityOptionsRow = function () {
        if ($scope.checkZonesPermissions() && $scope.sys.has_velocity) {
          if ($scope.sys.velMax === undefined) {
            $scope.sys.velMax = null;
          }
          $rootScope.velocity_target = $scope.sys;
        }
      };

      /*
       * Show modal with the system errors
       */
      $scope.showSystemErrors = function () {
        $rootScope.system_errors_target = $scope.sys;
      }

      $scope.getServerSetpoint = function () {
        // Initialize
        if (!$scope.sys.consign)
          $scope.sys.consign = DEFAULT_CONSIGN_SERVER;

        if ($scope.sys.consign === DEFAULT_CONSIGN_SERVER) {
          return DEFAULT_CONSIGN_SERVER;
        } else {
          return $scope.sys.consign + 'º';
        }
      }

      /*
       * Return icon of velocity
       */
      $scope.getIconVelocity = function () {
        if ($scope.class_vel !== null) {
          return "icon-velocity" + $scope.class_vel;
        } else if ($scope.sys.has_velocity) {
          // TODO: poner icono genérico -> 4 tubos
          return "icon-speed-generic";
        } else {
          //no hay aire
          return "icon-velocity0";
        }
      }

      $scope.getModeIcon = function () {
        if ($scope.show_mode) {
          //muestro el modo
          return "icon-" + $scope.checkAutoIcon();
        } else {
          //4 tubos -> modo generico
          return "icon-mode-generic";
        }
      }

      /*
       * Show modal with AirFlow Options buttons
       */
      $scope.showAirFlowOptionsRow = function () {
        if ($scope.checkZonesPermissions()) {
          $rootScope.air_flow_target = $scope.sys;
        }
      };

      $scope.i18n = function (t) {
        return $rootScope.i18n(t);
      };


      /*
       * Update consign of zone (add or dedduct system setpoint)
       */
      $scope.updateConsign = function (val) {
        var heat_mode = [2, 4, 5].indexOf(parseInt($scope.sys.mode)) > -1;
        var cool_mode = [1, 6, 8, 9].indexOf(parseInt($scope.sys.mode)) > -1;

        if ($scope.sys && $scope.sys.temperature_unit == "1") {
          if (val == 0.5)
            val = 1;
          else if (val == -0.5)
            val = -1;

          if ($scope.sys.consign === DEFAULT_CONSIGN_SERVER) {
            if ($scope.sys.eco) {
              if (heat_mode) {
                $scope.sys.consign = $scope.zones[0].upper_conf_limit;
              } else {
                $scope.sys.consign = $scope.zones[0].lower_conf_limit;
              }
            } else {
              $scope.sys.consign = '73';
            }
          }

          var new_consign = parseInt($scope.sys.consign) + val;
        } else {
          if ($scope.sys.consign === DEFAULT_CONSIGN_SERVER) {
            if ($scope.sys.eco) {
              if (heat_mode) {
                $scope.sys.consign = $scope.zones[0].upper_conf_limit;
              } else {
                $scope.sys.consign = $scope.zones[0].lower_conf_limit;
              }
            } else {
              $scope.sys.consign = '23.0';
            }
          }

          // Check if eco mode is activated, to fix out the range server consign when it changes the mode  
          if ($scope.sys.eco !== "0" && $scope.sys.eco !== "1") {
            if ((((parseFloat($scope.sys.consign) + parseFloat(val)).toFixed(1)) >= parseFloat($scope.zones[0].lower_limit)) && (((parseFloat($scope.sys.consign) + parseFloat(val)).toFixed(1)) < parseFloat($scope.zones[0].upper_limit))) {
              new_consign = (parseFloat($scope.sys.consign) + parseFloat(val)).toFixed(1);
            } else {
              if (val > 0) {
                new_consign = parseFloat($scope.zones[0].upper_limit).toFixed(1);
              } else {
                new_consign = parseFloat($scope.zones[0].lower_limit).toFixed(1);
              }
            }
          } else {
            new_consign = (parseFloat($scope.sys.consign) + parseFloat(val)).toFixed(1);
          }
        }



        var maxMin = SystemUtilsService.getMaxMinSystemSetpoint($scope.sys, null, (heat_mode) ? true : false);

        var max = maxMin.max;
        var min = maxMin.min;


        if (new_consign >= min && new_consign <= max) {

          //si soy zbs, lal comprobacion la he hecho ya antes
          if ($scope.sys.setup_type === "6" || !$scope.sys.eco ||
            !heat_mode && !cool_mode ||
            heat_mode && new_consign <= $scope.zones[0].upper_limit ||
            cool_mode && new_consign >= $scope.zones[0].lower_limit) {

            $scope.sys.consign = new_consign;

            if (!$rootScope.update_for_limits) {
              if ($scope.consign_timeout) {
                $timeout.cancel($scope.consign_timeout);
              }

              $scope.consign_timeout = $timeout(function () {
                var info = {
                  device_id: $scope.sys.device_id,
                  system_number: $scope.sys.system_number,
                  zone_number: 127
                };

                eventsService.createEvent('modzona', info, 'consign', $scope.sys.consign);
              }, 1000);

              $scope.zones.forEach(function (zone) {
                zone.offset_consign = $scope.sys.consign;
              });
            } else {
              $rootScope.update_for_limits = false;
            }
          }
        }
      };

      /*
       * Watch when loader change.
       */
      $scope.listener0 = $scope.$watch('sys.updated', function (new_val, old_val, scope) {
        if (new_val != old_val && new_val) {
          updateZonesLimits();
        }
      });

      /*
       * Watch when server consign is changed
       */
      $scope.listener1 = $scope.$watch('sys.server_consign', function (new_val, old_val, scope) {
        if (new_val != old_val && new_val != $scope.sys.consign) {
          $scope.updateConsign(new_val - $scope.sys.consign);
        }
      });

      /*
       * Turn off the system and all its zones
       */
      $scope.toggleSystemStatus = function () {
        $scope.sys.power = !$scope.sys.power;
        $scope.sys.last_field = 'power';
        $scope.sys.last_value = $scope.sys.power;
        var val;
        if ($scope.sys.power)
          val = '1';
        else
          val = '0';

        if (!$rootScope.demo) {
          var info = {
            device_id: $scope.sys.device_id,
            system_number: $scope.sys.system_number,
            zone_number: 127
          };

          $scope.zones.forEach(function (zone) {
            if (zone.status !== val) {
              zoneService.updateZone(zone.id, 'state', val);
              zone.state = val;
            }
          });

          eventsService.createEvent('modzona', info, 'state', val);
        } else {
          $scope.zones.forEach(function (zone) {
            zone.state = val;
          });
        };
      }

      /*
       * Watch when server power is changed
       */
      $scope.listener2 = $scope.$watch('sys.server_power', function (new_val, old_val, scope) {
        if (new_val != old_val && new_val != $scope.sys.power) {
          $scope.toggleSystemStatus();
        }
      });

      /*
       * Set system mode icon
       */
      $scope.listener3 = $scope.$watch('sys.mode', function (new_val, old_val, scope) {
        if (new_val !== old_val) {
          $scope.class_mode = getClassFromMode(new_val);
          $scope.sys.consign = DEFAULT_CONSIGN_SERVER;
          /*var heat_mode = [2,4,5].indexOf( parseInt($scope.sys.mode) ) > -1;
          var maxMin = SystemUtilsService.getMaxMinSystemSetpoint($scope.sys, null, (heat_mode)? true : false);
          var min = maxMin.min;
    
          if($scope.sys.consign < min){
            $scope.sys.consign = min;
          }else if(!heat_mode && $scope.sys.consign < parseFloat($scope.sys.min_limit)){
            $scope.sys.consign = $scope.sys.min_limit;
          }else if (heat_mode && $scope.sys.consign > parseFloat($scope.sys.max_limit)){
            $scope.sys.consign = $scope.sys.max_limit;
          }*/
        }
      });

      /*
       * Update system mode (send event)
       */
      $scope.listener4 = $scope.$watch('sys.new_mode', function (new_val, old_val) {
        // Fixed error when new_val = 0 (mode = stop) parsing to string
        if (new_val !== old_val && $scope.sys.modes[new_val] == '1') {
          if (parseFloat($scope.sys.firm_ws) >= 3.0) {
            if (new_val == 1) {
              new_val = 9;
              $scope.sys.new_mode = 9;
            }
            else if (new_val == 2) {
              new_val = 5;
              $scope.sys.new_mode = 5;
            }
          }
          $scope.sys.mode = new_val;
          $scope.sys.last_field = 'mode';
          $scope.sys.last_value = new_val;
          $scope.sys.updated = false;
          $rootScope.update_system_consign = true;

          // Create broadcast event
          var info = {
            device_id: $scope.sys.device_id,
            system_number: $scope.sys.system_number,
            zone_number: 127
          };

          // Check if system has eco (4 tubes don't have eco)
          if ($scope.sys.setup_type != "6") {
            eventsService.createEvent('modsistema', $scope.sys, 'mode', $scope.sys.new_mode);
          }
          else {
            // 4 tubes, need infosistema for get zones info
            if (new_val != 13) {
              eventsService.createParamsEvent('modsistema', $scope.sys, 'auto=0&mode=' + String($scope.sys.new_mode));
              eventsService.createEvent('modzona', info, 'mode', $scope.sys.new_mode);
              $scope.zones.forEach(function (zone) {
                zone.auto = "0";
              });
            } else
                eventsService.createEvent('modsistema', $scope.sys, 'auto', 1);

            if (!($scope.device && $scope.device.firm_ws && parseFloat($scope.device.firm_ws) >= 3.08))
              eventsService.createEvent('infosistema', $scope.sys, null, null);
          }

          $scope.zones.forEach(function (zone) {
            zone.updated = false;
            zone.mode = new_val;
          });

          for (var i = 0; i < $scope.zones.length; i++) {
            if ($scope.zones[i].master) {
              $scope.zones[i].mode = $scope.sys.mode;
            }
          }

          // Supermaster
          if ($scope.sys.master_setup) {
            $scope.server.systems.forEach(function (system) {
              if (system.id != $scope.sys.id) {
                // If 4 pipes system then change the mode of master zones
                if (system.setup_type == "3") {
                  system.zones.forEach(function (zone) {
                    if (zone.master) {
                      zone.new_mode = $scope.sys.mode;
                    }
                  });
                  $timeout(function () {
                    eventsService.createEvent('infosistema', system, null, null);
                  }, 500);
                } else {
                  system.new_mode = $scope.sys.mode;
                }
              }
            });
          }

          // Demo
          if ($rootScope.demo) {
            $scope.zones.forEach(function (zone) {
              if (new_val == '1' || new_val == '8' || new_val == '9')
                zone.temp = ((Math.random() * (27.5 - 24.0) + 24.0).toFixed(1)).toString();
              else if (new_val == '2' || new_val == '4' || new_val == '5')
                zone.temp = ((Math.random() * (21.9 - 17.0) + 17.0).toFixed(1)).toString();
            });

            $timeout(function () {
              $scope.sys.updated = true;
            }, 500);

            // Mark zones as updated
            $scope.zones.forEach(function (zone) {
              zone.updated = false;

              $timeout(function () {
                zone.new_mode = $scope.sys.new_mode;
                zone.mode = $scope.sys.new_mode;
                zone.updated = true;
                $scope.normalizeValuesConsignDemo(zone, $scope.sys.eco);
              }, 500);
            });
          }
        }
      });

      /*
       * Set system auto mode
       */
      $scope.listener15 = $scope.$watch('sys.auto', function (new_val, old_val, scope) {
        if (new_val != old_val) {
          if ($scope.sys.auto == "1")
            $scope.sys.new_mode = 13;
        }
      });

      /*
       * Set system eco icon
       */
      $scope.listener5 = $scope.$watch('sys.eco', function (new_val, old_val, scope) {
        if (new_val != old_val) {
          $scope.class_eco = getClassFromEco(new_val);
        }
      });

      /*
       * Update system eco (send event)
       */
      $scope.listener6 = $scope.$watch('sys.new_eco', function (new_val, old_val, scope) {
        if (new_val !== old_val && new_val !== false && new_val != null) {
          $scope.sys.eco = new_val;
          $scope.sys.last_field = 'eco';
          $scope.sys.last_value = new_val;
          $scope.sys.updated = false;

          eventsService.createEvent('modsistema', $scope.sys, 'eco', new_val);
          eventsService.createEvent('infosistema2', $scope.sys, null, null);
          $rootScope.update_system_consign = true;

          if (!$rootScope.demo) {
            $scope.zones.forEach(function (zone) {
              zone.updated = false;
            });
          }
          else {
            $timeout(function () {
              $scope.sys.updated = true;
              $scope.changeColorEcoDemo(scope.sys, new_val);
            }, 500);

            // Mark zones as updated
            $scope.zones.forEach(function (zone) {
              zone.updated = false;
              zone.last_field = null;
              zone.last_value = null;

              $scope.normalizeValuesConsignDemo(zone, new_val);

              $timeout(function () {
                zone.new_eco = new_val;
                zone.updated = true;
              }, 1000);
            });
          }
        }
      });

      /*
       * Change the color of ECO in demo
       */
      $scope.changeColorEcoDemo = function (sys, val) {
        switch (val) {
          case 0:
            sys.eco_color = '2';
            break;
          case 1:
            sys.eco_color = '3';
            break;
          case 2:
            sys.eco_color = '4';
            break;
          case 3:
            sys.eco_color = '4';
            break;
          case 4:
            sys.eco_color = '4';
            break;
        }
      };

      /*
       * Normalize values consign eco demo
       */
      $scope.normalizeValuesConsignDemo = function (zone, new_eco) {
        var heat_mode = [2, 4, 5].indexOf(parseInt(zone.mode)) > -1;
        var cool_mode = [1, 6, 8, 9].indexOf(parseInt(zone.mode)) > -1;
        var limits = getLimits();

        // Update consigns
        if (new_eco == '2' || new_eco == '3' || new_eco == '4') {
          if (heat_mode)
            zone.consign = '20.0';
          else if (cool_mode)
            zone.consign = '27.0';
        }

        // Update limits
        if (new_eco == '1' && zone.master != '1') {
          var master_zone = $scope.sys.zones.filter(function (z) { return z.master == '1'; })[0];

          if (heat_mode)
            zone.upper_limit = master_zone.consign;
          else if (cool_mode)
            zone.lower_limit = master_zone.consign;
        }
        else {
          zone.lower_limit = limits.lower_limit;
          zone.upper_limit = limits.upper_limit;
        }

        if (heat_mode) {
          $scope.server.consign = '20.0';
          $scope.sys.consign = '20.0';

          if (zone.consign > zone.upper_limit)
            zone.consign = zone.upper_limit.toFixed(1);
        }
        else if (cool_mode) {
          $scope.server.consign = '27.0';
          $scope.sys.consign = '27.0';

          if (zone.consign < zone.lower_limit)
            zone.consign = zone.lower_limit.toFixed(1);
        }
      };

      /*
       * Create event when velocity changes
       */
      $scope.listener7 = $scope.$watch('sys.velocity', function (new_val, old_val, scope) {
        if (new_val !== old_val) {
          $scope.class_vel = new_val;
          if ($scope.sys.setup_type == '3')
            $scope.sys.new_velocity = null;
        }
      });

      /*
       * Create event when air_flow changes
       */
      $scope.listener16 = $scope.$watch('sys.air_flow', function (new_val, old_val, scope) {
        if (new_val !== old_val) {
          $scope.class_vel = new_val;
          if ($scope.sys.setup_type != '4')
            $scope.sys.new_air_flow = null;
        }
      });

      /*
       * Update system eco (send event)
       */
      $scope.listener8 = $scope.$watch('sys.new_velocity', function (new_val, old_val, scope) {
        if (new_val !== old_val && new_val !== false && new_val != null) {
          $scope.sys.velocity = new_val;
          $scope.sys.last_field = 'velocity';
          $scope.sys.last_value = new_val;

          // 4 tubos
          if (!$scope.sys.has_velocity) {
            var info = {
              device_id: $scope.sys.device_id,
              system_number: $scope.sys.system_number,
              zone_number: 127
            };

            eventsService.createEvent('modzona', info, 'velocity', new_val);
            if (!($scope.device && $scope.device.firm_ws && parseFloat($scope.device.firm_ws) >= 3.08))
              eventsService.createEvent('infosistema', $scope.sys, null, null);

            $scope.sys.reject = true;
            $scope.zones.forEach(function (zone) {
              zone.updated = false;
              zone.new_velocity = new_val;
              zone.velocity_broadcast = true;
            });
          }
          else {
            $scope.sys.updated = false;
            eventsService.createEvent('modsistema', $scope.sys, 'velocity', new_val);

            $scope.zones.forEach(function (zone) {
              if (!zone.show_velocity || zone.master) {
                zone.updated = false;
              }
            });
          }

          // Demo
          if ($rootScope.demo) {
            $timeout(function () {
              $scope.sys.updated = true;
            }, 500);

            // Mark zones as updated
            $scope.zones.forEach(function (zone) {
              zone.updated = false;

              $timeout(function () {
                zone.new_velocity = new_val;
                zone.updated = true;
              }, 1000);
            });
          }
        }
      });

      /*
       * Update system eco (send event)
       */
      $scope.listener12 = $scope.$watch('sys.new_air_flow', function (new_val, old_val, scope) {
        if (new_val !== old_val && new_val !== false && new_val != null) {
          $scope.sys.air_flow = new_val;
          $scope.sys.last_field = 'air_flow';
          $scope.sys.last_value = new_val;

          // 4 tubos
          if (!$scope.sys.has_air_flow) {
            var info = {
              device_id: $scope.sys.device_id,
              system_number: $scope.sys.system_number,
              zone_number: 127
            };

            eventsService.createEvent('modzona', info, 'air_flow', new_val);
            if (!($scope.device && $scope.device.firm_ws && parseFloat($scope.device.firm_ws) >= 3.08))
              eventsService.createEvent('infosistema', $scope.sys, null, null);

            $scope.sys.reject = true;
            $scope.zones.forEach(function (zone) {
              zone.updated = false;
              zone.new_air_flow = new_val;
              zone.air_flow_broadcast = true;
            });
          }
          else {
            $scope.sys.updated = false;
            eventsService.createEvent('modsistema', $scope.sys, 'air_flow', new_val);

            $scope.zones.forEach(function (zone) {
              if (!zone.show_air_flow || zone.master) {
                zone.updated = false;
              }
            });
          }

          // Demo
          if ($rootScope.demo) {
            $timeout(function () {
              $scope.sys.updated = true;
            }, 500);

            // Mark zones as updated
            $scope.zones.forEach(function (zone) {
              zone.updated = false;

              $timeout(function () {
                zone.new_air_flow = new_val;
                zone.updated = true;
              }, 1000);
            });
          }
        }
      });

      /*
       * Set system scene icon
       */
      $scope.listener13 = $scope.$watch('sys.scene', function (new_val, old_val, scope) {
        if (new_val != old_val) {
          $scope.class_scene = getClassFromScene(new_val);
        }
      });

      /*
       * Update system scene (send event)
       */
      $scope.listener14 = $scope.$watch('sys.new_scene', function (new_val, old_val, scope) {
        if (new_val !== old_val && new_val !== false && new_val != null) {
          $scope.sys.scene = $scope.scenes.indexOf(new_val);
          $scope.sys.updated = false;

          $scope.zones.forEach(function (zone) {
            zone.updated = false;
            $timeout(function () {
              if (zone.master)
                zone.new_scene = new_val;
              zone.updated = true;
            }, 1000);
          });
          $scope.sys.updated = true;
        }
      });

      /*
       * Open config system modal
       */
      $scope.showConfigModal = function () {
        $rootScope.zone_config_target = $scope.sys;
        $rootScope.zone_config_target.prog = false;
      };

      /*
       * Open config system modal Schedules
       */
      $scope.showSchedulesModal = function () {
        if ($scope.sys.scene) {
          $rootScope.zbs_schedules_target = $scope.sys;
        } else {
          $rootScope.schedules_target = $scope.sys;
        }
      };

      /*
       * Stop update zone when modal is open
       * Return update when modal is closed
       *
      $scope.listener9 = $rootScope.$watch('modalShow', function(new_val, old_val, scope) {
        if( new_val && !old_val ) {
          $scope.zones.forEach(function(zone) {
            zone.reject = true;
          });
    
          clearTimeout($scope.update_timeout);
        }
        else if( !new_val && old_val ) {
          $scope.zones.forEach(function(zone) {
            zone.reject = false;
          });
    
          if( $scope.expand ) {
            timeoutUpdate();
          }
        }
      });*/

      /*
       * Watch when server expand is changed
       */
      $scope.listener10 = $scope.$watch('expand', function (new_val, old_val, scope) {
        if (new_val != old_val) {
          if (new_val) {
            //if( !$scope.sys.updated )
            eventsService.createEvent('infosistema2', $scope.sys, null, null);

            timeoutUpdate();
          }
          else {
            clearTimeout($scope.update_timeout);
          }
        }
      });

      /*
       * Check if user have permission to manage all zones of a system
       */
      $scope.checkZonesPermissions = function () {
        var permitted_zones = $scope.relation.permitted_zones;
        var system_zones_ids = $scope.sys.zones_ids;

        return ($scope.relation.type == 'advanced' || arraysEqual(system_zones_ids, permitted_zones));
      };

      /*
       * Watch when server power is changed
       */
      $scope.listener11 = $scope.$watch('sys.reload', function (new_val, old_val, scope) {
        if (new_val != old_val && new_val) {
          $scope.sys.reload = false;

          if ($scope.expand) {
            clearTimeout($scope.update_timeout);
            timeoutUpdate();

            $scope.sys.updated = false;
            $scope.sys.zones.forEach(function (zone) {
              zone.updated = false;
            });

            eventsService.createEvent('infosistema2', $scope.sys, null, null);
          }
        }
      });

      /*
       * Watch when cgi error is produced (example: system is disconected)
       * Close all systems
       */
      $scope.listener12 = $scope.$watch('sys.expand', function (new_val, old_val, scope) {
        if (new_val != old_val) {
          $scope.expand = new_val;
        }
      });

      /*
       * Watch when system errors is changed to show information icon
       */
      $scope.listener17 = $scope.$watch('sys.system_errors', function (new_val, old_val, scope) {
        if (new_val && new_val != old_val) {
          // Check if system error is activated ACS warning
          check_acs_warning();
        }
      });

      /*
       * Return class for a mode (integer)
       */
      function getClassFromMode(mode) {
        return $scope.modes[parseInt(mode)];
      }

      /*
       * Return class for eco (integer)
       */
      function getClassFromEco(eco) {
        return $scope.ecos[parseInt(eco)];
      };

      /*
       * Return eco id for eco class_eco
       */
      function getEcoFromClass(class_eco) {
        return $scope.ecos.indexOf(class_eco);
      };

      /*
       * Return class for scene (integer)
       */
      function getClassFromScene(scene) {
        return $scope.scenes[parseInt(scene)];
      }

      // Check if array is equals to other in any order
      function arraysEqual(a, b) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length != b.length) return false;

        if (a.sort().toString() == b.sort().toString())
          var result = true;
        else
          result = false;

        return result;
      }

      /*
       * Create event every polling time to refresh change from webserver
       */
      function timeoutUpdate() {
        $scope.update_timeout = setTimeout(function () {
          eventsService.createEvent('infosistema2', $scope.sys, null, null);
          timeoutUpdate();
        }, $scope.polling_time);
      }

      /*
       * Init the timeout to update the system data
       */
      $scope.sys.initTimeoutUpdate = function () {
        clearTimeout($scope.update_timeout);
        timeoutUpdate();
      }

      /*
       * Return the limits of the system
       */
      function getLimits() {
        var heat_mode = [2, 4, 5].indexOf(parseInt($scope.sys.mode)) > -1;
        var cool_mode = [1, 6, 8, 9].indexOf(parseInt($scope.sys.mode)) > -1;
        if ($scope.sys && $scope.sys.temperature_unit == "1") {
          var lower_limit = 59;
          var upper_limit = 86;
        } else {
          lower_limit = 15;
          upper_limit = 30;
        }

        switch (parseInt($scope.sys.eco)) {
          case 0:
            if ($scope.sys && $scope.sys.temperature_unit == "1") {
              lower_limit = 73;
              upper_limit = 73;
            } else {
              lower_limit = 23;
              upper_limit = 23;
            }
            break;
          case 1:
            if (cool_mode) {
              // master has 15 - 30
              // when zones come from Websockets update slaves limits (zone_row_controller)
            }
            else if (heat_mode) {
              // master has 15 - 30
              // when zones come from Websockets update slaves limits (zone_row_controller)
            }
            else {
              if ($scope.sys && $scope.sys.temperature_unit == "1") {
                lower_limit = 59;
                upper_limit = 86;
              } else {
                lower_limit = 18.0;
                upper_limit = 30.0;
              }
            }
            break;
          case 2:
            if (cool_mode) {
              if ($scope.sys && $scope.sys.temperature_unit == "1")
                lower_limit = 75;
              else
                lower_limit = 24.0;
            }
            else if (heat_mode) {
              if ($scope.system && $scope.system.temperature_unit == "1") {
                if ($rootScope.system_country_code == 'IT')
                  upper_limit = 69;
                else
                  upper_limit = 71;
              } else {
                if ($rootScope.system_country_code == 'IT')
                  upper_limit = 21.0;
                else
                  upper_limit = 22.0;
              }
            }
            break;
          case 3:
            if (cool_mode) {
              if ($scope.sys && $scope.sys.temperature_unit == "1")
                lower_limit = 77;
              else
                lower_limit = 25.0;
            }
            else if (heat_mode) {
              if ($scope.sys && $scope.sys.temperature_unit == "1") {
                if (['IT', 'FR', 'BE'].indexOf($rootScope.system_country_code) > -1)
                  upper_limit = 69;
                else
                  upper_limit = 70;
              } else {
                if (['IT', 'FR', 'BE'].indexOf($rootScope.system_country_code) > -1)
                  upper_limit = 20.5;
                else
                  upper_limit = 21.5;
              }
            }
            break;
          case 4:
            if (cool_mode) {
              if ($scope.sys && $scope.sys.temperature_unit == "1")
                lower_limit = 78;
              else
                lower_limit = 26.0;
            }
            else if (heat_mode) {
              if ($scope.sys && $scope.sys.temperature_unit == "1") {
                if (['FR', 'BE'].indexOf($rootScope.system_country_code) > -1)
                  upper_limit = 66;
                else if ($rootScope.system_country_code == 'IT')
                  upper_limit = 68;
                else
                  upper_limit = 69;
              } else {
                if (['FR', 'BE'].indexOf($rootScope.system_country_code) > -1)
                  upper_limit = 19.0;
                else if ($rootScope.system_country_code == 'IT')
                  upper_limit = 20.0;
                else
                  upper_limit = 21.0;
              }
            }
            break;
        };

        return { lower_limit: lower_limit, upper_limit: upper_limit };
      }

      /*
       * Update slaves zones of system limits when eco is manual
       * Master consign is the limit of slaves zones
       */
      function updateSlavesLimits() {
        if (parseInt($scope.sys.eco) == 1) {
          var master_zone = $scope.sys.zones.filter(function (z) { return z.master == '1' })[0]
          var heat_mode = [2, 4, 5].indexOf(parseInt($scope.sys.mode)) > -1;
          var cool_mode = [1, 6, 8, 9].indexOf(parseInt($scope.sys.mode)) > -1;

          $scope.sys.zones.forEach(function (zone) {
            if (zone.master != '1') {
              if (heat_mode) {
                zone.upper_limit = master_zone.consign;

                if (zone.consign > master_zone.consign)
                  zone.consign = master_zone.consign;
              }
              else if (cool_mode) {
                zone.lower_limit = master_zone.consign;

                if (zone.consign < master_zone.consign)
                  zone.consign = master_zone.consign;
              }
            }
          });
        }
      }

      function updateZonesLimits() {
        var limits = getLimits();
        $scope.zones.forEach(function (zone) {
          if ($scope.sys.eco == '0') {
            zone.lower_limit = zone.lower_conf_limit;
            zone.upper_limit = zone.upper_conf_limit;
          }
          else {
            zone.lower_limit = limits.lower_limit;
            zone.upper_limit = limits.upper_limit;
          }
        });

        if (parseInt($scope.sys.eco) == 1) {
          updateSlavesLimits();
        }
      }

      /*
       * Check the activated acs warning in errors system to show information icon
       */
      function check_acs_warning(){
        if ($scope.sys.system_errors && $scope.sys.system_errors[8] === "1" && ($scope.sys.system_errors.split("1").length - 1) === 1) {
          $scope.is_ACS_warning = true;
        }
      }

      /*
       * Check auto mode for show icon
       */
      $scope.checkAutoIcon = function () {
        if (!$scope.sys.mode) {
          $scope.class_mode = "mode-generic";
          return $scope.class_mode;
        }

        if ($scope.sys.auto == "1") {
          if ($scope.sys.mode == "0") {
            $scope.class_mode = "auto";
            return $scope.class_mode;
          } else {
            $scope.class_mode = getClassFromMode($scope.sys.mode);
            return $scope.class_mode + "-air";
          }
        } else
          return $scope.class_mode;
      };

      /*
       * Open the consumption chart
       */
      $scope.openConsumptionChart = function () {
        $rootScope.consumption_target = $scope.sys;
      }

      $scope.$on('$destroy', function () {
        $scope.listener1();
        $scope.listener2();
        $scope.listener3();
        $scope.listener4();
        $scope.listener5();
        $scope.listener6();
        $scope.listener7();
        $scope.listener8();
        //$scope.listener9();
        $scope.listener10();
        $scope.listener11();
        $scope.listener12();
        $scope.listener13();
        $scope.listener14();
        $scope.listener15();
        $scope.listener16();
        $scope.listener17();
        clearInterval($scope.intervalFocus);
        if ($scope.update_timeout) {
          clearTimeout($scope.update_timeout);
        }
        if ($scope.sys && !$rootScope.demo) {
          $scope.sys.updated = false;
          $scope.zones.forEach(function (zone) {
            zone.updated = false;
          });
        }
      });
    }]);
