angular
  .module("Airzone")
  .factory("SystemUtilsService", SystemUtilsService);

  SystemUtilsService.$inject = ['$timeout'];

function SystemUtilsService($timeout) {

  var service = {
    getMaxMinSystemSetpoint: getMaxMinSystemSetpoint,
    getMaxMinAbsoluteSetpoint: getMaxMinAbsoluteSetpoint,
    restartTimeout: restartTimeout,
    setSystemPower: setSystemPower
  };

  return service;

  /*********************************************************************/

  function restartTimeout(timeoutId, timeoutFunction, time){
    if(timeoutId) {
      $timeout.cancel(timeoutId);
    }

    timeoutId = $timeout(timeoutFunction, time);

    return timeoutId;

  }

  /*
   * Return system power to true if all zones are turn on or false if some zone is turn off
   */
  function setSystemPower(system){
    // Init all zones to true
    var all_zones_turn_on = true;
    if (system.zones){
      for( var i = 0; i < system.zones.length; i++ ){
        // Set to all zones to false if it is found some zone to turned off
        if( system.zones[i].state === "0" ){
          all_zones_turn_on = false;
        }
      }
    }

    return all_zones_turn_on
  }

  function getMaxMinSystemSetpoint(system, zone, isHeatSetpoint) {
    var max, min;
    if (system && system.temperature_unit === "1") {
      max = parseInt(system.max_limit);
      min = parseInt(system.min_limit);
      if (zone !== null && zone.auto === "1") {
        if (isHeatSetpoint) {
          if (parseInt(system.max_limit) < 86 - system.autochange_differential)
            max = parseInt(system.max_limit);
          else max = 86 - system.autochange_differential;
          min = 59;
        } else {
          if (parseInt(system.min_limit) > 59 + system.autochange_differential)
            min = parseInt(system.min_limit);
          else min = 59 + system.autochange_differential;
          max = 86;
        }
      } else if (isHeatSetpoint) min = 59;
      else max = 86; //if(heat_mode)
    } else {
      if (system.scene) {
        //ZBS
        max = parseFloat(system.max_limit); //TODO: máximos y mínimos del sistema!
        min = parseFloat(system.min_limit);

        if (zone !== null && zone.auto == "1") {
          if (isHeatSetpoint) {
            if (
              parseFloat(system.max_limit) <
              30.0 - system.autochange_differential / 2.0
            )
              max = parseFloat(system.max_limit);
            else max = 30.0 - system.autochange_differential / 2.0;
            min = 15.0;
          } else {
            if (
              parseFloat(system.min_limit) >
              15.0 + system.autochange_differential / 2.0
            )
              min = parseFloat(system.min_limit);
            else min = 15.0 + system.autochange_differential / 2.0;
            max = 30.0;
          }
        } else if (isHeatSetpoint) min = 15.0;
        else max = 30.0;
      } else {
        //no ZBS
        max = 30.0;
        min = 15.0;
        if (!isHeatSetpoint) min = 18.0;
      }
    }
    var maxMin = {
      max : max,
      min : min
    }
    return maxMin;
  }

  function getMaxMinAbsoluteSetpoint(
    deltaAutoChange,
    isHeatSetpoint,
    isUnitsFah
  ) {
    var maxMin = {
      max: 0,
      min: 0
    };

    if (isUnitsFah) {
      if (isHeatSetpoint) {
        maxMin.min = 59;
        maxMin.max = 86 - deltaAutoChange;
      } else {
        if ((64 - deltaAutoChange) < 59) {
          maxMin.min = 59 + deltaAutoChange;
        } else {
          maxMin.min = 64;
        }
        maxMin.max  = 86;
      }
    } else {
      //centigrados. puede ser que aqui no haya autochange
      if(deltaAutoChange !== null)
        deltaAutoChange /= 2.0;

      if (isHeatSetpoint) {
        maxMin.min = 15.0;
        if(deltaAutoChange !== null)
          maxMin.max  = 30.0 - deltaAutoChange;
        else 30.0;
      } else {
          if ((deltaAutoChange !== null) && ((18.0 - deltaAutoChange) < 15.0)) {
            maxMin.min = 15.0 + deltaAutoChange;
          } else {
            maxMin.min = 18.0;
          }
        maxMin.max  = 30.0;
      }
    }

    return maxMin;
  }
}
