Airzone.service('eventsService', ['$http', '$q', '$rootScope', 'sessionService', '$state',
  function ($http, $q, $rootScope, sessionService, $state) {
    return ({
      createEvent: createEventOnServer,
      createParamsEvent: createParamsEventOnServer,
      createEventModel: createEventModelOnServer
    });

    //
    //Public functions
    //

    /*
     * Send a event to the server. This will create a cgi with the instruction
     * @param {string} target_type zone or service
     * @param {string} target_id with id of target
     * @param {string} field to modify
     * @param {string} value value to appl value to applyy
     */
    function createEventOnServer(cgi, target, field, value) {
      if ($rootScope.demo) {
        return $q.when(true);
      }

      var request = $http({
        method: "post",
        url: url_path + "events/",
        params: {
          user_email: sessionService.email(),
          user_token: sessionService.token()
        },
        data: {
          event: {
            cgi: cgi,
            system_number: target.system_number,
            zone_number: target.zone_number,
            schedule_number: target.schedule_number,
            option: field,
            value: value,
            device_id: target.device_id
          }
        }
      });

      return (request.then(handleCreateSuccess, handleError));
    }

    /*
     * Send a event to the server. This will create a cgi with the instruction
     * @param {string} cgi is type of command to send
     * @param {string} target is the id of target
     * @param {string} parameters are keys with values concated with & to send 
     */
    function createParamsEventOnServer(cgi, target, parameters) {
      if ($rootScope.demo) {
        return $q.when(true);
      }

      var request = $http({
        method: "post",
        url: url_path + "events/",
        params: {
          user_email: sessionService.email(),
          user_token: sessionService.token()
        },
        data: {
          event: {
            cgi: cgi,
            system_number: target.system_number,
            zone_number: target.zone_number,
            schedule_number: target.schedule_number,
            params: parameters,
            device_id: target.device_id
          }
        }
      });

      return (request.then(handleCreateSuccess, handleError));
    }

    /*
     * Create an event given a model
     */
    function createEventModelOnServer(cgi, target) {
      if ($rootScope.demo) {
        return (true);
      }

      var request = $http({
        method: "post",
        url: url_path + "events/",
        params: {
          user_email: sessionService.email(),
          user_token: sessionService.token()
        },
        data: {
          cgi: cgi,
          model: target,
          device_id: target.device_id
        }
      });

      return (request.then(handleCreateSuccess, handleError));
    }

    /*
     * handle the create success response
     */
    function handleCreateSuccess(response) {
      return (response.data);
    };

    /*
     * Handle error when http request fail
     */
    function handleError(response) {
      // Logout when unauthorized
      if (response.status == 401) {
        sessionService.unauthorize();
      }
      // Problem with server
      else if (response.status == 422) {
        $rootScope.disconnected_device = true;
        return ($q.reject("An unknown error occurred."));
      } else if (response.status == 403) {
        $state.go('init.403');
        return ($q.reject("An unknown error occurred."));
      } else if (response.status == 404) {
        $state.go('init.404');
        return ($q.reject("An unknown error occurred."));
      }
      else if (!angular.isObject(response.data) || !response.data.message) {
        $rootScope.disconnection = true;
        return ($q.reject("An unknown error occurred."));
      }

      return ($q.reject(response.data.message));
    };
  }]);
