Airzone.controller('adjustController', ['$scope', '$rootScope', '$http', '$state', 'sessionService', 'userService', 'MOBILE_APP', '$injector',
  function ($scope, $rootScope, $http, $state, sessionService, userService, MOBILE_APP, $injector) {
    $scope.languageDefault = null;
    $scope.languageDefaultMobile = null;
    $scope.changePass = false;
    $scope.invalid = false;
    $scope.passUser = {
      pass: '',
      repass: '',
      repass2: ''
    };
    $scope.showPassword1 = false;
    $scope.inputType1 = 'password';
    $scope.showPassword2 = false;
    $scope.inputType2 = 'password';
    $scope.showPassword3 = false;
    $scope.inputType3 = 'password';

    // Inject the localStorage and notification service in mobile app
    if (MOBILE_APP) {
      //var localStorageService = $injector.get('localStorageService');
      //var notificationsService = $injector.get('notificationsService');
      $scope.user = {};
      $scope.user.send_notification = false;
      $scope.is_mobile_app = true;
    }

    // Prevent bottom space when keyboard hides
    $scope.scrollToTop = function($event){
      window.scrollTo(0, 0);
    }

    /*
      Uso una directiva para generar el desplegable, adjunto url del repo por
      si necesitais mirar algo:
      https://github.com/jseppi/angular-dropdowns
    */

    /*
      Listado de los idiomas soportados, el texto está traducido en su idioma original
    */
    $scope.languages = [
      {
        text: $rootScope.i18n('adjust.spanish'),
        value: 'es'
      },
      {
        text: $rootScope.i18n('adjust.english'),
        value: 'en'
      },
      {
        text: $rootScope.i18n('adjust.french'),
        value: 'fr'
      },
      {
        text: $rootScope.i18n('adjust.italian'),
        value: 'it'
      },
      {
        text: $rootScope.i18n('adjust.portuguese'),
        value: 'pt'
      },
      {
        text: $rootScope.i18n('adjust.german'),
        value: 'de'
      }
    ];

    /*
      Variable para controlar que mensaje se mostrara:
      - Cuando no se muestre ningún mesaje asignar el valor false
      - Para mostrar un mensaje, asignar el mismo valor que tenga el mensaje en
        "ng-show"
    */
    $scope.message = false;


    initialize();

    function initialize() {
      sessionService.user().then(function (user) {
        $scope.user = user;

        if ($rootScope.isMobile) {
          $scope.languageDefault = $scope.user["language"] || __eolo.default_language;
        }

        $scope.languages.forEach(function (language) {
          if (language.value == $scope.user.language) {
            $scope.languageDefault = JSON.parse(JSON.stringify(language));
            $scope.languageDefaultMobile = JSON.parse(JSON.stringify(language)).value;
            $scope.user.language = language.value;
          }
        });

        if (MOBILE_APP && sessionService.checkSession()) {
          $http({
            method: "get",
            url: url_path + "users/" + sessionService.id(),
            params: {
              user_email: sessionService.email(),
              user_token: sessionService.token()
            },
            data: {}
          }).then(function (data) {
            $scope.user.send_notification = data.data.user.send_notification;
          });
        }
      }, function (error) {
        console.log("Error get user session: ", error);
      });
    }

    /*
      Función que se ejecuta cada vez que el usuario cambia de idioma
    */
    $scope.changeLanguage = function (language) {
      $scope.user.language = language.value;
    };

    /*
      Funcion para guardar los datos del usuario
    */
    $scope.saveUserSettings = function () {
      if ($scope.editUserForm.$valid) {
        var params = {
          user_token: sessionService.token(),
          user_email: sessionService.email(),
          user: {
            name: $scope.user.name,
            surname: $scope.user.surname,
            language: $scope.user.language,
            advanced_adjust: $scope.user.advanced_adjust
          }
        }
        userService.updateUser($scope.user.id, params).then(function () {
          //forzamos la actualizacion de los datos de usuario
          sessionService.requestUserData().then(function () {
            $scope.message = 'saveSuccess';
            $rootScope.language = $scope.user.language;
          }).catch(function (error) {
            console.log("Error get the user data:", error);
            $scope.message = 'errorServer';
          });
        }).catch(function (error) {
          console.log("Error to update the user data:", error);
          $scope.message = 'errorServer';
        });
      } else {
        $scope.editUserForm.invalid = true;
      }
    };

    /*
     * Confirmation of delete user
     */
    $scope.deleteUserConfirmation = function () {
      return sessionService.destroyUser().then(function (data) {
        $scope.message = 'deleteUserSuccess';
      }, function (err) {
        $scope.message = 'errorServer';
      });
    };

    /*
     * Exit user
     */
    $scope.exitUser = function () {
      sessionService.unauthorize();
    };

    $scope.reloadAdjust = function () {
      $scope.message = false;
      $state.go('home.adjust', {}, { reload: true });
    }

    /*
     * Show message confirm
     */
    $scope.showMessageConfirm = function () {
      $scope.message = 'deleteUserConfirm';
    };

    $scope.tooglePassView = function () {
      $scope.showPassword1 = false;
      $scope.inputType1 = 'password';
      $scope.showPassword2 = false;
      $scope.inputType2 = 'password';
      $scope.showPassword3 = false;
      $scope.inputType3 = 'password';
      $scope.passUser = {
        pass: '',
        repass: '',
        repass2: ''
      };
      $scope.changePassUserForm.invalid = false;
      $scope.invalid_password = false;
      $scope.changePass = !$scope.changePass;
    };

    $scope.changePassUser = function () {
      $scope.invalid_password = false;
      if ($scope.changePassUserForm.$valid) {
        params = {
          user_token: sessionService.token(),
          user_email: sessionService.email(),
          user: {
            old_password: $scope.passUser.pass,
            password: $scope.passUser.repass,
            password_confirmation: $scope.passUser.repass
          }
        }
        userService.updateUser($scope.user.id, params).then(
          function (data) {
            sessionService.requestUserData().then(function () {
              $scope.message = 'saveSuccess';
              $scope.tooglePassView();
            }).catch(function (error) {
              $scope.changePassUserForm.invalid = true;
              $scope.invalid_password = true;
            });
          });
      } else {
        $scope.changePassUserForm.invalid = true;
      }
    };

    /*
     * Hide & show password function
     */
    $scope.hideShowPassword = function (type) {
      if (type === 1) {
        if ($scope.inputType1 == 'password') {
          $scope.showPassword1 = true;
          $scope.inputType1 = 'text';
        } else {
          $scope.showPassword1 = false;
          $scope.inputType1 = 'password';
        }
      }else if (type === 2) {
        if ($scope.inputType2 == 'password') {
          $scope.showPassword2 = true;
          $scope.inputType2 = 'text';
        } else {
          $scope.showPassword2 = false;
          $scope.inputType2 = 'password';
        }
      }else if (type === 3) {
        if ($scope.inputType3 == 'password') {
          $scope.showPassword3 = true;
          $scope.inputType3 = 'text';
        } else {
          $scope.showPassword3 = false;
          $scope.inputType3 = 'password';
        }
      }
    };

  }]);
