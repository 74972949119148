Airzone.controller('installationController', ['$scope', '$rootScope', '$timeout', 'URL_PATH', '$interval', '$http', '$q',
  function ($scope, $rootScope, $timeout, URL_PATH, $interval, $http, $q) {

    $scope.find_ssid = false;
    $scope.state = 1;
    $scope.old_state = 1;
    $scope.routers = [];
    $scope.router = null;
    $scope.error = false;
    $scope.error_mac_not_found = false;
    $scope.error_sleeping = false;
    $scope.error_not_connected = false;
    $scope.typeDhcp = true;
    $scope.loading = false;
    $scope.loading1 = false;
    $scope.loading2 = false;
    $scope.connection = {
      ip: '',
      port: '',
      masc: ''
    }
    $scope.show_advances = false;
    $scope.manual_router = false;
    $scope.low_signal = false;
    $scope.auto_search = false;
    $scope.server = {};
    $scope.inputType = 'password';

    if ($rootScope.isMobile) {
      $scope.devicePlatform = device.platform;
      $scope.deviceVersion = parseInt(device.version.replace('.', ''));
      isLocationEnabled();
      isLocationAvailable();
    }

    // Bluetooth configuration constants
    $scope.BLUETOOTH = {
      MIN_LEVEL_SIGNAL: -85,
      MIN_LEVEL_SIGNAL_RESET_WIFI: -85,
      MAX_TIME_SCAN_DEVICES: 2000,
      DEVICES_PREFIXES: 'AZW5GR',
      SERVICE_UUID: 'AABB',
      CHARACTERISTIC_UUID: 'C300',
      MAX_TIME_GET_DATA: 60000,
      SLEEP_TIME_BETWEEN_REQUEST: 2000
    }

    function scanComplete() {
      if ($scope.bluetooths_devices.length > 0) {
        $scope.bluetooths = $scope.bluetooths_devices.sort(function (a, b) { return parseInt(b.rssi) - parseInt(a.rssi); });
      }
      $scope.load = false;
      $scope.$apply();
      console.log($scope.bluetooths);
    }

    function goInit() {
      $scope.state = 1;
      $scope.$apply();
    }

    // Prevent bottom space when keyboard hides
    $scope.scrollToTop = function ($event) {
      window.scrollTo(0, 0);
    }

   /*
    * Hide & show password function
    */
    $scope.hideShowPassword = function () {
      if ($scope.inputType == 'password') {
        $scope.showPassword = true;
        $scope.inputType = 'text';
      } else {
        $scope.showPassword = false;
        $scope.inputType = 'password';
      }
    };


    /*
     * Set the state to a specific state
     */
    $scope.setState = function (state) {
      $scope.error = false;
      $scope.state = state;

      if (state === 9) {
        if ($scope.device_id) {
          disconnectBluetooth($scope.device_id);
        }
        $scope.error_not_connected = false;
        $scope.bluetooths = [];
        $scope.bluetooths_devices = [];
        $scope.load = true;
        if (!$scope.$$phase) {
          $scope.$apply();
        }
        scanDevicesBluetooth();
      }

      if (state === 11) {
        $scope.routers = []
        $scope.load = true;
        $scope.iterator = 0;
        writeDevice($scope.device_id, '{"cmd":"scan"}', 'getDeviceWifis');
      }

      if (state === 12) {
        $scope.inputType = 'password';
        $scope.showPassword = false;
        $scope.manual_router = true;
        $scope.router = {};
        $scope.router.bssid = '';
        $scope.router.seguridad = 'WPA';
      }

      if (state == 14) {
        $scope.retries = 24;
        $scope.loading1 = true;
        $scope.iterator = 0;
        $scope.checkConnectedDeviceIterator = 0;
        checkConnectedDevice();
      }

      if (state === 1) {
        isLocationEnabled();
        isLocationAvailable();
      }

      if ($scope.state === 3) {
        $scope.find_ssid = false;
        $scope.getMacRetries = 5;
        if (!$scope.find_ssid) {
          if ($scope.getCurrentSSID) {
            $interval.cancel($scope.getCurrentSSID);
          }
          $scope.getCurrentSSID = $interval(function () {
            WifiWizard2.getConnectedSSID()
              .then(function (data) {
                ssidHandler(data)
                  .then(function (mac) {
                    $scope.bssid = mac;
                    // esperamos a tener la mac para mostrar el botón de siguiente
                    $scope.find_ssid = true;
                    $interval.cancel($scope.getCurrentSSID);
                    $scope.$apply();
                  })
              })
              .catch(function (err) {
                fail(err);
              });
          }, 1000);
        };
      }

      if ($scope.state === 4) {
        $scope.loading1 = false;
        $scope.auto_search = false;
      }

      if ($scope.state === 5) {
        $scope.inputType = 'password';
        $scope.showPassword = false;
      }

      if (state == 8) {
        $scope.retries = 24;
        $scope.loading1 = true;
        console.log("Disconnect:", $scope.ssid_webserver);
        disconnect();
      }
    };

    /*
     * Check if bluetooth is Enabled and pass to next step
     *
     * @return {Promise}           - Promise object with result of check if device bluetooth is enabled
     * @throws {bluetoothDisabled} - Device bluetooth is Disabled
     */
    $scope.checkBluetooth = function () {
      ble.isEnabled(
        function () {
          $scope.setState(9);
        },
        function () {
          navigator.notification.alert(
            $rootScope.i18n('messages.errors.bluetooth_disabled_text'), // message
            $scope.setState(1),                                         // callback
            $rootScope.i18n('messages.errors.bluetooth_disabled_title'),// title
            $rootScope.i18n('buttons.accept')                           // buttonName
          );
          console.log('bluetoothDisabled: Device bluetooth is Disabled');
        }
      );
    }

    /*
     * Check if bluetooth is Enabled and pass to next step
     *
     * @return {Promise}           - Promise object with result of check if device bluetooth is enabled
     * @throws {bluetoothDisabled} - Device bluetooth is Disabled
     */
    function isBluetoothEnabled() {
      ble.isEnabled(
        function () {
          return true;
        },
        function () {
          navigator.notification.alert(
            $rootScope.i18n('messages.errors.bluetooth_disabled_text'), // message
            $scope.setState(1),                                         // callback
            $rootScope.i18n('messages.errors.bluetooth_disabled_title'),// title
            $rootScope.i18n('buttons.accept')                           // buttonName
          );
          console.log('bluetoothDisabled: Device bluetooth is Disabled');
        }
      );
    }

    /*
     * Show notification and redirect to first step
     */
    function notifyLocation() {
      navigator.notification.alert(
        $rootScope.i18n('messages.errors.location_disabled_text'),  // message
        goInit(),                                                   // callback
        $rootScope.i18n('messages.errors.location_disabled_title'), // title
        $rootScope.i18n('buttons.accept')                           // buttonName
      );
    }

    /*
     * Check if location is Enabled
     *
     *
     * @return {Promise}              - Promise object with result of check if device location is enabled
     * @throws {LocationDisabled}     - Device location is Disabled
     * @throws {errorLocationEnabled} - Error trying to check if the device location is Enabled
     */
    function isLocationEnabled() {
      cordova.plugins.diagnostic.isLocationEnabled(
        function (enabled) {
          if (enabled) {
            return true;
          } else {
            navigator.notification.alert(
              $rootScope.i18n('messages.errors.location_text'),  // message
              goInit(),                                // callback
              $rootScope.i18n('messages.errors.location_title'), // title
              $rootScope.i18n('buttons.accept')                  // buttonName
            );
          }
        }, function (error) {
          console.log('errorLocationEnabled', error);
        }
      );
    }

    /*
     * Check if location is Available
     *
     *
     * @return {Promise}                - Promise object with result of check if device location is available
     * @throws {LocationNotAvailable}   - Device location is not Available
     * @throws {errorLocationAvailable} - Error trying to check if the device location is Available
     * @throws {errorAskActiveLocation} - Error trying to ask the user if activate the location
     */
    function isLocationAvailable() {
      cordova.plugins.diagnostic.isLocationAvailable(
        function (available) {
          if (available) {
            return true;
          } else {
            if ($scope.devicePlatform === "Android") {
              cordova.plugins.diagnostic.requestLocationAuthorization(function (status) {
                switch (status) {
                  case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
                    console.log("Permission not requested");
                    break;
                  case cordova.plugins.diagnostic.permissionStatus.GRANTED:
                    console.log("Permission granted");
                    break;
                  case cordova.plugins.diagnostic.permissionStatus.DENIED:
                    goInit();
                    console.log("Permission denied");
                    break;
                  case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
                    console.log("Permission permanently denied");
                    notifyLocation();
                    break;
                }
              }, function (error) {
                console.error(error);
              });
            } else if ($scope.devicePlatform === "iOS") {
              // Ask the user if he want available location in Android
              cordova.plugins.diagnostic.requestLocationAuthorization(
                function (status) {
                  switch (status) {
                    case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
                      console.log("Permission not requested");
                      break;
                    case cordova.plugins.diagnostic.permissionStatus.DENIED:
                      console.log("Permission denied");
                      notifyLocation();
                      break;
                    case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
                      console.log("Permission denied always");
                      notifyLocation();
                      break;
                    case cordova.plugins.diagnostic.permissionStatus.GRANTED:
                      console.log("Permission granted always");
                      break;
                    case cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
                      console.log("Permission granted only when in use");
                      break;
                  }
                }, function (error) {
                  console.log('errorAskActiveLocation', error);
                }, cordova.plugins.diagnostic.locationAuthorizationMode.ALWAYS
              );
            }
          }
        }, function (error) {
          console.log('errorLocationAvailable', error);
        }
      );
    }

    /*
     * Search devices by bluetooth
     *
     * The succees method is called every time a new device is founded
     *
     * @constant {DEVICES_PREFIXES}      - String with prefix of device to filter devices found
     * @constant {MIN_LEVEL_SIGNAL}      - Minimum level of the signal, avoid showing devices too far away that can fail in communications
     * @constant {MAX_TIME_SCAN_DEVICES} - Device search time
     * @param  {function}                - Callback function when a new device is found
     * @return {Promise}                 - Promise object with each device founded
     * @throws {errorStartScan}          - Error trying to scan devices by bluetooth
     * @throws {errorStopScan}           - Error trying to stop scan devices by bluetooth
     */
    function scanDevicesBluetooth() {
      var deviceIds = [];
      ble.startScan([], function (device) {
        if (
          device.name &&
          device.name.indexOf($scope.BLUETOOTH.DEVICES_PREFIXES) > -1 &&
          device.rssi > $scope.BLUETOOTH.MIN_LEVEL_SIGNAL
        ) {
          // Change name property to fix conflict when associate device
          device.deviceName = device.name;
          if (deviceIds.indexOf(device.id) === -1) {
            // evitamos duplicidades
            deviceIds.push(device.id);
            delete device.name;
            $scope.bluetooths_devices.push(device);
            console.log(JSON.stringify(device));
          }

          return true;
        }
      },
        function () {
          console.log('errorStartScan: Error trying to scan devices by bluetooth');
          navigator.notification.alert(
            $rootScope.i18n('messages.errors.bluetooth_scan_text'), // message
            $scope.setState(9),                                     // callback
            $rootScope.i18n('messages.errors.bluetooth_scan_title'),// title
            $rootScope.i18n('installations.retry')                  // buttonName
          );
        })

      setTimeout(ble.stopScan,
        $scope.BLUETOOTH.MAX_TIME_SCAN_DEVICES,
        function () { console.log("Scan complete"); scanComplete() },
        function () { console.log("stopScan failed"); }
      );
    }

    $scope.selectBluetooth = function (device) {
      console.log(device)
      $scope.load = true;
      ble.isConnected(device.id,
        function () {
          $scope.load = false;
          $scope.device_id = device.id;
          $scope.state = 10;
          $scope.$apply();
        },
        function () {
          ble.connect(device.id,
            function () {
              $scope.selectBluetooth(device);
            },
            function () {
              console.log('Unable connect to device');
              navigator.notification.alert(
                $rootScope.i18n('messages.errors.bluetooth_connect_device_text'), // message
                $scope.setState(9),                                               // callback
                $rootScope.i18n('messages.errors.bluetooth_connect_device_title'),// title
                $rootScope.i18n('installations.retry')                            // buttonName
              );
            }
          )
        })
    }

    function connectDevice(device_id) {
      console.log(device_id)
      ble.isConnected(device_id,
        function () {
          return true;
        },
        function () {
          ble.connect(device_id,
            function () {
              return true;
            },
            function () {
              console.log('Unable connect to device');
              navigator.notification.alert(
                $rootScope.i18n('messages.errors.bluetooth_connect_device_text'), // message
                $scope.setState(9),                                               // callback
                $rootScope.i18n('messages.errors.bluetooth_connect_device_title'),// title
                $rootScope.i18n('installations.retry')                            // buttonName
              );
              return true;
            }
          )
        })
    }

    function writeDevice(device_id, command, type) {
      var data_buffer = stringToBytes(command);
      ble.write(
        device_id,
        $scope.BLUETOOTH.SERVICE_UUID,
        $scope.BLUETOOTH.CHARACTERISTIC_UUID,
        data_buffer,
        function () {
          console.log("Sent: ", command);
          return checkRead(device_id, type)
        },
        function (error) {
          console.log('errorWriteDevice: ', error);
          return checkRead(device_id, type)
        }
      );
    }

    function checkRead(dev_id, read_type) {
      if (!$scope.getDeviceDataInterval) {
        $scope.getDeviceDataInterval = $interval(
          function () {
            // Check every 2 seconds for 10 attemps
            if ($scope.iterator < 10) {
              $scope.iterator += 1;
              console.log("getDeviceData: ", $scope.iterator);
              return readDevice(dev_id, read_type);
            } else {
              console.log("Finish check read (10 attemps every 2 seconds)");
              console.log("Interval of read: ", $scope.getDeviceDataInterval);
              $interval.cancel($scope.getDeviceDataInterval);
              delete $scope.getDeviceDataInterval;
              if ($scope.deviceInfo.wifi.status !== 3) {
                $scope.error = true;
                $scope.error_not_connected = true;
              }
              return true;
            }
          }, 2000);
      }
    }

    /*
     * Read characteristic from device by Bluetooth
     *
     * @param  {String}          - Device ID
     * @return {deviceInfo}      - Result of read device
     * @throws {errorReadDevice} - Error when try read from device
     */
    function readDevice(device_id, device_data) {
      ble.read(
        device_id,
        $scope.BLUETOOTH.SERVICE_UUID,
        $scope.BLUETOOTH.CHARACTERISTIC_UUID,
        function (data) {
          $scope.deviceInfo = bytesToString(data);
          checkResponse(device_id, device_data);
        },
        function (error) {
          console.log('errorReadDevice', error);
          //disconnectBluetooth($scope.device_id);
          //connectDevice($scope.device_id);
        }
      );
    }

    function checkResponse(device_id, data_info) {
      console.log($scope.deviceInfo);

      if ($scope.deviceInfo) {
        if (data_info === 'getDeviceInfo' && $scope.deviceInfo.wifi) {
          unableReadInterval();

          if ($scope.deviceInfo.wifi.status === 0 || $scope.deviceInfo.wifi.status === 4) {
            $scope.error = true;
            finishLoadingBluetooth(device_id);
          } else if ($scope.deviceInfo.wifi.status === 3) {
            finishLoadingBluetooth(device_id);
          } else if ($scope.deviceInfo.wifi.status === 1 || $scope.deviceInfo.wifi.status === 2) {
            $scope.error = true;
            $scope.error_not_connected = true;
            finishLoadingBluetooth(device_id);
          }
        }

        if (data_info === 'getModbus' && $scope.deviceInfo.extrainfo && $scope.deviceInfo.extrainfo.modbusaddr) {
          unableReadInterval();
          return $scope.deviceInfo;
        }

        if (data_info === 'getDeviceWifis' && $scope.deviceInfo.aplist) {
          $scope.load = false;
          unableReadInterval();
          $scope.routers = $scope.deviceInfo.aplist;
          $scope.$apply();
        }

        if (data_info === 'connectDeviceToWifi') {
          unableReadInterval();
          $scope.loading2 = false;
          console.log($scope.deviceInfo);
          $scope.setState(14);
        }
      }
    }

    function disconnectBluetooth(device_id) {
      ble.disconnect(device_id,
        function () {
          console.log("disconnect Bluetooth success");
        },
        function (error) {
          console.log("Error disconnect Bluetooth: ", error)
        }
      );
    }

    function unableReadInterval() {
      if ($scope.getDeviceDataInterval) {
        $interval.cancel($scope.getDeviceDataInterval);
        delete $scope.getDeviceDataInterval;
        $scope.iterator = 10;
      }
    }

    $scope.getUnescapeString = function (string) {
      return unescape(string);
    }

    /*
     * Convert data to ArrayBuffer
     *
     * @param  {String}             - String with data
     * @return {ArrayBuffer}        - Array Buffer with data converted
     * @throws {errorStringToBytes} - Error when try pass string to bytes
     */
    function stringToBytes(string) {
      var array = new Uint8Array(string.length);
      for (var i = 0, l = string.length; i < l; i += 1) {
        array[i] = string.charCodeAt(i);
      }
      return (array.buffer)
    }

    /*
     * Convert ArrayBuffer to string
     *
     * @param  {ArrayBuffer}        - Array Buffer with data from device
     * @return {JSON}               - JSON with data from device
     * @throws {errorBytesToString} - Error when try pass bytes to string
     */
    function bytesToString(buffer) {
      try {
        var string = String.fromCharCode.apply(null, new Uint8Array(buffer));
        var clearString = string.replace(/\n/g, '');
        return JSON.parse(clearString);
      } catch (error) {
        /*
         En algunas ocasiones, mientras estoy recibiendo datos, el WS puede cambiar de estado
         mezlando la respuesta, y generándose un JSON mal formado, enviamos un objeto vacío para
         que vuelva a realizar la petición, que ya estará bien.
         */
        return {};
      }
    }

    function disconnect() {
      // disconnect the mobile from webserver
      if (device.platform !== 'iOS') {
        WifiWizard2.disconnect().then(function (data) {
          console.log("Disconnected: ", data);
          waitUntilConnected();
        }).catch(function (err) {
          console.log(err);
          waitUntilConnected();
        });
      } else {
        waitUntilConnected();
      }
    };

    /*
     * Return Mac formatted, filling with zeros
     */
    function formatMac(mac) {
      var macFormatted = '';
      var array = mac.split(':');

      for (var i = 0; i < array.length; i++) {
        macFormatted += ('00' + array[i]).slice(-2);
        if (i < array.length - 1) {
          macFormatted += ':'
        }
      }

      return macFormatted.toUpperCase();
    }

    /**
     * @returns {Promise<string>}
     */
    function getMac() {
      // get list of the available networks as an array of objects - android only
      // if ($scope.devicePlatform === "Android") {
      //   WifiWizard2.scan().then(function (networks) {
      //     console.log("SCAN Success: ", networks);
      //     setBssidAndroid(networks);
      //   }).catch(function (fail) {
      //     console.log("SCAN Fail: ", fail);
      //     $scope.getMacRetries -= 1;
      //     if ($scope.getMacRetries > 0){
      //       getMac();
      //     }else{
      //       getMacError("Error getting current mac: ", fail);
      //     }
      //   });
      // } else {
      return WifiWizard2.getConnectedBSSID().then(function (bssid) {
        // $scope.bssid = formatMac(bssid);
        return formatMac(bssid);
      }).catch(function (err) {
        $q.reject(getMacError("Error getting current BSSID: ", err));
      });
      //}
    };


    // function setBssidAndroid(networks) {
    //   console.log("networks: " + JSON.stringify(networks));
    //   if (networks !== []) {
    //     // find current bssid in current network ssid
    //     networks.forEach(function (network) {
    //       console.log("Network: ", network.SSID);
    //       if (network.SSID === $scope.ssid_webserver) {
    //         $scope.bssid = formatMac(network.BSSID);
    //         console.log("bssid: ", $scope.bssid);
    //         /*$scope.wifi.level = network.level;
    //         $scope.wifi.frequency = network.frequency;
    //         $scope.wifi.config = network.capabilities; */
    //       }
    //     });
    //   } else {
    //     $timeout(function () {
    //       getMac();
    //     }, 2000);
    //   }
    // };


    /**
     * 
     * @param {string} ssid
     * @returns {Promise<string>} 
     */
    function ssidHandler(ssid) {
      if (ssid.indexOf("AZW") > -1) {
        // remove double quotes
        ssid = ssid.replace('"', '').replace('"', '');
        console.log("ssidHandler found: ", ssid);

        $scope.ssid_webserver = ssid;
        return getMac();
      }
      console.log("ssidHandler not found: ", ssid);
      $scope.find_ssid = false;
      $scope.$apply();
    };

    function fail(e) {
      $scope.find_ssid = false;
      $scope.$apply();
    }

    /*
     * Go to previous state
     */
    $scope.goToPreviousState = function () {
      $scope.error = false;
      $scope.error_mac_not_found = false;
      $scope.error_sleeping = false;
      $scope.error_not_connected = false;

      if ($scope.state === 15) {
        $scope.state = 1;
      }

      if ($scope.state === 9) {
        $scope.state = 1;
      }

      if ($scope.state > 1 && !($scope.state == 4 && $scope.auto_search) && $scope.state != 9 && $scope.state != 12 && $scope.state != 2) {
        $scope.state = $scope.state - 1;
      }

      if ($scope.state === 12) {
        $scope.state = 10;
      }

      if ($scope.state === 2) {
        $scope.state = 9;
      }

      if ($scope.state <= 5) {
        $scope.routers = [];
        $scope.auto_search = false;
        $scope.loading1 = false;
      }
    }

    /*
     * Show message of the error getting mac
     */
    function getMacError(message, err) {
      $scope.error = true;
      $scope.error_mac_not_found = true;
      console.log(message, err);
    };

    /*
     * Get Mac
     *
    function getMac(){
      // Request mac
      console.log('Delay 0.5 seconds');
      $.ajax({
        type: "post",
        url:  "http://192.168.2.25/sendMAC.cgi",
        crossDomain: true,
        dataType: 'xml',
      }).success(
        function(data) {
          console.log(data);
          var x2js = new X2JS();
          var jsonObj = x2js.xml2json( data );
          $scope.mac = jsonObj.data.mac;
          $scope.find_ssid = true;
          $scope.new_ws = true;
          $timeout.cancel($scope.timer_send_mac); 
          $interval.cancel($scope.getMacInterval);
          $scope.setState(4);
        }
      ).error(
        function(error){
          console.log(error);
          if ($scope.getMacInterval){
            $interval.cancel($scope.getMacInterval);
          }
          $scope.find_ssid = true;
          $scope.setState(4);
        }
      );
    };*/

    /*
     * Select a router and continue
     */
    $scope.selectRouterBluetooth = function (router) {
      if (router.rssi > -70) {
        $scope.router = router;
        $scope.router.name = $scope.getUnescapeString($scope.router.ssid);
        $scope.manual_router = false;
        $scope.state = 12;
      } else {
        $scope.low_signal = true;
      }
    }

    /*
     * Select a router and continue
     */
    $scope.selectRouter = function (router) {
      if (router._rssi > 180) {
        $scope.router = router;
        if (router._seguridad == 'NO') {
          $scope.router._seguridad = 'NONE';
          $scope.state = 6;
        } else {
          $scope.manual_router = false;
          $scope.state = 5;
        }
      }
      else {
        $scope.low_signal = true;
      }
    }

    /*
     * Select manual router and continue
     */
    $scope.selectManualRouter = function (router) {
      if ($scope.router._name && $scope.router._seguridad) {
        $scope.manual_router = true;
        $scope.state = 5;
      }
    }

    /*
     * get the signal class of bluetooth to show in the page
     */
    $scope.getClassBluetoothSignal = function (signal) {
      switch (true) {
        case signal < -70:
          return "icon-signal-low";
        case signal < -65:
          return "icon-signal-mean";
        case signal < -60:
        default:
          return "icon-signal-good";
      }
    }

    /*
     * get the class to show in the page
     */
    $scope.getClassConnection = function (connection) {
      if (connection < 180)
        return "icon-signal-none";
      else if (connection < 190)
        return "icon-signal-low";
      else if (connection < 200)
        return "icon-signal-mean";
      else if (connection < 210)
        return "icon-signal-good";
      else
        return "icon-signal-excelent";
    };

    /*
     * Set the connection type
     */
    $scope.setConnectionType = function (type) {
      $scope.connection = {
        ip: '',
        port: '',
        masc: ''
      }
      if (type == 'dhcp') {
        $scope.typeDhcp = true;
      } else {
        $scope.typeDhcp = false;
      }
    };

    $scope.setConnectionBluetooth = function () {
      $scope.loading2 = true;
      var staticIP = ""
      if (!$scope.typeDhcp) {
        staticIP = ',"ip":"' + $scope.connection.ip + '","mask":"' + $scope.connection.mask + '","gateway":"' + $scope.connection.gateway + '"';
      }
      var command = '{"wifi":{"ssid":"' + $scope.router.name +
        '","bssid":"' + $scope.router.bssid + '","password":"' + $scope.router.password +
        '"},"network":{"dhcp":' + Number($scope.typeDhcp) + staticIP + '},"cmd":"connect"}'
      $scope.iterator = 0;
      writeDevice($scope.device_id, command, 'connectDeviceToWifi');
    }
    /*
     * Get the access points
     */
    $scope.getAccessPoints = function () {
      $scope.loading1 = true;
      $scope.auto_search = true;

      // Get info of router
      const options = {
        method: 'post',
        data: { user: 'root', pass: 'airzone' },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        serializer: 'urlencoded',
      };

      // Avoid issue CORS in http requests for iOS platform
      cordova.plugin.http.sendRequest('http://192.168.2.25/puntosdeacceso.cgi', options, function (response) {
        console.log('response: ', response);
        $scope.loading1 = false;
        var x2js = new X2JS();
        console.log('data: ', response.data);
        var jsonObj = x2js.xml_str2json(response.data);
        console.log('jsonObj: ', jsonObj);
        $scope.routers = jsonObj.PuntosAcceso.Punto;

        if ($scope.routers) {
          if (Object.prototype.toString.call($scope.routers) != '[object Array]')
            $scope.routers = [$scope.routers];
        }
        else {
          $scope.routers = [];
        }

        // Apply the changes in variables in the same view
        $scope.$apply();

        return true;
      }, function (response) {
        //prints Permission denied
        console.log(response.error);
        if (!$scope.manual_router) {
          if ($scope.state == 4)
            $scope.error = true;
          $scope.loading1 = false;
          $scope.$apply();
        }
      });
    };

    /*
     * Set connection parameters
     */
    $scope.setConnection = function () {
      $scope.loading2 = true;

      var data = {};
      if ($scope.typeDhcp) {
        data = "user=root&pass=airzone&dhcp=si";
      }
      else {
        data = "user=root&pass=airzone&ip=" + $scope.connection.ip + "gw=" + $scope.connection.port;
      }

      const options = {
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'text/plain' },
        serializer: 'utf8',
      };
      // send data as plain UTF8 encoded string in body
      // default content type "plain/text"
      // data must be a String
      // cordova.plugin.http.setDataSerializer('utf8');
      // Avoid issue CORS in http requests for iOS platform
      cordova.plugin.http.sendRequest('http://192.168.2.25/modconfred.cgi', options, function (response) {
        console.log("modconfred response: ", response);
        setAccessPoint();
      }, function (response) {
        console.log("Error modconfred: ", response);
        $scope.loading2 = false;
        $scope.error = true;
        $scope.$apply();
      });
    };

    function setAccessPoint() {
      if (!$scope.router._seguridad)
        $scope.router._seguridad = 'NONE';

      var queryStringParams = 'user=root&pass=airzone&seguridad=' + $scope.router._seguridad;
      queryStringParams = queryStringParams + '&punto=' + encodeURI($scope.router._name);
      queryStringParams = queryStringParams + '&passWIFI=' + encodeURI($scope.router._password);

      var url = 'http://192.168.2.25/asocpuntoacc.cgi';

      const options = {
        method: 'post',
        data: queryStringParams,
        headers: { 'Content-Type': 'text/plain' },
        serializer: 'utf8',
      };

      // cordova.plugin.http.setDataSerializer('utf8');
      // Avoid issue CORS in http requests for iOS platform
      cordova.plugin.http.sendRequest(url, options, function (response) {
        console.log("asocpuntoacc response: ", response);
      }, function (response) {
        console.log("Error asocpuntoacc: ", response);
      });

      $timeout(function () {
        $scope.loading2 = false;
        $scope.state = 7;
        //$scope.$apply();
        $scope.error = false;
      }, 10000);
    }

    $scope.retry = function () {
      if ($scope.state === 14) {
        $scope.error = false;
        $scope.error_not_connected = false;
        $scope.setState(9);
      } else if ($scope.state === 4) {
        $scope.loading1 = true;
        $scope.getAccessPoints();
      } else {
        $scope.setState(3);
      }

      $scope.error = false;
      $scope.error_mac_not_found = false;
      $scope.error_sleeping = false;
      $scope.error_not_connected = false;
    };

    $scope.toggleAdvanced = function () {
      $scope.show_advanced = !$scope.show_advanced;
      $scope.connection.port = '';
    };

    $scope.goManualRouter = function () {
      $scope.router = {};
      $scope.manual_router = true;
      $scope.state = 5;
      $scope.error = false;
      $scope.router._seguridad = 'WPA';
    };

    $scope.closeLowSignal = function () {
      $scope.low_signal = false;
    }

    /*
     * Get check connected device
     */
    $scope.check_connected_device = function () {
      // Sign in for get token
      $scope.user_email = 'user1@email.com';
      var data = {
        email: $scope.user_email,
        password: 'foobarfoo'
      };

      $http({
        method: "POST",
        url: URL_PATH + "users/sign_in",
        data: data,
        headers: { "Content-Type": 'application/json' }
      }).success(function (data) {
        if (data && data.user) {
          $scope.user_token = data.user.authentication_token;
          // Request check connected device
          $http({
            method: "GET",
            url: URL_PATH + "devices?mac=" + $scope.bssid + "&user_email=" + $scope.user_email + "&user_token=" + $scope.user_token + "&format=json",
            headers: { "Content-Type": 'application/json' },
            timeout: 5000
          }).success(function (data) {
            // If return data
            if (data && data.devices.length > 0) {
              var object = data.devices[0];
              // Check connection date should be less than 4 minutes in miliseconds
              if (object.connection_date && (moment() - moment(object.connection_date) < 240000)) {
                finishLoading();
              } else {
                if ($scope.retries > 0) {
                  $scope.retries -= 1;
                  console.log("Not connected, trying again... Retry: ", $scope.retries);
                } else if ($scope.retries === 0) {
                  $scope.error_not_connected = true;
                  $scope.error = true;
                  finishLoading();
                }
              }
              // If data is false, it isn't provisioned
            } else if (data && data.devices.length === 0) {
              console.log("check_connected_device Data: ", data)
              $scope.error_sleeping = true;
              $scope.error = true;
              finishLoading();
            }
          }).error(function (error) {
            $scope.error = true;
            finishLoading();
          });
        }
      }).error(function (error) {
        console.log(error);
        waitUntilConnected();
      });
    };

    /*
     * Finish loading and checks
     */
    function finishLoading() {
      $scope.loading1 = false;
      if ($scope.checkConnectionDevice) {
        $interval.cancel($scope.checkConnectionDevice);
        $scope.iterator = 20;
      }
      //$scope.$apply();
    }

    /*
     * Finish loading and checks
     */
    function finishLoadingBluetooth(dev_id) {
      $scope.loading1 = false;
      if ($scope.checkConnectionDeviceInterval) {
        $interval.cancel($scope.checkConnectionDeviceInterval);
        delete $scope.checkConnectionDeviceInterval;
        $scope.iterator = 20;
        disconnectBluetooth(dev_id);
      }
      $scope.$apply();
    }

    /*
     * Wait 10 seconds and check the Webserver connection to cloud
     * Try 6 times to request to cloud
     */
    function waitUntilConnected() {
      $scope.iterator = 0;
      $scope.checkConnectionDevice = $interval(
        function () {
          // Check every 10 seconds for 3 minutes
          if ($scope.iterator < 18) {
            $scope.iterator += 1;
            $scope.check_connected_device();
          }
        }, 10000);
    }

    function checkConnectedDevice() {
      if (!$scope.checkConnectionDeviceInterval) {
        $scope.checkConnectionDeviceInterval = $interval(
          function () {
            // Check every 5 seconds for 50 seconds
            if ($scope.checkConnectedDeviceIterator < 10) {
              $scope.checkConnectedDeviceIterator += 1;
              isBluetoothEnabled();
              isLocationEnabled();
              isLocationAvailable();
              connectDevice($scope.device_id);
              console.log('write => {"cmd":"info"}');
              writeDevice($scope.device_id, '{"cmd":"info"}', 'getDeviceInfo')
            } else {
              console.log("Finish check the connection of device to cloud");
              $interval.cancel($scope.checkConnectionDeviceInterval);
              delete $scope.checkConnectionDeviceInterval;
            }
          }, 5000);
      }
    }

    /*
     * Pattern function
     */
    $scope.macPattern = function (e) {
      var r = /([a-f0-9]{2})([a-f0-9]{2})/i,
        str = e.target.value.replace(/[^a-f0-9]/ig, "");

      while (r.test(str)) {
        str = str.replace(r, '$1' + ':' + '$2');
      }

      e.target.value = (str.slice(0, 17)).toUpperCase();
      $scope.server.mac = e.target.value;
    };

    /*
     * Check if device isn't sleeping and get kind of device (wifi o bluetooth)
     */
    $scope.lookForWS = function (form) {
      $scope.checkServerFormError = false;
      $scope.error_mac_not_found = false;
      $scope.error_pin_invalid = false;
      $scope.error_sleeping = false;
      $scope.checkServerForm = form;
      if ($scope.checkServerForm.$valid) {
        console.log($scope.server.mac);
        // Sign in for get token
        $scope.user_email = 'user1@email.com';
        var data = {
          email: $scope.user_email,
          password: 'foobarfoo'
        };

        $http({
          method: "POST",
          url: URL_PATH + "users/sign_in",
          data: data,
          headers: { "Content-Type": 'application/json' }
        }).success(function (data) {
          if (data && data.user) {
            $scope.user_token = data.user.authentication_token;
            // Request check connected device
            $http({
              method: "GET",
              url: URL_PATH + "devices?mac=" + $scope.server.mac + "&user_email=" + $scope.user_email + "&user_token=" + $scope.user_token + "&format=json",
              headers: { "Content-Type": 'application/json' },
              timeout: 5000
            }).success(function (response) {
              // If return data
              if (response && response.devices.length > 0) {
                var object = response.devices[0];
                // Check connection date should be less than 4 minutes in miliseconds
                if (object.mac === $scope.server.mac) {
                  if (object.pin === $scope.server.pin) {
                    if (object.is_bluetooth) {
                      $scope.checkBluetooth();
                    } else {
                      $scope.setState(2);
                    }
                  } else {
                    console.log("looking for ws (pin invalid) Data: ", data)
                    $scope.error_pin_invalid = true;
                    $scope.checkServerFormError = true;
                  }
                } else {
                  console.log("looking for ws (mac not found) Data: ", data)
                  $scope.error_mac_not_found = true;
                  $scope.checkServerFormError = true;
                }
                // If data is false, it isn't provisioned
              } else if (response && response.devices.length === 0) {
                console.log("looking for ws (sleeping) Data: ", response)
                $scope.error_sleeping = true;
                $scope.checkServerFormError = true;
              }
            }).error(function (error) {
              console.log("Error get devices:", error);
              $scope.checkServerFormError = true;
            });
          }
        }).error(function (error) {
          console.log("Error post sign_in:", error);
          $scope.checkServerFormError = true;
        });
      } else {
        $scope.checkServerForm.invalid = true;
      }
    };

    /*
     * Close modal on destroy
     */
    $scope.$on('$destroy', function () {
      if ($scope.getCurrentSSID) {
        $interval.cancel($scope.getCurrentSSID);
      }

      if ($scope.getDeviceDataInterval) {
        $interval.cancel($scope.getDeviceDataInterval);
      }

      if ($scope.checkConnectionDeviceInterval) {
        $interval.cancel($scope.checkConnectionDeviceInterval);
      }

      if ($scope.checkConnectionDevice) {
        $interval.cancel($scope.checkConnectionDevice);
      }

    });

  }]);
