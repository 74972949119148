Airzone.service('scheduleService', ['$http', '$q', '$rootScope', 'sessionService', '$state',
  function ($http, $q, $rootScope, sessionService, $state) {
    return ({
      getSchedules: getSchedulesFromServer,
      getSchedule: getScheduleById,
      replaceSchedule: replaceSchedule
    });

    /*
     * Get schedules from server or rootScope
     *
     * @param boolean forceUpdate - Force ajax request and not return $rootScope result
     */
    function getSchedulesFromServer(model, forceUpdate) {
      forceUpdate = typeof forceUpdate !== 'undefined' ? forceUpdate : false;

      // search for schedules of device in rootScope
      if ($rootScope && $rootScope.schedules && !forceUpdate) {
        if (model.class == 'System')
          var schedules = $rootScope.schedules.filter(function (x) { return x.system_id == model.id; });
        else if (model.class == 'Zone')
          schedules = $rootScope.schedules.filter(function (x) { return x.zone_id == model.id; });

        if (schedules && schedules.length > 0) {
          return $q.when(schedules);
        }
      }

      // define the url to get schedules by system or zone_id
      var request = $http({
        method: "get",
        url: url_path + "schedules/",
        params: {
          user_email: sessionService.email(),
          user_token: sessionService.token(),
          format: 'json',
          system_id: (model && model.class == 'System' ? model.id : null),
          zone_id: (model && model.class == 'Zone' ? model.id : null)
        }
      });

      return (request.then(handleIndexSuccess, handleError));
    };

    /*
     * Get a schedule given an id
     *
     * @params string id - Id of the schedule
     */
    function getScheduleById(id) {
      if ($rootScope.schedules) {
        var schedules = $rootScope.schedules.filter(function (x) { return x.id == id });

        if (schedules.length > 0) {
          return $q.when(schedules[0]);
        }
      }
      var request = $http({
        method: "get",
        url: url_path + "schedules/" + id,
        responseType: 'json',
        params: {
          user_email: sessionService.email(),
          user_token: sessionService.token(),
        },
        data: {
          id: id
        }
      });

      return (request.then(handleGetSuccess, handleError));
    };

    function handleIndexSuccess(response) {
      if (!$rootScope.schedules) {
        $rootScope.schedules = [];
      }

      $rootScope.schedules.push.apply($rootScope.schedules, response.data['schedules']);
      return (response.data['schedules']);
    };

    function handleGetSuccess(response) {
      var schedule = response.data['schedule'];

      if (!$rootScope.schedules) {
        $rootScope.schedules = [schedule];
      }
      else if ($rootScope.schedules.indexOf(schedule) == -1) {
        $rootScope.schedules.push(schedule);
      }

      return schedule;
    };


    function handleError(response) {
      // Logout when unauthorized
      if (response.status == 401) {
        sessionService.unauthorize();
      } else if (response.status == 403) {
        $state.go('init.403');
        return ($q.reject("An unknown error occurred."));
      } else if (response.status == 404) {
        $state.go('init.404');
        return ($q.reject("An unknown error occurred."));
      }

      if (!angular.isObject(response.data) || !response.data.message) {
        return ($q.reject("An unknown error occurred."));
      }

      return ($q.reject(response.data.message));
    };

    function getDatabaseSchedule(id) {
      if ($rootScope.schedules) {
        var schedules = $rootScope.schedules.filter(function (x) { return x.id == id });

        if (schedules.length > 0) {
          return schedules[0];
        }
      }

      return false;
    };

    /*
     * Update schedule with new schedule fields values
     */
    function replaceSchedule(schedule) {
      var db_schedule = getDatabaseSchedule(schedule.id);

      if (db_schedule) {
        if (!db_schedule.last_field || schedule[db_schedule.last_field] == db_schedule.last_value) {
          Object.keys(schedule).forEach(function (key) {
            db_schedule[key] = schedule[key];
          });

          db_schedule.updated = true;
          db_schedule.last_field = false;
          $rootScope.$apply();
        }
      };
    };
  }]);
