Airzone.directive('systemRow', function () {
  return {
    restrict: 'E',
    scope: {
      sys: '=system',
      server: '=server',
      ind: '=index',
      relation: '=relation'
    },
    controller: 'systemRowController',
    templateUrl: template_path + 'system_row.html'
  };
});
