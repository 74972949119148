Airzone.controller('modalSchedulesController',
  ['$scope', '$rootScope', 'zoneService', 'eventsService', 'scheduleService', '$timeout', 'SystemUtilsService',
    function ($scope, $rootScope, zoneService, eventsService, scheduleService, $timeout, SystemUtilsService) {

      $scope.showHelp = false;
      $scope.modes = ["stop", "cool-air", "heat-radiant", "ventilate", "heat-air", "heat-both", "dehumidify", "not_exit", "cool-radiant", "cool-both"];
      $scope.schedule_modes = ["", "stop", "ventilate", "cool-air", "heat-air", "heat-radiant", "heat-both", "dehumidify", "cool-radiant", "cool-both"];
      $scope.velocities = ["0", "1", "2", "3"];
      $scope.available_scenes = ["stop", "confort", "unocupied", "night", "eco", "vacation"];

      $scope.show_view = 'list';
      $scope.class_modal_mode;
      $scope.available_modes = [];
      $scope.error_day = false;
      $scope.block_update = false;
      $scope.not_firm_ws = true;
      $scope.available_vla = ['0', '1', '2'];

      // Prevent bottom space when keyboard hides
      $scope.scrollToTop = function($event){
        window.scrollTo(0, 0);
      }

      /*
       * Open modal ZBS schedules
       */
      $scope.listener3 = $rootScope.$watch('zbs_schedules_target', function (new_target) {
        if (new_target)
          initializeZBS(new_target);
      });

      /*
       * Refresh the list of ZBS schedules
       */
      function initializeZBS(new_target) {
        $scope.show_view = 'zbs';
        $scope.config = new_target.config;
        $scope.target = new_target;
        $scope.schedules = null;
        $scope.virtual_schedule = null;
        $scope.show_vla_type = $scope.hasVLA();
        $scope.not_firm_ws = false;

        if (new_target.temperature_unit !== undefined)
          $scope.unit = parseInt(new_target.temperature_unit);

        $scope.show = true;
        $rootScope.modalShow = true;

        $scope.info = {
          device_id: $scope.target.device_id,
          system_number: $scope.target.system_number,
          zone_number: $scope.target.zones[0].zone_number
        };

        eventsService.createEvent('infoprog', $scope.info, null, null);
      };

      /*
       * Seek free schedule number
       */
      function seekFreeScheduleNumber() {
        if ($scope.schedules.length == 0)
          $scope.last_schedule_number = 1;
        else {
          var i = 0;
          var not_found = true;
          while (i < $scope.schedules.length && not_found) {
            if ((i + 1).toString() != $scope.schedules[i].schedule_number) {
              $scope.last_schedule_number = i + 1;
              not_found = false;
            }
            i += 1;
          }

          if (not_found)
            $scope.last_schedule_number = parseInt($scope.schedules[$scope.schedules.length - 1].schedule_number) + 1;
        }
      }

      /*
       * Change the view of the modal to new ZBS Schedule
       */
      $scope.changeToNewZBSView = function () {
        seekFreeScheduleNumber();

        var n_name = parseInt($scope.last_schedule_number);

        if (n_name < 10)
          n_name = '0' + n_name;

        $scope.virtual_schedule = {
          enabled: '1',
          mode: null,
          final_mode: null,
          temp: 40,
          vla: '3',
          final_temp: null,
          cold_temp: ($scope.target.temperature_unit == '1' ? 75 : 24.0),
          heat_temp: ($scope.target.temperature_unit == '1' ? 73 : 23.0),
          start_hour: '00',
          start_minute: '00',
          end_hour: '23',
          end_minute: '59',
          days: ['0', '0', '0', '0', '0', '0', '0'],
          sleep_prog_mode: undefined,
          modes: null,
          name: 'PROG. ' + n_name,
          zone_number: $scope.info.zone_number,
          system_number: $scope.info.system_number,
          device_id: $scope.info.device_id,
          schedule_number: parseInt($scope.last_schedule_number),
          vent: null,
          final_vent: null,
          speed: null,
          final_speed: null,
          all_zones: true,
          zones: $scope.target.zones,
          scene: 1,
          selected_zones: [],
          disable_last_selected_zone: []
        };

        if ($scope.target.autochange_differential !== undefined) {
          //ZBS
          var autochange = ($scope.target.temperature_unit == '1') ? $scope.target.autochange_differential : ($scope.target.autochange_differential / 2.0);
          if ($scope.virtual_schedule.cold_temp < ($scope.virtual_schedule.heat_temp + autochange)) {
            $scope.virtual_schedule.cold_temp = $scope.virtual_schedule.heat_temp + autochange;
          }
        }

        // Initialize selected zones
        for (var i in $scope.virtual_schedule.zones) {
          $scope.virtual_schedule.selected_zones[i] = true;
          $scope.virtual_schedule.disable_last_selected_zone[i] = false;
        };

        // Prepare views
        $scope.new_edit_type = 'new_zbs';
        $scope.show_view = 'new_zbs';
        $scope.not_firm_ws = false;

        $scope.show_temp = true;
        $scope.show_power = false;
        $scope.show_scene = false;
        $scope.show_vla = false;
        $scope.block_update = false;
      };

      /*
       * Get Scene from class
       */
      $scope.getSceneClass = function (class_scene) {
        if (class_scene != null) {
          // Check ventilation on/off scene type
          if (parseInt(class_scene) == 6) {
            return "vla-on";
          }else if (parseInt(class_scene) == 7) {
            return "vla-off";
          }

          return $scope.available_scenes[parseInt(class_scene)];
        }
      };

      /*
       * Change Power
       */
      $scope.togglePower = function () {
        if ($scope.virtual_schedule.temp === 40)
          $scope.virtual_schedule.temp = 45;
        else
          $scope.virtual_schedule.temp = 40;
      };

      /*
       * Open modal
       */
      $scope.listener1 = $rootScope.$watch('schedules_target', function (new_target) {
        if (new_target) {
          initialize(new_target);
          refreshList(new_target);
        }
      });

      /*
       * Refresh the list of schedules
       */
      function initialize(new_target) {
        // Show if there are new target and is schedule modal
        if (new_target) {
          $scope.name = new_target.name;
          $scope.config = new_target.config;
          $scope.target = new_target;
          $scope.schedules = null;
          $scope.virtual_schedule = null;
          $scope.last_schedule_number = -1;

          $scope.show = true;
          $rootScope.modalShow = true;

          $scope.info = {
            device_id: new_target.device_id,
            system_number: new_target.system_number,
            zone_number: (new_target.class == 'System' ? '127' : new_target.zone_number)
          };

          // Demo
          if (new_target != undefined) {
            $timeout(function () {
              if ($rootScope.demo)
                $rootScope.update_schedules = true;
            }, 1000);
          }

          // Show new in system schedule and list in schedules of zones
          if ($scope.target.class == 'System')
            $scope.changeToNewView();
          else
            $scope.show_view = 'list';
        };
      };

      /*
       * Refresh the list of schedules
       */
      function refreshList(new_target) {
        if (new_target) {
          if (new_target.class == 'System') {
            $scope.target.prog_enabled = '1';
            $scope.target_type = 'System';
            zoneService.getZones($scope.target.id).then(function (data) {
              $scope.zones_next_num = data;
              $rootScope.update_schedules = true;
            }, function () {
              $scope.close();
            });
          }
          else if (new_target.class == 'Zone') {
            $scope.target.master = new_target.master;
            $scope.target_type = 'Zone';
            eventsService.createEvent('infoprog', $scope.info, null, null);
          }

          if (parseFloat($rootScope.firm_ws) >= 3.0) {
            $scope.modes = ["stop", "cool", "heat", "ventilate", "heat", "heat", "dehumidify", "not_exit", "cool", "cool"];
            $scope.schedule_modes = ["", "stop", "ventilate", "cool", "heat", "heat", "heat", "dehumidify", "cool", "cool"];
            $scope.changeNotHaveEndHour();
          }
        }
      }

      $scope.listener2 = $rootScope.$watch('update_schedules', function (new_value, old_value) {
        if (!$scope.block_update && new_value != old_value && new_value) {
          scheduleService.getSchedules($scope.target, true).then(function (data) {
            $scope.schedules = data.sort(function (a, b) { return a['schedule_number'] - b['schedule_number']; });

            // Define last free schedule number
            var exit = false;
            for (var i = 0; i < 24 && !exit; i++) {
              var exist = false;
              data.forEach(function (value) {
                if (i.toString() == value.schedule_number)
                  exist = true;
              });
              if (!exist) {
                $scope.last_schedule_number = (i - 1).toString();
                exit = true;
              }
            }
          }, function (error) {
            console.error(error);
          });
        }

        $rootScope.update_schedules = false;
      });

      /*
       * Enable or disable schedules
       */
      $scope.$watch('target.prog_enabled', function (new_value, old_value) {
        if (new_value != old_value && old_value != null && new_value) {
          $scope.target.last_field = 'prog_enabled'
          $scope.target.last_value = $scope.target.prog_enabled;

          if ($scope.prog_enabled_timeout) {
            clearTimeout($scope.prog_enabled_timeout);
          }

          $scope.prog_enabled_timeout = setTimeout(function () {
            eventsService.createEvent('modzona', $scope.info, 'prog_enabled', $scope.target.prog_enabled);
          }, 1000);
        }
      });

      /*
       * Toggle Schedule enable flag
       */
      $scope.toggleEnabled = function (schedule) {
        if ($scope.schedule_enabled_timeout) {
          clearTimeout($scope.schedule_enabled_timeout);
        }

        if (schedule.enabled === '0'){
          schedule.enabled = '1';
        }else if (schedule.enabled === '1'){
          schedule.enabled = '0';
        }
        $scope.schedule_enabled_timeout = setTimeout(function () {
          eventsService.createEvent('modprog', schedule, 'enabled', schedule.enabled);
          $scope.block_update = true;
        }, 1000);
      };

      /*
       * Translate strint 't'
       * Solved bug (can't call $rootScope directly)
       */
      $scope.i18n = function (t) {
        return $rootScope.i18n(t);
      };

      /*
       * Called when close modal (click out)
       */
      $scope.close = function () {
        if ($scope.show_view == 'list' || $scope.target.class == 'System') {
          $scope.schedules = null;
          $scope.target = null;
          $scope.show = false;
          $scope.show_view = 'list';
          $rootScope.modalShow = false;
          $rootScope.schedules_target = null;
          $rootScope.zbs_schedules_target = null;
          $scope.error_day = false;
          $scope.block_update = false;
        }
      };

      /*
       * Get mode class
       */
      $scope.getModeClass = function (class_mode) {
        if (class_mode != null)
          return $scope.schedule_modes[parseInt(class_mode)];
      }

      /*
       * Check if ws has ventilation so show the ventilation schedule type
       */
      $scope.hasVLA = function () {
        var show_vla_type = false;

        // Show ventilation schedule if almost one zone has ventilation
        $scope.target.zones.forEach(function(zone){
          if (zone.vla !== null){
            show_vla_type = true;
          }
        })

        return show_vla_type;
      }

      /*
       * Change the view of the modal
       */
      $scope.changeToNewView = function () {
        var n_name = parseInt($scope.last_schedule_number) + 2;

        if (n_name < 10)
          n_name = '0' + n_name;

        $scope.virtual_schedule = {
          enabled: '1',
          mode: (!$scope.haveModes() ? null : '1'),
          final_mode: (!$scope.haveModes() ? null : '1'),
          temp: (!$scope.haveModes() ? 20.0 : null),
          final_temp: (!$scope.haveModes() ? 21.0 : null),
          start_hour: '00',
          start_minute: '00',
          end_hour: '23',
          end_minute: '59',
          days: ['0', '0', '0', '0', '0', '0', '0'],
          sleep_prog_mode: undefined,
          modes: $scope.target.modes,
          name: 'PROG. ' + n_name,
          zone_number: $scope.info.zone_number,
          system_number: $scope.info.system_number,
          device_id: $scope.info.device_id,
          schedule_number: (parseInt($scope.last_schedule_number) + 1),
          vent: null,
          final_vent: null,
          speed: haveSpeedSchedules($scope.target),
          final_speed: haveSpeedSchedules($scope.target),
          all_zones: true,
          zones: $scope.target.zones
        };

        // Define availables modes
        $scope.available_modes = [];
        angular.forEach([0, 3, 1, 8, 9, 4, 2, 5, 6, 13, 7], function (value) {
          if ($scope.virtual_schedule.modes && $scope.virtual_schedule.modes[value] == 1 && $scope.available_modes.indexOf($scope.modes[value]) < 0)
            $scope.available_modes.push($scope.modes[value]);
        });

        // Prepare zones for system schedule
        if ($scope.target.class == 'System')
          angular.forEach($scope.virtual_schedule.zones, function (value) {
            value.selected_for_sche = true;
          });

        // Prepare views
        $scope.new_edit_type = 'new';
        $scope.show_view = 'new';
        if (parseFloat($rootScope.firm_ws) >= 3.0)
          $scope.not_firm_ws = false;
        else
          $scope.virtual_schedule.haveEndHour = ($scope.virtual_schedule.end_hour != null);

        $scope.show_mode = $scope.haveModes();
        $scope.block_update = false;
      };

      /*
       * Define if a zone or system can have speed schedules
       */
      function haveSpeedSchedules(target) {
        if (target.class == 'Zone') {
          if ($scope.haveModes() && target.speed_prog_mode == '1')
            return '0';
          else if (!$scope.haveModes() && target.speed_prog_mode == '0')
            return '0';
          else
            return null;

        } else if (target.class == 'System') {
          // Check if system zones have speed_prog_mod
          var speed = null;
          angular.forEach(target.zones, function (value) {
            if (value.speed_prog_mode != null)
              speed = value.speed_prog_mode;
          });

          $scope.target.speed_prog_mode = speed;
          // check if the system have zones
          if ($scope.haveModes() && speed == '1')
            return '0';
          else if (!$scope.haveModes() && speed == '0')
            return '0';
          else
            return null;
        }
      };

      /*
       * Change to edit ZBS view
       */
      $scope.changeToEditZBSView = function (schedule) {
        // Initialize views
        $scope.show_scene = false;
        $scope.show_power = false;
        $scope.show_temp = false;
        $scope.show_vla = false;

        $scope.virtual_schedule = {
          zone_number: schedule.zone_number,
          system_number: schedule.system_number,
          enabled: schedule.enabled,
          mode: schedule.mode,
          final_mode: schedule.final_mode,
          temp: schedule.temp,
          vla: schedule.vla,
          final_temp: schedule.final_temp,
          cold_temp: schedule.cold_temp,
          heat_temp: schedule.heat_temp,
          start_hour: schedule.start_hour,
          speed: schedule.speed,
          final_speed: schedule.final_speed,
          start_minute: schedule.start_minute,
          end_hour: schedule.end_hour,
          end_minute: schedule.end_minute,
          days: schedule.days,
          sleep_prog_mode: schedule.sleep_prog_mode,
          name: schedule.name,
          modes: schedule.modes,
          scene: schedule.scene,
          zones: $scope.target.zones,
          selected_zones: Array($scope.target.zones.length).fill(false),
          device_id: schedule.device_id,
          schedule_number: schedule.schedule_number,
          all_zones: true,
          disable_last_selected_zone: []
        };

        //schedule.selected_zones viene en formato string binario, donde cada posición es una zona => se cambia al que se usa aqui
        for (var i = 0; i < $scope.virtual_schedule.zones.length; i++) {
          var index = parseInt($scope.virtual_schedule.zones[i].zone_number) - 1;
          if (schedule.selected_zones[index] === "1")
            $scope.virtual_schedule.selected_zones[i] = true;
        }

        // Normalize hour
        if ($scope.virtual_schedule.start_hour.length == 1)
          $scope.virtual_schedule.start_hour = '0' + $scope.virtual_schedule.start_hour
        if ($scope.virtual_schedule.start_minute.length == 1)
          $scope.virtual_schedule.start_minute = '0' + $scope.virtual_schedule.start_minute
        if ($scope.virtual_schedule.vla)
          $scope.show_vla = true;
        else if ($scope.virtual_schedule.scene)
          $scope.show_scene = true;
        if ($scope.virtual_schedule.cold_temp && $scope.virtual_schedule.heat_temp)
          $scope.show_temp = true;
        if ($scope.virtual_schedule.temp)
          $scope.show_power = true;

        // Initialize params if not exist
        if (!$scope.virtual_schedule.scene)
          $scope.virtual_schedule.scene = 1;
        if (!$scope.virtual_schedule.temp)
          $scope.virtual_schedule.temp = 40;
        if (!$scope.virtual_schedule.cold_temp)
          $scope.virtual_schedule.cold_temp = ($scope.target.temperature_unit == '1' ? 75 : 24.0);
        if (!$scope.virtual_schedule.heat_temp)
          $scope.virtual_schedule.heat_temp = ($scope.target.temperature_unit == '1' ? 73 : 23.0);

        // Prepare zones for system schedule
        var enabled_zones = 0;
        for (i in $scope.virtual_schedule.zones) {
          $scope.virtual_schedule.disable_last_selected_zone[i] = false;
          if ($scope.virtual_schedule.selected_zones[i] == "1") {
            $scope.virtual_schedule.selected_zones[i] = true;
            enabled_zones += 1;
          } else {
            $scope.virtual_schedule.all_zones = false;
            $scope.virtual_schedule.selected_zones[i] = false;
          }
        };
        $scope.virtual_schedule.selected_zones = $scope.virtual_schedule.selected_zones.slice(0, $scope.virtual_schedule.zones.length);

        if (enabled_zones == 1)
          $scope.virtual_schedule.disable_last_selected_zone[$scope.virtual_schedule.selected_zones.indexOf(true)] = true;

        // Prepare edit view
        if (!$scope.virtual_schedule.scene)
          $scope.virtual_schedule.scene = 1;
        $scope.new_edit_type = 'edit_zbs';
        $scope.show_view = 'new_zbs';
        $scope.show_mode = null;
        $scope.block_update = false;
      };

      /*
       * Change to edit view
       */
      $scope.changeToEditView = function (schedule) {
        $scope.virtual_schedule = {
          zone_number: schedule.zone_number,
          system_number: schedule.system_number,
          enabled: schedule.enabled,
          mode: schedule.mode,
          final_mode: schedule.final_mode,
          temp: schedule.temp,
          final_temp: schedule.final_temp,
          start_hour: schedule.start_hour,
          speed: schedule.speed,
          final_speed: schedule.final_speed,
          start_minute: schedule.start_minute,
          end_hour: schedule.end_hour,
          end_minute: schedule.end_minute,
          days: schedule.days,
          sleep_prog_mode: schedule.sleep_prog_mode,
          name: schedule.name,
          modes: schedule.modes,
          device_id: schedule.device_id,
          schedule_number: schedule.schedule_number,
          vent: null,
          final_vent: null
        };

        if (parseFloat($rootScope.firm_ws >= 3.0))
          $scope.changeNotHaveEndHour();

        // Normalize hour
        if ($scope.virtual_schedule.start_hour.length == 1)
          $scope.virtual_schedule.start_hour = '0' + $scope.virtual_schedule.start_hour
        if ($scope.virtual_schedule.start_minute.length == 1)
          $scope.virtual_schedule.start_minute = '0' + $scope.virtual_schedule.start_minute
        if ($scope.virtual_schedule.end_hour.length == 1)
          $scope.virtual_schedule.end_hour = '0' + $scope.virtual_schedule.end_hour
        if ($scope.virtual_schedule.end_minute && $scope.virtual_schedule.end_minute.length == 1)
          $scope.virtual_schedule.end_minute = '0' + $scope.virtual_schedule.end_minute

        // Define availables modes
        $scope.available_modes = [];
        angular.forEach([0, 3, 1, 8, 9, 4, 2, 5, 6], function (value) {
          if ($scope.virtual_schedule.modes && $scope.virtual_schedule.modes[value] == 1 && $scope.available_modes.indexOf($scope.modes[value]) < 0)
            $scope.available_modes.push($scope.modes[value]);
        });

        // Prepare edit view
        $scope.new_edit_type = 'edit';
        $scope.show_view = 'new';
        $scope.virtual_schedule.haveEndHour = ($scope.virtual_schedule.end_hour != '255');
        $scope.show_mode = $scope.haveModes();
        $scope.block_update = false;
      };

      /*
       * Show mode button
       */
      $scope.haveModes = function () {
        return !($scope.target.modes == null || angular.equals($scope.target.modes, "000000000") || $scope.target.class == 'System' || $scope.target.master == null);
      };

      /*
       * Get if show velocity selector
       */
      $scope.haveVentilation = function () {
        if ($scope.target.class == 'System') {
          var vent = false;

          // Check if a zone have ventilation
          angular.forEach($scope.target.zones, function (value) {
            if (value.show_ventilation == "1" && !$rootScope.firm_ws)
              vent = true;
          });

          return vent;
        }
        else if ($scope.target.class == 'Zone' && !$rootScope.firm_ws)
          return ($scope.target.show_ventilation == '1');
      };

      /*
       * Toggle all zones in system schedules
       */
      $scope.toggleAllZones = function () {
        $scope.virtual_schedule.all_zones = !$scope.virtual_schedule.all_zones;
        if ($scope.virtual_schedule.all_zones)
          angular.forEach($scope.virtual_schedule.zones, function (value) {
            value.selected_for_sche = true;
          });
      };

      /*
       * Toggle all ZBS zones in system schedules
       */
      $scope.toggleAllZBSZones = function () {
        $scope.virtual_schedule.all_zones = !$scope.virtual_schedule.all_zones;
        if ($scope.virtual_schedule.all_zones) {
          for (var i in $scope.virtual_schedule.selected_zones) {
            $scope.virtual_schedule.selected_zones[i] = true;
            $scope.virtual_schedule.disable_last_selected_zone[i] = false;
          };
        } else {
          for (var i in $scope.virtual_schedule.selected_zones) {
            $scope.virtual_schedule.selected_zones[i] = false;
          };
          $scope.virtual_schedule.selected_zones[0] = true;
          $scope.virtual_schedule.disable_last_selected_zone[0] = true;
        }
      };

      /*
       * Check last zone selected for restrict the disable
       */
      $scope.checkLastZoneSelected = function (index) {
        var enable_zone = 0;
        $scope.virtual_schedule.selected_zones[index] = !$scope.virtual_schedule.selected_zones[index];

        $scope.virtual_schedule.selected_zones.forEach(function (value, i) {
          $scope.virtual_schedule.disable_last_selected_zone[i] = false;
          if ($scope.virtual_schedule.selected_zones[i])
            enable_zone += 1;
        });

        if (enable_zone == 1) {
          $scope.virtual_schedule.disable_last_selected_zone[$scope.virtual_schedule.selected_zones.indexOf(true)] = true;
        }
      }

      /*
       * Change the value of ventilation
       */
      $scope.toggleVLA = function () {
        if ($scope.virtual_schedule.vla === '0')
          $scope.virtual_schedule.vla = "1";
        else
          $scope.virtual_schedule.vla = "0";
      }

      /*
       * Change the type of the ZBS schedule
       */
      $scope.changeZBSType = function (type) {
        if (type == 'scene') {
          if (Number($scope.virtual_schedule.scene) > 5) {
            $scope.virtual_schedule.scene = 1;
          }
          $scope.show_scene = true;
          $scope.show_temp = false;
          $scope.show_power = false;
          $scope.show_vla = false;
        } else if (type == 'temp') {
          $scope.show_scene = false;
          $scope.show_temp = true;
          $scope.show_power = false;
          $scope.show_vla = false;
        } else if (type == 'power') {
          $scope.show_scene = false;
          $scope.show_temp = false;
          $scope.show_power = true;
          $scope.show_vla = false;
        } else if (type == 'vla') {
          $scope.show_scene = false;
          $scope.show_temp = false;
          $scope.show_power = false;
          $scope.show_vla = true;
        }
      }

      /*
       * Change the type of the schedule
       */
      $scope.changeType = function (type) {
        if (type == 'mode') {
          $scope.show_mode = $scope.haveModes();
          $scope.virtual_schedule.mode = '1';
          $scope.virtual_schedule.final_mode = '1';
          $scope.virtual_schedule.temp = null;
          $scope.virtual_schedule.final_temp = null;
          $scope.virtual_schedule.vent = null;
          $scope.virtual_schedule.final_vent = null;
          if ($scope.target.speed_prog_mode == '1') {
            $scope.virtual_schedule.speed = '0';
            $scope.virtual_schedule.final_speed = '0';
          } else {
            $scope.virtual_schedule.speed = null;
            $scope.virtual_schedule.final_speed = null;
          }
        } else if (type == 'temp') {
          if ($scope.haveModes())
            $scope.show_mode = !$scope.show_mode;
          $scope.virtual_schedule.mode = null;
          $scope.virtual_schedule.final_mode = null;
          $scope.virtual_schedule.temp = 20.0;
          $scope.virtual_schedule.final_temp = 21.0;
          if ($scope.target.speed_prog_mode == '0') {
            $scope.virtual_schedule.speed = '0';
            $scope.virtual_schedule.final_speed = '0';
          } else {
            $scope.virtual_schedule.speed = null;
            $scope.virtual_schedule.final_speed = null;
          }
        } else if (type == 'vent') {
          $scope.virtual_schedule.mode = null;
          $scope.virtual_schedule.final_mode = null;
          $scope.virtual_schedule.temp = 13.5;
          $scope.virtual_schedule.final_temp = 13.5;
          $scope.virtual_schedule.speed = null;
          $scope.virtual_schedule.final_speed = null;
        }
      };

      /*
       * ToggleDays
       */
      $scope.toggleDays = function (day) {
        if ($scope.virtual_schedule.days[day] == '0')
          $scope.virtual_schedule.days[day] = '1';
        else
          $scope.virtual_schedule.days[day] = '0';
      };

      /*
       * Update target with vla selected in modal
       * @params {string} vla to change in the target
       */
      $scope.selectVLA = function (class_vla) {
        $scope.virtual_schedule.vla = class_vla;
        $scope.show_view = 'new_zbs';
      }

      /*
       * Launch select of hour
       */
      $scope.selectNew = function (type) {
        $scope.select_type = type;
        if (type == 'start_hour' || type == 'end_hour') {
          $scope.preview_show = 'new';
          $scope.show_view = 'hour';
        } else if (type == 'temp' || type == 'final_temp') {
          $scope.preview_show = 'new';
          $scope.show_view = 'temp';
        } else if (type == 'speed') {
          $scope.select_speed = $scope.virtual_schedule.speed;
          $scope.preview_show = 'new';
          $scope.show_view = 'speeds';
        } else if (type == 'final_speed') {
          $scope.select_speed = $scope.virtual_schedule.final_speed;
          $scope.preview_show = 'new';
          $scope.show_view = 'speeds';
        } else if (type == 'mode') {
          $scope.available_modes = [];
          angular.forEach([0, 3, 1, 8, 9, 4, 2, 5, 6], function (value) {
            if ($scope.virtual_schedule.modes[value] == 1 && $scope.available_modes.indexOf($scope.modes[value]) < 0)
              $scope.available_modes.push($scope.modes[value]);
          });
          $scope.preview_show = 'new';
          $scope.show_view = 'modes';
          $scope.class_modal_mode = $scope.schedule_modes[parseInt($scope.virtual_schedule.mode)];
        } else if (type == 'final_mode') {
          $scope.available_modes = [];
          angular.forEach([0, 3, 1, 8, 9, 4, 2, 5, 6], function (value) {
            if ($scope.virtual_schedule.modes[value] == 1 && $scope.available_modes.indexOf($scope.modes[value]) < 0)
              $scope.available_modes.push($scope.modes[value]);
          });
          $scope.preview_show = 'new';
          $scope.show_view = 'modes';
          $scope.class_modal_mode = $scope.schedule_modes[parseInt($scope.virtual_schedule.final_mode)];
        } else if (type == 'scenes') {
          $scope.preview_show = 'new_zbs';
          $scope.show_view = 'scenes';
          if (Number($scope.virtual_schedule.scene) >= 6){
            $scope.class_modal_scene = $scope.virtual_schedule.scene;
          }else{
            $scope.class_modal_scene = $scope.available_scenes[parseInt($scope.virtual_schedule.scene)];
          }
        } else if (type == 'cold_temp') {
          $scope.preview_show = 'new_zbs';
          $scope.show_view = 'cold_temp';
        } else if (type == 'heat_temp') {
          $scope.preview_show = 'new_zbs';
          $scope.show_view = 'heat_temp';
        }
      };

      /*
       * Return to new ZBS schedule
       */
      $scope.returnToZBSNew = function () {
        $scope.show_view = 'new_zbs';
      };

      /*
       * Return to
       */
      $scope.returnToNew = function () {
        $scope.show_view = 'new';
      };

      /*
       * Change Hour
       */
      $scope.changeHour = function (type, value) {
        var max = 24;
        if (type == 'start_hour') {
          if (parseInt($scope.virtual_schedule.start_hour) == 0 && value < 0)
            $scope.virtual_schedule.start_hour = (max - 1).toString();
          else
            $scope.virtual_schedule.start_hour = ((parseInt($scope.virtual_schedule.start_hour) + value) % max).toString();
          if ($scope.virtual_schedule.start_hour == '23' && $scope.virtual_schedule.start_minute == '59')
            $scope.virtual_schedule.start_minute = '58';
          // Format Result
          if ($scope.virtual_schedule.start_hour.length == 1)
            $scope.virtual_schedule.start_hour = "0" + $scope.virtual_schedule.start_hour;

          setEndHour();

        } else if (type == 'end_hour') {
          if (parseInt($scope.virtual_schedule.end_hour) == 0 && value < 0)
            $scope.virtual_schedule.end_hour = (max - 1).toString();
          else {
            $scope.virtual_schedule.end_hour = ((parseInt($scope.virtual_schedule.end_hour) + value) % max).toString();
            //Normalize hour
            if (parseInt($scope.virtual_schedule.end_hour) < parseInt($scope.virtual_schedule.start_hour) && value < 0)
              $scope.virtual_schedule.end_hour = (max - 1).toString();
            else if (parseInt($scope.virtual_schedule.end_hour) < parseInt($scope.virtual_schedule.start_hour) && value > 0)
              $scope.virtual_schedule.end_hour = $scope.virtual_schedule.start_hour;
          }
          // Format Result
          if ($scope.virtual_schedule.end_hour.length == 1)
            $scope.virtual_schedule.end_hour = "0" + $scope.virtual_schedule.end_hour;
        }
      };

      /*
       * Change Minute
       */
      $scope.changeMinute = function (type, value) {
        var max = 60;
        if (type == 'start_hour') {
          if (parseInt($scope.virtual_schedule.start_minute) == 0 && value < 0)
            $scope.virtual_schedule.start_minute = (max - ($scope.virtual_schedule.start_hour == '23' && $scope.not_firm_ws ? 2 : 1)).toString();
          else {
            $scope.virtual_schedule.start_minute = ((parseInt($scope.virtual_schedule.start_minute) + value) % max).toString();
            if (($scope.virtual_schedule.start_hour == '23' && $scope.virtual_schedule.start_minute == '59') && $scope.not_firm_ws)
              $scope.virtual_schedule.start_minute = '00';
          }
          // Format Result
          if ($scope.virtual_schedule.start_minute.length == 1)
            $scope.virtual_schedule.start_minute = "0" + $scope.virtual_schedule.start_minute;

          setEndHour();

        } else if (type == 'end_hour') {
          if (parseInt($scope.virtual_schedule.end_minute) == 0 && value < 0)
            $scope.virtual_schedule.end_minute = (max - 1).toString();
          else {
            $scope.virtual_schedule.end_minute = ((parseInt($scope.virtual_schedule.end_minute) + value) % max).toString();
            //Normalize minutes
            if ($scope.virtual_schedule.end_hour == $scope.virtual_schedule.start_hour && parseInt($scope.virtual_schedule.end_minute) == parseInt($scope.virtual_schedule.start_minute) && value < 0)
              $scope.virtual_schedule.end_minute = (max - 1).toString();
            else if ($scope.virtual_schedule.end_hour == $scope.virtual_schedule.start_hour && parseInt($scope.virtual_schedule.end_minute) <= parseInt($scope.virtual_schedule.start_minute) && value > 0)
              $scope.virtual_schedule.end_minute = (parseInt($scope.virtual_schedule.start_minute) + 1).toString();
          }
          // Format Result
          if ($scope.virtual_schedule.end_minute.length == 1)
            $scope.virtual_schedule.end_minute = "0" + $scope.virtual_schedule.end_minute;
        }
      };

      /*
       * Change Temp
       */
      $scope.changeTemp = function (type, value) {
        var maxMin, consignaFinal,
          autoChange = null,
          auto = null,
          isHeatSetpoint = true;

        if ($scope.target.temperature_unit === "1") {
          autoChange = $scope.target.autochange_differential;
          consignaFinal = parseInt($scope.virtual_schedule[type] + value);
        } else {
          //centigrados (ZBS y normal)
          value /= 2.0;

          if (type == 'cold_temp' || type == 'heat_temp') {
            //ZBS
            autoChange = $scope.target.autochange_differential / 2.0;
          }
          consignaFinal = parseFloat($scope.virtual_schedule[type]) + parseFloat(value);
        }

        if (type == 'cold_temp' || type == 'heat_temp') {
          isHeatSetpoint = (type == 'heat_temp') ? true : false;
          auto = { auto: "1" };
        }
        maxMin = SystemUtilsService.getMaxMinSystemSetpoint($scope.target, auto, isHeatSetpoint);

        if (consignaFinal >= maxMin.min && consignaFinal <= maxMin.max)
          $scope.virtual_schedule[type] = consignaFinal;

        //compruebo autochange para ZBS
        if (type === 'cold_temp' || type === 'heat_temp') {
          if (type === 'cold_temp') {
            //aplico autochange
            if ($scope.virtual_schedule.cold_temp < ($scope.virtual_schedule.heat_temp + autoChange)) {
              $scope.virtual_schedule.heat_temp = $scope.virtual_schedule.cold_temp - autoChange;
            }
          } else {
            //aplico autochange
            if ($scope.virtual_schedule.heat_temp > ($scope.virtual_schedule.cold_temp - autoChange)) {
              $scope.virtual_schedule.cold_temp = $scope.virtual_schedule.heat_temp + autoChange;
            }
          }
        }
      };

      /*
       * Select Temp to a value
       */
      $scope.setTempTo = function (type, value) {
        if (type == 'temp')
          $scope.virtual_schedule.temp = value;
        else if (type == 'final_temp')
          $scope.virtual_schedule.final_temp = value;
      };

      /*
       * Show correct values of temp
       */
      $scope.getShowTemp = function (value) {
        if (value == 14.5)
          return 'ON';
        else if (value == 14.0)
          return 'OFF';
        else
          return null;
      };

      /*
       * Method to return if buttons plus and minus should be hidden or not
       */
      $scope.showTempPlusMinus = function (select_type) {
        if (select_type == 'temp') {
          return ($scope.virtual_schedule.temp != 14.0 && $scope.virtual_schedule.temp != 14.5);
        } else if (select_type == 'final_temp') {
          return ($scope.virtual_schedule.final_temp != 14.0 && $scope.virtual_schedule.final_temp != 14.5);
        }
      };

      /*
       * Method to return if button minus should be hidden or not
       */
      $scope.maxMinTemp = function (type, isMinTemp) {
        var maxMin,
          isHeatSetpoint = (type === 'heat_temp') ? true : false;

        maxMin = SystemUtilsService.getMaxMinSystemSetpoint($scope.target, { auto: "1" }, isHeatSetpoint);

        if (isMinTemp)
          return maxMin.min < $scope.virtual_schedule[type];
        return maxMin.max > $scope.virtual_schedule[type];
      };

      /*
       * Select the speed in the modal
       */
      $scope.selectSpeed = function (select_type, value) {
        if (select_type == 'speed') {
          $scope.select_speed = value;
          $scope.virtual_schedule.speed = value;
        }
        else if (select_type == 'final_speed') {
          $scope.select_speed = value;
          $scope.virtual_schedule.final_speed = value;
        }
        $scope.show_view = 'new';
      };

      /*
       * Update target with mode selected in modal
       * @params {string} mode to change in the target
       */
      $scope.selectMode = function (select_type, class_mode) {
        if (select_type == 'mode') {
          $scope.virtual_schedule.mode = getModeFromClass(class_mode);
          $scope.class_modal_mode = class_mode;
        } else if (select_type == 'final_mode') {
          $scope.virtual_schedule.final_mode = getModeFromClass(class_mode);
          $scope.class_modal_mode = class_mode;
        }
        $scope.show_view = 'new';
      };

      /*
       * Update target with scene selected in modal
       * @params {string} scene to change in the target
       */
      $scope.selectScene = function (class_scene) {
        $scope.virtual_schedule.scene = getSceneFromClass(class_scene);
        $scope.class_modal_scene = class_scene;
        $scope.show_view = 'new_zbs';
      };

      /*
       * Update target with scene selected in modal
       * @params {string} scene to change in the target
       */
      $scope.selectSceneVLA = function (class_scene) {
        $scope.virtual_schedule.scene = class_scene;
        $scope.class_modal_scene = class_scene;
        // Open view to choose the option vla when turn on
        if (class_scene === "6") {
          $scope.preview_show = 'new';
          $scope.show_view = 'vla_options';
        // Set the vla off when turn off and back
        }else if (class_scene === "7") {
          $scope.virtual_schedule.vla = '3';
          $scope.show_view = 'new_zbs';
        }
      };

      /*
       * Return mode id for mode class_name
       */
      function getModeFromClass(class_name) {
        return $scope.schedule_modes.indexOf(class_name);
      }

      /*
       * Return scene id for scene class_name
       */
      function getSceneFromClass(class_name) {
        return $scope.available_scenes.indexOf(class_name);
      }

      /*
       * Define if there are new end hour
       */
      $scope.changeHaveEndHour = function () {
        $scope.virtual_schedule.haveEndHour = !$scope.virtual_schedule.haveEndHour;
        $scope.virtual_schedule.end_hour = ($scope.virtual_schedule.haveEndHour ? '00' : '255');
        if ($scope.virtual_schedule.haveEndHour) {
          if ($scope.virtual_schedule.speed != null)
            $scope.virtual_schedule.final_speed = '0';
          if ($scope.virtual_schedule.mode != null)
            $scope.virtual_schedule.final_mode = '1';
          if ($scope.virtual_schedule.temp != null)
            $scope.virtual_schedule.final_temp = '21.0';

          setEndHour();
        } else {
          $scope.virtual_schedule.final_speed = null;
          $scope.virtual_schedule.final_mode = null;
          $scope.virtual_schedule.final_temp = null;
        }
      };

      /*
       * Set end hour from start hour
       */
      function setEndHour() {
        var hour = new Date();
        hour.setHours($scope.virtual_schedule.start_hour);
        hour.setMinutes($scope.virtual_schedule.start_minute);
        var new_hour = new Date(hour.getTime() + 60000);
        $scope.virtual_schedule.end_hour = (new_hour.getHours()).toString();
        $scope.virtual_schedule.end_minute = (new_hour.getMinutes()).toString();
        if ($scope.virtual_schedule.end_hour.length == 1)
          $scope.virtual_schedule.end_hour = '0' + $scope.virtual_schedule.end_hour;
        if ($scope.virtual_schedule.end_minute.length == 1)
          $scope.virtual_schedule.end_minute = '0' + $scope.virtual_schedule.end_minute;
      }

      /*
       * Normalize name
       */
      $scope.$watch("virtual_schedule.name", function (new_val, old_val) {
        if (new_val != old_val && $scope.virtual_schedule && $scope.virtual_schedule.name) {
          $scope.virtual_schedule.name = $scope.virtual_schedule.name.replace(/[^ÑA-Zña-z-,\./\ _0-9\s]/g, "").substring(0, 11);
        }
      });

      /*
       * Define have not end hour
       */
      $scope.changeNotHaveEndHour = function () {
        $scope.not_firm_ws = false;
        if ($scope.virtual_schedule) {
          $scope.virtual_schedule.end_hour = '255';
          $scope.virtual_schedule.final_speed = null;
          $scope.virtual_schedule.final_mode = null;
          $scope.virtual_schedule.final_temp = null;
        }
      };

      /*
       * Save Schedule
       */
      $scope.saveSchedule = function () {
        if ($scope.virtual_schedule && angular.equals($scope.virtual_schedule.days, ["0", "0", "0", "0", "0", "0", "0"]))
          $scope.error_day = true;
        else
          $scope.error_day = false;

        if (!$scope.error_day && $scope.virtual_schedule) {
          $scope.virtual_schedule.days = $scope.virtual_schedule.days.reverse();
          $scope.virtual_schedule.days = $scope.virtual_schedule.days.join("");
          $scope.virtual_schedule.days = binaryToHex($scope.virtual_schedule.days).result;
          $scope.virtual_schedule.modes = null;
          if (!$scope.target.scene)
            $scope.virtual_schedule.temp = parseFloat($scope.virtual_schedule.temp).toFixed(1);
          $scope.virtual_schedule.final_temp = parseFloat($scope.virtual_schedule.final_temp).toFixed(1);
          if ($scope.target.temperature_unit == "1") {
            $scope.virtual_schedule.cold_temp = parseInt($scope.virtual_schedule.cold_temp);
            $scope.virtual_schedule.heat_temp = parseInt($scope.virtual_schedule.heat_temp);
          } else {
            $scope.virtual_schedule.cold_temp = parseFloat($scope.virtual_schedule.cold_temp).toFixed(1);
            $scope.virtual_schedule.heat_temp = parseFloat($scope.virtual_schedule.heat_temp).toFixed(1);
          }


          if ($scope.target.class == 'Zone') {
            eventsService.createEventModel("modprog", $scope.virtual_schedule)

            // send event with zone
            initialize($scope.target);
          } else if ($scope.target.class == 'System') {
            if ($scope.target.scene) {
              if ($scope.show_power) {
                $scope.virtual_schedule.cold_temp = null;
                $scope.virtual_schedule.heat_temp = null;
                $scope.virtual_schedule.scene = null;
                $scope.virtual_schedule.vla = null;
              } else if ($scope.show_temp) {
                $scope.virtual_schedule.scene = null;
                $scope.virtual_schedule.temp = null;
                $scope.virtual_schedule.vla = null;
              } else if ($scope.show_scene) {
                $scope.virtual_schedule.cold_temp = null;
                $scope.virtual_schedule.heat_temp = null;
                $scope.virtual_schedule.temp = null;
                if ($scope.virtual_schedule.scene === '6' || $scope.virtual_schedule.scene === '7') {
                  $scope.virtual_schedule.scene = null;
                }else{
                  $scope.virtual_schedule.vla = null;
                }
              }

              $scope.virtual_schedule.disable_last_selected_zone = null;
              var selected_zones = 0;

              angular.forEach($scope.virtual_schedule.selected_zones, function (value, index) {
                if (value) {
                  selected_zones += 1 << (parseInt($scope.virtual_schedule.zones[index].zone_number) - 1);
                }
              });

              $scope.virtual_schedule.selected_zones = selected_zones.toString(16);
              delete $scope.virtual_schedule.zones;
              eventsService.createEventModel("modprog", $scope.virtual_schedule);
              $scope.show_view = 'waiting_message';
              $timeout(function () {
                $scope.show_view = 'zbs';
              }, 4000);
            } else {
              // send event to selected zones
              angular.forEach($scope.virtual_schedule.zones, function (value) {
                if (value.selected_for_sche) {
                  // clone schedule and send
                  var schedule_aux = JSON.parse(JSON.stringify($scope.virtual_schedule));
                  schedule_aux.zones = null;
                  schedule_aux.zone_number = value.zone_number;
                  if ($scope.target.auto_index_prog) {
                    schedule_aux.schedule_number = "24";
                  } else {
                    schedule_aux.schedule_number = $scope.zones_next_num.filter(
                      function (x) { return (x.id == value.id); })[0].next_schedule_number;
                  }
                  eventsService.createEventModel("modprog", schedule_aux);
                  eventsService.createEvent('modzona', value, 'prog_enabled', '1');
                }
              });
              // Show waiting message
              $scope.show_view = 'waiting_message';
            }
          }
        }
      };

      /*
       * Remove schedule
       */
      $scope.removeSchedule = function () {
        eventsService.createEvent("modprog", $scope.virtual_schedule, 'exist', '0');
        if ($scope.target.scene)
          initializeZBS($scope.target);
        else
          initialize($scope.target);
      }

      /*
       * Go to list
       */
      $scope.goToList = function () {
        initialize($scope.target);
        refreshList($scope.target);
      };

      $scope.closeScheduleModal = function () {
        if ($scope.target.class == 'Zone')
          $scope.show_view = 'list';
        else if ($scope.target.class == 'System') {
          if ($scope.target.scene) {
            if ($scope.show_view == 'list' || $scope.show_view == 'zbs')
              $scope.close();
            else
              $scope.show_view = 'zbs';
          } else {
            $scope.show_view = 'list';
            $scope.close();
          }
        }
      };

      /*
       * Binary to Hex
       */
      function binaryToHex(s) {
        var i, k, part, accum, ret = '';
        for (i = s.length - 1; i >= 3; i -= 4) {
          // extract out in substrings of 4 and convert to hex
          part = s.substr(i + 1 - 4, 4);
          accum = 0;
          for (k = 0; k < 4; k += 1) {
            if (part[k] !== '0' && part[k] !== '1') {
              // invalid character
              return { valid: false };
            }
            // compute the length 4 substring
            accum = accum * 2 + parseInt(part[k], 10);
          }
          if (accum >= 10) {
            // 'A' to 'F'
            ret = String.fromCharCode(accum - 10 + 'A'.charCodeAt(0)) + ret;
          } else {
            // '0' to '9'
            ret = String(accum) + ret;
          }
        }
        // remaining characters, i = 0, 1, or 2
        if (i >= 0) {
          accum = 0;
          // convert from front
          for (k = 0; k <= i; k += 1) {
            if (s[k] !== '0' && s[k] !== '1') {
              return { valid: false };
            }
            accum = accum * 2 + parseInt(s[k], 10);
          }
          // 3 bits, value cannot exceed 2^3 - 1 = 7, just convert
          ret = String(accum) + ret;
        }
        return { valid: true, result: ret };
      }

      /*
       * Decide if put two buttons
       */
      $scope.putTwoButton = function (type) {
        if (type == 'start') {
          return (($scope.virtual_schedule.temp != null || $scope.virtual_schedule.mode != null) && $scope.virtual_schedule.speed != null)
        } else if (type == 'final') {
          return (($scope.virtual_schedule.final_temp != null || $scope.virtual_schedule.final_mode != null) && $scope.virtual_schedule.final_speed != null)
        }
      };

      /*
       * Show the remove confirmation
       */
      $scope.showRemoveConfirmation = function () {
        $scope.show_view = 'remove_confirm';
      };

      /*
       * Show the edit view again
       */
      $scope.showEdit = function () {
        $scope.show_view = 'new';
      };

      /*
       * Remove root scope watch
       */
      $scope.$on('$destroy', function () {
        $scope.listener1();
        $scope.listener2();
        $scope.listener3();
      });
    }]);
