Airzone.controller('modalAlthermaSchedulesController',
  ['$scope', '$rootScope', 'eventsService', 'programService', '$timeout', 'SystemUtilsService', 'operatingSystemService', '$window',
    function ($scope, $rootScope, eventsService, programService, $timeout, SystemUtilsService, operatingSystemService, $window) {

      $scope.showHelp = false;
      $scope.show_view = 'list';
      $scope.error_day = false;
      $scope.block_update = false;
      $scope.not_firm_ws = true;

      // Prevent bottom space when keyboard hides
      $scope.scrollToTop = function ($event) {
        window.scrollTo(0, 0);
      }

      // Inside the controller
      $scope.isBoolean = function (value) {
        return typeof value === 'boolean';
      };

      /*
       * Open modal
       */
      $scope.listener1 = $rootScope.$watch('altherma_schedules_target', function (new_target) {
        if (new_target) {
          initialize(new_target);
          refreshList(new_target);
        }
      })

      /*
       * Refresh the list of schedules
       */
      function initialize(new_target) {
        // Show if there are new target and is schedule modal
        if (new_target) {
          $scope.loader = false;
          $scope.name = new_target.name;
          $scope.config = new_target.config;
          $scope.target = new_target;
          $scope.schedules = null;
          $scope.virtual_schedule = null;
          $scope.last_schedule_number = -1;

          $scope.show = true;
          $rootScope.modalShow = true;

          $scope.info = {
            device_id: new_target.device_id,
            system_number: '0'
          };

          // Demo
          $timeout(function () {
            if ($rootScope.demo)
              $rootScope.update_altherma_schedules = true;
          }, 1000);

          // Show list of schedules in altherma
          $scope.show_view = 'list';
        }
      }

      /*
       * Refresh the list of schedules
       */
      function refreshList(new_target) {
        if (new_target) {
          if (new_target.class == 'System') {
            $scope.target_type = 'System';
            eventsService.createEvent('infoprog2', $scope.info, null, null).then(function (data) {
              console.log("Event created: ", data);
            }).catch(function (error) {
              console.log("Error requesting schedules: ", error);
            });
            if ($rootScope.demo)
              $rootScope.update_altherma_schedules = true;
          }
        }
      }

      $scope.listener2 = $rootScope.$watch('update_altherma_schedules', function (new_value, old_value) {
        if (!$scope.block_update && new_value != old_value && new_value) {
          programService.getPrograms($scope.target.device_id).then(function (data) {
            $scope.loader = true;
            $scope.all_schedules = data;
            var altherma_schedules = data.filter(function (a) { return a['systems'][0]['system_number'] === '0' });
            $scope.schedules = altherma_schedules.sort(function (a, b) { return a['schedule_number'] - b['schedule_number']; });
          }, function (error) {
            console.error(error);
          });
        }

        $rootScope.update_altherma_schedules = false;
      })

      /*
       * Toggle Schedule enable flag
       */
      $scope.toggleEnabled = function (sche) {
        if (sche.enabled === "1") {
          sche.enabled = "0";
        } else {
          sche.enabled = "1";
        }
        $scope.virtual_schedule = sche;
        parseProgramData();

        eventsService.createEventModel("modprog2", $scope.virtual_schedule, 'exist', '1');
      }

      /*
       * Translate strint 't'
       * Solved bug (can't call $rootScope directly)
       */
      $scope.i18n = function (t) {
        return $rootScope.i18n(t);
      }

      /*
       * Called when close modal (click out)
       */
      $scope.close = function () {
        $scope.loader = false;
        $scope.not_load = null;
        $scope.schedules = null;
        $scope.target = null;
        $scope.show = false;
        $scope.show_view = 'list';
        $rootScope.modalShow = false;
        $rootScope.altherma_schedules_target = null;
        $scope.error_day = false;
        $scope.block_update = false;
      }

      /*
       * Seek free schedule number
       */
      function seekFreeScheduleNumber() {
        if (!$scope.all_schedules || $scope.all_schedules.length == 0)
          $scope.last_schedule_number = 1;
        else {
          var i = 0;
          var not_found = true;
          while (i < $scope.all_schedules.length && not_found) {
            if ((i + 1).toString() != $scope.all_schedules[i].schedule_number) {
              $scope.last_schedule_number = i + 1;
              not_found = false;
            }
            i += 1;
          }

          if (not_found)
            $scope.last_schedule_number = parseInt($scope.all_schedules[$scope.all_schedules.length - 1].schedule_number) + 1;
        }
      }

      /*
       * Show message of migrated installation
       */
      function migratedInstallationMessage() {
        console.log('Schedules migrated');
        let type = operatingSystemService.detectOperatingSystem();
        $scope.show_android = type.android;
        $scope.show_ios = type.ios;
        $scope.migrated_installation = true;
      }

      /*
       * Change the view of the modal
       */
      $scope.changeToNewView = function () {
        if (typeof $rootScope.server.ws_scheds_active !== 'boolean') {
          seekFreeScheduleNumber();
          var n_name = parseInt($scope.last_schedule_number);

          if (n_name < 10)
            n_name = '0' + n_name;

          $scope.virtual_schedule = {
            enabled: '1',
            temp: 42,
            power_acs: true,
            hour: '00',
            minute: '00',
            end_hour: '23',
            end_minute: '59',
            days: ['0', '0', '0', '0', '0', '0', '0'],
            name: 'PROG. ' + n_name,
            system_number: '0',
            device_id: $scope.info.device_id,
            schedule_number: parseInt($scope.last_schedule_number)
          }

          // Prepare views
          $scope.new_edit_type = 'new';
          $scope.show_view = 'new';
          $scope.block_update = false;
        } else {
          migratedInstallationMessage();
        }
      }

      /*
       * Change to edit view
       */
      $scope.changeToEditView = function (schedule) {
        $scope.virtual_schedule = {
          enabled: schedule.enabled,
          temp: schedule.temp,
          power_acs: schedule.power_acs,
          hour: schedule.hour,
          minute: schedule.minute,
          days: schedule.days,
          name: schedule.name,
          device_id: schedule.device_id,
          schedule_number: schedule.schedule_number
        }

        // Normalize hour
        if ($scope.virtual_schedule.hour.length == 1)
          $scope.virtual_schedule.hour = '0' + $scope.virtual_schedule.hour
        if ($scope.virtual_schedule.minute.length == 1)
          $scope.virtual_schedule.minute = '0' + $scope.virtual_schedule.minute

        // Prepare edit view
        $scope.new_edit_type = 'edit';
        $scope.show_view = 'new';
        $scope.block_update = false;
      }

      /*
       * ToggleDays
       */
      $scope.toggleDays = function (day) {
        if ($scope.virtual_schedule.days[day] == '0')
          $scope.virtual_schedule.days[day] = '1';
        else
          $scope.virtual_schedule.days[day] = '0';
      }

      /*
       * Launch select of hour
       */
      $scope.selectNew = function (type) {
        $scope.select_type = type;
        if (type == 'hour') {
          $scope.preview_show = 'new';
          $scope.show_view = 'hour';
        } else if (type == 'temp') {
          $scope.preview_show = 'new';
          $scope.show_view = 'temp';
        }
      }

      /*
       * Return to
       */
      $scope.returnToNew = function () {
        $scope.show_view = 'new';
      }

      /*
       * Change Hour
       */
      $scope.changeHour = function (type, value) {
        var max = 24;
        if (type == 'hour') {
          if (parseInt($scope.virtual_schedule.hour) == 0 && value < 0)
            $scope.virtual_schedule.hour = (max - 1).toString();
          else
            $scope.virtual_schedule.hour = ((parseInt($scope.virtual_schedule.hour) + value) % max).toString();
          if ($scope.virtual_schedule.hour == '23' && $scope.virtual_schedule.minute == '59')
            $scope.virtual_schedule.minute = '58';
          // Format Result
          if ($scope.virtual_schedule.hour.length == 1)
            $scope.virtual_schedule.hour = "0" + $scope.virtual_schedule.hour;
        }
      }

      /*
       * Change Minute
       */
      $scope.changeMinute = function (type, value) {
        var max = 60;
        if (type == 'hour') {
          if (parseInt($scope.virtual_schedule.minute) == 0 && value < 0)
            $scope.virtual_schedule.minute = (max - ($scope.virtual_schedule.hour == '23' && $scope.not_firm_ws ? 2 : 1)).toString();
          else {
            $scope.virtual_schedule.minute = ((parseInt($scope.virtual_schedule.minute) + value) % max).toString();
            if (($scope.virtual_schedule.hour == '23' && $scope.virtual_schedule.minute == '59') && $scope.not_firm_ws)
              $scope.virtual_schedule.minute = '00';
          }
          // Format Result
          if ($scope.virtual_schedule.minute.length == 1)
            $scope.virtual_schedule.minute = "0" + $scope.virtual_schedule.minute;
        }
      }

      /*
       * Change Temp
       */
      $scope.changeTemp = function (type, value) {
        var consignaFinal = null;

        consignaFinal = Number($scope.virtual_schedule[type]) + value;

        if (consignaFinal >= Number($scope.target.acs_min) && consignaFinal <= Number($scope.target.acs_max))
          $scope.virtual_schedule[type] = consignaFinal;
      }

      /*
       * Method to return if buttons plus and minus should be hidden or not
       */
      $scope.showTempPlusMinus = function (select_type) {
        if (select_type == 'minus') {
          return ($scope.virtual_schedule.temp != $scope.target.acs_min)
        } else if (select_type == 'plus') {
          return ($scope.virtual_schedule.temp != $scope.target.acs_max);
        }
      }

      /*
       * Method to return if button minus should be hidden or not
       */
      $scope.maxMinTemp = function (type, isMinTemp) {
        var maxMin = SystemUtilsService.getMaxMinSystemSetpoint($scope.target, { auto: "1" }, false);

        if (isMinTemp)
          return maxMin.min < $scope.virtual_schedule[type];
        return maxMin.max > $scope.virtual_schedule[type];
      }

      /*
       * Normalize name
       */
      $scope.$watch("virtual_schedule.name", function (new_val, old_val) {
        if (new_val != old_val && $scope.virtual_schedule && $scope.virtual_schedule.name) {
          $scope.virtual_schedule.name = $scope.virtual_schedule.name.replace(/[^ÑA-Zña-z-,\./\ _0-9\s]/g, "").substring(0, 11);
        }
      })

      /*
       * Load the schedules after 1 second
       */
      function loadSchedules() {
        // $timeout(function () {
        initialize($scope.target);
        refreshList($scope.target);
        // }, 1000);
      }

      /*
       * Parse the program data
       */
      function parseProgramData() {
        $scope.virtual_schedule.days = $scope.virtual_schedule.days.reverse();
        $scope.virtual_schedule.days = $scope.virtual_schedule.days.join("");
        $scope.virtual_schedule.days = binaryToHex($scope.virtual_schedule.days).result;
        $scope.virtual_schedule.modes = null;
        $scope.virtual_schedule.temp = Number($scope.virtual_schedule.temp);
        $scope.virtual_schedule.systems = [{ "system_number": "0", "zones_enabled": "0" }];
      }

      /*
       * Save Schedule
       */
      $scope.saveSchedule = function () {
        if ($scope.virtual_schedule && angular.equals($scope.virtual_schedule.days, ["0", "0", "0", "0", "0", "0", "0"]))
          $scope.error_day = true;
        else
          $scope.error_day = false;

        if (!$scope.error_day && $scope.virtual_schedule) {
          $scope.loader = false;
          $scope.show_view = '';
          parseProgramData();

          if (!$rootScope.demo) {
            eventsService.createEventModel("modprog2", $scope.virtual_schedule).then(function () {
              loadSchedules();
            }).catch(function (err) {
              console.log("Error save schedule: ", err);
            })
          } else {
            loadSchedules();
          }
        }
      }

      /*
       * Remove schedule
       */
      $scope.removeSchedule = function () {
        eventsService.createEvent("modprog2", $scope.virtual_schedule, 'exist', '0').then(function () {
          loadSchedules();
        }).catch(function (err) {
          console.log("Error remove schedule: ", err);
        })
      }

      /*
       * Show the schedules list, when close schedule modal
       */
      $scope.closeScheduleModal = function () {
        $scope.show_view = 'list';
      }

      /*
       * Binary to Hex
       */
      function binaryToHex(s) {
        var i, k, part, accum, ret = '';
        for (i = s.length - 1; i >= 3; i -= 4) {
          // extract out in substrings of 4 and convert to hex
          part = s.substr(i + 1 - 4, 4);
          accum = 0;
          for (k = 0; k < 4; k += 1) {
            if (part[k] !== '0' && part[k] !== '1') {
              // invalid character
              return { valid: false };
            }
            // compute the length 4 substring
            accum = accum * 2 + parseInt(part[k], 10);
          }
          if (accum >= 10) {
            // 'A' to 'F'
            ret = String.fromCharCode(accum - 10 + 'A'.charCodeAt(0)) + ret;
          } else {
            // '0' to '9'
            ret = String(accum) + ret;
          }
        }
        // remaining characters, i = 0, 1, or 2
        if (i >= 0) {
          accum = 0;
          // convert from front
          for (k = 0; k <= i; k += 1) {
            if (s[k] !== '0' && s[k] !== '1') {
              return { valid: false };
            }
            accum = accum * 2 + parseInt(s[k], 10);
          }
          // 3 bits, value cannot exceed 2^3 - 1 = 7, just convert
          ret = String(accum) + ret;
        }
        return { valid: true, result: ret };
      }

      /*
       * Decide if put two buttons
       */
      $scope.putTwoButton = function (type) {
        if (type == 'start') {
          return ($scope.virtual_schedule.temp != null && $scope.virtual_schedule.temp !== "14")
        }
      }

      /*
       * Show the remove confirmation
       */
      $scope.showRemoveConfirmation = function () {
        $scope.show_view = 'remove_confirm';
      }

      /*
       * Show the edit view again
       */
      $scope.showEdit = function () {
        $scope.show_view = 'new';
      }

      /*
       * Open Itunnes Apple
       */
      $scope.openItunesApple = function () {
        $window.open(operatingSystemService.goToNewItunesApple(), '_system', 'location=no');
      }

      /*
       * Open Itunnes Apple
       */
      $scope.openPlayGoogle = function () {
        $window.open(operatingSystemService.goToNewPlayGoogle(), '_system', 'location=no');
      }

      /*
       * Recharge the page when click
       */
      $scope.closeModal = function () {
        $scope.migrated_installation = false;
      }

      /*
       * Remove root scope watch
       */
      $scope.$on('$destroy', function () {
        $scope.listener1();
        $scope.listener2();
      })
    }]);
