// eslint-disable-next-line no-undef
Airzone.controller('serverController',
  ['$scope', '$rootScope', '$state', '$stateParams', '$window', 'systemService', 'deviceService', 'websocketsService', 'sessionService', 'eventsService', 'programService', 'purifierService', '$timeout', 'DEFAULT_CONSIGN_SERVER', 'MOBILE_APP', 'operatingSystemService',
    function ($scope, $rootScope, $state, $stateParams, $window, systemService, deviceService, websocketsService, sessionService, eventsService, programService, purifierService, $timeout, DEFAULT_CONSIGN_SERVER, MOBILE_APP, operatingSystemService) {

      // Constants
      $scope.scenes = ["stop", "confort", "unocupied", "night", "eco", "vacation"];
      $scope.ecos = ["eco-off", "eco-m", "eco-a", "eco-aa", "eco-aaa"];
      $scope.velocities = ["0", "1", "2"];
      $scope.air_flows = ["0", "1", "2"];
      $scope.date = new Date();
      $scope.polling_time = 15000;


      // Server flags
      $scope.systems = [];
      $scope.zones = {};
      $scope.weather = {};

      $scope.expand = false;
      $scope.power = true;
      $scope.turn_off_powerful = false;
      $rootScope.show_climate_forecast = false;
      $rootScope.show_electricity_prices = false;
      $rootScope.electricity_price = false;
      // Initialize notifications variables for mobile app
      if (MOBILE_APP) {
        $scope.alert_notification = false;
        $scope.not_show_alert_notification = false;
        $scope.is_mobile_app = true;
      }

      $scope.class_mode = "mode-generic";
      $scope.class_scene = "confort";
      $scope.class_eco = "eco-generic";
      $scope.class_vel = "0";
      $scope.class_air_flow = "0";
      $scope.server = {};
      // array with systems
      $rootScope.array_systems_purifier_warning = [];
      $rootScope.array_purifier_warning = [];
      $rootScope.show_hours_before = null;

      initialize();

      function initialize() {

        websocketsService.init();

        // Show climate info in home
        $rootScope.show_climate_info = true;
        deviceService.getDeviceRelation($stateParams.rel).then(
          function (device_relation) {
            $rootScope.server = device_relation.device;
            $rootScope.relation = device_relation;
            $rootScope.enabled_server_schedules = false;
            // Disabled server schedules if device is migrated to new app
            if (!$rootScope.server.migrated && $rootScope.server.programs_enabled){
              $rootScope.enabled_server_schedules = true;
            }
            $scope.server = $rootScope.server;
            $scope.relation = $rootScope.relation;

            if ($scope.server.electricity_prices && $scope.server.electricity_prices["today"] != null) {
              $rootScope.show_climate_forecast = false;
              $rootScope.show_electricity_prices = true;
              set_electricity_prices();
              init_chart();
            } else {
              $rootScope.show_climate_forecast = true;
            }

            systemService.getSystems($scope.server.id, false).then(function (systems) {
              var first_system = systems[0];
              $scope.systems = systems;
              $scope.server.systems = systems;
              $rootScope.has_new_schedules = false;
              $scope.server.has_velocity = false;

              // Check if it has system
              if ($scope.systems.length === 0) {
                $rootScope.not_systems = true;
                $scope.sys = { "system_number": "1", "device_id": $scope.server.id };
                eventsService.createEvent('infosistema2', $scope.sys, null, null);
              }

              // Check if it has altherma
              if (first_system && first_system.system_number === '0') {
                //console.log(first_system);
                $scope.has_altherma = true;
                $scope.altherma = first_system;
                $scope.systems = $scope.systems.slice(1);
                $scope.server.systems = $scope.server.systems.slice(1);
              }

              // Iterate all systems
              systems.forEach(function (sys) {
                // Check if if has velocity
                if (sys.has_velocity)
                  $scope.server.has_velocity = true;
                // Check if if has acs in radiant
                if (!$scope.has_altherma && sys.power_acs)
                  $scope.power_acs = sys.power_acs;
                // Check if if has power acs readonly
                if (sys.acs_power_readonly)
                  $scope.acs_power_readonly = true
              });

              // Check if there are purifier warnings
              if (first_system && first_system.firm_ws && parseFloat(first_system.firm_ws) >= 3.30) {
                purifierService.find($scope.server.id).then(function (data) {
                  $scope.purifiers = data.purifiers;
                  if ( $scope.purifiers.length > 0 ){
                    $scope.purifiers.forEach(function(purifier){
                      checkPurifierWarning(purifier);
                      // Hide rele option if purifier is enable
                      $scope.systems.filter(function(system){
                        if (system.system_number == purifier.system_number){
                          system.has_purifier = true;
                        }
                      })
                    });
                  }
                }).catch(function(error){
                  console.log("error: ", error);
                });
              }

              if (first_system && first_system.firm_ws && parseFloat(first_system.firm_ws) >= 3.0) {
                $scope.modes = ["stop", "cool", "heat", "ventilate", "heat", "heat", "dehumidify", "not_exit", "cool", "cool", "not_exit", "not_exit", "not_exit", "auto"];
                // Check if server has new schedules 
                if (parseFloat(first_system.firm_ws) >= 3.20 && !first_system.scene) {
                  $rootScope.has_new_schedules = true;
                  if ($rootScope.isMobile && $scope.systems.length > 1) {
                    $scope.expand = true;
                  }
                }

                if (!first_system.scene && $scope.server.modes && $scope.server.modes.length == 14) {
                  $scope.server.modes = $scope.server.modes.slice(4, $scope.server.modes.length);
                }

                if (first_system.temperature_unit !== undefined) {
                  $scope.server.units = first_system.temperature_unit;
                  $scope.server.consign = DEFAULT_CONSIGN_SERVER;
                }

              } else if ($scope.server.modes) {
                $scope.server.modes = $scope.server.modes.slice(5, $scope.server.modes.length);
                $scope.modes = ["stop", "cool-air", "heat-radiant", "ventilate", "heat-air", "heat-both", "dehumidify", "not_exit", "cool-radiant", "cool-both"];
              }

              // Create websocket channel for server
              //websocketsService.destroyChannels();
              websocketsService.createServerChannel($scope.server.id, null);

              // Get actual weather
              $scope.initializeWeather();
              $scope.listener_error = $rootScope.$watch('error_device', function (new_val, old_val, scope) {
                if (new_val) {
                  $scope.systems.forEach(function (system) {
                    system.expand = false;
                  });
                }
              });
            });
            updateDate();
            $rootScope.zone_from_navigation = true;
          }).catch(function (error) {
            console.log("Error get device relation: ", error);
          })
      }

      /*
       * Check if it has to show purifier warnings
       */
      function checkPurifierWarning(purifier){
        if (!purifier.notify100){
          if (purifier.time_unit === 'hours'){
            showPurifierWarning(purifier, purifier.warning_time, purifier.acc, true);
          }else if (purifier.time_unit === 'days'){
            var end_date = (moment(purifier.start_date)).add(purifier.warning_time, purifier.time_unit);
            var now = moment.utc();
            showPurifierWarning(purifier, end_date, now, true);
          }
        }
        if (!$rootScope.purifier_warning && !purifier.notify90 && !purifier.notify100){
          var ninety = purifier.warning_time * 0.9;
          if (purifier.time_unit === 'hours'){
            showPurifierWarning(purifier, ninety, purifier.acc, false);
          }else if (purifier.time_unit === 'days'){
            end_date = (moment(purifier.start_date)).add(ninety, purifier.time_unit);
            now = moment.utc();
            showPurifierWarning(purifier, end_date, now, false);
          }
        }
      }

      function showPurifierWarning(purifier, minuendo, substracting, warning100){
        if (minuendo - substracting <= 0){
          $rootScope.purifier_warning = true;
          $scope.systems.filter(function(sys) {
            if (purifier.system_number === sys.system_number){
              $rootScope.array_systems_purifier_warning.push(sys.name);
              if (warning100){
                purifier.notify100 = true;
                sys.purifier_warning = true;
                purifierService.updateSystem(sys);
              }else{
                if (purifier.time_unit === 'hours'){
                  var time = purifier.warning_time - purifier.acc;
                  $rootScope.show_hours_before = String(time) + " " + i18n('system_config.hours');
                }else if (purifier.time_unit === 'days'){
                  var end_date = (moment(purifier.start_date)).add(purifier.warning_time, purifier.time_unit);
                  var now = moment.utc();
                  time = end_date.diff(now, 'days');
                  $rootScope.show_hours_before = String(time) + " " + i18n('system_config.days');
                }
                purifier.notify90 = true;
              }
              $rootScope.array_purifier_warning.push(purifier);
            }
          });
        }
      }

      /*
       * Translate strint 't'
       */
      function i18n(t) {
        return $rootScope.i18n(t);
      }

      /*
       * View electricity prices info
       */
      $scope.show_electricity_prices_info = function () {
        $rootScope.show_climate_forecast = false;
        $rootScope.show_electricity_prices = true;
      };

      /*
       * View climate forecast info
       */
      $scope.show_climate_forecast_info = function () {
        $rootScope.show_electricity_prices = false;
        $rootScope.show_climate_forecast = true;
      };

      /*
       * Open config system modal Schedules
       */
      $scope.showServerSchedulesModal = function (calendar) {        
        if (calendar) {
          $rootScope.show_calendar_schedules = true;
        }
        $rootScope.server_schedules_target = $scope.server;

        if (!$rootScope.server.programs_enabled && typeof $scope.server.ws_scheds_active !== 'boolean'){
          $rootScope.show_enable_server_schedules_message = true;
        }
      }

      /*
       * Open Itunnes Apple
       */
      $scope.openItunesApple = function () {
        $window.open(operatingSystemService.goToNewItunesApple(), '_system', 'location=no');
      }

      /*
       * Open Itunnes Apple
       */
      $scope.openPlayGoogle = function () {
        $window.open(operatingSystemService.goToNewPlayGoogle(), '_system', 'location=no');
      }

      /*
       * Recharge the page when click
       */
      $scope.closeModal = function () {
        $scope.migrated_installation = false;
      }

      /*
       * Open altherma system modal Schedules
       */
      $scope.showSchedulesAlthermaModal = function () {
        $rootScope.altherma_schedules_target = $scope.altherma;
      };

      /*
       * Open acs radiant modal Schedules
       */
      $scope.showSchedulesACSRadiantModal = function () {
        $rootScope.acs_radiant_schedules_target = $scope.server;
      };

      /*
       * Turn on/off powerful of altherma
       */
      $scope.togglePowerful = function () {
        $scope.altherma.powerful = !$scope.altherma.powerful;
        if ($rootScope.demo) {
          if ($scope.altherma && $scope.altherma.powerful) {
            $scope.altherma.setpoint = $scope.altherma.acs_max;
          } else {
            $scope.altherma.setpoint = '45';
          }
        }
        if (!$rootScope.demo) {
          eventsService.createEvent('modsistema', $scope.altherma, 'powerful', String(Number($scope.altherma.powerful)));
        }
      }

      /*
       * Show modal with the system errors
       */
      $scope.showSystemErrors = function () {
        $rootScope.system_errors_target = $scope.altherma;
      }

      /*
       * Expand view or redirect to mobile view
       */
      $scope.detectMobile = function () {
        if (MOBILE_APP || (window.innerWidth <= 800 && window.innerHeight <= 812)) {
          $rootScope.altherma = $scope.altherma;
          $state.go('home.zoneAltherma', { id: $scope.altherma.id, rel: $scope.relation.id }, { reload: true });
        } else {
          $scope.expand__altherma = !$scope.expand__altherma;
          if ($scope.expand__altherma) {
            eventsService.createEvent('infosistema', $scope.altherma, null, null);
            timeoutUpdate();
          } else {
            clearTimeout($scope.update_timeout);
          }
        }
      }

      /*
       * Create event every polling time to refresh change from webserver
       */
      function timeoutUpdate() {
        $scope.update_timeout = setTimeout(function () {
          eventsService.createEvent('infosistema', $scope.altherma, null, null);
          timeoutUpdate();
        }, $scope.polling_time);
      }


      $scope.showModeOptions = function () {
        $scope.server.show_VAF_supply_heat = false;
        $rootScope.mode_target = $scope.server;
      };

      /*
       * Show modal with Scene Options buttons
       */
      $scope.showSceneOptions = function () {
        $rootScope.scene_target = $scope.server;
      };

      /*
       * Show modal with Eco Options buttons
       */
      $scope.showEcoOptions = function () {
        $rootScope.eco_target = $scope.server;
      };

      /*
       * Show modal with Velocity Options buttons
       */
      $scope.showVelocityOptions = function () {
        if ($scope.server.has_velocity) {
          $rootScope.velocity_target = $scope.server;
        }
      };

      /*
       * Show modal with AirFlow Options buttons
       */
      $scope.showAirFlowOptions = function () {
        $rootScope.air_flow_target = $scope.server;
      };

      /*
       * Turn off/on the server, its systems and zones
       */
      $scope.togglePower = function () {
        $scope.power = !$scope.power;

        $scope.systems.forEach(function (system) {
          system.server_power = $scope.power;
        });
      };

      /*
       * Turn on/off the altherma power_acs
       */
      $scope.toggleAlthermaStatus = function () {
        if (!$scope.acs_power_readonly){
          $scope.altherma.power_acs = !$scope.altherma.power_acs;

          if (!$rootScope.demo) {
            // Turn off the powerful if it is turned on and turn off the power acs
            if (!$scope.altherma.power_acs && $scope.altherma.powerful) {
              $scope.altherma.powerful = !$scope.altherma.powerful;
              eventsService.createEvent('modsistema', $scope.altherma, 'powerful', "0");
            }
            $timeout(function () {
              eventsService.createEvent('modsistema', $scope.altherma, 'power_acs', String(Number($scope.altherma.power_acs)));
            }, 200);
          }
        }
      }

      /*
       * Turn on/off the ACS Radiant
       */
      $scope.toggleACSRadiantStatus = function () {
        if (!$scope.acs_power_readonly){
          $scope.power_acs = String(Number(!+$scope.power_acs));

          $scope.info = {
            device_id: $scope.server.id,
            system_number: '0'
          };

          $timeout(function () {
            eventsService.createEvent('modsistema', $scope.info, 'power_acs', $scope.power_acs);
          }, 200);
        }
      }

      /*
       * Get server setpoint
       */
      $scope.getServerSetpoint = function () {
        if (!$scope.server.consign)
          $scope.server.consign = DEFAULT_CONSIGN_SERVER;

        if ($scope.server.consign === DEFAULT_CONSIGN_SERVER) {
          return DEFAULT_CONSIGN_SERVER;
        } else {
          return $scope.server.consign + 'º';
        }
      }

      /*
        * Update consign of altherma (add or dedduct value)
        */
      $scope.updateAlthermaConsign = function (val) {
        var new_consign,
          valid = false;

        new_consign = Number($scope.altherma.setpoint) + Number(val);
        if (new_consign >= Number($scope.altherma.acs_min) && new_consign <= Number($scope.altherma.acs_max)) {
          valid = true;
          $scope.altherma.setpoint = new_consign;
        }

        if (valid && !$rootScope.demo) {
          // Create event on server
          $scope.sys = { "system_number": "0", "device_id": $scope.server.id };
          eventsService.createEvent('modsistema', $scope.sys, 'setpoint', new_consign);
        }
      };

      /*
        * Update consign of server (add or dedduct value)
        */
      $scope.updateConsign = function (val) {
        var new_consign,
          valid = false;
        if (($scope.server.units !== undefined) && $scope.server.units === "1") {
          if (val == 0.5) val = 1;
          else if (val == -0.5) val = -1;

          if ($scope.server.consign === DEFAULT_CONSIGN_SERVER)
            $scope.server.consign = '73';

          new_consign = parseInt($scope.server.consign) + val;
          if (new_consign >= 64 && new_consign <= 86) {
            valid = true;
            $scope.server.consign = new_consign;
          }
        } else {

          if ($scope.server.consign === DEFAULT_CONSIGN_SERVER)
            $scope.server.consign = '23.0';

          new_consign = (parseFloat($scope.server.consign) + parseFloat(val)).toFixed(1);
          if (new_consign >= 18.0 && new_consign <= 30.0) {
            valid = true;
            $scope.server.consign = new_consign;
          }
        }

        if (valid) {
          $scope.systems.forEach(function (system) {
            system.server_consign = $scope.server.consign;
          });
        }
      };

      /*
        * Update all systems mode with new server mode
        */
      $scope.listener3 = $scope.$watch('server.new_mode', function (new_val, old_val, scope) {
        if (new_val != null && new_val !== false) {
          $scope.class_mode = $scope.modes[new_val];
          $scope.server.mode = new_val;

          $scope.systems.forEach(function (system) {
            system.new_mode = new_val;
          });
        }
      });

      /*
        * Update all systems auto mode with new server auto mode
        */
      $scope.listener4 = $scope.$watch('server.new_auto', function (new_val, old_val, scope) {
        if (new_val != null) {
          if (new_val) {
            $scope.class_mode = '13';
            $scope.server.mode = '13';
          } else {
            $scope.class_mode = $scope.modes[$scope.mode];
            $scope.server.mode = $scope.mode;
          }

          $scope.systems.forEach(function (system) {
            system.new_mode = $scope.server.mode;
          });
        }
      });

      /*
        * Update all systems scene with new server scene
        */
      $scope.listener5 = $scope.$watch('server.new_scene', function (new_val, old_val, scope) {
        if (new_val != null && new_val !== false) {
          $scope.class_scene = $scope.scenes[new_val];
          $scope.server.scene = new_val;

          $scope.systems.forEach(function (system) {
            system.new_mode = new_val;
          });
        }
      });

      /*
        * Update all systems eco with new server eco
        */
      $scope.listener6 = $scope.$watch('server.new_eco', function (new_val, old_val, scope) {
        if (new_val != null && new_val !== false) {
          $scope.class_eco = $scope.ecos[new_val];
          $scope.server.eco = new_val;

          $scope.systems.forEach(function (system) {
            system.new_eco = new_val;
          });
        }
      });

      /*
        * Update all systems velocity with new server velocity
        */
      $scope.listener7 = $scope.$watch('server.new_velocity', function (new_val, old_val, scope) {
        if (new_val != null && new_val !== false) {
          $scope.class_vel = new_val;
          $scope.server.velocity = new_val;

          $scope.systems.forEach(function (system) {
            if (system.velocity)
              system.new_velocity = new_val;
          });
        }
      });

      /*
        * Update all systems airflow with new server airflow
        */
      $scope.listener8 = $scope.$watch('server.new_air_flow', function (new_val, old_val, scope) {
        if (new_val != null && new_val !== false) {
          $scope.class_air_flow = new_val;
          $scope.server.air_flow = new_val;

          $scope.systems.forEach(function (system) {
            if (system.air_flow)
              system.new_air_flow = new_val;
          });
        }
      });

      $scope.getIconVelocity = function () {
        if ($scope.class_vel !== null) {
          return "icon-velocity" + $scope.class_vel;
        } else if ($scope.server.has_velocity) {
          // TODO: poner icono genérico -> 4 tubos
          return "icon-speed-generic";
        } else {
          //no hay aire
          return "icon-velocity0";
        }
      }

      /*
        * Check if user is basci
        */
      $scope.isAdvanced = function () {
        if ($scope.relation)
          return ($scope.relation.type == 'advanced');
        else
          return false;
      };

      /*
        * Manage toggle if user is advanced
        */
      $scope.toggleExpandServer = function () {
        if ($scope.systems.length > 1 && $scope.relation && $scope.relation.type == 'advanced') {
          $scope.expand = !$scope.expand;
          //pasamos la consigna tambien a las dos rayitas por defecto
          $scope.server.consign = DEFAULT_CONSIGN_SERVER;
        }
      };

      $scope.parseInt = function (number) {
        return parseInt(number);
      };

      /*
        * Initialize the weather
        */
      $scope.initializeWeather = function () {
        var aux = Number($scope.date.getHours());
        if (aux == 0) aux = 24;
        $rootScope.current_spot_name = $scope.server.spot_name;
        if ($scope.server.data.data.weather) {
          if ($scope.server.has_farenheit) {
            $rootScope.current_temp = $scope.server.data.data.weather[0].hourly[aux].tempF;
          } else {
            $rootScope.current_temp = $scope.server.data.data.weather[0].hourly[aux].tempC;
          }
          $rootScope.current_weather_icon = $scope.weatherIcon($scope.server.data.data.weather[0].hourly[aux].weatherCode);

          var lang = sessionService.language();
          if (lang == 'es')
            $scope.current_description = $scope.server.data.data.weather[0].hourly[aux]['lang_es'][0].value;
          else if (lang == 'en')
            $scope.current_description = $scope.server.data.data.weather[0].hourly[aux]['weatherDesc'][0].value;
          else
            $scope.current_description = '';

          // Hourly weather
          $scope.forecast = $scope.server.data.data.weather[0].hourly.slice(aux + 2, aux + 8).filter(function (n, index) {
            return (aux + 2 + index <= $scope.server.data.data.weather[0].hourly.length);
          });

          if ($scope.forecast.length < 6) {
            var n = 6 - $scope.forecast.length + 1;
            for (var i = 1; i < n; i++) {
              $scope.forecast.push($scope.server.data.data.weather[1].hourly[i]);
            }
          }

          // Next day weather
          $scope.server.data.data.weather.shift();
          $scope.daily = $scope.server.data.data.weather;
        } else {
          $rootScope.show_climate_info = false;
        }
      }

      $scope.weatherIcon = function (weather_code) {
        if (weather_code == undefined)
          return undefined;

        return {
          113: "icon-weather1",   // Clear/Sun
          116: "icon-weather2",   // Partly Cloudy
          119: "icon-weather3",   // Cloudy
          122: "icon-weather3",   // Overcast
          143: "icon-weather14",  // Mist
          176: "icon-weather4",   // Patchy rain nearby
          179: "icon-weather12",  // Patchy snow nearby
          182: "icon-weather7",   // Patchy sleet nearby
          185: "icon-weather7",   // Patchy freezing drizzle nearby
          200: "icon-weather18",  // Thundery outbreaks in nearby
          227: "icon-weather6",   // Blowing snow
          230: "icon-weather6",   // Blizzard
          248: "icon-weather14",  // Fog
          260: "icon-weather14",  // Freezing fog
          263: "icon-weather9",   // Patchy light drizzle
          266: "icon-weather4",   // Light drizzle
          281: "icon-weather7",   // Freezing drizzleFreezing drizzle
          284: "icon-weather7",   // Heavy freezing drizzle
          293: "icon-weather4",   // Patchy light rain
          296: "icon-weather5",   // Light rain
          299: "icon-weather10",  // Moderate rain at times
          302: "icon-weather5",   // MOderate rain
          305: "icon-weather10",  // Heavy rain at times
          308: "icon-weather5",   // Heavy rain
          311: "icon-weather7",   // Light freezing rain
          314: "icon-weather7",   // Moderate or Heavy freezing rain
          317: "icon-weather9",   // Light sleet
          320: "icon-weather6",   // Moderate or heavy sleet
          323: "icon-weather11",  // Patchy light snow
          326: "icon-weather11",  // Light snow
          329: "icon-weather6",   // Patchy moderate snow
          332: "icon-weather6",   // Moderate snow
          335: "icon-weather6",   // Patchy heavy snow
          338: "icon-weather6",   // Heavy snow
          350: "icon-weather7",   // Ice pellets
          353: "icon-weather9",   // Light rain shower
          356: "icon-weather10",  // Moderate or heavy rain shower
          359: "icon-weather5",   // Torrential rain shower
          362: "icon-weather12",  // Light sleet showers
          365: "icon-weather12",  // Moderate or heavy sleet showers
          368: "icon-weather11",  // Light snow showers
          371: "icon-weather11",  // Moderate or heavy snow showers
          374: "icon-weather12",  // Light showers of ice pellets
          377: "icon-weather7",   // Moderate or heavy showers of ice pellets
          386: "icon-weather18",  // Patchy light rain in area with thunder
          389: "icon-weather19",  // Moderate or heavy rain in area with thunder
          392: "icon-weather18",  // Patchy light snow in area with thunder
          395: "icon-weather19",  // Moderate or heavy snow in area with thunder

        }[weather_code];
      };

      /*
        * Return date minutes with leading zero
        */
      $scope.getMinutes = function () {
        var minutes = $scope.date.getMinutes();

        if (minutes < 10)
          return '0' + minutes;
        else
          return minutes;
      };

      /*
        * Send system signal to update (watch in zone_row_controller)
        */
      $scope.updateSystems = function () {
        $scope.systems.forEach(function (system) {
          if (system.updated)
            system.reload = true;
        });

        if ($rootScope.demo) {
          $timeout(function () {
            $scope.systems.forEach(function (system) {
              system.updated = true;

              system.zones.forEach(function (zone) {
                zone.updated = true;
              });
            });
          }, 3000);
        }
      };

      /*
        * Update climate date each 10 seconds
        */
      function updateDate() {
        var date = new Date();
        if ($scope.server.data && $scope.server.data.data && $scope.server.data.data.time_zone) {
          var tzDifference = parseFloat($scope.server.data.data.time_zone[0].utcOffset) * 60 + date.getTimezoneOffset();
          $scope.date = new Date(date.getTime() + tzDifference * 60 * 1000);
        }

        if ($scope.date.getHours() >= 21 && $scope.getMinutes() == 00 && $scope.server)
          set_electricity_prices();

        $scope.update_date = setTimeout(function () {
          updateDate();
        }, 10000);
      }

      /*
        * Set electricity prices
        */
      function set_electricity_prices() {
        var minutes = ":00h";
        var max_day = "";
        var min_day = "";
        $scope.server.max_price = Math.max.apply(null, $scope.server.electricity_prices["today"]);
        $scope.server.min_price = Math.min.apply(null, $scope.server.electricity_prices["today"]);
        if ($scope.server.electricity_prices["today"]) {
          $scope.server.hour_max_price = $scope.server.electricity_prices["today"].indexOf($scope.server.max_price);
          $scope.server.hour_min_price = $scope.server.electricity_prices["today"].indexOf($scope.server.min_price);
        }

        if ($scope.server && $scope.server.electricity_prices && $scope.server.electricity_prices["tomorrow"] != [] && $scope.date.getHours() >= 21 && $scope.server.hour_max_price < $scope.date.getHours()) {
          $scope.server.max_price = Math.max.apply(null, $scope.server.electricity_prices["tomorrow"]);
          $scope.server.hour_max_price = $scope.server.electricity_prices["tomorrow"].indexOf($scope.server.max_price);
          max_day = "D+1";
        }
        if ($scope.server && $scope.server.electricity_prices && $scope.server.electricity_prices["tomorrow"] != [] && $scope.date.getHours() >= 21 && $scope.server.hour_min_price < $scope.date.getHours()) {
          $scope.server.min_price = Math.min.apply(null, $scope.server.electricity_prices["tomorrow"]);
          $scope.server.hour_min_price = $scope.server.electricity_prices["tomorrow"].indexOf($scope.server.min_price);
          min_day = "D+1";
        }

        $rootScope.electricity_price = $scope.server.electricity_prices["today"][$scope.date.getHours()];
        $scope.server.hour_max_price = $scope.server.hour_max_price + minutes + max_day;
        $scope.server.hour_min_price = $scope.server.hour_min_price + minutes + min_day;
        var range = ($scope.server.max_price - $scope.server.min_price) / 3;
        if (parseFloat($scope.server.electricity_prices["today"][$scope.date.getHours()]) > $scope.server.max_price - range) {
          $scope.server.price_color = '#ff3000';
          $rootScope.price_color = '#ff3000';
        } else if (parseFloat($scope.server.electricity_prices["today"][$scope.date.getHours()]) < $scope.server.min_price + range) {
          $scope.server.price_color = '#56722a';
          $rootScope.price_color = '#56722a';
        } else {
          $scope.server.price_color = '#ff8400';
          $rootScope.price_color = '#ff8400';
        }
      }

      /*
        * Initialize electricity prices chart
        */
      function init_chart() {
        var range = ($scope.server.max_price - $scope.server.min_price) / 3;
        var xData = [];
        $scope.server.prices_chart = [];
        var item = {};
        var j = 0;
        if ($scope.server && $scope.server.electricity_prices && $scope.server.electricity_prices["tomorrow"].length != 0)
          var k = Number($scope.date.getHours()).toFixed(0);
        else
          k = 0;
        for (var i = k; i < $scope.server.electricity_prices["today"].length; i++) {
          if (parseFloat($scope.server.electricity_prices["today"][i]) > $scope.server.max_price - range)
            item["color"] = "#ff3000";
          else if (parseFloat($scope.server.electricity_prices["today"][i]) < $scope.server.min_price + range)
            item["color"] = "#56722a";
          else
            item["color"] = "#ff8400";

          if (i == Number($scope.date.getHours()).toFixed(0) && $scope.server && $scope.server.electricity_prices && $scope.server.electricity_prices["tomorrow"].length == 0) {
            item["marker"] = {
              symbol: 'diamond',
              fillColor: item["color"],
              lineWidth: 1.5,
              lineColor: '#FFF'
            };
          }
          item["y"] = $scope.server.electricity_prices["today"][i];
          $scope.server.prices_chart.splice(j, 0, item);
          var xAxis = "";
          xAxis = i + "h";
          xData.splice(j, 0, xAxis);
          item = {};
          j++;
        }

        if ($scope.server && $scope.server.electricity_prices && $scope.server.electricity_prices["tomorrow"].length != 0) {
          j = $scope.server.prices_chart.length;

          for (i = 0; i < Number($scope.date.getHours()).toFixed(0); i++) {
            if (parseFloat($scope.server.electricity_prices["tomorrow"][i]) > $scope.server.max_price - range)
              item["color"] = "#ff3000";
            else if (parseFloat($scope.server.electricity_prices["tomorrow"][i]) < $scope.server.min_price + range)
              item["color"] = "#56722a";
            else
              item["color"] = "#ff8400";

            item["y"] = $scope.server.electricity_prices["tomorrow"][i];
            $scope.server.prices_chart.splice(j, 0, item);
            xAxis = i + "h";
            xData.splice(j, 0, xAxis);
            item = {};
            j++;
          }
          item = $scope.server.prices_chart[0];
          item["marker"] = {
            symbol: 'diamond',
            fillColor: item["color"],
            lineWidth: 1.5,
            lineColor: '#FFF'
          };
          $scope.server.prices_chart[0] = item;
        }
        var data = {
          "xData": xData,
          "yData": [{
            "name": "€/Kwh",
            "data": $scope.server.prices_chart
          }]
        };

        $scope.lineChartYData = data.yData;
        $scope.lineChartXData = data.xData;
      }

      /*
        * Hide edit climate info in home
        * Remove root scope watchs
        */
      $scope.$on('$destroy', function () {
        $scope.listener3();
        $scope.listener4();
        $scope.listener5();
        $scope.listener6();
        $scope.listener7();
        $scope.listener8();
        if ($scope.listener_error) $scope.listener_error();
        $rootScope.show_climate_info = false;
        $rootScope.show_climate = false;
        clearTimeout($scope.update_date);
        if ($scope.update_timeout) {
          clearTimeout($scope.update_timeout);
        }
        $scope.server.systems = [];
        $scope.systems = [];
      });
    }]);
