// eslint-disable-next-line no-undef
Airzone.controller('initController', ['$scope', '$rootScope', '$state', '$http', 'websocketsService', '$window', 'MOBILE_APP',
  function ($scope, $rootScope, $state, $http, websocketsService, $window, MOBILE_APP) {

    if (MOBILE_APP) {
      $rootScope.info_hide = true;
    }
    //websocketsService.destroy();

    /*
     * Reload login page
     */
    $scope.reloadLogin = function () {
      $state.go('init.login', {}, { reload: true });
    };

    $scope.browserContinue = function () {
      $rootScope.browser_continue = true;
      $state.go('init.login');
    };

    /*
     * Redirect to itunes apple airzonecloud
     */
    $scope.goToItunesApple = function () {
      if ($rootScope.language === 'en') {
        var lang = 'us';
      } else {
        lang = $rootScope.language;
      }
      var url = 'https://itunes.apple.com/' + lang + '/app/airzone-cloud/id968067256';
      $window.open(url, '_system', 'location=no');
    };

    /*
     * Redirect to play google airzonecloud
     */
    $scope.goToPlayGoogle = function () {
      var url = 'https://play.google.com/store/apps/details?id=es.altra.airzone';
      $window.open(url, '_system', 'location=no');
    };

    initialize();

    function initialize() {
      // eslint-disable-next-line no-undef
      if (!__eolo && !$rootScope.browser_continue) {
        $http({
          method: 'POST',
          // eslint-disable-next-line no-undef
          url: url_path + 'browsers/',
          data: {},
          params: { format: 'json' }
        }).success(function (data) {
          if (!data.supported)
            $state.go('init.browsers');
        });
      }
    }
  }]);
