Airzone.directive('validations', function () {
  return {
    restrict: 'A', // only activate on element attribute
    require: '?ngModel', // get a hold of NgModelController
    replace: true,
    scope: {
      ngModel: '='
    },
    link: function (scope, element, attrs, ngModel) {

      element.bind('propertychange keyup change paste', function (blurEvent) {
        var regExp = /[^ÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛÄËÏÖÜÃÕÑÇA-Záéíóúàèìòùâêîôûäëïöüãõñça-z-,\.!¿?()/\ _0-9\s]/g;
        var kCd = blurEvent.keyCode || blurEvent.which;
        var str = element.val();
        if (scope.$root.isMobile && (kCd == 0 || kCd == 229)) //for android chrome keycode fix
          kCd = str.charCodeAt(str.length - 1);

        if ([8, 27, 37, 38, 39, 40, 46].indexOf(kCd) === -1) {
          ngModel.$setViewValue(element.val().replace(regExp, ''));
          if (str != element.val().replace(regExp, ''))
            ngModel.$render();
        }
      });
    }
  };
});
