Airzone.controller('adminUsersController', ['$scope', '$rootScope', '$http', 'sessionService', '$state', 'userService',
  function ($scope, $rootScope, $http, sessionService, $state, userService) {

    $scope.show_modal = false;
    $scope.modal_status = 'info';
    $scope.current_page = 1;
    $scope.total_pages = 1;
    $scope.total_devices = 0;
    $scope.accordion = 0;
    $scope.select_user = null;
    $scope.emailErrorMessage = false;


    $scope.filterStatus = { text: $rootScope.i18n('all'), value: "" };

    $scope.getUsers = function () {
      $scope.emailErrorMessage = false;
      params = {
        user_token: sessionService.token(),
        user_email: sessionService.email(),
        limit: 10,
        page: $scope.current_page
      };

      if ($scope.filterEmail && $scope.filterEmail.length > 2) {
        params.email = ($scope.filterEmail).toLowerCase();
      } else if ($scope.filterEmail) {
        $scope.emailErrorMessage = true;
        console.log("Debe tener mínimo 3 caracteres");
      }

      if ($scope.orderBy) {
        params.order_by = $scope.orderBy;
        params.order = $scope.orderAsc ? "asc" : "desc";
      }

      if (params.email) {
        userService.getUsers(params).then(
          function (data) {
            $scope.users = data['users'];
            $scope.total_pages = data.total_pages;
            $scope.total_devices = data.count;
          }, function (error) {
          }
        );
      };
    }
    //$scope.getUsers();

    /*
     * Show modal with server info
     */
    $scope.showUserInfo = function (user) {
      $scope.show_modal = true;
      $rootScope.modalShow = true;

      $scope.select_user = $scope.users.filter(function (f) { return (f.id == user.id); });
      if ($scope.select_user) {
        $scope.select_user = $scope.select_user[0];
      }
    };

    /*
     * Close server info modal
     */
    $scope.goToList = function () {
      $scope.show_modal = false;
      $rootScope.modalShow = false;
      $scope.select_user = null;
      $scope.modal_status = 'info';
    };

    $scope.setModalStatus = function (status) {
      $scope.modal_status = status;
    };
  }]);
