var I18n = I18n || {}; I18n.translations = {
	"es": {
		"modal_vertical": {
			"title": "Posiciones de las lamas verticales",
			"position_0": "Inclinación 0: lamas en 90º",
			"position_1": "Inclinación 1: lamas en 72º",
			"position_2": "Inclinación 2: lamas en 54º",
			"position_3": "Inclinación 3: lamas en 36º",
			"position_4": "Inclinación 4: lamas en 0º",
			"position_swing": "Swing: alterna todas las posiciones de las lamas"
		},
		"modal_horizontal": {
			"title": "Posiciones de las lamas horizontales",
			"position_0": "Inclinación 0: lamas en 180º",
			"position_1": "Inclinación 1: lamas en 135º",
			"position_2": "Inclinación 2: lamas en 90º",
			"position_3": "Inclinación 3: lamas en 45º",
			"position_4": "Inclinación 4: lamas en 0º",
			"position_swing": "Swing: alterna todas las posiciones de las lamas"
		},
		"adjust": {
			"title": "Ajustes de usuario",
			"title_admin": "Ajustes de usuario administrador",
			"personal": "Datos personales",
			"language": "Idioma",
			"spanish": "Español",
			"english": "English",
			"french": "Français",
			"italian": "Italiano",
			"portuguese": "Português",
			"german": "Deutsch",
			"password": {
				"password_change": "Cambiar contraseña",
				"new_password_placeholder": "Nueva contraseña",
				"new_password_repeat_placeholder": "Repita nueva contraseña",
				"old_password_placeholder": "Contraseña actual"
			},
			"require": {
				"name": "Indique su nombre",
				"surname": "Indique su apellido"
			},
			"advanced_adjust": "Ajustes avanzados",
			"advanced_adjust_text": "Mostrar ajustes avanzados",
			"time_sync": "Sincronizar fecha y hora",
			"save": {
				"title": "Ajustes guardados",
				"body": "Sus ajustes se han guardado correctamente."
			},
			"unsubscribe_confirm": "¿Desea darse de baja de la aplicación Airzone?  Todos sus datos serán borrados y no podrán recuperarse",
			"unsubscribe": {
				"title": "Baja Confirmada",
				"body": "Ha sido dado de baja de Airzone, todos sus datos personales se han borrado correctamente."
			},
			"change_pass": "Cambiar contraseña",
			"warranty": "Ampliación de la garantía"
		},
		"languages": {
			"es": "Español",
			"en": "Inglés"
		},
		"advanced_adjust": {
			"title": "Ajustes avanzados",
			"subtitle": "Ajustes de sistemas y zonas",
			"all_zones": "Todas"
		},
		"adjust_zone": {
			"zone": "Zona",
			"dir_zone": "Dirección de zona",
			"associatted_exist": "Salidas asociadas",
			"non": "No",
			"yes": "Sí",
			"master": "Maestro",
			"basic_mode": "Modo básico",
			"amb_temp": "Temperatura ambiente",
			"master_dir": "Dirección maestra",
			"builder": "Fabricante",
			"stages": "Etapas",
			"heat": "Calor",
			"heat_air": "Aire",
			"heat_radiant": "Radiante",
			"heat_combined": "Combinado",
			"heat_combo": "Aire+Radiante",
			"cold": "Frío",
			"cold_air": "Aire",
			"cold_radiant": "Radiante",
			"cold_combined": "Combinado",
			"cold_combo": "Aire+Radiante",
			"ventilation": "Ventilación",
			"step1": "Paso 1",
			"step2": "Paso 2",
			"step3": "Paso 3",
			"caudal": "Caudal",
			"q-adapt": "Q-Adapt: Peso Zona",
			"inputs": "Entradas",
			"window": "Ventana",
			"window_normally_open": "Normalmente Abierto",
			"window_normally_closed": "Normalmente Cerrado",
			"presence": "Presencia",
			"presence_normally_open": "Normalmente Abierto",
			"presence_normally_closed": "Normalmente Cerrado",
			"spray_dew": "Sonda Rocío",
			"spray_dew_normally_open": "Normalmente Abierto",
			"spray_dew_normally_closed": "Normalmente Cerrado",
			"local_ventilation": "Ventilación Local",
			"local_vent_normally_open": "Normalmente Abierto",
			"local_vent_normally_closed": "Normalmente Cerrado",
			"information": "Información",
			"firm_term": "Firmware Termostato",
			"firm_module": "Firmware Módulo",
			"warning": "Aviso",
			"errors": "Errores",
			"error": "Error",
			"error3": "Error 3-Rejilla no presente",
			"error4": "Error 4-Rejilla bloqueada",
			"error5": "Error 5-Sonda no presente",
			"error6": "Error 6-Sonda cortocircuitada",
			"error7": "Error 7-Elementos de climatización incompatibles",
			"error8": "Error 8-Pérdida comunicaciones con módulo sonda remota",
			"error9": "Error 9-Alarma de presencia",
			"error10": "Error 10-Alarma de ventana",
			"error11": "Error 11-Alarma de Antihielo",
			"error12": "Zona sin termostato",
			"error15": "Error 15-Fallo de comunicaciones entre medidor de consumo y central del sistema",
			"error16": "Error 16-Error de medida en medidor de consumo",
			"manufacturer_0": "Sin fabricante",
			"manufacturer_1": "Fujitsu General I",
			"manufacturer_2": "Fujitsu General II",
			"manufacturer_3": "Daikin I",
			"manufacturer_4": "Daikin II",
			"manufacturer_5": "LG",
			"manufacturer_6": "Tango",
			"manufacturer_7": "Mitsubishi Electric",
			"manufacturer_8": "Toshiba",
			"manufacturer_9": "Gree",
			"no_errors": "No hay errores",
			"use_adapt_set": "Temperatura modo usuario",
			"zone_weight": "Peso de la zona",
			"eco_function": "Función Eco",
			"function_eco": {
				"1": "Normalmente abierto",
				"2": "Normalmente cerrado"
			},
			"DC_fan_voltage": "Voltaje de ventilador DC",
			"min_volt": "V mínimo",
			"max_volt": "V máximo",
			"constant_ventilation": "Ventilación Constante",
			"cold_battery_temperature": "Temperatura batería frío",
			"heat_battery_temperature": "Temperatura batería calor",
			"relay_title": "Relé paro-marcha AA",
			"relay": "Relé",
			"control_stage": "Etapas de control",
			"cold_control_stage": {
				"0": "Expansión directa",
				"1": "Agua",
				"2": "Aire de apoyo",
				"3": "Suelo",
				"4": "Techo",
				"5": "Eléctrico"
			},
			"heat_control_stage": {
				"0": "Expansión directa",
				"1": "Agua",
				"2": "Aire de apoyo",
				"3": "Suelo",
				"4": "Radiador/Techo",
				"5": "Eléctrico"
			},
			"valves": "Válvulas",
			"help": {
				"direction_zone": "Dirección de zona",
				"direction_zone_text": "Parámetro de sólo lectura que indica la dirección de la zona seleccionada.",
				"slave": "Salidas Asociadas (esclavas)",
				"slave_text": " Parámetro de sólo lectura que indica si la zona seleccionada posee módulos/salidas esclavas y la dirección de las mismas.",
				"master": "Maestro",
				"master_text": "Permite activar la maestría en la zona seleccionada. Permitiendo elegir entre SI y NO.",
				"basic_mode": "Modo Básico",
				"basic_mode_text": "Permite deshabilitar las funciones avanzadas del termostato dejando un control básico para facilitar su uso. Seleccione ON para activar el modo básico. Seleccione OFF en caso contrario.",
				"ambient_temp": "Temperatura Ambiente",
				"ambient_temp_text": "Parámetro de sólo lectura que muestra la temperatura ambiente de la zona con la que el sistema está trabajando.",
				"manufacturer": "Fabricante",
				"manufacturer_text": "Parámetro que indica el fabricante de las tramas",
				"heat_type_config": "Etapa de Calor",
				"heat_type_config_text": "Permite configurar las etapas de calor de la zona. Las opciones a configuración son Aire, Radiante, Combinado y OFF que elimina el modo calor en la zona seleccionada.",
				"cold_type_config": "Etapa de Frío",
				"cold_type_config_text": "Permite configurar las etapas de frío de la zona. Las opciones a configurar son Aire , Radiante , Combinado y OFF que elimina el modo frío en la zona seleccionada.",
				"ventilation": "Ventilation",
				"ventilation_text": "Habilita/Deshabilita la función de ventilación de la zona.",
				"q_weight": "Q-Adapt: Peso Zona",
				"q_weight_text": "En sistemas ACUAZONE / IBPro32 / Innobus Air configurados con Q-Adapt manual, permite modificar el peso total de la zona con valores comprendidos entre 1-99.",
				"window": "Ventana",
				"window_text": "Permite controlar el estado de una zona en función de la apertura de una ventana. En caso de activarse dicha entrada, la climatización de la zona se apagará hasta que se restablezca su estado de reposo. Para activar esta función, configure la lógica de la entrada entre los siguientes valores: Normalmente Abierto o Normalmente cerrado. El valor OFF deshabilita esta función.",
				"presence": "Presencia",
				"presence_text": "Permite controlar el estado de una zona en función de un detector de presencia. En caso de activarse dicha entrada, la climatización de la zona activará el modo Sleep con valor 90. Para activar esta función, configure la lógica de la entrada entre los siguientes valores: Normalmente Abierto o Normalmente cerrado. El valor OFF deshabilita esta función.",
				"spray_dew": "Sonda Rocío",
				"spray_dew_text": "Permite controlar el estado de una zona en función de un detector de presencia. En caso de activarse dicha entrada, la climatización de la zona activará el modo Sleep con valor 90. Para activar esta función, configure la lógica de la entrada entre los siguientes valores: Normalmente Abierto o Normalmente cerrado. El valor OFF deshabilita esta función.",
				"local_vent": "Ventilación Local",
				"local_vent_text": "Activa/desactiva la ventilación local de la zona. Para activar esta función, configure la lógica de la entrada entre los siguientes valores: Normalmente Abierto o Normalmente cerrado. El valor OFF deshabilita esta función.",
				"errors": "Errores",
				"errors_text": "Parámetro de sólo lectura que indica si hay errores activos en la zona seleccionada. En caso que indique SI, pulse sobre el parámetro para desplegar la barra y visualizar los errores activos.",
				"firmt": "Firmware Termostato",
				"firmt_text": "Parámetro de sólo lectura que indica el software del termostato de la zona asociada.",
				"firmm": "Firmware Módulo",
				"firmm_text": "Parámetro de sólo lectura que indica el software del módulo local de la zona asociada.",
				"associatted_exist_text": "Muestra las salidas de control asociadas al termostato.",
				"use_adapt_set_text": "Configure las Tª consigna para cada uno de los modos usuario, en cada zona.",
				"confort_text": "Calor: 15 a 30˚C / 59 a 86˚F (por defecto 20˚C / 68˚F). Frío: 18 a 30˚C / 64 a 86˚F (por defecto 24˚C / 75˚F).",
				"eco_text": "Calor: 15 a 30˚C / 59 a 86˚F (por defecto 19˚C / 66˚F). Frío: 18 a 30˚C / 64 a 86˚F (por defecto 29˚C / 77˚F).",
				"unocupied_text": "Calor: 15 a 22˚C / 59 a 72˚F (por defecto 17,5˚C / 63˚F). Frío: 24 a 30˚C / 75 a 86˚F (por defecto 27˚C / 81˚F).",
				"vacation_text": "Calor: 10 a 16˚C / 50 a 61˚F (por defecto 10˚C / 50˚F). Frío: 29 a 35,5˚C / 84 a 96˚F (por defecto 35˚C / 95˚F).",
				"offset_text": "Corrección de la temperatura ambiente de las distintas zonas del sistema tanto en modo frío como en calor, con un factor de corrección comprendido entre - 2,5˚C y 2,5˚C / -5˚F y 5˚F en pasos de 0,5˚C / 1˚F. Por defecto se encuentra configurado en 0˚C / 0˚F.",
				"stages_text": "Permite configurar las etapas de frío y calor en la zona. Las opciones a configurar son:",
				"air_text": "Habilita el calor por aire en la zona seleccionada.",
				"radiant_text": "Habilita el calor radiante en la zona seleccionada.",
				"air_radiant_text": "Habilita el calor por aire y radiante en la zona y permite al usuario seleccionar la etapa de calor que desea en dicha zona.",
				"inputs_text": "Permite regir el estado de un módulo de zona en función de la detección de presencia o de apertura de una ventana.",
				"window_zbs_text": "Solo actúa sobre el elemento de control del módulo de zona. En una zona en la que está activado este contacto, si se detecta una apertura de ventana, pasados 60 segundos la zona se apaga, mostrando un aviso de Ventana activa en la aplicación. Por defecto está desactivado, si desea activar esta función, defina la lógica del contacto como Normalmente abierto o cerrado.",
				"presence_zbs_text": "Solo actúa sobre el elemento de control del módulo de zona si está asociado a un termostato, en módulos de zona secundarios no funciona. En una zona en la que está activado este contacto, si pasados 5 minutos no se detecta presencia, se activa Temporizador 90, mostrando un aviso de Presencia activa en la aplicación. Por defecto está desactivado, si desea activar esta función, defina la lógica del contacto como Normalmente abierto o cerrado.",
				"information_text": "Este parámetro ofrece información sobre el sistema y los elementos conectados."
			}
		},
		"buttons": {
			"sign_in": "Entrar",
			"cancel": "Cancelar",
			"init": "Comenzar",
			"send": "Enviar",
			"save": "Guardar",
			"accept": "Aceptar",
			"resend_confirmation": "Reenviar confirmación",
			"back": "Volver",
			"icon": "Icono",
			"unsubscribe": "Darse de baja",
			"remove": "Eliminar",
			"restore_factory": "Restaurar de fábrica",
			"next": "Siguiente",
			"prev": "Anterior",
			"close": "Cerrar",
			"recharge": "Recargar",
			"hide": "Ocultar",
			"update_device": "Detectar sistemas",
			"continue": "Continuar",
			"exit": "Salir",
			"help": "Ayuda",
			"select": "Seleccionar",
			"invite": "Invitar",
			"update": "Actualizar"
		},
		"email": "Email",
		"name": "Nombre",
		"surname": "Apellidos",
		"password": "Contraseña",
		"new_password": "Nueva contraseña",
		"repeat_password": "Repetir contraseña",
		"server": {
			"notification": {
				"message": "Ya está disponible la tarifa eléctrica para mañana.",
				"alert_message": {
					"title": "Notificaciones",
					"text": "¿Desea recibir notificaciones de Airzonecloud?",
					"remember": "No preguntarme de nuevo"
				}
			},
			"temp": "Temperatura",
			"months": {
				"0": "ENE",
				"1": "FEB",
				"2": "MAR",
				"3": "ABR",
				"4": "MAY",
				"5": "JUN",
				"6": "JUL",
				"7": "AGO",
				"8": "SEP",
				"9": "OCT",
				"10": "NOV",
				"11": "DIC"
			},
			"days": {
				"0": "Domingo",
				"1": "Lunes",
				"2": "Martes",
				"3": "Miércoles",
				"4": "Jueves",
				"5": "Viernes",
				"6": "Sábado"
			},
			"current_price": "PRECIO ACTUAL",
			"minimum_price": "MÍNIMO",
			"maximum_price": "MÁXIMO",
			"day_price": "PRECIO DEL DÍA",
			"month": "mes",
			"year": "año"
		},
		"state": "Estado",
		"enabled": "Habilitado",
		"sleeping": "Aprovisionado",
		"connected": "Conectado",
		"activated": "Activado",
		"components": "Componentes",
		"historic": "Histórico",
		"system": "Sistema",
		"filters": "Filtros",
		"mac": "Mac",
		"locality": "Localidad",
		"all": "Todos",
		"pin": "PIN",
		"zone_name": "Nombre",
		"connection": "Conexión",
		"compare": "Comparar",
		"day": "Día",
		"month": "Mes",
		"year": "Año",
		"range": "Rango",
		"maximum": "Máximo",
		"minimum": "Mínimo",
		"mode": "Moda",
		"average": "Media",
		"without_data": "No hay datos de consumo",
		"last_calculated": "Último calculado",
		"last_hour_calculated": "Última hora",
		"last_day_calculated": "Último día",
		"last_week_calculated": "Últimos 7 días",
		"periods": "Periodos",
		"minutes": "Minutos",
		"connections_minute": "Conexiones/Minuto",
		"deviation": "Desviación Estándar",
		"full_page": "Ver en pantalla completa",
		"print_chart": "Imprimir gráfico",
		"download_png": "Descargar en PNG",
		"download_jpg": "Descargar en JPG",
		"download_pdf": "Descargar en PDF",
		"download_avg": "Descargar en AVG",
		"download_csv": "Descargar en CSV",
		"download_xls": "Descargar en XLS",
		"view_data_table": "Ver tabla de datos",
		"modal_mode": {
			"stop": "Stop",
			"stop_text": "Modo parado",
			"cold_air": "Frío aire",
			"cold_air_text": "Modo frío",
			"heat_radiant": "Calor radiante",
			"heat_radiant_text": "Modo calor radiante",
			"ventilate": "Ventilación",
			"ventilate_text": "Modo ventilación",
			"heat_air": "Calor aire",
			"heat_air_text": "Modo calor",
			"heat_both": "Calor combinado",
			"heat_both_text": "Modo calor combinado",
			"dehumidify": "Deshumectación",
			"dehumidify_text": "Modo de dehumectación",
			"cold_radiant": "Frío radiante",
			"cold_radiant_text": "Modo frío radiante",
			"cold_both": "Frío combinado",
			"cold_both_text": "Modo frío combinado",
			"auto_text": "Cambia automáticamente entre frío y calor dependiendo de la demanda global.",
			"emergency_heat": "Calor de emergencia",
			"emergency_heat_text": "Activa el calor auxiliar para suministrar aire caliente en caso de fallo mecánico en el el sistema."
		},
		"modal_eco": {
			"title": "Modos de ahorro",
			"eco": "Seleccione entre uno de los modos de ahorro. Cuanto mayor sea la categoría A, mayor será el ahorro de energía, aunque menor la potencia de enfriado."
		},
		"modal_speed": {
			"title": "Modos de velocidad del aire disponibles",
			"speed": "Seleccione entre una de las velocidades. A mayor número, mayor será la velocidad de aire."
		},
		"modal_air_flow": {
			"silence": "La velocidad del ventilador es menor que en el modo estándar para reducir el ruido",
			"standard": "Establecido por defecto. El sistema regula automáticamente la velocidad del ventilador basándose en el número de zonas demandando frío o calor",
			"power": "La velocidad del ventilador es mayor que en el modo estándar para incrementar el caudal"
		},
		"modal_scene": {
			"stop": "El sistema de aire acondicionado permanecerá apagado independientemente de la demanda de las zonas, todas las compuertas motorizadas permanecerán abiertas",
			"confort": "Modo de usuario estándar y por defecto. Seleccione la temperatura de consigna deseada usando los rangos de temperatura preestablecidos",
			"unoccupied": "Esta función ahorra energía cuando el usuario se encuentra ausente por cortos periodos de tiempo. Si se activa el termostato, la zona trabaja en modo confort",
			"night": "El sistema cambia automáticamente la temperatura de consigna 0,5º C/1º F cada 30 minutos hasta un máximo de 4 pasos (2º C/4º F) en 2 horas. Al enfriar, el sistema incrementa la temperatura de consigna; al calentar, el sistema disminuye la temperatura de consigna",
			"eco": "El rango de temperaturas de consigna disponibles cambia para un funcionamiento más eficiente",
			"vacation": "Esta función ahorra energía cuando el usuario está ausente por largos periodos de tiempo"
		},
		"home": {
			"menu": {
				"servers": "Servidores",
				"adjusts": "Usuario",
				"help": "Ayuda",
				"admin": "Servidores",
				"exit": "Cerrar Sesión",
				"admin_users": "Usuarios",
				"admins": "Administradores",
				"connections": "Conexiones"
			},
			"upload": {
				"title": "Hay una nueva versión de Airzone Cloud",
				"description": "Por favor, actualiza la aplicación para poder usarla."
			},
			"maintenance": {
				"title": "Este sitio está temporalmente en mantenimiento",
				"description": "Este sitio se encuentra bajo mantenimiento programado.",
				"text": "Volverá a estar disponible pronto."
			}
		},
		"installations": {
			"return": "Volver",
			"exit": "Salir",
			"finish": "Finalizar",
			"next": "Siguiente",
			"edit": "Editar",
			"error": "Se ha producido un error al conectar con el Webserver Airzone Cloud. Comprueba que tu dispositivo se encuentra conectado a la red WiFi del Webserver Airzone Cloud (AZWSCLOUDxxx)",
			"error_mac_not_found": "Se ha producido un error al obtener la mac del webserver",
			"error_sleeping": "Se ha producido un error, el webserver no está aprovisionado",
			"error_not_connected": "Se ha producido un error, el webserver no puede conectarse a la cloud",
			"retry": "Reintentar",
			"state1": {
				"text": "Asegúrese de que su dispositivo esté correctamente alimentado (LED D11 fijo) y se encuentre dentro del alcance de cobertura."
			},
			"state2": {
				"text": "Compruebe que el dispositivo está alimentado y en modo punto de acceso LED WIFI/D13 fijo."
			},
			"state3": {
				"assisted": "Instalación Asistida",
				"wps": "Instalación WPS"
			},
			"state4": {
				"text": "Cambie su red wifi en los ajustes de su dispositivo por una que comience por AZWSCLOUDxxxx y regrese a este menú.",
				"text1": "En caso de notificación por pérdida de conexión a internet, mantenga la conexión con el dispositivo para poder continuar con el proceso de asociación.",
				"text2": "Seleccione la red wifi que comienza por: AZWSCLOUDxxxx",
				"text3": "Recuerde deshabilitar la red de datos móviles si usa Android 5 o superior",
				"loading_text": "Cambiando a la red seleccionada. Por favor espere...",
				"low_signal": {
					"title": "Cobertura insuficiente",
					"text": "Aproxime el Webserver al router o instale un repetidor Wifi para mejorar la cobertura"
				},
				"auto": "Automático",
				"select_router": "Seleccione el método de asociación a router",
				"auto_info": "Automático. Busca de forma automática las redes Wifi próximas a su Webserver.",
				"manual_info": "Manual. Introduzca los datos de la red WiFi deseada de forma manual."
			},
			"state5": {
				"text": "Seleccione el router que desea asociar:",
				"name": "Nombre",
				"sec": "Seguridad",
				"signal": "Señal",
				"loading_text": "Conectando con el dispositivo. Por favor espere...",
				"loading_text2": "(Si la operación se demora más de un minuto comprobar que la conexión del dispositivo es la correcta)",
				"no_routers": "No se ha encontrado ninguna red WiFi próxima al Webserver Airzone Cloud, comprueba que tu red está activada y visible para su conexión."
			},
			"state6": {
				"text": "Introduzca los datos de su red wifi",
				"none": "Sin seguridad",
				"wep": "WEP",
				"wpa": "WPA/WPA2"
			},
			"state7": {
				"text": "Datos de su configuración de red",
				"ip": "IP",
				"port": "Puerta de enlace",
				"masc": "Máscara de red",
				"manual": "MANUAL",
				"dhcp": "DHCP",
				"loading_text": "Configurando el webserver Airzone. Por favor espere...",
				"advanced": "Ajustes avanzados"
			},
			"state8": {
				"text": "Proceso realizado correctamente"
			},
			"state9": {
				"text": "Una vez asociado el webserver, el LED WIFI/D10 permanecerá parpadeando",
				"select": "Selecciona tu webserver",
				"searching": "Buscando dispositivos Bluetooth",
				"not_found": "Dispositivos Bluetooth no encontrados",
				"not_appear": "¿No aparece su dispositivo?",
				"click_here": "Pulse aquí"
			},
			"state15": {
				"title": "Asociación manual"
			},
			"state16": {
				"title": "¿Cómo obtengo la mac del webserver?",
				"boldtext1": "Mediante la App.",
				"text1": " Desde la pantalla de Servidores, pulse sobre Editar, pulse sobre el icono del lápiz, dentro de este menú podrá consultar la MAC de su dispositivo.",
				"boldtext2": "En la portada del manual",
				"text2": " de usuario del Webserver, dispone de una pegatina donde viene el código del producto, la MAC y el PIN.",
				"boldtext3": "Mediante el termostato Blueface",
				"text3": " (AZxx6BLUEFACEC) (solo si es maestro del sistema 1). Acceda al Menú de Usuario > Información > Webserver y consulte la MAC de su dispositivo.",
				"boldtext4": "Mediante el termostato Think",
				"text4": " (AZxx6THINKx) (solo si es maestro del sistema 1). Acceda al Menú de configuración avanzada (dos pulsaciones prolongadas) > Sistema > Información > Webserver y consulte la MAC de su dispositivo.",
				"boldtext5": "En el dispositivo",
				"text5": " dispone de una pegatina donde viene el código del producto, la MAC y el PIN."
			}
		},
		"login": {
			"rememberme": "Recuérdame",
			"restore_password": "Recordar contraseña",
			"demo": "Demo",
			"router": "Asociar router"
		},
		"register": {
			"sign_up": "Crear cuenta",
			"repeat_password": "Confirmar contraseña"
		},
		"complete_registration": {
			"title": "Complete su registro"
		},
		"messages": {
			"migrated_user": "Usuario registrado en la nueva plataforma",
			"migrated_installation": "Instalación registrada en la nueva plataforma",
			"new_app": "Por favor, instala tu nueva app móvil de Airzonecloud:",
			"errors": {
				"recaptcha_response": "Error al comprobar el recaptcha, por favor inténtelo de nuevo más tarde",
				"recaptcha": "Lo sentimos, el recaptcha no es válido",
				"name_blank": "El nombre no puede estar en blanco",
				"surname_blank": "El apellido no puede estar en blanco",
				"login": "Lo sentimos, el email o la contraseña no son válidos",
				"email": "Lo sentimos, el email no es válido",
				"email_blank": "El email no puede estar en blanco",
				"email_taken": "La dirección de correo ya está en uso",
				"password_length": "La contraseña debe tener al menos 8 caracteres",
				"password_blank": "La contraseña no puede estar en blanco",
				"password_confirmation_check": "Lo sentimos, las contraseñas deben ser iguales",
				"password_confirmation_blank": "Debe confirmar su contraseña",
				"password_new_length": "La nueva contraseña debe tener al menos 8 caracteres",
				"password_new_blank": "La nueva contraseña no puede estar en blanco",
				"invalid_old_password": "La contraseña actual introducida no es correcta.",
				"condition": "Debe aceptar las condiciones de uso y la política de privacidad",
				"token_invalid": "El código de activación es erróneo. Vuelve a solicitar Recordar Contraseña.",
				"email_not_exist": "Lo sentimos pero no existe ninguna cuenta asociada a esa dirección de email",
				"confirmation": "Ha ocurrido un error en la confirmación. Por favor, rellene el formulario y le enviaremos un nuevo email de confirmación.",
				"unconfirmed": "¡Correo no confirmado!",
				"server_blank": "Indique un nombre para el servidor",
				"mac_blank": "Indique la dirección MAC de su servidor",
				"mac_format": "El formato de la MAC es incorrecto",
				"mac_repeated": "Ya existe un servidor con esta MAC",
				"pin_blank": "Indique el código PIN de su servidor",
				"mac_invalid": "La MAC introducida es incorrecta, por favor compruebe que concuerda con los datos del Webserver cloud",
				"pin_invalid": "El PIN introducido no se corresponde con la MAC",
				"server_sleeping": "El Webserver Cloud solicitado no tiene conexión a internet. Comprueba que está asociado al router y que éste dispone de conexión a Internet.",
				"server_taken": "La MAC solicitada ya se encuentra dada de alta en la plataforma Cloud",
				"location_blank": "Indique la localidad donde se encuentra su servidor",
				"server_icon_blank": "Seleccione un icono para representar su servidor",
				"file_error": "El fichero que intentas importar es erróneo. Los formatos soportados son xls, xlsx y csv.",
				"must_select_file": "Debe seleccionar un archivo",
				"not_connected": "Actualmente no tiene conexión a internet y las acciones realizadas no tendrán efecto en su sistema Airzone. Cuando disponga de conexión recargue la página y vuelva a intentarlo.",
				"not_connected_title": "Aviso sobre su conexión a internet",
				"not_connected_device": "Su dispositivo Airzone no se encuentra actualmente conectado a internet por lo que las acciones que realice no tendrán efecto en el mismo. Conecte su dispositivo Airzone a internet , recargue la página y vuelva a intentarlo.",
				"not_connected_device_title": "Aviso sobre la conexión de su dispositivo",
				"error_device": "Se ha producido un error en su dispositivo. Si este mensaje persiste por favor pongase en contacto con su instalador.",
				"registration_already_completed": "Ya se ha completado el registro anteriormente. Por favor inicie sesión con sus datos.",
				"user_taken": "El usuario ya está asociado con el servidor",
				"without_internet_title": "Conexión a Internet",
				"without_internet_text": "Esta aplicación necesita conexión a Internet para funcionar. Por favor, conecte su dispositivo y vuelva a intentarlo",
				"day_blank": "Debe seleccionar un día",
				"mac_length_seek": "Debe tener mínimo 4 dígitos",
				"email_length_seek": "Debe tener mínimo 3 caracteres",
				"not_systems_title": "Aviso de los sistemas",
				"not_systems": "No se han detectado sistemas. Por favor, revise su instalación.",
				"location_title": "Localización desactivada",
				"location_text": "Compruebe que tiene activado la localización en los ajustes de su teléfono.",
				"location_disabled_title": "Localización no habilitada",
				"location_disabled_text": "Compruebe que tiene habilitada la localización para esta aplicación en los ajustes de su teléfono.",
				"bluetooth_disabled_title": "Bluetooth desactivado",
				"bluetooth_disabled_text": "Compruebe que tiene activado el Bluetooth en los ajustes de su teléfono.",
				"bluetooth_connect_device_title": "No se puede conectar con el dispositivo",
				"bluetooth_connect_device_text": "El dispositivo no se encuentra o no se ha podido conectar con él, pruebe a acercarse más.",
				"bluetooth_scan_title": "Error de escaneo",
				"bluetooth_scan_text": "Error al intentar escanear dispositivos Bluetooth."
			},
			"success": {
				"register": "¡Registro correcto!",
				"send_reset_password": "Acabamos de enviarte un email con las instrucciones para que modifiques tu contraseña.",
				"reset_password": "Tu contraseña ha sido modificada",
				"resend_confirmation": "Se ha enviado un nuevo email de confirmación a tu correo.",
				"user_server_added": "La conexión con el servidor se ha realizado correctamente. Podrá acceder desde la ventana de servidores.",
				"user_server_updated": "La información del servidor ha sido actualizada correctamente.",
				"restore_factory": "El servidor ha sido restaurado a su configuración inicial.",
				"added_basic_user_to_server": "El usuario ha sido añadido correctamente al servidor. Pulse Aceptar para acceder a la pantalla de permisos del usuario.",
				"added_advanced_user_to_server": "El usuario ha sido añadido correctamente al servidor.",
				"sync_datetime": "La fecha y hora del servidor se ha actualizado en todos los sistemas correctamente."
			},
			"info": {
				"has_email": "¡Tienes un email!",
				"go_login": "Ir a login",
				"reset_password": "Vuelve al login he introduce tu email y tu nueva contraseña para entrar.",
				"change_password": "Escribe y repite tu nueva contraseña.",
				"send_reset_password": "Hey, le pasa a todo el mundo. Solo indicanos tu dirección de email y te enviaremos un email con las instrucciones para que modifiques tu contraseña.",
				"register_success": "Se ha enviado un email a tu cuenta para verificar su identidad.",
				"complete_registration_success": "El registro se ha completado correctamente. Ya puede iniciar sesión y acceder a la gestión de su dispositivo Airzone.",
				"resend_confirmation": "Escribe tu email y te enviaremos un nuevo email de confirmación.",
				"unconfirmed": "Debe confirmar su correo antes de iniciar sessión",
				"remove_server_admin": {
					"question": "¿Desea eliminar este servidor?",
					"info": "Todos los datos asociados a este servidor serán eliminados."
				},
				"restore_server_admin": {
					"question": "¿Desea restaurar este servidor a la configuración de fábrica?",
					"info": "Todos los datos asociados a este servidor serán reseteados."
				},
				"update_server": "Se está actualizando el servidor. Esta operación puede tardar varios minutos.",
				"update_server_info": "Se va iniciar el proceso de actualización de este webserver a la versión",
				"update_server_confirmation": "¿Desea actualizar este servidor?",
				"browsers": "Lo sentimos, pero el navegador que estás utilizando no es soportado por nuestra aplicación o es una versión muy antigua. Si deseas poder usar la aplicación te recomendamos que instales o actualices uno de los siguientes navegadores.",
				"disable_permission_device": "Es necesario tener permiso en al menos una zona",
				"auto_mode_battery_temperature": "Cambio de modo automático habilitado",
				"purifier_maintenance1": "Realice el mantenimiento de su equipo de purificación/higienización.",
				"purifier_maintenance2": "Realice el mantenimiento de su equipo de purificación/higienización. Acceda al menú de configuración avanzada del sistema para reiniciar el contador (Mantenimiento de purificador).",
				"purifier_maintenance_before": "Realice el mantenimiento de su equipo de purificación/higienización antes de:"
			},
			"title": {
				"reset_password": "Recordar Contraseña",
				"change_password": "Modificar Contraseña",
				"resend_confirmation": "Reenviar confirmación",
				"user_server_added": "Servidor creado correctamente",
				"user_server_updated": "Servidor actualizado correctamente",
				"add_server": "Añadir servidor",
				"edit_server": "Editar servidor",
				"error": "Error",
				"server_data": "Datos del servidor",
				"remove_server": "Eliminar servidor",
				"restore_factory": "Restaurar servidor de fábrica",
				"user_server_added_device": "Usuario añadido correctamente",
				"sync_datetime": "Sincronización correcta",
				"device_permissions": "Permisos de usuario",
				"maintenance": "Mantenimiento",
				"browsers": "Navegador no soportado"
			}
		},
		"servers": {
			"no_results": "No se han encontrado servidores",
			"tags": {
				"servers": "servidores",
				"results": "resultados",
				"page": "Página ",
				"of": " de "
			},
			"new": {
				"add_servers": "Añadir servidores",
				"return": "Volver",
				"from_file": "Desde archivo",
				"select_file": "Seleccione un fichero",
				"select_file2": "Seleccione un archivo",
				"from_form": "Desde formulario",
				"success_add2": "Se han añadido",
				"success_add_f2": "servidores correctamente",
				"success_add1": "Se ha añadido",
				"success_add_f1": "servidor correctamente",
				"failure_add1": "No se ha podido añadir el siguiente servidor",
				"failure_add2": "No se han podido añadir los siguientes",
				"failure_add_f2": "servidores",
				"accept": "Aceptar",
				"success": {
					"added": "Servidor añadido correctamente",
					"added2": "El servidor ha sido correctamente dado de alta en la plataforma"
				}
			}
		},
		"system_config": {
			"0": "Sistema zonificado",
			"1": "Sistema zonificado FC",
			"2": "Instalación 2 tubos",
			"3": "Instalación 4 tubos",
			"4": "RadianT",
			"6": "Airzone INT",
			"title": "Ajustes de sistema",
			"systems": "Sistema",
			"acs_system": "ACS",
			"system_fw": "Firmware",
			"ccp_options": {
				"0": "Aerotermia",
				"1": "2 tubos",
				"2": "4 tubos",
				"3": "RadianT"
			},
			"setup_type": "Tipo de instalación",
			"blueface_number": "Blueface",
			"think_r": "Think R",
			"think_c": "Think C",
			"lite": "Lite",
			"radio_number": "Tacto R",
			"tact_number": "Tacto C",
			"probe_number": "Sonda",
			"firm_ws": "Firmware Webserver",
			"cold_mixing_valve": "Válvula mezclado frío",
			"heat_rele_board_number": "Nº placas de reles en calor",
			"cold_rele_board_number": "Nº placas de reles en frío",
			"combi_rele_board_number": "Nº placas de reles en combo",
			"rele_fw_1": "Firmware Placa Relés 1",
			"rele_fw_2": "Firmware Placa Relés 2",
			"rele_fw_3": "Firmware Placa Relés 3",
			"rele_fw_4": "Firmware Placa Relés 4",
			"rele_fw_5": "Firmware Placa Relés 5",
			"rele_fw_6": "Firmware Placa Relés 6",
			"rele_fw_7": "Firmware Placa Relés 7",
			"rele_fw_8": "Firmware Placa Relés 8",
			"fancoil_gw": "Pasarela Fancoil",
			"vent_gw": "Pasarela Ventilación",
			"dehum_gw": "Pasarela Deshumi",
			"heat_eme_gw": "Pasarela EmeHeat",
			"heat_aux_gw": "Pasarela AuxHeat",
			"knx_gw": "Pasarela KNX",
			"radio_switchboard_fw": "Módulo válvulas radio",
			"ccp": "CCP",
			"system_number": "Nº sistema",
			"direction_system": "Dirección del sistema",
			"etapes": "Etapas",
			"cold_mode": "Modo frío",
			"heat_mode": "Modo calor",
			"cold_mode_combined": "Frío Combinado",
			"heat_mode_combined": "Calor Combinado",
			"min_temp": "Temp. Mínima",
			"max_temp": "Temp. Máxima",
			"hysteresis": "Histéresis",
			"cold_hysteresis": "Histéresis Frío",
			"heat_hysteresis": "Histéresis Calor",
			"protect_temp": "Temperatura de Protección",
			"cauldron": "Caldera",
			"cold_aco_air": "Aire Acondicionado - Modo Frio",
			"heat_aco_air": "Aire Acondicionado - Modo Calor",
			"radiant_algorithm": "Algoritmo radiante",
			"proportional_operture": "Apertura Proporcional",
			"min_air": "Aire mínimo",
			"master_installation": "Supermaestro",
			"q_adapt": "Q-Adapt",
			"q_adapt_c": "Q-Adapt",
			"q_adapt_d": "Q-Adapt",
			"q_adapt0": "estandar",
			"q_adapt1": "potencia",
			"q_adapt2": "silencio",
			"q_adapt3": "mínimo",
			"q_adapt4": "máximo",
			"min_volt": "Tensión Mínimo",
			"max_volt": "Tensión Máximo",
			"q_adaptd1": "Manual",
			"q_adaptd0": "Automático",
			"ventilation_mode": "Ventilación",
			"system_weight": "Peso del sistema",
			"work_mode": "Modo de ventilación",
			"work_mode_ext_air": "Aire exterior",
			"work_mode_recirc": "Recircular",
			"work_mode_combined": "Combinado",
			"air_ventilate": "Climatizar y Ventilar",
			"overall_ventilation": "Entrada Ventilación Global",
			"normally_open": "Normalmente Abierto",
			"normally_closed": "Normalmente Cerrado",
			"busy": "Freecooling Ocupado",
			"unoccupied": "Freecooling Desocupado",
			"delta": "Delta",
			"ext_temp": "Temperatura Exterior",
			"setpoint_temperature": "Temperatura Consigna",
			"input_output": "Entrada/Salida",
			"input": "Entrada",
			"probe": "Sonda",
			"stop_start": "Paro-Marcha",
			"output": "Salida",
			"demand_vmc": "Demanda VMC",
			"demand_radiant": "Radiante",
			"air": "Aire",
			"minutes": "Minutos",
			"caudal": "Caudal",
			"information": "Información",
			"errors": "Errores",
			"error0": "No hay error",
			"error1": "Error pasarela máquina",
			"global_ventilation": "Ventilación global",
			"global_ventilation_every": "Cada (min)",
			"global_ventilation_run_for": "Durante (min)",
			"antifreeze": "Antihielo",
			"auxiliary_heat": "Calor auxiliar",
			"available_stages": "Etapas disponibles",
			"first_supply_heat": "Etapa principal",
			"aux_heat": "Calor aux.",
			"heat_pump": "Bomba de calor",
			"fan_configuration": "Conf. ventilador",
			"electric": "Eléctrico",
			"furnace": "Quemador",
			"delay": "Retardo",
			"first_stage": "Primera etapa",
			"min_time_exhausted": "Tiempo mín. funcionamiento",
			"second_stage": "Segunda etapa",
			"differential": "Diferencial",
			"min_time": "Tiempo mín.",
			"autochange": "Modo Auto",
			"setpoint_differential": "Diferencial temp.",
			"mode_switching_protection1": "Protección cambio",
			"mode_switching_protection2": "modo (mín.)",
			"heat_ovr": "Dif. imposición calor",
			"away_mode_settings": "Conf. modos ausente",
			"override_time1": "Inhabilitar",
			"override_time2": "durante (min)",
			"room_temp": "Temperatura ambiente",
			"test_rocio": "Protección condensación",
			"rocio_hist": "Nivel de protección",
			"rocio_hist_very_high": "Muy alto",
			"rocio_hist_high": "Alto",
			"rocio_hist_med": "Medio",
			"rocio_hist_low": "Bajo",
			"rocio_hist_very_low": "Muy bajo",
			"test_rocio_off": "La protección frente a condensaciones estará desactivada durante 1h, pasado este tiempo el nivel de protección será ",
			"temperature_range": "Rango de temperatura",
			"stage_configuration": "Configuración calor",
			"temp_gap": "Diferencia temp.",
			"firmMC": "Medidor consumo",
			"plenum_mode_title": "Modo standby",
			"hitachi_prot_title": "Modo protección - Retardo en el cierre de los elementos motorizados",
			"battery_temperature": "Temperatura Batería",
			"min_battery_temperature": "Temperatura batería calor",
			"max_battery_temperature": "Temperatura batería frío",
			"auto_mode_battery_temperature": "Cambio de Modo automático en función de Temperatura de batería",
			"deshumidifier_function": "Función Deshumectador",
			"humidity_limit": "Humedad límite",
			"disabled": "Deshabilitada",
			"air_supply_settings": "Ajuste aire de apoyo",
			"air_supply_type": "Tipo de aire de apoyo",
			"fan_delay": "Retardo Ventilador",
			"standby_mode_label1": "Estado de la unidad",
			"standby_mode_label2": " en confort",
			"standby_mode": {
				"0": "Apagada",
				"1": "Standby"
			},
			"wsccp_activation_delay": "Retardo de activación",
			"wsccp_cold_heat_tai": "Temperaturas TAI",
			"wsccp_desactivation_acs": "Función ACS",
			"purifier_control": "Mantenimiento del purificador",
			"units": "Unidades",
			"hours": "Horas",
			"days": "Días",
			"timer": "Tiempo",
			"counter": "Contador",
			"reset": "Reset",
			"fixed_time": "Tiempo fijado",
			"production": "Producción",
			"help": {
				"direction_system": "Dirección del Sistema",
				"direction_system_text": "Indica la dirección del sistema.",
				"temp_ranges": "Rango de temperaturas",
				"temp_ranges_text": "Permite seleccionar los rangos de temperatura mínimos (modo frío) y máximo (modo calor) del sistema.",
				"combined_steps": "Etapas combinadas",
				"combined_steps_text": "Permite definir la diferencia de temperatura entre consigna y ambiente que queremos fijar, para que en modo combinado pase de utilizar las etapas de Aire y Radiante a sólo la de Radiante.",
				"protect_temp": "Temperatura de Protección",
				"protect_temp_text": "Permite definir las temperaturas en las que se apagará la caldera o el áire acondicionado para que no sufran daños.",
				"radiant_algorithm": "Algoritmo radiante",
				"radiant_algorithm_text": "Configure el intervalo de funcionamiento del algoritmo de eficiencia radiante. Este algoritmo adapta el funcionamiento del elemento radiante controlado para evitar sobrecalentamientos debidos a posibles inercias térmicas, por ejemplo, en suelos radiantes.",
				"proportional_operture": "Apertura Proporcional",
				"proportional_operture_text": "Permite seleccionar entre apertura total o cerrada.",
				"min_air": "Aire mínimo",
				"min_air_text": "Esto permite la entrada de un caudal mínimo de aire en zonas donde se haya llegado a consigna. De esta forma, el sistema permite realizar el aporte de aire de ventilación a través de los conductos de climatización. Si la zona está apagada la compuerta se cerrará completamente.",
				"master_installation": "Maestro de Instalación",
				"master_installation_text": "Permite imponer el modo de funcionamiento del sistema 1 al resto de sistemas conectados.",
				"q_adapt": "Q-Adapt",
				"q_adapt_text": "Es una función de los sistemas Airzone para adaptar el caudal del equipo de aire a las necesidades del sistema / zona.",
				"q_adapt_power": "Q-Potencia - Favorece el aumento de caudal en el mapa de velocidades.",
				"q_adapt_standard": "Q-Estándar - Configuración por defecto.",
				"q_adapt_max": "Q-Máximo - El sistema trabaja a velocidad máxima, independientemente del número de zonas.",
				"q_adapt_min": "Q-Silencio - Favorece la reducción del ruido.",
				"q_adapt_silent": "Q-Mínimo - Trabaja a velocidad mínima el sistema.",
				"ventilation_mode_weight": "Modo Ventilación - Peso del sistema",
				"ventilation_mode_weight_text": "Permite configurar la relevancia del sistema hacia la Central de Control de Ventilación.",
				"ventilation_mode_work": "Modo Ventilación - Modo de funcionamiento",
				"ventilation_mode_work_text": "Este parámetro permite seleccionar el tipo de ventilación desead en el sistema.",
				"ventilation_mode_clim": "Modo Ventilación - Climatizar y Ventilar",
				"ventilation_mode_clim_text": "Permite configurar los niveles de funcionamiento del sistema para que Climatizar y Ventilar trabajan simultáneamente.",
				"ventilation_mode_global": "Modo Ventilación - Entrada Ventilación Global",
				"ventilation_mode_global_text": "Activa la ventilación local de todas las zonas. Debe seleccionar la lógica de la entrada entre \"Normalmente abierto\" o \"Normalmente cerrado\".",
				"free_cooling_busy": "FreeCooling - Ocupado",
				"free_cooling_busy_text": "Permite activar el funcionamiento freecooling en modo ocupado. Delta permite establecer el diferencial de temperatura para la activación del Freecooling.",
				"free_cooling_unoccupied": "FreeCooling - Desocupado",
				"free_cooling_unoccupied_text": "Permite activar el funcionamiento freecooling en modo desocupado.",
				"ext_temp": "Temperatura exterior - Parámetro que permite establecer la temperatura exterior deseada. Cuando T.amb exterior < T.ext configurada entrará en funcionamiento el Freecooling.",
				"consign": "Consigna: Parámetro que permite establecer la temperatura interior deseada. Cuando T.amb interior < T.int configurada se activará el Freecooling.",
				"input": "Entrada",
				"input_text": "Permite configurar la entrada:",
				"input_probe": "Sonda - Permite medir mediante una sonda externa la temperatura de retorno de un equipo de aire acondicionado.",
				"input_door": "Puerta - Permite realizar un paro-marcha remoto del sistema. Al volver a iniciar el sistema, éste vuelve al estado anterior a la parada.",
				"exit": "Salida",
				"exit_text": "Permite configurar la salida",
				"global_ventilation": "Permite activar el modo ventilación en todas las zonas cuando el sistema no tenga demanda en alguna zona. Por defecto se encuentra desactivado. Al activarlo debe configurar los parámetros: Cada (min) y Durante (min).",
				"global_ventilation_every": "Configure la duración del intervalo (en minutos) entre los períodos de activación de la ventilación global. Configurable de 5 a 40 minutos, en pasos de 5 minutos (por defecto, 15 minutos).",
				"global_ventilation_run_for": "Configure el tiempo (en minutos) durante el cual la ventilación global estará funcionando. Configurable de 5 a 20 minutos, en pasos de 5 minutos (por defecto, 10 minutos).",
				"auxiliary_heat": "Permite habilitar/deshabilitar el calor auxiliar. Por defecto se encuentra deshabilitado. Cuando el calor auxiliar es habilitado, es necesario configurar los siguientes parámetros.",
				"availables_stages": "Defina cuántas etapas de calor auxiliar tiene el sistema (1 o 2).",
				"main_stage": "Seleccione el primer sistema que suministra calor: Bomba de calor o Calor auxiliar.",
				"ventilation_conf": "Seleccione entre Eléctrico (ventilación encendida) o Quemador (ventilación apagada). Si selecciona Eléctrico configure el retardo del ventilador: Define el tiempo de retardo (en segundos) para encender/apagar el ventilador cuando no hay demanda del calentador auxiliar. Valores: 0, 45, 60 y 120 segundos.",
				"stage_differential": "Define el diferencial de temperatura que el sistema tiene que superar para activar la etapa de calor auxiliar. Valores: 1 a 5˚C / 2 a 10˚F, en pasos de 1˚C / 2˚F (por defecto 1˚C / 2˚F).",
				"stage_hysteresis": "Define la histéresis de funcionamiento de la etapa. Valores: 0,5 a 1˚C / 1 a 2˚F, en pasos de 0,5˚C / 1˚F (por defecto 0,5˚C / 1˚F).",
				"minimum_time_operating": "Define el tiempo mínimo (en minutos) que la etapa principal debe estar activa antes de que se pueda activar la etapa de calor auxiliar. Valores: 0, 45, 60 y 120 minutos (por defecto 45 minutos).",
				"away_modes_conf": "Permite la configuración de los parámetros del modo de usuario desocupado y vacaciones.",
				"hysteresis": "Si la temperatura de consigna es superada por el diferencial definido, la zona dejará de crear demanda. Rango: 1 a 3,5˚C / 2 a 7˚F en pasos de 0,5˚C / 1˚F (por defecto, 3˚C / 6˚F).",
				"during_disable": "Seleccione el tiempo (en minutos) tras el cual se reanudará el modo de ausencia seleccionado cuando un usuario toque la pantalla durante dicho modo. Valores: 10 a 120 minutos en pasos de 10 minutos (por defecto, 60 minutos).",
				"auto_mode": "Esta opción permite al usuario configurar los 3 valores que definen el funcionamiento del cambio automático que establece el modo de la unidad interior.",
				"temperature_differential": "Define el diferencial mínimo entre las consignas de los modos frío y calor. Valores: 0 a 3,5˚C / 0 a 7˚F, en pasos de 0,5˚C / 1˚F (por defecto, 1˚C / 2˚F).",
				"change_mode_protection": "Define el tiempo (en minutos) de funcionamiento mínimo de uno de los modos antes de permitir un cambio de modo. Valores: 15, 3, 60 y 90 minutos (por defecto, 30 minutos).",
				"heat_differential": "Si una zona tiene una demanda de calor más alta que esta temperatura definida, el sistema vuelve a funcional en modo calor incluso si la demanda global en modo frío excede la demanda global el modo calor. Valores: Off y de 1,5 a 4˚C / 3 a 8˚F en pasos de 0,5˚C / 1˚F (por defecto, Off).",
				"antifreeze": "Evita que la temperatura ambiente de su zona descienda por debajo de los 12˚C / 53˚F, aunque su zona se encuentre apagada. Está desactivado por defecto.",
				"environment_temperature": "Permite mostrar/ocultar la temperatura ambiente y la humedad relativa de la zona.",
				"heat_configuration": "Permite configurar los siguientes parámetros de la etapa de control Aire+Radiante.",
				"cold_mode": "Permite seleccionar la temperatura mínima para el modo frío (18-26˚C / 64-78˚F, por defecto 18˚C / 64˚F).",
				"heat_mode": "Permite seleccionar la temperatura máxima para el modo calor (19-30˚C / 66-86˚F, por defecto 30˚C / 86˚F).",
				"proportional_operture_zbs": "Configuración del tipo de apertura de la salida de la compuerta: Todo/Nada o Proporcional (por defecto).",
				"minimum_air_zbs": "Permite habilitar/deshabilitar la entrada de un caudal mínimo de aire en zonas donde se haya llegado a consigna.",
				"information": "Ofrece información sobre el sistema y los elementos conectados.",
				"standby_mode_text": "Permite seleccionar para el modo frío y calor el estado de la unidad interior una vez esté en confort.",
				"standby_on": "Los elementos motorizados de las zonas encendidas (On) permanecen abiertos cuando no hay demanda.",
				"standby_off": "Los elementos motorizados de las zonas encendidas (On) permanecen cerrados cuando no hay demanda."
			}
		},
		"systems": {
			"modes": {
				"stop": "Stop",
				"header": "Modos de funcionamiento",
				"heat": "Calor",
				"heat-air": "Calor aire",
				"heat-radiant": "Calor radiante",
				"heat-both": "Calor combinado",
				"cool": "Frío",
				"cool-air": "Frío aire",
				"cool-radiant": "Frío radiante",
				"cool-both": "Frío combinado",
				"dehumidify": "Deshumectar",
				"ventilate": "Ventilar",
				"auto": "Auto",
				"emergency-heat": "Calor emergencia",
				"heat-supply": "Calor de apoyo",
				"dehumidifier": "Deshumectador"
			},
			"eco": {
				"eco-off": "Eco Off",
				"header": "Eco-Adapt",
				"eco-m": "Eco manual",
				"eco-a": "Eco A",
				"eco-aa": "Eco A+",
				"eco-aaa": "Eco A++"
			},
			"velocity": {
				"header": "Velocidad del Aire",
				"velocity-0": "Auto",
				"velocity-1": "Baja",
				"velocity-2": "Media",
				"velocity-3": "Alta",
				"velocity-mlp": "Velocidad"
			},
			"air_flow": {
				"header": "Control de caudal",
				"air_flow-0": "Silencio",
				"air_flow-1": "Estándar",
				"air_flow-2": "Potencia"
			},
			"scene": {
				"header": "Modos de usuario",
				"confort": "Confort",
				"eco": "Eco",
				"vacation": "Vacaciones",
				"unocupied": "Desocupado",
				"night": "Noche",
				"stop": "Stop"
			},
			"timer": {
				"header": "Temporizador",
				"timer-off": "0 minutos",
				"timer30": "30 minutos",
				"timer60": "60 minutos",
				"timer90": "90 minutos"
			},
			"consumption": {
				"header": "Medidor de consumo"
			},
			"schedules": {
				"weekly": "Programaciones semanales",
				"calendar": "Programaciones de calendario"
			},
			"without_consumption_data": "No se disponen de datos de consumo en este momento, vuelva a revisarlos más tarde. Los datos se actualizan cada hora.",
			"error0": "Error configuración de CCP",
			"error1": "Error de comunicación CCP - Pasarela Aerotermia",
			"error2": "Error comunicación Pasarela Aerotermia - Máquina",
			"error3": "Error de comunicación medidor de consumo",
			"error4": "Error en la medida del medidor de consumo",
			"error5": "Error H2 detectado; retardo de compuerta activado. Para deshabilitarlo acceda al menú de configuración avanzada del sistema (modo protección)",
			"error6": "Aviso configuración: Protecciones y algoritmo de control deshabilitados. Consulte la documentación de la pasarela para habilitar estas funciones.",
			"error7": "Error de máquina:",
			"error8": "ACS activo",
			"error9": "Error de comunicaciones pasarela Altherma - central",
			"error10": "Error de comunicaciones pasarela Altherma - unidad interior"
		},
		"terms": {
			"accept": "Acepto las ",
			"legal_terms": "Condiciones de uso",
			"and": " y la ",
			"privacy": "Política de privacidad",
			"publicity": "Deseo recibir comunicaciones comerciales"
		},
		"device_relations_list": {
			"title": "Servidores",
			"no_servers_title": "¡Bienvenido a Airzone!",
			"no_servers_info": "Para poder empezar a controlar su instalación, debe añadir un servidor a su cuenta.",
			"remove_confirmation": "¿Desea eliminar este servidor de su cuenta?",
			"removed_server": "Servidor eliminado",
			"internal_error": "Error interno",
			"internal_error_message": "Su acción no ha podido realizarse, por favor, inténtelo de nuevo más tarde",
			"actions": {
				"edit": "Editar",
				"config": "Configuración",
				"users": "Usuarios",
				"remove": "Eliminar",
				"close": "Cerrar",
				"accept": "Aceptar",
				"cancel": "Cancelar",
				"confirmation_deleted": "El servidor se ha eliminado con éxito",
				"add_server": "Añadir servidor"
			}
		},
		"add_server": {
			"name_placeholder": "Nombre (casa, oficina, chalet)",
			"mac_placeholder": "MAC (12:AB:34:CD:45:F0)",
			"pin_placeholder": "PIN",
			"location_placeholder": "Localidad del Servidor"
		},
		"add_user_to_device": {
			"title": "Añadir usuario",
			"advanced": "Avanzado",
			"basic": "Básico"
		},
		"users": {
			"title": "Usuarios",
			"basic_user": "Usuarios Básicos",
			"advanced_user": "Usuarios avanzados",
			"basic": "básico",
			"advanced": "avanzado",
			"add_user": "Añadir usuario",
			"destroy": "Eliminar usuario",
			"remove_confirmation": "¿Está seguro de que desea eliminar el acceso del usuario al servidor?",
			"zones": "Zonas con acceso",
			"blank": "No hay usuarios."
		},
		"admin_users": {
			"confirmed": "Confirmado",
			"results": "Resultados",
			"no_results": "No hay resultados",
			"page": "Página",
			"of": "de",
			"device_modal": {
				"title": "Equipos accesibles por el usuario",
				"no_devices": "El usuario no tiene acceso a ningún equipo",
				"location": "Localización"
			}
		},
		"zones": {
			"warnings": {
				"presence": "Alarma de Presencia",
				"window": "Alarma de Ventana",
				"antifreeze": "Antihielo",
				"dew": "Rocío activo",
				"dew_protection": "Protección Rocío Activa",
				"thermostat": "Zona sin termostato",
				"heat_supply_activated": "Calor de apoyo activado",
				"low_valve_battery": "Batería baja válvula",
				"valve_NTC_alarm": "Alarma NTC2",
				"F05C": "Error F05-C: Sonda de temperatura batería de frío en circuito abierto",
				"F05H": "Error F05-H: Sonda de temperatura batería de calor en circuito abierto",
				"F06C": "Error F06-C: Sonda de temperatura batería de frío en cortocircuito",
				"F06H": "Error F06-H: Sonda de temperatura batería de calor en cortocircuito",
				"dehumidifier_activated": "Deshumectador activado"
			},
			"adjusts": {
				"title": "Ajustes de zona",
				"title_all": "Todas las zonas",
				"name": "Nombre",
				"name_system": "Nombre del sistema",
				"autoturnoff": "Sleep",
				"timer": "Temporizador",
				"defrosting": "Antihielo",
				"local_ventilation": "Ventilación local",
				"lite": "Ajustes Lite",
				"led": "Estado LED",
				"work_mode": "Etapas",
				"cool": "Frío",
				"heat": "Calor",
				"air_direction": "Dirección del aire",
				"lama_direction": "Dirección de lama",
				"horizontal": "Horizontal",
				"vertical": "Vertical",
				"name_explanation": "Permite modificar el nombre de la zona",
				"autoturnoff_explanation": "La zona se apagará automaticamente transcurrido un intervalo de tiempo seleccionado. Cada intervalo de 30 minutos, la temperatura subirá un grado en frío o bajará un grado en calor.",
				"sleep_off": "Off - Desactiva el temporizador",
				"sleep_30": "30 - Activa el temporizador y a los 30 minutos apagará la zona.",
				"sleep_60": "60 - Activa el temporizador y a los 60 minutos apagará la zona.",
				"sleep_90": "90 - Activa el temporizador y a los 90 minutos apagará la zona.",
				"defrosting_explanation": "Si el sistema detecta que la temperatura ambiente es inferior a 12ºC, se enciende automáticamente en modo calor.",
				"work_mode_explanation": "Permite seleccionar que método utilizar para enfriar o calentar una zona, cuando la instalación permite hacerlo mediante aire o suelo radiante:",
				"mode_air": "Aire - Solo mediante aire",
				"mode_radiant": "Radiante - Solo mediante suelo radiante",
				"mode_mix": " - Utiliza una combinación de ambos",
				"air_direction_explanation": "Permite seleccionar la dirección del aire en frío y en calor.",
				"local_ventilation_explanation": "Esta opción permite activar o desactivar la ventilación en la zona seleccionada cuando el sistema no está enfriando o calentando alguna zona de manera activa",
				"lite_explanation": "Permite configurar el LED del termostato Lite cable para que pasados unos segundos se apague"
			},
			"schedules": {
				"title": "Programaciones",
				"init": "Inicio",
				"end": "Fin",
				"days": "Días",
				"monday": "L",
				"tuesday": "M",
				"wednesday": "X",
				"thursday": "J",
				"friday": "V",
				"saturday": "S",
				"sunday": "D",
				"add_schedule": "Añadir Programación",
				"schedules_types": "Tipos de programación",
				"temp": "Temp",
				"mode": "Modo",
				"vent": "Ventilación",
				"start_hour": "Hora de Inicio",
				"end_hour": "Hora de fin",
				"start_mode": "Modo Inicio",
				"end_mode": "Modo Fin",
				"start_temp": "Temp. Inicio",
				"end_temp": "Temp. Fin",
				"start_vent": "Vent. Inicio",
				"end_vent": "Vent. Fin",
				"close": "Cerrar",
				"remove": "Eliminar",
				"save": "Guardar",
				"enable": "Habilitar",
				"hour": "Hora",
				"temperature": "Temperatura",
				"velocity": "Velocidad",
				"vel_0": "Automático",
				"vel_1": "Velocidad 1",
				"vel_2": "Velocidad 2",
				"vel_3": "Velocidad 3",
				"new_prog": "Nueva Programación",
				"edit_prog": "Editar Programación",
				"schedules_name": "Nombre",
				"remove_confirmation": "¿Está seguro que desea eliminar la programación de forma permanente?",
				"all_zones": "Todas las zonas",
				"systems": "sistemas",
				"waiting_title": "Creando programaciones",
				"waiting_text": "En este momento se están creando las programaciones. Este proceso puede tardar algunos segundos hasta que se creen en todas las zonas.",
				"save_confirmation": "¿Desea guardar la programación realizada?",
				"edit_confirmation": "¿Desea guardar los cambios realizados?",
				"invalid_dates_range": "No se puede guardar la programación para el rango de fecha y zona seleccionados"
			}
		}
	},
	"en": {
		"adjust": {
			"title": "User settings",
			"title_admin": "Admin user settings",
			"personal": "Personal details",
			"language": "Language",
			"spanish": "Español",
			"english": "English",
			"french": "Français",
			"italian": "Italiano",
			"portuguese": "Português",
			"german": "Deutsch",
			"password": {
				"password_change": "Change password",
				"new_password_placeholder": "New password",
				"new_password_repeat_placeholder": "Repeat new password",
				"old_password_placeholder": "Current password"
			},
			"require": {
				"name": "Name",
				"surname": "Surname"
			},
			"advanced_adjust": "Advanced settings",
			"advanced_adjust_text": "Show advanced settings",
			"time_sync": "Synchronize date and time",
			"save": {
				"title": "Saved settings",
				"body": "All your settings have been saved."
			},
			"unsubscribe_confirm": "Do you want to unsubscribe from Airzone app? All your personal details will be deleted and it will be impossible to restore them.",
			"unsubscribe": {
				"title": "You have been unsubscribed",
				"body": "You have been unsubscribed from Airzone. All your personal details have been successfully deleted"
			},
			"change_pass": "Change password",
			"warranty": "Warranty extension"
		},
		"languages": {
			"es": "Spanish",
			"en": "English"
		},
		"advanced_adjust": {
			"title": "Advanced settings",
			"subtitle": "Zone and system settings",
			"all_zones": "All"
		},
		"adjust_zone": {
			"zone": "Zone",
			"dir_zone": "Zone address",
			"associatted_exist": "Linked zones",
			"non": "No",
			"yes": "Yes",
			"master": "Master",
			"basic_mode": "Basic mode",
			"amb_temp": "Room temperature",
			"master_dir": "Master address",
			"builder": "Manufacturer",
			"stages": "Stages",
			"heat": "Heating",
			"heat_air": "Air",
			"heat_radiant": "Radiant",
			"heat_combined": "Combined",
			"heat_combo": "Air+Radiant",
			"cold": "Cooling",
			"cold_air": "Air",
			"cold_radiant": "Radiant",
			"cold_combined": "Combined",
			"cold_combo": "Air+Radiant",
			"ventilation": "Ventilation",
			"step1": "Step 1",
			"step2": "Step 2",
			"step3": "Step 3",
			"caudal": "Flow",
			"q-adapt": "Q-Adapt: Zone weight",
			"inputs": "Inputs",
			"window": "Window",
			"window_normally_open": "Normally Open",
			"window_normally_closed": "Normally Closed",
			"presence": "Presence",
			"presence_normally_open": "Normally Open",
			"presence_normally_closed": "Normally Closed",
			"spray_dew": "Dew probe",
			"spray_dew_normally_open": "Normally Open",
			"spray_dew_normally_closed": "Normally Closed",
			"local_ventilation": "Local Ventilation",
			"local_vent_normally_open": "Normally Open",
			"local_vent_normally_closed": "Normally Closed",
			"information": "Information",
			"firm_term": "Firmware Thermostat",
			"firm_module": "Firmware Module",
			"warning": "Warning",
			"errors": "Errors",
			"error": "Error",
			"error3": "Error 3-Grille not present",
			"error4": "Error 4-Blocked grille",
			"error5": "Error 5-Probe not present",
			"error6": "Error 6-Short circuit in probe",
			"error7": "Error 7-Incompatible AC elements",
			"error8": "Error 8-Lost communication with remote probe module",
			"error9": "Error 9-Presence alarm",
			"error10": "Error 10-Window alarm",
			"error11": "Error 11-Anti-freezing alarm",
			"error12": "Zone without thermostat",
			"error15": "Error 15-Energy meter-Main Control Board communication error",
			"error16": "Error 16-Energy meter measurement error",
			"manufacturer_0": "No manufacturer",
			"manufacturer_1": "Fujitsu General I",
			"manufacturer_2": "Fujitsu General II",
			"manufacturer_3": "Daikin I",
			"manufacturer_4": "Daikin II",
			"manufacturer_5": "LG",
			"manufacturer_6": "Tango",
			"manufacturer_7": "Mitsubishi Electric",
			"manufacturer_8": "Toshiba",
			"manufacturer_9": "Gree",
			"no_errors": "No errors",
			"use_adapt_set": "User mode setpoint",
			"zone_weight": "Weight",
			"eco_function": "Eco function",
			"function_eco": {
				"1": "Normally Open",
				"2": "Normally Closed"
			},
			"DC_fan_voltage": "DC fan voltage",
			"min_volt": "V minimum",
			"max_volt": "V maximum",
			"constant_ventilation": "Steady ventilation",
			"cold_battery_temperature": "Cold battery temperature",
			"heat_battery_temperature": "Hot battery temperature",
			"relay_title": "AC start-stop relay",
			"relay": "Relay",
			"control_stage": "Control stages",
			"cold_control_stage": {
				"0": "DX",
				"1": "Water",
				"2": "Air supply",
				"3": "Underfloor",
				"4": "Ceiling",
				"5": "Electric"
			},
			"heat_control_stage": {
				"0": "Electric",
				"1": "Water",
				"2": "Air supply",
				"3": "Underfloor",
				"4": "Radiator/Ceiling",
				"5": "Electric"
			},
			"valves": "Valves",
			"help": {
				"direction_zone": "Zone address",
				"direction_zone_text": "Read-only parameter that displays the address of the selected zone.",
				"slave": "Subordinate outputs",
				"slave_text": "Read-only parameter indicating if there are modules/subordinate outputs in the selected zone and the address of the selected zone.",
				"master": "Master",
				"master_text": "It allows the activation of the master features in the selected zone, ***** It allows you to the activate the mastery of the zone selected. Permitiendo elegir entre SI y NO.",
				"basic_mode": "Basic Mode",
				"basic_mode_text": "It allows you to deactivate the advanced functions of the thermostat and use only the basic control. Select ON to activate the basic use. Select OFF to deactivate it.",
				"ambient_temp": "Room Temperature",
				"ambient_temp_text": "Only-read parameter that shows the room temperature of the operating zone/the zone the system is working",
				"manufacturer": "Manufacturer",
				"manufacturer_text": "Parameter that indicates the manufacturer of the IR commands",
				"heat_type_config": "Heating stage",
				"heat_type_config_text": "It allows configuring the heating stages of the zone. These are the available options: Air, Radiant, Combined and OFF (heating mode will be deactivated in the zone).",
				"cold_type_config": "Cooling Stage",
				"cold_type_config_text": "It allows configuring the cooling stages of the zone. These are the available options: Air, Radiant, Combined and OFF (cooling mode will be deactivated in the zone).",
				"ventilation": "Ventilation",
				"ventilation_text": "It activates/deactivates the ventilation in the zone.",
				"q_weight": "Q-Adapt: Zone Weight",
				"q_weight_text": "In ACUAZONE / IBPro32 / Innobus Air systems configured with manual Q-Adapt, the total weight of the zone can be modified from 1 to 99.",
				"window": "Window",
				"window_text": "It allows controlling the zone state based on the opening of a window. If this input is activated, the air conditioning of the zone will switch off until the Stand-by state is restored. To activate this function, configure the input logic choosing Normally open or Normally closed. Set OFF to deactivate this function.",
				"presence": "Presence",
				"presence_text": "It allows controlling the state of a zone based on a presence sensor. If this input is activated, the air conditioning of the zone will activate the sleep mode(value 90). To activate this function, configure the input logic choosing Normally open or Normally closed. Set OFF to deactivate this function.",
				"spray_dew": "Dew probe",
				"spray_dew_text": "It allows controlling the state of a zone based on a presence sensor. If this input is activated, the air conditioning of the zone will activate the sleep mode(value 90). To activate this function, configure the input logic choosing Normally open or Normally closed. Set OFF to deactivate this function.",
				"local_vent": "Local ventilation",
				"local_vent_text": "It activates/deactivates the local ventilation of the zone. To activate this function, configure the input logic choosing Normally open or Normally closed. Set OFF to deactivate this function.",
				"errors": "Errors",
				"errors_text": "Only-read parameter that displays if there are active errors in the zone. If it displays 'YES', press on the parameter to see theactive errors.",
				"firmt": "Firmware Thermostat",
				"firmt_text": "Only-read parameter that displays the thermostat software of the associated zone.",
				"firmm": "Firmware Module",
				"firmm_text": "Only-read parameter that displays the local module software of the associated zone.",
				"associatted_exist_text": "Shows displays the control outputs associated with the thermostat.",
				"use_adapt_set_text": "This option is used to configure the set point temperature of each user mode for each zone.",
				"confort_text": "Heat: 15 to 30˚C / 59 to 86˚F by default 20˚C / 68˚F. Cool: 18 to 30˚C / 64 to 86˚F, by default 24˚C / 75˚F.",
				"eco_text": "Heat: 15 to 30˚C / 59 to 86˚, by default 19˚C / 66˚F. Cool: 18 to 30˚C / 64 to 86˚F, by default 29˚C / 77˚F.",
				"unocupied_text": "Heat: 15 to 22˚C / 59 to 72˚F, by default 17.5˚C / 63˚F. Cool: 24 to 30˚C / 75 to 86˚F, by default 27˚C / 81˚F.",
				"vacation_text": "Heat: 10 to 16˚C / 50 to 61˚F, by default 10˚C / 50˚F. Cool: 29 to 35.5˚C / 84 to 96˚F, by default 35˚C / 95˚F.",
				"offset_text": "Correction factor for the room temperature of the zones (range: from -2.5˚C to 2.5 ˚C / -5˚F to 5˚F in steps of 0.5˚C / 1˚F). It is in 0˚C / 0˚F by default.",
				"stages_text": "This parameter is used to select cooling/heating stages in the zone. The features to be configured are:",
				"air_text": "It activates the heating air in the zone.",
				"radiant_text": "It activates radiant heating.",
				"air_radiant_text": "It activates both air and radiant heating in the zone and also allows the user to select the desired heating stage: Air, Radiant or Air+Radiant.",
				"inputs_text": "It determines the state of the module of a zone based on the occupancy or the opening of the window.",
				"window_zbs_text": "This option allows the control the state of a zone module based on the opening of the window. It only has effect on the control element of the zone module. When this contact is on, if it detects any open window, it turns off the zone after 60 seconds (displaying an Open window notification on the app). It is off by default. In order to activate this feature, configure it as Normally open or Normally closed.",
				"presence_zbs_text": "It determines the state of the module of a zone based on the occupancy. It only has effect on the zone module if it is associated to a thermostat (it does not operate in secondary zone modules). When this contact is on, if the zone is empty for over 5 minutes, the Sleep 90 feature is activated (displaying an Occupancy notification on the app). It is off by default. In order to activate this feature, configure it as Normally open or Normally closed.",
				"information_text": "This parameter provides information about the zone and the connected elements."
			}
		},
		"buttons": {
			"sign_in": "Sign in",
			"cancel": "Cancel",
			"init": "Start",
			"send": "Send",
			"save": "Save",
			"accept": "Accept",
			"resend_confirmation": "Resend confirmation",
			"back": "Back",
			"icon": "Icon",
			"unsubscribe": "Unsubscribe",
			"remove": "Remove",
			"restore_factory": "Restore factory settings",
			"next": "Next",
			"prev": "Previous",
			"close": "Close",
			"recharge": "Reload",
			"hide": "Hide",
			"update_device": "Detect systems",
			"continue": "Continue",
			"exit": "Exit",
			"help": "Help",
			"select": "Select",
			"invite": "Invite",
			"update": "Update"
		},
		"email": "Email",
		"name": "Name",
		"surname": "Surname",
		"password": "Password",
		"new_password": "New password",
		"repeat_password": "Repetir contraseña",
		"server": {
			"notification": {
				"message": "Now available the electricity tariff for tomorrow.",
				"alert_message": {
					"title": "Notificacions",
					"text": "Do you wish receive notificacions of Airzonecloud?",
					"remember": "Don't ask me again"
				}
			},
			"temp": "Temperature",
			"months": {
				"0": "JAN",
				"1": "FEB",
				"2": "MAR",
				"3": "APR",
				"4": "MAY",
				"5": "JUN",
				"6": "JUL",
				"7": "AUG",
				"8": "SEP",
				"9": "OCT",
				"10": "NOV",
				"11": "DEC"
			},
			"days": {
				"0": "Sunday",
				"1": "Monday",
				"2": "Tuesday",
				"3": "Wednesday",
				"4": "Thursday",
				"5": "Friday",
				"6": "Saturday"
			},
			"current_price": "CURRENT PRICE",
			"minimum_price": "MINIMUM",
			"maximum_price": "MAXIMUM",
			"day_price": "PRICE OF THE DAY"
		},
		"state": "Status",
		"enabled": "Enabled",
		"sleeping": "Ready",
		"connected": "Connected",
		"activated": "Activated",
		"components": "Components",
		"historic": "Historic",
		"system": "System",
		"filters": "Filters",
		"mac": "Mac",
		"locality": "Locality",
		"all": "All",
		"pin": "PIN",
		"zone_name": "Name",
		"connection": "Connection",
		"compare": "Compare",
		"day": "Day",
		"month": "Month",
		"year": "Year",
		"range": "Range",
		"maximum": "Maximum",
		"minimum": "Minimum",
		"mode": "Mode",
		"average": "Mean",
		"without_data": "There is no consumption data",
		"full_page": "View in full screen",
		"print_chart": "Print chart",
		"download_png": "Download in PNG",
		"download_jpg": "Download in JPG",
		"download_pdf": "Download in PDF",
		"download_avg": "Download in AVG",
		"download_csv": "Download in CSV",
		"download_xls": "Download in XLS",
		"view_data_table": "View data table",
		"modal_mode": {
			"stop": "Stop",
			"stop_text": "Stop mode",
			"cold_air": "Cooling air",
			"cold_air_text": "Cooling mode",
			"heat_radiant": "Radiant heating",
			"heat_radiant_text": "Radiant heating mode",
			"ventilate": "Ventilation",
			"ventilate_text": "Ventilation mode",
			"heat_air": "Heating air",
			"heat_air_text": "Heating mode",
			"heat_both": "Combined heating",
			"heat_both_text": "Combined heating mode",
			"dehumidify": "Dry",
			"dehumidify_text": "Dry mode",
			"cold_radiant": "Radiant cooling",
			"cold_radiant_text": "Radiant cooling mode",
			"cold_both": "Combined cooling",
			"cold_both_text": "Combined cooling mode",
			"auto_text": "The Auto mode allows automatic switching between cool and heat, depending on the global demand.",
			"emergency_heat": "Emergency heat",
			"emergency_heat_text": "This mode activates the Auxiliary Heat to provide heated air in case of a mechanical failure in the system."
		},
		"modal_eco": {
			"title": "Saving modes",
			"eco": "Select one of the available saving modes. The larger the value of the energy rating (A, A+, A++), the higher the energy saving would be (although the cooling power will be decreased)."
		},
		"modal_speed": {
			"title": "Available speed modes",
			"speed": "Select one of the available speeds. The larger the value of the speed, the higher the speed would be."
		},
		"modal_air_flow": {
			"silence": "The fan speed is lower than in standard mode to reduce the noise",
			"standard": "Default setting. The system automatically regulates the fan speed based on the number of zones calling for cooling or heating",
			"power": "The fan speed is higher than in standard mode to increase the flow"
		},
		"modal_scene": {
			"stop": "The air-conditioning system will remain switched off regardless of the demand status of any zone, all the motorized dampers will remain opened",
			"confort": "Default and standard user mode. The desired set point temperature can be selected using the predefined temperature ranges",
			"unoccupied": "To be used when there is no presence detected for short periods of time. A more efficient set point temperature will be set. If the thermostat is activated, the zone will start running in comfort mode",
			"night": "The system automatically changes the set point temperature 0.5º C/1º F every 30 minutes in up to 4 increments of 2º C/4º F in 2 hours. When cooling, the system increases the set point temperature; when heating, the system decreases the set point temperature",
			"eco": "The range of available set point temperatures change for more efficient operation",
			"vacation": "This mode feature saves energy while the user is away for extended periods of time"
		},
		"home": {
			"menu": {
				"servers": "Servers",
				"adjusts": "User",
				"help": "Help",
				"admin": "Servers",
				"exit": "Log out",
				"admin_users": "Users",
				"admins": "Admins",
				"connections": "Connections"
			},
			"upload": {
				"title": "New Airzone Cloud version available",
				"description": "Please, update the app to use it."
			},
			"maintenance": {
				"title": "This site is temporarily in maintenance",
				"description": "This site is currently down for maintenance.",
				"text": "It will be restored soon."
			}
		},
		"installations": {
			"return": "Return",
			"exit": "Exit",
			"finish": "Finish",
			"next": "Next",
			"edit": "Edit",
			"error": "An error occurred while connecting with the Webserver Airzone Cloud. Check if your device is correctly connected to the WiFi network of the Webserver Airzone Cloud (AZWSCLOUDxxx).",
			"error_mac_not_found": "An error occurred, could not find the MAC",
			"error_sleeping": "An error occurred, the webserver has not been provisioned",
			"error_not_connected": "An error occurred, the webserver can not connect to the cloud",
			"retry": "Retry",
			"init_text": "",
			"state1": {
				"text": "Make sure your device is properly powered (D11 LED steady) and is within signal range"
			},
			"state2": {
				"text": "Check the webserver is connected and in WiFi Direct mode, the WiFi/D13 LED is fixed"
			},
			"state3": {
				"assisted": "Assisted installation",
				"wps": "WPS Installation"
			},
			"state4": {
				"text": "Change your Wi-Fi network in the device settings to one beginning with: AZWSCLOUDxxxx",
				"text1": "In case of notification of loss of internet connection, keep the connection to the device in order to continue the association process.",
				"text2": "Select the Wi-Fi network beginning with: AZWSCLOUDxxxx",
				"text3": "Remember to deactivate the mobile data network if you are using Android 5 or later",
				"loading_text": "Changing networks. Please wait...",
				"low_signal": {
					"title": "Limited range",
					"text": "Please place the Webserver closer to the router or install a WiFi range extender"
				},
				"auto": "Automatic",
				"select_router": "Select the type of association with router",
				"auto_info": "Automatic: The system starts searching for available WiFi networks.",
				"manual_info": "Manual: You have to manually enter the data of your WiFi network."
			},
			"state5": {
				"text": "Select the router you want to associate:",
				"name": "Name",
				"sec": "Security",
				"signal": "Signal",
				"loading_text": "Connecting with the device. Please wait...",
				"loading_text2": "(If this operation takes more than a minute check if the connection of the device is correct)",
				"no_routers": "It was impossible to detect any WiFi network. Please check if your WiFi network is visible and it is activated."
			},
			"state6": {
				"text": "Enter the data of your Wi-Fi network",
				"none": "No security",
				"wep": "WEP",
				"wpa": "WPA/WPA2"
			},
			"state7": {
				"text": "Data of your network settings",
				"ip": "IP",
				"port": "Port",
				"masc": "Netmask",
				"manual": "MANUAL",
				"dhcp": "DHCP",
				"loading_text": "Configuring the Airzone webserver. Please wait...",
				"advanced": "Advanced settings"
			},
			"state8": {
				"text": "The process has been successfully completed"
			},
			"state9": {
				"text": "Once you have associated the webserver, the WiFi/D10 LED will be flashing",
				"select": "Select your webserver",
				"searching": "Searching the Bluetooth devices",
				"not_found": "Bluetooth devices not found",
				"not_appear": "Is your device not listed?",
				"click_here": "Press here"
			},
			"state15": {
				"title": "Manual association"
			},
			"state16": {
				"title": "How do I get the Webserver’s MAC address?",
				"boldtext1": "Using the App:",
				"text1": " From the Servers screen, press on Edit and press the pencil icon, you will be able to get the MAC address of your device inside this menu.",
				"boldtext2": "The cover of the Webserver User Manual",
				"text2": " provides a sticker with the product code, MAC and PIN code.",
				"boldtext3": "Using the Blueface Thermostat",
				"text3": " (AZxx6BLUEFACEC) (only if it is the Master thermostat of the System 1). Access to the User Settings > Information > Webserver, and check the device’s MAC address.",
				"boldtext4": "Using the Think Thermostat",
				"text4": " (AZxx6THINKx) (only if it is the Master thermostat of the System 1). Access to the Advanced Settings Menu (press and hold twice) > System > Information > Webserver, and check the device’s MAC address.",
				"boldtext5": "The device",
				"text5": " comes with a sticker with the product code, MAC and PIN code."
			}
		},
		"login": {
			"rememberme": "Rememberme",
			"restore_password": "Remember password",
			"demo": "Demo",
			"router": "Associate router"
		},
		"register": {
			"sign_up": "Create account",
			"repeat_password": "Repeat password"
		},
		"complete_registration": {
			"title": "Complete your registration"
		},
		"messages": {
			"migrated_user": "Registered user on the new platform",
			"migrated_installation": "Registered installation on the new platform",
			"new_app": "Please install your new Airzonecloud mobile app:",
			"errors": {
				"recaptcha_response": "Error validating recaptcha, please try again later",
				"recaptcha": "Sorry, recaptcha not valid",
				"name_blank": "Please enter your name",
				"surname_blank": "Please enter your surname",
				"login": "Sorry, your email or password are not valid",
				"email": "Sorry, your email is not valid",
				"email_blank": "Please enter your email",
				"email_taken": "The email account is already in  use",
				"password_length": "The password must have at least 8 characters",
				"password_blank": "Please enter your password",
				"password_confirmation_check": "Sorry, the passwords don't match",
				"password_confirmation_blank": "Please confirm your password",
				"password_new_length": "The password must have at least 8 characters",
				"password_new_blank": "Please enter your password",
				"condition": "Please accept the terms of use and the privacy policy",
				"token_invalid": "The validation code is not correct. Please. use Remember Password again.",
				"email_not_exist": "Sorry, there are no accounts associated to that email account",
				"confirmation": "An error ocurred while confirming. Please fill the form and we will send you a confirmation email.",
				"unconfirmed": "Unconfirmed email",
				"server_blank": "Choose a name for the server",
				"mac_blank": "Enter the MAC address of your server",
				"mac_invalid": "The MAC address you have entered is incorrect. Please check it matches the MAC address of your Webserver Cloud.",
				"server_sleeping": "The Webserver Cloud requested does not have an Internet connection. Check your connection and if it is correctly associated with the router.",
				"mac_format": "The MAC address format is not correct",
				"mac_repeated": "There is already a server with the same MAC address",
				"pin_blank": "Enter your server PIN code",
				"pin_invalid": "The PIN you have entered does not match the MAC address",
				"location_blank": "Please enter the location of your server",
				"server_icon_blank": "Select an icon to represent your server",
				"server_taken": "The MAC address you have requested is already in use",
				"file_error": "The extension of the file you are importing is invalid. Please choose a xls, xlsx or csv file.",
				"must_select_file": "You must select one file",
				"not_connected": "Sorry but you are not connected to the Internet. All the actions you are performing will not have any effect in your Airzone system. Please reconnect, reload the page and try again.",
				"not_connected_title": "Internet connection failure",
				"not_connected_device": "Your Airzone device is not connected to the Internet. All the actions you are performing will not have any effect. Please connect your device to the Internet, reload and try again.",
				"not_connected_device_title": "Internet connection failure",
				"error_device": "An error has ocurred. If this message persists, please contact your installer.",
				"registration_already_completed": "Your registration is already completed. Please log in entering your data.",
				"user_taken": "The user is already associated with the server",
				"without_internet_title": "Internet Connection",
				"without_internet_text": "This application needs internet connection to work. Please connect your device and try again",
				"day_blank": "You must select a day",
				"mac_length_seek": "Minimum 4 digits required",
				"email_length_seek": "Minimum 3 characters required",
				"not_systems_title": "System notices",
				"not_systems": "System not found. Please check your installation.",
				"location_title": "Location disabled",
				"location_text": "Check the location is enabled in your phone settings.",
				"location_disabled_title": "Location not available",
				"location_disabled_text": "Check the location for this application is enabled in your phone settings.",
				"bluetooth_disabled_title": "Bluetooth disabled",
				"bluetooth_disabled_text": "Check the Bluetooth is enabled.",
				"bluetooth_connect_device_title": "Device Disconnected",
				"bluetooth_connect_device_text": "Bluetooth connection lost.",
				"bluetooth_scan_title": "Error of scan",
				"bluetooth_scan_text": "Error trying to scan Bluetooth devices."
			},
			"success": {
				"register": "Registration completed!",
				"send_reset_password": "We have sent you an email with the instructions for how to change your password.",
				"reset_password": "Your password has been succesfully changed",
				"resend_confirmation": "We have sent your a new confirmation email.",
				"user_server_added": "Server has been successfully connected. You can access it from the server tab.",
				"user_server_updated": "Server information udpated.",
				"restore_factory": "You hace restored server factory values.",
				"added_basic_user_to_server": "The user has been successfully added to the server. Press Accept to access the screen of user permission.",
				"added_advanced_user_to_server": "The user has been successfully added to the server.",
				"sync_datetime": "The date and hour of the servant has got up-to-date in all the systems correctly."
			},
			"info": {
				"has_email": "You have an email!",
				"go_login": "Go to Login",
				"reset_password": "Go back to login to enter your email and your new password and login.",
				"change_password": "Write and repeat your new password.",
				"send_reset_password": "Don't worry, it can happen to anyone. Just enter your email and we will send you an email with the instructions for how to modify your password.",
				"register_success": "We have sent you an email to verify your identity.",
				"complete_registration_success": "Your registration is completed. You can log in and manage your Airzone devices.",
				"resend_confirmation": "Write your email and we will send you a confirmation email.",
				"unconfirmed": "You must confirm your email before logging in",
				"remove_server_admin": {
					"question": "Do you want to remove this server?",
					"info": "All the data associated to the server will be reset."
				},
				"restore_server_admin": {
					"question": "Do you want to restore the server factory values?",
					"info": "All the data associated to the server will be reset."
				},
				"update_server": "Updating server. This process can take several minutes...",
				"update_server_info": "Starting the process of updating this webserver to version",
				"update_server_confirmation": "Do you want to update this server?",
				"browsers": "Sorry, the browser you are using is not compatible with our application or its version is too old. If you want to use the application please install or update any of the following browsers.",
				"disable_permission_device": "It is necessary to have permission in at least one area",
				"auto_mode_battery_temperature": "Auto mode change enabled",
				"purifier_maintenance1": "Maintain your purification / sanitizing equipment.",
				"purifier_maintenance2": "Maintain your purification / sanitizing equipment. Access the advanced system setup menu to reset the counter (Purifier Maintenance).",
				"purifier_maintenance_before": "Maintain your purification / sanitizing equipment before:"
			},
			"title": {
				"reset_password": "Remember my password",
				"change_password": "Change password",
				"resend_confirmation": "Resend confirmation",
				"user_server_added": "Server successfully created",
				"user_server_updated": "Server successfully updated",
				"add_server": "Add server",
				"edit_server": "Edit server",
				"error": "Error",
				"server_data": "Server data",
				"remove_server": "Remove server",
				"restore_factory": "Restore factory values",
				"user_server_added_device": "User successfully added",
				"sync_datetime": "Correct synchronization",
				"device_permissions": "User permissions",
				"maintenance": "Maintenance",
				"browsers": "Browser not supported"
			}
		},
		"servers": {
			"no_results": "Server not found",
			"tags": {
				"servers": "servers",
				"results": "results",
				"page": "Page",
				"of": " of "
			},
			"new": {
				"add_servers": "Add servers",
				"return": "Return",
				"from_file": "From file",
				"select_file": "Select file",
				"select_file2": "Select file",
				"from_form": "From form",
				"success_add2": "Servers",
				"success_add_f2": "successfully added",
				"success_add1": "Server",
				"success_add_f1": "successfully added",
				"failure_add1": "This server could not be added",
				"failure_add2": "These servers could not be added",
				"failure_add_f2": "servers",
				"accept": "Accept",
				"success": {
					"added": "Server successfully added",
					"added2": "The server has been successfully added in the service"
				}
			}
		},
		"system_config": {
			"0": "Zoning  system",
			"1": "FC Zoning  system",
			"2": "2-pipe installation",
			"3": "4-pipe installation",
			"4": "RadianT",
			"6": "Airzone INT",
			"title": "System settings",
			"systems": "System",
			"acs_system": "DHW",
			"system_fw": "Firmware",
			"ccp_options": {
				"0": "Aerothermal",
				"1": "2 pipes",
				"2": "4 pipes",
				"3": "RadianT"
			},
			"setup_type": "Installation type",
			"blueface_number": "Blueface",
			"think_r": "Think R",
			"think_c": "Think W",
			"lite": "Lite",
			"radio_number": "Tacto R",
			"tact_number": "Tacto W",
			"probe_number": "Probe",
			"firm_ws": "Firmware Webserver",
			"cold_mixing_valve": "Cold mixing valve",
			"heat_rele_board_number": "Number of relay boards in heating mode",
			"cold_rele_board_number": "Number of relay boards in cooling mode",
			"combi_rele_board_number": "Number of relay boards in combined mode",
			"rele_fw_1": "Firmware Relay Board 1",
			"rele_fw_2": "Firmware Relay Board 2",
			"rele_fw_3": "Firmware Relay Board 3",
			"rele_fw_4": "Firmware Relay Board 4",
			"rele_fw_5": "Firmware Relay Board 5",
			"rele_fw_6": "Firmware Relay Board 6",
			"rele_fw_7": "Firmware Relay Board 7",
			"rele_fw_8": "Firmware Relay Board 8",
			"fancoil_gw": "Fancoil Gateway",
			"vent_gw": "Ventilation Gateway",
			"dehum_gw": "Dehum. Gateway",
			"heat_eme_gw": "EmeHeat Gateway",
			"heat_aux_gw": "AuxHeat Gateway",
			"knx_gw": "KNX Gateway",
			"radio_switchboard_fw": "Wireless valve module",
			"ccp": "CCP",
			"system_number": "System number",
			"direction_system": "System address",
			"etapes": "Stages",
			"cold_mode": "Cooling mode",
			"heat_mode": "Heating mode",
			"cold_mode_combined": "Combined cooling",
			"heat_mode_combined": "Combined heating",
			"min_temp": "Lowest temp.",
			"max_temp": "Highest temp.",
			"hysteresis": "Hysteresis",
			"cold_hysteresis": "Cooling Hysteresis",
			"heat_hysteresis": "Heating Hysteresis",
			"protect_temp": "Protection temperature",
			"cauldron": "Boiler",
			"cold_aco_air": "Air Conditioning - Cooling Mode",
			"heat_aco_air": "Air Conditioning - Heating Mode",
			"radiant_algorithm": "Radiant Algorithm",
			"proportional_operture": "Proportional opening",
			"min_air": "Minimum air",
			"master_installation": "Supermaster",
			"q_adapt": "Q-Adapt",
			"q_adapt_c": "Q-Adapt",
			"q_adapt_d": "Q-Adapt",
			"q_adapt0": "standard",
			"q_adapt1": "power",
			"q_adapt2": "silence",
			"q_adapt3": "minimum",
			"q_adapt4": "maximum",
			"min_volt": "minimum voltage",
			"max_volt": "maximum voltage",
			"q_adaptd1": "Manual",
			"q_adaptd0": "Automatic",
			"ventilation_mode": "Ventilation",
			"system_weight": "System weight",
			"work_mode": "Ventilation mode",
			"work_mode_ext_air": "External air",
			"work_mode_recirc": "Recirculate",
			"work_mode_combined": "Combined",
			"air_ventilate": "Air conditioning and ventilation",
			"overall_ventilation": "Global Ventilation Input",
			"normally_open": "Normally Open",
			"normally_closed": "Normally Closed",
			"busy": "Busy Freecooling",
			"unoccupied": "Free Freecooling",
			"delta": "Delta",
			"ext_temp": "External Temperature",
			"setpoint_temperature": "Set-point Temperature",
			"input_output": "Input/Output",
			"input": "Input",
			"probe": "Probe",
			"stop_start": "Stop-Start",
			"output": "Output",
			"demand_vmc": "VMC Demand",
			"demand_radiant": "Radiant",
			"air": "Air",
			"minutes": "Minutes",
			"caudal": "Flow",
			"information": "Information",
			"errors": "Errors",
			"error0": "No errors",
			"error1": "Error in unit gateway",
			"global_ventilation": "Global ventilation",
			"global_ventilation_every": "Every (min)",
			"global_ventilation_run_for": "Run for (min)",
			"antifreeze": "Anti-freezing",
			"auxiliary_heat": "Auxiliary Heat",
			"available_stages": "Available stages",
			"first_supply_heat": "First heating stage",
			"aux_heat": "Aux. heat",
			"heat_pump": "Heat pump",
			"fan_configuration": "Fan configuration",
			"electric": "Electric",
			"furnace": "Furnace",
			"delay": "Delay",
			"first_stage": "First stage",
			"min_time_exhausted": "Min. time exhausted",
			"second_stage": "Second stage",
			"differential": "Differential",
			"min_time": "Min. time",
			"autochange": "Autochange",
			"setpoint_differential": "Differential temp.",
			"mode_switching_protection1": "Mode switching",
			"mode_switching_protection2": "protection (min)",
			"heat_ovr": "Heat OVR temp.",
			"away_mode_settings": "Away mode settings",
			"override_time1": "Override",
			"override_time2": "time (min)",
			"room_temp": "Room temp.",
			"test_rocio": "Condensation protection",
			"rocio_hist": "Protection level",
			"rocio_hist_very_high": "Very high",
			"rocio_hist_high": "High",
			"rocio_hist_med": "Medium",
			"rocio_hist_low": "Low",
			"rocio_hist_very_low": "Very low",
			"test_rocio_off": "The protection against condensation will deactivate for 1 hour, after this time, the protection level will be ",
			"temperature_range": "Temperature range",
			"stage_configuration": "Heating configuration",
			"temp_gap": "Temperature gap",
			"firmMC": "Energy meter",
			"plenum_mode_title": "Standby mode",
			"hitachi_prot_title": "Protection mode - Motorized elements closing delay",
			"battery_temperature": "Battery Temperature",
			"min_battery_temperature": "Hot battery temperature",
			"max_battery_temperature": "Cold battery temperature",
			"auto_mode_battery_temperature": "Automatic mode change depending on battery temperature",
			"deshumidifier_function": "Dehumidifier Function",
			"humidity_limit": "Limit humidity",
			"disabled": "Disabled",
			"air_supply_settings": "Air supply settings",
			"air_supply_type": "Air supply adjust",
			"fan_delay": "Fan delay",
			"standby_mode_label1": "Status of the unit",
			"standby_mode_label2": " in comfort",
			"standby_mode": {
				"0": "Off",
				"1": "Standby"
			},
			"wsccp_activation_delay": "Activation delay",
			"wsccp_cold_heat_tai": "LWT temperatures",
			"wsccp_desactivation_acs": "DHW function",
			"purifier_control": "Purifier maintenance",
			"units": "Units",
			"hours": "Hours",
			"days": "Days",
			"timer": "Schedule",
			"counter": "Counter",
			"reset": "Reset",
			"fixed_time": "Fixed time",
			"production": "Production",
			"help": {
				"direction_system": "System address",
				"direction_system_text": "Select a system address",
				"temp_ranges": "Temperature range",
				"temp_ranges_text": "It allows you to select the lowest temperature (cooling mode) and highest temperature (heating mode) of the system.",
				"combined_steps": "Combined stages",
				"combined_steps_text": "It allows you to define the temperature difference between set-point temperature and room temperature to start using only Radiant stages instead of Air and Radiant stages in combined mode.",
				"protect_temp": "Protection Temperature",
				"protect_temp_text": "It allows you to define the temperatures in which the boiler or the air conditioning will be switched off to protect them from any type of damage.",
				"radiant_algorithm": "Radiant algorithm",
				"radiant_algorithm_text": "Configure the operating interval of the radiant efficiency algorithm. This algorithm regulates the operation of the controlled radiant element to avoid overheating.",
				"proportional_operture": "Proportional opening",
				"proportional_operture_text": "It allows you to choose between total opening or closed.",
				"min_air": "Minimum air",
				"min_air_text": "It allows the inlet of a minimun air supply in zones where the set-point temperature has been reached. This way, the system allows the ventilation air supply through the air conditioning ducts. If the zone is off, the damper will close completely.",
				"master_installation": "Installation Master",
				"master_installation_text": "It allows you to impose the operating system 1 to the rest of the systems connected.",
				"q_adapt": "Q-Adapt",
				"q_adapt_text": "It is a special function of Airzone systems that adapts the AC unit flow to the system/zone needs.",
				"q_adapt_power": "Q-Strength - It contributes to increase the supply in the speed map.",
				"q_adapt_standard": "Q-Standard - Configuration by deafult.",
				"q_adapt_max": "Q-Maximum - The system operates at maximum speed, irrespective of the number of zones.",
				"q_adapt_min": "Q-Silence - It contributes to reduce noise.",
				"q_adapt_silent": "Q-Minimum - The system operates at minimum speed.",
				"ventilation_mode_weight": "Ventilation Mode - System weight",
				"ventilation_mode_weight_text": "It allows configuring the system relevance towards the Ventilation Main Control Board.",
				"ventilation_mode_work": "Ventilation Mode - Operating Mode",
				"ventilation_mode_work_text": "This parameter allows you to select the desired type of ventilation in the system.",
				"ventilation_mode_clim": "Ventilation Mode - Air conditioning and Ventilation",
				"ventilation_mode_clim_text": "It allows you to configure the operating level of the system to combine simultaneously Air conditioning and Ventilation.",
				"ventilation_mode_global": "Ventilation Mode - Global Ventilation Input",
				"ventilation_mode_global_text": "It activates the local ventilation of all the zones. The input logic could be \"Normally open\" or \"Normally closed\".",
				"free_cooling_busy": "FreeCooling - Busy",
				"free_cooling_busy_text": "It allows the activation of freecooling operation in busy mode. Delta allows you to select the temperature differential to activate the Freecooling.",
				"free_cooling_unoccupied": "FreeCooling - Unoccupied",
				"free_cooling_unoccupied_text": "It allows the activation of freecooling operation in unoccupied mode.",
				"ext_temp": "External temperature - It is a parameter that allows you to select the desired external temperature. When external room temp. < external temp. configured the Freecooling will activate.",
				"consign": "Set-point: It is a parameter that allows you to select the desired internal temperature. When internal room temp. < interal temp. configured the Freecooling will activate.",
				"input": "Input",
				"input_text": "It allows configuring the input:",
				"input_probe": "Probe - It allows you to measure return temperature of the AC unit through a external probe.",
				"input_door": "Door - It allows you to perform a remote stop/start of the system. When you restart the system, it return to previous state.",
				"exit": "Output",
				"exit_text": "It allows configuring the output",
				"global_ventilation": "This menu allows you to activate/deactivate the fan mode in all zones when the system is not actively heating or cooling any zones. By default, the global ventilation is deactivated. When activated, the following settings are used to configure Global Ventilation",
				"global_ventilation_every": "Configure the length of the interval (in minutes) between periods of ventilation. Configurable from 5 to 40 minutes, in 5 minutes increments (by default, 15 minutes).",
				"global_ventilation_run_for": "Configure the time duration that ventilation is activated (in minutes). Configurable from 5 to 20 minutes, in 5 minutes increments (by default, 10 minutes).",
				"auxiliary_heat": "This menu is used to enable/disable the auxiliary heat. By default, the auxiliary heat is disabled. When auxiliary heat is enabled, the following parameters are used to configure the operation of the auxiliary heat.",
				"availables_stages": "Defines how many stages of auxiliary heat a system has.",
				"main_stage": "If the setting for Auxiliary heat is 1 or 2, then the first system to supply heat must be defined as either: Heat Pump or Aux. Heat.",
				"ventilation_conf": "Select between Electric (Fan on) or Furnace (Fan off) option. If you select Electric option, define the Fan Delay: Defines the delay time (in seconds) to turn on/off the fan when there is no demand from the Auxiliary heater. Possible values are 0, 45, 60 and 120 seconds.",
				"stage_differential": "Temperature that the system has to surpass to activate the stage of auxiliary heat. Values: 1 to 5˚C / 2 to 10˚F in increments of 0.5˚C / 1˚F. By default, 1˚C / 2˚F.",
				"stage_hysteresis": "Defines the hysteresis for the operation of the stage. Values: 1 to 5˚C / 2 to 10˚F in increments of 0.5˚C / 1˚F. By default, 1˚C / 2˚F.",
				"minimum_time_operating": "Minimum time that the Heat Pump must be active before the stage of auxiliary heat can be activated. Possible values are 0, 45, 60 and 120 minutes. By default, 45 minutes.",
				"away_modes_conf": "This option configures the following parameters of the unoccupied user mode and the vacation user mode.",
				"hysteresis": "If the set point temperature is surpassed by the differential defined, the zone will cease demand. Range: 1 to 3.5˚C / 2 to 7˚F in steps of 0.5˚C / 1˚F. By default, 3˚C / 6˚F.",
				"during_disable": "Sets time that the zone will resume the selected away mode when the user touches the thermostat screen during an away period. Values: 10 to 120 minutes in steps of 10 minutes. By default, 60 minutes.",
				"auto_mode": "This option allows the user to configure the three values that define the auto-changeover operation that sets the mode of the indoor unit.",
				"temperature_differential": "Defines the minimum differential between heating and cooling set points. Values: 0 to 3.5˚C / 0 to 7˚F in increments of 0.5˚C / 1˚F, by default 1˚C / 2˚F.",
				"change_mode_protection": "Defines the minimum run time before allowing a mode change. Possible values are 15, 30, 60 and 90 minutes. By default, 30 minutes.",
				"heat_differential": "If a zone has a higher heating demand than this temperature, the system reverts heating operation even if the cooling global demand exceeds the global heat demand. Possible values are: Off, 1.5 to 4˚C / 3 to 8˚F in increments of 0.5˚C/1˚F. Default value: Off.",
				"antifreeze": "This feature prevents the room temperature from lowering below 12˚C / 53˚F, although your zone is off. It is deactivated by default",
				"environment_temperature": "This option allows the room temperature and relative humidity to be shown/hidden. By default, the room temperature is shown",
				"heat_configuration": "This option configures the following parameters of the Air+Radiant control stage.",
				"cold_mode": "Selects the lowest temperature in cooling mode (18-26˚C / 64-78˚F, 18˚C / 64˚F by default).",
				"heat_mode": "Selects the highest temperature in heating mode (19-30˚C / 66-86˚F, 30˚C / 86˚F by default).",
				"proportional_operture_zbs": "Use this parameter to configure the type of opening of the damper output: All/Nothing or Modulating (by default).",
				"minimum_air_zbs": "This allows the access of a minimum air flow in zones where the set-point temperature has been already reached. It is deactivated by default.",
				"information": "This parameter provides information about the system and the connected elements.",
				"standby_mode_text": "Allows to select the state of the unit for cooling and heating modes once it is in comfort.",
				"standby_on": "The motorized elements of the activated zones (On) remain open when there is no demand.",
				"standby_off": "The motorized elements of the activated zones (On) remain closed when there is no demand."
			}
		},
		"systems": {
			"modes": {
				"stop": "Stop",
				"header": "Operating modes",
				"heat": "Heating",
				"heat-air": "Air heating",
				"heat-radiant": "Radiant heating",
				"heat-both": "Combined heating",
				"cool": "Cooling",
				"cool-air": "Air cooling",
				"cool-radiant": "Radiant cooling",
				"cool-both": "Combined cooling",
				"dehumidify": "Dry",
				"ventilate": "Ventilate",
				"auto": "Auto",
				"emergency-heat": "Emergency heat",
				"heat-supply": "Heat supply",
				"dehumidifier": "Dehumedifier"
			},
			"eco": {
				"eco-off": "Eco Off",
				"header": "Eco-Adapt",
				"eco-m": "Eco manual",
				"eco-a": "Eco A",
				"eco-aa": "Eco A+",
				"eco-aaa": "Eco A++"
			},
			"velocity": {
				"header": "Air speed",
				"velocity-0": "Auto",
				"velocity-1": "Low speed",
				"velocity-2": "Medium speed",
				"velocity-3": "High speed",
				"velocity-mlp": "Speed"
			},
			"air_flow": {
				"header": "Airflow control",
				"air_flow-0": "Silence",
				"air_flow-1": "Standard",
				"air_flow-2": "Power"
			},
			"scene": {
				"header": "User mode",
				"confort": "Confort",
				"eco": "Eco",
				"vacation": "Vacation",
				"unocupied": "Unoccupied",
				"night": "Night",
				"stop": "Stop"
			},
			"timer": {
				"header": "Timer",
				"timer-off": "0 minutes",
				"timer30": "30 minutes",
				"timer60": "60 minutes",
				"timer90": "90 minutes"
			},
			"consumption": {
				"header": "Energy meter"
			},
			"schedules": {
				"weekly": "Weekly schedules",
				"calendar": "Calendar schedules"
			},
			"without_consumption_data": "No consumption data is available at this time, check again later. The data is updated every hour",
			"error0": "CCP Configuration Error",
			"error1": "Communication error CCP - Aerothermal Gateway",
			"error2": "Communication error Aerothermal Gateway - Unit",
			"error3": "Energy meter communication error",
			"error4": "Energy meter measurement error",
			"error5": "H2 error detected, damper delay enabled. To disable it, access to the Advanced settings menu (protection mode)",
			"error6": "Configuration warning: Protections and control algorithm are disabled. Refer to the gateway documentation to enable these functions.",
			"error7": "AC unit error:",
			"error8": "DHW activated",
			"error9": "Communication error Altherma Gateway - main control board ",
			"error10": "Communication error Altherma Gateway - indoor unit"
		},
		"terms": {
			"accept": "I accept the ",
			"legal_terms": "Terms of Use",
			"and": " and the ",
			"privacy": "Privacy policy",
			"publicity": "I agree to let Airzone send me communications regarding product promotions and/or services"
		},
		"device_relations_list": {
			"title": "Servers",
			"no_servers_title": "Welcome to Airzone!",
			"no_servers_info": "To start controlling your installation, you must add a server to your account.",
			"remove_confirmation": "Do you want to remove this server from your account?",
			"removed_server": "Servidor removed",
			"internal_error": "Internal error",
			"internal_error_message": "We could not perform your action. Please try again later",
			"actions": {
				"edit": "Edit",
				"config": "Configuration",
				"users": "Users",
				"remove": "Remove",
				"close": "Close",
				"accept": "Accept",
				"cancel": "Cancel",
				"confirmation_deleted": "The server has been successfully removed",
				"add_server": "Add server"
			}
		},
		"add_server": {
			"name_placeholder": "Name (flat, office, house)",
			"mac_placeholder": "MAC (12:AB:34:CD:45:F0)",
			"pin_placeholder": "PIN",
			"location_placeholder": "Server Location"
		},
		"add_user_to_device": {
			"title": "Add user",
			"advanced": "Advanced",
			"basic": "Basic"
		},
		"users": {
			"title": "Users",
			"basic_user": "Basic users",
			"advanced_user": "Advanced users",
			"basic": "basic",
			"advanced": "advanced",
			"add_user": "Add user",
			"destroy": "Remove user",
			"remove_confirmation": "Do you want to remove the user access to the server?",
			"zones": "Accessible zones",
			"blank": "There are no users."
		},
		"admin_users": {
			"confirmed": "Confirmed",
			"results": "Results",
			"no_results": "No results",
			"page": "Page",
			"of": "of",
			"device_modal": {
				"title": "Accessible units by the user",
				"no_devices": "The user cannot access any AC unit",
				"location": "Location"
			}
		},
		"zones": {
			"warnings": {
				"presence": "Presence alarm",
				"window": "Window alarm",
				"antifreeze": "Anti-freezing",
				"dew": "Active drew",
				"dew_protection": "Active dew protection",
				"thermostat": "Zone without thermostat",
				"heat_supply_activated": "Heat supply activated",
				"low_valve_battery": "Low valve battery",
				"valve_NTC_alarm": "NTC2 alarm",
				"F05C": "Error F05-C: Cooling battery temperature probe - Open circuit",
				"F05H": "Error F05-H: Heating battery temperature probe - Open circuit",
				"F06C": "Error F06-C: Cooling battery temperature probe - Short circuit",
				"F06H": "Error F06-H: Heating battery temperature probe - Short circuit",
				"dehumidifier_activated": "Activated dehumidifier"
			},
			"adjusts": {
				"title": "Zone settings",
				"title_all": "All zones",
				"name": "Name",
				"name_system": "System name",
				"autoturnoff": "Sleep",
				"timer": "Timer",
				"defrosting": "Anti-freezing",
				"local_ventilation": "Local ventilation",
				"lite": "Settings Lite",
				"led": "LED state",
				"work_mode": "Stages",
				"cool": "Cooling",
				"heat": "Heating",
				"air_direction": "Air address",
				"lama_direction": "Slats address",
				"horizontal": "Horizontal",
				"vertical": "Vertical",
				"name_explanation": "It allows you to change the name of the zone",
				"autoturnoff_explanation": "The zone will automatically switch off after an adjustable period of time. After 30 minutes, the temperature will rise one degree in cooling mode or decrease one degree in heating mode.",
				"sleep_off": "Off - It deactivates the timer",
				"sleep_30": "30 - It activates the timer and after 30 minutes the zone will switch off.",
				"sleep_60": "60 - It activates the timer and after 60 minutes the zone will switch off.",
				"sleep_90": "90 - It activates the timer and after 90 minutes the zone will switch off.",
				"defrosting_explanation": "If the system detects the room temperature is lower than 12ºC, the heating mode automatically activates.",
				"work_mode_explanation": "It allows you to select which mode to use to cool or heat a zone, when both air and radiant floor are available:",
				"mode_air": "Air - Only air",
				"mode_radiant": "Radiant - Only radiant floor",
				"mode_mix": " - Air and radiant floor combined",
				"air_direction_explanation": "It allows you to choose the air direction in cold and heat.",
				"local_ventilation_explanation": "This option enables the activation or deactivation of the ventilation in the selected zone when the system is not actively cooling or heating any of the zones",
				"lite_explanation": "Enables to set the LED of the Wired Lite Thermostat to turn off after a few seconds"
			},
			"schedules": {
				"title": "Schedules",
				"init": "Start",
				"end": "End",
				"days": "Days",
				"monday": "Mo",
				"tuesday": "Tu",
				"wednesday": "We",
				"thursday": "Th",
				"friday": "Fr",
				"saturday": "Sa",
				"sunday": "Su",
				"add_schedule": "Add schedule",
				"schedules_types": "Schedule types",
				"temp": "Temp",
				"mode": "Mode",
				"vent": "Ventilation",
				"start_hour": "Start time",
				"end_hour": "End time",
				"start_mode": "Start mode",
				"end_mode": "End mode",
				"start_temp": "Start temperature",
				"end_temp": "End temperature",
				"start_vent": "Start ventilation",
				"end_vent": "End ventilation",
				"close": "Close",
				"remove": "Remove",
				"save": "Save",
				"enable": "Enable",
				"hour": "Hour",
				"temperature": "Temperature",
				"velocity": "Speed",
				"vel_0": "Automatic",
				"vel_1": "Low Speed",
				"vel_2": "Medium Speed",
				"vel_3": "High Speed",
				"new_prog": "New schedule",
				"edit_prog": "Edit schedule",
				"schedules_name": "Name",
				"remove_confirmation": "Do you want to remove the schedule permanently?",
				"all_zones": "All zones",
				"systems": "systems",
				"waiting_title": "Create schedules",
				"waiting_text": "Creating schedules... This process may take several seconds until it is completed in all zones.",
				"save_confirmation": "Save the schedule?",
				"edit_confirmation": "Save changes?",
				"invalid_dates_range": "Unable to save the schedule for the selected dates-range and zone"
			}
		}
	},
	"fr": {
		"adjust": {
			"title": "Paramètres utilisateur",
			"title_admin": "Paramètres utilisateur administrateur",
			"personal": "Données personnelles",
			"language": "Langue",
			"spanish": "Español",
			"english": "English",
			"french": "Français",
			"italian": "Italiano",
			"portuguese": "Português",
			"german": "Deutsch",
			"password": {
				"password_change": "Modifier mot de passe",
				"new_password_placeholder": "Nouveau mot de passe",
				"new_password_repeat_placeholder": "Répéter nouveau mot de passe",
				"old_password_placeholder": "Mot de passe actuel"
			},
			"require": {
				"name": "Indiquez votre prénom",
				"surname": "Indiquez votre nom"
			},
			"advanced_adjust": "Paramètres avancés",
			"advanced_adjust_text": "Afficher paramètres avancés",
			"time_sync": "Synchroniser date et heure",
			"save": {
				"title": "Paramètres enregistrés",
				"body": "Vos réglages ont été enregistrés correctement."
			},
			"unsubscribe_confirm": "Voulez-vous vous désinscrire de l'application Airzone ?  Toutes vos données seront effacées et aucun retour arrière ne sera possible",
			"unsubscribe": {
				"title": "Désinscription confirmée",
				"body": "Vous vous êtes correctement désinscrit d'Airzone. Toutes vos données personnelles ont été effacées complètement."
			},
			"change_pass": "Modifier mot de passe",
			"warranty": "Extension de garantie"
		},
		"languages": {
			"es": "Espagnol",
			"en": "Anglais"
		},
		"advanced_adjust": {
			"title": "Paramètres avancés",
			"subtitle": "Paramètres du système et des zones",
			"all_zones": "Toutes"
		},
		"adjust_zone": {
			"zone": "Zone",
			"dir_zone": "Adresse de zone",
			"associatted_exist": "Sorties associées",
			"non": "Non",
			"yes": "Oui",
			"master": "Principal",
			"basic_mode": "Mode basique",
			"amb_temp": "Température ambiante",
			"master_dir": "Adresse principale",
			"builder": "Fabricant",
			"stages": "Étapes",
			"heat": "Chauffage",
			"heat_air": "Air",
			"heat_radiant": "Rayonnant",
			"heat_combined": "Combiné",
			"heat_combo": "Air+Rayonnant",
			"cold": "Refroidissement",
			"cold_air": "Air",
			"cold_radiant": "Rayonnant",
			"cold_combined": "Combiné",
			"cold_combo": "Air+Rayonnant",
			"ventilation": "Ventilation",
			"step1": "Étape 1",
			"step2": "Étape 2",
			"step3": "Étape 3",
			"caudal": "Débit",
			"q-adapt": "Q-Adapt : Poids zone",
			"inputs": "Entrées",
			"window": "Fenêtre",
			"window_normally_open": "Normalement Ouvert",
			"window_normally_closed": "Normalement Fermé",
			"presence": "Présence",
			"presence_normally_open": "Normalement Ouvert",
			"presence_normally_closed": "Normalement Fermé",
			"spray_dew": "Sonde de rosée",
			"spray_dew_normally_open": "Normalement Ouvert",
			"spray_dew_normally_closed": "Normalement Fermé",
			"local_ventilation": "Ventilation locale",
			"local_vent_normally_open": "Normalement Ouvert",
			"local_vent_normally_closed": "Normalement Fermé",
			"information": "Informations",
			"firm_term": "Firmware Thermostat",
			"firm_module": "Firmware Module",
			"warning": "Avis",
			"errors": "Erreurs",
			"error": "Erreur",
			"error3": "Erreur 3-Grille non présente",
			"error4": "Erreur 4-Grille bloquée",
			"error5": "Erreur 5-Sonde non présente",
			"error6": "Erreur 6-Sonde court-circuitée",
			"error7": "Erreur 7-Éléments de climatisation incompatibles",
			"error8": "Erreur 8-Communications manquées avec module sonde éloignée",
			"error9": "Erreur 9-Alarme de présence",
			"error10": "Erreur 10-Alarme de fenêtre",
			"error11": "Erreur 11-Alarme de dégivrage",
			"error12": "Zone sans thermostat",
			"error15": "Erreur 15-Erreur de communication entre le compteur d’énergie et platine centrale du système",
			"error16": "Erreur 16-erreur de mesure du compteur de consommation",
			"manufacturer_0": "Sans fabricant",
			"manufacturer_1": "Fujitsu General I",
			"manufacturer_2": "Fujitsu General II",
			"manufacturer_3": "Daikin I",
			"manufacturer_4": "Daikin II",
			"manufacturer_5": "LG",
			"manufacturer_6": "Tango",
			"manufacturer_7": "Mitsubishi Electric",
			"manufacturer_8": "Toshiba",
			"manufacturer_9": "Gree",
			"no_errors": "Aucune erreur trouvée",
			"use_adapt_set": "Temp. mode utilisateur",
			"zone_weight": "Poids de la zone",
			"eco_function": "Fonction Eco",
			"function_eco": {
				"1": "Normalement Ouvert",
				"2": "Normalement Fermé"
			},
			"DC_fan_voltage": "Tension CC du ventilateur",
			"min_volt": "V minimum",
			"max_volt": "V maximum",
			"constant_ventilation": "Ventilation constante",
			"cold_battery_temperature": "T. batterie froide",
			"heat_battery_temperature": "T. batterie chaude",
			"relay_title": "Relais marche - arrêt AA",
			"relay": "Relais",
			"control_stage": "Étapes de contrôle",
			"cold_control_stage": {
				"0": "Détente directe",
				"1": "Eau",
				"2": "Apport d'air",
				"3": "Sol",
				"4": "Plafond",
				"5": "Electrique"
			},
			"heat_control_stage": {
				"0": "Electrique",
				"1": "Eau",
				"2": "Apport d'air",
				"3": "Sol",
				"4": "Radiateur/Plafond",
				"5": "Electrique"
			},
			"valves": "Vannes",
			"help": {
				"direction_zone": "Adresse de zone",
				"direction_zone_text": "Paramètre de lecture uniquement qui indique l'adresse de la zone sélectionnée.",
				"slave": "Sorties Associées (secondaires)",
				"slave_text": " Paramètre de lecture uniquement qui indique si la zone sélectionnée a des modules/sorties secondaires et leur adresse.",
				"master": "Principale",
				"master_text": "Permet de configurer la zone sélectionnée comme principale. Permet de choisir entre OUI et NON.",
				"basic_mode": "Mode Basique",
				"basic_mode_text": "Permet de désactiver les fonctionnalités avancées du thermostat et d'utiliser uniquement le contrôle basique pour faciliter son usage. Sélectionnez ON pour activer le mode basique. Dans le cas contraire, sélectionnez OFF.",
				"ambient_temp": "Température Ambiante",
				"ambient_temp_text": "Paramètre de lecture uniquement qui montre la température ambiante de la zone avec laquelle le système travaille.",
				"manufacturer": "Fabricant",
				"manufacturer_text": "Paramètre qui indique le fabricant des trames",
				"heat_type_config": "Étape de Chauffage",
				"heat_type_config_text": "Permet de configurer les étapes de chauffage de la zone. Les options à configurer sont Air, Rayonnant, Combiné et OFF qui désactive le mode chauffage dans la zone sélectionnée.",
				"cold_type_config": "Étape de Refroidissement",
				"cold_type_config_text": "Permet de configurer les étapes de refroidissement de la zone. Les options à configurer sont Air, Rayonnant, Combiné et OFF qui désactive le mode refroidissement dans la zone sélectionnée.",
				"ventilation": "Ventilation",
				"ventilation_text": "Active/désactive la fonctionnalité de ventilation de la zone.",
				"q_weight": "Q-Adapt: Poids Zone",
				"q_weight_text": "Dans des systèmes ACUAZONE/IBPro32/Innobus Air configurés avec Q-Adapt manuel, permet de modifier le poids total de la zone avec des valeurs comprises entre 1-99.",
				"window": "Fenêtre",
				"window_text": "Permet de contrôler le statut d'une zone en fonction de l'ouverture d'une fenêtre. Lorsque cette entrée est activée, la climatisation de la zone s’éteint jusqu'à ce que son état de repos soit rétabli. Pour activer cette fonctionnalité, configurez la logique de l’entrée parmi les valeurs suivantes : Normalement Ouvert ou Normalement Fermé. La valeur OFF désactive cette fonctionnalité.",
				"presence": "Présence",
				"presence_text": "Permet de contrôler le statut d’une zone en fonction d’un détecteur de présence. Lorsque cette entrée est activée, la climatisation de la zone active le mode Veille avec valeur 90. Pour activer cette fonctionnalité, configurez la logique de l’entrée parmi les valeurs suivantes : Normalement Ouvert ou Normalement Fermé. La valeur OFF désactive cette fonctionnalité.",
				"spray_dew": "Sonde point de rosée",
				"spray_dew_text": "Permet de contrôler le statut d’une zone en fonction d’un détecteur de point de rosée. Lorsque cette entrée est activée, la climatisation de la zone active le mode Veille avec valeur 90. Pour activer cette fonctionnalité, configurez la logique de l’entrée parmi les valeurs suivantes : Normalement Ouvert ou Normalement Fermé. La valeur OFF désactive cette fonctionnalité.",
				"local_vent": "Ventilation locale",
				"local_vent_text": "Active/désactive la ventilation locale de la zone. Pour activer cette fonctionnalité, configurez la logique de l'entrée parmi les valeurs suivantes : Normalement Ouvert ou Normalement Fermé. La valeur OFF désactive cette fonctionnalité.",
				"errors": "Erreurs",
				"errors_text": "Paramètre de lecture uniquement qui indique les erreurs actives dans la zone sélectionnée. Si le mot OUI est affiché, appuyez sur le paramètre pour déployer la barre et afficher les erreurs actives.",
				"firmt": "Firmware Thermostat",
				"firmt_text": "Paramètre de lecture uniquement qui montre la version du logiciel du thermostat de la zone associée.",
				"firmm": "Firmware Module",
				"firmm_text": "Paramètre de lecture uniquement qui montre la version du logiciel du module local de la zone associée.",
				"associatted_exist_text": "Affiche les sorties de contrôle associées au thermostat.",
				"use_adapt_set_text": "Configurez les températures de consigne de chacun des modes utilisateur, par créneaux de 0,5˚C / 1˚F, dans chacune des zones.",
				"confort_text": "Chaud 15 à 30˚C / 59 à 86˚F par défaut 20˚C / 68˚F. Refroid: 18 à 30˚C / 64 à 86˚F, par défaut 24˚C / 75˚F.",
				"eco_text": "Chaud 15 à 30˚C / 59 à 86˚F par défaut 19˚C / 66˚F. Refroid: 18 à 30˚C / 64 à 86˚F, par défaut 29˚C / 77˚F.",
				"unocupied_text": "Chaud 15 à 22˚C / 59 à 72˚F par défaut 17,5˚C / 63˚F. Refroid: 24 à 30˚C / 75 à 86˚F, par défaut 27˚C / 81˚F.",
				"vacation_text": "Chaud 10 à 16˚C / 50 à 61˚F par défaut 10˚C / 50˚F. Refroid: 29 à 35,5˚C / 84 à 96˚F, par défaut 35˚C / 95˚F.",
				"offset_text": "Corrige la température ambiante des différentes zones du système, aussi bien en mode Refroidissement qu'en mode Chauffage, en appliquant un facteur de correction compris entre -2,5°C et +2,5°C / 5˚F et 5˚F, par créneaux de 0,5°C / 1˚F. La configuration par défaut est de 0°C / 0˚F.",
				"stages_text": "Permet de configurer Ce paramètre fournit des informations sur le système et les éléments connectésles étapes de Refroidissement et de Chauffage dans la zone sélectionnée ou dans toutes les zones du système. Les options pouvant être configurées sont:",
				"air_text": "Active le chauffage par air dans la zone sélectionnée.",
				"radiant_text": "Active le chauffage rayonnant dans la zone sélectionnée.",
				"air_radiant_text": "Active le chauffage par air et plancher rayonnant dans la zone et vous permet de sélectionner l'étape Chauffage souhaitée dans ladite zone : Air, Rayonnant ou Air + Rayonnant.",
				"inputs_text": "Permet de régler l'état d'un module de zone en fonction de la détection de présence ou d'ouverture d'une fenêtre.",
				"window_zbs_text": "Permet de régler l'état d'un module de zone en fonction de la détection d'ouverture d'une fenêtre. Dans les zones où ce contact est activé, la zone s’éteindra au bout de 60 secondes en cas de détection de fenêtre ouverte. Un avertissement « Fenêtre active » s'affichera alors sur le thermostat. Cette fonction est désactivée par défaut. Pour l’activer, il vous suffit de définir la logique du contact comme normalement ouvert ou fermé.",
				"presence_zbs_text": "Permet de régler l'état d'un module de zone en fonction de la détection de présence. Dans les zones où cette fonction est activée, le mode Veille 90 sera activé au bout de 5 minutes si aucune présence n'est pas détectée. Un avertissement « Présence activée » s'affichera alors sur le thermostat. Cette fonction est désactivée par défaut. Pour l'activer, il vous suffit de définir la logique du détecteur comme normalement ouvert ou fermé.",
				"information_text": "Ce paramètre fournit des informations sur le système et les éléments connectés."
			}
		},
		"buttons": {
			"sign_in": "Connexion",
			"cancel": "Annuler",
			"init": "Commencer",
			"send": "Envoyer",
			"save": "Enregistrer",
			"accept": "Accepter",
			"resend_confirmation": "Renvoyer confirmation",
			"back": "Retour",
			"unsubscribe": "Se désinscrire",
			"icon": "Icône",
			"remove": "Supprimer",
			"restore_factory": "Réinitialiser",
			"next": "Suivant",
			"prev": "Précedant",
			"close": "Fermer",
			"recharge": "Actualiser",
			"hide": "Cacher",
			"update_device": "Détecter systèmes",
			"continue": "Continuer",
			"exit": "Sortir",
			"help": "Aide",
			"select": "Sélectionner",
			"invite": "Inviter",
			"update": "Mettre à jour"
		},
		"email": "Email",
		"name": "Prénom",
		"surname": "Nom",
		"password": "Mot de passe",
		"new_password": "Nouveau mot de passe",
		"repeat_password": "Répéter mot de passe",
		"server": {
			"notification": {
				"message": "Le prix de l’électricité de demain est disponible dès maintenant.",
				"alert_message": {
					"title": "Notifications",
					"text": "Vous voulez recevoir les notifications d’Airzonecloud?",
					"remember": "Ne me demandez pas de nouveau"
				}
			},
			"temp": "Température",
			"months": {
				"0": "JAN",
				"1": "FÉV",
				"2": "MAR",
				"3": "AVR",
				"4": "MAI",
				"5": "JUN",
				"6": "JUL",
				"7": "AOÛ",
				"8": "SEP",
				"9": "OCT",
				"10": "NOV",
				"11": "DÉC"
			},
			"days": {
				"0": "Dimanche",
				"1": "Lundi",
				"2": "Mardi",
				"3": "Mercredi",
				"4": "Jeudi",
				"5": "Vendredi",
				"6": "Samedi"
			},
			"current_price": "PRIX ACTUEL",
			"minimum_price": "MINIMUM",
			"maximum_price": "MAXIMUM",
			"day_price": "PRIX DU JOUR"
		},
		"state": "Statut",
		"enabled": "Habilité",
		"sleeping": "Disponible",
		"connected": "Connecté",
		"activated": "Activé",
		"components": "Composants",
		"historic": "Historique",
		"system": "Système",
		"filters": "Filtres",
		"mac": "Mac",
		"locality": "Localité",
		"all": "Tous",
		"pin": "PIN",
		"zone_name": "Nom",
		"connection": "Connexion",
		"compare": "Comparer",
		"day": "Journée",
		"month": "Mois",
		"year": "Année",
		"range": "Intervalle",
		"maximum": "Maximum",
		"minimum": "Le minimum",
		"mode": "Mode",
		"average": "Mean",
		"without_data": "Aucune donnée de consommation",
		"full_page": "Voir en plein écran",
		"print_chart": "Imprimer tableau",
		"download_png": "Télécharger en format PNG",
		"download_jpg": "Télécharger en format JPG",
		"download_pdf": "Télécharger en format PDF",
		"download_avg": "Télécharger en format AVG",
		"download_csv": "Télécharger en format CSV",
		"download_xls": "Télécharger en format XLS",
		"view_data_table": "Voir le tableau de données",
		"modal_mode": {
			"stop": "Veille",
			"stop_text": "Mode veille",
			"cold_air": "Refroid. air",
			"cold_air_text": "Mode refroid.",
			"heat_radiant": "Chauffage rayonnant",
			"heat_radiant_text": "Mode chauffage rayonnant",
			"ventilate": "Ventilation",
			"ventilate_text": "Mode ventilation",
			"heat_air": "Chauffage air",
			"heat_air_text": "Mode chauffage",
			"heat_both": "Chauffage combiné",
			"heat_both_text": "Mode chauffage combiné",
			"dehumidify": "Déshumidification",
			"dehumidify_text": "Mode déshumidification",
			"cold_radiant": "Refroid. rayonnant",
			"cold_radiant_text": "Mode refroid. rayonnant",
			"cold_both": "Refroid. combiné",
			"cold_both_text": "Mode refroid. combiné",
			"auto_text": "Permet d'alterner automatiquement entre refroidissement et chauffage, en fonction de la demande globale.",
			"emergency_heat": "Chaud auxiliaire",
			"emergency_heat_text": "Active le chauffage auxiliaire pour assurer l'approvisionnement en air chaud en cas de défaillance mécanique du système."
		},
		"modal_eco": {
			"title": "Modes économie",
			"eco": "Sélectionnez un mode d'économie d'énergie. Plus la classification A est élevée, plus les économies d'énergies sont élevées et moins la puissance de refroidissement est forte."
		},
		"modal_speed": {
			"title": "Modes de vitesse de l'air disponibles",
			"speed": "Sélectionnez une des vitesses. Plus le numéro est élevé, plus la vitesse de l'air augmente."
		},
		"modal_air_flow": {
			"silence": "Le système travaille à une vitesse inférieure à celle du mode Standard afin de privilégier la diminution du bruit",
			"standard": "Configuration par défaut. Le système adapte la vitesse de ventilation en fonction du nombre de zones en demande",
			"power": "Le système travaille à une vitesse supérieure à celle du mode Standard afin de favoriser l’augmentation du débit"
		},
		"modal_scene": {
			"stop": "L’équipement de climatisation reste à l’arrêt indépendamment de la demande en chauffage ou refroidissement des zones. En outre, tous les registres motorisés restent fermés",
			"confort": "Mode utilisateur standard, par défaut. Sélectionnez la température de consigne souhaitée en utilisant les plages de température prédéfinies",
			"unoccupied": "Utilisé lorsqu'aucune présence n'est détectée pendant de courtes périodes. Une température de consigne plus efficace sera alors définie. Si le thermostat est activé, la zone enclenchera le mode Confort",
			"night": "Le système modifie automatiquement la température de consigne de 0,5ºC/1ºF toutes les 30 minutes jusqu'à 2ºC/4ºF maximum. Lorsqu'il refroidit, le système augmente la température de consigne ; lorsqu'il chauffe, le système diminue la température de consigne",
			"eco": "Limite les températures de consigne à des valeurs de plus en plus efficaces",
			"vacation": "Cette fonction permet de faire des économies d'énergie lorsque l'utilisateur est absent pendant de longues périodes"
		},
		"home": {
			"menu": {
				"servers": "Serveurs",
				"adjusts": "Paramètres",
				"help": "Aide",
				"admin": "Serveurs",
				"exit": "Déconnexion",
				"admin_users": "Utilisateurs",
				"admins": "Administrateurs",
				"connections": "Connexions"
			},
			"upload": {
				"title": "Une nouvelle version de Airzone Cloud est disponible",
				"description": "S'il vous plait mettez à jour l'application afin de pouvoir l'utiliser."
			},
			"maintenance": {
				"title": "Ce site est temporairement en maintenance",
				"description": "Ce site est actuellement en cours de maintenance.",
				"text": "l sera bientôt disponible."
			}
		},
		"installations": {
			"return": "Retour",
			"exit": "Sortir",
			"finish": "Terminer",
			"next": "Suivant",
			"edit": "Éditer",
			"error": "Une erreur s'est produite lorsque vous avez connecté avec le Webserver Airzone Cloud. Vérifiez que votre dispositif est connecté au réseau Wi-Fi du Serveur web Airzone Cloud (AZWSCLOUDxxx).",
			"error_mac_not_found": "Une erreur s'est produite lors de l'obtention du code MAC depuis le Webserver",
			"error_sleeping": "Une erreur est survenue, le serveur Web n'a pas été provisionné",
			"error_not_connected": "Une erreur est survenue, le serveur Web ne peut pas se connecter au cloud",
			"retry": "Réessayer",
			"state1": {
				"text": "Vérifiez que votre dispositif est correctement alimenté (LED D11 fixe) et qu’il n’est pas hors de la zone de couverture"
			},
			"state2": {
				"text": "Vérifiez que le serveur Web soit connecté et en mode point d'accès, LED Wi-Fi/D13 fixe."
			},
			"state3": {
				"assisted": "Installation Assistée",
				"wps": "Installation WPS"
			},
			"state4": {
				"text": "Changez votre réseau Wi-Fi sur les paramètres de votre dispositif et choisissez un réseau qui commence par AZWSCLOUDxxxx",
				"text1": "Dans le cas d’une notification de perte de connexion internet, conservez la connexion à le Webserver de manière à continuer le processus d’association.",
				"text2": "Choisissez le réseau Wi-Fi qui commence par : AZWSCLOUDxxxx",
				"text3": "Rappelez-vous de désactiver le réseau de données mobiles si vous utilisez Android 5 ou supérieur",
				"loading_text": "Mise à jour du réseau en cours. Veuillez patienter s'il vous plaît.",
				"low_signal": {
					"title": "Réseau insuffisant",
					"text": "Approchez le WebServer au routeur ou installez un amplificateur Wi-Fi pour améliorer le signal"
				},
				"auto": "Automatique",
				"select_router": "Sectionnez la méthode d'appairage du routeur",
				"auto_info": "Automatique. Les réseaux Wi-Fi qui sont proches de votre Webserver sont recherchés automatiquement",
				"manual_info": "Manuel. Les données du Réseau Wi-Fi de votre choix sont introduites manuellement"
			},
			"state5": {
				"text": "Choisissez le routeur à associer :",
				"name": "Nom",
				"sec": "Sécurité",
				"signal": "Signal",
				"loading_text": "Connexion au dispositif en cours. Veuillez patienter s'il vous plaît.",
				"loading_text2": "(Si cela prend plus d'une minute, vérifiez que le dispositif est correctement connecté)",
				"no_routers": "Aucun réseau Wi-Fi n'a été trouvé à proximité du Serveur Web Airzone Cloud ; vérifiez que votre réseau est activé et qu'il se trouve visible pour votre connexion."
			},
			"state6": {
				"text": "Introduisez les données de votre réseau Wi-Fi",
				"none": "Sans sécurité",
				"wep": "WEP",
				"wpa": "WPA/WPA2"
			},
			"state7": {
				"text": "Données de votre configuration du réseau",
				"ip": "IP",
				"port": "Passerelle",
				"masc": "Masque réseau",
				"manual": "MANUEL",
				"dhcp": "DHCP",
				"loading_text": "Configuration du serveur Web Airzone en cours. Veuillez patienter s'il vous plaît.",
				"advanced": "Réglages avancés"
			},
			"state8": {
				"text": "Processus exécuté correctement"
			},
			"state9": {
				"text": "Après avoir associé le Serveur web, la LED Wi-Fi/D10 reste à clignoter",
				"select": "Sélectionnez votre webserver",
				"searching": "Recherche des appareils Bluetooth",
				"not_found": "Dispositifs Bluetooth introuvables",
				"not_appear": "Le dispositif n'apparaît pas ?",
				"click_here": "Appuyez ici"
			},
			"state15": {
				"title": "Association manuelle"
			},
			"state16": {
				"title": "Comment obtenir l'adresse MAC du Webserver ?",
				"boldtext1": "En utilisant l'application.",
				"text1": " Dans l'écran Serveurs, appuyez sur Éditer, puis sur l'icône de crayon. À l'intérieur de ce menu, vous pourrez consulter l'adresse MAC de votre dispositif.<br>",
				"boldtext2": "Sur la couverture du manuel utilisateur du Webserver",
				"text2": " se trouve une étiquette indiquant le code du produit, l'adresse MAC et le code PIN.",
				"boldtext3": "En utilisant le thermostat Blueface",
				"text3": " (AZxx6BLUEFACEC) (uniquement si thermostat principal du système 1). Accédez au menu Configuration d'utilisateur > Informations > Webserver, et consultez l'adresse MAC de votre dispositif.",
				"boldtext4": "En utilisant le thermostat Think",
				"text4": " (AZxx6THINKx) (uniquement si thermostat principal du système 1). Accédez au menu Configuration avancée (deux longues pressions) > Système > Informations > Webserver, et consultez l'adresse MAC de votre dispositif.",
				"boldtext5": "Sur le dispositif",
				"text5": " se trouve une étiquette indiquant le code du produit, l'adresse MAC et le code PIN."
			}
		},
		"login": {
			"rememberme": "Se souvenir de moi",
			"restore_password": "Rappeler mot de passe",
			"demo": "Démo",
			"router": "Associer router"
		},
		"register": {
			"sign_up": "Créer un compte",
			"repeat_password": "Répéter mot de passe"
		},
		"complete_registration": {
			"title": "Complétez votre inscription"
		},
		"messages": {
			"migrated_user": "Utilisateur enregistré sur la nouvelle plateforme",
			"migrated_installation": "Installation enregistrée sur la nouvelle plateforme",
			"new_app": "Veuillez installer votre nouvelle application mobile Airzonecloud:",
			"errors": {
				"recaptcha_response": "Une erreur est survenue lors de la vérification du code reCAPTCHA. S’il vous plait, veuillez réessayer plus tard",
				"recaptcha": "Nous sommes désolés, le code reCAPTCHA est incorrect",
				"name_blank": "Le champ prénom ne peut pas rester vide",
				"surname_blank": "Le champ nom ne peut pas rester vide",
				"login": "Désolé, l'adresse email ou le mot de passe est incorrect",
				"email": "Désolé, l'adresse email indiquée est incorrecte",
				"email_blank": "Le champ adresse email ne peut pas rester vide",
				"email_taken": "L'adresse email existe déjà",
				"password_length": "Le mot de passe doit contenir au moins 8 caractères",
				"password_blank": "Le champ mot de passe ne peut pas rester vide",
				"password_confirmation_check": "Désolé, les mots de passe doivent correspondre",
				"password_confirmation_blank": "Veuillez valider votre mot de passe",
				"password_new_length": "Le mot de passe doit contenir au moins 8 caractères",
				"password_new_blank": "Le champ mot de passe ne peut pas rester vide",
				"condition": "Veuillez accepter les conditions d'utilisation et la politique de confidentialité",
				"token_invalid": "Le code d'activation est erroné. Veuillez demander un nouveau code en cliquant sur Rappeler mot de passe.",
				"email_not_exist": "Désolé, il n'existe aucun compte associé à cette adresse email",
				"confirmation": "Une erreur s'est produite lors de la confirmation. Veuillez remplir le formulaire et un nouveau email de confirmation vous sera envoyé.",
				"unconfirmed": "Adresse email non confirmée !",
				"server_blank": "Saisissez un nom pour votre serveur",
				"mac_blank": "Indiquez l'adresse MAC de votre serveur",
				"mac_invalid": "La MAC introduite est incorrecte. Veuillez vérifier qu'elle correspond aux données du Serveur Web Airzone Cloud.",
				"server_sleeping": "Le Serveur web Cloud demandé n'a pas d'accès à Internet. Vérifiez qu'il est associé à un router et que ce dernier possède de connexion à internet.",
				"mac_format": "Le format de l'adresse MAC est incorrect",
				"mac_repeated": "L'adresse MAC est déjà active sur un autre serveur",
				"pin_blank": "Saisissez le code PIN de votre serveur",
				"pin_invalid": "Le code PIN introduit ne correspond pas à la MAC",
				"location_blank": "Indiquez la localité où se trouve votre serveur",
				"server_icon_blank": "Choisissez une icône pour répresenter votre serveur",
				"server_taken": "La MAC demandée a déjà été enregistrée sur la plate-forme Cloud",
				"file_error": "Le fichier que vous tentez d'importer est incorrect. Les formats supportés sont xls, xlsx et csv.",
				"must_select_file": "Veuillez sélectionner un fichier",
				"not_connected": "Actuellement vous n'avez pas de connexion Internet. Les actions réalisées n'auront aucun effet sur votre système Airzone. Lorsque la connexion est disponible, actualisez le site et réessayez.",
				"not_connected_title": "Alerte sur votre connexion Internet",
				"not_connected_device": "Votre dispositif Airzone n'est pas connecté au réseau actuellement. Les actions réalisées n'auront aucun effet. Connectez votre dispositif Airzone sur Internet, actualisez le site et réessayez.",
				"not_connected_device_title": "Alerte sur la connexion de votre dispositif",
				"error_device": "Une erreur s'est produite sur votre dispositif. Si ce message persiste, veuillez contacter votre installateur.",
				"registration_already_completed": "Vous êtes déjà inscrit. Veuillez ouvrir la session en utilisant votre nom d'utilisateur et votre mot de passe.",
				"user_taken": "Le nom d'utilisateur est déjà associé au serveur.",
				"without_internet_title": "Connexion Internet",
				"without_internet_text": "Cette application nécessite une connexion Internet pour fonctionner S'il vous plaît connecter votre appareil et essayez à nouveau.",
				"day_blank": "Vous devez sélectionner un jour",
				"mac_length_seek": "Minimum 4 chiffres requis",
				"email_length_seek": "Minimum 3 caractères requis",
				"not_systems_title": "Avis des systèmes",
				"not_systems": "Aucun système n'a été détecté. S'il vous plaît, vérifiez votre installation",
				"location_title": "Localisation est désactivé",
				"location_text": "Vérifiez que vous avez activé la localisation dans les paramètres de votre téléphone.",
				"location_disabled_title": "Localisation non activé.",
				"location_disabled_text": "Vérifiez que vous avez activé la localisation de cette application dans les paramètres de votre téléphone.",
				"bluetooth_disabled_title": "Bluetooth désactivé",
				"bluetooth_disabled_text": "Vérifiez que le Bluetooth est activé.",
				"bluetooth_connect_device_title": "Dispositif déconnecté",
				"bluetooth_connect_device_text": "Connexion Bluetooth perdue.",
				"bluetooth_scan_title": "Erreur de scan",
				"bluetooth_scan_text": "Erreur lors de la tentative de numérisation des dispositifs Bluetooth."
			},
			"success": {
				"register": "Votre inscription a été enregistrée correctement !",
				"send_reset_password": "Vous recevrez un email avec les instructions pour redéfinir votre mot de passe.",
				"reset_password": "Votre mot de passe a bien été modifié.",
				"resend_confirmation": "Nous avons envoyé un nouveau email de confirmation sur votre adresse email.",
				"user_server_added": "La connexion avec le serveur a bien été établie. Vous pourrez accéder à travers la fenêtre des serveurs.",
				"user_server_updated": "Les informations du serveur ont bien été actualisées.",
				"restore_factory": "Le serveur a été réinitialisé.",
				"added_basic_user_to_server": "L'utilisateur a bien été enregistré sur le serveur. Appuyez sur Accepter pour accéder à l'écran des autorisations de l'utilisateur.",
				"added_advanced_user_to_server": "L'utilisateur a bien été enregistré sur le serveur.",
				"sync_datetime": "La date et une heure du serveur a été actualisée dans tous les systèmes correctement."
			},
			"info": {
				"has_email": "Vous avez un adresse mail !",
				"go_login": "S'adresser à l'écran Login",
				"reset_password": "Revenez sur l'écran Login et saisissez votre email et votre nouveau mot de passe pour vous connecter.",
				"change_password": "Tapez et répétez votre nouveau mot de passe.",
				"send_reset_password": "Hey, ça arrive à tout le monde. Juste indiquez-nous votre adresse email et nous vous enverrons un email avec les instructions pour modifier votre mot de passe.",
				"register_success": "Nous avons envoyé un email à votre adresse pour vérifier votre identité.",
				"complete_registration_success": "L'inscription a bien été faite. Vous pouvez vous connecter et accéder à la gestion de votre dispositif Airzone.",
				"resend_confirmation": "Saisissez votre adresse email et nous vous enverrons un nouveau email de confirmation.",
				"unconfirmed": "Vous devez confirmer votre email avant de vous connecter",
				"remove_server_admin": {
					"question": "Voulez-vous supprimer ce serveur ?",
					"info": "Toutes les données associées à ce serveur seront supprimées."
				},
				"restore_server_admin": {
					"question": "Voulez-vous restaurer les valeurs d'usine de ce serveur ?",
					"info": "Toutes les données associées à ce serveur seront réinitialisées."
				},
				"update_server": "Mise à jour du serveur en cours. Cette opération peut prendre quelques minutes.",
				"update_server_info": "Démarrage du processus de mise à jour de ce serveur Web vers la version",
				"update_server_confirmation": "Voulez-vous mettre à jour ce serveur ?",
				"browsers": "Désolé, le navigateur que vous utilisez n'est pas compatible avec notre application ou est désuet. Si vous voulez utiliser l'application, veuillez installer ou mettre à jour un des navigateurs suivants.",
				"disable_permission_device": "Il est nécessaire d’avoir l’autorisation d’au moins une zone",
				"auto_mode_battery_temperature": "Basculement de mode automatique activé",
				"purifier_maintenance1": "Procédez à l'entretien de votre équipement de purification/désinfection.",
				"purifier_maintenance2": "Procédez à l'entretien de votre équipement de purification/désinfection. Accédez au menu de configuration avancée du système pour réinitialiser le compteur (Maintenance du purificateur).",
				"purifier_maintenance_before": "Procédez à l'entretien de votre équipement de purification/désinfection avant :"
			},
			"title": {
				"reset_password": "Rappeler mot de passe",
				"change_password": "Modifier mot de passe",
				"resend_confirmation": "Renvoyer confirmation",
				"user_server_added": "Serveur créé correctement",
				"user_server_updated": "Serveur mis à jour correctement",
				"add_server": "Ajouter serveur",
				"edit_server": "Éditer serveur",
				"error": "Erreur",
				"server_data": "Données du serveur",
				"remove_server": "Supprimer serveur",
				"restore_factory": "Restaurer serveur d'usine",
				"user_server_added_device": "Utilisateur ajouté correctement",
				"sync_datetime": "Synchronisation correcte",
				"device_permissions": "Autorisations de l’utilisateur",
				"maintenance": "Maintenance",
				"browsers": "Navigateur non supporté"
			}
		},
		"servers": {
			"no_results": "Aucun serveur trouvé",
			"tags": {
				"servers": "serveurs",
				"results": "résultat",
				"page": "Page ",
				"of": " de "
			},
			"new": {
				"add_servers": "Ajouter serveurs",
				"return": "Retourner",
				"from_file": "Du fichier",
				"select_file": "Sélectionnez un fichier",
				"select_file2": "Sélectionnez un fichier",
				"from_form": "Du formulaire",
				"success_add2": " serveurs",
				"success_add_f2": "ils ont été ajoutés correctement",
				"success_add1": " serveur",
				"success_add_f1": "il a été ajouté correctement",
				"failure_add1": "Impossible d'ajouter le serveur suivant",
				"failure_add2": "Impossible d'ajouter les serveurs",
				"failure_add_f2": " suivants",
				"accept": "Accepter",
				"success": {
					"added": "Serveur ajouté correctement",
					"added2": "Le serveur a bien été enregistré sur la plate-forme"
				}
			}
		},
		"system_config": {
			"0": "Système zoné ",
			"1": "Système zoné FC",
			"2": "Installation à 2 tubes",
			"3": "Installation à 4 tubes",
			"4": "RadianT",
			"6": "Airzone INT",
			"title": "Paramètres du système",
			"systems": "Système",
			"acs_system": "ECS",
			"system_fw": "Firmware",
			"ccp_options": {
				"0": "Aier-Eau",
				"1": "2 tubes",
				"2": "4 tubes",
				"3": "RadianT"
			},
			"setup_type": "Type d'installation",
			"blueface_number": "Blueface",
			"think_r": "Think R",
			"think_c": "Think F",
			"lite": "Lite",
			"radio_number": "Tacto R",
			"tact_number": "Tacto F",
			"probe_number": "Sonde",
			"firm_ws": "Firmware Webserver",
			"cold_mixing_valve": "Valve de mélange en refroidissement",
			"heat_rele_board_number": "Nº platines de relais en chauffage",
			"cold_rele_board_number": "Nº platines de relais en refroidissement",
			"combi_rele_board_number": "Nº platines de relais en combiné",
			"rele_fw_1": "Firmware Platine Relais 1",
			"rele_fw_2": "Firmware Platine Relais 2",
			"rele_fw_3": "Firmware Platine Relais 3",
			"rele_fw_4": "Firmware Platine Relais 4",
			"rele_fw_5": "Firmware Platine Relais 5",
			"rele_fw_6": "Firmware Platine Relais 6",
			"rele_fw_7": "Firmware Platine Relais 7",
			"rele_fw_8": "Firmware Platine Relais 8",
			"fancoil_gw": "Passerelle Ventilo-convecteur",
			"vent_gw": "Passerelle Ventilation",
			"dehum_gw": "Passerelle Deshumidification",
			"heat_eme_gw": "Passerelle Chauffage d'émergence",
			"heat_aux_gw": "Passerelle Chauffage auxiliaire",
			"knx_gw": "Passerelle KNX",
			"radio_switchboard_fw": "Module vannes radio",
			"ccp": "CCP",
			"system_number": "Nº système",
			"direction_system": "Adresse du système",
			"etapes": "Étapes",
			"cold_mode": "Mode refroidissement",
			"heat_mode": "Mode chauffage",
			"cold_mode_combined": "Refroidissement Combiné",
			"heat_mode_combined": "Chauffage Combiné",
			"min_temp": "Temp. Minimale",
			"max_temp": "Temp. Maximale",
			"hysteresis": "Hystérésis",
			"cold_hysteresis": "Refroidissement Hystérésis",
			"heat_hysteresis": "Chauffage Hystérésis",
			"protect_temp": "Température de Protection",
			"cauldron": "Chaudière",
			"cold_aco_air": "Climatisation - Mode Refroidissement",
			"heat_aco_air": "Climatisation - Mode Chauffage",
			"radiant_algorithm": "Algorithme Rayonnant",
			"proportional_operture": "Ouverture Proportionnelle",
			"min_air": "Air minimum",
			"master_installation": "Supermaître",
			"q_adapt": "Q-Adapt",
			"q_adapt_c": "Q-Adapt",
			"q_adapt_d": "Q-Adapt",
			"q_adapt0": "standard",
			"q_adapt1": "puissance",
			"q_adapt2": "silence",
			"q_adapt3": "minimum",
			"q_adapt4": "maximum",
			"min_volt": "Tension Minimale",
			"max_volt": "Tension Maximale",
			"q_adaptd0": "Automatique",
			"q_adaptd1": "Manuel",
			"ventilation_mode": "Ventilation",
			"system_weight": "Poids du système",
			"work_mode": "Mode de ventilation",
			"work_mode_ext_air": "Air extérieur",
			"work_mode_recirc": "Recirculer",
			"work_mode_combined": "Combiné",
			"air_ventilate": "Climatiser et Ventiler",
			"overall_ventilation": "Entrée Ventilation Globale",
			"normally_open": "Normalement Ouvert",
			"normally_closed": "Normalement Fermé",
			"busy": "Freecooling Occupé",
			"unoccupied": "Freecooling Inoccupé",
			"delta": "Delta",
			"ext_temp": "Température Extérieure",
			"setpoint_temperature": "Température Consigne",
			"input_output": "Entrée/Sortie",
			"input": "Entrée",
			"probe": "Sonde",
			"stop_start": "Arrêt-Marche",
			"output": "Sortie",
			"demand_vmc": "Demande VMC",
			"demand_radiant": "Rayonnant",
			"air": "Air",
			"minutes": "Minutes",
			"caudal": "Débit",
			"information": "Information",
			"errors": "Erreurs",
			"error0": "Aucune erreur trouvée",
			"error1": "Erreur passerelle unité",
			"global_ventilation": "Ventilation globale",
			"global_ventilation_every": "Toutes les (min)",
			"global_ventilation_run_for": "Durant (min)",
			"antifreeze": "Hors-gel",
			"auxiliary_heat": "Chauffage auxiliaire",
			"available_stages": "Etapes disponibles",
			"first_supply_heat": "Etape principale",
			"aux_heat": "Chauf. aux.",
			"heat_pump": "Pompe à chaleur",
			"fan_configuration": "Conf. ventilateur",
			"electric": "Electrique",
			"furnace": "Brûleur",
			"delay": "Délai",
			"first_stage": "Première étape",
			"min_time_exhausted": "Temps min. fonctionnement",
			"second_stage": "Deuxième étape",
			"differential": "Différentiel",
			"min_time": "Temps min.",
			"autochange": "Mode Auto",
			"setpoint_differential": "Différentiel temp.",
			"mode_switching_protection1": "Protection chang.",
			"mode_switching_protection2": "mode (min)",
			"heat_ovr": "Dif. imposition chauffage",
			"away_mode_settings": "Conf. modes absence",
			"override_time1": "Désactiver",
			"override_time2": "durant (min)",
			"room_temp": "T. ambiante",
			"test_rocio": "Protection anticondensation",
			"rocio_hist": "Niveau de protection",
			"rocio_hist_very_high": "Très élevé",
			"rocio_hist_high": "Élevé",
			"rocio_hist_med": "Moyen",
			"rocio_hist_low": "Bas",
			"rocio_hist_very_low": "Très bas",
			"test_rocio_off": "La protection contre la condensation sera désactivée pendant 1h, après cette période le niveau de protection sera ",
			"temperature_range": "Plage température",
			"stage_configuration": "Configuration chauffage",
			"temp_gap": "Différence temp",
			"firmMC": "Compteur d'Energie",
			"plenum_mode_title": "Mode standby",
			"hitachi_prot_title": "Mode protection - Retard dans la fermeture des registres motorisés",
			"battery_temperature": "Température batterie",
			"min_battery_temperature": "T. batterie chaude",
			"max_battery_temperature": "T. batterie froide",
			"auto_mode_battery_temperature": "Basculement de mode automatique en fonction de la température de la batterie",
			"deshumidifier_function": "Fonction déshumidificateur",
			"humidity_limit": "Humidité limite",
			"disabled": "Désactivé",
			"air_supply_settings": "Réglages apport d'air",
			"air_supply_type": "Type d'apport d'air",
			"fan_delay": "Délai venilateur",
			"standby_mode_label1": "Etat de l'unité",
			"standby_mode_label2": " en confort",
			"standby_mode": {
				"0": "Arrêt",
				"1": "Standby"
			},
			"wsccp_activation_delay": "Délai d’activation",
			"wsccp_cold_heat_tai": "Température de l’eau en sortie",
			"wsccp_desactivation_acs": "Fonction ECS ",
			"purifier_control": "Maintenance du purificateur",
			"units": "Unités",
			"hours": "Heures",
			"days": "Jours",
			"timer": "Temps",
			"counter": "Compteur",
			"reset": "Reset",
			"fixed_time": "Temps fixé",
			"production": "Production",
			"help": {
				"direction_system": "Adresse du Système",
				"direction_system_text": "Indiquez l'adresse du système.",
				"temp_ranges": "Plage de températures",
				"temp_ranges_text": "Cela permet de sélectionner les plages de température minimale (mode refroidissement) et maximale (mode chauffage) du système.",
				"combined_steps": "Étapes combinées",
				"combined_steps_text": "Cela permet de définir la différence entre la température de consigne et ambiante pour qu'en mode chauffage combiné on passe d'utiliser les étapes d'Air et Rayonnant à Rayonnant uniquement.",
				"protect_temp": "Température de protection",
				"protect_temp_text": "Cela permet de définir les limites de température de désactivation de la chaudière ou la climatisation afin d'éviter tout dommage.",
				"radiant_algorithm": "Algorithme rayonnant",
				"radiant_algorithm_text": "Configurez l'intervale de fonctionnement de l'algorithme d'efficacité rayonnante. Cet algorithme adapte le fonctionnement de l'élément rayonnant contrôlé afin d'éviter des surchauffes en raison d'éventuelles inerties thermiques, par exemple, dans les planchers chauffants.",
				"proportional_operture": "Ouverture Proportionnelle",
				"proportional_operture_text": "Cela permet de choisir entre ouverture tout ou rien ou proportionnelle.",
				"min_air": "Air minimum",
				"min_air_text": "Cela permet l'entrée d'un débit minimal d'air dans les zones qui ont atteint leur consigne. De cette façon, le système permet de réaliser l'apport d'air de ventilation au travers des gaines. Si la zone est désactivée, le registre se ferme complètement.",
				"master_installation": "Installation principale",
				"master_installation_text": "Cela permet d'imposer le mode de fonctionnement du système 1 aux autres systèmes connectés.",
				"q_adapt": "Q-Adapt",
				"q_adapt_text": "Fonctionnalité des systèmes Airzone qui permet d'adapter le débit de l'unité de climatisation aux besoins du système/de la zone.",
				"q_adapt_power": "Q-Puissance - Met en avant des débits plus élevés pour les installations où la demande thermique est plus importante.",
				"q_adapt_standard": "Q-Standard - Configuration par défaut.",
				"q_adapt_max": "Q-Maximum - Le système travaille à vitesse maximale, indépendamment du nombre de zones en demande.",
				"q_adapt_min": "Q-Silence - Réajustement des débits pour les installations où prime avant tout le confort sonore.",
				"q_adapt_silent": "Q-Minimum - Maintient la vitesse de ventilation au minimum.",
				"ventilation_mode_weight": "Mode Ventilation - Poids du système",
				"ventilation_mode_weight_text": "Permet de configurer l'importance du système vers la Platine de Contrôle de Ventilation.",
				"ventilation_mode_work": "Mode Ventilation - Mode de fonctionnement",
				"ventilation_mode_work_text": "Ce paramètre permet de sélectionner le type de ventilation souhaité dans le système.",
				"ventilation_mode_clim": "Mode Ventilation - Climatiser et Ventiler",
				"ventilation_mode_clim_text": "Permet de configurer les niveaux de fonctionnement du système pour climatiser et ventiler simultanément.",
				"ventilation_mode_global": "Mode Ventilation - Entrée Ventilation Globale",
				"ventilation_mode_global_text": "Active la ventilation locale de toutes les zones. Sélectionnez la logique de l'entrée entre 'Normalement ouvert' ou 'Normalement fermé'.",
				"free_cooling_busy": "Freecooling - Occupé",
				"free_cooling_busy_text": "Cela permet d'activer le fonctionnement Freecooling en mode occupé. Delta permet d'établir le différentiel de température pour l'activation du Freecooling.",
				"free_cooling_unoccupied": "Freecooling - Inoccupé",
				"free_cooling_unoccupied_text": "Permet d'activer le fonctionnement Freecooling en mode inoccupé.",
				"ext_temp": "Température extérieure - Paramètre qui permet d'établir la température extérieure souhaitée. Lorsque la T.amb. extérieure < T.ext. configurée, le Freecooling sera activé.",
				"consign": "Consigne : Paramètre qui permet d'établir la température intérieure souhaitée. Lorsque la T.amb. intérieure < T.int. configurée, le Freecooling sera activé.",
				"input": "Entrée",
				"input_text": "Cela permet de configurer l'entrée:",
				"input_probe": "Sonde - Cela permet de mesurer la température de retour d’un équipement de climatisation au travers d'une sonde extérieure.",
				"input_door": "Porte - Cela permet de réaliser le marche-arrêt du système à distance. Lorsque vous activez à nouveau le système, celui-ci revient au statut avant l'ârret.",
				"exit": "Sortie",
				"exit_text": "Cela permet de configurer la sortie",
				"global_ventilation": "Permet d'activer/désactiver le mode Ventilation dans toutes les zones, lorsque le système n'a aucune zone en demande. Cette fonction est désactivée par défaut. Lors de son activation, il faut configurer les paramètres suivants",
				"global_ventilation_every": "Configurez la durée de l'intervalle (en minutes) entre les périodes d'activation de la ventilation globale. Celle-ci peut être configurée de 5 à 40 minutes, par créneaux de 5 minutes (par défaut, 15 minutes).",
				"global_ventilation_run_for": "Configurez la durée (en minutes) de fonctionnement de la ventilation globale. Celle-ci peut être configurée de 5 à 20 minutes, par créneaux de 5 minutes (par défaut, 10 minutes).",
				"auxiliary_heat": "Permet d’activer/désactiver le chauffage auxiliaire. Cette fonction est désactivée par défaut. Lorsque le chauffage auxiliaire est activé, il faut configurer les paramètres suivants.",
				"availables_stages": "Définissez les étapes de chauffage auxiliaire dont dispose le système.",
				"main_stage": "Sélectionnez le 1er système qui fournit du chauffage : Pompe à chaleur ou Chauffage auxiliaire.",
				"ventilation_conf": "Sélectionnez Électrique (ventilation allumée) ou Brûleur (ventilation éteinte). Si vous sélectionnez l’option Électrique, veuillez configurer le délai ventilateur: Définit la durée (en secondes) que met le ventilateur pour allumer / éteindre en l’absence de demande de l’appareil de chauffage auxiliaire. Valeurs : 0, 45, 60 et 120 secondes.",
				"stage_differential": "Définit le différentiel de température qui doit être dépassé par le système pour activer l'étape de chauffage auxiliaire. Valeurs : de 1 à 5˚C / 2 à 10˚F par créneaux de 0,5˚C / 1˚F. Par défaut : 1˚C / 2˚F.",
				"stage_hysteresis": "Définit l'hystérésis de fonctionnement de l'étape. Valeurs : de 1 à 5˚C / 2 à 10˚F par créneaux de 0,5˚C / 1˚F. Par défaut : 1˚C / 2˚F.",
				"minimum_time_operating": "Définit la durée minimale (en minutes) durant laquelle l’étape principale doit être activée avant de pouvoir activer l'étape de chauffage auxiliaire. Valeurs : 0, 45, 60 et 120 minutes (par défaut, 45 minutes).",
				"away_modes_conf": "Permet de configurer les paramètres suivants pour le mode utilisateur Inoccupé et Vacances.",
				"hysteresis": "Si la température de consigne est dépassée par le différentiel défini, la zone cessera de générer une demande. Plage : de 1 à 3.5˚C / 2 à 7˚F par créneaux de 0,5˚C / 1˚F. Par défaut : 3˚C / 6˚F.",
				"during_disable": "Sélectionnez la durée (en minutes) au bout de laquelle le mode absence se remettra en marche lorsque l'utilisateur touche l'écran durant ce mode. Valeurs : de 10 à 120 minutes, par créneaux de 10 minutes (par défaut, 60 minutes).",
				"auto_mode": "Configurez les paramètres suivants du Mode Auto.",
				"temperature_differential": "Définit le différentiel minimum entre les températures de consigne des modes refroidissement et chauffage. Valeurs : de 0 à 3.5˚C / 0 à 7˚F par créneaux de 0,5˚C / 1˚F, par défaut : 1˚C / 2˚F.",
				"change_mode_protection": "Définit la durée (en minutes) de fonctionnement minimal de l'un des modes avant de permettre un changement de mode. Valeurs : 15, 30, 60 et 90 minutes (par défaut, 30 minutes).",
				"heat_differential": "Si une zone génère une demande de chauffage plus élevée que la température définie ici, le système revient au mode chauffage même si la demande globale en mode refroidissement est supérieure à la demande globale en mode chauffage. Valeurs : Off et de 1.5 à 4˚C / 3 à 8˚F par créneaux de 0,5˚C/1˚F. Par défaut : Off.",
				"antifreeze": "Évite que la température ambiante de la zone descende sous les 12˚C / 53˚F, y compris lorsque la zone est éteinte. Activée par défaut.",
				"environment_temperature": "Permet d'afficher/masquer la température ambiante et l'humidité relative de la zone. Ces informations sont affichées par défaut.",
				"heat_configuration": "Permet de configurer les paramètres suivants de l'étape de contrôle Air+Rayonnant.",
				"cold_mode": "Permet de sélectionner la température minimale du mode Refroidissement (18-26˚C / 64-78˚F, 18˚C / 64˚F par défaut).",
				"heat_mode": "Permet de sélectionner la température maximale du mode Chauffage (19-30˚C / 66-86˚F, 30˚C / 86˚F par défaut).",
				"proportional_operture_zbs": "Configuration du type d'ouverture de la sortie de registre : Tout / Rien ou Proportionnelle (par défaut).",
				"minimum_air_zbs": "Permet d'activer/désactiver l'entrée d'un débit minimum d'air dans les zones qui ont atteint la température de consigne.",
				"information": "Ce paramètre fournit des informations sur le système et les éléments connectés.",
				"standby_mode_text": "Permet de sélectionner le comportement de l’unité intérieure, aussi bien en chauffage qu’en refroidissement, lorsque le système est en confort.",
				"standby_on": "Les éléments motorisés des zones allumées (On) restent ouverts lorsqu'il n'y a pas de demande.",
				"standby_off": "Les éléments motorisés des zones allumées (On) restent fermés lorsqu'il n'y a pas de demande."
			}
		},
		"systems": {
			"modes": {
				"stop": "Stop",
				"header": "Modes de fonctionnement",
				"heat": "Chauffage",
				"heat-air": "Chauffage air",
				"heat-radiant": "Chauffage rayonnant",
				"heat-both": "Chauffage combiné",
				"cool": "Refroidissement",
				"cool-air": "Refroidissement air",
				"cool-radiant": "Refroidissement rayonnant",
				"cool-both": "Refroidissement combiné",
				"dehumidify": "Déshumidification",
				"ventilate": "Ventilation",
				"auto": "Auto",
				"emergency-heat": "Chaud auxiliaire",
				"heat-supply": "Chauffage d’appoint",
				"dehumidifier": "Déshumidificateur"
			},
			"eco": {
				"eco-off": "Eco Off",
				"header": "Eco-Adapt",
				"eco-m": "Eco manuel",
				"eco-a": "Eco A",
				"eco-aa": "Eco A+",
				"eco-aaa": "Eco A++"
			},
			"velocity": {
				"header": "Vitesse de l'air",
				"velocity-0": "Auto",
				"velocity-1": "Vitesse faible",
				"velocity-2": "Vitesse moyenne",
				"velocity-3": "Vitesse forte",
				"velocity-mlp": "Vitesse"
			},
			"air_flow": {
				"header": "Contrôle du débit",
				"air_flow-0": "Silence",
				"air_flow-1": "Standard",
				"air_flow-2": "Puissance"
			},
			"scene": {
				"header": "Mode utilisateur",
				"confort": "Confort",
				"eco": "Eco",
				"vacation": "Vacances",
				"unocupied": "Inoccupé",
				"night": "Nuit",
				"stop": "Stop"
			},
			"timer": {
				"header": "Timer",
				"timer-off": "0 minutes",
				"timer30": "30 minutes",
				"timer60": "60 minutes",
				"timer90": "90 minutes"
			},
			"consumption": {
				"header": "Compteur d’énergie"
			},
			"schedules": {
				"weekly": "Programmations hebdomadaires",
				"calendar": "Programmations de calendrier"
			},
			"without_consumption_data": "Aucune donnée de consommation n'est disponible pour le moment, veuillez vérifier à nouveau plus tard. Les données sont mises à jour toutes les heures.",
			"error0": "Erreur configuration CCP",
			"error1": "Erreur de communication CCP - Passerelle Pac Air-Eau",
			"error2": "Erreur de communication Passerelle Pac Air-Eau - Unite",
			"error3": "Erreur de communication du compteur de consommation",
			"error4": "Erreur de mesure du compteur de consommation",
			"error5": "Erreur H2 détectée; retard du registre activé. Pour le désactiver, accédez au menu de configuration avancée du système (mode de protection)",
			"error6": "Notification sur la configuration : Paramètres de protection et algorithme de contrôle désactivés. Consultez la documentation de la passerelle pour activer ces fonctions.",
			"error7": "Erreur unité :",
			"error8": "ECS activée",
			"error9": "Erreur de communications passerelle Altherma - platine centrale",
			"error10": "Erreur de communications passerelle Altherma - untié interieur"
		},
		"terms": {
			"accept": "J'accepte les ",
			"legal_terms": "Conditions d'utilisation",
			"and": " et la ",
			"privacy": "Politique de confidentialité",
			"publicity": "Je désire recevoir des communications"
		},
		"device_relations_list": {
			"title": "Serveurs",
			"no_servers_title": "Bienvenue chez Airzone !",
			"no_servers_info": "Pour commencer à contrôler votre installation, veuillez ajouter un serveur à votre compte.",
			"remove_confirmation": "Souhaitez-vous supprimer ce serveur de votre compte ?",
			"removed_server": "Serveur supprimé",
			"internal_error": "Erreur interne",
			"internal_error_message": "Votre action n'a pas pu être terminée. Veuillez essayer à nouveau plus tard.",
			"actions": {
				"edit": "Éditer",
				"config": "Configuration",
				"users": "Utilisateur",
				"remove": "Supprimer",
				"close": "Fermer",
				"accept": "Accepter",
				"cancel": "Annuler",
				"confirmation_deleted": "Le serveur a été supprimé avec succès",
				"add_server": "Ajouter serveur"
			}
		},
		"add_server": {
			"name_placeholder": "Nom (maison, bureau)",
			"mac_placeholder": "MAC (12:AB:34:CD:45:F0)",
			"pin_placeholder": "PIN",
			"location_placeholder": "Localité d'emplacement du serveur"
		},
		"add_user_to_device": {
			"title": "Ajouter utilisateur",
			"advanced": "avancé",
			"basic": "de base"
		},
		"users": {
			"title": "Utilisateurs",
			"basic_user": "utilisateurs de base",
			"advanced_user": "Utilisateurs avancés",
			"basic": "de base",
			"advanced": "avancé",
			"add_user": "Ajouter utilisateur",
			"destroy": "Supprimer utilisateur",
			"remove_confirmation": "Êtes-vous sûr de vouloir supprimer l'accès de l'utilisateur au serveur ?",
			"zones": "Zones d'accès",
			"blank": "Aucun utilisateur trouvé."
		},
		"admin_users": {
			"confirmed": "Confirmé",
			"results": "Résultats",
			"no_results": "Aucun résultat trouvé",
			"page": "Page",
			"of": "de",
			"device_modal": {
				"title": "Unités accesibles pour l'utilisateur",
				"no_devices": "L'utilisateur n'a accès à aucun équipe",
				"location": "Localisation"
			}
		},
		"zones": {
			"warnings": {
				"presence": "Alarme de Présence",
				"window": "Alarme de Fenêtre",
				"antifreeze": "Hors-gel",
				"dew": "Pt Rosé Actif",
				"dew_protection": "Protection rosé activé",
				"thermostat": "Zone sans thermostat",
				"heat_supply_activated": "Chauff. d’appoint activé",
				"low_valve_battery": "Pile vanne faible",
				"valve_NTC_alarm": "Alarme NTC2",
				"F05C": "Erreur F05-C : Sonde de température de la batterie du refroidissement en circuit ouvert",
				"F05H": "Erreur F05-H : Sonde de température de la batterie du chauffage en circuit ouvert",
				"F06C": "Erreur F06-C : Sonde de température de la batterie du refroidissement court-circuit",
				"F06H": "Erreur F06-H : Sonde de température de la batterie du chauffage en court-circuit",
				"dehumidifier_activated": "Déshumidificateur activé"
			},
			"adjusts": {
				"title": "Paramètres de zone",
				"title_all": "Toutes les zones",
				"name": "Nom",
				"name_system": "Nom du système",
				"autoturnoff": "Veille",
				"timer": "Timer",
				"defrosting": "Hors-gel",
				"local_ventilation": "Ventilation locale",
				"lite": "Paramètres Lite",
				"led": "État LED",
				"work_mode": "Étapes",
				"cool": "Refroidissement",
				"heat": "Chauffage",
				"air_direction": "Direction du débit d'air",
				"lama_direction": "Direction de lame",
				"horizontal": "Horizontale",
				"vertical": "Verticale",
				"name_explanation": "Cela permet de modifier le nom de la zone",
				"autoturnoff_explanation": "La zone s'éteint automatiquement au bout d'un intervalle de temps défini. Chaque 30 minutes, la température augmentera 1ºC en refroidissement ou diminuera 1ºC en chauffage.",
				"sleep_off": "Off - Désactiver la temporisation",
				"sleep_30": "30 - Active la temporisation et après 30 minute la zone sera désactivée.",
				"sleep_60": "60 - Active la temporisation et après 60 minute la zone sera désactivée.",
				"sleep_90": "90 - Active la temporisation et après 90 minute la zone sera désactivée.",
				"defrosting_explanation": "Lorsque la température ambiante descend en-dessous de 12ºC, le mode chauffage sera activér automatiquement.",
				"work_mode_explanation": "Permet de sélectionner la méthode à utiliser pour rafraîchir ou réchauffer une zone, lorsque l'installation permet de le faire au travers d'air ou plancher chauffant :",
				"mode_air": "Air - Air uniquement",
				"mode_radiant": "Rayonnant - Plancher chauffant uniquement",
				"mode_mix": " - Combine air et plancher chauffant",
				"air_direction_explanation": "Cela permet de sélectionner la direction du débit d'air en refroidissement et en chauffage(pour grille intelligente motorisée seulement).",
				"local_ventilation_explanation": "Cette option permet d’activer ou de désactiver  la ventilation dans la zone sélectionnée, lorsque le système ne refroidit ni ne chauffe aucune zone de manière active",
				"lite_explanation": "Permet d'éteindre le thermostat Lite filaire au bout de quelques secondes (indiqués dans l'état de la zone)"
			},
			"schedules": {
				"title": "Programmations",
				"init": "Début",
				"end": "Fin",
				"days": "Jours",
				"monday": "Lu",
				"tuesday": "Ma",
				"wednesday": "Me",
				"thursday": "Je",
				"friday": "Ve",
				"saturday": "Sa",
				"sunday": "Di",
				"add_schedule": "Ajouter programmation",
				"schedules_types": "Types de programmation",
				"temp": "Temp",
				"mode": "Mode",
				"vent": "Ventilation",
				"start_hour": "Heure de début",
				"end_hour": "Heure de fin",
				"start_mode": "Mode Début",
				"end_mode": "Mode Fin",
				"start_temp": "Temp. Début",
				"end_temp": "Temp. Fin",
				"start_vent": "Vent. Début",
				"end_vent": "Vent. Fin",
				"close": "Fermer",
				"remove": "Supprimer",
				"save": "Enregistrer",
				"enable": "Activer",
				"hour": "Heure",
				"temperature": "Température",
				"velocity": "Vitesse",
				"vel_0": "Automatique",
				"vel_1": "Vitesse 1",
				"vel_2": "Vitesse 2",
				"vel_3": "Vitesse 3",
				"new_prog": "Nouvelle programmation",
				"edit_prog": "Modifier programmation",
				"schedules_name": "Nom",
				"remove_confirmation": "Êtes-vous sûr de vouloir supprimer la programmation définitivement ?",
				"all_zones": "Toutes les zones",
				"systems": "systèmes",
				"waiting_title": "Création de programmations en cours",
				"waiting_text": "Nous sommes actuellement en train de créer les programmations. Ce processus peut prendre quelques secondes jusqu'à ce qu'il soit créé dans toutes les zones.",
				"save_confirmation": "Voulez-vous enregistrer cette programmation ?",
				"edit_confirmation": "Voulez-vous enregistrer ces modifications ?",
				"invalid_dates_range": "Il est impossible de sauvegarder la programmation pour les dates et la zone sélectionnés"
			}
		}
	},
	"it": {
		"adjust": {
			"title": "Impostazioni utente",
			"title_admin": "Impostazioni utente amministratore",
			"personal": "Dati personali",
			"language": "Lingua",
			"spanish": "Español",
			"english": "English",
			"french": "Français",
			"italian": "Italiano",
			"portuguese": "Português",
			"german": "Deutsch",
			"password": {
				"password_change": "Modifica password",
				"new_password_placeholder": "Nuova password",
				"new_password_repeat_placeholder": "Ripetere nuova password",
				"old_password_placeholder": "Password attuale"
			},
			"require": {
				"name": "Nome",
				"surname": "Cognome"
			},
			"advanced_adjust": "Impostazioni avanzate",
			"advanced_adjust_text": "Visualizza impostazioni avanzate",
			"time_sync": "Data e ora di sincronizzare",
			"save": {
				"title": "Impostazioni salvate",
				"body": "Le impostazioni sono state salvate."
			},
			"unsubscribe_confirm": "Desidera cancellarsi dall'applicazione Airzone? Tutti i suoi dati saranno eliminati e non potranno essere recuperati",
			"unsubscribe": {
				"title": "Cancellazione confermata",
				"body": "È stato cancellato da Airzone Cloud, tutti i suoi dati sono stati eliminati."
			},
			"change_pass": "Cambiare password",
			"warranty": "Estensione della garanzia"
		},
		"languages": {
			"es": "Spagnolo",
			"en": "Inglese"
		},
		"advanced_adjust": {
			"title": "Impostazioni avanzate",
			"subtitle": "Impostazioni dei sistemi e delle zone",
			"all_zones": "Tutte"
		},
		"adjust_zone": {
			"zone": "Zona",
			"dir_zone": "Indirizzo di zona",
			"associatted_exist": "Uscite associate",
			"non": "No",
			"yes": "Sì",
			"master": "Master",
			"basic_mode": "Modo Base",
			"amb_temp": "Temperatura ambiente",
			"master_dir": "Indirizzo master",
			"builder": "Costruttore",
			"stages": "Tipi di impianto",
			"heat": "Caldo",
			"heat_air": "Aria",
			"heat_radiant": "Radiante caldo",
			"heat_combined": "Caldo Combinato",
			"heat_combo": "Aria+Radiante",
			"cold": "Freddo",
			"cold_air": "Aria",
			"cold_radiant": "Freddo Radiante",
			"cold_combined": "Freddo Combinato",
			"cold_combo": "Aria+Radiante",
			"ventilation": "Ventilazione",
			"step1": "Livello 1",
			"step2": "Livello 2",
			"step3": "Livello 3",
			"caudal": "Portata",
			"q-adapt": "Q-Adapt: Peso aeraulico della zona",
			"inputs": "Ingressi",
			"window": "Finestra",
			"window_normally_open": "Normalmente Aperto",
			"window_normally_closed": "Normalmente Chiuso",
			"presence": "Presenza",
			"presence_normally_open": "Normalmente Aperto",
			"presence_normally_closed": "Normalmente Chiuso",
			"spray_dew": "Sonda Umidità",
			"spray_dew_normally_open": "Normalmente Aperto",
			"spray_dew_normally_closed": "Normalmente Chiuso",
			"local_ventilation": "Ventilazione",
			"local_vent_normally_open": "Normalmente Aperto",
			"local_vent_normally_closed": "Normalmente Chiuso",
			"information": "Informazioni",
			"firm_term": "Firmware Termostato",
			"firm_module": "Firmware Modulo",
			"warning": "Preavviso",
			"errors": "Errori",
			"error": "Errore",
			"error3": "Errore 3-Motorizzazione non rilevata",
			"error4": "Errore 4-Motorizzazione bloccata",
			"error5": "Errore 5-Sonda di temp. non rilevata",
			"error6": "Errore 6-Sonda di temp. in cortocircuito",
			"error7": "Errore 7-Elementi del sistema non compatibili",
			"error8": "Errore 8-Comunicazione persa con modulo di sonda remota",
			"error9": "Errore 9-Allarme presenza",
			"error10": "Errore 10-Allarme finestra",
			"error11": "Errore 11-Allarme antigelo",
			"error12": "Zona senza termostato",
			"error15": "Errore 15-Errore di comunicazione tra misuratore elettrici. e scheda centrale del sistema",
			"error16": "Errore 16-Errore di misurazione del misuratore elettrici",
			"manufacturer_0": "Costruttore non definito",
			"manufacturer_1": "Fujitsu General I",
			"manufacturer_2": "Fujitsu General II",
			"manufacturer_3": "Daikin I",
			"manufacturer_4": "Daikin II",
			"manufacturer_5": "LG",
			"manufacturer_6": "Tango",
			"manufacturer_7": "Mitsubishi Electric",
			"manufacturer_8": "Toshiba",
			"manufacturer_9": "Gree",
			"no_errors": "Non ci sono errori",
			"use_adapt_set": "Temp. modo utente",
			"zone_weight": "Peso dellla zona",
			"eco_function": "Funzione Eco",
			"function_eco": {
				"1": "Normalmente Aperto",
				"2": "Normalmente Chiuso"
			},
			"DC_fan_voltage": "Voltaggio DC del ventilatore",
			"min_volt": "V minimo",
			"max_volt": "V massimo",
			"constant_ventilation": "Ventilazione constante",
			"cold_battery_temperature": "Temp. batt. fredda",
			"heat_battery_temperature": "Temp. batt. calda",
			"relay_title": "Relé ON/OFF macchina",
			"relay": "Relé",
			"control_stage": "Tipi di impianto",
			"cold_control_stage": {
				"0": "Espansione diretta",
				"1": "Aqua",
				"2": "Apporto di aria",
				"3": "Pavimento",
				"4": "Radiatore/Soffitto",
				"5": "Eletrico"
			},
			"heat_control_stage": {
				"0": "Eletrico",
				"1": "Aqua",
				"2": "Apporto di aria",
				"3": "Pavimento",
				"4": "Radiatore/Soffitto",
				"5": "Eletrico"
			},
			"valves": "Valvole",
			"help": {
				"direction_zone": "Indirizzo della zona",
				"direction_zone_text": "Parametro che indica l'indirizzo della zona selezionata.",
				"slave": "Uscite secondarie",
				"slave_text": "Parametro che indica se la zona selezionata ha moduli/uscite secondarie ed eventualmente l'indirizzo delle stesse.",
				"master": "Master",
				"master_text": "Consente di impostare la zona selezionata come master. Permette di scegliere tra SÌ o NO.",
				"basic_mode": "Modo Base",
				"basic_mode_text": "Consente di disattivare le funzioni avanzate del termostato lasciando il controllo base per facilitarne l'utilizzo. Selezionare ON per attivare il modo base. In caso contrario selezionare OFF.",
				"ambient_temp": "Temperatura Ambiente",
				"ambient_temp_text": "Parametro che mostra la temperatura ambiente della zona.",
				"manufacturer": "Costruttore",
				"manufacturer_text": "Parametro che indica i modelli di unità da comandare",
				"heat_type_config": "Tipi di impianti in caldo",
				"heat_type_config_text": "Permette di impostare i tipi di impianto in caldo presenti nella zona. E' possibile scegliere tra Aria, Radiante, Combinato ed OFF, che annulla il modo caldo nella zona selezionata.",
				"cold_type_config": "Tipi di impianto in freddo",
				"cold_type_config_text": "Permette di impostare i tipi di impianto in freddo presenti nella zona. E' possibile scegliere tra Aria, Radiante, Combinato ed OFF, che annulla il modo freddo nella zona selezionata.",
				"ventilation": "Ventilazione",
				"ventilation_text": "Attiva/Disattiva la funzione di ventilazione della zona.",
				"q_weight": "Q-Adapt: Peso aeraulico della Zona",
				"q_weight_text": "Il suo sistema Acuazone/IBPRO32/Innobus Air, configurato con Q-Adapt manuale, consente di impostare il peso aeraulico della zona con valori tra 1-99.",
				"window": "Finestra",
				"window_text": "Consente di attivare/disattivare una zona in funzione di un contatto finestra. Fino a quando questo ingresso rimarrà attivo, la climatizzazione nella zona sarà spenta. Per attivare questa funzione, impostare la logica di funzionamento del contatto tra i seguenti valori: Normalmente Aperto o Normalmente chiuso. Il valore OFF disabilita la funzione.",
				"presence": "Presenza",
				"presence_text": "Consente di controllare lo stato di una zona in funzione di un sensore di presenza. Se non viene rilevata presenza, verrà attivato il modo Sleep a 90 minuti. Per attivare questa funzione, impostare la logica di funzionamento del contatto tra i seguenti valori: Normalmente Aperto o Normalmente chiuso. Il valore OFF disabilita la funzione.",
				"spray_dew": "Sonda umidità",
				"spray_dew_text": "Consente di attivare/disattivare la deumidificazione tramite impianto ad aria per mezzo di un sensore di umidità. Per attivare questa funzione, impostare la logica di funzionamento del contatto tra i seguenti valori: Normalmente Aperto o Normalmente chiuso. Il valore OFF disabilita la funzione.",
				"local_vent": "Ventilazione",
				"local_vent_text": "Attiva/disattiva la ventilazione della zona. Per attivare questa funzione, impostare la logica di funzionamento del contatto tra i seguenti valori: Normalmente Aperto o Normalmente chiuso. Il valore OFF disabilita la funzione.",
				"errors": "Errori",
				"errors_text": "Parametro che indica se ci sono errori attivi nella zona selezionata (SI/NO). In caso positivo, premere sul parametro stesso per visualizzare gli errori presenti.",
				"firmt": "Firmware Termostato",
				"firmt_text": "Parametro indicante il firmware del termostato della zona.",
				"firmm": "Firmware Modulo",
				"firmm_text": "Parametro indicante il firmware del modulo locale della zona.",
				"associatted_exist_text": "Mostra le uscite di controllo associate al termostato.",
				"use_adapt_set_text": "Configurare le temperature impostate per ognuno dei modi dei modi dell’utente",
				"confort_text": "Modo caldo: da 15 a 30 ˚C  / 59 a 86˚F (20 ˚C / 68˚F per difetto). Modo freddo: da 18 a 30 ˚C / 64 a 86 ˚F (24 ˚C / 75˚F per difetto).",
				"eco_text": "Modo caldo: da 15 a 30 ˚C  / 59 a 86˚F (19 ˚C / 66 ˚F per difetto). Modo freddo: da 18 a 30 ˚C / 64 a 86 ˚F (29 ˚C / 77˚F per difetto).",
				"unocupied_text": "Modo caldo: da 15 a 22 ˚C / 59 a 72˚F (17,5 ˚C  / 63˚F per difetto). Modo freddo: da 24 a 30 ˚C / 75 a 86 ˚F (27 ˚C  / 81 ˚F per difetto).",
				"vacation_text": "Modo caldo: da 10 a 16 ˚C / 50 a 61˚F (10 ˚C  / 50˚F per difetto). Modo freddo: da 29 a 35,5 ˚C / 84 a 96 ˚F (35 ˚C / 95 ˚F per difetto).",
				"offset_text": "Correzione della temperatura ambiente delle diverse zone del sistema in modo freddo e in modo caldo, con un fattore di correzione compreso tra - 2,5 °C e 2,5 °C / -5 e 5 °F a intervalli di 0,5 °C / 1 °F. Si trova configurato a 0 °C / 0 °F per default.",
				"stages_text": "Permette di configurare le sorgenti freddo e caldo nella zona selezionata o in tutte le zone del sistema.",
				"air_text": "Abilita il modo caldo/freddo ad aria nella zona selezionata.",
				"radiant_text": "Abilita il caldo/freddo radiante nella zona selezionata.",
				"air_radiant_text": "abilita il caldo/freddo ad aria e radiante nella zona selezionata e consente all'utente di selezionare la sorgente caldo che desidera in tale zona.",
				"inputs_text": "Permette di gestire lo stato di un modulo di zona in base al rilevamento di presenza o finestra.",
				"window_zbs_text": "Agisce solo sull'elemento di controllo del modulo di zona. Se viene rilevata l'apertura di una finestra in una zona in cui si trova attivato questo contatto, questa si spegne dopo 60 secondi, mostrando un avviso Finestra attiva nel termostato. Questa funzione è disattivata per difetto. Per attivarla è necessario definire la logica del contatto come Normalmente aperto o chiuso.",
				"presence_zbs_text": "Agisce sull'elemento di controllo del modulo di zona solo se è associato a un termostato. Non funziona in moduli di zona secondari. Se dopo 5 minuti non viene rilevata alcuna presenza in una zona in cui si trova attivato questo contatto, si attiva Sleep 90, mostrando un avviso Presenza attiva nel termostato. Questa funzione è disattivata per difetto. Per attivarla è necessario definire la logica del contatto come Normalmente aperto o chiuso.",
				"information_text": "Consente di visualizzare le informazioni di sistema e dispositivi."
			}
		},
		"buttons": {
			"sign_in": "Entra nel Cloud",
			"cancel": "Annulla",
			"init": "Avvio",
			"send": "Invia",
			"save": "Salva",
			"accept": "Accetta",
			"resend_confirmation": "Inviare nuovamente la conferma?",
			"back": "Indietro",
			"icon": "Icona",
			"unsubscribe": "Cancellare l'iscrizione",
			"remove": "Rimuovi",
			"restore_factory": "Ripristinare le impostazioni di fabbrica",
			"next": "Avanti",
			"prev": "Indietro",
			"close": "Chiudi",
			"recharge": "Aggiorna",
			"hide": "Nascondi",
			"update_device": "Rileva sistemi",
			"continue": "Continua",
			"exit": "Esci",
			"help": "Aiuto",
			"select": "Selezionare",
			"invite": "Invitare",
			"update": "Aggiornare"
		},
		"email": "Email",
		"name": "Nome",
		"surname": "Cognome",
		"password": "Password",
		"new_password": "Nuova password",
		"repeat_password": "Reinserire password",
		"server": {
			"notification": {
				"message": "È già disponibile la tariffa elettrica per domani.",
				"alert_message": {
					"title": "Notifiche",
					"text": "Desidera ricevere notifiche da Airzonecloud?",
					"remember": "Non mi chiedere di nuovo"
				}
			},
			"temp": "Temperatura",
			"months": {
				"0": "GEN",
				"1": "FEB",
				"2": "MAR",
				"3": "APR",
				"4": "MAG",
				"5": "GIU",
				"6": "LUG",
				"7": "AGO",
				"8": "SET",
				"9": "OTT",
				"10": "NOV",
				"11": "DIC"
			},
			"days": {
				"0": "Domenica",
				"1": "Lunedì",
				"2": "Martedì",
				"3": "Mercoledì",
				"4": "Giovedì",
				"5": "Venerdì",
				"6": "Sabato"
			},
			"current_price": "PREZZO ATTUALE",
			"minimum_price": "MINIIMO",
			"maximum_price": "MASSIMO",
			"day_price": "PREZZO DEL GIORNO"
		},
		"state": "Stato",
		"enabled": "Abilitato",
		"sleeping": "Attesa",
		"connected": "Collegato",
		"activated": "Attivato",
		"components": "Componenti",
		"historic": "Cronologia",
		"system": "Sistema",
		"filters": "Filtri",
		"mac": "MAC",
		"locality": "Località",
		"all": "Tutti",
		"pin": "PIN",
		"zone_name": "Nome",
		"connection": "Connessione",
		"compare": "Confrontare",
		"day": "Giorno",
		"month": "Mese",
		"year": "Anno",
		"range": "Gamma",
		"maximum": "Massimo",
		"minimum": "Minimo",
		"mode": "Moda",
		"average": "Media",
		"without_data": "Non esistono dati sul consumo",
		"full_page": "Vedere a tutto schermo",
		"print_chart": "Stampare tabella",
		"download_png": "Scaricare in PNG",
		"download_jpg": "Scaricare in JPG",
		"download_pdf": "Scaricare in PDF",
		"download_avg": "Scaricare in AVG",
		"download_csv": "Scaricare in CSV",
		"download_xls": "Scaricare in XLS",
		"view_data_table": "Visualizza la tabella dei dati",
		"modal_mode": {
			"stop": "Stop",
			"stop_text": "Spegnimento totale del sistema",
			"cold_air": "Freddo Aria",
			"cold_air_text": "Freddo tramite impianto ad aria",
			"heat_radiant": "Caldo radiante",
			"heat_radiant_text": "Caldo tramite impianto radiante",
			"ventilate": "Ventilazione",
			"ventilate_text": "Sola ventilazione",
			"heat_air": "Caldo Aria",
			"heat_air_text": "Caldo tramite impianto ad aria",
			"heat_both": "Caldo combinato",
			"heat_both_text": "Caldo tramite entrambi gli impianti",
			"dehumidify": "Deumidificazione",
			"dehumidify_text": "Sola deumidifazione",
			"cold_radiant": "Freddo radiante",
			"cold_radiant_text": "Freddo tramite impianto radiante",
			"cold_both": "Freddo combinato",
			"cold_both_text": "Freddo tramite entrambi gli impianti",
			"auto_text": "Consente di cambiare automaticamente tra freddo e caldo, in base alla domanda globale.",
			"emergency_heat": "Caldo emergenza",
			"emergency_heat_text": "Attiva il caldo ausiliare per somministrare l’aria calda in caso di un errore meccanico nel sistema."
		},
		"modal_eco": {
			"title": "Modo ECO",
			"eco": "Selezioni il livello di efficienza energetica desiderato. Con queste impostazioni sarà data la precedenza al risparmio energetico, i tempi di messa a regime potrebbero allungarsi."
		},
		"modal_speed": {
			"title": "Velocità di ventilazione disponibili",
			"speed": "Selezionare una velocità. A valore più alto corrisponde una maggiore velocità dell'aria."
		},
		"modal_air_flow": {
			"silence": "Il sistema lavora a una velocità inferiore che in modo Standard con lo scopo di ridurre il rumore dell’impianto.",
			"standard": "Configurazione predefinita. Il sistema modifica la velocità in funzione del numero di zone aperte.",
			"power": "Il sistema lavora ad una velocità più alta che in modo Standard con lo scopo di aumentare la portata."
		},
		"modal_scene": {
			"stop": "Il sistema viene spento completamente indipendentemente dello stato del resto delle zone. Inoltre, tutti gli elementi terminali verranno chiuse.",
			"confort": "Modo dell’utente standard per difetto. Selezionare la temperatura impostata desiderata usando i limiti di temperatura prestabiliti.",
			"unoccupied": "Si utilizza quando non viene rilevata presenza in brevi periodi di tempo. Verrà stabilita una temperatura impostata più efficiente. Se il termostato è attivato la zona inizierà a lavorare in modo comfort.",
			"night": "Il sistema cambia automaticamente la temperatura impostata di 0,5 °C / 1 °F ogni 30 minuti fino a un massimo di 2 °C / 4 °F. Durante il raffreddamento il sistema incrementa la temperatura impostata, durante il riscaldamento la diminuisce.",
			"eco": "Questo parametro imposta il livello di efficienza energetica del sistema limitando le temperature impostabili in ambiente dai termostati allo scopo di favorire al massimo un utilizzo efficiente dell’impianto.",
			"vacation": "Questa funzione consente di risparmiare energia quando l’utente è assente per lunghi periodi di tempo."
		},
		"home": {
			"menu": {
				"servers": "Server",
				"adjusts": "Impostazioni",
				"help": "Aiuto",
				"admin": "Utente",
				"exit": "Uscire",
				"admin_users": "Utenti",
				"admins": "Amministratori",
				"connections": "Connessioni"
			},
			"upload": {
				"title": "È disponibile una nuova versione di Airzone Cloud",
				"description": "Si prega di aggiornare l'applicazione per poterla utilizzare."
			},
			"maintenance": {
				"title": "Questo sito è temporaneamente in manutenzione",
				"description": "Questo sito è attualmente in fase di manutenzione.",
				"text": "E sarà presto disponibile."
			}
		},
		"installations": {
			"return": "Indietro",
			"exit": "Uscire",
			"finish": "Fine",
			"next": "Avanti",
			"edit": "Modifica",
			"error": "È successo un errore nella connessione con il Webserver Airzone Cloud. Verifica che il tuo dispositivo sia collegato alla rete Wifi del Webserver Airzone Cloud (AZWSCLOUDxxx).",
			"error_mac_not_found": "Si è verificato un errore durante l'ottenimento dell'indirizzo Mac dal webserver",
			"error_sleeping": "Si è verificato un errore, il WServer non è stato provisioning",
			"error_not_connected": "Si è verificato un errore, il WServer non riesce a connettersi al cloud",
			"retry": "Riprovare",
			"state1": {
				"text": "Verificare che il dispositivo sia correttamente alimentato (LED D11 fisso) e che si trovi nel raggio di copertura"
			},
			"state2": {
				"text": "Verifichi che il webserver sia alimentato e che il LED WIFI/D13 sia acceso in modo fisso."
			},
			"state3": {
				"assisted": "Installazione Assistita",
				"wps": "Installazione WPS"
			},
			"state4": {
				"text": "Selezioni la rete wifi del Webserver (AZWSCLOUDxxxx) dal menù impostazioni del suo dispositivo e di seguito torni a questo menu.",
				"text1": "Se ricevi notifica di perdita connessione internet, mantieni attiva la connessione con il Webserver per continuare la procedura di associazione.",
				"text2": "Selezioni la rete wifi AZWSCLOUDxxxx",
				"text3": "Se utilizza Android 5 o versioni successive disabilitare la rete dati",
				"loading_text": "Configurazione rete. Attendere...",
				"low_signal": {
					"title": "Copertura insufficiente",
					"text": "Avvicina il Webserver al router o installa un amplificatore Wifi per migliorare la copertura"
				},
				"auto": "Automático",
				"select_router": "Scegli il metodo di associazione al router",
				"auto_info": "Automatico. Cerca automaticamente le reti wireless nelle vicinanze al Webserver.",
				"manual_info": "Manuale. Inserisci manualmente i dati della rete wireless."
			},
			"state5": {
				"text": "Selezioni il router a cui associarsi:",
				"name": "Nome",
				"sec": "Sicurezza",
				"signal": "Segnale",
				"loading_text": "Collegamento dispositivo. Attendere prego...",
				"loading_text2": "(Se la connessione ritarda più di un minuto, prego verificare che la connessione del dispositivo sia corretta)",
				"no_routers": "Non si trova nessuna rete WiFi disponibile, verifica che la rete sia attiva e visibile per realizzare la connessione."
			},
			"state6": {
				"text": "Inserisca i dati della sua rete Wifi'",
				"none": "Senza sicurezza",
				"wep": "WEP",
				"wpa": "WPA/WPA2"
			},
			"state7": {
				"text": "Dati di rete",
				"ip": "IP",
				"port": "Gateway",
				"masc": "Maschera di sottorete",
				"manual": "MANUALE",
				"dhcp": "DHCP",
				"loading_text": "Configurazione del webserver in corso. Attendere prego...",
				"advanced": "Impostazioni avanzate"
			},
			"state8": {
				"text": "Configurazione realizzata correttamente"
			},
			"state9": {
				"text": "Webserver associato correttamente, verificare che il Led Wifi/D10 stia lampeggiando.",
				"select": "Seleziona il tuo webserver",
				"searching": "Ricerca nei dispositivi Bluetooth",
				"not_found": "Dispositivi Bluetooth non trovati",
				"not_appear": "Non compare sul dispositivo?",
				"click_here": "Premere qui"
			},
			"state15": {
				"title": "Associazione manuale"
			},
			"state16": {
				"title": "Come posso ottenere l’indirizzo MAC del Webserver?",
				"boldtext1": "Con la app.",
				"text1": " Dalla schermata Server, fare clic su Modifica, selezionare l’icona con la matita e all’interno di questo menu consultare l’indirizzo MAC del dispositivo.<br>",
				"boldtext2": "La copertina del manuale per l'utente del Webserver",
				"text2": " dispone di un adesivo in cui appare il codice del prodotto, l’indirizzo MAC e il PIN.",
				"boldtext3": "Mediante il termostato Blueface",
				"text3": " (AZxx6BLUEFACEC) (solo se è maestro del sistema 1). Accedere al menu Configurazione utente > Informazioni > Webserver e consultare l’indirizzo MAC del dispositivo.",
				"boldtext4": "Mediante il termostato Think",
				"text4": " (AZxx6THINKx) (solo se è maestro del sistema 1). Accedere al menu Configurazioni avanzate (tenere premuto a lungo per due volte) > Sistema > Informazioni > Webserver e consultare l’indirizzo MAC del dispositivo.",
				"boldtext5": "Il dispositivo",
				"text5": " dispone di un adesivo in cui appare il codice del prodotto, l’indirizzo MAC e il PIN."
			}
		},
		"login": {
			"rememberme": "Ricordati di me",
			"restore_password": "Ricordare password",
			"demo": "Demo",
			"router": "Associare router"
		},
		"register": {
			"sign_up": "Creare account",
			"repeat_password": "Confermare password"
		},
		"complete_registration": {
			"title": "Registrazione completa."
		},
		"messages": {
			"migrated_user": "Utente registrato sulla nuova piattaforma",
			"migrated_installation": "Installazione registrata sulla nuova piattaforma",
			"new_app": "Installa la tua nuova app mobile Airzonecloud:",
			"errors": {
				"recaptcha_response": "Errore durante la verifica del captcha, riprova più tardi",
				"recaptcha": "Attenzione, il recaptcha non è corretta",
				"name_blank": "Inserire nome",
				"surname_blank": "Inserire cognome",
				"login": "Attenzione, la sua email o la sua password non sono corrette",
				"email": "Attenzione, la sua email non è corretta",
				"email_blank": "Attenzione, inserire un indirizzo email",
				"email_taken": "Indirizzo email già esistente",
				"password_length": "La password deve essere di almeno 8 caratteri",
				"password_blank": "Attenzione, inserire una password",
				"password_confirmation_check": "Attenzione, le password devono corrispondere",
				"password_confirmation_blank": "Confermi la sua password",
				"password_new_length": "La password deve essere di almeno 8 caratteri",
				"password_new_blank": "Attenzione, inserire una password",
				"condition": "Attenzione, deve accettare i termini di servizio e l'informativa sulla privacy",
				"token_invalid": "Il codice di attivazione non è corretto. Ripetere il processo di recupero della password.",
				"email_not_exist": "Non esiste nessun account associato a questo indirizzo email.",
				"confirmation": "Si è verificato un errore durante le operazioni di conferma. La preghiamo di compilare nuovamente il modulo e le invieremo una nuova email di conferma.",
				"unconfirmed": "Indirizzo email non confermato",
				"server_blank": "Inserire un nome per il server",
				"mac_blank": "Inserire l'indirizzo MAC del suo server",
				"mac_invalid": "La MAC inserita non è corretta. Verifica che la MAC corrisponda ai dati del Webserver Airzone Cloud.",
				"server_sleeping": "Il Webserver Cloud richiesto non ha connessione ad Internet. Verifica che sia associato al router e che il router abbia connessione ad Internet.",
				"mac_format": "Il formato dell'indirizzo MAC non è corretto",
				"mac_repeated": "Esiste già un server con questo indirizzo MAC",
				"pin_blank": "Inserisca il codice PIN del suo server",
				"pin_invalid": "Il codice PIN inserito non corrisponde alla MAC",
				"location_blank": "Inserisca la località dove si trova il suo server",
				"server_icon_blank": "Selezioni una icona per rappresentare il suo server",
				"server_taken": "La MAC richiesta è già stata registrata sulla piattaforma Cloud",
				"file_error": "Il formato del file è errato. I formati supportati sono xls, xlsx e csv.",
				"must_select_file": "Selezionare almeno un file",
				"not_connected": "Attualmente non sei collegato a Internet quindi i comandi inviati non avranno effetto nel sistema. Ripristina la connessione a Internet e riprova.",
				"not_connected_title": "Avviso sulla connessione Internet",
				"not_connected_device": "Il dispositivo Airzone non è collegato a Internet quindi i comandi inviati non avranno effetto. Collegare il dispositivo e riprovare.",
				"not_connected_device_title": "Avviso sulla connessione del suo dispositivo",
				"error_device": "Si è verificato un errore. Se questo messaggio persiste per favore contatti il centro assistenza.",
				"registration_already_completed": "Registrazione già realizzata. Prego, accedere con i dati corretti.",
				"user_taken": "Utente già associato con il server",
				"without_internet_title": "Connessione Internet",
				"without_internet_text": "Questa applicazione richiede una connessione a Internet per funzionare Collegare il dispositivo e riprovare.",
				"day_blank": "È necessario selezionare un giorno",
				"mac_length_seek": "Sono richieste 4 cifre minime",
				"email_length_seek": "Sono richiesti almeno 3 caratteri",
				"not_systems_title": "Avviso dei sistemi",
				"not_systems": "Nessun sistema rilevato. Si prega di controllare l'installazione.",
				"location_title": "Localizzazione disattivata",
				"location_text": "Verificare nelle impostazioni del proprio telefono che la localizzazione sia attiva.",
				"location_disabled_title": "Localizzazione non abilitata",
				"location_disabled_text": "Verificare nelle impostazioni del proprio telefono che la localizzazione sia abilitata per questa applicazione.",
				"bluetooth_disabled_title": "Bluetooth disattivato",
				"bluetooth_disabled_text": "Verificare nelle impostazioni del proprio telefono che il Bluetooth si attivo.",
				"bluetooth_connect_device_title": "Dispositivo scollegato",
				"bluetooth_connect_device_text": "Perdita del collegamento Bluetooth.",
				"bluetooth_scan_title": "Errore di scansione",
				"bluetooth_scan_text": "Errore durante il tentativo di scansione dei dispositivi Bluetooth."
			},
			"success": {
				"register": "Registrazione eseguita correttamente!",
				"send_reset_password": "Le abbiamo appena inviato una email con le istruzioni per ottenere una nuova password.",
				"reset_password": "Password modificata",
				"resend_confirmation": "Abbiamo inviato una email di conferma al suo indirizzo.",
				"user_server_added": "La connessione con il server è stata stabilita correttamente. Può accedere dal menu dei server.",
				"user_server_updated": "Informazione del server correttamente aggiornate.",
				"restore_factory": "Il server ha ripristinato le impostazioni iniziali.",
				"added_basic_user_to_server": "Utente aggiunto correttamente al server. Premere su Accetta per accedere alla schermata di impostazione degli utenti.",
				"added_advanced_user_to_server": "Utente aggiunto correttamente al server.",
				"sync_datetime": "La data ed ora del servitore si è attualizzato correttamente in tutti i sistemi."
			},
			"info": {
				"has_email": "Nuova email!",
				"go_login": "Andare alla pagina di Login",
				"reset_password": "Torni alla pagina di Login e inserisca il suo indirizzo email e la nuova password per accedere.",
				"change_password": "Inserisca e ripeta la sua nuova password.",
				"send_reset_password": "Ha dimenticato la password? Non si preouccupi! Indichi il suo indirizzo email e le invieremo le istruzioni per modificarla.",
				"register_success": "Abbiamo inviato una email al suo indirizzo per verificare la sua identità.",
				"complete_registration_success": "Registrazione completata correttamente. Adesso può registrarsi e accedere ad Airzone Cloud.",
				"resend_confirmation": "Inserisca il suo indirizzo email e le invieremo una nuova conferma.",
				"unconfirmed": "Prego, prima di registrarsi, confermi la sua email",
				"remove_server_admin": {
					"question": "Desidera cancellare questo server?",
					"info": "Tutti i dati associati a questo server saranno cancellati."
				},
				"restore_server_admin": {
					"question": "Desidera ripristinare le impostazioni di fabbrica del server?",
					"info": "Tutti i dati associati a questo server saranno cancellati."
				},
				"update_server": "Aggiornamento server. Questa operazione potrebbe richiedere alcuni minuti.",
				"update_server_info": "Avvio del processo di aggiornamento di questo server web alla versione",
				"update_server_confirmation": "Vuole aggiornare questo server?",
				"browsers": "Attenzione, il browser non è compatibile con la applicazione di Airzone oppure è una versione molto vecchia. Se desidera utilizzare l'applicazione, le raccomandiamo di installare o aggiornare uno dei seguenti browser.",
				"disable_permission_device": "È necessario avere il permesso in almeno una zona",
				"auto_mode_battery_temperature": "Cambio del modo automatico abilitato",
				"purifier_maintenance1": "Realizza il mantenimento del suo impianto di purificazione/igiene.",
				"purifier_maintenance2": "Realizza il mantenimento del suo impianto di purificazione/igiene. Accedi al mené di configurazione avanzata del sistema per riniziare il contatore (mantenimento del purificatore).",
				"purifier_maintenance_before": "Realizza il mantenimento del suo impianto di purificazione/igiene prima di :"
			},
			"title": {
				"reset_password": "Recupero password",
				"change_password": "Cambiare password",
				"resend_confirmation": "Rinviare conferma",
				"user_server_added": "Server aggiunto correttamente",
				"user_server_updated": "Server aggiornato correttamente",
				"add_server": "Aggiungere server",
				"edit_server": "Modifica server",
				"error": "Errore",
				"server_data": "Dati del server",
				"remove_server": "Eliminare server",
				"restore_factory": "Ripristinare impostazioni di fabbrica",
				"user_server_added_device": "Utente aggiunto correttamente",
				"sync_datetime": "Sincronizzazione corretta",
				"device_permissions": "Permessi utenti",
				"maintenance": "Mantenimento",
				"browsers": "Browser non supportato"
			}
		},
		"servers": {
			"no_results": "Non ci sono server configurati",
			"tags": {
				"servers": "Server",
				"results": "Risultati",
				"page": "Pagina",
				"of": " di "
			},
			"new": {
				"add_servers": "Aggiungere server",
				"return": "Indietro",
				"from_file": "Da file",
				"select_file": "Selezionare un file",
				"select_file2": "Selezionare un file",
				"from_form": "Da modulo",
				"success_add2": "Sono stati aggiunti",
				"success_add_f2": "servers correttamente",
				"success_add1": "E' stato aggiunto",
				"success_add_f1": "server correttamente",
				"failure_add1": "Non si può aggiungere questo server",
				"failure_add2": "Non si possono aggiungere questi server",
				"failure_add_f2": "server",
				"accept": "Accettare",
				"success": {
					"added": "Server aggiunto correttamente",
					"added2": "Server correttamente incorporato sulla piattaforma"
				}
			}
		},
		"system_config": {
			"0": "Sistema zonificato",
			"1": "Sistema zonificato FC",
			"2": "Installazione a 2 tubi",
			"3": "Installazione a 4 tubi",
			"4": "RadianT",
			"6": "Airzone INT",
			"title": "Impostazioni di sistema",
			"systems": "Sistemi",
			"acs_system": "ACS",
			"system_fw": "Firmware",
			"ccp_options": {
				"0": "Idronica",
				"1": "2 tubi",
				"2": "4 tubi",
				"3": "RadianT"
			},
			"setup_type": "Tipo di installazione",
			"blueface_number": "Blueface",
			"think_r": "Think R",
			"think_c": "Think C",
			"lite": "Lite",
			"radio_number": "Tacto R",
			"tact_number": "Tacto C",
			"probe_number": "Sonda",
			"firm_ws": "Firmware Webserver",
			"cold_mixing_valve": "Valvola miscelatrice freddo",
			"heat_rele_board_number": "Numero di schede di relè in modo caldo",
			"cold_rele_board_number": "Numero di schede di relè in modo freddo",
			"combi_rele_board_number": "Numero di schede di relè in modo combinato",
			"rele_fw_1": "Firmware Scheda Relè 1",
			"rele_fw_2": "Firmware Scheda Relè 2",
			"rele_fw_3": "Firmware Scheda Relè 3",
			"rele_fw_4": "Firmware Scheda Relè 4",
			"rele_fw_5": "Firmware Scheda Relè 5",
			"rele_fw_6": "Firmware Scheda Relè 6",
			"rele_fw_7": "Firmware Scheda Relè 7",
			"rele_fw_8": "Firmware Scheda Relè 8",
			"fancoil_gw": "Interfaccia Fancoil",
			"vent_gw": "Interfaccia Ventilazione",
			"dehum_gw": "Interfaccia Deumid.",
			"heat_eme_gw": "Interfaccia Calore Emergenza",
			"heat_aux_gw": "interfaccia Calore Ausiliare",
			"knx_gw": "Interfaccia KNX",
			"radio_switchboard_fw": "Modulo valvole radio",
			"ccp": "Centrale Controllo Produzione",
			"system_number": "Numero del sistema",
			"direction_system": "Indirizzo del sistema",
			"etapes": "Tipi di impianto",
			"cold_mode": "Modo freddo",
			"heat_mode": "Modo caldo",
			"cold_mode_combined": "Freddo Combinato",
			"heat_mode_combined": "Caldo Combinato",
			"min_temp": "Temp. Minima",
			"max_temp": "Temp. Massima",
			"hysteresis": "Isteresi",
			"cold_hysteresis": "Freddo isteresi",
			"heat_hysteresis": "Caldo isteresi",
			"protect_temp": "Temperatura di Protezione",
			"cauldron": "Caldaia",
			"cold_aco_air": "Climatizzazione - Modo Freddo",
			"heat_aco_air": "Climatizzazione - Modo Caldo",
			"radiant_algorithm": "Algoritmo radiante",
			"proportional_operture": "Apertura Proporzionale",
			"min_air": "Aria minima",
			"master_installation": "Supermaestro",
			"q_adapt": "Q-Adapt",
			"q_adapt_c": "Q-Adapt",
			"q_adapt_d": "Q-Adapt",
			"q_adapt0": "Standard",
			"q_adapt1": "Potenza",
			"q_adapt2": "Silenzio",
			"q_adapt3": "Minimo",
			"q_adapt4": "Massima",
			"min_volt": "Tensione minima",
			"max_volt": "Tensione massima",
			"q_adaptd0": "Automatico",
			"q_adaptd1": "Manuale",
			"ventilation_mode": "Ventilazione",
			"system_weight": "Peso aeraulico del sistema",
			"work_mode": "Tipo di ventilazione",
			"work_mode_ext_air": "Aria esterna",
			"work_mode_recirc": "Ricircolo",
			"work_mode_combined": "Combinato",
			"air_ventilate": "Climatizzare e Ventilare",
			"overall_ventilation": "Ingresso Ventilazione Generale",
			"normally_open": "Normalmente Aperto",
			"normally_closed": "Normalmente Chiuso",
			"busy": "Freecooling Occupato",
			"unoccupied": "Freecooling libero",
			"delta": "Delta",
			"ext_temp": "Temperatura Esterna",
			"setpoint_temperature": "Temperatura Impostata",
			"input_output": "Ingresso/Uscita",
			"input": "Ingresso",
			"probe": "Sonda",
			"stop_start": "On-Off",
			"output": "Uscita",
			"demand_vmc": "Richiesta VMC",
			"demand_radiant": "Radiante",
			"air": "Aria",
			"minutes": "Minuti",
			"caudal": "Portata",
			"information": "Informazione",
			"errors": "Errori",
			"error0": "Non esistono errori",
			"error1": "Errore nella interfaccia della macchina",
			"global_ventilation": "Ventilaz. globale",
			"global_ventilation_every": "Ogni (min)",
			"global_ventilation_run_for": "Per (min)",
			"antifreeze": "Antigelo",
			"auxiliary_heat": "Caldo aus.",
			"available_stages": "Imp. disponibili",
			"first_supply_heat": "Impianto princ.",
			"aux_heat": "Caldo aus.",
			"heat_pump": "Pompa calore",
			"fan_configuration": "Conf. ventilat.",
			"electric": "Elettrico",
			"furnace": "Bruciat.",
			"delay": "Ritardo",
			"first_stage": "1º impianto",
			"min_time_exhausted": "Tempo min. funzionamento",
			"second_stage": "2º impianto",
			"differential": "Differenziale",
			"min_time": "Tempo min.",
			"autochange": "Modo Auto",
			"setpoint_differential": "Differenz. temp.",
			"mode_switching_protection1": "Protezione cambio",
			"mode_switching_protection2": "modo (min)",
			"heat_ovr": "Diff. impos. caldo",
			"away_mode_settings": "Conf. modo assente",
			"override_time1": "Inabilitare",
			"override_time2": "per (min)",
			"room_temp": "T. ambiente",
			"test_rocio": "Protezione rischio condensa",
			"rocio_hist": "Livello di protezione",
			"rocio_hist_very_high": "Molto alto",
			"rocio_hist_high": "Alto",
			"rocio_hist_med": "Medio",
			"rocio_hist_low": "Basso",
			"rocio_hist_very_low": "Molto basso",
			"test_rocio_off": "La protezione contro la condensa sarà disattivata durante 1h, dopo questo tempo il livello di protezione sarà ",
			"temperature_range": "Rango di temperatura",
			"stage_configuration": "Configurazione caldo",
			"temp_gap": "Differenza temp",
			"firmMC": "Misuratore elett.",
			"plenum_mode_title": "Modo standby",
			"hitachi_prot_title": "Modo protezione - Ritardo nella chiusura degli elementi motorizzati",
			"battery_temperature": "Temperatura batteria",
			"min_battery_temperature": "Temp. batt. calda",
			"max_battery_temperature": "Temp. batt. fredda",
			"auto_mode_battery_temperature": "Cambio del modo automatico in funzione della temperatura della batteria",
			"deshumidifier_function": "Funzione deumidificatore",
			"humidity_limit": "Umidità limite",
			"disabled": "Disabilitata",
			"air_supply_settings": "Impostazione apporto di aria",
			"air_supply_type": "Tipo di apporto di aria",
			"fan_delay": "Ritardo ventilatore",
			"standby_mode_label1": "Stato della unità",
			"standby_mode_label2": " in comfort",
			"standby_mode": {
				"0": "Spenta",
				"1": "Standby"
			},
			"wsccp_activation_delay": "Ritardo di attivazione",
			"wsccp_cold_heat_tai": "Temperatura acqua in uscita",
			"wsccp_desactivation_acs": "Funzione ACS",
			"purifier_control": "Mantenimento del purificatore",
			"units": "Unitá",
			"hours": "Ore",
			"days": "Giorni",
			"timer": "Tempo",
			"counter": "Contatore",
			"reset": "Reset",
			"fixed_time": "Tempo prestabilito",
			"production": "Produzione",
			"help": {
				"direction_system": "Indirizzo del sistema",
				"direction_system_text": "Selezioni l'indirizzo del sistema.",
				"temp_ranges": "Intervallo di temperature",
				"temp_ranges_text": "Permette di selezionare i limiti di temperatura minima (modo freddo) e massima (modo caldo) del sistema.",
				"combined_steps": "Sorgenti combinate",
				"combined_steps_text": "Permette di definire il differenziale tra temperatura impostata e temperatura ambiente per utilizzare l'impianto ad aria e quello radiante in modo combinato.",
				"protect_temp": "Temperatura di Protezione",
				"protect_temp_text": "Permette di definire le temperature in cui spegnare la caldaia o il climatizzazione per evitare di danni.",
				"radiant_algorithm": "Algoritmo radiante",
				"radiant_algorithm_text": "Imposti il differenziale di temperatura tra quella impostata e quella effettiva in ambiente per l'algoritmo di efficienza radiante. Questo algoritmo adegua il funzionamento della valvola sul collettore dell'impianto radiante per evitare picchi di temperatura dovuti all'inerzia termica.",
				"proportional_operture": "Lavoro Proporzionale",
				"proportional_operture_text": "Permette di selezionare tra apertura totale o chiusa.",
				"min_air": "Aria minima",
				"min_air_text": "Questo permette di non chiudere completamente la motorizzazione al raggiungimento della temperatura. Questo allo scopo di garantire un minimo di portata aria nella zona. Ideale se si desidera utilizzare i condotti anche per il rinnovo dell'aria o per evitare eccessivi tassi din umidità a temperatura raggiunta. Se la zona viene messa in OFF la motorizzazione si chiude.",
				"master_installation": "Maestro dell'installazione",
				"master_installation_text": "Permette di replicare il modo di funzionamento impostatto del sistema a tutti i sistemi della installazione.",
				"q_adapt": "Q-Adapt",
				"q_adapt_text": "Questa funzione permette di impostare il comportamento delle velocità di ventilazione della macchina in rapporto al numero di zone in regime di domanda.",
				"q_adapt_power": "Q-Potenza - Favorisce l'utilizzo delle velocità più alte escludendo la più bassa.",
				"q_adapt_standard": "Q-Standard - Impostazioni predefinite.",
				"q_adapt_max": "Q-Massimo - Il sistema lavora alla velocità più alta disponibile, indipendentemente del numero di zone aperte.",
				"q_adapt_min": "Q-Silenzio - Favorisce l'utilizzo delle velocità di ventilazione più basse escludendo la più alta.",
				"q_adapt_silent": "Q-Minima - Il sistema funziona alla velocità minima.",
				"ventilation_mode_weight": "Modo Ventilazione - Peso aeraulico del sistema",
				"ventilation_mode_weight_text": "Permette di impostare il peso aeraulico del sistema sulla Centrale di Controllo di Ventilazione.",
				"ventilation_mode_work": "Modo Ventilazione - Modo di funzionamento",
				"ventilation_mode_work_text": "Questo parametro permette di selezionare il tipo di ventilazione desiderato nel sistema.",
				"ventilation_mode_clim": "Modo Ventilazione - Climatizzare e Ventilare",
				"ventilation_mode_clim_text": "Permette di impostare i livelli di funzionamento del sistema per far funzionare Climatizzare e Ventilare simultaneamente.",
				"ventilation_mode_global": "Modo Ventilazione - Ingresso Ventilazione Globale",
				"ventilation_mode_global_text": "Attiva la ventilazione locale di tutte le zone. Selezioni la logica dell'ingresso tra 'Normalmente aperto' o 'Normalmente chiuso'.",
				"free_cooling_busy": "FreeCooling - Occupato",
				"free_cooling_busy_text": "Permette di attivare il funzionamento freecooling in modo occupato. Delta permette di stabilire il differenziale di temperatura per l'attivazione del Freecooling.",
				"free_cooling_unoccupied": "FreeCooling - Non occupato",
				"free_cooling_unoccupied_text": "Permette di attivare il funzionamento freecooling in modo inoccupato.",
				"ext_temp": "Temperatura esterna - Parametro che permette di stabilire la temperatura esterna desiderata. Quando T.amb esterna < T.est impostata, il Freecooling sarà messo in funzione.",
				"consign": "Temperatura impostata: Parametro che permette di stabilire la temperatura interna desiderata. Quando T.amb interna < T.int impostata il Freecooling viene attivato.",
				"input": "Ingresso",
				"input_text": "Permette di impostare l'ingresso:",
				"input_probe": "Sonda - Permette di misurare mediante una sonda esterna la temperatura di ritorno di un impianto di climatizzazione.",
				"input_door": "Porta - Permette di realizzare un On-Off remoto del sistema. Nel riavviare, il sistema ritorna allo stato previo al punto di sosta.",
				"exit": "Uscita",
				"exit_text": "Permette di impostare l'uscita",
				"global_ventilation": "Consente di attivare/disattivare il modo ventilazione in tutte le zone quando il sistema non presenta domanda in nessuna zona. Appare disattivato per difetto. necessario configurare i parametri seguenti durante l’attivazione: Ogni (min) et Per (min).",
				"global_ventilation_every": "Configurare la durata dell’intervallo (in minuti) tra i periodi di attivazione della ventilazione globale. È possibile configurare da 5 a 40 minuti, a intervalli di 5 minuti (15 minuti per difetto).",
				"global_ventilation_run_for": "Configurare il tempo (in minuti) di funzionamento della ventilazione globale. È possibile configurare da 5 a 20 minuti, a intervalli di 5 minuti (10 minuti per difetto).",
				"auxiliary_heat": "Consente di abilitare/disabilitare il caldo ausiliare. Appare disattivato per difetto. Quando il cado ausiliare è abilitato è necessario configurare i seguenti parametri.",
				"availables_stages": "Definire di quanti impianti di caldo ausiliare dispone il sistema (1 o 2).",
				"main_stage": "Selezionare il primo sistema che fornisce calore: Pompa calore o Caldo aus.",
				"ventilation_conf": "Selezionare tra Elettrico (ventilazione accesa) o Bruciat. (ventilazione spenta). Se viene selezionato Elettrico, configurare Ritardo ventilat: Imposta il tempo di ritardo (in secondi) per spegnere il ventilatore quando non vi è alcuna domanda del riscaldatore ausiliare. Valori: 0, 45, 60 e 120 secondi.",
				"stage_differential": "Definisce il differenziale della temperatura che il sistema deve superare per attivare il primo impianto di caldo ausiliare. Valori: da 1 a 5 ˚C / 2 a 10 °F, a intervalli di 1 ˚C / 2 °F (1 ˚C / 2 °F per difetto).",
				"stage_hysteresis": "Definisce la isteresi di funzionamento del primo impianto. Valori: da 0,5 a 1 ˚C / 1 a 2 °F, a intervalli di 0,5 ˚C / 1 °F (0,5 ˚C / 1 °F per difetto).",
				"minimum_time_operating": "Definisce il tempo minimo (in minuti) in cui la ìmpianto princ. deve essere attiva prima che possa essere attivato il primo impianto di caldo ausiliare. Valori: 0, 45, 60 e 120 minuti (45 minuti per difetto).",
				"away_modes_conf": "Consente di configurare i seguenti parametri del modo utente Libero o Vacanze.",
				"hysteresis": "Se la temperatura impostata viene superata dal differenziale impostato, la zona non creerà più domanda. Livello: da 1 a 3,5 ˚C / 2 a 7 °F a intervalli di 0,5 ˚C / 1 °F (3 ˚C / 6 °F per difetto).",
				"during_disable": "Selezionare il tempo (in minuti) che si desidera far trascorrere prima che venga riattivato il modo assente dal momento in cui l’utente tocca lo schermo. Valori: da 10 a 120 minuti, a intervalli di 10 minuti (60 minuti per difetto).",
				"auto_mode": "Configurare i seguenti parametri del modo Auto.",
				"temperature_differential": "Definisce il differenziale minimo tra le temperature impostate dei modi freddo e caldo. Valori: da 0 a 3,5 ˚C / 0 a 7 °F, a intervalli di 0,5 ˚C / 1 °F (1 ˚C / 2 °F per difetto).",
				"change_mode_protection": "Definisce il tempo (in minuti) di funzionamento minimo di uno dei modi prima di consentire un cambio di modo. Valori: 15, 30, 60 e 90 minuti (30 minuti per difetto).",
				"heat_differential": "Se una zona presenta una domanda di caldo più alta della temperatura definita, il sistema torna a funzionare in modo caldo anche se la domanda globale in modo freddo supera la domanda globale in modo caldo. Valori: Off e da 1,5 a 4 ˚C / 3 a 8 °F a intervalli di 0,5 ˚C / 1 °F (Off per difetto).",
				"antifreeze": "Evita che la temperatura ambiente della zona scenda al di sotto dei 12 °C / 53 °F, anche se la zona è spenta. (In modo Stop non si attiva).",
				"environment_temperature": "Consente di mostrare/nascondere la temperatura ambiente e l’umidità relativa della zona. Sono mostrate per difetto.",
				"heat_configuration": "Offre la possibilità di gestire i seguenti parametri Aira+Radiante.",
				"cold_mode": "Permette di selezionare la temperatura minima per il modo freddo (18-26˚C / 64-78˚F, per difetto 18˚C / 64˚F).",
				"heat_mode": "Permette di selezionare la temperatura massima per il modo caldo (19-30˚C / 66-86˚F, per difetto 30˚C / 86˚F).",
				"proportional_operture_zbs": "Configurazione del tipo di apertura dell'uscita della serranda: Tutto/Niente o Proporzionale (per difetto).",
				"minimum_air_zbs": "Consente di abilitare/disabilitare l'entrata di una portata minima di aria in quelle zone in cui è stata raggiunta la temperatura impostata nelle diverse serrande del sistema.",
				"information": "Consente di visualizzare le informazioni di sistema e dispositivi.",
				"standby_mode_text": "Consente l’impostazione dello stato della unità interna con tutte le zone in comfort nei modi freddo e caldo.",
				"standby_on": "Le motorizzazioni di tutte le zone accese (On) rimangono aperte quando non c'è richiesta.",
				"standby_off": "Le motorizzazioni di tutte le zone accese (On) rimangono chiuse quando non c'è richiesta."
			}
		},
		"systems": {
			"modes": {
				"stop": "Stop",
				"header": "Modi di funzionamento",
				"heat": "Caldo",
				"heat-air": "Caldo aria",
				"heat-radiant": "Caldo radiante",
				"heat-both": "Caldo combinato",
				"cool": "Freddo",
				"cool-air": "Freddo aria",
				"cool-radiant": "Freddo radiante",
				"cool-both": "Freddo combinato",
				"dehumidify": "Deumidificazione",
				"ventilate": "Ventilazione",
				"auto": "Auto",
				"emergency-heat": "Caldo emergenza",
				"heat-supply": "Calore di supporto",
				"dehumidifier": "Deumidificatore"
			},
			"eco": {
				"eco-off": "Off",
				"header": "Eco-Adapt",
				"eco-m": "Manuale",
				"eco-a": "Eco A",
				"eco-aa": "Eco A+",
				"eco-aaa": "Eco A++"
			},
			"velocity": {
				"header": "Velocità di ventilazione",
				"velocity-0": "Auto",
				"velocity-1": "Velocità bassa",
				"velocity-2": "Velocità media",
				"velocity-3": "Velocità alta",
				"velocity-mlp": "Velocità"
			},
			"air_flow": {
				"header": "Controllo portata",
				"air_flow-0": "Silenzio",
				"air_flow-1": "Standard",
				"air_flow-2": "Potenza"
			},
			"scene": {
				"header": "Modi utente",
				"confort": "Comfort",
				"eco": "Eco",
				"vacation": "Vacanze",
				"unocupied": "Libero",
				"night": "Notte",
				"stop": "Stop"
			},
			"timer": {
				"header": "Timer",
				"timer-off": "0 minuti",
				"timer30": "30 minuti",
				"timer60": "60 minuti",
				"timer90": "90 minuti"
			},
			"consumption": {
				"header": "Misuratore elettrici"
			},
			"schedules": {
				"weekly": "Programmazioni settimanali",
				"calendar": "Programmazioni di calendario"
			},
			"without_consumption_data": "Nessun dato di consumo disponibile al momento, ricontrollare più tardi. I dati vengono aggiornati ogni ora.",
			"error0": "Errore configurazione CCP",
			"error1": "Errore di comunicazione CCP - Interfaccia idronica",
			"error2": "Errore di comunicazione Interfaccia idronica - Unita",
			"error3": "Errore di comunicazione del misuratore elettrici",
			"error4": "Errore di misurazione del misuratore elettrici",
			"error5": "Rilevato errore H2; ritardo di serrande circolari attivato. Per disabilitarlo, accedere al menu di configurazione avanzata del sistema (modalità di protezione)",
			"error6": "Avviso di configurazione: Protezioni e algoritmo di controllo disabilitato. Consultare la documentazione della interfaccia di comunicazione per abilitare queste funzioni.",
			"error7": "Errore unità:",
			"error8": "ACS attiva",
			"error9": "Errore di comunicazione Interfaccia Altherma - scheda centrale",
			"error10": "Errore di comunicazione Interfaccia Altherma - unità interna"
		},
		"terms": {
			"accept": "Accetto le ",
			"legal_terms": "Condizioni di utilizzo",
			"and": " e l'",
			"privacy": "Informativa sulla privacy",
			"publicity": "Desidero ricevere comunicazioni commerciali"
		},
		"device_relations_list": {
			"title": "Server",
			"no_servers_title": "Benvenuto in Airzone Cloud!",
			"no_servers_info": "Per poter controllare la sua installazione è necessario aggiungere un server al suo account.",
			"remove_confirmation": "Desidera eliminare questo server dal suo account?",
			"removed_server": "Server eliminato",
			"internal_error": "Errore interno",
			"internal_error_message": "Quanto richiesto non può essere eseguito. Per favore, riprovare più tardi",
			"actions": {
				"edit": "Modifica",
				"config": "Impostazioni",
				"users": "Utenti",
				"remove": "Cancella",
				"close": "Chiudi",
				"accept": "Accetta",
				"cancel": "Annulla",
				"confirmation_deleted": "Server eliminato correttamente",
				"add_server": "Aggiungere un server"
			}
		},
		"add_server": {
			"name_placeholder": "Nome (casa, ufficio, ecc.)",
			"mac_placeholder": "MAC (12:AB:34:CD:45:F0)",
			"pin_placeholder": "PIN",
			"location_placeholder": "Località del Server"
		},
		"add_user_to_device": {
			"title": "Aggiungere utente",
			"advanced": "Avanzato",
			"basic": "Base"
		},
		"users": {
			"title": "Utenti",
			"basic_user": "Utenti base",
			"advanced_user": "Utenti avanzati",
			"basic": "Base",
			"advanced": "Avanzato",
			"add_user": "Aggiungere utente",
			"destroy": "Eliminare utente",
			"remove_confirmation": "Desidera eliminare l'accesso dell'utente al server?",
			"zones": "Accesso alle Zone",
			"blank": "Non ci sono utenti."
		},
		"admin_users": {
			"confirmed": "Confermato",
			"results": "Risultati",
			"no_results": "Non ci sono risultati",
			"page": "Pagina",
			"of": "di",
			"device_modal": {
				"title": "Server accessibile per l'utente",
				"no_devices": "L'utente non ha accesso a nessun server",
				"location": "Ubicazione"
			}
		},
		"zones": {
			"warnings": {
				"presence": "Allarme presenza",
				"window": "Allarme finestra",
				"antifreeze": "Antigelo",
				"dew": "Umidità attiva",
				"dew_protection": "Protezione Umidità attiva",
				"thermostat": "Zona senza termostato",
				"heat_supply_activated": "Supporto termico attivato",
				"low_valve_battery": "Batteria bassa valvola",
				"valve_NTC_alarm": "Allarme NTC2",
				"F05C": "Errore F05-C: Sonda di temperatura della batteria di raffreddamento in circuito circuito aperto",
				"F05H": "Errore F05-H: Sonda di temperatura della batteria di riscaldamento in circuito aperto",
				"F06C": "Errore F06-C: Sonda di temperatura della batteria di raffreddamento in cortocircuito",
				"F06H": "Errore F06-H: Sonda di temperatura della batteria di riscaldamento in cortocircuito",
				"dehumidifier_activated": "Deumidificatore attivato"
			},
			"adjusts": {
				"title": "Impostazioni di zona",
				"title_all": "Tutte le zone",
				"name": "Nome",
				"name_system": "Nome del sistema",
				"autoturnoff": "Sleep",
				"timer": "Timer",
				"defrosting": "Antigelo",
				"local_ventilation": "Ventilazione locale",
				"lite": "Impostazioni Lite",
				"led": "Stato LED",
				"work_mode": "Modo di funzionamento",
				"cool": "Freddo",
				"heat": "Caldo",
				"air_direction": "Direzione dell'aria",
				"lama_direction": "Direzione delle alette",
				"horizontal": "Orizzontale",
				"vertical": "Verticale",
				"name_explanation": "Consente di modificare il nome della zona",
				"autoturnoff_explanation": "La zona si spegnerà automaticamente una volta trascorso il tempo selezionato. Ogni 30 minuti, la temperatura si alzerà di un grado in modo freddo o scenderà di un grado in modo caldo.",
				"sleep_off": "Off - Disattiva lo spegnimento automatico",
				"sleep_30": "30 - Attiva lo spegnimento automatico. Trascorsi 30 minuti la zona si spegne.",
				"sleep_60": "60 - Attiva lo spegnimento automatico. Trascorsi 60 minuti la zona si spegne.",
				"sleep_90": "90 - Attiva lo spegiìnimento automatico. Trascorsi 90 minuti la zona si spegne.",
				"defrosting_explanation": "Se il sistema rileva in ambiente una temperatura inferiore a 12ºC, la macchina si accende automaticamente in modo caldo.",
				"work_mode_explanation": "Permette selezionare l'impianto con cui raffreddare o riscaldare una zona, nei casi dove l'installazione lo consente utilizzando l'impianto ad aria o il pavimento radiante:",
				"mode_air": "Aria - Solo tramite aria",
				"mode_radiant": "Radiante - Solo tramite pavimento radiante",
				"mode_mix": " - Entrambi gli impianti",
				"air_direction_explanation": "Consente di selezionare la direzione dell'aria sia in freddo che in caldo.",
				"local_ventilation_explanation": "Questa opzione permette di attivare o disattivare la ventilazione nella zona selezionata quando il sistema non sta raffreddando o riscaldando una zona in modo attivo.",
				"lite_explanation": "Configurare la modalità di illuminazione del led sulla sonda stessa."
			},
			"schedules": {
				"title": "Programmazioni",
				"init": "Inizio",
				"end": "Fine",
				"days": "Giorni",
				"monday": "Lu",
				"tuesday": "Ma",
				"wednesday": "Me",
				"thursday": "Gi",
				"friday": "Ve",
				"saturday": "Sa",
				"sunday": "Do",
				"add_schedule": "Aggiungere programmazione",
				"schedules_types": "Tipi di programmazione",
				"temp": "Temp",
				"mode": "Modo",
				"vent": "Ventilazione",
				"start_hour": "Ora di inizio",
				"end_hour": "Ora di fine",
				"start_mode": "Modo Iniziale",
				"end_mode": "Modo Finale",
				"start_temp": "Temp. Inizio",
				"end_temp": "Temp. Finale",
				"start_vent": "Vel. Iniziale",
				"end_vent": "Vel. Finale",
				"close": "Chiudi",
				"remove": "Elimina",
				"save": "Salva",
				"enable": "Attiva",
				"hour": "Ora",
				"temperature": "Temperatura",
				"velocity": "Velocità",
				"vel_0": "Automatica",
				"vel_1": "Velocità bassa",
				"vel_2": "Velocità media",
				"vel_3": "Velocità alta",
				"new_prog": "Nuova Programmazione",
				"edit_prog": "Modificare Programmazione",
				"schedules_name": "Nome",
				"remove_confirmation": "Desidera eliminare la programmazione ?",
				"all_zones": "Tutte le zone",
				"systems": "sistemi",
				"waiting_title": "Programmazione in fase di creazione",
				"waiting_text": "Programmazion in fase di salvataggio. Questo proccesso può richiedere alcuni minuti.",
				"save_confirmation": "Vuoi salvare la programmazione realizzata?",
				"edit_confirmation": "Vuoi salvare le modifiche?",
				"invalid_dates_range": "Impossibile salvare la programmazione per l'intervallo di date e la zona selezionati"
			}
		}
	},
	"pt": {
		"modal_vertical": {
			"title": "Posições das lâminas verticais",
			"position_0": "Inclinação 0: lâminas a 90°",
			"position_1": "Inclinação 1: lâminas a 72°",
			"position_2": "Inclinação 2: lâminas a 54°",
			"position_3": "Inclinação 3: lâminas a 36°",
			"position_4": "Inclinação 4: lâminas a 0°",
			"position_swing": "Swing: alterna todas as posições das lâminas"
		},
		"modal_horizontal": {
			"title": "Posições das lâminas horizontais",
			"position_0": "Inclinação 0: lâminas a 180°",
			"position_1": "Inclinação 1: lâminas a 135°",
			"position_2": "Inclinação 2: lâminas a 90°",
			"position_3": "Inclinação 3: lâminas a 45°",
			"position_4": "Inclinação 4: lâminas a 0°",
			"position_swing": "Swing: alterna todas as posições das lâminas"
		},
		"adjust": {
			"title": "Ajustes de usuário",
			"title_admin": "Ajustes de usuário administrador",
			"personal": "Dados pessoais",
			"language": "Idioma",
			"spanish": "Español",
			"english": "English",
			"french": "Français",
			"italian": "Italiano",
			"portuguese": "Português",
			"german": "Deutsch",
			"password": {
				"password_change": "Trocar palavra-passe",
				"new_password_placeholder": "Nova palavra-passe",
				"new_password_repeat_placeholder": "Repita nova palavra-passe",
				"old_password_placeholder": "Palavra-passe atual"
			},
			"require": {
				"name": "Informe seu nome",
				"surname": "Informe seu sobrenome"
			},
			"advanced_adjust": "Ajustes avançados",
			"advanced_adjust_text": "Mostrar ajustes avançados",
			"time_sync": "Sincronizar data e hora",
			"save": {
				"title": "Ajustes salvos",
				"body": "Seus ajustes foram salvos corretamente."
			},
			"unsubscribe_confirm": "Deseja eliminar da aplicação Airzone? Todos os seus dados serão apagados e não poderão ser recuperados",
			"unsubscribe": {
				"title": "Eliminação confirmada",
				"body": "Todos os seus dados pessoais foram apagados do Airzone."
			},
			"change_pass": "Trocar palavra-passe",
			"warranty": "Extensão de garantia"
		},
		"languages": {
			"es": "Espanhol",
			"en": "Inglês"
		},
		"advanced_adjust": {
			"title": "Ajustes avançados",
			"subtitle": "Ajustes de sistemas e zonas",
			"all_zones": "Todas"
		},
		"adjust_zone": {
			"zone": "Zona",
			"dir_zone": "Endereço da zona",
			"associatted_exist": "Saídas associadas",
			"non": "Não",
			"yes": "Sim",
			"master": "Mestre",
			"basic_mode": "Modo básico",
			"amb_temp": "Temperatura ambiente",
			"master_dir": "Endereço mestre",
			"builder": "Fabricante",
			"stages": "Etapas",
			"heat": "Calor",
			"heat_air": "Ar",
			"heat_radiant": "Radiante",
			"heat_combined": "Combinado",
			"heat_combo": "Ar+Radiante",
			"cold": "Frio",
			"cold_air": "Ar",
			"cold_radiant": "Radiante",
			"cold_combined": "Combinado",
			"cold_combo": "Ar+Radiante",
			"ventilation": "Ventilação",
			"step1": "Passo 1",
			"step2": "Passo 2",
			"step3": "Passo 3",
			"caudal": "Fluxo",
			"q-adapt": "Q-Adapt: Peso da zona",
			"inputs": "Entradas",
			"window": "Janela",
			"window_normally_open": "Normalmente aberto",
			"window_normally_closed": "Normalmente fechado",
			"presence": "Presença",
			"presence_normally_open": "Normalmente aberto",
			"presence_normally_closed": "Normalmente fechado",
			"spray_dew": "Sonda orvalho",
			"spray_dew_normally_open": "Normalmente aberto",
			"spray_dew_normally_closed": "Normalmente fechado",
			"local_ventilation": "Ventilação local",
			"local_vent_normally_open": "Normalmente aberto",
			"local_vent_normally_closed": "Normalmente fechado",
			"information": "Informação",
			"firm_term": "Firmware termostato",
			"firm_module": "Firmware módulo",
			"warning": "Aviso prévio",
			"errors": "Erros",
			"error": "Erro",
			"error3": "Erro 3-Grelha ausente",
			"error4": "Erro 4-Grelha bloqueada",
			"error5": "Erro 5-Sonda no presente",
			"error6": "Erro 6-Sonda com curto-circuito",
			"error7": "Erro 7-Elementos de climatização incompatíveis",
			"error8": "Erro 8-Perda de comunicação com módulo sonda remota",
			"error9": "Erro 9-Alarme de presença",
			"error10": "Erro 10-Alarme de janela",
			"error11": "Erro 11-Alarme de antigelo",
			"error12": "Zona sem termostato",
			"error15": "Erro 15-Falha de comunicação entre o medidor consumo e a central do sistema",
			"error16": "Erro 16-Erro de medição no medidor de consumo",
			"manufacturer_0": "Sem fabricante",
			"manufacturer_1": "Fujitsu Geral I",
			"manufacturer_2": "Fujitsu Geral II",
			"manufacturer_3": "Daikin I",
			"manufacturer_4": "Daikin II",
			"manufacturer_5": "LG",
			"manufacturer_6": "Tango",
			"manufacturer_7": "Mitsubishi Electric",
			"manufacturer_8": "Toshiba",
			"manufacturer_9": "Gree",
			"no_errors": "Não há erros",
			"use_adapt_set": "Temperatura modo usuário",
			"zone_weight": "Peso da zona",
			"eco_function": "Função Eco",
			"function_eco": {
				"1": "Normalmente aberto",
				"2": "Normalmente fechado"
			},
			"DC_fan_voltage": "Voltagem CC do ventilador",
			"min_volt": "V mínimo",
			"max_volt": "V máximo",
			"constant_ventilation": "Ventilação constante",
			"cold_battery_temperature": "Tª bateria frio",
			"heat_battery_temperature": "Tª bateria calor",
			"relay_title": "Relé ligar-desligar AA",
			"relay": "Relé",
			"control_stage": "Etapas controlo",
			"cold_control_stage": {
				"0": "Expansão direta",
				"1": "Água",
				"2": "Ar fornecido",
				"3": "Pavimento",
				"4": "Radiador/Teto",
				"5": "Elétrico"
			},
			"heat_control_stage": {
				"0": "Elétrico",
				"1": "Água",
				"2": "Ar fornecido",
				"3": "Pavimento",
				"4": "Radiador/Teto",
				"5": "Elétrico"
			},
			"valves": "Válvulas",
			"help": {
				"direction_zone": "Endereço da zona",
				"direction_zone_text": "Parâmetro de apenas leitura que indica o endereço da zona selecionada.",
				"slave": "Saídas associadas (escravas)",
				"slave_text": " Parâmetro de apenas leitura que indica se a zona selecionada possui módulos/saídas escravas e o endereço delas.",
				"master": "Mestre",
				"master_text": "Permite ativar o mestre na zona selecionada. Permitindo escolher entre SIM e NÃO.",
				"basic_mode": "Modo básico",
				"basic_mode_text": "Permite desabilitar as funções avançadas do termostato e deixa um controlo básico para facilitar seu uso. Selecione ON para ativar o modo básico. Selecione OFF caso contrário.",
				"ambient_temp": "Temperatura ambiente",
				"ambient_temp_text": "Parâmetro de apenas leitura que mostra a temperatura ambiente da zona com que o sistema trabalha.",
				"manufacturer": "Fabricante",
				"manufacturer_text": "Parâmetro que indica o fabricante das tramas",
				"heat_type_config": "Etapa de calor",
				"heat_type_config_text": "Permite configurar as etapas de calor da zona. As opções para configuração são Ar, Radiante, Combinado e OFF, que elimina o modo calor na zona selecionada.",
				"cold_type_config": "Etapa de frio",
				"cold_type_config_text": "Permite configurar as etapas de frio da zona. As opções para configuração são Ar, Radiante, Combinado e OFF, que elimina o modo frio na zona selecionada.",
				"ventilation": "Ventilação",
				"ventilation_text": "Habilita/desabilita a função de ventilação da zona.",
				"q_weight": "Q-Adapt: Peso da zona",
				"q_weight_text": "Em sistemas ACUAZONE / IBPro32 / Innobus Air configurados com Q-Adapt manual, permite modificar o peso total da zona com valores compreendidos entre 1-99.",
				"window": "Janela",
				"window_text": "Permite controlar o estado de uma zona em função da abertura de uma janela. Caso tal entrada seja ativada, a climatização da zona será desligada até que o estado de repouso seja reestabelecido. Para ativar esta função, configure a lógica da entrada entre os seguintes valores: Normalmente aberto ou Normalmente fechado. O valor OFF desabilita esta função.",
				"presence": "Presença",
				"presence_text": "Permite controlar o estado de uma zona em função de um detetor de presença. Caso tal entrada seja ativada, a climatização da zona ativará o modo Sleep com valor 90. Para ativar esta função, configure a lógica da entrada entre os seguintes valores: Normalmente aberto ou Normalmente fechado. O valor OFF desabilita esta função.",
				"spray_dew": "Sonda orvalho",
				"spray_dew_text": "Permite controlar o estado de uma zona em função de um detetor de presença. Caso tal entrada seja ativada, a climatização da zona ativará o modo Sleep com valor 90. Para ativar esta função, configure a lógica da entrada entre os seguintes valores: Normalmente aberto ou Normalmente fechado. O valor OFF desabilita esta função.",
				"local_vent": "Ventilação local",
				"local_vent_text": "Ativa/desativa a ventilação local da zona. Para ativar esta função, configure a lógica da entrada entre os seguintes valores: Normalmente aberto ou Normalmente fechado. O valor OFF desabilita esta função.",
				"errors": "Erros",
				"errors_text": "Parâmetro de apenas leitura que indica se há erros ativos na zona selecionada. Caso indique SI, pressione sobre o parâmetro para exibir a barra e visualizar os erros ativos.",
				"firmt": "Firmware termostato",
				"firmt_text": "Parâmetro de apenas leitura que indica o software do termostato da zona associada.",
				"firmm": "Firmware módulo",
				"firmm_text": "Parâmetro de apenas leitura que indica o software do módulo local da zona associada.",
				"associatted_exist_text": "Mostra as saídas de controlo associadas ao termostato.",
				"use_adapt_set_text": "Configure as temperaturas de referência para cada modo de usuário",
				"confort_text": "Confort. Modo calor: 15 a 30˚C / 59 a 86˚ F (por padrão, 20˚C / 68 ˚ F). Modo frio: 18 a 30˚C / 64 a 86 ˚F (por padrão, 24˚C / 75˚F).",
				"eco_text": "Modo calor: 15 a 30˚ C / 59 a 86 ˚ F (por padrão, 19˚C / 66˚ F ). Modo frio: 18 a 30˚C / 64 a 86 ˚F (por padrão, 29˚C / 77 ˚ F).",
				"unocupied_text": "Modo calor: 15 a 22˚ C / 59 a 72˚F (por padrão, 17,5˚C / 63˚F). Modo frio: 24 a 30˚C / 75 a 86˚F (por padrão, 27˚C / 81 ˚F).",
				"vacation_text": "Modo calor: 10 a 16˚C / 50 a 61˚F (por padrão, 10˚C / 50˚ f). Modo frio: 29 a 35,5˚C / 84 a 96 ˚F (por padrão, 35˚C / 95˚F ).",
				"offset_text": "Permite corrigir a temperatura ambiente nas diferentes zonas, ou em todas elas, devido a desvios produzidos por fontes de calor/frio próximas, com fator de correção compreendido entre -2,5°C e 2,5°C / -5 a 5 °F em intervalos de 0,5°C / 1 °F. Por padrão, está configurado como 0°C / 0 °F.",
				"stages_text": "Permite configurar as etapas de frio e calor na zona selecionada. As opções a serem configuradas são:",
				"air_text": "Habilita calor por ar na zona selecionada.",
				"radiant_text": "Habilita calor radiante na zona selecionada.",
				"air_radiant_text": "Habilita o calor por ar e radiante na zona selecionada e permite que o usuário selecione a etapa de calor que deseja em tal zona.",
				"inputs_text": "Permite gerenciar o estado de um módulo de zona em função da deteção de presença ou de abertura de uma janela.",
				"window_zbs_text": "Somente atua sobre o elemento de controlo do módulo de zona. Numa zona em que esta função estiver ativada, caso seja detetada a abertura de uma janela, a zona será desligada após 60 segundos e um aviso \"Janela ativa\" será exibido no termostato. Por padrão, esta função está desativada. Caso queira ativá-la, defina a lógica do contacto como Normalmente aberto ou fechado.",
				"presence_zbs_text": "Somente atua sobre o elemento de controlo do módulo de zona se estiver associado a um termostato; não funciona em módulos de zona secundários. Numa zona em que esta função esteja ativada, caso nenhuma presença seja detetada até 5 minutos, Sleep 90 é ativado e a mensagem \"Presença ativa\" é exibida no termostato. Por padrão, esta função está desativada. Caso queira ativá-la, defina a lógica do contacto como Normalmente aberto ou fechado.",
				"information_text": "Permite visualizar informação sobre o sistema e os dispositivos."
			}
		},
		"buttons": {
			"sign_in": "Entrar",
			"cancel": "Cancelar",
			"init": "Começar",
			"send": "Enviar",
			"save": "Salvar",
			"accept": "Aceitar",
			"resend_confirmation": "Reenviar confirmação",
			"back": "Voltar",
			"icon": "Ícone",
			"unsubscribe": "Cancelar subscrição",
			"remove": "Eliminar",
			"restore_factory": "Restaurar de fábrica",
			"next": "Seguinte",
			"prev": "Anterior",
			"close": "Fechar",
			"recharge": "Recarregar",
			"hide": "Ocultar",
			"update_device": "Detetar sistemas",
			"continue": "Continuar",
			"exit": "Sair",
			"help": "Ajuda",
			"select": "Selecionar",
			"invite": "Convite",
			"update": "Atualizar"
		},
		"email": "Email",
		"name": "Nome",
		"surname": "Sobrenomes",
		"password": "Palavra-passe",
		"new_password": "Nova palavra-passe",
		"repeat_password": "Repetir palavra-passe",
		"server": {
			"notification": {
				"message": "A tarifa elétrica para o período da manhã já está disponível.",
				"alert_message": {
					"title": "Notificações",
					"text": "Deseja receber notificações da Airzone Cloud?",
					"remember": "Não perguntar novamente"
				}
			},
			"temp": "Temperatura",
			"months": {
				"0": "JAN",
				"1": "FEV",
				"2": "MAR",
				"3": "ABR",
				"4": "MAI",
				"5": "JUN",
				"6": "JUL",
				"7": "AGO",
				"8": "SET",
				"9": "OUT",
				"10": "NOV",
				"11": "DEZ"
			},
			"days": {
				"0": "Domingo",
				"1": "Segunda-feira",
				"2": "Terça-feira",
				"3": "Quarta-feira",
				"4": "Quinta-feira",
				"5": "Sexta-feira",
				"6": "Sábado"
			},
			"current_price": "PREÇO ACTUAL",
			"minimum_price": "MINIMO",
			"maximum_price": "MAXIMO",
			"day_price": "PREÇO DO DIA"
		},
		"state": "Estado",
		"enabled": "Habilitado",
		"sleeping": "Fornecido",
		"connected": "Conectado",
		"activated": "Ativado",
		"components": "Componentes",
		"historic": "Histórico",
		"system": "Sistema",
		"filters": "Filtros",
		"mac": "Mac",
		"locality": "Localidade",
		"all": "Todos",
		"pin": "PIN",
		"zone_name": "Nome",
		"connection": "Conexão",
		"compare": "Comparar",
		"day": "Dia",
		"month": "Mês",
		"year": "Ano",
		"range": "Alcance",
		"maximum": "Máximo",
		"minimum": "Mínimo",
		"mode": "Moda",
		"average": "Média",
		"without_data": "Nenhum dado de consumo",
		"full_page": "Ver em tela completa",
		"print_chart": "Imprimir Tabela",
		"download_png": "Download em PNG",
		"download_jpg": "Download em JPG",
		"download_pdf": "Download em PDF",
		"download_avg": "Download em AVG",
		"download_csv": "Download em CSV",
		"download_xls": "Download em XLS",
		"view_data_table": "Exibir tabela de dados",
		"modal_mode": {
			"stop": "Stop",
			"stop_text": "Modo parado",
			"cold_air": "Frio ar",
			"cold_air_text": "Modo frio",
			"heat_radiant": "Calor radiante",
			"heat_radiant_text": "Modo calor radiante",
			"ventilate": "Ventilação",
			"ventilate_text": "Modo ventilação",
			"heat_air": "Calor ar",
			"heat_air_text": "Modo calor",
			"heat_both": "Calor combinado",
			"heat_both_text": "Modo calor combinado",
			"dehumidify": "Eliminação da humidade",
			"dehumidify_text": "Modo de humidade",
			"cold_radiant": "Frio radiante",
			"cold_radiant_text": "Modo frio radiante",
			"cold_both": "Frio combinado",
			"cold_both_text": "Modo frio combinado",
			"auto_text": "Permite alteração automática entre frio e calor, de acordo com a demanda global.",
			"emergency_heat": "Calor emergência",
			"emergency_heat_text": "Ativa o calor auxiliar para fornecer ar quente no caso de falha mecânica do sistema."
		},
		"modal_eco": {
			"title": "Modos de economia",
			"eco": "Selecione um modo de economia. Quanto maior a categoria A, maior será a economia de energia, ainda que a potência de resfriamento seja mais pequena."
		},
		"modal_speed": {
			"title": "Modos de velocidade de ar disponíveis",
			"speed": "Selecione uma velocidade. Quanto maior o número, maior será a velocidade do ar."
		},
		"modal_air_flow": {
			"silence": "A velocidade do ventilador é mais baixo que em padrão para favorecer a redução de ruído.",
			"standard": "O sistema modifica a velocidade em função do número de zonas.",
			"power": "A velocidade do ventilador é maior que en em padrão para favorecer o aumento de fluxo."
		},
		"modal_scene": {
			"stop": "O sistema de climatização se manterá parado, independentemente do estado das demais zonas. Para além disso, todos os elementos terminais (grelhas, comportas, etc.) permanecerão fechados.",
			"confort": "Modo de usuário padrão. Selecione a temperatura de referência desejada através dos intervalos de temperatura pré-estabelecidos.",
			"unoccupied": "Utilizado quando não se deteta presença durante curtos períodos de tempo. Será estabelecida uma temperatura de referência mais eficiente. Se o termostato estiver ativado, a zona começará a trabalhar em modo Confort.",
			"night": "O sistema altera automaticamente a temperatura de referência em 0,5°C / 1°F a cada 30 minutos, até um máximo de 2°C / 4 °F. Durante o arrefecimento, o sistema incrementa a temperatura de referência e, no aquecimento, a reduz.",
			"eco": "O sistema e adapta a temperatura a valores cada vez mais eficientes.",
			"vacation": "Esta função poupa energia quando o usuário não estiver presente por longos períodos de tempo."
		},
		"home": {
			"menu": {
				"servers": "Servidores",
				"adjusts": "Usuário",
				"help": "Ajuda",
				"admin": "Servidores",
				"exit": "Encerrar sessão",
				"admin_users": "Usuários",
				"admins": "Administradores",
				"connections": "Conexões"
			},
			"upload": {
				"title": "Existe uma versão nova de Airzone Cloud.",
				"description": "Atualize a aplicação para utilizá-la."
			},
			"maintenance": {
				"title": "Este site está temporariamente em manutenção",
				"description": "Esta página está em manutenção programada.",
				"text": "E ficará disponível novamente em breve."
			}
		},
		"installations": {
			"return": "Voltar",
			"exit": "Sair",
			"finish": "Finalizar",
			"next": "Seguinte",
			"edit": "Editar",
			"error": "Ocorreu um erro ao conectar ao Webserver Airzone Cloud. Verifique se o seu dispositivo está conectado à rede WiFi do Webserver Airzone Cloud (AZWSCLOUDxxx)",
			"error_mac_not_found": "Ocorreu um erro ao obter o mac do webserver",
			"error_sleeping": "Ocorreu um erro, o webserver nÃ£o estÃ¡ registado",
			"error_not_connected": "Ocorreu um erro, o webserver nÃ£o consegue ligar-se Ã  nuvem",
			"retry": "Retentar",
			"state1": {
				"text": "Certifique-se de que o seu dispositivo está devidamente alimentado (LED D11 fixo) e que está dentro do alcance de cobertura"
			},
			"state2": {
				"text": "Verifique se o dispositivo está alimentado e em modo ponto de acesso LED WIFI fixo."
			},
			"state3": {
				"assisted": "Instalação assistida",
				"wps": "Instalação WPS"
			},
			"state4": {
				"text": "Altere a rede WiFi nos ajustes do dispositivo por uma que comece com AZWSCLOUDxxxx e volte a este menu.",
				"text1": "Se a perda de aviso de conexão com a Internet, manter a conexão com o dispositivo para continuar o processo de Ascian.",
				"text2": "Selecione a rede WiFi que começa com: AZWSCLOUDxxxx",
				"text3": "Lembre-se de desativar a rede de dados móveis se utiliza Android 5 ou superior",
				"loading_text": "A alterar a rede selecionada. Aguarde...",
				"low_signal": {
					"title": "Cobertura insuficiente",
					"text": "Aproxime o Webserver do router ou instale um repetidor WiFi para melhorar a cobertura"
				},
				"auto": "Automático",
				"select_router": "Selecione o método de associação ao router",
				"auto_info": "Automático. Busca as redes WiFi próximas ao Webserver de forma automática.",
				"manual_info": "Manual. Introduza os dados da rede WiFi desejada manualmente."
			},
			"state5": {
				"text": "Selecione o router que deseja associar:",
				"name": "Nome",
				"sec": "Segurança",
				"signal": "Sinal",
				"loading_text": "Conectando com o dispositivo. Aguarde...",
				"loading_text2": "(Se a operação demorar mais de um minuto, verificar se a conexão do dispositivo está correta)",
				"no_routers": "Não foi encontrada nenhuma rede WiFi próxima ao Webserver Airzone Cloud. Verifique se a rede está ativa e visível para conexão."
			},
			"state6": {
				"text": "Introduza os dados da rede WiFi",
				"none": "Sem segurança",
				"wep": "WEP",
				"wpa": "WPA/WPA2"
			},
			"state7": {
				"text": "Dados da sua configuração de rede",
				"ip": "IP",
				"port": "Gateway",
				"masc": "Máscara de rede",
				"manual": "MANUAL",
				"dhcp": "DHCP",
				"loading_text": "A configurar o webserver Airzone. Aguarde...",
				"advanced": "Ajustes avançados"
			},
			"state8": {
				"text": "Processo realizado corretamente"
			},
			"state9": {
				"text": "O LED WIFI continuará a piscar após ter sido associado ao webserver",
				"select": "Selecione seu webserver",
				"searching": "Procurando dispositivos Bluetooth",
				"not_found": "Dispositivos Bluetooth não encontrados",
				"not_appear": "O seu dispositivo não aparece?",
				"click_here": "Prima aqui"
			},
			"state15": {
				"title": "Associação manual"
			},
			"state16": {
				"title": "Como obtenho o MAC do Webserver?",
				"boldtext1": "Através da aplicação.",
				"text1": " Em Servidores, prima em Editar e prima sobre o ícone do lápis. Nesse menu, poderá consultar o MAC do dispositivo.",
				"boldtext2": "A capa do manual",
				"text2": " do usuário do Webserver possui uma etiqueta com o código de produto, o MAC e o PIN.",
				"boldtext3": "Através do termostato Blueface",
				"text3": " (AZxx6BLUEFACEC) (apenas se for o mestre do sistema 1). Aceda ao menu Configuração de usuário > Informação > Webserver e consulte o MAC do dispositivo.",
				"boldtext4": "Através do termostato Think",
				"text4": " (AZxx6THINKx) (apenas se for o mestre do sistema 1). Aceda ao menu Configuração avançada (premir prolongaamente duas vezes) > Sistema > Informação > Webserver e consulte o MAC do dispositivo.",
				"boldtext5": "O dispositivo",
				"text5": " possui uma etiqueta com o código do produto, o MAC e o PIN."
			}
		},
		"login": {
			"rememberme": "Lembre-se de mim",
			"restore_password": "Lembrar palavra-passe",
			"demo": "Demo",
			"router": "Associar router"
		},
		"register": {
			"sign_up": "Criar conta",
			"repeat_password": "Confirmar palavra-passe"
		},
		"complete_registration": {
			"title": "Complete seu registo"
		},
		"messages": {
			"migrated_user": "Usuário registrado na nova plataforma",
			"migrated_installation": "Instalação registrada na nova plataforma",
			"new_app": "Instale seu novo aplicativo móvel Airzonecloud:",
			"errors": {
				"recaptcha_response": "Erro ao verificar o captcha. Tente novamente mais tarde",
				"recaptcha": "Desculpe, o captcha não é válido",
				"name_blank": "O nome não pode estar em branco",
				"surname_blank": "O sobrenome não pode estar em branco",
				"login": "A palavra-passe ou o e-mail não é válido",
				"email": "O e-mail não é válido",
				"email_blank": "O e-mail não pode estar em branco",
				"email_taken": "O e-mail já está em uso",
				"password_length": "A palavra-passe deve ter pelo menos 8 caracteres",
				"password_blank": "A palavra-passe não pode estar em branco",
				"password_confirmation_check": "As palavras-passe devem ser iguais",
				"password_confirmation_blank": "Deve confirmar a sua palavra-passe",
				"password_new_length": "A palavra-passe nova deve ter pelo menos 8 caracteres",
				"password_new_blank": "A palavra-passe nova não pode estar em branco",
				"invalid_old_password": "A palavra-passe informada não está correta.",
				"condition": "Deve aceitar as condições de uso e a política de privacidade",
				"token_invalid": "O código de ativação não está correto. Solicite novamente Recordar Palavra-passe.",
				"email_not_exist": "Não existe nenhuma conta associada a esse e-mail",
				"confirmation": "Ocorreu um erro na confirmação. Preencha o formulário e enviaremos um novo e-mail de confirmação.",
				"unconfirmed": "E-mail não confirmado!",
				"server_blank": "Indique um nome para o servidor",
				"mac_blank": "Indique o endereço MAC do seu servidor",
				"mac_format": "O formato da MAC está incorreto",
				"mac_repeated": "Já existe um servidor com esta MAC",
				"pin_blank": "Indique o código PIN do seu servidor",
				"mac_invalid": "A MAC introduzida está incorreta. Verifique se concorda com os dados do Webserver cloud",
				"pin_invalid": "O PIN introduzido não corresponde à MAC",
				"server_sleeping": "O Webserver Cloud solicitado não tem ligação à Internet. Verifique se está associado ao router e se possui ligação à Internet.",
				"server_taken": "A MAC solicitada já foi incluída na plataforma Cloud",
				"location_blank": "Indique a localidade do seu servidor",
				"server_icon_blank": "Indique um ícone para representar seu servidor",
				"file_error": "O ficheiro que tenta importar está incorreto. Os formatos suportados são xls, xlsx e csv.",
				"must_select_file": "Deve selecionar um ficheiro",
				"not_connected": "Não há ligação à Internet no momento e as ações realizadas não terão efeito em seu sistema Airzone. Quando tiver ligação à Internet, recarregue a página e tente novamente.",
				"not_connected_title": "Aviso sobre sua ligação à Internet",
				"not_connected_device": "Seu dispositivo Airzone não está ligado à Internet no momento e, portanto, as ações realizadas não terão efeito. Ligue seu dispositivo Airzone à Internet, recarregue a página e tente novamente.",
				"not_connected_device_title": "Aviso sobre sua ligação do seu dispositivo",
				"error_device": "Ocorreu um erro no seu dispositivo. Se essa mensagem persistir, entre em contacto com o seu instalador.",
				"registration_already_completed": "O registo já foi concluído anteriormente. Inicie a sessão com seus dados.",
				"user_taken": "O usuário já está associado ao servidor",
				"without_internet_title": "Conexão de internet",
				"without_internet_text": "Esta aplicação precisa de ligação à Internet para o trabalho. Conecte seu dispositivo e tente novamente",
				"day_blank": "Você deve selecionar um dia",
				"mac_length_seek": "Mínimo de 4 dígitos necessários",
				"email_length_seek": "Mínimo de 3 caracteres necessários",
				"not_systems_title": "Aviso dos sistemas",
				"not_systems": "Nenhum sistema foi detectado. Por favor, verifique a instalaÃÃ£o.",
				"location_title": "Localização desativada",
				"location_text": "Verifique se a localização está ativada nos ajustes do seu telefone.",
				"location_disabled_title": "Localização não habilitada",
				"location_disabled_text": "Verifique se a localização para esta aplicação está ativada nos ajustes do seu telefone.",
				"bluetooth_disabled_title": "Bluetooth desativado",
				"bluetooth_disabled_text": "Verifique se o Bluetooth está ativado nos ajustes do seu telefone.",
				"bluetooth_connect_device_title": "Dispositivo desconectado",
				"bluetooth_connect_device_text": "Ligação por Bluetooth perdida.",
				"bluetooth_scan_title": "Erro de digitalização",
				"bluetooth_scan_text": "Erro ao tentar digitalizar dispositivos Bluetooth."
			},
			"success": {
				"register": "Registo correto!",
				"send_reset_password": "Acabamos de enviar um e-mail com instruções para modificar sua palavra-passe.",
				"reset_password": "A sua palavra-passe foi modificada",
				"resend_confirmation": "Foi enviada uma nova mensagem de confirmação para seu e-mail.",
				"user_server_added": "A conexão com o servidor foi realizada corretamente. Poderá acessar a partir da janela de servidores.",
				"user_server_updated": "A informação do servidor foi atualizada corretamente.",
				"restore_factory": "O servidor foi restaurado à sua configuração inicial.",
				"added_basic_user_to_server": "O usuário foi adicionado corretamente ao servidor. Pressione Aceitar para acessar o ecrã de permissões do usuário.",
				"added_advanced_user_to_server": "O usuário foi adicionado corretamente ao servidor."
			},
			"info": {
				"has_email": "Recebeu um e-mail!",
				"go_login": "Ir a login",
				"reset_password": "Volte ao login e introduza seu e-mail e sua nova palavra-passe para entrar.",
				"change_password": "Escreva e repita sua palavra-passe nova.",
				"send_reset_password": "Isso acontece com todo mundo. Informe o seu endereço de e-mail e enviaremos instruções para modificar sua palavra-passe.",
				"register_success": "Um e-mail foi enviado ao seu endereço para verificar sua identidade.",
				"complete_registration_success": "O registo foi realizado corretamente. Pode iniciar a sessão e acessar a gestão do seu dispositivo Airzone.",
				"resend_confirmation": "Informe o seu e-mail e enviaremos uma nova mensagem de confirmação.",
				"unconfirmed": "Confirme seu e-mail antes de iniciar a sessão",
				"remove_server_admin": {
					"question": "Deseja eliminar este servidor?",
					"info": "Todos os dados associados a este servidor serão eliminados."
				},
				"restore_server_admin": {
					"question": "Deseja restaurar este servidor à configuração de fábrica?",
					"info": "Será feito um reset de todos os dados associados a este servidor."
				},
				"update_server": "O servidor está sendo atualizado. Esta operação pode demorar vários minutos.",
				"update_server_info": "Iniciando o processo de atualização deste servidor web para a versão",
				"update_server_confirmation": "Deseja atualizar este servidor?",
				"browsers": "O navegador utilizado não é suportado por nossa aplicação ou é uma versão muito antiga. Caso queira usar a aplicação, recomendamos a instalação ou a atualização de um dos seguintes navegadores.",
				"disable_permission_device": "É necessário ter permissão em pelo menos uma área",
				"auto_mode_battery_temperature": "Troca de modo automático ativada",
				"purifier_maintenance1": "Faça a manutenção de seu equipamento de purificação / higienização.",
				"purifier_maintenance2": "Faça a manutenção de seu equipamento de purificação / purificação. Aceda o menu de configuração avançada do sistema para iniciar o contador novamente (Manutenção do Purificador).",
				"purifier_maintenance_before": "Mantenha seu equipamento de purificação / higienização antes de:"
			},
			"title": {
				"reset_password": "Lembrar palavra-passe",
				"change_password": "Modificar palavra-passe",
				"resend_confirmation": "Reenviar confirmação",
				"user_server_added": "Servidor criado corretamente",
				"user_server_updated": "Servidor atualizado corretamente",
				"add_server": "Adicionar servidor",
				"edit_server": "Editar servidor",
				"error": "Erro",
				"server_data": "Dados do servidor",
				"remove_server": "Eliminar servidor",
				"restore_factory": "Restaurar servidor de fábrica",
				"user_server_added_device": "Usuário adicionado corretamente",
				"sync_datetime": "Timing correto",
				"device_permissions": "Permissões de usuário",
				"maintenance": "Manutenção",
				"browsers": "Navegador não suportado"
			}
		},
		"servers": {
			"no_results": "Nenhum servidor foi encontrado",
			"tags": {
				"servers": "servidores",
				"results": "resultados",
				"page": "Página ",
				"of": " de "
			},
			"new": {
				"add_servers": "Adicionar servidores",
				"return": "Voltar",
				"from_file": "Desde ficheiro",
				"select_file": "Selecione um ficheiro",
				"select_file2": "Selecione um ficheiro",
				"from_form": "Desde formulário",
				"success_add2": "Foram adicionados",
				"success_add_f2": "servidores corretamente",
				"success_add1": "Foi adicionado",
				"success_add_f1": "servidor corretamente",
				"failure_add1": "Não foi possível adicionar o seguinte servidor",
				"failure_add2": "Não foi possível adicionar os seguintes",
				"failure_add_f2": "servidores",
				"accept": "Aceitar",
				"success": {
					"added": "Servidor adicionado corretamente",
					"added2": "O servidor foi incluído corretamente na plataforma"
				}
			}
		},
		"system_config": {
			"0": "Sistema por zonas",
			"1": "Sistema por zonas  FC",
			"2": "Instalação de 2 tubos",
			"3": "Instalação de 4 tubos",
			"4": "RadianT",
			"6": "Airzone INT",
			"title": "Ajustes de sistema",
			"systems": "Sistema",
			"acs_system": "AQS",
			"system_fw": "Firmware",
			"ccp_options": {
				"0": "Aerothermie",
				"1": "2 tubos",
				"2": "4 tubos",
				"3": "RadianT"
			},
			"setup_type": "Tipo de instalação",
			"blueface_number": "Blueface",
			"think_r": "Think R",
			"think_c": "Think C",
			"lite": "Lite",
			"radio_number": "Tacto R",
			"tact_number": "Tacto C",
			"probe_number": "Sonda",
			"firm_ws": "Firmware Webserver",
			"cold_mixing_valve": "Válvula misturadora frio",
			"heat_rele_board_number": "Nº placas de relés em calor",
			"cold_rele_board_number": "Nº placas de relés em frio",
			"combi_rele_board_number": "Nº placas de relés em combo",
			"rele_fw_1": "Firmware Placa Relés 1",
			"rele_fw_2": "Firmware Placa Relés 2",
			"rele_fw_3": "Firmware Placa Relés 3",
			"rele_fw_4": "Firmware Placa Relés 4",
			"rele_fw_5": "Firmware Placa Relés 5",
			"rele_fw_6": "Firmware Placa Relés 6",
			"rele_fw_7": "Firmware Placa Relés 7",
			"rele_fw_8": "Firmware Placa Relés 8",
			"fancoil_gw": "Gateway fancoil",
			"vent_gw": "Gateway ventilação",
			"dehum_gw": "Gateway desumidificação",
			"heat_eme_gw": "Gateway EmeHeat",
			"heat_aux_gw": "Gateway AuxHeat",
			"knx_gw": "Gateway KNX",
			"radio_switchboard_fw": "Funkventile-Modul",
			"ccp": "CCP",
			"system_number": "Nº sistema",
			"direction_system": "Endereço do sistema",
			"etapes": "Etapas",
			"cold_mode": "Modo frio",
			"heat_mode": "Modo calor",
			"cold_mode_combined": "Frio combinado",
			"heat_mode_combined": "Calor combinado",
			"min_temp": "Temp. mínima",
			"max_temp": "Temp. máxima",
			"hysteresis": "Histerese",
			"cold_hysteresis": "Histerese frio",
			"heat_hysteresis": "Histerese calor",
			"protect_temp": "Temperatura de proteção",
			"cauldron": "Caldeira",
			"cold_aco_air": "Ar condicionado - Modo frio",
			"heat_aco_air": "Ar condicionado - Modo calor",
			"radiant_algorithm": "Algoritmo radiante",
			"proportional_operture": "Abertura proporcional",
			"min_air": "Ar mínimo",
			"master_installation": "Supermaestro",
			"q_adapt": "Q-Adapt",
			"q_adapt_c": "Q-Adapt",
			"q_adapt_d": "Q-Adapt",
			"q_adapt0": "padrão",
			"q_adapt1": "potência",
			"q_adapt2": "silêncio",
			"q_adapt3": "mínimo",
			"q_adapt4": "máximo",
			"min_volt": "Tensão mínima",
			"max_volt": "Tensão máxima",
			"q_adaptd1": "Manual",
			"q_adaptd0": "Automático",
			"ventilation_mode": "Ventilação",
			"system_weight": "Peso do sistema",
			"work_mode": "Modo de ventilação",
			"work_mode_ext_air": "Ar exterior",
			"work_mode_recirc": "Recircular",
			"work_mode_combined": "Combinado",
			"air_ventilate": "Climatizar e Ventilar",
			"overall_ventilation": "Entrada ventilação global",
			"normally_open": "Normalmente aberto",
			"normally_closed": "Normalmente fechado",
			"busy": "Freecooling ocupado",
			"unoccupied": "Freecooling desocupado",
			"delta": "Delta",
			"ext_temp": "Temperatura exterior",
			"setpoint_temperature": "Temperatura de referência",
			"input_output": "Entrada/saída",
			"input": "Entrada",
			"probe": "Sonda",
			"stop_start": "On/Off",
			"output": "Saída",
			"demand_vmc": "Demanda VMC",
			"demand_radiant": "Radiante",
			"air": "Ar",
			"minutes": "Minutos",
			"caudal": "Fluxo",
			"information": "Informação",
			"errors": "Erros",
			"error0": "Não há erros",
			"error1": "Erro gateway máquina",
			"global_ventilation": "Ventilação global",
			"global_ventilation_every": "Cada (min)",
			"global_ventilation_run_for": "Durante (min)",
			"antifreeze": "Antigelo",
			"auxiliary_heat": "Calor auxiliar",
			"available_stages": "Etapas disponíveis",
			"first_supply_heat": "Etapa principal",
			"aux_heat": "Calor aux.",
			"heat_pump": "Bomba de calor",
			"fan_configuration": "Conf. ventilador",
			"electric": "Elétrico",
			"furnace": "Queimador",
			"delay": "Atraso",
			"first_stage": "Primeira etapa",
			"min_time_exhausted": "Tempo mín. funcionamento",
			"second_stage": "Segunda etapa",
			"differential": "Diferencial",
			"min_time": "Tempo mín.",
			"autochange": "Modo Auto",
			"setpoint_differential": "Diferencial temp.",
			"mode_switching_protection1": "Proteção troca",
			"mode_switching_protection2": "modo (min)",
			"heat_ovr": "Dif. imposição calor",
			"away_mode_settings": "Conf. modos ausente",
			"override_time1": "Desativar",
			"override_time2": "durante (min)",
			"room_temp": "T ambiente",
			"test_rocio": "Proteção anti-condensação",
			"rocio_hist": "Nível de protecção",
			"rocio_hist_very_high": "Muito elevado",
			"rocio_hist_high": "Elevado",
			"rocio_hist_med": "Médio",
			"rocio_hist_low": "Baixo",
			"rocio_hist_very_low": "Muito baixola",
			"test_rocio_off": "A proteção anti-condensação será desativada por 1h, passado esse tempo o nível de protecção será ",
			"temperature_range": "Intervalo de temp.",
			"stage_configuration": "Configuração calor",
			"temp_gap": "Diferença temp",
			"firmMC": "Medidor consumo",
			"plenum_mode_title": "Modo standby",
			"hitachi_prot_title": "Modo proteção - Retardo no cerre dos elementos motorizados",
			"battery_temperature": "Temperatura bateria",
			"min_battery_temperature": "Tª bateria calor",
			"max_battery_temperature": "Tª bateria frío",
			"auto_mode_battery_temperature": "Troca de modo automático em função da temperatura da bateria",
			"deshumidifier_function": "Função desumidificador",
			"humidity_limit": "Humidade limite",
			"disabled": "Desativado",
			"air_supply_settings": "Ajustes ar fornecido",
			"air_supply_type": "Tipo de ar fornecido",
			"fan_delay": "Atraso ventilador",
			"standby_mode_label1": "Estado da unidade",
			"standby_mode_label2": " em comfort",
			"standby_mode": {
				"0": "Apagada",
				"1": "Standby"
			},
			"wsccp_activation_delay": "Atraso activação",
			"wsccp_cold_heat_tai": "Temperaturas TSA",
			"wsccp_desactivation_acs": "Funcao AQS",
			"purifier_control": "Manutenção do purificador de ar",
			"units": "Unidades",
			"hours": "Horas",
			"days": "Dias",
			"timer": "Tempo",
			"counter": "Contador",
			"reset": "Reset",
			"fixed_time": "Tempo estabelecido",
			"production": "Produção",
			"help": {
				"direction_system": "Endereço do sistema",
				"direction_system_text": "Indica o endereço do sistema.",
				"temp_ranges": "Intervalo de temperaturas",
				"temp_ranges_text": "Permite selecionar os intervalos de temperatura mínimo (modo frio) e máximo (modo calor) do sistema.",
				"combined_steps": "Etapas combinadas",
				"combined_steps_text": "Permite definir a diferença de temperatura entre referência e ambiente que desejamos fixar para que em modo combinado deixe de utilizar as etapas de Ar e Radiante e passe a utilizar a de Radiante, apenas.",
				"protect_temp": "Temperatura de proteção",
				"protect_temp_text": "Permite definir as temperaturas em que a caldeira ou o ar condicionado será desligado para que não sofram danos.",
				"radiant_algorithm": "Algoritmo radiante",
				"radiant_algorithm_text": "Configure o intervalo de funcionamento do algoritmo de eficiência radiante. Este algoritmo adapta o funcionamento do elemento radiante controlado para evitar sobreaquecimento causado por possíveis inércias térmicas, por exemplo, em pavimentos radiantes.",
				"proportional_operture": "Abertura proporcional",
				"proportional_operture_text": "Permite selecionar entre abertura total ou fechada.",
				"min_air": "Ar mínimo",
				"min_air_text": "Permite a entrada de um fluxo mínimo de ar em zonas onde chegou-se à temperatura de referência. Desta forma, o sistema permite a entrada de ar de ventilação através das condutas de climatização. Se a zona estiver desligada, a comporta se fechará totalmente.",
				"master_installation": "Maestro de Instalação",
				"master_installation_text": "Permite impor o modo de funcionamento do sistema 1 aos outros sistemas conectados.",
				"q_adapt": "Q-Adapt",
				"q_adapt_text": "É uma função dos sistemas Airzone para adaptar o fluxo do equipamento de ar às necessidades do sistema/zona.",
				"q_adapt_power": "Q-Potência - Favorece o aumento de fluxo no mapa de velocidades.",
				"q_adapt_standard": "Q-Padrão - Configuração por padrão.",
				"q_adapt_max": "Q-Máximo - O sistema trabalha com velocidade máxima, independentemente do número de zonas.",
				"q_adapt_min": "Q-Silêncio - Favorece a redução do ruído.",
				"q_adapt_silent": "Q-Mínimo - O sistema trabalha na velocidade mínima.",
				"ventilation_mode_weight": "Modo Ventilação - Peso do sistema",
				"ventilation_mode_weight_text": "Permite configurar a relevância do sistema até a Central de Controlo de Ventilação.",
				"ventilation_mode_work": "Modo Ventilação - Modo de funcionamento",
				"ventilation_mode_work_text": "Este parâmetro permite selecionar o tipo de ventilação desejada no sistema.",
				"ventilation_mode_clim": "Modo Ventilação - Climatizar e Ventilar",
				"ventilation_mode_clim_text": "Permite configurar os níveis de funcionamento do sistema para que Climatizar e Ventilar trabalhem simultaneamente.",
				"ventilation_mode_global": "Modo Ventilação - Entrada ventilação global",
				"ventilation_mode_global_text": "Ativa a ventilação local de todas as zonas. Deve selecionar a lógica da entrada entre \"Normalmente aberto\" ou \"Normalmente fechado\".",
				"free_cooling_busy": "FreeCooling - Ocupado",
				"free_cooling_busy_text": "Permite ativar o funcionamento freecooling em modo ocupado. Delta permite estabelecer o diferencial de temperatura para a ativação do freecooling.",
				"free_cooling_unoccupied": "FreeCooling - Desocupado",
				"free_cooling_unoccupied_text": "Permite ativar o funcionamento freecooling em modo desocupado.",
				"ext_temp": "Temperatura exterior - Parâmetro que permite estabelecer a temperatura exterior desejada. Quando T.amb exterior < T.ext configurada, o freecooling entrará em funcionamento.",
				"consign": "Referência: Parâmetro que permite estabelecer a temperatura interior desejada. Quando T.amb interior < T.int configurada, o freecooling será ativado.",
				"input": "Entrada",
				"input_text": "Permite configurar a entrada:",
				"input_probe": "Sonda - Permite medir a temperatura de retorno de um equipamento de ar condicionado através de uma sonda externa.",
				"input_door": "Porta - Permite realizar um arranque-paragem remoto do sistema. Ao iniciar novamente o sistema, ele volta ao estado anterior à paragem.",
				"exit": "Saída",
				"exit_text": "Permite configurar a saída",
				"global_ventilation": "Permite ativar/desativar o modo ventilação em todas as zonas quando o sistema não tiver demanda em alguma zona. Por padrão, está desativado. Quando esse modo é ativado, os seguintes parâmetros devem ser configurados: Cada (min) e Durante(min).",
				"global_ventilation_every": "Configure a duração do intervalo (em minutos) entre os períodos de ativação da ventilação global. Configurável de 5 a 40 minutos, em intervalos de 5 minutos (por padrão, 15 minutos).",
				"global_ventilation_run_for": "Configure o tempo (em minutos) de funcionamento da ventilação global. Configurável de 5 a 20 minutos, em intervalos de 5 minutos (por padrão, 10 minutos).",
				"auxiliary_heat": "Permite ativar/desativar o calor auxiliar. Por padrão, está desativado. Quando o calor auxiliar estiver ativado, é necessário configurar os seguintes parâmetros.",
				"availables_stages": "Defina o número de etapas de calor auxiliar do sistema (1 ou 2).",
				"main_stage": "Selecione o primeiro sistema de fornecimento de calor: Bomba de calor ou Calor auxiliar.",
				"ventilation_conf": "Selecione entre Elétrico (ventilação ligada) ou Queimador (ventilação desligada). Se selecionar Elétrico, configure o atraso ventilador: Define o tempo de atraso (em segundos) para desligar o ventilador quando não houver demanda do aquecedor auxiliar. Valores: 0, 45, 60 e 120 segundos.",
				"stage_differential": "Define o diferencial de temperatura que o sistema precisa superar para ativar a etapa de calor auxiliar. Valores: 1 a 5˚C / 2 a 10 °F, em intervalos de 1˚C / 2 °F (por padrão, 1˚C / 2 °F).",
				"stage_hysteresis": "Define a histerese de funcionamento da etapa. Valores: 0,5 a 1˚C / 1 a 2 °F, em intervalos de 0,5˚C / 1 °F (por padrão, 0,5˚C / 1 °F).",
				"minimum_time_operating": "Define o tempo mínimo (em minutos) que a etapa principal deve estar ativa antes que a primeira etapa de calor auxiliar possa ser ativada. Valores: 0, 45, 60 e 120 minutos (por padrão, 45 minutos).",
				"away_modes_conf": "Permite a configuração dos seguintes parâmetros do modo de usuário Desocupado e Férias.",
				"hysteresis": "Se a temperatura de referência for superada pelo diferencial definido, a zona deixará de criar demanda. Intervalo: 1 a 3,5˚C ( 2 a 7 °F), em intervalos de 0,5˚C / 1 °F (por padrão, 3˚C / 6 °F).",
				"during_disable": "Configure os seguintes parâmetros do modo Auto.",
				"auto_mode": "Após um usuário tocar no ecrã, este intervalo de tempo (em minutos) indica quando o modo de ausência selecionado será retomado. Valores: 10 a 120 minutos, em intervalos de 10 minutos (por padrão, 60 minutos).",
				"temperature_differential": "Define o diferencial mínimo entre as temperaturas de referência dos modos frio e calor. Valores: 0 a 3,5˚C / 0 a 7 °F, em intervalos de 0,5˚C / 1 °F (por padrão, 1˚C / 2 °F).",
				"change_mode_protection": "Define o tempo (em minutos) de funcionamento mínimo de um dos modos antes de permitir a troca de modo. Valores: 15, 30, 60 e 90 minutos (por padrão, 30 minutos).",
				"heat_differential": "Se uma zona tiver uma demanda de calor mais alta que esta temperatura, o sistema voltará a funcionar em modo calor, inclusive se a demanda global em modo frio exceder a demanda global em modo calor. Valores: Off e de 1,5 a 4˚C (3 a 8 °F), em intervalos de 0,5˚C / 1 °F (por padrão, Off).",
				"antifreeze": "Evita que a temperatura ambiente da zona fique abaixo de 12˚ C / 53 °F, mesmo que esteja desligada.",
				"environment_temperature": "Permite mostrar/ocultar a temperatura ambiente e a humidade relativa da zona.",
				"heat_configuration": "Permite configurar os seguintes parâmetros da etapa de controle Ar+Radiante.",
				"cold_mode": "Permite selecionar a temperatura mínima para o modo frio (18-26˚C / 64-78 ˚F, padrão 18˚C / 64 ˚F).",
				"heat_mode": "Permite selecionar a temperatura máxima para o modo de aquecimento (19-30°C / 66-86 °F, padrão 30°C / 86 °F)",
				"proportional_operture_zbs": "Configuração do tipo de abertura: Tudo/Nada ou proporcional (por padrão).",
				"minimum_air_zbs": "Permite habilitar/desabilitar a entrada de um fluxo mínimo de ar nas zonas em que a temperatura de referência foi atingida nas diferentes comportas do sistema.",
				"information": "Permite visualizar informação sobre o sistema e os dispositivos.",
				"standby_mode_text": "Permite selecionar o estado da unidade interna para o modo frio e calor, uma vez que conforto.",
				"standby_on": "Quando não há demanda, os elementos motorizados das zonas inflamadas (On) se abrem.",
				"standby_off": "Quando não há demanda, os elementos motorizados das zonas ativadas (On) permanecem fechados."
			}
		},
		"systems": {
			"modes": {
				"stop": "Stop",
				"header": "Modos de funcionamento",
				"heat": "Calor",
				"heat-air": "Calor ar",
				"heat-radiant": "Calor radiante",
				"heat-both": "Calor combinado",
				"cool": "Frio",
				"cool-air": "Frio ar",
				"cool-radiant": "Frio radiante",
				"cool-both": "Frio combinado",
				"dehumidify": "Retirar a humidade",
				"ventilate": "Ventilar",
				"auto": "Auto",
				"emergency-heat": "Calor emergência",
				"heat-supply": "Calor de suporte",
				"dehumidifier": "Desumidificador"
			},
			"eco": {
				"eco-off": "Eco Off",
				"header": "Eco-Adapt",
				"eco-m": "Eco manual",
				"eco-a": "Eco A",
				"eco-aa": "Eco A+",
				"eco-aaa": "Eco A++"
			},
			"velocity": {
				"header": "Velocidade do ar",
				"velocity-0": "Auto",
				"velocity-1": "Baixa",
				"velocity-2": "Média",
				"velocity-3": "Alta",
				"velocity-mlp": "Velocidade"
			},
			"air_flow": {
				"header": "Controlo de fluxo",
				"air_flow-0": "Silêncio",
				"air_flow-1": "Padrão",
				"air_flow-2": "Potência"
			},
			"scene": {
				"header": "Modos de usuário",
				"confort": "Comfort",
				"eco": "Eco",
				"vacation": "Férias",
				"unocupied": "Desocupado",
				"night": "Noite",
				"stop": "Stop"
			},
			"timer": {
				"header": "Temporizador",
				"timer-off": "0 minutos",
				"timer30": "30 minutos",
				"timer60": "60 minutos",
				"timer90": "90 minutos"
			},
			"consumption": {
				"header": "Medidor de consumo"
			},
			"schedules": {
				"weekly": "Programações semanais",
				"calendar": "Programações de calendário"
			},
			"without_consumption_data": "Não há dados de consumo disponíveis no momento, verifique novamente mais tarde. Os dados são atualizados a cada hora.",
			"error0": "Erro configuração CCP",
			"error1": "Erro de comunicação CCP - Aerothermie Gateway",
			"error2": "Erro de comunicação Aerothermie Gateway - Maquina",
			"error3": "Erro de comunicação  no medidor de consumo",
			"error4": "Erro de medição no medidor de consumo",
			"error5": "Erro H2 detectado; atraso do comporta ativado. Para desativá-lo, acesse o menu avançado de configuração do sistema (modo de proteção)",
			"error6": "Aviso de configuração: Proteções e algoritmo de controle desativado. Veja a documentação do gateway para habilitar estas funções.",
			"error7": "Erro máquina:",
			"error8": "AQS ativa",
			"error9": "Erro de comunicação gateway Altherma - central",
			"error10": "Erro de comunicação gateway Altherma - unidade interior"
		},
		"terms": {
			"accept": "Aceito as ",
			"legal_terms": "Condições de uso",
			"and": " e a ",
			"privacy": "Política de privacidade",
			"publicity": "Desejo receber comunicações comerciais"
		},
		"device_relations_list": {
			"title": "Servidores",
			"no_servers_title": "Bem-vindos à Airzone!",
			"no_servers_info": "Para começar a controlar a instalação, adicione um servidor à sua conta.",
			"remove_confirmation": "Deseja eliminar este servidor da sua conta?",
			"removed_server": "Servidor eliminado",
			"internal_error": "Erro interno",
			"internal_error_message": "Não foi possível realizar sua ação. Tente novamente mais tarde",
			"actions": {
				"edit": "Editar",
				"config": "Configuração",
				"users": "Usuários",
				"remove": "Eliminar",
				"close": "Fechar",
				"accept": "Aceitar",
				"cancel": "Cancelar",
				"confirmation_deleted": "O servidor foi eliminado com sucesso",
				"add_server": "Adicionar servidor"
			}
		},
		"add_server": {
			"name_placeholder": "Nome (casa, escritório, chalé)",
			"mac_placeholder": "MAC (12:AB:34:CD:45:F0)",
			"pin_placeholder": "PIN",
			"location_placeholder": "Localização do servidor"
		},
		"add_user_to_device": {
			"title": "Adicionar usuário",
			"advanced": "Avançado",
			"basic": "Básico"
		},
		"users": {
			"title": "Usuários",
			"basic_user": "Usuários básicos",
			"advanced_user": "Usuários avançados",
			"basic": "básico",
			"advanced": "avançado",
			"add_user": "Adicionar usuário",
			"destroy": "Eliminar usuário",
			"remove_confirmation": "Tem a certeza que deseja eliminar o acesso do usuário ao servidor?",
			"zones": "Zonas com acesso",
			"blank": "Não há usuários."
		},
		"admin_users": {
			"confirmed": "Confirmado",
			"results": "Resultados",
			"no_results": "Sem resultados",
			"page": "Página",
			"of": "de",
			"device_modal": {
				"title": "Unidades acessíveis pelo usuário",
				"no_devices": "O usuário não pode acessar nenhuma unidade de AC",
				"location": "Localização"
			}
		},
		"zones": {
			"warnings": {
				"presence": "Alarme de presença",
				"window": "Alarme de janela",
				"antifreeze": "Antigelo",
				"dew": "Orv. ativo",
				"dew_protection": "ProteÃ§ao Orv Ativa",
				"thermostat": "Zona sem termostato",
				"heat_supply_activated": "Suporte térmico ativado",
				"low_valve_battery": "Pouca bateria válvula",
				"valve_NTC_alarm": "Alarme NTC2",
				"F05C": "Erro F05-C: Sonda de temperatura da bateria de frio em circuito aberto",
				"F05H": "Erro F05-H: Sonda de temperatura da bateria de calor em circuito aberto",
				"F06C": "Erro F06-C: Sonda de temperatura da bateria de frio em curto-circuito",
				"F06H": "Erro F06-H: Sonda de temperatura da bateria de calor em curto-circuito",
				"dehumidifier_activated": "Desumidificador ativado"
			},
			"adjusts": {
				"title": "Ajustes de zona",
				"title_all": "Todas as zonas",
				"name": "Nome",
				"name_system": "Nome do sistema",
				"autoturnoff": "Sleep",
				"timer": "Temporizador",
				"defrosting": "Antigelo",
				"local_ventilation": "Ventilação local",
				"lite": "Ajustes Lite",
				"led": "Estado LED",
				"work_mode": "Etapas",
				"cool": "Frio",
				"heat": "Calor",
				"air_direction": "Direção do ar",
				"lama_direction": "Direção da lâmina",
				"horizontal": "Horizontal",
				"vertical": "Vertical",
				"name_explanation": "Permite modificar o nome da zona",
				"autoturnoff_explanation": "A zona se desligará automaticamente após o intervalo de tempo selecionado. A cada intervalo de 30 minutos, a temperatura subirá um grau em frio ou diminuirá um grau em calor.",
				"sleep_off": "Off - Desativa o temporizador",
				"sleep_30": "30 - Ativa o temporizador e, aos 30 minutos, desliga a zona.",
				"sleep_60": "60 - Ativa o temporizador e, aos 60 minutos, desliga a zona.",
				"sleep_90": "90 - Ativa o temporizador e, aos 90 minutos, desliga a zona.",
				"defrosting_explanation": "Se o sistema detetar que a temperatura ambiente é inferior a 12°C, será ligado automaticamente em modo calor.",
				"work_mode_explanation": "Permite selecionar o método a ser utilizado para resfriar ou aquecer uma zona, quando a instalação permitir isso através de ar ou pavimento radiante:",
				"mode_air": "Ar - Apenas mediante ar",
				"mode_radiant": "Radiante - Apenas mediante pavimento radiante",
				"mode_mix": " - Utiliza uma combinação de ambos",
				"air_direction_explanation": "Permite selecionar a direção do ar em frio e em calor.",
				"local_ventilation_explanation": "Esta opção permite ativar ou desativar a ventilação na zona selecionada quando o sistema não estiver a arrefecer ou aquecer uma zona de maneira ativa.",
				"lite_explanation": "Poderá configurar o termostato Lite cabo para que depois de alguns segundos será desligado."
			},
			"schedules": {
				"title": "Programações",
				"init": "Início",
				"end": "Fim",
				"days": "Dias",
				"monday": "SEG",
				"tuesday": "TER",
				"wednesday": "QUA",
				"thursday": "QUI",
				"friday": "SEX",
				"saturday": "SAB",
				"sunday": "DOM",
				"add_schedule": "Adicionar programação",
				"schedules_types": "Tipos de programação",
				"temp": "Temp.",
				"mode": "Modo",
				"vent": "Ventilação",
				"start_hour": "Hora de início",
				"end_hour": "Hora de fim",
				"start_mode": "Modo Início",
				"end_mode": "Modo Fim",
				"start_temp": "Temp. Início",
				"end_temp": "Temp. Fim",
				"start_vent": "Vent. Início",
				"end_vent": "Vent. Fim",
				"close": "Fechar",
				"remove": "Eliminar",
				"save": "Salvar",
				"enable": "Habilitar",
				"hour": "Hora",
				"temperature": "Temperatura",
				"velocity": "Velocidade",
				"vel_0": "Automático",
				"vel_1": "Velocidade 1",
				"vel_2": "Velocidade 2",
				"vel_3": "Velocidade 3",
				"new_prog": "Nova programação",
				"edit_prog": "Editar programação",
				"schedules_name": "Nome",
				"remove_confirmation": "Tem a certeza que deseja eliminar a programação permanentemente?",
				"all_zones": "Todas as zonas",
				"systems": "sistemas",
				"waiting_title": "A criar programações",
				"waiting_text": "Neste momento, as programações estão sendo criadas. Este processo pode demorar alguns segundos, até que todas as zonas sejam criadas.",
				"save_confirmation": "¿Deseja guardar a programação realizada?",
				"edit_confirmation": "¿Deseja guardar as alterações realizadas?",
				"invalid_dates_range": "Não é possível salvar o programação para o intervalo de datas e a zona seleccionados"
			}
		}
	},
	"de": {
		"modal_vertical": {
			"title": "Positionen der vertikalen Lamellen",
			"position_0": "Neigung 0: Lamellen in 90°",
			"position_1": "Neigung 1: Lamellen in 72°",
			"position_2": "Neigung 2: Lamellen in 54°",
			"position_3": "Neigung 3: Lamellen in 36°",
			"position_4": "Neigung 4: Lamellen in 0°",
			"position_swing": "Swing: Wechselt alle Positionen der Lamellen ab"
		},
		"modal_horizontal": {
			"title": "Positionen der horizontalen Lamellen",
			"position_0": "Neigung 0: Lamellen in 180°",
			"position_1": "Neigung 1: Lamellen in 135°",
			"position_2": "Neigung 2: Lamellen in 90°",
			"position_3": "Neigung 3: Lamellen in 45°",
			"position_4": "Neigung 4: Lamellen in 0°",
			"position_swing": "Swing: Wechselt alle Positionen der Lamellen ab"
		},
		"adjust": {
			"title": "Benutzereinstellungen",
			"title_admin": "Administrator-Benutzereinstellungen",
			"personal": "Persönliche Daten",
			"language": "Sprache",
			"spanish": "Español",
			"english": "English",
			"french": "Français",
			"italian": "Italiano",
			"portuguese": "Português",
			"german": "Deutsch",
			"password": {
				"password_change": "Passwort ändern",
				"new_password_placeholder": "Neues Passwort",
				"new_password_repeat_placeholder": "Neues Passwort wiederholen",
				"old_password_placeholder": "Aktuelles Passwort"
			},
			"require": {
				"name": "Geben Sie Ihren Vornamen an",
				"surname": "Geben Sie Ihren Nachnamen an"
			},
			"advanced_adjust": "Erweiterte Einstellungen",
			"advanced_adjust_text": "Erweiterte Einstellungen anzeigen",
			"time_sync": "Datum und Uhrzeit synchronisieren",
			"save": {
				"title": "Gespeicherte Einstellungen",
				"body": "Ihre Einstellungen wurden erfolgreich gespeichert."
			},
			"unsubscribe_confirm": "Möchten Sie sich von der Airzone-Anwendung endgültig abmelden? Alle Ihre Daten werden gelöscht und können nicht wiederhergestellt werden",
			"unsubscribe": {
				"title": "Endgültige Abmeldung bestätigt",
				"body": "Sie wurden endgültig von Airzone abgemeldet; alle Ihre persönlichen Daten wurden erfolgreich gelöscht."
			},
			"change_pass": "Passwort ändern",
			"warranty": "Garantieverlängerung"
		},
		"languages": {
			"es": "Spanisch",
			"en": "Englisch"
		},
		"advanced_adjust": {
			"title": "Erweiterte Einstellungen",
			"subtitle": "System- und Zonen-Einstellungen",
			"all_zones": "Alle"
		},
		"adjust_zone": {
			"zone": "Zone",
			"dir_zone": "Zonenadresse",
			"associatted_exist": "Verbundene Ausgänge",
			"non": "Nein",
			"yes": "Ja",
			"master": "Master",
			"basic_mode": "Basismodus",
			"amb_temp": "Raumtemperatur",
			"master_dir": "Masteradresse",
			"builder": "Hersteller",
			"stages": "Stufen",
			"heat": "Wärme",
			"heat_air": "Luft",
			"heat_radiant": "Strahlung",
			"heat_combined": "Kombi",
			"heat_combo": "Luft+Strahlung",
			"cold": "Kälte",
			"cold_air": "Luft",
			"cold_radiant": "Strahlung",
			"cold_combined": "Kombi",
			"cold_combo": "Luft+Strahlung",
			"ventilation": "Lüftung",
			"step1": "Schritt 1",
			"step2": "Schritt 2",
			"step3": "Schritt 3",
			"caudal": "Durchflussmenge",
			"q-adapt": "Q-Adapt: Gewichtung Zone",
			"inputs": "Eingänge",
			"window": "Fenster",
			"window_normally_open": "Normal offen",
			"window_normally_closed": "Normal geschlossen",
			"presence": "Präsenz",
			"presence_normally_open": "Normal offen",
			"presence_normally_closed": "Normal geschlossen",
			"spray_dew": "Tausonde",
			"spray_dew_normally_open": "Normal offen",
			"spray_dew_normally_closed": "Normal geschlossen",
			"local_ventilation": "Lokale Lüftung",
			"local_vent_normally_open": "Normal offen",
			"local_vent_normally_closed": "Normal geschlossen",
			"information": "Information",
			"firm_term": "Firmware Thermostat",
			"firm_module": "Firmware Modul",
			"warning": "Bemerken",
			"errors": "Fehler",
			"error": "Fehler",
			"error3": "Fehler 3-Gitter nicht vorhanden",
			"error4": "Fehler 4-Gitter blockiert",
			"error5": "Fehler 5-Sonde nicht vorhanden",
			"error6": "Fehler 6-Sonde kurzgeschlossen",
			"error7": "Fehler 7-Klimatisierungselemente nicht kompatibel",
			"error8": "Fehler 8-Verbindungsverlust mit Modul Remote-Sonde",
			"error9": "Fehler 9-Präsenzalarm",
			"error10": "Fehler 10-Fensteralarm",
			"error11": "Fehler 11-Frostschutzalarm",
			"error12": "Zone ohne thermostat",
			"error15": "Fehler 15-Kommunikationsstörungen zwischen Verbrauchszähler und Systemzentral",
			"error16": "Fehler 16-Messfehler im Verbrauchszähler",
			"manufacturer_0": "Ohne Hersteller",
			"manufacturer_1": "Fujitsu General I",
			"manufacturer_2": "Fujitsu General II",
			"manufacturer_3": "Daikin I",
			"manufacturer_4": "Daikin II",
			"manufacturer_5": "LG",
			"manufacturer_6": "Tango",
			"manufacturer_7": "Mitsubishi Electric",
			"manufacturer_8": "Toshiba",
			"manufacturer_9": "Gree",
			"no_errors": "Keine Fehler vorhanden",
			"use_adapt_set": "Temperatur Benutzermodus",
			"zone_weight": "Gewichtung Zone",
			"eco_function": "Eco Funktion",
			"function_eco": {
				"1": "Normal offen",
				"2": "Normal geschlossen"
			},
			"DC_fan_voltage": "Ventilatorspannung DC",
			"min_volt": "Minimum V",
			"max_volt": "Maximum V",
			"constant_ventilation": "Ständige Belüftung",
			"cold_battery_temperature": "Batt.-Temp. Kalt",
			"heat_battery_temperature": "Batt.-Temp. Warm",
			"relay_title": "AC-Start-Stopp-Relais",
			"relay": "Relais",
			"control_stage": "Kontrollstufen",
			"cold_control_stage": {
				"0": "Direktverdamp.",
				"1": "Wasser",
				"2": "Lufzufuhr",
				"3": "Fußboden",
				"4": "Heizkörper/Decke",
				"5": "Eletric"
			},
			"heat_control_stage": {
				"0": "Eletric",
				"1": "Wasser",
				"2": "Lufzufuhr",
				"3": "Fußboden",
				"4": "Heizkörper/Decke",
				"5": "Eletric"
			},
			"valves": "Ventile",
			"help": {
				"direction_zone": "Zonenadresse",
				"direction_zone_text": "Nur-Lese-Parameter; gibt ausgewählte Zonenadresse an.",
				"slave": "Verbundene Ausgänge (Slaves)",
				"slave_text": " Nur-Lese-Parameter; gibt an, ob die ausgewählte Zone Module/Slave-Ausgänge hat, und deren Adresse.",
				"master": "Master",
				"master_text": "Ermöglicht die Masterfunktion in der ausgewählten Zone. Ermöglicht Auswahl JA oder NEIN.",
				"basic_mode": "Basismodus",
				"basic_mode_text": "Ermöglicht die Deaktivierung der erweiterten Funktionen des Thermostats unter Beibehaltung einer Basissteuerung zur leichteren Bedienung. ON wählen, um Basisfunktion zu aktivieren. Andernfalls OFF wählen.",
				"ambient_temp": "Raumtemperatur",
				"ambient_temp_text": "Nur-Lese-Parameter; gibt Raumtemperatur derjenigen Zone an, mit der das System derzeit arbeitet.",
				"manufacturer": "Hersteller",
				"manufacturer_text": "Parameter, der den Hersteller der Rahmen angibt",
				"heat_type_config": "Wärmestufe",
				"heat_type_config_text": "Ermöglicht die Konfiguration der Wärmestufen der Zone. Die Konfigurationsmöglichkeiten sind Luft, Strahler, Kombi und OFF, womit der Wärmemodus der ausgewählten Zone gelöscht wird.",
				"cold_type_config": "Kältestufe",
				"cold_type_config_text": "Ermöglicht die Konfiguration der Kältestufen der Zone. Die Konfigurationsmöglichkeiten sind Luft, Strahler, Kombi und OFF, womit der Kältemodus der ausgewählten Zone gelöscht wird.",
				"ventilation": "Lüftung",
				"ventilation_text": "Aktiviert/Deaktiviert die Lüftungsfunktion der Zone.",
				"q_weight": "Q-Adapt: Gewichtung Zone",
				"q_weight_text": "Ermöglicht in mit Q-Adapt manuell konfigurierten Systemen ACUAZONE / IBPro32 / Innobus Air, die Änderung der Gesamtgewichtung der Zone mit Werten zwischen 1-99.",
				"window": "Fenster",
				"window_text": "Ermöglicht die Statussteuerung einer Zone je nach Öffnen eines Fensters. Wenn dieser Eingang aktiviert wird, schaltet sich die Klimatisierung der Zone solange aus, bis der Ruhestatus wieder hergestellt wird. Um diese Funktion zu aktivieren, muss die Eingangslogik mit einem der folgenden Werte konfiguriert werden: Normal offen oder Normal geschlossen. Der Wert OFF deaktiviert diese Funktion.",
				"presence": "Präsenz",
				"presence_text": "Ermöglicht die Statussteuerung einer Zone nach Präsenzdetektor. Wenn dieser Eingang aktiviert wird, aktiviert die Klimatisierung der Zone den Sleepmodus mit Wert 90. Um diese Funktion zu aktivieren, muss die Eingangslogik mit einem der folgenden Werte konfiguriert werden: Normal offen oder Normal geschlossen. Der Wert OFF deaktiviert diese Funktion.",
				"spray_dew": "Tausonde",
				"spray_dew_text": "Ermöglicht die Statussteuerung einer Zone nach Präsenzdetektor. Wenn dieser Eingang aktiviert wird, aktiviert die Klimatisierung der Zone den Sleepmodus mit Wert 90. Um diese Funktion zu aktivieren, muss die Eingangslogik mit einem der folgenden Werte konfiguriert werden: Normal offen oder Normal geschlossen. Der Wert OFF deaktiviert diese Funktion.",
				"local_vent": "Lokale Lüftung",
				"local_vent_text": "Aktiviert/Deaktiviert die lokale Lüftung der Zone. Um diese Funktion zu aktivieren, muss die Eingangslogik mit einem der folgenden Werte konfiguriert werden: Normal offen oder Normal geschlossen. Der Wert OFF deaktiviert diese Funktion.",
				"errors": "Fehler",
				"errors_text": "Nur-Lese-Parameter; gibt an, ob aktive Fehler in der ausgewählten Zone vorliegen. Falls JA, drücken Sie auf den Parameter, um die Leiste zu öffnen und die aktiven Fehler anzuzeigen.",
				"firmt": "Firmware Thermostat",
				"firmt_text": "Nur-Lese-Parameter; gibt die Software des Thermostats der verbundenen Zone an.",
				"firmm": "Firmware Modul",
				"firmm_text": "Nur-Lese-Parameter; gibt die Software des lokalen Moduls der verbundenen Zone an.",
				"associatted_exist_text": "Anzeige der mit them thermostat verbundenen Steuerausgänge.",
				"use_adapt_set_text": "Konfiguration der Solltemperaturen",
				"confort_text": "Komfort. Wärmemodus: 15 bis 30 ˚C / 20 bis 68 ˚F (Standard, 20 ˚C / 68 ˚F). Kältemodus: 18 bis 30 ˚C / 64 bis 86 ˚F (Standard, 24 ˚C / 75 ˚F).",
				"eco_text": "Eco. Wärmemodus: 15 bis 30 ˚C / 59 bis 86 ˚F (Standard, 19 ˚C / 66 ˚F). Kältemodus: 18 bis 30 ˚C / 64 bis 86 ˚F (Standard, 29 ˚C / 77 ˚F).",
				"unocupied_text": "Unbewohnt. Wärmemodus: 15 bis 22 ˚C / 59 bis 72 ˚F (Standard, 17,5 ˚C / 63 ˚F). Kältemodus: 24 bis 30 ˚C / 75 bis 86 ˚F (Standard, 27 ˚C / 81 ˚F).",
				"vacation_text": "Urlaub. Wärmemodus: 10 bis 16 ˚C / 50 bis 61 ˚F (Standard, 10 ˚C / 50 ˚F). Kältemodus: 29 bis 35,5 ˚C / 84 bis 96 ˚F (Standard, 35 ˚C / 95 ˚F).",
				"offset_text": "Ermöglicht die Korrektur der in den verschiedenen bzw. in allen Zonen des Systems gemessenen Raumtemperatur, aufgrund Abweichungen durch nahe Wärme-/Kältequellen, mit einem Korrekturfaktor zwischen - 2,5 °C und 2,5 °C / -5 und 5 °F in Schritten von 0,5 °C / 1 °F. Die Standardkonfiguration ist 0 °C / 0 °F.",
				"stages_text": "Ermöglicht das Konfigurieren der Stufen „Wärme“ in der ausgewählten Zone oder in allen Zonen des Systems.",
				"air_text": "Aktiviert Wärme durch Luft in der ausgewählten Zone.",
				"radiant_text": "Aktiviert Wärme durch Strahlung in der ausgewählten Zone.",
				"air_radiant_text": "Aktiviert Wärme durch Luft und Strahlung in der ausgewählten Zone.",
				"inputs_text": "Ermöglicht die Steuerung des Modulstatus einer Zone nach Präsenzerkennung und fensterkontakt.",
				"window_zbs_text": "Es wird lediglich das Steuerelement des Zonenmoduls eingestellt. Wenn in einer Zone, in der dieser Kontakt aktiviert ist, ein geöffnetes Fenster erkannt wird, wird die Zone nach 60 Sekunden abgeschaltet, wobei am Thermostat die Meldung Fenster aktiv erscheint. In der Standardeinstellung ist die Funktion deaktiviert.",
				"presence_zbs_text": "Die Einstellung des Steuerelements Zonenmodul erfolgt nur, wenn es mit einem Thermostat verbunden ist; in Sekundärzonen-Modulen funktioniert sie nicht. Wenn in einer Zone, in der dieser Kontakt aktiviert ist, nach 5 Minuten keine Präsenz erkannt wird, setzt der Sleep 90 ein, wobei am Thermostat die Meldung Präsenz aktiv erscheint.",
				"information_text": "Ermöglicht die Anzeige von Infos über system und geräte."
			}
		},
		"buttons": {
			"sign_in": "Öffnen",
			"cancel": "Abbrechen",
			"init": "Starten",
			"send": "Senden",
			"save": "Speichern",
			"accept": "Akzeptieren",
			"resend_confirmation": "Bestätigung erneut senden",
			"back": "Zurück",
			"icon": "Symbol",
			"unsubscribe": "Endgültig abmelden",
			"remove": "Löschen",
			"restore_factory": "Werkseinstellung wiederherstellen",
			"next": "Weiter",
			"prev": "Vorherige",
			"close": "Schließen",
			"recharge": "Neu laden",
			"hide": "Ausblenden",
			"update_device": "Systeme erkennen",
			"continue": "Fortsetzen",
			"exit": "Verlassen",
			"help": "Hilfe",
			"select": "Wählen",
			"invite": "Einladen",
			"update": "Aktualisieren"
		},
		"email": "E-Mail",
		"name": "Vorname",
		"surname": "Name",
		"password": "Passwort",
		"new_password": "Neues Passwort",
		"repeat_password": "Passwort wiederholen",
		"server": {
			"notification": {
				"message": "Der Stromtarif für die Morgenstunden ist jetzt verfügbar.",
				"alert_message": {
					"title": "Mitteilungen",
					"text": "Möchten Sie Mitteilungen von Airzonecloud erhalten?",
					"remember": "Mich nicht erneut fragen"
				}
			},
			"temp": "Temperatur",
			"months": {
				"0": "JAN",
				"1": "FEB",
				"2": "MÄR",
				"3": "APR",
				"4": "MAI",
				"5": "JUN",
				"6": "JUL",
				"7": "AUG",
				"8": "SEP",
				"9": "OKT",
				"10": "NOV",
				"11": "DEZ"
			},
			"days": {
				"0": "Sonntag",
				"1": "Montag",
				"2": "Dienstag",
				"3": "Mittwoch",
				"4": "Donnerstag",
				"5": "Freitag",
				"6": "Samstag"
			},
			"current_price": "TAGESPREIS",
			"minimum_price": "MINIMUM",
			"maximum_price": "MAXIMUM",
			"day_price": "PREIS DES TAGES"
		},
		"state": "Status",
		"enabled": "Aktiviert",
		"sleeping": "Versorgt",
		"connected": "Angeschlossen",
		"activated": "Aktiviert",
		"components": "Komponenten",
		"historic": "Historie",
		"system": "System",
		"filters": "Filter",
		"mac": "Mac",
		"locality": "Ort",
		"all": "Alle",
		"pin": "PIN",
		"zone_name": "Vorname",
		"connection": "Verbindung",
		"compare": "Vergleichen Sie",
		"day": "Tag",
		"month": "Monat",
		"year": "Jahr",
		"range": "Bereiche",
		"maximum": "Maximal",
		"minimum": "Minimum",
		"mode": "Mode",
		"average": "Durchschnittliche",
		"without_data": "Es gibt keine Verbrauchsdaten",
		"full_page": "Im Vollbildmodus anzeigen",
		"print_chart": "Tabelle drucken",
		"download_png": "Auf PNG herunterladen",
		"download_jpg": "Auf JPG herunterladen",
		"download_pdf": "Auf PDF herunterladen",
		"download_avg": "Auf AVG herunterladen",
		"download_csv": "Auf CSV herunterladen",
		"download_xls": "Auf XLS herunterladen",
		"view_data_table": "Datentabelle anzeigen",
		"modal_mode": {
			"stop": "Stopp",
			"stop_text": "Stehmodus",
			"cold_air": "Kälte Luft",
			"cold_air_text": "Kältemodus",
			"heat_radiant": "Strahlungswärme",
			"heat_radiant_text": "Strahlungswärme-Modus",
			"ventilate": "Lüftung",
			"ventilate_text": "Lüftungsmodus",
			"heat_air": "Wärme Luft",
			"heat_air_text": "Wärmemodus",
			"heat_both": "Kombiwärme",
			"heat_both_text": "Kombiwärme-Modus",
			"dehumidify": "Entfeuchtung",
			"dehumidify_text": "Entfeuchtungsmodus",
			"cold_radiant": "Strahlungskälte",
			"cold_radiant_text": "Strahlungskälte-Modus",
			"cold_both": "Kombikälte",
			"cold_both_text": "Kombikälte-Modus",
			"auto_text": "Ermöglicht in Abhängigkeit vom Gesamtbedarf einen automatischen Wechsel zwischen Kälte und Wärme.",
			"emergency_heat": "Notfall-Wärme",
			"emergency_heat_text": "Aktiviert die Zusatzwärme, um im Fall einer mechanischen Störung im System Warmluft bereitzustellen."
		},
		"modal_eco": {
			"title": "Sparmodi",
			"eco": "Wählen Sie einen der Sparmodi. Je höher die Kategorie A, umso höher die Energieeinsparung, jedoch umso niedriger die Kühlleistung."
		},
		"modal_speed": {
			"title": "Verfügbare Luftgeschwindigkeitsmodi",
			"speed": "Wählen Sie eine der Geschwindigkeiten. Je höher die Zahl, umso höher die Luftgeschwindigkeit."
		},
		"modal_air_flow": {
			"silence": "Die Geschwindigkeit ist niedriger als im Standard, um die Geräuschunterdrückung zu begünstigen.",
			"standard": "Das System ändert die Geschwindigkeit je nach Anzahl der Zonen.",
			"power": "Die Geschwindigkeit ist höher als im Standard, um die Durchflusssteigerung zu begünstigen."
		},
		"modal_scene": {
			"stop": "Das Klimatisierungssystem bleibt ausgeschaltet, unabhängig vom Status der restlichen Zonen. Außerdem bleiben alle Endeinrichtungen (Gitter, Ventile etc.) geschlossen.",
			"confort": "Standardbenutzermodus. Wählen Sie die gewünschte Solltemperatur aus den voreingestellten   Temperaturbereichen aus.",
			"unoccupied": "Wird verwendet, wenn in kurzen Zeitabständen keine Präsenz erkannt wird. Es wird eine effizientere Solltemperatur festgelegt. Falls der Thermostat aktiviert ist, startet die Zone den Betrieb im Komfortmodus.",
			"night": "Das System verändert automatisch die Solltemperatur alle 30 Minuten um 0,5 °C / 1° F bis höchstens 2 °C / 4° FC. Beim Kühlen erhöht das System die Solltemperatur; beim Heizen reduziert das System die Solltemperatur.",
			"eco": "Passt die Temperaturen an immer effizientere Werte.",
			"vacation": "Diese Funktion spart Energie, falls der Benutzer während langer Zeiträume abwesend ist."
		},
		"home": {
			"menu": {
				"servers": "Server",
				"adjusts": "Benutzer",
				"help": "Hilfe",
				"admin": "Server",
				"exit": "Sitzung beenden",
				"admin_users": "Benutzer",
				"admins": "Administratoren",
				"connections": "Verbindungen"
			},
			"upload": {
				"title": "Ine neue Version der DKN Cloud ist verfügbar",
				"description": "Bitte App aktualisieren, um diese verwenden zu können."
			},
			"maintenance": {
				"title": "Diese Seite ist vorübergehend in Wartung",
				"description": "Diese Site wird momentan einer planmäßigen Wartung.",
				"text": "Unterzogen und steht bald wieder zur Verfügung."
			}
		},
		"installations": {
			"return": "Zurück",
			"exit": "Verlassen",
			"finish": "Beenden",
			"next": "Weiter",
			"edit": "Bearbeiten",
			"error": "Bei der Verbindung mit dem Webserver Airzone Cloud ist ein Fehler aufgetreten. Prüfen Sie, dass Ihr Gerät an das WLAN des Webservers Airzone Cloud (AZWSCLOUDxxx) angeschlossen ist",
			"error_mac_not_found": "Beim Abrufen des Mac vom Webserver ist ein Fehler aufgetreten.",
			"error_sleeping": "Ein Fehler ist aufgetreten, der Webserver ist nicht registriert",
			"error_not_connected": "Ein Fehler ist aufgetreten, der Webserver kann sich nicht mit der Cloud verbinden",
			"retry": "Neuversuch",
			"state1": {
				"text": "Überprüfen Sie, ob Ihr Gerät korrekt versorgt wird (LED D11 kontinuierlich) und sich innerhalb des Empfangsbereichs befindet"
			},
			"state2": {
				"text": "Prüfen Sie, dass das Gerät mit Strom versorgt wird und sich im Modus Zugriffspunkt WLAN-LED ohne Unterbrechung befindet."
			},
			"state3": {
				"assisted": "Geführte Installation",
				"wps": "WPS-Installation"
			},
			"state4": {
				"text": "Ändern Sie Ihr WLAN in den Einstellungen Ihres Geräts in ein Netz, das mit AZWSCLOUDxxx beginnt und gehen Sie zurück zu diesem Menü.",
				"text1": "Wenn Hinweis Verlust der Internetverbindung , halten Sie die Verbindung zum Gerät, den Prozess des Ascian fortzusetzen.",
				"text2": "Wählen Sie das WLAN, das beginnt mit: AZWSCLOUDxxxx",
				"text3": "Sie müssen das Mobildatennetz deaktivieren, wenn Sie Android 5 oder höher verwenden",
				"loading_text": "Änderung in das ausgewählte Netz wird durchgeführt. Bitte warten ...",
				"low_signal": {
					"title": "Empfang nicht ausreichend",
					"text": "Bringen Sie den Webserver in die Nähe des Routers oder installieren Sie einen WLAN-Verstärker, um den Empfang zu verbessern"
				},
				"auto": "Automatisch",
				"select_router": "Wählen Sie die Router-Pairing-Methode",
				"auto_info": "Automatisch. Sucht automatisch die WLANs in der Nähe Ihres Webservers.",
				"manual_info": "Manuell. Geben Sie die Daten des gewünschten WLAN manuell ein."
			},
			"state5": {
				"text": "Wählen Sie den Router, den Sie pairen möchten:",
				"name": "Name",
				"sec": "Sicherheit",
				"signal": "Signal",
				"loading_text": "Das Gerät wird verbunden. Bitte warten ...",
				"loading_text2": "(Wenn der Vorgang länger als eine Minute dauert, prüfen Sie ob die Verbindung des Geräts richtig ist)",
				"no_routers": "Es wurde kein WLAN in der Nähe des Webservers Airzone Cloud gefunden. Prüfen Sie ob Ihr Netz aktiviert ist und zur Verbindung angezeigt wird."
			},
			"state6": {
				"text": "Geben Sie die Daten Ihres WLAN ein"
			},
			"state7": {
				"text": "Daten Ihrer Netzkonfiguration",
				"ip": "IP",
				"port": "Link-Port",
				"masc": "Netzmaske",
				"manual": "MANUELL",
				"dhcp": "DHCP",
				"loading_text": "Airzone-Webserver wird konfiguriert. Bitte warten ...",
				"advanced": "Erweiterte Einstellungen"
			},
			"state8": {
				"text": "Vorgang wurde erfolgreich durchgeführt"
			},
			"state9": {
				"text": "Nachdem der Webserver verbunden ist, blinkt die WLAN-LED weiter",
				"select": "Wählen Sie Ihren Webserver",
				"searching": "Suchen nach Bluetooth-Geräten",
				"not_found": "Bluetooth-Geräte nicht gefunden",
				"not_appear": "Ihr Gerät wird nicht angezeigt?",
				"click_here": "Hier drücken"
			},
			"state15": {
				"title": "Manuelle Assoziation"
			},
			"state16": {
				"title": "Wie bekomme ich den Mac aus dem Webserver?",
				"boldtext1": "Über die App.",
				"text1": " Drücken Sie im Menü Server auf Bearbeiten und klicken Sie auf das Symbol Stift, um die MAC Ihres Geräts anzuzeigen.",
				"boldtext2": "",
				"text2": "Diese Information befindet sich auf der Rückseite dieser Bedienungsanleitung",
				"boldtext3": "Über den Blueface-Master-Thermostat",
				"text3": " des Systems 1 Ihrer Installation. In diesem Fall greifen Sie auf das Benutzermenü zu, drücken auf Informationen und wählen Webserver aus, um die Parameter MAC und PIN Ihres Geräts abzurufen.",
				"boldtext4": "Über den Think-Master-Thermostat",
				"text4": " des Systems 1 Ihrer Installation. In diesem Fall greifen Sie auf das Menü Erweiterte Konfiguration des Systems zu, drücken auf Informationen und wählen den Webserver aus, um die Parameter MAC und PIN Ihres Geräts abzurufen.",
				"boldtext5": "Auf dem Informationsaufkleber",
				"text5": " des Webserver-Geräts selbst"
			}
		},
		"login": {
			"rememberme": "Erinnere dich an mich",
			"restore_password": "Passwort merken",
			"demo": "Demo",
			"router": "Router pairen"
		},
		"register": {
			"sign_up": "Konto anlegen",
			"repeat_password": "Passwort bestätigen"
		},
		"complete_registration": {
			"title": "Registrierung abschließen"
		},
		"messages": {
			"migrated_user": "Registrierter Benutzer auf der neuen Plattform",
			"migrated_installation": "Registrierte Installation auf der neuen Plattform",
			"new_app": "Bitte installieren Sie Ihre neue mobile Airzonecloud-App:",
			"errors": {
				"recaptcha": "Leider ist die recaptcha ungültig",
				"name_blank": "Das Feld Vorname muss ausgefüllt werden",
				"surname_blank": "Das Feld Nachname muss ausgefüllt werden",
				"login": "Leider ist die E-Mail oder das Passwort ungültig",
				"email": "Leider ist die E-Mail ungültig",
				"email_blank": "Das Feld E-Mail-Adresse muss ausgefüllt werden",
				"email_taken": "Die E-Mail-Adresse wird bereits verwendet",
				"password_length": "Das Passwort muss mindestens 8 Zeichen haben",
				"password_blank": "Das Feld Passwort muss ausgefüllt werden",
				"password_confirmation_check": "Leider müssen die Passwörter identisch sein",
				"password_confirmation_blank": "Sie müssen Ihr Passwort bestätigen",
				"password_new_length": "Das neue Passwort muss mindestens 8 Zeichen haben",
				"password_new_blank": "Das Feld Neues Passwort muss ausgefüllt werden",
				"invalid_old_password": "Das aktuell eingegebene Passwort ist falsch.",
				"condition": "Sie müssen die Nutzungsbedingungen akzeptieren und die Datenschutzerklärung",
				"token_invalid": "Der Aktivierungscode ist falsch. Fordern Sie erneut Passwort merken an.",
				"email_not_exist": "Leider ist kein Konto mit dieser E-Mail-Adresse verbunden",
				"confirmation": "Bei der Bestätigung ist ein Fehler aufgetreten. Füllen Sie bitte das Formular aus. Wir senden Ihnen eine neue Bestätigungsmail.",
				"unconfirmed": "E-Mail nicht bestätigt!",
				"server_blank": "Geben Sie einen Namen für den Server an",
				"mac_blank": "Geben Sie die MAC-Adresse Ihres Servers an",
				"mac_format": "Das MAC-Format ist falsch",
				"mac_repeated": "Es besteht bereits ein Server mit dieser MAC",
				"pin_blank": "Geben Sie den PIN-Code Ihres Servers an",
				"mac_invalid": "Die eingegebene MAC ist falsch. Prüfen Sie bitte, dass sie mit den Daten des Cloud-Webservers übereinstimmt",
				"pin_invalid": "Die eingegebene PIN stimmt nicht mit der MAC überein",
				"server_sleeping": "Der angegebene Cloud-Webserver hat keine Internetverbindung. Prüfen Sie, dass er mit dem Router gepairt ist und der Router über Internetverbindung verfügt.",
				"server_taken": "Die angeforderte MAC ist bereits in der Cloud-Plattform angemeldet",
				"location_blank": "Geben Sie den Ort an, an dem sich Ihr Server befindet",
				"server_icon_blank": "Wählen Sie ein Symbol zur Darstellung Ihres Servers",
				"file_error": "Die Datei, die Sie importieren möchten, ist falsch. Die unterstützten Formate sind xls, xlsx und csv.",
				"must_select_file": "Sie müssen eine Datei auswählen",
				"not_connected": "Sie haben derzeit keine Internetverbindung und die durchgeführten Aktionen werden in Ihrem Airzone-System nicht wirksam. Wenn Sie über eine Internetverbindung verfügen, laden Sie die Seite erneut und versuchen Sie es noch einmal.",
				"not_connected_title": "Hinweis zu Ihrer Internetverbindung",
				"not_connected_device": "Ihr Airzone-Gerät ist derzeit nicht mit dem Internet verbunden. Deshalb werden die durchgeführten Aktionen nicht wirksam. Verbinden Sie Ihr Airzone-Gerät mit dem Internet, laden Sie die Seite erneut und versuchen Sie es noch einmal.",
				"not_connected_device_title": "Hinweis zur Verbindung Ihres Gerätes",
				"error_device": "In Ihrem Gerät ist ein Fehler aufgetreten. Falls diese Meldung weiter besteht, wenden Sie sich bitte an Ihren Installateur.",
				"registration_already_completed": "Die Registrierung wurde bereits früher abgeschlossen. Loggen Sie sich bitte mit Ihren Daten ein.",
				"user_taken": "Der Benutzer ist bereits mit dem Server verbunden",
				"without_internet_title": "Internetverbindung",
				"without_internet_text": "Diese Anwendung benötigt Internetverbindung zur Arbeit. Bitte verbinden Sie Ihr Gerät und versuchen Sie es erneut",
				"day_blank": "Sie müssen einen Tag auswählen",
				"mac_length_seek": "Mindestens 4 Ziffern erforderlich",
				"email_length_seek": "Mindestens 3 Zeichen erforderlich",
				"not_systems_title": "Hinweis auf die Systeme",
				"not_systems": "Es wurden keine Systeme festgestellt. ÜberprÃ¼fen Sie bitte Ihre Installation",
				"location_title": "Standort deaktiviert",
				"location_text": "Bitte überprüfen Sie, ob Sie den Standort in den Einstellungen Ihres Telefons aktiviert haben.",
				"location_disabled_title": "Standort nicht freigegeben",
				"location_disabled_text": "Bitte überprüfen Sie, ob Sie den Standort in den Einstellungen Ihres Telefons freigegeben haben.",
				"bluetooth_disabled_title": "Bluetooth deaktiviert",
				"bluetooth_disabled_text": "Bitte überprüfen Sie, ob Sie Bluetooth in den Einstellungen Ihres Telefons aktiviert haben.",
				"bluetooth_connect_device_title": "Gerät ausgeschaltet",
				"bluetooth_connect_device_text": "Bluetooth-Verbindung unterbrochen.",
				"bluetooth_scan_title": "Scanfehler",
				"bluetooth_scan_text": "Fehler beim Scannen von Bluetooth-Geräten."
			},
			"success": {
				"register": "Registrierung erfolgreich!",
				"send_reset_password": "Wir haben Ihnen eine E-Mail mit der Anleitung gesendet, damit Sie Ihr Passwort ändern.",
				"reset_password": "Ihr Passwort wurde geändert",
				"resend_confirmation": "Es wurde eine neue Bestätigungsmail an Ihre E-Mail-Adresse gesendet.",
				"user_server_added": "Die Verbindung mit dem Server wurde erfolgreich hergestellt. Sie können über das Fenster für Server darauf zugreifen.",
				"user_server_updated": "Die Information des Servers wurde erfolgreich aktualisiert.",
				"restore_factory": "Der Server wurde auf die Ausgangskonfiguration zurückgesetzt.",
				"added_basic_user_to_server": "Der Benutzer wurde dem Server erfolgreich hinzugefügt. Drücken Sie auf Akzeptieren um auf den Konfigurationsbildschirm zuzugreifen.",
				"added_advanced_user_to_server": "Der Benutzer wurde dem Server erfolgreich hinzugefügt."
			},
			"info": {
				"has_email": "Sie haben eine E-Mail erhalten!",
				"go_login": "Zum Log-In gehen",
				"reset_password": "Gehen Sie zurück zum Log-In und geben Sie Ihre E-Mail-Adresse und Ihr neues Passwort ein, um sich anzumelden.",
				"change_password": "Schreiben Sie Ihr neues Passwort und wiederholen Sie es.",
				"send_reset_password": "Kein Problem, das kann schon mal vorkommen. Geben Sie lediglich Ihre E-Mail-Adresse an. Wir senden Ihnen dann eine E-Mail mit Anleitung zur Änderung Ihres Passworts.",
				"register_success": "Es wurde eine E-Mail an Ihr Konto gesendet, um Ihre Identität zu prüfen.",
				"complete_registration_success": "Die Registrierung wurde erfolgreich abgeschlossen. Sie können jetzt die Sitzung starten und auf die Verwaltung Ihres Airzone-Geräts zugreifen.",
				"resend_confirmation": "Geben Sie Ihre E-Mail-Adresse ein. Wir senden Ihnen eine neue Bestätigungsmail.",
				"unconfirmed": "Sie müssen Ihre E-Mail-Adresse bestätigen, bevor Sie die Sitzung starten",
				"remove_server_admin": {
					"question": "Möchten Sie diesen Server löschen?",
					"info": "Alle mit diesem Server verbundenen Daten werden gelöscht."
				},
				"restore_server_admin": {
					"question": "Möchten Sie diesen Server auf Werkseinstellung zurücksetzen?",
					"info": "Alle mit diesem Server verbundenen Daten werden zurückgesetzt."
				},
				"update_server": "Der Server wird aktualisiert. Der Vorgang kann einige Minuten dauern.",
				"update_server_info": "Starten des Aktualisierungsprozesses dieses Webservers auf Version",
				"update_server_confirmation": "Möchten Sie diesen Server aktualisieren?",
				"browsers": "Leider wird der Browser, den Sie gerade verwenden, nicht durch unsere Anwendung unterstützt oder es handelt sich um eine sehr alte Version. Wenn Sie die Anwendung verwenden möchten, empfehlen wir, dass Sie einen der folgenden Browser installieren oder aktualisieren.",
				"disable_permission_device": "Es ist notwendig mindestens in einer Zone Berechtigung haben",
				"auto_mode_battery_temperature": "Automatischer Moduswechsel aktiviert",
				"purifier_maintenance1": "Führen Sie die Wartung Ihres Luftreinigers/Desinfektionsanlage durch.",
				"purifier_maintenance2": "Führen Sie die Wartung Ihres Luftreinigers/Desinfektionsanlage durch. Gelangen Sie in das Menü von den erweiterten Einstellungen des Systems um den Zähler neu zu starten (Wartung des Luftreinigers).",
				"purifier_maintenance_before": "Führen Sie die Wartung Ihres Luftreinigers/Desinfektionsanlage durch bevor:"
			},
			"title": {
				"reset_password": "Passwort merken",
				"change_password": "Passwort ändern",
				"resend_confirmation": "Bestätigung erneut senden",
				"user_server_added": "Der Server wurde erfolgreich angelegt",
				"user_server_updated": "Der Server wurde erfolgreich aktualisiert",
				"add_server": "Server hinzufügen",
				"edit_server": "Server bearbeiten",
				"error": "Fehler",
				"server_data": "Serverdaten",
				"remove_server": "Server löschen",
				"restore_factory": "Server auf Werkseinstellung zurücksetzen",
				"user_server_added_device": "Der Benutzer wurde erfolgreich hinzugefügt",
				"sync_datetime": "Richtige timing",
				"device_permissions": "Benutzerberechtigungen",
				"maintenance": "Wartung",
				"browsers": "Der Navigator wird nicht unterstützt"
			}
		},
		"servers": {
			"no_results": "Es wurden keine Server gefunden",
			"tags": {
				"servers": "Server",
				"results": "Ergebnisse",
				"page": "Seite ",
				"of": " von "
			},
			"new": {
				"add_servers": "Server hinzufügen",
				"return": "Zurück",
				"from_file": "Aus Datei",
				"select_file": "Wählen Sie eine Datei",
				"select_file2": "Wählen Sie eine Datei",
				"from_form": "Aus Formular",
				"success_add2": "Die Server wurden",
				"success_add_f2": "erfolgreich hinzugefügt",
				"success_add1": "Der Server wurde",
				"success_add_f1": "erfolgreich hinzugefügt",
				"failure_add1": "Der folgende Server konnte nicht hinzugefügt werden",
				"failure_add2": "Die folgenden Server konnten nicht",
				"failure_add_f2": "hinzugefügt werden",
				"accept": "Akzeptieren",
				"success": {
					"added": "Der Server wurde erfolgreich hinzugefügt",
					"added2": "Der Server wurde auf der Plattform erfolgreich angemeldet"
				}
			}
		},
		"system_config": {
			"0": "System mit Zoneneinteilung",
			"1": "System mit Zoneneinteilung FC",
			"2": "2-Rohre-Installation",
			"3": "4-Rohre-Installation",
			"4": "RadianT",
			"6": "Airzone INT",
			"title": "Systemeinstellungen",
			"systems": "System",
			"acs_system": "WBW",
			"system_fw": "Firmware",
			"ccp_options": {
				"0": "Aerothermie",
				"1": "2-Rohre",
				"2": "4-Rohre",
				"3": "RadianT"
			},
			"setup_type": "Installationsart",
			"blueface_number": "Blueface",
			"think_r": "Think R",
			"think_c": "Think K",
			"lite": "Lite",
			"radio_number": "Tacto R",
			"tact_number": "Tacto K",
			"probe_number": "Sonde",
			"firm_ws": "Firmware Webserver",
			"cold_mixing_valve": "Kältemischventil",
			"heat_rele_board_number": "Anzahl Relaisplatten in Wärme",
			"cold_rele_board_number": "Anzahl Relaisplatten in Kälte",
			"combi_rele_board_number": "Anzahl Relaisplatten in Kombi",
			"rele_fw_1": "Firmware Relaisplatte 1",
			"rele_fw_2": "Firmware Relaisplatte 2",
			"rele_fw_3": "Firmware Relaisplatte 3",
			"rele_fw_4": "Firmware Relaisplatte 4",
			"rele_fw_5": "Firmware Relaisplatte 5",
			"rele_fw_6": "Firmware Relaisplatte 6",
			"rele_fw_7": "Firmware Relaisplatte 7",
			"rele_fw_8": "Firmware Relaisplatte 8",
			"fancoil_gw": "Fancoil-Gateway",
			"vent_gw": "Gateway Lüftung",
			"dehum_gw": "Gateway Entfeuch",
			"heat_eme_gw": "Gateway EmeHeat",
			"heat_aux_gw": "Gateway AuxHeat",
			"knx_gw": "Gateway KNX",
			"radio_switchboard_fw": "Módulo válvulas radio",
			"ccp": "CCP",
			"system_number": "System-Nr.",
			"direction_system": "Systemadresse",
			"etapes": "Stufen",
			"cold_mode": "Kältemodus",
			"heat_mode": "Wärmemodus",
			"cold_mode_combined": "Kombikälte",
			"heat_mode_combined": "Kombiwärme",
			"min_temp": "Mindesttemp.",
			"max_temp": "Höchsttemp.",
			"hysteresis": "Hysterese",
			"cold_hysteresis": "Kältehysterese",
			"heat_hysteresis": "Wärmehysterese",
			"protect_temp": "Schutztemperatur",
			"cauldron": "Kessel",
			"cold_aco_air": "Klimaanlage - Kältemodus",
			"heat_aco_air": "Klimaanlage - Wärmemodus",
			"radiant_algorithm": "Strahlungsalgorithmus",
			"proportional_operture": "Proportionale Öffnung",
			"min_air": "Mindestluft",
			"master_installation": "Supermaster",
			"q_adapt": "Q-Adapt",
			"q_adapt_c": "Q-Adapt",
			"q_adapt_d": "Q-Adapt",
			"q_adapt0": "Standard",
			"q_adapt1": "Leistung",
			"q_adapt2": "Leise",
			"q_adapt3": "Minimum",
			"q_adapt4": "Máximal",
			"min_volt": "Mindestspannung",
			"max_volt": "Höchstspannung",
			"q_adaptd1": "Manuell",
			"q_adaptd0": "Automatisch",
			"ventilation_mode": "Lüftung",
			"system_weight": "Systemgewicht",
			"work_mode": "Lüftungsmodus",
			"work_mode_ext_air": "Außenluft",
			"work_mode_recirc": "Rezirkulieren",
			"work_mode_combined": "Kombi",
			"air_ventilate": "Klimatisieren und Lüften",
			"overall_ventilation": "Einlass Gesamtlüftung",
			"normally_open": "Normal offen",
			"normally_closed": "Normal geschlossen",
			"busy": "Freecooling belegt",
			"unoccupied": "Freecooling nicht belegt",
			"delta": "Delta",
			"ext_temp": "Außentemperatur",
			"setpoint_temperature": "Solltemperatur",
			"input_output": "Einlass/Auslass",
			"input": "Einlass",
			"probe": "Sonde",
			"stop_start": "Stopp/Lauf",
			"output": "Auslass",
			"demand_vmc": "VMC-Abruf",
			"demand_radiant": "Strahlung",
			"air": "Luft",
			"minutes": "Minuten",
			"caudal": "Durchflussmenge",
			"information": "Information",
			"errors": "Fehler",
			"error0": "Kein Fehler vorhanden",
			"error1": "Fehler Maschinen-Gateway",
			"global_ventilation": "Gesamtlüftung",
			"global_ventilation_every": "Alle (Min)",
			"global_ventilation_run_for": "Für (min)",
			"antifreeze": "Antifrost",
			"auxiliary_heat": "Zusatzwärme",
			"available_stages": "Verfügbare Stufen",
			"first_supply_heat": "Hauptstufe",
			"aux_heat": "Zus. Wärme",
			"heat_pump": "Wärmepumpe",
			"fan_configuration": "Ventilatorkonf.",
			"electric": "Electric",
			"furnace": "Brenner",
			"delay": "Verzög.",
			"first_stage": "Erste Stufe",
			"min_time_exhausted": "Min. Betriebszeit",
			"second_stage": "Zweite Stufe",
			"differential": "Differenz.",
			"min_time": "Min. Betriebszeit",
			"autochange": "Auto-Mod.",
			"setpoint_differential": "Temp.differenz",
			"mode_switching_protection1": "Schutz",
			"mode_switching_protection2": "Modusänderung (Min)",
			"heat_ovr": "Diff. Wärmevorgabe",
			"away_mode_settings": "Konf. Abwesenh.Modi",
			"override_time1": "Deaktivieren",
			"override_time2": "für (Min)",
			"room_temp": "Raumtemp.",
			"test_rocio": "Schutz gegen Kondensation",
			"rocio_hist": "Schutzniveau",
			"rocio_hist_very_high": "Sehr hoch",
			"rocio_hist_high": "Hoch",
			"rocio_hist_med": "Mittleres",
			"rocio_hist_low": "Niedrig",
			"rocio_hist_very_low": "Sehr niedrig",
			"test_rocio_off": "Den Kondensationsschutz wird innerhalb der nächsten Stunde abgeschaltet sein. Nach Ende dieses Zeitraums ist das Schutzniveau ",
			"temperature_range": "Temperaturbereich",
			"stage_configuration": "Wärmekonfiguration",
			"temp_gap": "Temp. differenz",
			"firmMC": "Verbrauchszähl",
			"plenum_mode_title": "Standby-Betrieb",
			"hitachi_prot_title": "Schutzmodus - Schliessverzögerung bei den motorisierten Elementen",
			"battery_temperature": "Batterie-Temperatur",
			"min_battery_temperature": "Batt.-Temp. Warm",
			"max_battery_temperature": "Batt.-Temp. Kalt",
			"auto_mode_battery_temperature": "Automatischer Moduswechsel je nach Batterie-Temperatur",
			"deshumidifier_function": "Entfeuchter Funktion",
			"humidity_limit": "Luftfeuchtigkeit Grenze",
			"disabled": "Deaktiviert",
			"air_supply_settings": "Lufzufuhr Einst.",
			"air_supply_type": "Art der Lufzufuhr",
			"fan_delay": "Ventilatorverzög",
			"standby_mode_label1": "Statusgerät bei",
			"standby_mode_label2": " Komfort",
			"standby_mode": {
				"0": "Ausgeschaltet",
				"1": "Standby"
			},
			"wsccp_activation_delay": "Aktivierungs-Verzögerung",
			"wsccp_cold_heat_tai": "Wasservorlauf-temperatur",
			"wsccp_desactivation_acs": "WBW funktion",
			"purifier_control": "Wartung des Luftreinigers",
			"units": "Einheiten",
			"hours": "Stunden",
			"days": "Tage",
			"timer": "Zeitraum",
			"counter": "Zähler",
			"reset": "Reset",
			"fixed_time": "Festgelegte Zeit",
			"production": "Produktion",
			"help": {
				"direction_system": "Systemadresse",
				"direction_system_text": "Zeigt die Systemadresse an.",
				"temp_ranges": "Temperaturbereich",
				"temp_ranges_text": "Ermöglicht die Auswahl der Mindest-Temperaturbereiche (Kältemodus) und Höchst-Temperaturbereiche (Wärmemodus) des Systems.",
				"combined_steps": "Kombistufen",
				"combined_steps_text": "Ermöglicht die Festlegung der gewünschten Temperaturdifferenz zwischen Soll- und Raumtemperatur, damit im Kombimodus der Übergang von der Verwendung der Luft- und Strahlungsstufen nur der Übergang zur Strahlungsstufe erfolgt.",
				"protect_temp": "Schutztemperatur",
				"protect_temp_text": "Ermöglicht die Festlegung der Temperaturen, bei denen der Kessel oder die Klimaanlage zur Vermeidung von Beschädigungen abgeschaltet wird.",
				"radiant_algorithm": "Strahlungsalgorithmus",
				"radiant_algorithm_text": "Konfigurieren Sie bitte den Funktionsintervall des Algorithmus für Energie-Effizienz. Dieser Algorithmus passt die Funktion des gesteuerten Strahlungselements an, damit Überhitzungen aufgrund eventueller thermischer Trägheiten, z. B. bei Fußbodenheizungen, vermeidet werden.",
				"proportional_operture": "Proportionale Öffnung",
				"proportional_operture_text": "Ermöglicht die Wahl zwischen vollständiger oder geschlossener Öffnung.",
				"min_air": "Mindestluft",
				"min_air_text": "Dies ermöglicht den Einlass einer Mindestdurchflussmenge Luft in Zonen, in denen die Solltemperatur erreicht wurde. Auf diese Weise ermöglicht das System die Luftzufuhr zur Lüftung über die Schächte der Klimaanlage. Wenn die Zone ausgeschaltet ist, wird das Ventil vollständig geschlossen.",
				"master_installation": "Installationsmaster",
				"master_installation_text": "Ermöglicht, den Betriebsmodus von System 1 den übrigen angeschlossenen Systemen vorzuschreiben.",
				"q_adapt": "Q-Adapt",
				"q_adapt_text": "Eine Funktion der Airzone-Systeme zur Anpassung der Durchflussmenge der Luftanlage an die Bedürfnisse des Systems / der Zone.",
				"q_adapt_power": "Q-Leistung - Begünstigt die Durchflussmengensteigerung auf der Geschwindigkeitenkarte.",
				"q_adapt_standard": "Q-Standard - Standardkonfiguration.",
				"q_adapt_max": "Q-Maximal - Das System arbeitet mit maximaler Geschwindigkeit, unabhängig von der Anzahl der Zonen.",
				"q_adapt_min": "Q-Leise - Begünstigt die Geräuschminderung.",
				"q_adapt_silent": "Q-Minimum - System läuft mit Mindestgeschwindigkeit.",
				"ventilation_mode_weight": "Lüftungsmodus - Systemgewicht",
				"ventilation_mode_weight_text": "Ermöglicht die Konfiguration der Relevanz des Systems in Richtung zur Lüftungs-Steuerzentrale.",
				"ventilation_mode_work": "Lüftungsmodus - Betriebsmodus",
				"ventilation_mode_work_text": "Dieser Parameter ermöglicht die Auswahl der gewünschten Lüftungsart im System.",
				"ventilation_mode_clim": "Lüftungsmodus - Klimatisieren und Lüften",
				"ventilation_mode_clim_text": "Ermöglicht die Konfiguration der Funktionsebenen des Systems, damit Klimatisieren und Lüften gleichzeitig laufen.",
				"ventilation_mode_global": "Lüftungsmodus - Einlass Gesamtlüftung",
				"ventilation_mode_global_text": "Aktiviert die lokale Lüftung aller Zonen. Sie müssen die Einlasslogik „normal offen“ oder „normal geschlossen“ wählen.",
				"free_cooling_busy": "FreeCooling - Belegt",
				"free_cooling_busy_text": "Ermöglicht die Aktivierung des Freecooling-Betriebs im Modus „Belegt“. Delta ermöglicht die Festlegung der Temperaturdifferenz zur Aktivierung des Freecooling.",
				"free_cooling_unoccupied": "Freecooling - Nicht belegt",
				"free_cooling_unoccupied_text": "Ermöglicht die Aktivierung des Freecooling-Betriebs im Modus „Nicht belegt“.",
				"ext_temp": "Außentemperatur - Parameter für die Festlegung der gewünschten Außentemperatur. Wenn Außen-Umgebungstemperatur < konfigurierte Außentemperatur ist, läuft das Freecooling an.",
				"consign": "Soll: Parameter für die Festlegung der gewünschten Innentemperatur. Wenn Innen-Raumtemperatur < konfigurierte Innentemperatur ist, läuft das Freecooling an.",
				"input": "Einlass",
				"input_text": "Ermöglicht die Einlass-Konfiguration:",
				"input_probe": "Sonde - Ermöglicht die Messung der Rücklauftemperatur einer Klimaanlage mithilfe einer externen Sonde.",
				"input_door": "Tür - Ermöglicht einen ferngesteuerten Lauf-Stopp des Systems durchzuführen. Bei Neustart des Systems kehrt dieses in den Status vor dem Stopp zurück.",
				"exit": "Auslass",
				"exit_text": "Ermöglicht die Auslass-Konfiguration",
				"global_ventilation": "Ermöglicht die Aktivierung/Deaktivierung des Lüftungsmodus in allen Zonen, wenn das System in einer Zone keinen Bedarf hat.",
				"global_ventilation_every": "Konfigurieren Sie die Intervalldauer (in Minuten) zwischen den Aktivierungszeiten der Gesamtlüftung. Konfigurierbar von 5 bis 40 Minuten, in Schritten von 5 Minuten (Standard, 15 Minuten).",
				"global_ventilation_run_for": "Konfigurieren Sie die Betriebszeit (in Minuten) während der die Gesamtlüftung läuft. Konfigurierbar von 5 bis 20 Minuten, in Schritten von 5 Minuten (Standard, 10 Minuten).",
				"auxiliary_heat": "Ermöglicht das Aktivieren/Deaktivieren der Zusatzwärme. In der Standardeinstellung ist die Funktion nicht aktiviert.",
				"availables_stages": "Legen Sie fest, wie viele Stufen Zusatzwärme das System hat (1 oder 2).",
				"main_stage": "Wählen Sie das erste System, das Wärme liefert: Wärmepumpe oder Zusatzwärme.",
				"ventilation_conf": "Wählen Sie zwischen Electric (eingeschaltete Lüftung) und Brenner (ausgeschaltete Lüftung). Wenn Sie Electric wählen, konfigurieren Sie Ventilatorverzög: Legt die Verzögerungszeit (in Sekunden) zum Abschalten des Ventilators fest, wenn kein Bedarf des Zusatzkessels besteht. Werte: 0, 45, 60 und 120 Sekunden.",
				"stage_differential": "Legt die Temperaturdifferenz fest, die das System überschreiten muss, um die Erste Stufe der Zusatzwärme zu aktivieren. Werte: 1 bis 5 ˚C / 10 ˚F, in Schritten von 1 ˚C / 2 ˚F.",
				"stage_hysteresis": "Legt die Betriebshysterese der Ersten Stufe fest. Werte: 0,5 bis 1 ˚C / 1 bis 2 ˚F, in Schritten von 0,5 ˚C / 1 ˚F.",
				"minimum_time_operating": "Legt die Mindestzeit (in Minuten) fest, die die hauptstufe aktiv sein muss, bevor die Erste Stufe Zusatzwärme aktiviert werden kann. Werte: 0, 45, 60 und 120 Minuten.",
				"away_modes_conf": "Ermöglicht die Konfiguration folgender Parameter des Benutzermodus Unbewohnt und Urlaub.",
				"hysteresis": "Wenn die Solltemperatur durch die festgelegte Differenz überschritten wird, erzeugt die Zone keinen Bedarf mehr. Bereich: 1 bis 3,5 ˚C / 2 bis 7 ˚F in Schritten von 0,5 ˚C / 1 ˚F.",
				"during_disable": "Zeit (in Minuten) wählen nach der der gewählte Abwesenheitsmodus wieder anläuft, wenn ein Benutzer den Bildschirm in diesem Modus berührt. Werte: 10 bis 120 Minuten in Schritten von 10 Minuten (Standard, 60 Minuten).",
				"auto_mode": "Konfiguration der folgenden Parameter des Auto-Modus.",
				"temperature_differential": "Legt die Mindestdifferenz zwischen den Solltemperaturen der Kälte- und Wärmemodi fest. Werte: 0 bis 3,5 ˚C / 0 bis 7 ˚F, in Schritten von 0,5 ˚C / 1 ˚F.",
				"change_mode_protection": "Legt die Mindest-Betriebszeit (in Minuten) einer der Modi fest, bevor eine Modusänderung möglich ist. Werte: 15, 30, 60 und 90 Minuten.",
				"heat_differential": "Wenn eine Zone einen höheren Wärmebedarf hat als die festgelegte Temperatur, funktioniert das System wieder im Wärmemodus, selbst wenn der Gesamtbedarf im Kältemodus den Gesamtbedarf im Wärmemodus übersteigt. Werte: Aus und von 1,5 bis 4 ˚C / 3 bis 8 ˚F in Schritten von 0,5 ˚C / 1˚F.",
				"antifreeze": "Verhindert, dass die Raumtemperatur der Zone unter 12˚C / 53˚F sinkt, auch wenn die Zone abgeschaltet ist.",
				"environment_temperature": "Ermöglicht Anzeige/Ausblenden der Raumtemperatur und relativen Luftfeuchtigkeit der Zone. In der Standardeinstellung ist die Anzeige aktiviert.",
				"heat_configuration": "Ermöglicht die Konfiguration der folgenden Luft+Strahlung-Steuerstufe.",
				"cold_mode": "Ermöglicht die Auswahl der Mindesttemperatur für den Kühlmodus  (18-26˚C / 64-78˚F, Standard 18˚C / 64˚F).",
				"heat_mode": "Ermöglicht die Auswahl der Höchsttemperatur für den Heizmodus (19 – 30 °C  / 66-86˚F, Standard 30˚C / 86˚F).",
				"proportional_operture_zbs": "Konfiguration der Öffnungsart: Alle/Keine und proportional.",
				"minimum_air_zbs": "Ermöglicht die Aktivierung/Deaktivierung des Einlasses einer Mindestdurchflussmenge Luft in Zonen, in denen an den verschiedenen Systemventilen die Solltemperatur erreicht wurde.",
				"information": "Ermöglicht die Anzeige von Infos über system und geräte.",
				"standby_mode_text": "Dient für die Einstellung vom Statusgerät beim Wärme- und Kältemodus, wenn es schon bei Komfort ist.",
				"standby_on": "Wenn das System keinen Bedarf mehr erzeugt, bleiben die motorisierten Elemente der eingeschalteten Zonen geöffnet.",
				"standby_off": "Wenn das System keinen Bedarf mehr erzeugt, bleiben die motorisierten Elemente der eingeschalteten Zonen geschlossen."
			}
		},
		"systems": {
			"modes": {
				"stop": "Stopp",
				"header": "Betriebsmodi",
				"heat": "Wärme",
				"heat-air": "Wärme Luft",
				"heat-radiant": "Strahlungswärme",
				"heat-both": "Kombiwärme",
				"cool": "Kälte",
				"cool-air": "Kälte Luft",
				"cool-radiant": "Strahlungskälte",
				"cool-both": "Kombikälte",
				"dehumidify": "Entfeuchten",
				"ventilate": "Lüften",
				"auto": "Auto",
				"emergency-heat": "Notfall-Wärme",
				"heat-supply": "Hitze der Unterstützung",
				"dehumidifier": "Entfeuchter"
			},
			"eco": {
				"eco-off": "Eco AUS",
				"header": "Eco-Adapt",
				"eco-m": "Eco manuell",
				"eco-a": "Eco A",
				"eco-aa": "Eco A+",
				"eco-aaa": "Eco A++"
			},
			"velocity": {
				"header": "Luftgeschwindigkeit",
				"velocity-0": "Auto",
				"velocity-1": "Niedrig",
				"velocity-2": "Mittel",
				"velocity-3": "Hoch",
				"velocity-mlp": "Geschwindigkeit"
			},
			"air_flow": {
				"header": "Dfluss-Steuerung",
				"air_flow-0": "Schweigen",
				"air_flow-1": "Standard",
				"air_flow-2": "Leistung"
			},
			"scene": {
				"header": "Benutzermodi",
				"confort": "Komfort",
				"eco": "Eco",
				"vacation": "Urlaub",
				"unocupied": "Unbewohnt",
				"night": "Nacht",
				"stop": "Stop"
			},
			"timer": {
				"header": "Zeitschalt",
				"timer-off": "0 minuten",
				"timer30": "30 minuten",
				"timer60": "60 minuten",
				"timer90": "90 minuten"
			},
			"consumption": {
				"header": "Verbrauchszähler"
			},
			"schedules": {
				"weekly": "Wochenpläne",
				"calendar": "Monatspläne"
			},
			"without_consumption_data": "Derzeit sind keine Verbrauchsdaten verfügbar. Versuchen Sie es später erneut. Die Daten werden stündlich aktualisiert.git s",
			"error0": "CCP Konfigurationsstorungen",
			"error1": "Kommunikationsstorungen CCP - Aerothermie Gateway",
			"error2": "Kommunikationsstorungen Aerothermie Gateway - Maschine",
			"error3": "Kommunikationsstorungen Verbrauchszähler",
			"error4": "Messfehler im Verbrauchszähler",
			"error5": "Fehler H2, entdeckt, Ventile und Steuerausgänge Verzögerung aktiviert. Um es zu deaktivieren, rufen Sie das erweiterte Systemkonfigurationsmenü (Schutzmodus) auf",
			"error6": "Konfigurationswarnung: Schutz und Steuerungsalgorithmus deaktiviert. Um diese Funktion zu aktivieren, lesen Sie die Gateway-Dokumentation",
			"error7": "Maschinenfehler:",
			"error8": "WBW aktiv",
			"error9": "Kommunikationsfehler Altherma-Gateway - Zentrale",
			"error10": "Kommunikationsfehler Altherma-Gateway - inneren Einheit"
		},
		"terms": {
			"accept": "Ich akzeptiere die ",
			"legal_terms": "Nutzungsbedingungen",
			"and": " und die ",
			"privacy": "Datenschutzerklärung",
			"publicity": "Ich möchte Werbung erhalten"
		},
		"device_relations_list": {
			"title": "Server",
			"no_servers_title": "Willkommen bei Airzone!",
			"no_servers_info": "Damit Sie mit der Steuerung Ihrer Anlage beginnen können, müssen Sie Ihrem Konto einen Server hinzufügen.",
			"remove_confirmation": "Möchten Sie diesen Server von Ihrem Konto löschen?",
			"removed_server": "Server wurde gelöscht",
			"internal_error": "Interner Fehler",
			"internal_error_message": "Ihre Aktion konnte nicht durchgeführt werden. Versuchen Sie es bitte später noch einmal",
			"actions": {
				"edit": "Bearbeiten",
				"config": "Konfiguration",
				"users": "Benutzer",
				"remove": "Löschen",
				"close": "Schließen",
				"accept": "Akzeptieren",
				"cancel": "Abbrechen",
				"confirmation_deleted": "Der Server wurde erfolgreich gelöscht",
				"add_server": "Server hinzufügen"
			}
		},
		"add_server": {
			"name_placeholder": "Name (Haus, Büro, Bungalow)",
			"mac_placeholder": "MAC (12:AB:34:CD:45:F0)",
			"pin_placeholder": "PIN",
			"location_placeholder": "Standort des Servers"
		},
		"add_user_to_device": {
			"title": "Benutzer hinzufügen",
			"advanced": "Erweitert",
			"basic": "Grundlegend"
		},
		"users": {
			"title": "Benutzer",
			"basic_user": "Benutzer für Grundlegend",
			"advanced_user": "Benutzer für Erweitert",
			"basic": "grundlegend",
			"advanced": "erweitert",
			"add_user": "Benutzer hinzufügen",
			"destroy": "Benutzer löschen",
			"remove_confirmation": "Sind Sie sicher, dass Sie den Zugriff des Benutzers auf den Server löschen möchten?",
			"zones": "Zonen mit Zugriff",
			"blank": "Keine Benutzer vorhanden."
		},
		"admin_users": {
			"confirmed": "Bestätigt",
			"results": "Ergebnisse",
			"no_results": "Keine Ergebnisse",
			"page": "Seite",
			"of": "von",
			"device_modal": {
				"title": "Zugängliche Einheiten durch den Benutzer",
				"no_devices": "Der Benutzer kann nicht auf eine AC-Einheit zugreifen",
				"location": "Ort"
			}
		},
		"zones": {
			"warnings": {
				"presence": "Präsenzalarm",
				"window": "Fensteralarm",
				"antifreeze": "Antifrost",
				"dew": "Tau aktiv",
				"dew_protection": "Tauschutz aktiv",
				"thermostat": "Zone ohne thermostat",
				"heat_supply_activated": "Hitzeunterstützung aktiviert",
				"low_valve_battery": "Batt. schwach Ventil",
				"valve_NTC_alarm": "NTC2-Alarm",
				"F05C": "Fehler F05-C: Temperatursonde der Kühlbatterien in offenem Stromkreislauf",
				"F05H": "Fehler F05-H: Temperatursonde der Heizbatterien in offenem Stromkreislauf",
				"F06C": "Fehler F06-C: Temperatursonde der Kühlbatterien in Kurzschluss",
				"F06H": "Fehler F06-H: Temperatursonde der Heizbatterien in Kurzschluss",
				"dehumidifier_activated": "Entfeuchter aktiviert"
			},
			"adjusts": {
				"title": "Zoneneinstellungen",
				"title_all": "Alle Zonen",
				"name": "Name",
				"name_system": "Name des Systems",
				"autoturnoff": "Sleep",
				"timer": "Zeitschaltuhr",
				"defrosting": "Antifrost",
				"local_ventilation": "Lokale Lüftung",
				"lite": "Lite-Einstellungen",
				"led": "LED-Status",
				"work_mode": "Stufen",
				"cool": "Kälte",
				"heat": "Wärme",
				"air_direction": "Luftrichtung",
				"lama_direction": "Lamellenrichtung",
				"horizontal": "Horizontal",
				"vertical": "Vertikal",
				"name_explanation": "Ermöglicht die Namensänderung der Zone",
				"autoturnoff_explanation": "Die Zone wird nach Ablauf des gewählten Zeitintervalls automatisch abgeschaltet. Jeweils im 30-Minuten-Intervall steigt die Temperatur um ein Grad in Kälte oder sinkt um ein Grad in Wärme.",
				"sleep_off": "AUS - Die Zeitschaltuhr wird deaktiviert",
				"sleep_30": "30 - Aktiviert die Zeitschaltuhr und die Zone wird nach 30 Minuten abgeschaltet.",
				"sleep_60": "60 - Aktiviert die Zeitschaltuhr und die Zone wird nach 60 Minuten abgeschaltet.",
				"sleep_90": "90 - Aktiviert die Zeitschaltuhr und die Zone wird nach 90 Minuten abgeschaltet.",
				"defrosting_explanation": "Wenn das System erkennt, dass die Raumtemperatur unter 12 °C beträgt, schaltet es automatisch den Wärmemodus ein.",
				"work_mode_explanation": "Ermöglicht die Auswahl der Methode, die zum Kühlen oder Heizen einer Zone verwendet werden soll, wenn die Anlage dies über Luft oder Fußbodenheizung ermöglicht:",
				"mode_air": "Luft - Nur über Luft",
				"mode_radiant": "Strahlung - Nur über Fußbodenheizung",
				"mode_mix": " - Verwendet eine Kombination von beiden",
				"air_direction_explanation": "Ermöglicht die Auswahl der Luftrichtung bei Kälte und Wärme.",
				"local_ventilation_explanation": "Diese Option ermöglicht die Aktivierung bzw. Deaktivierung der Lüftung in der ausgewählten Zone, wenn das System eine bestimmte Zone nicht aktiv kühlt oder beheizt.",
				"lite_explanation": "Ermöglicht das Konfigurieren der Lite-Thermostat-LED, so dass sie nach einigen Sekunden ausgeschaltet wird."
			},
			"schedules": {
				"title": "Programmierungen",
				"init": "Start",
				"end": "Ende",
				"days": "Tage",
				"monday": "Mo",
				"tuesday": "Di",
				"wednesday": "Mi",
				"thursday": "Do",
				"friday": "Fr",
				"saturday": "Sa",
				"sunday": "So",
				"add_schedule": "Programmierung hinzufügen",
				"schedules_types": "Programmierarten",
				"temp": "Temp.",
				"mode": "Modus",
				"vent": "Lüftung",
				"start_hour": "Startzeit",
				"end_hour": "Endzeit",
				"start_mode": "Startmodus",
				"end_mode": "Endmodus",
				"start_temp": "Temp. Start",
				"end_temp": "Temp. Ende",
				"start_vent": "Lüft. Start",
				"end_vent": "Lüft. Ende",
				"close": "Schließen",
				"remove": "Löschen",
				"save": "Speichern",
				"enable": "Aktivieren",
				"hour": "Uhrzeit",
				"temperature": "Temperatur",
				"velocity": "Geschwindigkeit",
				"vel_0": "Automatisch",
				"vel_1": "Geschwindigkeit 1",
				"vel_2": "Geschwindigkeit 2",
				"vel_3": "Geschwindigkeit 3",
				"new_prog": "Neue Programmierung",
				"edit_prog": "Programmierung bearbeiten",
				"schedules_name": "Name",
				"remove_confirmation": "Sind Sie sicher, dass Sie die Programmierung endgültig löschen möchten?",
				"all_zones": "Alle Zonen",
				"systems": "systeme",
				"waiting_title": "Programmierungen werden angelegt",
				"waiting_text": "Derzeit werden die Programmierungen angelegt. Dieser Vorgang kann einige Sekunden dauern, bis sie in allen Zonen angelegt sind.",
				"save_confirmation": "Möchten Sie die Programmierung speichern?",
				"edit_confirmation": "Möchten Sie die Änderungen speichern?",
				"invalid_dates_range": "Das Programm kann für den ausgewählten Datumsbereich und Zone nicht gespeichert werden"
			}
		}
	}
};