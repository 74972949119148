Airzone.controller('serverUsersController', ['$scope', '$rootScope', '$state', '$stateParams', 'deviceService', 'systemService', 'sessionService', 'operatingSystemService', '$window',
  function ($scope, $rootScope, $state, $stateParams, deviceService, systemService, sessionService, operatingSystemService, $window) {
    $scope.loader_list = true;
    $scope.loader_permissions = false; //TODO change
    $scope.partial = 'list'; //list, permissions
    $scope.device_relation_id = $stateParams.device_relation_id;
    $scope.device_relations_basics = [];
    $scope.device_relations_advanced = [];
    $scope.messageServer = '';

    $scope.systems = [];
    $scope.zones_permissions = {};
    $scope.systems_permissions = {};

    initialize();

    function initialize() {
      $scope.partial = 'list';
      $scope.loader_list = true;

      sessionService.user().then(
        function (data) {
          $scope.user = data.user;
        }, function (err) {
          sessionService.unauthorize();
        }
      );

      deviceService.getDeviceRelation($scope.device_relation_id).then(function (data) {
        // owner device relation
        $scope.device_relation = data;

        // get other basic users of server
        deviceService.getDeviceRelations($scope.device_relation.device.id, true, null, 100).then(function (data) {
          $scope.device_relations = data.device_relations;
          $scope.device_relations_basics = $scope.device_relations.filter(
            function (x) { return x.type == 'basic' });
          $scope.device_relations_advanced = $scope.device_relations.filter(
            function (x) { return (x.type == 'advanced' && !x.hide_invitation) });
          $scope.loader_list = false;

          // Show permissions for new device_relation created (new user added)
          if ($stateParams.permission_relation_id) {
            var dr = $scope.device_relations_basics.filter(function (x) {
              return x.id == $stateParams.permission_relation_id
            })[0];

            $scope.show_permissions(dr);
          }
        });
      });
    };

    /*
     * Show the permissions of an device relation
     */
    $scope.show_permissions = function (dr) {
      $scope.show_last_permission_message = false;
      $scope.dr_selected = dr;
      $scope.partial = 'permissions';

      systemService.getSystemsWithZones(dr.device.id, false).then(function (systems) {
        $scope.systems = systems;

        // Get system and zones permissions
        $scope.systems.forEach(function (system) {
          var all_permitted = false;

          system.zones.forEach(function (zone) {
            if ($scope.dr_selected.permitted_zones.indexOf(zone.id) > -1) {
              $scope.zones_permissions[zone.id] = true;
              all_permitted = true;
            }
            else {
              $scope.zones_permissions[zone.id] = false;
            }
          });

          $scope.systems_permissions[system.id] = all_permitted;
        });
      });
    };

    /*
     * Show remove confirmation
     */
    $scope.removeDeviceRelationConfirmation = function () {
      $scope.messageServer = 'deleteServerConfirm';
    };

    /*
     * Remove the device relation
     */
    $scope.removeDeviceRelation = function () {
      return deviceService.deleteDeviceRelation($scope.dr_selected).then(function (data) {
        $scope.messageServer = '';
        if ($stateParams.permission_relation_id)
          $state.go('home.users', { device_relation_id: $scope.device_relation.id }, { location: 'replace', inherit: false });
        else
          initialize();
      }, function (data) {
        if (data === "User migrated.") {
          console.log('Invitation of migrated user');
          let type = operatingSystemService.detectOperatingSystem();
          $scope.show_android = type.android;
          $scope.show_ios = type.ios;
          $scope.user_migrated_invitation = true;
        }
        $scope.messageServer = '';
      });
    };

    /*
     * Toggle zone permition (true => false or false => true)
     */
    $scope.toggleZonePermission = function (zone_id) {
      var last_permission = $scope.zones_permissions.length;
      var enable_count = 0;
      var disable_count = 0;
      $scope.show_last_permission_message = false;

      $scope.systems.forEach(function (system) {
        system.zones.forEach(function (zone) {
          if ($scope.zones_permissions[zone.id] == true) {
            enable_count += 1;
          } else {
            disable_count += 1;
          }
        });
      });

      if (enable_count == 1 && $scope.zones_permissions[zone_id] == true) {
        for (var keyName in $scope.systems_permissions) {
          var key = keyName;
        }

        $scope.systems_permissions[key] = !$scope.systems_permissions[key];
        $scope.show_last_permission_message = true;
      } else {
        $scope.setZonePermission(zone_id, !$scope.zones_permissions[zone_id]);
      }

    };

    /*
     * Set zone permission
     */
    $scope.setZonePermission = function (zone_id, permission) {
      var oldPermission = $scope.zones_permissions[zone_id];
      $scope.zones_permissions[zone_id] = permission;

      if ($scope.zones_permissions[zone_id]) {
        $scope.dr_selected.permitted_zones.push(zone_id);
      }
      else {
        var index = $scope.dr_selected.permitted_zones.indexOf(zone_id);
        if (index > -1) {
          $scope.dr_selected.permitted_zones.splice(index, 1);
        }
      }

      $scope.show_permissions($scope.dr_selected)

      deviceService.updateDeviceRelation($scope.dr_selected).then(function (data) {
      }, function (data) {
        if (data === "User migrated.") {
          console.log('Invitation of migrated user');
          let type = operatingSystemService.detectOperatingSystem();
          $scope.show_android = type.android;
          $scope.show_ios = type.ios;
          $scope.user_migrated_invitation = true;
          $scope.zones_permissions[zone_id] = oldPermission;
        }
        $scope.messageServer = '';
      });
    };

    /*
     * Toggle system permission (true => false or false => true)
     * and set all system zones permissions to system permission
     */
    $scope.toggleSystemPermission = function (system) {
      var last_permission = $scope.systems_permissions.length;
      var enable_count = 0;
      var disable_count = 0;
      $scope.show_last_permission_message = false;

      $scope.systems.forEach(function (sys) {
        if ($scope.systems_permissions[sys.id] == true) {
          enable_count += 1;
        } else {
          disable_count += 1;
        }
      });

      if (enable_count == 1 && $scope.systems_permissions[system.id] == true) {
        $scope.show_last_permission_message = true;
      } else {
        $scope.systems_permissions[system.id] = !$scope.systems_permissions[system.id];

        system.zones.forEach(function (zone) {
          $scope.setZonePermission(zone.id, $scope.systems_permissions[system.id]);
        });
      }
    };

    /*
     * Return to the list
     */
    $scope.returnToList = function () {
      if ($stateParams.permission_relation_id) {
        $state.go('home.users',
          { device_relation_id: $scope.device_relation_id },
          { location: 'replace', inherit: false });
      }
      else {
        $scope.dr_selected = null;
        $scope.partial = 'list';
      }
    };

    // Give if is new user
    $scope.isNewUser = function () {
      return ($stateParams.permission_relation_id);
    }

    /*
* Open Itunnes Apple
*/
$scope.openItunesApple = function () {
  $window.open(operatingSystemService.goToNewItunesApple(), '_system', 'location=no');
}

/*
 * Open Itunnes Apple
 */
$scope.openPlayGoogle = function () {
  $window.open(operatingSystemService.goToNewPlayGoogle(), '_system', 'location=no');
}

/*
 * Recharge the page when click
 */
$scope.closeModal = function () {
  $scope.user_migrated_invitation = false;
}
  }]);
