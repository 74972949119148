Airzone.controller('notConnectionModalController', ['$scope', '$rootScope', '$window',
  function ($scope, $rootScope, $window) {

    // Variable to manage the modal of internet
    $rootScope.disconnection = false;

    /*
     * Translate strint 't'
     * Solved bug (can't call $rootScope directly)
     */
    $scope.i18n = function (t) {
      return $rootScope.i18n(t);
    };

    /*
     * Recharge the page when click
     */
    $scope.refreshPage = function () {
      $window.location.reload();
    }

  }]);
