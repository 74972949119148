angular.module("filters", []).filter("location", function() {
  return function(string, level) {
    if (string != null) {
      var array = string.split(",");

      if (array.length > 1) {
        if (level === "city") {
          return array[0];
        }

        if (level == "country") {
          var lastItem = array.length - 1;
          return array[lastItem];
        }

        if (level === "lessCity") {
          array.splice(0, 1);
          return "(" + array.join(", ") + ")";
        }
      }
    }

    return "";
  };
});

angular.module("filters").filter("units", function() {
  return function(input, unit) {

    input = parseFloat(input);
    if (!isNaN(input)) {
      if (unit !== undefined && unit === 1) {
        //fah
        return input.toFixed();
      } else {
        //ºC
        return input.toFixed(1);
      }
    }
  };
});



angular.module("filters").filter('toArray', function() { return function(obj) {
  if (!(obj instanceof Object)) return obj;
  return obj.map(function(val, key) {
      return Object.defineProperty(val, '$key', {__proto__: null, value: key});
  });
}});
