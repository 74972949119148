Airzone.constant('STATS_URL', logStatsUrl);
Airzone.constant('DEMO_EMAIL', "demo@email.com");
Airzone.constant('MOBILE_APP', isMobileApp);
Airzone.constant('DEFAULT_CONSIGN_SERVER', '--');
Airzone.constant('URL_PATH', url_path);
Airzone.constant('WEBSOCKETS_PUSHER_URL', pusherUrl);
Airzone.constant('WEBSOCKETS_PUSHER_PORT', pusherPort);
Airzone.constant('GOOGLE_MAPS_KEY', googleMapsKey);
Airzone.constant('VERSION_APP', '3.4.0.17-dev');
Airzone.constant('GOOGLE_PLAY', googlePlay);
Airzone.constant('ITUNES_APPLE', itunesApple);

Airzone.config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {
  // For any unmatched url, redirect to /state1
  if( __eolo ) {
    $urlRouterProvider.otherwise( "/splash" );
  }
  else {
    $urlRouterProvider.otherwise( "/home/servers" );
  }

  // Now set up the states
  $stateProvider
    .state('init', {
      abstract: true,
      url: '/init',
      templateUrl: template_path + 'init.html',
      controller: 'initController'
    })
    .state('splash', {
      url: '/splash',
      templateUrl: template_path + 'splash.html',
      resolve: {
        checkVersion: ['$http', '$state', '$rootScope', 'VERSION_APP', function($http, $state, $rootScope, VERSION_APP){
          if (isMobileApp){
            var version_app_string = String(VERSION_APP).replace(/\./g, '');
            return $http.get(check_status_url_path + "check_status/airzone?version=" + version_app_string).catch(function(response){
              if(response  && response.status == 503){
                $state.go('init.maintenance');
              }
              else if (response && response.status == 403){
                $state.go('init.update_app');
              }
            });
          }else{
            return true;
          }
        }]
      },
      data: { login: true },
      controller: function( $state, $scope, VERSION_APP ) {
        $scope.version_app = String(VERSION_APP);
        setTimeout( function(){
          $state.go( "home.servers" );
        }, 2000 );
      }
    })
    .state('installation', {
      url: '/installation',
      templateUrl: template_path + 'installation.html',
      controller: 'installationController'
    })
    .state('init.worldTourDemo', {
      url: '/world_tour_demo',
      resolve: {
        initSubmitDemoForm : ['$http', function ($http){
          return $http({
              method: 'POST',
              url: url_path + 'users/sign_in',
              data: {
                email: 'world_tour_demo@email.com',
                password: 'airzonecloud'
              }
            });
        }]},
      controller: 'demoController'
    })
    .state('init.update_app', {
      url: '/update_app',
      templateUrl: template_path + 'update_app.html'
    })
    .state('init.maintenance', {
      url: '/maintenance',
      templateUrl: template_path + 'maintenance.html'
    })
    .state('init.login', {
      url: '/login',
      templateUrl: template_path + 'login.html',
      controller: 'loginController'
    })
    .state('init.register', {
      url: "/register?info_hide",
      templateUrl: template_path + "register.html",
      controller: "registerController"
    })
    .state('init.complete', {
      url: "/complete_registration?reset_password_token&email&name&surname",
      templateUrl: template_path + "complete_registration.html",
      controller: "completeRegistrationController"
    })
    .state('init.password', {
      url: "/password?reset_password_token",
      templateUrl: template_path + 'password.html',
      controller: 'passwordController'
    })
    .state('init.confirmation', {
      url: "/confirmation?invalid_token",
      templateUrl: template_path + 'confirmation.html',
      controller: 'confirmationController'
    })
    .state('home', {
      abstract: true,
      url: "/home",
      templateUrl: template_path + 'home.html',
      controller: 'homeController',
      resolve : {
        checkLogin: ['sessionService', '$q', 'websocketsService', '$rootScope', '$state', function(sessionService, $q, websocketsService, $rootScope, $state){
// //          if (!isMobileApp){
//             return sessionService.user().then(function(){
//               // Init websockets
//               //websocketsService.init();
//             }, function(err){
//               console.log("ERR:",err)
//               return $q.reject();
//             })
// //          }else{
// //            return true;
// //          }          
        }]
      }
    })
    .state('home.servers', {
      url: "/servers",
      templateUrl: template_path + 'user_servers.html',
      controller: 'userServerController'
    })
    .state('home.addUserServer', {
      url: "/add_user_server",
      templateUrl: template_path + 'add_user_server.html',
      controller: 'addServerController'
    })
    .state('home.editUserServer', {
      url: "/edit_user_server?id",
      templateUrl: template_path + 'edit_user_server.html',
      controller: 'editServerController'
    })
    .state('home.adjust', {
      url: "/adjust",
      templateUrl: template_path + 'adjust.html',
      controller: 'adjustController'
    })
    .state('home.users', {
      url: "/users?device_relation_id&permission_relation_id",
      templateUrl: template_path + 'server_users.html',
      controller: 'serverUsersController'
    })
    .state('home.addUserToDevice', {
      url: "/add_user_to_device?device_relation_id",
      templateUrl: template_path + 'add_user_to_device.html',
      controller: 'addUserToDeviceController'
    })
    .state('home.server', {
      url: "/server?rel",
      templateUrl: template_path + 'server.html',
      controller: 'serverController'
    })
    .state('home.zone', {
      url: "/zone?id&rel",
      templateUrl: template_path + 'zone.html',
      controller: 'zoneController',
      onEnter: ['$rootScope', function($rootScope){
        // Hide menu button from home
        $rootScope.zonaView = true;
      }],
      onExit: ['$rootScope', function($rootScope){
        // Hide menu button from home
        $rootScope.zonaView = false;
      }]
    })
    .state('home.zoneAltherma', {
      url: "/zone_altherma?id&rel",
      templateUrl: template_path + 'zone_altherma.html',
      controller: 'zoneAlthermaController',
      onEnter: ['$rootScope', function($rootScope){
        // Hide menu button from home
        $rootScope.zonaView = true;
      }],
      onExit: ['$rootScope', function($rootScope){
        // Hide menu button from home
        $rootScope.zonaView = false;
      }]
    })
    .state('home.help', {
      url: "/help",
      templateUrl: template_path + 'help.html'
    })
    .state('home.userAdvancedAdjust', {
      url: "/advanced_adjust?device_relation_id",
      templateUrl: template_path + 'user_advanced_adjust.html',
      controller: 'userAdvancedAdjustController'
    })
    .state('init.browsers', {
      url: '/browsers',
      templateUrl: template_path + 'unsupported_browser.html'
    })
    .state('init.403', {
      url: '/403',
      templateUrl: template_path + '403.html'
    })
    .state('init.404', {
      url: '/404',
      templateUrl: template_path + '404.html'
    });

  // Web version routes
  if( !__eolo ) {
    $stateProvider
    .state('home.addServer', {
      url: "/admin_add_server",
      templateUrl: template_path + 'admin_add_server.html',
      controller: 'adminAddServerController'
    })
    .state('home.adminAdjust', {
      url: "/admin_adjust",
      templateUrl: template_path + 'admin_adjust.html',
      controller: 'adminAdjustController'
    })
    .state('home.admin', {
      url: "/admin_servers",
      templateUrl: template_path + 'admin_servers.html',
      controller: 'adminListController'
    })
    .state('home.adminUsers', {
      url: "/admin_users",
      templateUrl: template_path + 'admin_users.html',
      controller: 'adminUsersController'
    })
    .state('home.adminConnections', {
      url: "/admin_connections",
      templateUrl: template_path + 'admin_connections.html',
      controller: 'adminConnectionsController'
    })
    .state('home.map', {
      url: "/admin_map",
      templateUrl: template_path + 'admin_map.html',
      controller: 'adminMapController'
    });
  }
}]);

Airzone.config(['localStorageServiceProvider', function (localStorageServiceProvider) {
  localStorageServiceProvider
    .setPrefix('Airzone');
}]);

// Resolve 406 Http status in http request
Airzone.config(['$httpProvider', function($httpProvider) {
  $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';

  if( __eolo ) {
    $httpProvider.interceptors.push( __eolo.interceptor );
  }
}]);
