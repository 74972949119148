Airzone.controller('zoneAlthermaController',
  ['$scope', '$rootScope', 'deviceService', 'systemService', 'zoneService', '$stateParams', '$state', 'eventsService', '$timeout', 'websocketsService',
    function ($scope, $rootScope, deviceService, systemService, zoneService, $stateParams, $state, eventsService, $timeout, websocketsService) {

      // https://github.com/revolunet/angular-carousel
      // Plugin del carousel utilizado para la temperatura

      // Definimos los rangos de temperatura
      $scope.minTemp;
      $scope.maxTemp;

      // Carrusel
      $scope.listTemp = [30];
      $scope.tempIndex = -1;

      // show zone from navigation
      $rootScope.zone_from_navigation = false;
      $rootScope.showMenuBtn = false;
      $scope.update_timeout;

      initialize();

      /*
       * Check if altherma data exists else request datas
       */
      function initialize() {
        // Get zone
        if (!$rootScope.altherma) {
          console.log("$stateParams.id: ", $stateParams.id);
          systemService.getSystem($stateParams.id).then(function (system) {
            $rootScope.altherma = system;
            loadViewZone();
          })
        } else {
          loadViewZone();
        }
      }

      /*
       * Create websockets, poolling and load temperatures
       */
      function loadViewZone() {
        $scope.zone = $rootScope.altherma;
        $scope.zone.lock_carousel = false;
        $scope.zone.updated = true;
        // Create websocket channel for server
        websocketsService.createServerChannel($scope.zone.device_id, websocketCallback);
        if ($rootScope.zone_from_navigation == false && !$rootScope.demo) {
          eventsService.createEvent('infosistema', $scope.zone, null, null);
        }

        // load temp list
        loadListTemp();

        // Check powerful, if it's activated then lock carousel
        if ($scope.zone.powerful) {
          lockCarousel();
        }

        // init auto updated
        timeoutUpdate();
      }
      /*
       * Función que se ejecuta cada vez que cambia la temperatura
       */
      $scope.listener1 = $scope.$watch('tempIndex', function (new_val, old_val, scope) {
        if (new_val != null && old_val != null && new_val != old_val) {
          // prevent first time when reload page
          if (old_val != -1)
            $scope.zone.reject = true;

          if ($scope.zone && $scope.zone.setpoint != $scope.listTemp[$scope.tempIndex]) {
            $scope.zone.setpoint = $scope.listTemp[$scope.tempIndex];

            if (!$rootScope.demo) {
              eventsService.createEvent('modsistema', $scope.zone, 'setpoint', $scope.zone.setpoint);
            }
          }
        }
      })

      function websocketCallback(zone) {
        if (zone !== null && zone.id === $scope.zone.id) {

          Object.keys(zone).forEach(function (key) {
            $scope.zone[key] = zone[key];
          });

          updateTempIndex();
          $scope.zone.updated = true;
          $scope.zone.last_field = false;
          $scope.$apply();
        }
      }

      /*
       * Turn on/off powerful of altherma
       */
      $scope.togglePowerful = function () {
        $scope.zone.powerful = !$scope.zone.powerful;
        if ($rootScope.demo) {
          if ($scope.zone.powerful) {
            $scope.tempIndex = $scope.listTemp.indexOf($scope.zone.acs_max);
            lockCarousel();
          } else {
            $scope.zone.lock_carousel = false;
            $timeout(function () {
              $scope.tempIndex = $scope.listTemp.indexOf('45');
            }, 350)
          }
        }

        if (!$rootScope.demo) {
          eventsService.createEvent('modsistema', $scope.zone, 'powerful', String(Number($scope.zone.powerful)));
        }
      };

      /*
       * Listen websockets and update carrusel when setpoint of altherma change
       */
      $scope.listener2 = $scope.$watch('zone.setpoint', function (new_val, old_val, scope) {
        if (new_val !== old_val && $scope.tempIndex !== $scope.listTemp.indexOf(new_val)) {
          $scope.zone.lock_carousel = false;
          // Wait 300 ms until carousel es unlocked, so change temperature
          $timeout(function () {
            $scope.tempIndex = $scope.listTemp.indexOf(new_val);
            if ($scope.zone.powerful) {
              lockCarousel();
            }
          }, 300)
        }
      });

      /*
       * Stop update zone when modal is open
       * Return update when modal is closed
       */
      $scope.listener3 = $rootScope.$watch('modalShow', function (new_val, old_val, scope) {
        if (new_val && !old_val) {
          $scope.zone.reject = true;
          clearTimeout($scope.update_timeout);
        }
        else if (!new_val && old_val) {
          $scope.zone.reject = false;
          timeoutUpdate();
        }
      });

      /*
       * Get the tank temperature
       */
      $scope.getTempTank = function () {
        if ($scope.zone && $scope.zone.tank_temp)
          return $scope.zone.tank_temp;
      }

      /*
       * Activate or deactivate zone
       */
      $scope.activateZone = function () {
        if (!$scope.zone.acs_power_readonly){
          $scope.zone.power_acs = !$scope.zone.power_acs;

          if (!$rootScope.demo) {
            // Turn off the powerful if it is turned on and turn off the power acs
            if (!$scope.zone.power_acs && $scope.zone.powerful) {
              $scope.zone.powerful = !$scope.zone.powerful;
              eventsService.createEvent('modsistema', $scope.zone, 'powerful', '0');
            }
            $timeout(function () {
              eventsService.createEvent('modsistema', $scope.zone, 'power_acs', String(Number($scope.zone.power_acs)));
            }, 200);
          }
        }
      };

      /*
       * Return to servers page
       */
      $scope.goBack = function () {
        $state.go('home.server', { rel: $stateParams.rel });
      };

      /*
       * Open config altherma modal Schedules
       */
      $scope.showSchedulesAlthermaModal = function () {
        $rootScope.altherma_schedules_target = $scope.zone;
      }

      /*
       * Remove timeouts
       */
      $scope.$on('$destroy', function () {
        clearTimeout($scope.update_timeout);

        $scope.listener1();
        $scope.listener2();
        $scope.listener3();
      });

      /*
       * Create event for update zone with websockets
       */
      function timeoutUpdate() {
        $scope.update_timeout = setTimeout(function () {
          eventsService.createEvent('infosistema', $scope.zone, null, null);
          timeoutUpdate();
        }, 15000);
      }

      /*
       * Lock carousel after 350 ms, because the duration of the transition is 300 ms
       */
      function lockCarousel() {
        $timeout(function () {
          $scope.zone.lock_carousel = true;
        }, 350)
      }

      /*
       * Generator list temperature
       */
      function generatorListTemp(min, max) {
        var arrayTemp = [];

        for (var i = min; i <= max; i += 1) {
          arrayTemp.push(String(i));
        }

        return arrayTemp;
      }

      function updateTempIndex() {
        if ($scope.zone.temperature_unit == "1")
          var index = $scope.listTemp.indexOf(parseInt($scope.zone.setpoint));
        else
          index = $scope.listTemp.indexOf($scope.zone.setpoint);

        if ($scope.tempIndex !== index) {
          $scope.tempIndex = index;
        }

        if ($scope.tempIndex < 0)
          $scope.tempIndex = 0;

        $scope.show_carrusel = true;
        $rootScope.show_carrusel = true;
      }

      /*
       * Load carrusel
       */
      function loadListTemp() {
        var minTemp = Number($scope.zone.acs_min);
        var maxTemp = Number($scope.zone.acs_max);

        $scope.listTemp = generatorListTemp(minTemp, maxTemp);
        $rootScope.listTemp = $scope.listTemp;
        updateTempIndex();
      }
    }]);
