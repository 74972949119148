Airzone.controller('addServerController', ['$scope', '$http', 'sessionService', '$state', 'MOBILE_APP', '$injector', 'deviceService',
  function ($scope, $http, sessionService, $state, MOBILE_APP, $injector, deviceService) {
    // Flag to show or hide error messages
    $scope.show_error = false;
    $scope.show_success = false;
    $scope.taken_error = false;
    $scope.mac_error = false;
    $scope.pin_error = false;
    $scope.server = {};
    $scope.server.sync_datetime = true;
    $scope.messageServer = false;
    // Inject the recaptcha service in web app
    if (!MOBILE_APP) {
      var vcRecaptchaService = $injector.get('vcRecaptchaService');
    }

    // Prevent bottom space when keyboard hides
    $scope.scrollToTop = function($event){
      window.scrollTo(0, 0);
    }

    /*
     * POST register request to server and proccess response
     */
    $scope.submitAddServerForm = function () {
      if (!$scope.server.spot_location) {
        $scope.addServerForm.$valid = false;
        $scope.addServerForm.invalid = true;
        $scope.addServerForm.location.$invalid = true;
        $scope.addServerForm.location.$error.required = true;
      }
      if ($scope.addServerForm.$valid) {
        if (!MOBILE_APP) {
          var body_params = {
            device_relation: {
              mac: $scope.server.mac,
              pin: $scope.server.pin,
              user_id: sessionService.id(),
              type: 'advanced',
              gRecaptchaResponse: $scope.server.gRecaptchaResponse
            },
            user_email: sessionService.email(),
            user_token: sessionService.token()
          }
        } else {
          body_params = {
            device_relation: {
              mac: $scope.server.mac,
              pin: $scope.server.pin,
              user_id: sessionService.id(),
              type: 'advanced'
            },
            user_email: sessionService.email(),
            user_token: sessionService.token()
          }
        }
        $http({
          method: 'POST',
          url: url_path + 'device_relations',
          responseType: 'json',
          data: body_params,
        }).success(function (data, status, headers, config) {
          console.log(data);
          $scope.device_relation = data.device_relation;
          $http({
            method: 'PUT',
            url: url_path + 'devices/' + data.device_relation.device.id,
            responseType: 'json',
            data: {
              device: {
                name: $scope.server.name,
                icon: $scope.server.icon,
                sync_datetime: $scope.server.sync_datetime,
                spot_location: $scope.server.spot_location,
                spot_complete_name: $scope.server.spot_complete_name,
                spot_google_id: $scope.server.spot_google_id,
                spot_google_reference: $scope.server.spot_google_reference,
                spot_country_code: $scope.server.spot_country_code
              },
              user_email: sessionService.email(),
              user_token: sessionService.token(),
            }
          }).success(function (data, status, headers, config) {
            $scope.show_success = true;
          }).error(function (data, status, headers, config) {
            serviceUnavailable();
          });
        }).error(function (data, status, headers, config) {
          $scope.addServerForm.invalid = true;

          // Logout when unauthorized
          if (status == 401) {
            sessionService.unauthorize();
          } else if (status == 403) {
            $state.go('init.403');
          } else if (status == 404) {
            $state.go('init.404');
          } else if (status == 433) {
            $scope.addServerForm.$error.recaptcha_response = true;
          } else {
            $scope.show_error = true;
            if (!MOBILE_APP) {
              vcRecaptchaService.reload();
            }
            if (data['errors'].device_id) {
              if (data['errors'].device_id.indexOf('taken') > -1) {
                $scope.taken_error = true;
              }
              else if (data['errors'].device_id.indexOf('sleeping') > -1) {
                $scope.sleeping_error = true;
              }
            }
            else if (data['errors'].mac) {
              $scope.mac_error = true;
            }
            else if (data['errors'].pin) {
              $scope.pin_error = true;
            }
            else if (data['errors'].user_id && data['errors'].user_id.indexOf('taken') > -1) {
              $scope.taken_error = true;
            }
          }
        });
      } else {
        $scope.addServerForm.invalid = true;
        if (!MOBILE_APP) {
          vcRecaptchaService.reload();
        }
      }
    };

    /*
     * Pattern function
     */
    $scope.macPattern = function (e) {
      var r = /([a-f0-9]{2})([a-f0-9]{2})/i,
        str = e.target.value.replace(/[^a-f0-9]/ig, "");

      while (r.test(str)) {
        str = str.replace(r, '$1' + ':' + '$2');
      }

      e.target.value = (str.slice(0, 17)).toUpperCase();
      $scope.server.mac = e.target.value;
    };

    /*
     * Hide error message and show form
     */
    $scope.acceptError = function () {
      $scope.show_error = false;
      $scope.taken_error = false;
      $scope.mac_error = false;
      $scope.pin_error = false;
      $scope.sleeping_error = false;
    };

    function serviceUnavailable(){
      deviceService.deleteDeviceRelation($scope.device_relation).then(function (data) {
        $scope.messageServer = 'errorServer';
        if (!MOBILE_APP) {
          vcRecaptchaService.reload();
        }
      }, function (data) {
        console.log("Error: ", data);
      });
    }
  }]);
