Airzone.directive('zoneRow', function () {
  return {
    restrict: 'E',
    scope: {
      zone: '=zone',
      system: '=system',
      relation: '=relation'
    },
    templateUrl: template_path + 'zone_row.html',
    controller: 'zoneRowController'
  };
});
