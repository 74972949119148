Airzone.controller('modalAdjustZoneController',
  ['$scope', '$rootScope', 'zoneService', 'eventsService', 'SystemUtilsService',
    function ($scope, $rootScope, zoneService, eventsService, SystemUtilsService) {

      $scope.showHelp = false;
      $scope.config = null;
      $scope.masterVar = null;
      $scope.basicModeVar = null;
      $scope.showHeatTypeConfig = null;
      $scope.heatTypeConfigVar = null;
      $scope.showColdTypeConfig = null;
      $scope.coldTypeConfigVar = null;
      $scope.manufacturerShow = null;
      $scope.windowVar = null;
      $scope.showWindow = null;
      $scope.presenceVar = null;
      $scope.showPresence = null;
      $scope.ecoFunctionVar = null;
      $scope.showEcoFunctionVar = null;
      $scope.coldConstantVentilationVar = null;
      $scope.heatConstantVentilationVar = null;
      $scope.sprayDewVar = null;
      $scope.showSprayDew = null;
      $scope.localVentVar = null;
      $scope.showLocalVent = null;
      $scope.offset_environment_cold = null;
      $scope.offset_environment_heat = null;

      $scope.old_coldTypeConfig = '1';
      $scope.available_scenes = ["confort", "eco", "unocupied", "vacation"];
      $scope.available_cold_control_stage = ["0", "1"];
      $scope.available_heat_control_stage = ["0", "1"];

      var timeouts = {
        master_timeout: null,
        basic_mode_timeout: null,
        heat_type_config_timeout: null,
        cold_type_config_timeout: null,
        ventilation_timeout: null,
        manufacturer_timeout: null,
        q_weight_timeout: null,
        window_timeout: null,
        presence_timeout: null,
        eco_function_timeout: null,
        cold_constant_ventilation_timeout: null,
        heat_constant_ventilation_timeout: null,
        v_min_module_010_timeout: null,
        v_max_module_010_timeout: null,
        spray_dew_timeout: null,
        local_vent_timeout: null,
        user_mode_setpoint_timeout: null,
        automatic_weight_timeout: null,
        offset_environment_timeout: null,
        control_stage_timeout: null
      }

      /*
       * Open modal
       */
      $scope.listener1 = $rootScope.$watch('zone_adjust_target', function (new_target, old_target, scope) {
        if (new_target) {
          $scope.zone = new_target;
          $scope.show = true;
          $rootScope.modalShow = true;

          // Get last zone info configuration (websockets)
          if (parseInt($scope.zone.zone_number) == 127) {
            $scope.config = {
              basic_mode: "1",
              cold_type_config: "1",
              cold_values: "3",
              heat_type_config: "1",
              heat_values: "3",
              presence: "0",
              spray_dew: "0",
              window: "0",
              zone_number: "127"
            };

            initialize();
          }
          else {
            eventsService.createEvent('infoconfzona', $scope.zone, null, null);
            $rootScope.last_zone_number = $scope.zone.zone_number;
          }

          $scope.listener_error = $rootScope.$watch('error_device', function (new_val, old_val, scope) {
            if (new_val) {
              $scope.close();
            }
          });
        }
      });

      /*
       * Prevent websocket update
       */
      $scope.listener2 = $rootScope.$watch('zone_config', function (new_val, old_val, scope) {
        if (new_val && $rootScope.zone_adjust_target) {
          $scope.config = new_val;
          initialize();
        }
      });

      function initialize() {
        // Check firmware version
        if (parseFloat($scope.config.firm_ws) >= 3.17) {
          $scope.firmws317 = true;
        }
        // Master
        if ($scope.config.master)
          $scope.masterVar = ($scope.config.master == '1');

        //Basic mode
        $scope.basicModeVar = ($scope.config.basic_mode == '1');

        //Heat type config
        $scope.showHeatTypeConfig = false;
        if ($scope.config.VAF_coldstage && $scope.config.VAF_heatstage) {
          $scope.heatTypeConfigVar = ($scope.config.heat_type_config != '0' && $scope.config.heat_type_config != '2');
        }else{
          $scope.heatTypeConfigVar = ($scope.config.heat_type_config != '0');
        }
        if (!$scope.heatTypeConfigVar) $scope.old_heatTypeConfig = $scope.config.heat_type_config;

        //Cold type config
        $scope.showColdTypeConfig = false;
        if ($scope.config.VAF_coldstage && $scope.config.VAF_heatstage) {
          $scope.coldTypeConfigVar = ($scope.config.cold_type_config != '0' && $scope.config.cold_type_config != '2');
        }else{
          $scope.coldTypeConfigVar = ($scope.config.cold_type_config != '0');
        }
        if (!$scope.coldTypeConfigVar) $scope.old_coldTypeConfig = $scope.config.cold_type_config;

        //Heat check only read (disabled button)
        if ($scope.config.heat_values == '0') {
          $scope.disabled_heat_type_config = true;
        } else if (parseInt($scope.config.heat_values) == 1 || parseInt($scope.config.heat_values) == 2 || parseInt($scope.config.heat_values) == 3) {
          $scope.old_heatTypeConfig = '1';
          $scope.disabled_heat_type_config = false;
        } else if (parseInt($scope.config.heat_values) == 4) {
          $scope.old_heatTypeConfig = '2';
          $scope.disabled_heat_type_config = false;
        }

        //Cold check only read (disabled button)
        if ($scope.config.cold_values == '0') {
          $scope.disabled_cold_type_config = true;
        } else if (parseInt($scope.config.cold_values) == 1 || parseInt($scope.config.cold_values) == 2 || parseInt($scope.config.cold_values) == 3) {
          $scope.old_coldTypeConfig = '1';
          $scope.disabled_cold_type_config = false;
        } else if (parseInt($scope.config.cold_values) == 4) {
          $scope.old_coldTypeConfig = '2';
          $scope.disabled_cold_type_config = false;
        }


        //Window
        $scope.showWindow = false;
        $scope.windowVar = ($scope.config.window != '0');

        //Presence
        $scope.showPresence = false;
        $scope.presenceVar = ($scope.config.presence != '0');

        //Function Eco
        $scope.ecoFunctionVar = ($scope.config.eco_function != '0');

        //Cold Constant Ventilation
        $scope.show_cold_constant_ventilation = false;
        if ($scope.config.cold_constant_ventilation != null) {
          $scope.coldConstantVentilationVar = $scope.config.cold_constant_ventilation === '1' ? true : false;
        }

        //Heat Constant Ventilation
        if ($scope.config.heat_constant_ventilation != null) {
          $scope.heatConstantVentilationVar = $scope.config.heat_constant_ventilation === '1' ? true : false;
        }

        //Spray Dew
        $scope.showSprayDew = false;
        $scope.sprayDewVar = ($scope.config.spray_dew != '0');

        //Local Vent
        $scope.showLocalVent = false;
        $scope.localVentVar = ($scope.config.local_vent != '0');

        //array errors
        if ($scope.config.errors) {
          $scope.errors_ary = $scope.config.errors.split('');
          $scope.errors_ary_empty = $scope.errors_ary.filter(function (x) { return x != 0; }).length == 0;

          // Ingoro warning del rocio
          if ($scope.errors_ary[25] == "1" || $scope.errors_ary[26] == "1") {
            $scope.errors_ary_empty = true
          }
        }

        //Manufacturer
        $scope.manufacturerShow = false;

        //User Mode Setpoint
        $scope.show_user_mode_setpoint_scenes_options = false;
        $scope.class_user_mode_setpoint = true;
        $scope.class_user_mode_setpoint_scene = 'confort';
        $scope.consign_user_mode_setpoint = $scope.config.confort_cold_consign;

        //Offset Enviroment Cold
        $scope.class_offset_environment = true;

        //Offset Enviroment Heat
        if ($scope.config.offset_environment_heat) {
          if ($scope.zone.temperature_unit == "1")
            $scope.available_offset_environment_heat = ["-5", "-4", "-3", "-2", "-1", "0", "1", "2", "3", "4", "5"];
          else
            $scope.available_offset_environment_heat = ["-2.5", "-2", "-1.5", "-1", "-0.5", "0", "0.5", "1", "1.5", "2", "2.5"];
          $scope.offset_environment_value = $scope.available_offset_environment_heat[parseInt($scope.config.offset_environment_heat)];
        }

        if ($scope.config.offset_environment_cold) {
          if ($scope.zone.temperature_unit == "1")
            $scope.available_offset_environment_cold = ["-5", "-4", "-3", "-2", "-1", "0", "1", "2", "3", "4", "5"];
          else
            $scope.available_offset_environment_cold = ["-2.5", "-2", "-1.5", "-1", "-0.5", "0", "0.5", "1", "1.5", "2", "2.5"];
          $scope.offset_environment_value = $scope.available_offset_environment_cold[parseInt($scope.config.offset_environment_cold)];
        }

        // New Control Stages
        if ($scope.config.VAF_cold_stages_available){
          $scope.available_cold_control_stage = $scope.config.VAF_cold_stages_available.split(',');
        } 
        if ($scope.config.VAF_heat_stages_available) {
          $scope.available_heat_control_stage = $scope.config.VAF_heat_stages_available.split(',');
        }
        if ($scope.config.VAF_radiant_stages_heat_available != null || $scope.config.VAF_radiant_stages_cold_available != null) {
          if ($scope.config.VAF_radiant_stages_heat_available != null)
            $scope.available_heat_radiant_control_stage = $scope.config.VAF_radiant_stages_heat_available.split(',');
          if ($scope.config.VAF_radiant_stages_cold_available != null)
            $scope.available_cold_radiant_control_stage = $scope.config.VAF_radiant_stages_cold_available.split(',');
          // Hide options radiant stages in DI6 or show it in VAF
          if ($scope.config.VAF_radiant_stages_heat_available.length > 0 && !$scope.config.VAF_radiant_stages_cold_available){
            // Show options radiant stages in VAF
            $scope.has_radiant_stage_options = true; 
          }else{
            $scope.has_radiant_stage_options = false;
            // Initialize onoff cold radiant stage if it's DI6
            $scope.coldRadiantControlStageVar = $scope.config.VAF_radiant_cold_stage != "0";
            // Initialize onoff heat radiant stage if it's DI6
            $scope.heatRadiantControlStageVar = $scope.config.VAF_radiant_heat_stage != "0";
          }

          if ($scope.available_heat_radiant_control_stage && $scope.available_heat_radiant_control_stage.includes("3")){
            // Show new radianT control stage fields
            $scope.has_radianT_control_stage = true;
            // Show options of radianT control stage
            $scope.show_radianT_control_stage_type = true;
            //Cold radianT control Stage value of switch button: turn off => 0,1 ; turn on => 2,3 
            $scope.coldRadianTControlStageVar = $scope.config.cold_type_config != "0" && $scope.config.cold_type_config != "1";
            //Heat radianT control Stage value of switch button: turn off => 0,1 ; turn on => 2,3
            $scope.heatRadianTControlStageVar = $scope.config.heat_type_config != "0" && $scope.config.heat_type_config != "1";
          }
        }

        //Control Stages
        if ($scope.config.VAF_coldstage && $scope.config.VAF_heatstage) {
          // Show new control stage fields
          $scope.has_control_stage = true;
          // Show options control stage
          $scope.show_control_stage_type = true;
          //Cold control Stage value of switch button
          $scope.coldControlStageVar = $scope.coldTypeConfigVar;
          //Heat control Stage value of switch button
          $scope.heatControlStageVar = $scope.heatTypeConfigVar;
          //Radiant cold control Stage value of switch button
          $scope.coldRadiantControlStageVar = (Number($scope.config.cold_type_config) > 1);
          //Radiant heat control Stage value of switch button
          $scope.heatRadiantControlStageVar = (Number($scope.config.heat_type_config) > 1);
        }else{
          // Hide new control stage fields
          $scope.has_control_stage = false;
        }
      }

      /*
       * Parsing value to Float with one decimal
       */
      $scope.parseFloatFixed1 = function (value) {
        return parseFloat(value).toFixed(1);
      }

      /*
       * Get the names of the slaves zones
       */
      $scope.getSlaveString = function () {
        var str = '';
        if ($scope.config && $scope.config.slave)
          str = $scope.config.slave.replaceAll('_', ",");
        return str;
      };

      /*
       * Toogle master value
       */
      $scope.toggleMaster = function () {
        $scope.masterVar = !$scope.masterVar;
      };

      $scope.listener3 = $scope.$watch('masterVar', function (new_val, old_val, scope) {
        if ($scope.config != null && old_val != null) {

          if ($scope.masterVar) {
            $scope.config.master = '1';
          } else {
            $scope.config.master = '0';
          }


          timeouts.master_timeout = SystemUtilsService.restartTimeout(timeouts.master_timeout, function () {
            eventsService.createEvent('modconfzona', $scope.zone, 'master', $scope.config.master);
            $scope.config.last_field = 'master';
            $scope.config.last_value = $scope.config.master;
          }, 500);
        }
      });

      /*
       * Toogle basic mode
       */
      $scope.toggleBasicMode = function () {
        $scope.basicModeVar = !$scope.basicModeVar;
      };

      $scope.listener4 = $scope.$watch('basicModeVar', function (new_val, old_val, scope) {
        if ($scope.config != null && old_val != null) {

          if ($scope.basicModeVar) {
            $scope.config.basic_mode = '1';
          } else {
            $scope.config.basic_mode = '0';
          }

          timeouts.basic_mode_timeout = SystemUtilsService.restartTimeout(timeouts.basic_mode_timeout, function () {
            eventsService.createEvent('modconfzona', $scope.zone, 'basic_mode', $scope.config.basic_mode);
            $scope.config.last_field = 'basic_mode';
            $scope.config.last_value = $scope.config.basic_mode;
          }, 500);

        }
      });

      /*
       * Toogle heat type config
       */
      $scope.toggleHeatTypeConfig = function () {
        $scope.heatTypeConfigVar = !$scope.heatTypeConfigVar;
      }

      $scope.listener5 = $scope.$watch('heatTypeConfigVar', function (new_val, old_val, scope) {
        if ($scope.config != null && old_val != null) {

          if ($scope.heatTypeConfigVar) {
            $scope.config.heat_type_config = ($scope.old_heatTypeConfig).toString();
          } else {
            $scope.config.heat_type_config = '0';
          }

          timeouts.heat_type_config_timeout = SystemUtilsService.restartTimeout(timeouts.heat_type_config_timeout, function () {
            if ($scope.heatTypeConfigVar)
              eventsService.createEvent('modconfzona', $scope.zone, 'heat_type_config', ($scope.old_heatTypeConfig).toString());
            else
              eventsService.createEvent('modconfzona', $scope.zone, 'heat_type_config', $scope.config.heat_type_config);

            $scope.config.last_field = 'heat_type_config';
            $scope.config.last_value = $scope.config.heat_type_config;
          }, 500);
        }
      });

      $scope.toggleShowHeatConfig = function () {
        if ($scope.heatTypeConfigVar && parseInt($scope.config.heat_values) > 0) {
          closeOptions($scope.showHeatTypeConfig);
          $scope.showHeatTypeConfig = !$scope.showHeatTypeConfig;
        }
      }

      $scope.changeHeatTypeConfig = function (value) {
        $scope.config.heat_type_config = value;
        $scope.old_heatTypeConfig = $scope.config.heat_type_config;


        timeouts.heat_type_config_timeout = SystemUtilsService.restartTimeout(timeouts.heat_type_config_timeout, function () {
          eventsService.createEvent('modconfzona', $scope.zone, 'heat_type_config', $scope.config.heat_type_config);
          $scope.config.last_field = 'heat_type_config';
          $scope.config.last_value = $scope.config.heat_type_config;
        }, 500);

        $scope.showHeatTypeConfig = false;
      };

      /*
       * Toogle cold type config
       */
      $scope.toggleColdTypeConfig = function () {
        if (parseInt($scope.config.cold_values) > 0) {
          closeOptions($scope.coldTypeConfigVar);
          $scope.coldTypeConfigVar = !$scope.coldTypeConfigVar;
        }
      }

      $scope.listener6 = $scope.$watch('coldTypeConfigVar', function (new_val, old_val, scope) {
        if ($scope.config != null && old_val != null) {

          if ($scope.coldTypeConfigVar) {
            $scope.config.cold_type_config = ($scope.old_coldTypeConfig).toString();
          } else {
            $scope.config.cold_type_config = '0';
          }

          timeouts.cold_type_config_timeout = SystemUtilsService.restartTimeout(timeouts.cold_type_config_timeout, function () {
            if ($scope.config.coldTypeConfigVar)
              eventsService.createEvent('modconfzona', $scope.zone, 'cold_type_config', ($scope.old_coldTypeConfig.toString()));
            else
              eventsService.createEvent('modconfzona', $scope.zone, 'cold_type_config', $scope.config.cold_type_config);
            $scope.config.last_field = 'cold_type_config';
            $scope.config.last_value = $scope.config.cold_type_config;
          }, 500);

        }
      });

      $scope.toggleShowColdConfig = function () {
        if (parseInt($scope.config.cold_values) > 0) {
          closeOptions($scope.showColdTypeConfig);
          $scope.showColdTypeConfig = !$scope.showColdTypeConfig;
        }
      }

      $scope.changeColdTypeConfig = function (value) {
        $scope.config.cold_type_config = value;
        $scope.old_coldTypeConfig = value;


        timeouts.cold_type_config_timeout = SystemUtilsService.restartTimeout(timeouts.cold_type_config_timeout, function () {
          eventsService.createEvent('modconfzona', $scope.zone, 'cold_type_config', $scope.config.cold_type_config);
          $scope.config.last_field = 'cold_type_config';
          $scope.config.last_value = $scope.config.cold_type_config;
        }, 500);

        $scope.showColdTypeConfig = false;
      };

      /*
       * Change ventilation
       */
      $scope.changeVentilation = function (value) {
        $scope.config.ventilation = value;

        timeouts.ventilation_timeout = SystemUtilsService.restartTimeout(timeouts.ventilation_timeout, function () {
          eventsService.createEvent('modconfzona', $scope.zone, 'ventilation', $scope.config.ventilation);
          $scope.config.last_field = 'ventilation';
          $scope.config.last_value = $scope.config.ventilation;
        }, 500);

        $scope.ventilationVar = false;
      };

      $scope.toggleVentilation = function () {
        closeOptions($scope.ventilationVar);
        $scope.ventilationVar = !$scope.ventilationVar;
      };

      /*
       * Toggle manufacturer
       */
      $scope.toggleManufacturer = function () {
        closeOptions($scope.manufacturerShow);
        $scope.manufacturerShow = !$scope.manufacturerShow
      };

      $scope.changeManufacturer = function (value) {
        $scope.config.manufacturer = value;

        timeouts.manufacturer_timeout = SystemUtilsService.restartTimeout(timeouts.manufacturer_timeout, function () {
          eventsService.createEvent('modconfzona', $scope.zone, 'manufacturer', $scope.config.manufacturer);
          $scope.config.last_field = 'manufacturer';
          $scope.config.last_value = $scope.config.manufacturer;
        }, 500);

        $scope.manufacturerShow = false;
      };


      //Change q_weight
      $scope.changeQWeight = function (value) {
        var range = 1;
        if (value > 0 && (parseFloat($scope.config.q_weight) + range <= 100)) {
          $scope.config.q_weight = (parseFloat($scope.config.q_weight) + range).toString();
        } else if (value < 0 && (parseFloat($scope.config.q_weight) - range > 0)) {
          $scope.config.q_weight = (parseFloat($scope.config.q_weight) - range).toString();
        }


        timeouts.q_weight_timeout = SystemUtilsService.restartTimeout(timeouts.q_weight_timeout, function () {
          eventsService.createEvent('modconfzona', $scope.zone, 'q_weight', $scope.config.q_weight);
          $scope.config.last_field = 'q_weight';
          $scope.config.last_value = $scope.config.q_weight;
        }, 500);

      };

      /*
       * Toogle window type config
       */
      $scope.toggleWindow = function () {
        $scope.windowVar = !$scope.windowVar;
      }
      $scope.toggleWindowVar = function () {
        closeOptions($scope.showWindow);
        $scope.showWindow = !$scope.showWindow;
      }

      $scope.listener7 = $scope.$watch('windowVar', function (new_val, old_val, scope) {
        if ($scope.config != null && old_val != null && $scope.config.window != null) {

          if ($scope.windowVar) {
            $scope.config.window = '1';
          } else {
            $scope.config.window = '0';
          }

          timeouts.window_timeout = SystemUtilsService.restartTimeout(timeouts.window_timeout, function () {
            eventsService.createEvent('modconfzona', $scope.zone, 'window', $scope.config.window);
            $scope.config.last_field = 'window';
            $scope.config.last_value = $scope.config.window;
          }, 500);

        }
      });

      $scope.changeWindow = function (value) {
        $scope.config.window = value;

        timeouts.window_timeout = SystemUtilsService.restartTimeout(timeouts.window_timeout, function () {
          eventsService.createEvent('modconfzona', $scope.zone, 'window', $scope.config.window);
          $scope.config.last_field = 'window';
          $scope.config.last_value = $scope.config.window;
        }, 500);

        $scope.showWindow = false;
      };

      /*
       * Toogle presence type config
       */
      $scope.togglePresence = function () {
        $scope.presenceVar = !$scope.presenceVar;
      }

      $scope.togglePresenceVar = function () {
        closeOptions($scope.showPresence);
        $scope.showPresence = !$scope.showPresence;
      }

      $scope.listener8 = $scope.$watch('presenceVar', function (new_val, old_val, scope) {
        if ($scope.config != null && old_val != null && $scope.config.presence != null) {

          if ($scope.presenceVar) {
            $scope.config.presence = '1';
          } else {
            $scope.config.presence = '0';
          }

          timeouts.presence_timeout = SystemUtilsService.restartTimeout(timeouts.presence_timeout, function () {
            eventsService.createEvent('modconfzona', $scope.zone, 'presence', $scope.config.presence);
            $scope.config.last_field = 'presence';
            $scope.config.last_value = $scope.config.presence;
          }, 500);

        }
      });

      $scope.changePresence = function (value) {
        $scope.config.presence = value;

        timeouts.presence_timeout = SystemUtilsService.restartTimeout(timeouts.presence_timeout, function () {
          eventsService.createEvent('modconfzona', $scope.zone, 'presence', $scope.config.presence);
          $scope.config.last_field = 'presence';
          $scope.config.last_value = $scope.config.presence;
        }, 500);

        $scope.showPresence = false;
      };

      /*
       * Toogle on/off in eco function
       */
      $scope.toggleEcoFunctionVar = function () {
        $scope.ecoFunctionVar = !$scope.ecoFunctionVar;
        $scope.changeFunctionEco(String(+$scope.ecoFunctionVar));
      }

      /*
       * Toogle eco function
       */
      $scope.toggleFunctionEco = function () {
        closeOptions($scope.showEcoFunctionVar);
        $scope.showEcoFunctionVar = !$scope.showEcoFunctionVar;
      }

      /*
       * Change the value of function eco
       */
      $scope.changeFunctionEco = function (value) {
        $scope.showEcoFunctionVar = false;

        $scope.config.eco_function = value;

        timeouts.eco_function_timeout = SystemUtilsService.restartTimeout(timeouts.eco_function_timeout, function () {
          eventsService.createEvent('modconfzona', $scope.zone, 'eco_function', $scope.config.eco_function);
        }, 500);
      }

      /*
       * Toogle constant ventilation
       */
      $scope.toggleConstantVentilation = function (mode) {
        if (mode === 'cold') {
          $scope.coldConstantVentilationVar = !$scope.coldConstantVentilationVar;
          if ($scope.coldConstantVentilationVar) {
            $scope.config.cold_constant_ventilation = '1';
          } else {
            $scope.config.cold_constant_ventilation = '0';
          }

          timeouts.cold_constant_ventilation_timeout = SystemUtilsService.restartTimeout(timeouts.cold_constant_ventilation_timeout, function () {
            eventsService.createEvent('modconfzona', $scope.zone, 'cold_constant_ventilation', $scope.config.cold_constant_ventilation);
          }, 500);
        } else {
          $scope.heatConstantVentilationVar = !$scope.heatConstantVentilationVar;
          if ($scope.heatConstantVentilationVar) {
            $scope.config.heat_constant_ventilation = '1';
          } else {
            $scope.config.heat_constant_ventilation = '0';
          }

          timeouts.heat_constant_ventilation_timeout = SystemUtilsService.restartTimeout(timeouts.heat_constant_ventilation_timeout, function () {
            eventsService.createEvent('modconfzona', $scope.zone, 'heat_constant_ventilation', $scope.config.heat_constant_ventilation);
          }, 500);
        }
      }

      /*
       * Change minimum voltage module 0-10v
       */
      $scope.v_min_module_010_change = function (value) {
        if (parseFloat($scope.config.v_min_module_010) + value <= parseFloat($scope.config.v_max_module_010)) {
          if ((parseFloat($scope.config.v_min_module_010) + value >= 1 && parseFloat($scope.config.v_min_module_010) + value <= 10))
            $scope.config.v_min_module_010 = (parseFloat($scope.config.v_min_module_010) + value).toString();

          timeouts.v_min_module_010_timeout = SystemUtilsService.restartTimeout(timeouts.v_min_module_010_timeout, function () {
            eventsService.createEvent('modconfzona', $scope.zone, 'v_min_module_010', $scope.config.v_min_module_010);
          }, 500);
        }
      };

      /*
       * Change maximum voltage module 0-10v
       */
      $scope.v_max_module_010_change = function (value) {
        if (parseFloat($scope.config.v_max_module_010) + value >= parseFloat($scope.config.v_min_module_010)) {
          if ((parseFloat($scope.config.v_max_module_010) + value >= 1) && (parseFloat($scope.config.v_max_module_010) + value <= 10))
            $scope.config.v_max_module_010 = (parseFloat($scope.config.v_max_module_010) + value).toString();

          timeouts.v_max_module_010_timeout = SystemUtilsService.restartTimeout(timeouts.v_max_module_010_timeout, function () {
            eventsService.createEvent('modconfzona', $scope.zone, 'v_max_module_010', $scope.config.v_max_module_010);
          }, 500);

        }
      };

      /*
       * Toogle spray dew type config
       */
      $scope.toggleSprayDew = function () {
        $scope.sprayDewVar = !$scope.sprayDewVar;
      }

      $scope.toggleSprayDewVar = function () {
        closeOptions($scope.showSprayDew);
        $scope.showSprayDew = !$scope.showSprayDew;
      }

      $scope.listener9 = $scope.$watch('sprayDewVar', function (new_val, old_val, scope) {
        if ($scope.config != null && old_val != null) {

          if ($scope.sprayDewVar) {
            $scope.config.spray_dew = '1';
          } else {
            $scope.config.spray_dew = '0';
          }

          timeouts.spray_dew_timeout = SystemUtilsService.restartTimeout(timeouts.spray_dew_timeout, function () {
            eventsService.createEvent('modconfzona', $scope.zone, 'spray_dew', $scope.config.spray_dew);
            $scope.config.last_field = 'spray_dew';
            $scope.config.last_value = $scope.config.spray_dew;
          }, 500);

        }
      });

      $scope.changeSprayDew = function (value) {
        $scope.config.spray_dew = value;


        timeouts.spray_dew_timeout = SystemUtilsService.restartTimeout(timeouts.spray_dew_timeout, function () {
          eventsService.createEvent('modconfzona', $scope.zone, 'spray_dew', $scope.config.spray_dew);
          $scope.config.last_field = 'spray_dew';
          $scope.config.last_value = $scope.config.spray_dew;
        }, 500);

        $scope.showSprayDew = false;
      };

      /*
       * Toogle local vent type config
       */
      $scope.toggleLocalVent = function () {
        $scope.localVentVar = !$scope.localVentVar;
      }

      $scope.toggleLocalVentVar = function () {
        closeOptions($scope.showLocalVent);
        $scope.showLocalVent = !$scope.showLocalVent;
      }

      $scope.listener10 = $scope.$watch('localVentVar', function (new_val, old_val, scope) {
        if ($scope.config != null && old_val != null) {

          if ($scope.localVentVar) {
            $scope.config.local_vent = '1';
          } else {
            $scope.config.local_vent = '0';
          }

          timeouts.local_vent_timeout = SystemUtilsService.restartTimeout(timeouts.local_vent_timeout, function () {
            eventsService.createEvent('modconfzona', $scope.zone, 'local_vent', $scope.config.local_vent);
            $scope.config.last_field = 'local_vent';
            $scope.config.last_value = $scope.config.local_vent;
          }, 500);


        }
      });

      $scope.changeLocalVent = function (value) {
        $scope.config.local_vent = value;

        timeouts.local_vent_timeout = SystemUtilsService.restartTimeout(timeouts.local_vent_timeout, function () {
          eventsService.createEvent('modconfzona', $scope.zone, 'local_vent', $scope.config.local_vent);
          $scope.config.last_field = 'local_vent';
          $scope.config.last_value = $scope.config.local_vent;
        }, 500);

        $scope.showLocalVent = false;
      };

      /*
       * Create event to change ZBS consign user mode setpoint
       */
      $scope.changeUserModeSetpoint = function (value) {
        if ($scope.zone.temperature_unit == "0") {
          if (value > 0)
            value = 0.5;
          else
            value = -0.5;
        }

        if (setUserModeSetpointConsign(value)) {

          timeouts.user_mode_setpoint_timeout = SystemUtilsService.restartTimeout(timeouts.user_mode_setpoint_timeout, function () {
            if ($scope.class_user_mode_setpoint) {
              switch ($scope.class_user_mode_setpoint_scene) {
                case 'confort':
                  eventsService.createEvent('modconfzona', $scope.zone, 'confort_cold_consign', $scope.config.confort_cold_consign);
                  break;
                case 'eco':
                  eventsService.createEvent('modconfzona', $scope.zone, 'eco_cold_consign', $scope.config.eco_cold_consign);
                  break;
                case 'unocupied':
                  eventsService.createEvent('modconfzona', $scope.zone, 'unocupied_cold_consign', $scope.config.unocupied_cold_consign);
                  break;
                case 'vacation':
                  eventsService.createEvent('modconfzona', $scope.zone, 'vacation_cold_consign', $scope.config.vacation_cold_consign);
                  break;
              }
            } else {
              switch ($scope.class_user_mode_setpoint_scene) {
                case 'confort':
                  eventsService.createEvent('modconfzona', $scope.zone, 'confort_heat_consign', $scope.config.confort_heat_consign);
                  break;
                case 'eco':
                  eventsService.createEvent('modconfzona', $scope.zone, 'eco_heat_consign', $scope.config.eco_heat_consign);
                  break;
                case 'unocupied':
                  eventsService.createEvent('modconfzona', $scope.zone, 'unocupied_heat_consign', $scope.config.unocupied_heat_consign);
                  break;
                case 'vacation':
                  eventsService.createEvent('modconfzona', $scope.zone, 'vacation_heat_consign', $scope.config.vacation_heat_consign);
                  break;
              }
            }
          }, 500);
        }
      };

      /*
       * Show/Hide user mode setpoint scenes options
       */
      $scope.userModeSetpointScenesOptions = function () {
        closeOptions($scope.show_user_mode_setpoint_scenes_options);
        $scope.show_user_mode_setpoint_scenes_options = !$scope.show_user_mode_setpoint_scenes_options;
      };

      /*
       * Set cold or heat mode in ZBS user mode setpoint
       */
      $scope.setUserModeSetpoint = function () {
        $scope.class_user_mode_setpoint = !$scope.class_user_mode_setpoint;
        $scope.changeUserModeSetpointConsign($scope.class_user_mode_setpoint_scene);
      };

      /*
       * Change User Mode Setpoint Consign
       */
      $scope.changeUserModeSetpointConsign = function (scene) {
        $scope.show_user_mode_setpoint_scenes_options = false;
        $scope.class_user_mode_setpoint_scene = scene;

        if ($scope.class_user_mode_setpoint) {
          switch (scene) {
            case 'confort':
              $scope.consign_user_mode_setpoint = $scope.config.confort_cold_consign;
              break;
            case 'eco':
              $scope.consign_user_mode_setpoint = $scope.config.eco_cold_consign;
              break;
            case 'unocupied':
              $scope.consign_user_mode_setpoint = $scope.config.unocupied_cold_consign;
              break;
            case 'vacation':
              $scope.consign_user_mode_setpoint = $scope.config.vacation_cold_consign;
              break;
          }
        } else {
          switch (scene) {
            case 'confort':
              $scope.consign_user_mode_setpoint = $scope.config.confort_heat_consign;
              break;
            case 'eco':
              $scope.consign_user_mode_setpoint = $scope.config.eco_heat_consign;
              break;
            case 'unocupied':
              $scope.consign_user_mode_setpoint = $scope.config.unocupied_heat_consign;
              break;
            case 'vacation':
              $scope.consign_user_mode_setpoint = $scope.config.vacation_heat_consign;
              break;
          }
        }
      };

      /*
       * Set user mode setpoint ZBS consign checking the limits
       */
      function setUserModeSetpointConsign(val) {
        var max;
        var min;
        var consign;
        var modified_value;

        if ($scope.class_user_mode_setpoint) {
          switch ($scope.class_user_mode_setpoint_scene) {
            case 'confort':
              if ($scope.zone.temperature_unit == "1") {
                max = 86;
                consign = parseInt($scope.config.confort_cold_consign) + val;
                min = setColdConsignMinimum();
              } else {
                max = 30.0;
                consign = parseFloat($scope.config.confort_cold_consign) + val;
                min = setColdConsignMinimum();
              }
              modified_value = checkSetSetpoint('confort', min, max, consign, val);
              break;
            case 'eco':
              if ($scope.zone.temperature_unit == "1") {
                max = 86;
                consign = parseInt($scope.config.eco_cold_consign) + val;
                min = setColdConsignMinimum();
              } else {
                max = 30.0;
                consign = parseFloat($scope.config.eco_cold_consign) + val;
                min = setColdConsignMinimum();
              }
              modified_value = checkSetSetpoint('eco', min, max, consign, val);
              break;
            case 'unocupied':
              if ($scope.zone.temperature_unit == "1") {
                min = 75;
                max = 86;
                consign = parseInt($scope.config.unocupied_cold_consign) + val;
              } else {
                min = 24.0;
                max = 30.0;
                consign = parseFloat($scope.config.unocupied_cold_consign) + val;
              }
              modified_value = checkSetSetpoint('unocupied', min, max, consign, val);
              break;
            case 'vacation':
              if ($scope.zone.temperature_unit == "1") {
                min = 84;
                max = 96;
                consign = parseInt($scope.config.vacation_cold_consign) + val;
              } else {
                min = 29.0;
                max = 35.5;
                consign = parseFloat($scope.config.vacation_cold_consign) + val;
              }
              modified_value = checkSetSetpoint('vacation', min, max, consign, val);
              break;
          }
        } else {
          switch ($scope.class_user_mode_setpoint_scene) {
            case 'confort':
              if ($scope.zone.temperature_unit == "1") {
                min = 59;
                max = 86 - parseInt($scope.config.autochange_differential);
                consign = parseInt($scope.config.confort_heat_consign) + val;
              } else {
                min = 15.0;
                max = 30.0 - parseFloat($scope.config.autochange_differential);
                consign = parseFloat($scope.config.confort_heat_consign) + val;
              }
              modified_value = checkSetSetpoint('confort', min, max, consign, val);
              break;
            case 'eco':
              if ($scope.zone.temperature_unit == "1") {
                min = 59;
                max = 86 - parseInt($scope.config.autochange_differential);
                consign = parseInt($scope.config.eco_heat_consign) + val;
              } else {
                min = 15.0;
                max = 30.0 - parseFloat($scope.config.autochange_differential);
                consign = parseFloat($scope.config.eco_heat_consign) + val;
              }
              modified_value = checkSetSetpoint('eco', min, max, consign, val);
              break;
            case 'unocupied':
              if ($scope.zone.temperature_unit == "1") {
                min = 59;
                max = 72;
                consign = parseInt($scope.config.unocupied_heat_consign) + val;
              } else {
                min = 15.0;
                max = 22.0;
                consign = parseFloat($scope.config.unocupied_heat_consign) + val;
              }
              modified_value = checkSetSetpoint('unocupied', min, max, consign, val);
              break;
            case 'vacation':
              if ($scope.zone.temperature_unit == "1") {
                min = 50;
                max = 61;
                consign = parseInt($scope.config.vacation_heat_consign) + val;
              } else {
                min = 10.0;
                max = 16.0;
                consign = parseFloat($scope.config.vacation_heat_consign) + val;
              }
              modified_value = checkSetSetpoint('vacation', min, max, consign, val);
              break;
          }
        }
        return modified_value;
      };

      /*
       * Check and set setpoint return if value is modified or not
       */
      function checkSetSetpoint(scene, minimum, maximum, setpoint, value) {
        var modified = false;

        if (minimum <= setpoint && setpoint <= maximum) {
          switch (scene) {
            case 'confort':
              if ($scope.class_user_mode_setpoint)
                $scope.config.confort_cold_consign = setpoint.toString();
              else
                $scope.config.confort_heat_consign = setpoint.toString();
              checkResetSetpoint('confort', value);
              break;
            case 'eco':
              if ($scope.class_user_mode_setpoint)
                $scope.config.eco_cold_consign = setpoint.toString();
              else
                $scope.config.eco_heat_consign = setpoint.toString();
              checkResetSetpoint('eco', value);
              break;
            case 'unocupied':
              if ($scope.class_user_mode_setpoint)
                $scope.config.unocupied_cold_consign = setpoint.toString();
              else
                $scope.config.unocupied_heat_consign = setpoint.toString();
              break;
            case 'vacation':
              if ($scope.class_user_mode_setpoint)
                $scope.config.vacation_cold_consign = setpoint.toString();
              else
                $scope.config.vacation_heat_consign = setpoint.toString();
              break;
          }

          $scope.consign_user_mode_setpoint = setpoint.toString();
          modified = true;
        }

        return modified;
      }

      /*
       * Set cold consign minimum
       */
      function setColdConsignMinimum() {
        var minimum;

        if ($scope.zone.temperature_unit == "1") {
          minimum = 64;
          // Reset in farenheit if cold consign minimum is greater than autochange differential plus heat minimum
          if (parseInt($scope.config.autochange_differential) + 59 > minimum)
            minimum = parseInt($scope.config.autochange_differential) + 59;
        } else {
          minimum = 18.0;
          // Reset in celsius if cold consign minimum is greater than autochange differential plus heat minimum
          if (parseFloat($scope.config.autochange_differential) + 15.0 > minimum)
            minimum = parseFloat($scope.config.autochange_differential) + 15.0;
        }

        return minimum;
      };

      /*
       * Check autochange differential and reset setpoints if is need
       */
      function checkResetSetpoint(scene, value) {
        if (scene == 'confort') {
          if ($scope.config.confort_cold_consign - $scope.config.confort_heat_consign < $scope.config.autochange_differential) {
            if (value < 0) {
              if ($scope.zone.temperature_unit == "1")
                $scope.config.confort_heat_consign = (parseInt($scope.config.confort_heat_consign) + value).toString();
              else
                $scope.config.confort_heat_consign = (parseFloat($scope.config.confort_heat_consign) + value).toString();
            } else if (value > 0) {
              if ($scope.zone.temperature_unit == "1")
                $scope.config.confort_cold_consign = (parseInt($scope.config.confort_cold_consign) + value).toString();
              else
                $scope.config.confort_cold_consign = (parseFloat($scope.config.confort_cold_consign) + value).toString();
            }
          }
        } else if (scene == 'eco') {
          if ($scope.config.eco_cold_consign - $scope.config.eco_heat_consign < $scope.config.autochange_differential) {
            if (value < 0) {
              if ($scope.zone.temperature_unit == "1")
                $scope.config.eco_heat_consign = (parseInt($scope.config.eco_heat_consign) + value).toString();
              else
                $scope.config.eco_heat_consign = (parseFloat($scope.config.eco_heat_consign) + value).toString();
            } else if (value > 0) {
              if ($scope.zone.temperature_unit == "1")
                $scope.config.eco_cold_consign = (parseInt($scope.config.eco_cold_consign) + value).toString();
              else
                $scope.config.eco_cold_consign = (parseFloat($scope.config.eco_cold_consign) + value).toString();
            }
          }
        }
      };

      /*
       * Change zone automatic weight in ZBS
       */
      $scope.toggleAutomaticWeightConfig = function () {
        $scope.config.automatic_weight = !$scope.config.automatic_weight;

        timeouts.automatic_weight_timeout = SystemUtilsService.restartTimeout(timeouts.automatic_weight_timeout, function () {
          eventsService.createEvent('modconfzona', $scope.zone, 'automatic_weight', Number($scope.config.automatic_weight));
        }, 500);
      };

      /*
       * Return if the offset enviroment value in ZBS is selected
       */
      $scope.selectOffsetEnvironmentTemp = function (type, index) {
        if (type == 'cold') {
          return ($scope.config.offset_environment_cold == index.toString());
        } else {
          return ($scope.config.offset_environment_heat == index.toString());
        }
      };

      /*
       * Return if the control stage value in ZBS is selected
       */
      $scope.selectControlStage = function (type, index) {
        if ($scope.config){
          if (type == 'cold') {
            return ($scope.config.VAF_coldstage == index.toString());
          } else if (type == 'heat') {
            return ($scope.config.VAF_heatstage == index.toString());
          } else if (type == 'radiant') {
            // Check selected cold/heat stage control in ZBS and DI6 or RadianT systems
            if ($scope.show_control_stage_type || $scope.show_radianT_control_stage_type){
              return ($scope.config.VAF_radiant_cold_stage == index.toString());
            }else{
              // Electric comes in index = 0 in ZBS and it should index = 5 to select it
              if ($scope.has_radiant_stage_options && index === 0 && $scope.config.VAF_radiant_heat_stage === "5"){
                index = 5;
              }
              return ($scope.config.VAF_radiant_heat_stage == index.toString());  
            }
          }
        }
      };

      /*
       * Set the reverse values of stages in DI6 and radianT
       */
      function setValueReverseStage(type, value) {
        // Change the reverse stage, exept electric value
        if (value !== '5'){
          if (type === 'radiant' || $scope.has_radianT_control_stage){              
            if ($scope.show_radianT_control_stage_type){
              $scope.config.VAF_radiant_heat_stage = $scope.config.VAF_radiant_cold_stage;
            }else{
              $scope.config.VAF_radiant_cold_stage = $scope.config.VAF_radiant_heat_stage;
            }
          }else if (type === 'cold'){
            $scope.config.VAF_heatstage = $scope.config.VAF_coldstage;
          }else if (type === 'heat'){
            $scope.config.VAF_coldstage = $scope.config.VAF_heatstage;
          }
        }
      }

      /*
       * Send values of the control stage in ZBS and DI6 when turn on/off
       */
      $scope.toggleOnOffControlStage = function (type) {
        $scope.show_cold_control_stage_options = false;
        $scope.show_heat_control_stage_options = false;
        $scope.show_radiant_control_stage_options = false;
        if (type == 'cold') {
          $scope.coldControlStageVar = !$scope.coldControlStageVar;
          // If it is DI6 system and cold stage is showed then consider the cold radiant option
          if (!$scope.has_radiant_stage_options){
            var configTypeRadiant = Number($scope.coldRadiantControlStageVar);
            // If it has radiant sum 2
            if (configTypeRadiant != 0) {
              configTypeRadiant = 2;
            }
            $scope.config.cold_type_config = String(Number($scope.coldControlStageVar) + configTypeRadiant);
          }else{
            $scope.config.cold_type_config = String(Number($scope.coldControlStageVar));
          }
          eventsService.createEvent('modconfzona', $scope.zone, 'cold_type_config', $scope.config.cold_type_config);
        } else {
          if (type == 'heat') {
            $scope.heatControlStageVar = !$scope.heatControlStageVar;
            configTypeRadiant = Number($scope.heatRadiantControlStageVar);
          }
          if (type == 'radiant') {
            if (!$scope.has_radiant_stage_options && $scope.show_control_stage_type){
              $scope.coldRadiantControlStageVar = !$scope.coldRadiantControlStageVar;
              configTypeRadiant = Number($scope.coldRadiantControlStageVar);
            }else{
              $scope.heatRadiantControlStageVar = !$scope.heatRadiantControlStageVar;
              configTypeRadiant = Number($scope.heatRadiantControlStageVar);
            }
          }
          // If it has radiant sum 2
          if (configTypeRadiant != 0) {
            configTypeRadiant = 2;
          }
          // If it is DI6 system and cold stage is showed then consider the cold option
          if (!$scope.has_radiant_stage_options && $scope.show_control_stage_type){
            $scope.config.cold_type_config = String(Number($scope.coldControlStageVar) + configTypeRadiant);
            eventsService.createEvent('modconfzona', $scope.zone, 'cold_type_config', $scope.config.cold_type_config);
          }else{
            $scope.config.heat_type_config = String(Number($scope.heatControlStageVar) + configTypeRadiant);
            eventsService.createEvent('modconfzona', $scope.zone, 'heat_type_config', $scope.config.heat_type_config);
          }
        }
      };

      /*
       * Show the cold or heat and radiant options of the control stage in ZBS
       */
      $scope.showControlStageOptions = function (type) {
        if (type === 'cold' && $scope.coldControlStageVar) {
          closeOptions($scope.show_cold_control_stage_options);
          $scope.show_cold_control_stage_options = !$scope.show_cold_control_stage_options;
        } else if (type === 'heat' && $scope.heatControlStageVar) {
          closeOptions($scope.show_heat_control_stage_options);
          $scope.show_heat_control_stage_options = !$scope.show_heat_control_stage_options;
        } else if (type === 'radiant' && ($scope.coldRadiantControlStageVar || $scope.heatRadiantControlStageVar)) {
          closeOptions($scope.show_radiant_control_stage_options);
          $scope.show_radiant_control_stage_options = !$scope.show_radiant_control_stage_options;
        }
      }

      /*
       * Show the cold or heat options of the control stage in ZBS 
       */
      $scope.toggleControlStage = function () {
        $scope.show_cold_control_stage_options = false;
        $scope.show_heat_control_stage_options = false;
        $scope.show_radiant_control_stage_options = false;
        if ($scope.config.VAF_radiant_stages_heat_available !== ["3","4","5"]){
          $scope.show_control_stage_type = !$scope.show_control_stage_type;
        }
      };

      /*
       * Change the control stage in ZBS
       */
      $scope.changeControlStage = function (type, value) {
        if (type == 'cold') {
          $scope.show_cold_control_stage_options = !$scope.show_cold_control_stage_options;
          $scope.config.VAF_coldstage = $scope.available_cold_control_stage.indexOf(value);
          setValueReverseStage(type, value);
        } else if (type == 'heat') {
          $scope.show_heat_control_stage_options = !$scope.show_heat_control_stage_options;
          $scope.config.VAF_heatstage = $scope.available_heat_control_stage.indexOf(value);
          setValueReverseStage(type, value);
        } else if (type == 'radiant') {
          
          $scope.show_radiant_control_stage_options = !$scope.show_radiant_control_stage_options;
          if ($scope.show_control_stage_type){
            $scope.config.VAF_radiant_cold_stage = value;
          }else{
            $scope.config.VAF_radiant_heat_stage = value;
          }
          setValueReverseStage(type, value);
        }

        timeouts.control_stage_timeout = SystemUtilsService.restartTimeout(timeouts.control_stage_timeout, function () {
          if (type == 'cold') {
            eventsService.createEvent('modconfzona', $scope.zone, 'VAF_coldstage', $scope.config.VAF_coldstage);
          } else if (type == 'heat') {
            eventsService.createEvent('modconfzona', $scope.zone, 'VAF_heatstage', $scope.config.VAF_heatstage);
          } else if (type == 'radiant') {
            if ($scope.show_control_stage_type){
              eventsService.createEvent('modconfzona', $scope.zone, 'VAF_radiant_cold_stage', $scope.config.VAF_radiant_cold_stage);
            }else{
              eventsService.createEvent('modconfzona', $scope.zone, 'VAF_radiant_heat_stage', $scope.config.VAF_radiant_heat_stage);
            }
          }
        }, 500);

      };

      /*
       * Return if the control stage value in RadianT is selected
       */
      $scope.selectRadianTControlStage = function (type, value) {
        if ($scope.config){
          if ($scope.show_radianT_control_stage_type){
            return ($scope.config.VAF_radiant_cold_stage == value);
          }else{
            return ($scope.config.VAF_radiant_heat_stage == value);
          }
        }
      };

      /*
       * Send the cold or heat values of the control stage in radianT when turn on/off
       */
      $scope.toggleOnOffRadianTControlStage = function (type) {
        $scope.show_cold_radianT_control_stage_options = false;
        $scope.show_heat_radianT_control_stage_options = false;
        if (type == 'cold') {
          $scope.coldRadianTControlStageVar = !$scope.coldRadianTControlStageVar;
          // Set floor if cold stage is turned on
          if ($scope.coldRadianTControlStageVar && $scope.config.VAF_radiant_heat_stage === "5"){
            $scope.config.VAF_radiant_cold_stage = "3";
            $scope.config.VAF_radiant_heat_stage = "3";
          }
          // Send turn off => 0 or turn on => 2
          $scope.config.cold_type_config = String(Number($scope.coldRadianTControlStageVar) * 2);
          eventsService.createEvent('modconfzona', $scope.zone, 'cold_type_config', $scope.config.cold_type_config);
        } else if (type == 'heat') {
          $scope.heatRadianTControlStageVar = !$scope.heatRadianTControlStageVar;
          $scope.config.heat_type_config = String(Number($scope.heatRadianTControlStageVar) * 2);
          eventsService.createEvent('modconfzona', $scope.zone, 'heat_type_config', $scope.config.heat_type_config);
        }
      };

      /*
       * Show the cold or heat and radiant options of the control stage in radianT
       */
      $scope.showRadianTControlStageOptions = function (type) {
        if (type === 'cold' && $scope.coldRadianTControlStageVar) {
          closeOptions($scope.show_cold_radianT_control_stage_options);
          $scope.show_cold_radianT_control_stage_options = !$scope.show_cold_radianT_control_stage_options;
        } else if (type === 'heat' && $scope.heatRadianTControlStageVar) {
          closeOptions($scope.show_heat_radianT_control_stage_options);
          $scope.show_heat_radianT_control_stage_options = !$scope.show_heat_radianT_control_stage_options;
        }
      }

      /*
       * Show the cold or heat options of the control stage in radianT
       */
      $scope.toggleRadianTControlStage = function () {
        $scope.show_cold_radianT_control_stage_options = false;
        $scope.show_heat_radianT_control_stage_options = false;
        $scope.show_radianT_control_stage_type = !$scope.show_radianT_control_stage_type;
      };

      /*
       * Change the control stage in radianT
       */
      $scope.changeRadianTControlStage = function (type, value) {
        if (type == 'cold') {
          $scope.show_cold_radianT_control_stage_options = !$scope.show_cold_radianT_control_stage_options;
          $scope.config.VAF_radiant_cold_stage = value;
          setValueReverseStage(type, value);
        } else if (type == 'heat') {
          $scope.show_heat_radianT_control_stage_options = !$scope.show_heat_radianT_control_stage_options;
          $scope.config.VAF_radiant_heat_stage = value;
          setValueReverseStage(type, value);
        }
        // Turn off cold stage if electric is selected
        if ($scope.config.VAF_radiant_heat_stage === "5"){
          $scope.coldRadianTControlStageVar = false;
        }
        timeouts.control_stage_timeout = SystemUtilsService.restartTimeout(timeouts.control_stage_timeout, function () {
          if($scope.show_radianT_control_stage_type){
            eventsService.createEvent('modconfzona', $scope.zone, 'VAF_radiant_cold_stage', $scope.config.VAF_radiant_cold_stage);
          }else{
            eventsService.createEvent('modconfzona', $scope.zone, 'VAF_radiant_heat_stage', $scope.config.VAF_radiant_heat_stage);
          }
        }, 500);

      };

      /*
       * Set the value cold or heat of the offset enviroment
       */
      $scope.toggleOffsetEnvironment = function () {
        $scope.class_offset_environment = !$scope.class_offset_environment;
        if ($scope.class_offset_environment) {
          $scope.offset_environment_value = $scope.available_offset_environment_cold[parseInt($scope.config.offset_environment_cold)];
          //Change cold or heat options if options show
          if ($scope.show_offset_environment_heat_options) {
            $scope.show_offset_environment_heat_options = false;
            $scope.show_offset_environment_cold_options = true;
          }
        } else {
          $scope.offset_environment_value = $scope.available_offset_environment_heat[parseInt($scope.config.offset_environment_heat)];
          if ($scope.show_offset_environment_cold_options) {
            $scope.show_offset_environment_cold_options = false;
            $scope.show_offset_environment_heat_options = true;
          }
        }
      };

      $scope.showOffsetEnvironmentOptions = function () {
        if ($scope.class_offset_environment) {
          closeOptions($scope.show_offset_environment_cold_options);
          $scope.show_offset_environment_cold_options = !$scope.show_offset_environment_cold_options;
        } else if (!$scope.class_offset_environment) {
          closeOptions($scope.show_offset_environment_heat_options);
          $scope.show_offset_environment_heat_options = !$scope.show_offset_environment_heat_options;
        }
      };

      /*
       * Change offset enviroment in ZBS
       */
      $scope.changeOffsetEnvironment = function (type, value) {
        $scope.offset_environment_value = value;
        if (type == 'cold') {
          $scope.show_offset_environment_cold_options = !$scope.show_offset_environment_cold_options;
          $scope.config.offset_environment_cold = $scope.available_offset_environment_cold.indexOf(value);
        } else if (type == 'heat') {
          $scope.show_offset_environment_heat_options = !$scope.show_offset_environment_heat_options;
          $scope.config.offset_environment_heat = $scope.available_offset_environment_heat.indexOf(value);
        }

        timeouts.offset_environment_timeout = SystemUtilsService.restartTimeout(timeouts.offset_environment_timeout, function () {
          if (type == 'cold') {
            eventsService.createEvent('modconfzona', $scope.zone, 'offset_environment_cold', $scope.config.offset_environment_cold);
          } else if (type == 'heat') {
            eventsService.createEvent('modconfzona', $scope.zone, 'offset_environment_heat', $scope.config.offset_environment_heat);
          }
        }, 500);

      };

      /*
       * Called when close modal (click out)
       */
      $scope.close = function () {
        $scope.show = false;
        $scope.config = null;
        $rootScope.modalShow = false;
        $rootScope.zone_adjust_target = null;
        $rootScope.zone_config = null;
        $scope.errors_ary = null;
        $scope.disabled_heat_type_config = null;
        $scope.disabled_cold_type_config = null;
        $scope.heatTypeConfigVar = null;
        $scope.coldTypeConfigVar = null;
        $scope.showHelp = false;
      };

      /*
       * Translate strint 't'
       * Solved bug (can't call $rootScope directly)
       */
      $scope.i18n = function (t) {
        return $rootScope.i18n(t);
      };

      /*
       * Close all oppened options
       */
      function closeOptions(no_close_var) {
        if (no_close_var != $scope.manufacturerShow)
          $scope.manufacturerShow = false;
        if (no_close_var != $scope.showHeatTypeConfig)
          $scope.showHeatTypeConfig = false;
        if (no_close_var != $scope.showColdTypeConfig)
          $scope.showColdTypeConfig = false;
        if (no_close_var != $scope.ventilationVar)
          $scope.ventilationVar = false;
        if (no_close_var != $scope.showWindow)
          $scope.showWindow = false;
        if (no_close_var != $scope.showPresence)
          $scope.showPresence = false;
        if (no_close_var != $scope.showSprayDew)
          $scope.showSprayDew = false;
        if (no_close_var != $scope.showLocalVent)
          $scope.showLocalVent = false;
        if (no_close_var != $scope.show_user_mode_setpoint_scenes_options)
          $scope.show_user_mode_setpoint_scenes_options = false;
        if (no_close_var != $scope.show_offset_environment_cold_options)
          $scope.show_offset_environment_cold_options = false;
        if (no_close_var != $scope.show_offset_environment_heat_options)
          $scope.show_offset_environment_heat_options = false;
        if (no_close_var != $scope.showEcoFunctionVar)
          $scope.showEcoFunctionVar = false;
        if (no_close_var != $scope.show_cold_control_stage_options)
          $scope.show_cold_control_stage_options = false;
        if (no_close_var != $scope.show_heat_control_stage_options)
          $scope.show_heat_control_stage_options = false;
        if (no_close_var != $scope.show_radiant_control_stage_options)
          $scope.show_radiant_control_stage_options = false;
        /*if (no_close_var != $scope.show_cold_radianT_control_stage_options)
          $scope.show_cold_radianT_control_stage_options = false;
        if (no_close_var != $scope.show_heat_radianT_control_stage_options)
          $scope.show_heat_radianT_control_stage_options = false;*/
      };

      /*
       * Remove root scope watchs
       */
      $scope.$on('$destroy', function () {
        $scope.listener1();
        $scope.listener2();
        $scope.listener3();
        $scope.listener4();
        $scope.listener5();
        $scope.listener6();
        $scope.listener7();
        $scope.listener8();
        $scope.listener9();
        $scope.listener10();
        $rootScope.last_zone_number = -1;
      });
    }]);
