// Actions at begin of application
// aux function
function inArray(elem, array) {
  if (array.indexOf) {
    return array.indexOf(elem);
  }

  for (var i = 0, length = array.length; i < length; i++) {
    if (array[i] === elem) {
      return i;
    }
  }

  return -1;
}

function byString(o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

var __eolo;
if (mobile_env) {
  __eolo = {
    enabled: true,
    initialized: false,
    internet_warned: false,
    default_language: "es",
    initialize: function () {
      return document.addEventListener("deviceready", function () {
        document.addEventListener("backbutton", __eolo.back);
        document.addEventListener("touchmove", __eolo.touch);
        document.addEventListener("touchstart", __eolo.touch);
        document.addEventListener("pause", __eolo.pause);
        document.addEventListener("resume", __eolo.resume);
        //document.addEventListener("hidekeyboard", __eolo.hide_keyboard);
        window.onresize = __eolo.resize;
        //Keyboard.automaticScrollToTopOnHiding = true;
        return __eolo.initialized = true;
      });
    },
    touch: function (e) { },
    resize: function (e) { },
    //hide_keyboard: function (e) { },
    offline: function () {
      return __eolo.internet_warning();
    },
    online: function () {
      //return __eolo.google_places();
    },
    // google_places: function () {
    //   if (__eolo.internet) {
    //     return $.getScript("https://www.google.com/jsapi", function () {
    //       return google.load("maps", "3", {
    //         other_params: "sensor=false&libraries=places",
    //         callback: function () { }
    //       });
    //     });
    //   }
    // },
    internet: function () {
      return navigator.connection.type !== Connection.NONE;
    },
    interceptor: [
      '$q', '$rootScope', function ($q, $rootScope) {
        var service;
        service = {
          request: function (config) {
            if (__eolo.initialized && !__eolo.internet() && !__eolo.internet_warned) {
              return __eolo.internet_warning();
            } else {
              return config;
            }
          }
        };
        return service;
      }
    ]
  };
}

var airzone_dependencies;
var template_path = './templates/';
var url_path = '';
// eslint-disable-next-line no-unused-vars
var environment = "";
var check_status_url_path = '';
var isMobileApp = false;

if (!__eolo) {
  airzone_dependencies = ['ui.router', 'LocalStorageModule', 'ngAnimate', 'ngSanitize', 'ngDropdowns', 'filters', 'angular-carousel', 'AngularChart', 'vcRecaptcha', 'g1b.datetime-inputs'];
  airzone_dependencies.push("angularFileUpload");
  //airzone_dependencies.push( "templates" );

  // airzone_dependencies.push( "ngGrid" );
  // eslint-disable-next-line no-undef
  //environment = gon.environment;
}
else {
  airzone_dependencies = ['ui.router', 'LocalStorageModule', 'ngAnimate', 'ngSanitize', 'ngDropdowns', 'filters', 'angular-carousel', 'AngularChart', 'g1b.datetime-inputs'];
  url_path = "https://" + defaultHost + "/";
  check_status_url_path = checkStatusApp;
  //environment = "production";
  // Flag which detect if it is mobile or web app
  isMobileApp = true;
}

// eslint-disable-next-line no-undef
var Airzone = angular.module('Airzone', airzone_dependencies);

if(mobile_env) {
  Airzone.config([
    "$httpProvider", function ($httpProvider) {
      return $httpProvider.interceptors.push(__eolo.interceptor);
    }
  ]);
}



Airzone.run(['$rootScope', '$state', 'sessionService', 'websocketsService',
  function ($rootScope, $state, sessionService, websocketsService) {

    if (mobile_env) {
      // configuraciones __eolo dependientes del angular
      __eolo.back = function (e) {
        if (["init.login", "home", "home.servers"].indexOf($state.current.name) >= 0) {
          e.preventDefault();
          e.stopPropagation();
          return window.plugins.android_home.launch();
        } else if (!$rootScope.modalShow) {
          return window.history.back();
        }
      };

      __eolo.pause = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if ($rootScope.inside) {
          $state.go('home.servers');
        }
        return __eolo.enabled = false;
      };

      __eolo.resume = function (e) {
        e.preventDefault();
        e.stopPropagation();
        __eolo.enabled = true;
        return websocketsService.reconnect();
      };

      __eolo.internet_warning = function () {
        __eolo.internet_warned = true;

        return navigator.notification.confirm($rootScope.i18n('messages.errors.without_internet_text'), function (button) {
          return sessionService.unauthorize();
        }, $rootScope.i18n('messages.errors.without_internet_title'), [$rootScope.i18n('buttons.accept')]);
      };

      // inicializo
      __eolo.initialize();
    }

    // inicializaciones $rootscope
    $rootScope.menuTrigger = false;
    $rootScope.showMenuBtn = false;

    // Check permissions of roles of user
    $rootScope.checkPermissions = function (to_state) {
      var adminSites = ['home', 'home.admin', 'home.addServer', 'home.map', 'home.adminAdjust', 'home.adminUsers'];
      var basicSites = ['home', 'home.servers', 'home.addUserServer', 'home.editUserServer', 'home.zoneAltherma',
        'home.adjust', 'home.help', 'home.systems', 'home.zone', 'home.server',
        'home.users', 'home.addUserToDevice', 'home.userAdvancedAdjust'];

      var role = sessionService.role();

      // rutas admin que no están en móvil
      if (!mobile_env &&
        (role == 'admin' || role == 'sales')) {
        return (inArray(to_state.name, adminSites) != -1);
      }

      // sino rutas normales
      return (inArray(to_state.name, basicSites) != -1);

    };

    $rootScope.$on('$stateNotFound',
      function (event, unfoundState, fromState, fromParams) {
        console.log(unfoundState.to); // "lazy.state"
        console.log(unfoundState.toParams); // {a:1, b:2}
        console.log(unfoundState.options); // {inherit:false} + default options
      })

    // Authenticate routes
    $rootScope.controlRoute = false;
    $rootScope.$on('$stateChangeStart', function (e, toState, toParams, fromState, fromParams) {
      if (toState.name.split('.')[0] !== 'init'
        && toState.name !== 'splash'
        && toState.name.split('.')[0] !== 'installation') {
        if ($rootScope.controlRoute) {
          $rootScope.controlRoute = false;
          return;
        }
        // rutas autentificadas
        // paro propagación
        e.preventDefault();
        $rootScope.controlRoute = true;

        // obtengo usuario
        sessionService.user()
          .then(function () {
            // compruebo permisos
            if (!$rootScope.checkPermissions(toState)) {
              // no permito. Redirijo login
              sessionService.unauthorize();
            } else {
              // permitido
              //muestro loader
              $rootScope.loader = true;

              // continuo
              $state.go(toState.name, toParams);
            }
          }).catch(function (err) {
            // no tengo sesión. Redirijo al login
            sessionService.unauthorize();
          });
      }
      // si no es ruta autentificada, continuo
    });


    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
      $rootScope.menuTrigger = false;
      $rootScope.loader = false;
      //console.log("SUCCESSSS", toState, fromState);
      if (toState.name == 'home.zone')
        $rootScope.showMenuBtn = false;
      else
        $rootScope.showMenuBtn = true;
    });

    $rootScope.$on('$stateChangeError', function (e, toState, toParams, fromState, fromParams, error) {
      e.preventDefault();
      if (fromState.name === '' && toState.name === 'splash' && fromState.abstract === true){
        $state.go('splash');
      }else{
        sessionService.unauthorize();
      }
    });

    // Detect if device is a mobile
    // de variable de etorno!!
    $rootScope.isMobile = mobile_env;

    // Modal show/hide
    $rootScope.modalShow = false;

    // View Zona
    $rootScope.zonaView = false;

    // Type Environment
    //$rootScope.environment = environment;

    // Is User demo
    $rootScope.demo = sessionService.typeUser('demo');

    // Show/hide flags
    $rootScope.show_edit_servers_btn = false;
    $rootScope.show_climate_info = false;

    // Used for open modals
    $rootScope.system_config_target;
    $rootScope.zone_config_target;
    $rootScope.schedules_target;
    $rootScope.zbs_schedules_target;
    $rootScope.server_schedules_target;
    $rootScope.show_calendar_schedules;
    $rootScope.altherma_schedules_target;
    $rootScope.acs_radiant_schedules_target;
    $rootScope.velocity_target;
    $rootScope.eco_target;
    $rootScope.mode_target;
    $rootScope.system_errors_target;
    $rootScope.consumption_target; // show consumption chart modal
    $rootScope.last_zone_number;

    // System and Zone config (open modals)
    $rootScope.system_config;
    $rootScope.zone_config;

    // Models (used in services)
    $rootScope.systems;
    $rootScope.zones;

    // Others
    $rootScope.editServer;
    $rootScope.disconnection;
    $rootScope.user;
    $rootScope.new_cloud_message;  // show new cloud message modal
    $rootScope.disconnected_device;  // show disconnected device modal
    $rootScope.error_device; // show error device modal
    $rootScope.not_systems; // show error not systems modal
    $rootScope.show_alerts; // show alerts modal
    $rootScope.auto_mode_temperature_baterry; // show message of auto mode of temperature battery is enabled
    $rootScope.update_schedules;
    $rootScope.update_new_schedules;
    $rootScope.has_new_schedules; // if it has server schedules then disable the system and zones schedules buttons
    $rootScope.server_updated;
    $rootScope.current_page = 1; // Show the first page in servers of user by default
    $rootScope.browser_continue = false;
    $rootScope.zone_from_navigation = false;  // set true when open zone screen from server window
    $rootScope.general_update = false; // used for set loading class in home menu
    $rootScope.altherma; // Save the altherma system to render in mobile
    $rootScope.purifier_warning;  // show the purifier warning modal

    // Set Language
    $rootScope.detectLanguage = function () {
      if (sessionService.checkUser() && !$rootScope.demo) {
        $rootScope.language = sessionService.language();
      } else {
        var language = navigator.language || navigator.userLanguage;

        if (language.indexOf('es') > -1)
          $rootScope.language = 'es';
        else if (language.indexOf('fr') > -1)
          $rootScope.language = 'fr';
        else if (language.indexOf('it') > -1)
          $rootScope.language = 'it';
        else if (language.indexOf('pt') > -1)
          $rootScope.language = 'pt';
        else if (language.indexOf('de') > -1)
          $rootScope.language = 'de';
        else
          $rootScope.language = 'en';
      }
    };

    $rootScope.detectLanguage();


    // I18n
    $rootScope.i18n = function (path) {
      return byString(I18n.translations[$rootScope.language || "es"], path);
    };
  }]);
