Airzone.controller('addUserToDeviceController', ['$scope', '$rootScope', '$state', '$stateParams', '$window', 'deviceService', 'operatingSystemService',
  function ($scope, $rootScope, $state, $stateParams, $window, deviceService, operatingSystemService) {
    $scope.loader = true;
    $scope.type = 'basic';
    $scope.class_type = false;
    $scope.show_error = false;
    $scope.show_success = false;
    $scope.device_relation = null;
    $scope.new_device_relation = null;

    initialize();

    /*
     * Called when controller is loaded
     */
    function initialize() {

      deviceService.getDeviceRelation($stateParams.device_relation_id).then(
        function (data) {
          $scope.device_relation = data;
        });

    }

    /**
     * On submit user form
     */
    $scope.submitAddUserForm = function () {
      if ($scope.addUserForm.$valid) {
        let params = {
          type: $scope.type,
          name: $scope.new_user.name,
          email: $scope.new_user.email.toLowerCase(),
          surname: $scope.new_user.surname,
          pin: $scope.device_relation.device.pin,
          mac: $scope.device_relation.device.mac
        }

        deviceService.createDeviceRelation(params).then(
          function (data) {
            $scope.new_device_relation = data.device_relation;
            $scope.show_success = true;
          }, function (data) {
            if (data !== "User migrated."){
              $scope.taken_error = true;
              $scope.show_error = true;
            } else if (data === "User migrated."){
              console.log('Invitation of migrated user');
              let type = operatingSystemService.detectOperatingSystem();
              $scope.show_android = type.android;
              $scope.show_ios = type.ios;
              $scope.user_migrated_invitation = true;
            }
          });

      } else {
        $scope.addUserForm.invalid = true;
      }
    }

    /*
     * Change the state between basic and advanced
     */
    $scope.changeState = function () {
      $scope.class_type = !$scope.class_type;
      if ($scope.type == 'advanced') {
        $scope.type = 'basic';
      } else {
        $scope.type = 'advanced';
      }
    };

    /*
      * If relation type is advanced redirecto to server_users
      * If relation type is basic show permissions page
      */
    $scope.acceptMessage = function () {
      if ($scope.type == 'basic') {
        $state.go('home.users', {
          device_relation_id: $scope.device_relation.id,
          permission_relation_id: $scope.new_device_relation.id
        });
      }
      else {
        $state.go('home.users', { device_relation_id: $scope.device_relation.id });
      }
    };

    /*
     * Hide error message and show form
     */
    $scope.acceptError = function () {
      $scope.show_error = false;
      $scope.taken_error = false;
    }

    /*
   * Open Itunnes Apple
   */
  $scope.openItunesApple = function () {
    $window.open(operatingSystemService.goToNewItunesApple(), '_system', 'location=no');
  }

  /*
   * Open Itunnes Apple
   */
  $scope.openPlayGoogle = function () {
    $window.open(operatingSystemService.goToNewPlayGoogle(), '_system', 'location=no');
  }

  /*
   * Recharge the page when click
   */
  $scope.closeModal = function () {
    $scope.user_migrated_invitation = false;
  }
  }]);
