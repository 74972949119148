Airzone.controller('enableServerSchedulesModalController', ['$scope', '$rootScope',
  function ($scope, $rootScope) {

    /*
     * Translate strint 't'
     * Solved bug (can't call $rootScope directly)
     */
    $scope.i18n = function (t) {
      return $rootScope.i18n(t);
    };

    /*
     * Recharge the page when click
     */
    $scope.enablePrograms = function () {
      // TO DO => update enabled_programs in backend
      console.log("Enable programs");
      $rootScope.server.programs_enabled = true;
      $rootScope.show_enable_server_schedules_message = false;
    }

    /*
     * Close modal
     */
    $scope.close = function () {
      // TO DO => remove programs in backend
      console.log("Remove programs");
      $rootScope.show_enable_server_schedules_message = false;
    };

  }]);
