Airzone.controller('registerController', ['$scope', '$rootScope', '$stateParams', '$http', function ($scope, $rootScope, $stateParams, $http) {
  // Flag to show or hide error messages
  $scope.register_error = false;
  $scope.register_success = false;

  // Flag to switch view to show
  // values: [register, terms]
  $scope.state = 'register'
  $scope.invalid = false;
  $rootScope.info_hide = Boolean($stateParams.info_hide);

  // show password inputs by default
  $scope.inputTypePass = 'password';
  $scope.inputTypePassConfirmation = 'password';
  $scope.showPassword = false;
  $scope.showPasswordConfirmation = false;

  // Get the legal terms in appropriate language
  $scope.htmlString = $rootScope.i18n('terms.body');
  $http.get("/terms.json").success(function (response) {
    var terms = response[$rootScope.language];
    if (!terms) { terms = response.en; }
    $scope.terms = terms;
  });

  /*
   * POST register request to server and proccess response
   */
  $scope.submitRegisterForm = function () {
    if ($scope.registerForm.$valid) {
      $http({
        method: 'POST',
        url: url_path + 'users',
        responseType: 'json',
        data: {
          user: {
            name: this.register.name,
            surname: this.register.surname,
            email: this.register.email,
            password: this.register.password,
            password_confirmation: this.register.password_confirmation,
            tos: true,
            publicity: this.register.publicity,
            language: $rootScope.language,
            gRecaptchaResponse: $scope.registerForm.gRecaptchaResponse
          }
        }
      }).success(function (data, status, headers, config) {
        //localStorageService.set('token', data['user']['authentication_token'] );
        $scope.state = 'register_success';
      }).error(function (data, status, headers, config) {
        $scope.registerForm.invalid = true;
        if (status === 433) {
          $scope.registerForm.$error.recaptcha_response = true;
        } else {
          $scope.register_error = true;
        }
      });
    } else {
      $scope.registerForm.invalid = true;
    }
  }

  /*
   * Hide & show password function
   */
  $scope.hideShowPassword = function () {
    if ($scope.inputTypePass == 'password') {
      $scope.showPassword = true;
      $scope.inputTypePass = 'text';
    } else {
      $scope.showPassword = false;
      $scope.inputTypePass = 'password';
    }
  }

  /*
   * Hide & show password confirmation function
   */
  $scope.hideShowPasswordConfirmation = function () {
    if ($scope.inputTypePassConfirmation == 'password') {
      $scope.showPasswordConfirmation = true;
      $scope.inputTypePassConfirmation = 'text';
    } else {
      $scope.showPasswordConfirmation = false;
      $scope.inputTypePassConfirmation = 'password';
    }
  }
}]);
