Airzone.service('websocketsService',
  ['$rootScope', 'sessionService', 'zoneService', 'systemService', 'WEBSOCKETS_PUSHER_URL', 'WEBSOCKETS_PUSHER_PORT', 'URL_PATH',
    function ($rootScope, sessionService, zoneService, systemService, WEBSOCKETS_PUSHER_URL, WEBSOCKETS_PUSHER_PORT, URL_PATH) {

      //var dispatcher = null;
      var channels = {};

      // Pusher vars
      var app_key = '765ec374ae0a69f4ce44';
      var pusher = null;

      return ({
        init: createDispatcher,
        createServerChannel: createServerChannel,
        destroyChannels: destroyChannels,
        reconnect: reconnect,
        destroy: destroy
      });

      function createDispatcher() {
        if (!$rootScope.demo && !pusher && sessionService.token()) {
          Pusher.host = WEBSOCKETS_PUSHER_URL;
          Pusher.ws_port = WEBSOCKETS_PUSHER_PORT;
          Pusher.wss_port = WEBSOCKETS_PUSHER_PORT;

          var user_token = sessionService.token();
          var user_email = sessionService.email();
          var options = {
            enabledTransports: ['ws'],
            disableStats: true,
            ignoreNullOrigin: true,
            auth: {
              params: {
                user_token: user_token,
                user_email: user_email
              }
            },
            //encrypted: $rootScope.environment == 'production'
            encrypted: true
          }

          // if ($rootScope.environment == 'production')
          options['authEndpoint'] = URL_PATH + 'pusher/auth';

          // Config pusher
          pusher = new Pusher(app_key, options);

          // Pusher logs
          Pusher.log = function (data) {
            //console.log('\t\t', data);
          };
        }
      };

      function createServerChannel(channel_name, zoneCallback) {
        var name = 'private-' + channel_name;

        if (!$rootScope.demo && pusher && !channels[name]) {
          var channel = pusher.subscribe(name);
          if (zoneCallback !== undefined) {
            channels[name] = { channel: channel, cb: zoneCallback }
          } else {
            channels[name] = { channel: channel }
          }
          //channels[channel_name] = channel;
          bindChannel(name);

        } else if (channels[name] && channels[name].cb !== zoneCallback) {
          //en el caso de que el canal exista, pero ahora le agregamos/quitamos/cambiamos el callback =>
          channels[name].cb = zoneCallback;
        }
      };

      function bindChannel(channel_name) {
        //var channel = channels[channel_name];
        var channel = channels[channel_name].channel;
        var cb = null;
        if (channels[channel_name].cb)
          cb = channels[channel_name].cb;
        if (channel) {
          //channels[channel_name] = channel;

          channel.bind('zone', function (zone) {
            //zoneService.replaceZone(zone);
            if (cb !== null)
              cb(zoneService.replaceZone(zone, false));
            else
              zoneService.replaceZone(zone, true);
          });

          channel.bind('system', function (system) {
            systemService.replaceSystem(system);
          });

          channel.bind('zone_config', function (config) {
            if ($rootScope.modalShow) {
              zoneService.replaceConfig(config);
            }
          });

          channel.bind('system_config', function (config) {
            systemService.replaceConfig(config);
          });
          // System and zones schedules
          channel.bind('zone_schedules', function (data) {
            if ($rootScope.modalShow) {
              $rootScope.update_schedules = true;
              $rootScope.$apply();
            }
          });
          // Server schedules
          channel.bind('server_schedules', function (data) {
            console.log("Schedules channel: ", data);
            if ($rootScope.modalShow) {
              if ($rootScope.server_schedules_target) {
                $rootScope.update_new_schedules = true;
                // Altherma schedules
              } else if ($rootScope.altherma_schedules_target) {
                $rootScope.update_altherma_schedules = true;
               // ACS Radiant schedules
              } else if ($rootScope.acs_radiant_schedules_target) {
                $rootScope.update_acs_radiant_schedules = true;
              }
            }
            $rootScope.$apply();
          });
          channel.bind('server', function (data) {
            //detectar sistemas
            if (data.updated) {
              $rootScope.server_updated = true;
              $rootScope.$apply();
            }
            else if (data.error) {
              $rootScope.error_device = true;
              $rootScope.$apply();
            }
          });
        };
      };

      function reconnect() {
        if (pusher) {
          var channels_name = Object.keys(channels);

          // destroy();
          createDispatcher();

          if (channels_name) {
            channels_name.forEach(function (name) {
              createServerChannel(name);
            });
          }
        }
      };

      function destroyChannels() {
        if (pusher && channels) {
          for (var channel_name in channels) {
            pusher.unsubscribe(channel_name);
            //channels[channel_name].channel.destroy();
          }

          channels = {};
          retry = false;
        }
      };

      function destroy() {
        destroyChannels();

        if (pusher) {
          pusher.disconnect();
          pusher = null;
        }
      };
    }]);
