Airzone.service('deviceService',
  ['$http', '$q', '$rootScope', '$injector', 'sessionService', '$state', 'DEMO_EMAIL', 'MOBILE_APP',
    function ($http, $q, $rootScope, $injector, sessionService, $state, DEMO_EMAIL, MOBILE_APP) {

      if (!MOBILE_APP) {
        var $upload = $injector.get('$upload');
      }

      return ({
        getDeviceRelations: getDeviceRelations,
        deleteDeviceRelation: deleteDeviceRelation,
        getDeviceRelation: getDeviceRelation,
        createDevice: createDevice,
        createDevicesFile: createDevicesFile,
        createDeviceRelation: createDeviceRelation,
        updateDeviceRelation: updateDeviceRelation,
        getDeviceConnectionsCurrent: getDeviceConnectionsCurrent,
        getDeviceConnectionsTime: getDeviceConnectionsTime,
        destroy: destroy
      });

      //
      // Public methods
      //

      // Get devices from server
      function getDeviceRelations(device_id, forceUpdate, current_page, limit, filter) {
        forceUpdate = typeof forceUpdate !== 'undefined' ? forceUpdate : false;
        if ($rootScope && $rootScope.device_relations && !forceUpdate) {
          return $q.when($rootScope.device_relations);
        } else {
          if ($rootScope.user && $rootScope.user.email == DEMO_EMAIL)
            current_page = 2;

          if (filter){
            if (filter.hasOwnProperty("mac")){
              var mac = filter["mac"];
            }
            if (filter.hasOwnProperty("name")){
              var name = filter["name"];
            }
            if (filter.hasOwnProperty("location")){
              var location = filter["location"];
            }
          }
          var request = $http({
            method: "get",
            url: url_path + "device_relations/",
            params: {
              user_email: sessionService.email(),
              user_token: sessionService.token(),
              format: 'json',
              device_id: device_id,
              limit: limit,
              page: current_page,
              mac: mac,
              name: name,
              location: location
            },
            data: {
            }
          });
        }

        return (request.then(handleIndexSuccess, handleError));
      };

      function getDeviceRelation(id) {
        if ($rootScope.device_relations) {
          var devices = $rootScope.device_relations.filter(function (x) { return x.id == id });

          if (devices.length > 0) {
            return $q.when(devices[0]);
          }
        }

        var request = $http({
          method: "get",
          url: url_path + "device_relations/" + id,
          responseType: 'json',
          params: {
            user_email: sessionService.email(),
            user_token: sessionService.token(),
          }
        });

        return (request.then(handleGetSuccess, handleError));
      };

      /*
     * Remove a deviceRelation
     */
      function deleteDeviceRelation(device) {
        var request = $http({
          method: "delete",
          url: url_path + "device_relations/" + device.id + "?.json",
          params: {
            user_email: sessionService.email(),
            user_token: sessionService.token(),
          },
          data: {
          }
        });
        return (request.then(handleRemoveSuccess, handleError));
      };

      function handleError(response) {
        // Logout when unauthorized
        if (response.status == 401) {
          sessionService.unauthorize();
        } else if (response.status == 403) {
          $state.go('init.403');
          return ($q.reject("An unknown error occurred."));
        } else if (response.status == 404) {
          $state.go('init.404');
          return ($q.reject("An unknown error occurred."));
        } else if (response.status == 422 && response.data && response.data.errors && response.data.errors.user  && response.data.errors.user.indexOf("migrated") >= 0 ) {
          console.log('Device migrated');
          // $rootScope.new_cloud_message = true;
          return ($q.reject("User migrated."));
        }

        if (!angular.isObject(response.data) || !response.data.message) {
          return ($q.reject("An unknown error occurred."));
        }

        return ($q.reject(response.data.message));
      };

      function handleIndexSuccess(response) {
        return (response.data);
      };

      function handleGetSuccess(response) {
        return (response.data['device_relation']);
      };

      function handleRemoveSuccess(response) {
        return (response.data);
      };

      function handleCreateSuccess(response) {
        return (response.data);
      };

      /**
       * Create a device
       */
      function createDevice(mac, pin) {
        var request = $http({
          method: "post",
          url: url_path + "devices?.json",
          params: {
            user_email: sessionService.email(),
            user_token: sessionService.token(),
          },
          data: {
            device: {
              mac: mac,
              pin: pin
            }
          }
        });

        return (request.then(handleCreateSuccess, handleError));
      };

      /**
       * Create devices by file
       */
      function createDevicesFile(file) {
        var request = $upload.upload({
          method: "post",
          url: url_path + "import_devices?",
          params: {
            user_email: sessionService.email(),
            user_token: sessionService.token(),
            format: 'json'
          },
          data: {
            import_devices: {}
          },
          file: file
        });

        return (request.then(handleCreateSuccess, handleError));
      };

      /**
       * Create device relation
       */
      function createDeviceRelation(device_relation) {
        var request = $http({
          method: "post",
          url: url_path + "device_relations?",
          params: {
            user_email: sessionService.email(),
            user_token: sessionService.token(),
            format: 'json'
          },
          data: {
            device_relation: {
              pin: device_relation.pin,
              mac: device_relation.mac,
              email: device_relation.email,
              name: device_relation.name,
              surname: device_relation.surname,
              type: device_relation.type,
              hide_invitation: device_relation.hide_invitation
            }
          },
        });

        return (request.then(handleCreateSuccess, handleError));
      };

      /**
       * Update device relation
       */
      function updateDeviceRelation(device_relation) {
        var request = $http({
          method: "put",
          url: url_path + "device_relations/" + device_relation.id,
          params: {
            user_email: sessionService.email(),
            user_token: sessionService.token(),
            format: 'json'
          },
          data: {
            device_relation: {
              permitted_zones: device_relation.permitted_zones
            }
          },
        });

        return (request.then(handleCreateSuccess, handleError));
      };

      /**
       * Get device current connections
       */
      function getDeviceConnectionsCurrent(mac, from, to) {
        var mac_formatted = mac.replace(/:/g, "");

        var request = $http({
          method: "get",
          url: url_path + "device_connections_current?mac=" + mac_formatted,
          params: {
            user_email: sessionService.email(),
            user_token: sessionService.token(),
            format: 'json'
          }
        });

        return (request.then(handleIndexSuccess, handleError));
      };

      /**
       * Get device connections by periods or minutes
       */
      function getDeviceConnectionsTime(mac, periods, minutes) {
        var param_periods = ""
        var param_minutes = ""

        if (periods) {
          param_periods = "&periods=" + periods;
        }
        if (minutes) {
          param_minutes = "&minutes=" + minutes;
        }
        var request = $http({
          method: "get",
          url: url_path + "device_connections_time?mac=" + mac + param_periods + param_minutes,
          params: {
            user_email: sessionService.email(),
            user_token: sessionService.token(),
            format: 'json'
          }
        });

        return (request.then(handleIndexSuccess, handleError));
      };

      function destroy() {
        $rootScope.device_relations = null;
      };
    }]);
