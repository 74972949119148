Airzone.service('purifierService',
  ['$http', '$q', 'sessionService',
    function ($http, $q, sessionService ) {

      return ({
        create: createPurifier,
        find: findPurifier,
        updateSystem: updateSystem,
        update: updatePurifier,
        destroy: destroyPurifier
      });

      //
      // Public methods
      //

      // Send purifier data to server
      function createPurifier(purifier_data) {

        var request = $http({
          method: "post",
          url: url_path + "purifiers",
          params: {
            user_email: sessionService.email(),
            user_token: sessionService.token(),
          },
          data: {
            purifier: purifier_data
          }
        });

        return (request.then(handleCreateSuccess, handleError));
      }

      function findPurifier(device_id, system_number){
        var parameters = "?device_id=" + device_id;

        if (system_number){
          parameters += "&system_number=" + system_number
        } 
        
        var request = $http({
          method: "get",
          url: url_path + "purifiers" + parameters,
          params: {
            user_email: sessionService.email(),
            user_token: sessionService.token(),
          }
        });

        return (request.then(handleCreateSuccess, handleError));
      }

      function updateSystem(system){
        var request = $http({
          method: "put",
          url: url_path + "systems/" + system.id,
          params: {
            user_email: sessionService.email(),
            user_token: sessionService.token()
          },
          data: {purifier_warning: system.purifier_warning}
        });

        return (request.then(handleCreateSuccess, handleError))
      }

      function updatePurifier(purifier){
        var request = $http({
          method: "put",
          url: url_path + "purifiers/" + purifier.id,
          params: {
            user_email: sessionService.email(),
            user_token: sessionService.token(),
          },
          data: purifier
        });

        return (request.then(handleCreateSuccess, handleError));
      }

      function destroyPurifier(purifier) {
        var request = $http({
          method: "delete",
          url: url_path + "purifiers/" + purifier.id,
          params: {
            user_email: sessionService.email(),
            user_token: sessionService.token(),
          }
        });

        return (request.then(handleCreateSuccess, handleError));
      }

      function handleCreateSuccess(response) {
        return (response.data);
      }

      function handleError(response) {
        // Logout when unauthorized
        if (response.status == 401) {
          sessionService.unauthorize();
        }

        return ($q.reject(response.data.message));
      }
    }]);