Airzone.controller('adminAddServerController', ['$scope', '$rootScope', 'deviceService', '$state', '$http', '$upload', 'sessionService',
  function ($scope, $rootScope, deviceService, $state, $http, $upload, sessionService) {
    $scope.success_devices = 0;
    $scope.failure_devices = 0;
    $scope.already_register_mac = false;
    $scope.loader = false;
    $scope.file_error = false;
    $scope.erroneous_devices = [];

    /*
     * Add servers by file
     */
    $scope.submitAddServerFileForm = function () {
      $scope.file_error = false;
      if ($scope.addServerFileForm.$valid) {
        deviceService.createDevicesFile($scope.file).then(
          function (data) {
            $scope.loader = true;
            $rootScope.general_loader = true;
            $scope.import_device_id = data.import_devices.id
            interval = setInterval(function () {
              $http({
                method: "get",
                url: url_path + "import_devices/" + $scope.import_device_id,
                params: {
                  user_email: sessionService.email(),
                  user_token: sessionService.token(),
                  format: 'json'
                }
              }).success(function (data) {
                if (data['import_devices']['state'] == 'finished') {
                  clearInterval(interval);
                  $scope.erroneous_devices = data['import_devices']['failure_devices']
                  $scope.success_devices = data['import_devices']['success_devices'].length
                  $scope.failure_devices = data['import_devices']['failure_devices'].length
                  $scope.loader = false;
                  $rootScope.general_loader = false;
                }
              }).error(function (data, status, headers, config) {
                // Logout when unauthorized
                if (status == 401) {
                  sessionService.unauthorize();
                } else if (status == 403) {
                  $state.go('init.403');
                } else if (status == 404) {
                  $state.go('init.404');
                }

              });
            }, 1000);
          },
          function (error) {
            $scope.addServerFileForm.invalid = true;
            $scope.file_error = true;
          }
        );
      } else {
        $scope.addServerFileForm.invalid = true;
      }
    };

    /*
     * Add server manually
     */
    $scope.submitAddServerForm = function () {
      $scope.already_register_mac = false;
      if ($scope.addServerForm.$valid) {
        deviceService.createDevice($scope.server.mac, $scope.server.pin).then(
          function (data) {
            $scope.show_success = true;
          },
          function (error) {
            $scope.addServerForm.invalid = true;
            $scope.already_register_mac = true;
          }
        );
      } else {
        $scope.addServerForm.invalid = true;
      }
    };

    /*
     * Change $scope with file
     */
    $scope.onFileSelect = function ($files) {
      $scope.file = $files[0]
      $('input[name=file]').attr('title', $scope.file.name)
    };

    /*
     * Pattern function
     */
    $scope.macPattern = (function () {
      var regexp = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
      return {
        test: function (value) {
          return regexp.test(value);
        }
      };
    })();

    /*
     * Reload the page
     */
    $scope.reloadPage = function () {
      $state.go("home.addServer", {}, { reload: true });
    };
  }]);
