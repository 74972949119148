Airzone.service( 'operatingSystemService', [ '$rootScope', 'MOBILE_APP', 'ITUNES_APPLE', 'GOOGLE_PLAY',
  function( $rootScope, MOBILE_APP, ITUNES_APPLE, GOOGLE_PLAY ){
    return({
      goToNewItunesApple: goToNewItunesApple,
      goToNewPlayGoogle: goToNewPlayGoogle,
      detectOperatingSystem: detectOperatingSystem
    });

  //
  //Public functions
  //

  /*
   * Redirect to new itunes apple airzonecloud
   */
  function goToNewItunesApple() {
    if ($rootScope.language === 'en') {
      var lang = 'us';
    } else {
      lang = $rootScope.language;
    }
    var url = 'https://itunes.apple.com/' + lang + '/app/' + ITUNES_APPLE;
    
    return url
  }

  /*
   * Redirect to new play google airzonecloud
   */
  function goToNewPlayGoogle() {
    var url = 'https://play.google.com/store/apps/details?id=' + GOOGLE_PLAY;
    return url;
  }

  /**
   * Determine the mobile operating system.
   * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
   * 
   */
   function detectOperatingSystem() {
    let show_android = false;
    let show_ios = false;

    if (MOBILE_APP) {
      if (device.platform === 'Android') {
        show_android = true;
      }
      if (device.platform === 'iOS') {
        show_ios = true;
      }
    } else {
      if (getMobileOperatingSystem() === 'Android') {
        show_android = true;
      } else if (getMobileOperatingSystem() === 'iOS') {
        show_ios = true;
      } else {
        show_android = true;
        show_ios = true;
      }
    }
    return {
      android: show_android,
      ios: show_ios
    }
  }

  /**
   * Determine the mobile operating system.
   * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
   *
   * @returns {String}
   */
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
  }

}]);
