Airzone.controller('zoneController',
  ['$scope', '$rootScope', 'deviceService', 'systemService', 'zoneService', '$stateParams', '$state', 'eventsService', '$timeout', 'websocketsService', 'SystemUtilsService',
    function ($scope, $rootScope, deviceService, systemService, zoneService, $stateParams, $state, eventsService, $timeout, websocketsService, SystemUtilsService) {

      // https://github.com/revolunet/angular-carousel
      // Plugin del carousel utilizado para la temperatura

      $scope.modes_matching = ["stop", "cool-air", "heat-radiant", "ventilate", "heat-air", "heat-both", "dehumidify", "emergency-heat", "cool-radiant", "cool-both", "not_exit", "not_exit", "auto"];
      $scope.scenes = ["stop", "confort", "unocupied", "night", "eco", "vacation"];
      $scope.velocities = ["0", "1", "2", "3"];
      $scope.sleep_modes = ['sleep-off', 'sleep30', 'sleep60', 'sleep90'];

      // Definimos los rangos de temperatura
      $scope.minTemp;
      $scope.maxTemp;
      $scope.modes = [];
      $scope.mode = '';
      $scope.velocity = '';
      $scope.air_flow = '';

      // Carrusel
      $scope.listTemp = [20];
      $scope.tempIndex = -1;

      // show mode, velocity, auto modes and air_flow flags
      $scope.show_mode = false;
      $scope.show_air_flow = false;
      $scope.show_velocity = false;
      $scope.show_scene = false;
      $rootScope.zone_from_navigation = false;
      $rootScope.showMenuBtn = false;
      $scope.class_modal_auto = false;

      $scope.update_timeout;

      initialize();

      function initialize() {
        // Get zone
        zoneService.getZone($stateParams.id).then(function (zone) {
          $scope.zone = zone;
          $scope.zone.updated = true;
          $scope.system = systemService.getDatabaseSystem(zone.system_id);
          $scope.show_old_schedules = !$scope.zone.scene && !$rootScope.enabled_server_schedules;

          if (zone.modes) {
            $rootScope.firm_ws = $scope.system.firm_ws;
            if (parseFloat($rootScope.firm_ws) >= 3.17) {
              $scope.firmws_317 = true;
            }
            if (parseFloat($rootScope.firm_ws) >= 3.33) {
              $scope.firmws_333 = true;
            }
            if (parseFloat($rootScope.firm_ws) >= 3.0)
              $scope.modes_matching = ["stop", "cool", "heat", "ventilate", "heat", "heat", "dehumidify", "emergency-heat", "cool", "cool", "not_exit", "not_exit", "not_exit", "auto"];
            for (var i = 0; i <= zone.modes.length; i++) {
              if (zone.modes[i] == 1 && i != 7) {
                $scope.modes.push($scope.modes_matching[i]);
              }
            }
          }

          // Create websocket channel for server
          //websocketsService.destroyChannels();
          websocketsService.createServerChannel($scope.zone.device_id, websocketCallback);

          $scope.mode = $scope.modes_matching[parseInt(zone.mode)];

          $scope.velocity = zone.velocity;
          $scope.velMax = zone.velMax;
          $scope.air_flow = zone.air_flow;
          $scope.show_mode = $scope.zone.master == "1";

          // Change to auto heat if ZBS system is in auto heat mode
          $scope.zone.show_auto_modes = false;
          if ($scope.zone.auto == "1") {
            $scope.zone.show_auto_modes = true;
            if ($scope.zone.mode == "5")
              $scope.class_modal_auto = true;
          }

          // Sleep for ZBS System
          if ($scope.zone.scene)
            $scope.sleep_modes = ['timer-off', 'timer30', 'timer60', 'timer90'];

          $scope.show_velocity = $scope.zone.show_velocity == '1';
          $scope.show_air_flow = $scope.zone.air_flow != null;

          if ($rootScope.zone_from_navigation == false) {
            eventsService.createEvent('infozona', $scope.zone, null, null);
          }

          // load temp list
          loadListTemp();

          // init auto updated
          timeoutUpdate();
        });
      }



      /*
       * Función que se ejecuta cada vez que cambia la temperatura
       */
      $scope.listener1 = $scope.$watch('tempIndex', function (new_val, old_val, scope) {
        if (new_val != null && old_val != null && old_val != -1 && new_val != old_val) {
          // prevent first time when reload page
          if (old_val != -1)
            $scope.zone.reject = true;



          if (parseFloat($rootScope.firm_ws) >= 3.0 && ($scope.zone.offset !== null)) {
            // Lite ZBS
            if ($scope.system.scene) {
              if ($scope.system && $scope.system.temperature_unit == "1") {
                if ($scope.zone.auto != '1') {
                  if ($scope.zone.offset != parseFloat(((parseFloat($scope.listTemp[new_val]) - parseFloat($scope.zone.consign)))).toFixed(1)) {
                    $scope.zone.offset = parseFloat(parseFloat($scope.listTemp[new_val]) - parseFloat($scope.zone.consign)) / 2;

                    if ($scope.consign_timeout) {
                      $timeout.cancel($scope.consign_timeout);
                    }

                    $scope.consign_timeout = $timeout(function () {
                      eventsService.createEvent('modzona', $scope.zone, 'offset', $scope.zone.offset);
                    }, 1000);
                  } else if ($scope.zone.offset != ((parseInt($scope.listTemp[new_val]) - parseInt($scope.zone.consign)) / 2)) {
                    $scope.zone.offset = (parseInt($scope.listTemp[new_val]) - parseInt($scope.zone.consign)) / 2;

                    if ($scope.consign_timeout) {
                      $timeout.cancel($scope.consign_timeout);
                    }

                    $scope.consign_timeout = $timeout(function () {
                      eventsService.createEvent('modzona', $scope.zone, 'offset', $scope.zone.offset);
                    }, 1000);
                  }
                } else if ($scope.zone.auto == '1') {
                  if (!$scope.class_modal_auto) {
                    if ($scope.zone.cold_offset != ((parseInt($scope.listTemp[new_val]) - parseInt($scope.zone.coldConsign)) / 2)) {
                      $scope.zone.cold_offset = (parseInt($scope.listTemp[new_val]) - parseInt($scope.zone.coldConsign)) / 2;

                      if ($scope.consign_timeout) {
                        $timeout.cancel($scope.consign_timeout);
                      }

                      $scope.consign_timeout = $timeout(function () {
                        eventsService.createEvent('modzona', $scope.zone, 'cold_offset', $scope.zone.cold_offset);
                      }, 1000);
                    }
                  } else {
                    if ($scope.zone.heat_offset != ((parseInt($scope.listTemp[new_val]) - parseInt($scope.zone.heatConsign)) / 2)) {
                      $scope.zone.heat_offset = (parseInt($scope.listTemp[new_val]) - parseInt($scope.zone.heatConsign)) / 2;

                      $scope.consign_timeout = $timeout(function () {
                        eventsService.createEvent('modzona', $scope.zone, 'heat_offset', $scope.zone.heat_offset);
                      }, 1000);
                    }
                  }
                }
              } else {
                if ($scope.zone.auto != '1') {
                  if ($scope.zone.offset != (parseFloat($scope.listTemp[new_val]) - parseFloat($scope.zone.consign))) {
                    $scope.zone.offset = parseFloat($scope.listTemp[new_val]) - parseFloat($scope.zone.consign);

                    $scope.consign_timeout = $timeout(function () {
                      eventsService.createEvent('modzona', $scope.zone, 'offset', $scope.zone.offset);
                    }, 1000);
                  }
                } else if ($scope.zone.auto == '1') {
                  if (!$scope.class_modal_auto) {
                    if ($scope.zone.cold_offset != (parseFloat($scope.listTemp[new_val]) - parseFloat($scope.zone.coldConsign))) {
                      $scope.zone.cold_offset = parseFloat($scope.listTemp[new_val]) - parseFloat($scope.zone.coldConsign);

                      $scope.consign_timeout = $timeout(function () {
                        eventsService.createEvent('modzona', $scope.zone, 'cold_offset', $scope.zone.cold_offset);
                      }, 1000);
                    }
                  } else {
                    if ($scope.zone.heat_offset != (parseFloat($scope.listTemp[new_val]) - parseFloat($scope.zone.heatConsign))) {
                      $scope.zone.heat_offset = parseFloat($scope.listTemp[new_val]) - parseFloat($scope.zone.heatConsign);

                      $scope.consign_timeout = $timeout(function () {
                        eventsService.createEvent('modzona', $scope.zone, 'heat_offset', $scope.zone.heat_offset);
                      }, 1000);
                    }
                  }
                }
              }
            } else if ($scope.zone && parseFloat($scope.zone.consign) + $scope.zone.offset != $scope.listTemp[$scope.tempIndex]) {
              $scope.zone.offset = parseFloat($scope.listTemp[new_val]) - parseFloat($scope.zone.consign);

              $scope.consign_timeout = $timeout(function () {
                eventsService.createEvent('modzona', $scope.zone, 'offset', $scope.zone.offset);
              }, 1000);
            }
          } else {
            if ($scope.zone && $scope.zone.consign != $scope.listTemp[$scope.tempIndex]) {
              if ($scope.zone.auto == '1') {
                if ($scope.zone.temperature_unit == "1")
                  $scope.zone.consign = parseInt($scope.listTemp[$scope.tempIndex]);
                else
                  $scope.zone.consign = parseFloat($scope.listTemp[$scope.tempIndex]).toFixed(1);

                if ($scope.class_modal_auto)
                  var consign_type = 'heat_consign';
                else if (!$scope.class_modal_auto)
                  consign_type = 'cold_consign';

                if (($scope.class_modal_auto && $scope.zone.heatConsign != $scope.listTemp[$scope.tempIndex]) || (!$scope.class_modal_auto && $scope.zone.coldConsign != $scope.listTemp[$scope.tempIndex])) {
                  if ($scope.class_modal_auto) {
                    $scope.zone.heatConsign = $scope.zone.consign;
                  } else if (!$scope.class_modal_auto) {
                    $scope.zone.coldConsign = $scope.zone.consign;
                  }

                  // Stop update consign by websocket
                  if ($scope.update_timeout){
                    $timeout.cancel($scope.update_timeout);
                    timeoutUpdate();
                  }

                  $scope.consign_timeout = $timeout(function () {
                    eventsService.createEvent('modzona', $scope.zone, consign_type, $scope.zone.consign);
                  }, 1000);
                }

              } else {
                $scope.zone.consign = parseFloat($scope.listTemp[$scope.tempIndex]).toFixed(1);

                $scope.consign_timeout = $timeout(function () {
                  eventsService.createEvent('modzona', $scope.zone, 'consign', $scope.zone.consign);
                }, 1000);
              }
            };
          }
        };
      });

      function websocketCallback(zone) {
        if (zone !== null && zone.id === $scope.zone.id) {

          Object.keys(zone).forEach(function (key) {
            $scope.zone[key] = zone[key];
          });

          updateTempIndex();
          $scope.zone.updated = true;
          $scope.zone.last_field = false;
          $scope.$apply();
        }
      }

      /*
       * Set system auto mode
       */
      $scope.listener3 = $scope.$watch('zone.auto', function (new_val, old_val, scope) {
        if (new_val != old_val) {
          if ($scope.zone.auto == "1") {
            if ($scope.zone.mode == "5")
              $scope.class_modal_auto = true;
            else
              $scope.class_modal_auto = false;
            $scope.zone.mode = "13";
          }
        }
      });

      /*
       * Listen websockets and update mode when consign of zone change
       */
      $scope.listener4 = $scope.$watch('zone.mode', function (new_val, old_val, scope) {
        if (parseInt(new_val) !== parseInt(old_val) && new_val !== false && old_val != undefined) {
          $scope.mode = $scope.modes_matching[parseInt(new_val)];
          var system = systemService.getDatabaseSystem($scope.zone.system_id);

          if (system) {
            system.mode = new_val;
          }

          if ($scope.zone.auto == '1') {
            $scope.zone.show_auto_modes = true;
            if ($scope.zone.mode == "5")
              $scope.class_modal_auto = true;
            else if ($scope.zone.mode == "9")
              $scope.class_modal_auto = false;
          } else
            $scope.zone.show_auto_modes = false;

          if ($scope.zone.updated) {
            loadListTemp();
          }
        };
      });

      /*
       * Listen websockets and update velocity when consign of zone change
       */
      $scope.listener5 = $scope.$watch('zone.velocity', function (new_val, old_val, scope) {
        if (new_val !== old_val && new_val !== false) {
          $scope.velocity = new_val;
        };
      });

      /*
       * Listen websockets and update scene when consign of zone change
       */
      $scope.listener11 = $scope.$watch('zone.scene', function (new_val, old_val, scope) {
        if (new_val && new_val !== old_val && new_val !== false) {
          $scope.zone.scene = new_val;
          $scope.class_scene = getClassFromScene(new_val);
        };
      });

      /*
       * Listen modal mode change
       */
      $scope.listener6 = $scope.$watch('zone.new_mode', function (new_val, old_val, scope) {
        if (new_val !== old_val && new_val !== false && old_val != undefined) {
          if ($scope.zone.auto == '1') {
            eventsService.createEvent('modzona', $scope.zone, 'auto', 0);
            $scope.zone.auto = '0';
          }

          $scope.mode = $scope.modes_matching[parseInt(new_val)];

          if ($scope.mode == 'auto')
            $scope.zone.show_auto_modes = true;
          else
            $scope.zone.show_auto_modes = false;

          if (parseFloat($rootScope.firm_ws) >= 3.0) {
            if (new_val == 1)
              new_val = 9;
            else if (new_val == 2)
              new_val = 5;
          }

          if ($scope.system.setup_type !== '3' && $scope.system.setup_type != '6') {
            eventsService.createEvent('modsistema', $scope.system, 'mode', new_val);
          }
          else {
            if (new_val != 13) {
              if ($scope.mode_timeout) {
                $timeout.cancel($scope.mode_timeout);
              }
              // Set timeout for solve error when auto = 0 arrive after to backend
              $scope.mode_timeout = $timeout(function () {
                eventsService.createEvent('modzona', $scope.zone, 'mode', new_val);
              }, 100);
            } else
              eventsService.createEvent('modzona', $scope.zone, 'auto', 1);
            //eventsService.createEvent('infozona', $scope.zone, null, null);
          }

          $scope.zone.mode = new_val;

          // Supermaster
          if ($scope.system.master_setup) {
            $rootScope.systems.forEach(function (system) {
              if (system.id != $scope.system.id) {
                // If 4 pipes system then change the mode of master zones
                if (system.setup_type == "3") {
                  system.zones.forEach(function (zone) {
                    if (zone.master) {
                      eventsService.createEvent('modzona', zone, 'mode', $scope.zone.mode);
                    }
                  });
                  $timeout(function () {
                    eventsService.createEvent('infosistema', system, null, null);
                  }, 500);
                } else {
                  eventsService.createEvent('modsistema', system, 'mode', $scope.zone.mode);
                }
              }
            });
          }

          if (!$rootScope.demo)
            $scope.zone.updated = false;
        };
      });

      /*
       * Listen modal velocity change
       */
      $scope.listener7 = $scope.$watch('zone.new_velocity', function (new_val, old_val, scope) {
        if (new_val !== old_val && new_val !== false) {
          eventsService.createEvent('modzona', $scope.zone, 'velocity', new_val);
          $scope.zone.velocity = new_val;
        };
      });

      /*
       * Listen modal scene change
       */
      $scope.listener12 = $scope.$watch('zone.new_scene', function (new_val, old_val, scope) {
        if (new_val !== old_val && new_val !== false) {
          $scope.class_scene = (new_val);
          $scope.system.scene = new_val;
          eventsService.createEvent('modsistema', $scope.zone, 'scene', $scope.scenes.indexOf(new_val));
        };
      });

      /*
       * Stop update zone when modal is open
       * Return update when modal is closed
       */
      $scope.listener8 = $rootScope.$watch('modalShow', function (new_val, old_val, scope) {
        if (new_val && !old_val) {
          $scope.zone.reject = true;
          $timeout.cancel($scope.update_timeout);
        }
        else if (!new_val && old_val) {
          $scope.zone.reject = false;
          timeoutUpdate();
        }
      });

      /*
       * Listen websockets and update carrusel when consign of zone change
       */
      $scope.listener9 = $scope.$watch('zone.offset', function (new_val, old_val, scope) {
        if (new_val && old_val && new_val != old_val) {
          if ($scope.system && $scope.system.temperature_unit == "1") {
            $scope.tempIndex = $scope.listTemp.indexOf(parseInt($scope.zone.consign) + (parseInt($scope.zone.offset) * 2));
          } else {
            $scope.tempIndex = $scope.listTemp.indexOf(parseFloat(new_val + parseFloat($scope.zone.consign)).toFixed(1));
          }
        }
      });

      /*
       * Listen websockets and update air flow when consign of zone change
       */
      $scope.listener10 = $scope.$watch('zone.air_flow', function (new_val, old_val, scope) {
        if (new_val !== old_val && new_val !== false) {
          $scope.air_flow = new_val;
        };
      });

      /*
       * Listen modal air_flow change
       */
      $scope.listener13 = $scope.$watch('zone.new_air_flow', function (new_val, old_val, scope) {
        if (new_val !== old_val && new_val !== false) {
          eventsService.createEvent('modzona', $scope.zone, 'air_flow', new_val);
          $scope.zone.air_flow = new_val;
        };
      });

      /*
       * Get the ext temp
       */
      $scope.getTemp = function () {
        if ($scope.zone && $scope.zone.temp) {
          if ($scope.system && $scope.system.temperature_unit == "1") {
            if (parseInt($scope.zone.temp) <= 50)
              return "< 50";
            else if (parseInt($scope.zone.temp) >= 95)
              return "> 95";
            else
              return parseInt($scope.zone.temp);
          } else {
            if (parseFloat($scope.zone.temp) <= 10)
              return "< 10";
            else if (parseFloat($scope.zone.temp) >= 34.9)
              return "> 35";
            else
              return $scope.zone.temp;
          }
        }
      };

      /*
       * Get the humidity
       */
      $scope.getHumidity = function () {
        if ($scope.zone && $scope.zone.humidity)
          return $scope.zone.humidity;
      }

      /*
       * Show modal with Mode Options buttons
       */
      $scope.showModeOptions = function (server) {
        if (!$scope.system.auto_mode_battery_temperature) {
          $scope.zone.show_VAF_supply_heat = false;
          $scope.zone.show_dehumidifier = false;
          $rootScope.mode_target = $scope.zone;
        } else {
          $rootScope.show_alerts = true;
          $rootScope.auto_mode_battery_temperature = true;
        }
      };

      /*
       * Show modal with airFlow Options buttons
       */
      $scope.showAirFlowOptions = function (server) {
        $rootScope.air_flow_target = $scope.zone;
      };

      /*
       * Show modal with Velocity Options buttons
       */
      $scope.showVelocityOptions = function (server) {
        $rootScope.velocity_target = $scope.zone;
      };

      /*
       * Show modal with Scene Options buttons
       */
      $scope.showSceneOptions = function (server) {
        $rootScope.scene_target = $scope.zone;
      };

      /*
       * Return class for actual mode (ex: 0 -> stop)
       */
      $scope.getClassMode = function () {
        return $scope.modes_matching[parseInt($scope.zone.mode)];
      };

      /*
       * Return class for a scene (integer)
       */
      function getClassFromScene(scene) {
        return $scope.scenes[parseInt(scene)];
      }

      /*
       * Activate or deactivate zone
       */
      $scope.activateZone = function () {
        if ($scope.zone.state == '1')
          $scope.zone.state = '0';
        else
          $scope.zone.state = '1';

        $scope.system.power = SystemUtilsService.setSystemPower($scope.system);
        $scope.zone.reject = true;

        if ($scope.zone) {
          eventsService.createEvent('modzona', $scope.zone, 'state', $scope.zone.state);
          $scope.zone.reject = true;
        }
      };

      /*
       * Get Icon Mode
       */
      $scope.getIconMode = function () {
        if ($scope.zone && $scope.zone.auto == "1") {
          if ($scope.zone.mode == "0" || $scope.zone.mode == "13") {
            $scope.mode = "auto";
            return ("icon-auto");
          } else {
            return ("icon-" + $scope.mode + "-air");
          }
        } else
          return ("icon-" + $scope.mode);
      };

      /*
       * Get Icon Velocity
       */
      $scope.getIconVelocity = function () {

        if ($scope.velMax !== null && $scope.velocity !== '0') {
          //iconos nuevos si es mlp y no es auto
          return "icon-vel-mlp-" + $scope.velocity + " btn-large";
        } else {
          return ("icon-velocity" + $scope.velocity);
        }

      };

      /*
       * Get Icon AirFlow
       */
      $scope.getIconAirFlow = function () {
        return ("icon-airflow" + $scope.zone.air_flow);
      };

      /*
       * Return to servers page
       */
      $scope.goBack = function () {
        // Remove the loading in system and zones
        if ($scope.system && !$scope.system.updated) {
          $scope.system.updated = true;
          $scope.system.zones.forEach(function (zone) {
            zone.updated = true;
          });
        }
        $state.go('home.server', { rel: $stateParams.rel });
      };

      /*
       * Show modal with Adjust Options
       */
      $scope.showModalAdjust = function () {
        $rootScope.zone_config_target = $scope.zone;
      };

      /*
       * Show modal Schedules
       */
      $scope.showModalSchedules = function () {
        $rootScope.schedules_target = $scope.zone;
        $rootScope.schedules_target.config.updated = false;
      };

      /*
       * Turn off sleep
       */
      $scope.offSleep = function () {
        $scope.zone.sleep = '0';
        eventsService.createEvent('modzona', $scope.zone, 'sleep', '0');
      };

      /*
       * Turn auto mode
       */
      $scope.changeModeState = function () {
        $scope.class_modal_auto = !$scope.class_modal_auto;
        loadListTemp();
      };

      /*
       * Listen websockets and update carrusel when consign of zone change
       */
      $scope.listener2 = $scope.$watch('zone.consign', function (new_val, old_val, scope) {
        if (new_val && old_val && new_val != old_val && $scope.tempIndex !== $scope.listTemp.indexOf(new_val) && $scope.zone.auto != "1") {
          if ($scope.system && $scope.system.temperature_unit == "1") {
            $scope.tempIndex = $scope.listTemp.indexOf(parseInt(new_val));
          } else {
            $scope.tempIndex = $scope.listTemp.indexOf(new_val);
          }
        }
      });

      /*
       * Update cold offset when websockets update consign with cold offset
       */
      $scope.listener14 = $scope.$watch('zone.cold_offset', function (new_val, old_val, scope) {
        if (new_val != old_val && (($scope.zone.auto == "1" && !$scope.class_modal_auto) || ($scope.zone.mode == "9"))) {
          if ($scope.system && $scope.system.temperature_unit == "1")
            $scope.tempIndex = $scope.listTemp.indexOf(parseInt($scope.zone.coldConsign + (new_val * 2)));
          else
            $scope.tempIndex = $scope.listTemp.indexOf((parseFloat($scope.zone.coldConsign) + new_val).toFixed(1));
        }
      });

      /*
       * Update heat offset when websockets update consign with heat offset
       */
      $scope.listener15 = $scope.$watch('zone.heat_offset', function (new_val, old_val, scope) {
        if (new_val != old_val && (($scope.zone.auto == "1" && $scope.class_modal_auto) || ($scope.zone.mode == "5"))) {
          if ($scope.system && $scope.system.temperature_unit == "1")
            $scope.tempIndex = $scope.listTemp.indexOf(parseInt($scope.zone.heatConsign + (new_val * 2)));
          else
            $scope.tempIndex = $scope.listTemp.indexOf((parseFloat($scope.zone.heatConsign) + new_val).toFixed(1));
        }
      });

      /*
       * Update heatConsign when websockets update consign with offset
       */
      $scope.listener16 = $scope.$watch('zone.heatConsign', function (new_val, old_val, scope) {
        if (new_val != old_val) {
          if ($scope.zone && $scope.zone.heatConsign !== null && $scope.zone.auto == '1' && $scope.class_modal_auto) {
            if ($scope.system && $scope.system.temperature_unit == "1") {
              if ($scope.zone.offset !== null)
                $scope.tempIndex = $scope.listTemp.indexOf(parseInt($scope.zone.heatConsign + ($scope.zone.heat_offset * 2)));
              else
                $scope.tempIndex = $scope.listTemp.indexOf(parseInt($scope.zone.heatConsign));
            } else {
              if ($scope.zone.offset !== null)
                $scope.tempIndex = $scope.listTemp.indexOf(parseInt($scope.zone.heatConsign + $scope.zone.heat_offset).toFixed(1));
              else
                $scope.tempIndex = $scope.listTemp.indexOf(parseFloat($scope.zone.heatConsign).toFixed(1));
            }
          }
        }
      });

      /*
       * Update coldConsign when websockets update consign with offset
       */
      $scope.listener17 = $scope.$watch('zone.coldConsign', function (new_val, old_val, scope) {
        if (new_val != old_val) {
          if ($scope.zone && $scope.zone.coldConsign !== null && $scope.zone.auto == '1' && !$scope.class_modal_auto) {
            if ($scope.system && $scope.system.temperature_unit == "1") {
              if ($scope.zone.offset !== null)
                $scope.tempIndex = $scope.listTemp.indexOf(parseInt($scope.zone.coldConsign + ($scope.zone.cold_offset * 2)));
              else
                $scope.tempIndex = $scope.listTemp.indexOf(parseInt($scope.zone.coldConsign));
            } else {
              if ($scope.zone.offset !== null)
                $scope.tempIndex = $scope.listTemp.indexOf(parseFloat($scope.zone.coldConsign + $scope.zone.cold_offset).toFixed(1));
              else
                $scope.tempIndex = $scope.listTemp.indexOf(parseFloat($scope.zone.coldConsign).toFixed(1));
            }
          }
        }
      });

      /*
       * Remove timeouts
       */
      $scope.$on('$destroy', function () {
        $timeout.cancel($scope.update_timeout);

        $scope.listener1();
        $scope.listener2();
        $scope.listener3();
        $scope.listener4();
        $scope.listener5();
        $scope.listener6();
        $scope.listener7();
        $scope.listener8();
        $scope.listener9();
        $scope.listener10();
        $scope.listener11();
        $scope.listener12();
        $scope.listener13();
        $scope.listener14();
        $scope.listener15();
        $scope.listener16();
        $scope.listener17();
        $scope.listener18();
      });

      /*
       * Create event for update zone with websockets
       */
      function timeoutUpdate() {
        $scope.update_timeout = $timeout(function () {
          eventsService.createEvent('infozona', $scope.zone, null, null);
          timeoutUpdate();
        }, 30000);
      };

      /*
       * Generator list temperature
       */
      function generatorListTemp(min, max, mode) {
        var arrayTemp = [];
        var heat_mode = [2, 4, 5].indexOf(parseInt(mode)) > -1;
        var cool_mode = [1, 6, 8, 9].indexOf(parseInt(mode)) > -1;
        if ($scope.system && $scope.system.temperature_unit == "1") {
          var temp_change_value = 1;
          var minimum = 59;
          var maximum = 86;
        } else {
          var temp_change_value = 0.5;
          var minimum = 15.0;
          var maximum = 30.0;
        }

        if (min > minimum)
          minimum = min;
        if (max < maximum)
          maximum = max;

        if ($scope.zone.offset != null) {
          temp_change_value = 1;
          if ($scope.zone.eco == '0' || (!cool_mode && !heat_mode && $scope.zone.mode !== "0")) {
            if (min < (parseFloat($scope.zone.consign) - 3))
              min = (parseFloat($scope.zone.consign) - 3);
            if (max > (parseFloat($scope.zone.consign) + 3))
              max = (parseFloat($scope.zone.consign) + 3);
            minimum = min;
            maximum = max;
          } else {
            if ($scope.system && $scope.system.temperature_unit == "1") {
              temp_change_value = 2;
              if ($scope.zone.auto == "1") {
                if ($scope.class_modal_auto) {
                  if (max > parseInt($scope.zone.heatConsign) + 6.0)
                    max = (parseInt($scope.zone.heatConsign) + 6.0);
                  else if (max > parseInt($scope.zone.heatConsign) + 4.0)
                    max = (parseInt($scope.zone.heatConsign) + 4.0);
                  else if (max > parseInt($scope.zone.heatConsign) + 2.0)
                    max = (parseInt($scope.zone.heatConsign) + 2.0);
                  if (minimum < (parseInt($scope.zone.heatConsign) - 6.0))
                    minimum = (parseFloat($scope.zone.heatConsign) - 6.0);
                  maximum = max;
                  min = minimum;
                } else {
                  if (min < parseFloat($scope.zone.coldConsign) - 6.0)
                    min = (parseFloat($scope.zone.coldConsign) - 6.0);
                  else if (min < parseFloat($scope.zone.coldConsign) - 4.0)
                    min = (parseFloat($scope.zone.coldConsign) - 4.0);
                  else if (min < parseFloat($scope.zone.coldConsign) - 2.0)
                    min = (parseFloat($scope.zone.coldConsign) - 2.0);
                  maximum = (parseFloat($scope.zone.coldConsign) + 6.0);
                }
              } else {
                max = (parseInt($scope.zone.consign) + 6.0);
                min = (parseFloat($scope.zone.consign) - 6.0);
                maximum = max;
                minimum = min;
              }
            } else {
              if ($scope.zone.auto == "1") {
                if ($scope.class_modal_auto) {
                  if (max >= parseFloat($scope.zone.heatConsign) + 3.0)
                    max = (parseFloat($scope.zone.heatConsign) + 3.0);
                  else if (max >= parseFloat($scope.zone.heatConsign) + 2.0)
                    max = (parseFloat($scope.zone.heatConsign) + 2.0);
                  else if (max >= parseFloat($scope.zone.heatConsign) + 1.0)
                    max = (parseFloat($scope.zone.heatConsign) + 1.0);
                  if (minimum < (parseFloat($scope.zone.heatConsign) - 3.0))
                    minimum = (parseFloat($scope.zone.heatConsign) - 3.0);
                } else {
                  if (min <= parseFloat($scope.zone.coldConsign) - 3.0)
                    min = (parseFloat($scope.zone.coldConsign) - 3.0);
                  else if (min <= parseFloat($scope.zone.coldConsign) - 2.0)
                    min = (parseFloat($scope.zone.coldConsign) - 2.0);
                  else if (min <= parseFloat($scope.zone.coldConsign) - 1.0)
                    min = (parseFloat($scope.zone.coldConsign) - 1.0);
                  maximum = (parseFloat($scope.zone.coldConsign) + 3.0);
                }
              } else {
                if (heat_mode && (max > parseFloat($scope.zone.consign) + 3.0) && ($scope.zone.lower_conf_limit != '27.0'))
                  max = (parseFloat($scope.zone.consign) + 3.0);
                else if (heat_mode && (max > parseFloat($scope.zone.consign) + 2.0) && ($scope.zone.lower_conf_limit != '27.0'))
                  max = (parseFloat($scope.zone.consign) + 2.0);
                else if (heat_mode && (max > parseFloat($scope.zone.consign) + 1.0) && ($scope.zone.lower_conf_limit != '27.0'))
                  max = (parseFloat($scope.zone.consign) + 1.0);
                else if (cool_mode && (min < parseFloat($scope.zone.consign) - 3.0) && ($scope.zone.upper_conf_limit != '18.0'))
                  min = (parseFloat($scope.zone.consign) - 3.0);
                else if (cool_mode && (min < parseFloat($scope.zone.consign) - 2.0) && ($scope.zone.upper_conf_limit != '18.0'))
                  min = (parseFloat($scope.zone.consign) - 2.0);
                else if (cool_mode && (min < parseFloat($scope.zone.consign) - 1.0) && ($scope.zone.upper_conf_limit != '18.0'))
                  min = (parseFloat($scope.zone.consign) - 1.0);
                if (cool_mode)
                  maximum = (parseFloat($scope.zone.consign) + 3.0);
                if (heat_mode && minimum < (parseFloat($scope.zone.consign) - 3.0))
                  minimum = (parseFloat($scope.zone.consign) - 3.0);
              }
            }
          }
        }

        if (cool_mode && $scope.zone.cold_offset == null || (($scope.zone.auto == "1") && (!$scope.class_modal_auto))) {
          for (var i = min; i <= maximum; i += temp_change_value) {
            if ($scope.zone.temperature_unit == "1") {
              arrayTemp.push(i);
            } else {
              arrayTemp.push(parseFloat(i).toFixed(1));
            }
          }
        }
        else if (heat_mode && $scope.zone.heat_offset == null || (($scope.zone.auto == "1") && ($scope.class_modal_auto))) {
          for (var i = minimum; i <= max; i += temp_change_value) {
            if ($scope.zone.temperature_unit == "1") {
              arrayTemp.push(i);
            } else {
              arrayTemp.push(parseFloat(i).toFixed(1));
            }
          }

        }
        else {
          for (var i = minimum; i <= maximum; i += temp_change_value) {
            if ($scope.zone.temperature_unit == "1") {
              arrayTemp.push(i);
            } else {
              arrayTemp.push(parseFloat(i).toFixed(1));
            }
          }
        }

        return arrayTemp;
      }

      function updateTempIndex() {
        var index = $scope.tempIndex;
        if (parseFloat($rootScope.firm_ws) >= 3.0 && ($scope.zone.offset != null || $scope.zone.cold_offset != null)) {
          if ($scope.system && $scope.system.temperature_unit == "1") {
            index = $scope.listTemp.indexOf(parseInt($scope.zone.consign) + (parseInt($scope.zone.offset) * 2));
            if ($scope.zone.auto == '1') {
              if (!$scope.class_modal_auto) {
                index = $scope.listTemp.indexOf(parseInt($scope.zone.coldConsign) + (parseInt($scope.zone.cold_offset) * 2));
              } else {
                index = $scope.listTemp.indexOf(parseInt($scope.zone.heatConsign) + (parseInt($scope.zone.heat_offset) * 2));
              }
            }
          } else {
            index = $scope.listTemp.indexOf((parseFloat($scope.zone.consign) + $scope.zone.offset).toFixed(1));
            if ($scope.zone.auto == '1') {
              if ($scope.class_modal_auto)
                index = $scope.listTemp.indexOf((parseFloat($scope.zone.heatConsign) + $scope.zone.heat_offset).toFixed(1));
              else
                index = $scope.listTemp.indexOf((parseFloat($scope.zone.coldConsign) + $scope.zone.cold_offset).toFixed(1));
            }
          }
        } else {
          if ($scope.zone.auto == "1") {
            if ($scope.system && $scope.system.temperature_unit == "1") {
              if ($scope.class_modal_auto)
                index = $scope.listTemp.indexOf(parseInt($scope.zone.heatConsign));
              else
                index = $scope.listTemp.indexOf(parseInt($scope.zone.coldConsign));
            } else {
              if ($scope.class_modal_auto)
                index = $scope.listTemp.indexOf(parseFloat($scope.zone.heatConsign).toFixed(1));
              else
                index = $scope.listTemp.indexOf(parseFloat($scope.zone.coldConsign).toFixed(1));
            }
          } else {
            if ($scope.zone.temperature_unit == "1")
              index = $scope.listTemp.indexOf(parseInt($scope.zone.consign));
            else
              index = $scope.listTemp.indexOf(parseFloat($scope.zone.consign).toFixed(1));
          }
        }

        if ($scope.tempIndex !== index) {
          $timeout(function () {
            $scope.tempIndex = index;
          }, 500);
        }

        if ($scope.tempIndex < 0)
          $scope.tempIndex = 0;



        $scope.show_carrusel = true;
        $rootScope.show_carrusel = true;
      }

      /*
       * Load carrusel
       */
      function loadListTemp() {
        var limits = getLimits();
        if ($scope.system && $scope.system.temperature_unit == "1") {
          var minTemp = parseInt(limits.min);
          var maxTemp = parseInt(limits.max);
        } else {
          var minTemp = parseFloat(limits.min);
          var maxTemp = parseFloat(limits.max);
        }

        $scope.listTemp = generatorListTemp(minTemp, maxTemp, $scope.zone.mode);

        $rootScope.listTemp = $scope.listTemp;
        //$timeout(function(){

        updateTempIndex();


        //}, 500);
      };

      function getLimits() {
        var heat_mode = [2, 4, 5].indexOf(parseInt($scope.zone.mode)) > -1;
        var cool_mode = [1, 6, 8, 9].indexOf(parseInt($scope.zone.mode)) > -1;
        var country_code = $rootScope.system_country_code;
        var lower_limit, upper_limit;

        if (!country_code)
          country_code = 'ES';

        switch (parseInt($scope.system.eco)) {
          case 0:
            if (cool_mode) {
              lower_limit = $scope.zone.lower_conf_limit;
              upper_limit = 30.0;
            }
            else if (heat_mode) {
              lower_limit = 15.0;
              upper_limit = $scope.zone.upper_conf_limit;
            }
            else {
              lower_limit = 15.0;
              upper_limit = 30.0;
            }
            break;
          case 1:
            if (cool_mode) {
              lower_limit = $scope.zone.lower_limit;
              upper_limit = 30.0;
            }
            else if (heat_mode) {
              lower_limit = 15.0;
              upper_limit = $scope.zone.upper_limit;
            }
            else {
              lower_limit = 15.0;
              upper_limit = 30.0;
            }
            break;
          case 2:
            if (cool_mode) {
              lower_limit = 24.0;
              upper_limit = 30.0;
            }
            else if (heat_mode) {
              lower_limit = 15.0;
              if (country_code == 'IT')
                upper_limit = 21.0;
              else
                upper_limit = 22.0;
            }
            else {
              lower_limit = 15.0;
              upper_limit = 30.0;
            }
            break;
          case 3:
            if (cool_mode) {
              lower_limit = 25.0;
              upper_limit = 30.0;
            }
            else if (heat_mode) {
              lower_limit = 15.0;
              if (['IT', 'FR', 'BE'].indexOf(country_code) > -1)
                upper_limit = 20.5;
              else
                upper_limit = 21.5;
            }
            else {
              lower_limit = 15.0;
              upper_limit = 30.0;
            }
            break;
          case 4:
            if (cool_mode) {
              lower_limit = 26.0;
              upper_limit = 30.0;
            }
            else if (heat_mode) {
              lower_limit = 15.0;
              if (['FR', 'BE'].indexOf(country_code) > -1)
                upper_limit = 19.0;
              else if (country_code == 'IT')
                upper_limit = 20.0;
              else
                upper_limit = 21.0;
            }
            else {
              lower_limit = 15.0;
              upper_limit = 30.0;
            }
            break;
        }

        // For VAF in fahrenheit degree
        if ($scope.system && $scope.system.temperature_unit == "1") {
          // For Lite
          if ($scope.zone.offset != null){
            lower_limit = (parseInt($scope.zone.consign) - 6);
            upper_limit = (parseInt($scope.zone.consign) + 6);
            if ($scope.zone.auto == "1") {
              if (!$scope.class_modal_auto) {
                lower_limit = (parseInt($scope.zone.coldConsign) - 6);
                upper_limit = (parseInt($scope.zone.coldConsign) + 6);
              } else {
                lower_limit = (parseInt($scope.zone.heatConsign) - 6);
                upper_limit = (parseInt($scope.zone.heatConsign) + 6);
              }
            }
          }else{
            if (!lower_limit) {
              if (heat_mode || $scope.class_modal_auto)
                lower_limit = 59;
              else
                lower_limit = $scope.zone.lower_conf_limit; //59;
            }
            if (!upper_limit) {
              if (cool_mode || !$scope.class_modal_auto)
                upper_limit = 86;
              else
                upper_limit = $scope.zone.upper_conf_limit; //86;
            }
          }
        } else {
          if (!lower_limit) {
            // For Lite
            if ($scope.zone.offset != null){
              lower_limit = (parseFloat($scope.zone.consign) - 3.0);
              upper_limit = (parseFloat($scope.zone.consign) + 3.0);
              if ($scope.zone.auto == "1") {
                if (!$scope.class_modal_auto) {
                  lower_limit = (parseFloat($scope.zone.coldConsign) - 3.0);
                  upper_limit = (parseFloat($scope.zone.coldConsign) + 3.0);
                } else {
                  lower_limit = (parseFloat($scope.zone.heatConsign) - 3.0);
                  upper_limit = (parseFloat($scope.zone.heatConsign) + 3.0);
                }
              }
            }else{
              if (heat_mode || $scope.class_modal_auto)
                lower_limit = 15.0;
              else
                lower_limit = $scope.zone.lower_conf_limit; //15.0;
            }
          }
          if (!upper_limit) {
            if (cool_mode || !$scope.class_modal_auto)
              upper_limit = 30.0;
            else
              upper_limit = $scope.zone.upper_conf_limit; //30.0;
          }
        }

        return { min: lower_limit, max: upper_limit };
      }

      /*
       * Update vla zone when it's changed in websockets or zone adjust (infouser)
       */
      $scope.listener18 = $scope.$watch('zone.vla', function (new_val, old_val, scope) {
        if (new_val != old_val) {
          console.log("Zone vla: ", $scope.zone.vla);
          //console.log("Watch: ", $scope.zone.state);
          $scope.zone.val = new_val;
        }
      });

      /*
       * Change value of vla and create event
       */
      $scope.turnOnOffVentilation = function () {
        if ($scope.zone.vla === '0')
          $scope.zone.vla = "1";
        else
          $scope.zone.vla = "0";

        eventsService.createEvent('moduser', $scope.zone, 'vla', $scope.zone.vla);
      };

      /*
       * Show the VAF supply heat
       */
      $scope.showSupplyHeat = function () {
        $scope.zone.show_VAF_supply_heat = true;
        $rootScope.mode_target = $scope.zone;
      };

      /*
       * Show the dehumidifier
       */
      $scope.showDehumidifier = function () {
        $scope.zone.show_dehumidifier = true;
        $rootScope.mode_target = $scope.zone;
      };

    }]);
