Airzone.controller('loginController', ['$scope', '$rootScope', '$http', '$state', '$window', 'operatingSystemService', 'sessionService', 'URL_PATH', 'DEMO_EMAIL', 'MOBILE_APP', function ($scope, $rootScope, $http, $state, $window, operatingSystemService, sessionService, URL_PATH, DEMO_EMAIL, MOBILE_APP) {
  // Flag to show or hide error messages
  $scope.login_error = false;
  $scope.unconfirmed_error = false;
  $scope.password_error = false;

  // Flag to swith form to show
  // values: [login, password, register_success]
  $scope.state = 'login';
  $scope.invalid = false;
  $scope.eolo = __eolo;
  $scope.showPassword = false;
  $scope.inputType = 'password';
  $rootScope.current_page = 1;
  if (MOBILE_APP) {
    $scope.login = {};
    $scope.login_data = {};

    if (cordova.plugins) {
      var ss = new cordova.plugins.SecureStorage(
        function () {
          console.log('Success');
          $scope.init();
        },
        function (error) { console.log('Error ' + error); },
        'Airzonecloud-2.1.5');
    }

    // Remember email and password if exists
    $scope.init = function () {
      if (ss) {
        ss.get(function (value) {

          $scope.login_data.email = value;
          //ahora cogemos el password
          ss.get(function (value) {

            $scope.login_data.password = value;
            //if ($scope.login_data && $scope.login_data.email && $scope.login_data.password){
            $scope.login.email = $scope.login_data.email;
            $scope.login.password = $scope.login_data.password;
            $scope.$apply();
            //}
          }, function (error) {
            console.log('Error ' + error);
          }, 'password');
        }, function (error) {
          console.log('Error ' + error);
        }, 'email');
      }
    }
  }

  /*
   * Init session with email and password
   */
  $scope.submitLoginForm = function () {
    if ($scope.loginForm.$valid) {
      if (MOBILE_APP) {
        var data = {
          email: $scope.login.email,
          password: $scope.login.password
        }
        if (ss && $scope.login.email !== DEMO_EMAIL) {
          //guardamos primero mail y despues contraseña
          ss.set(function (key) {
            //ahora contraseña
            ss.set(function (key) {
            }, function (error) {
              console.log('Error ' + error);
            }, 'password', $scope.login.password);
          }, function (error) {
            console.log('Error ' + error);
          }, 'email', $scope.login.email);
        }
      } else {
        data = {
          email: this.login.email,
          password: this.login.password
        }
      }
      $http({
        method: 'POST',
        url: url_path + 'users/sign_in',
        data: data
      }).success(function (data, status, headers, config) {
        if (__eolo && data['user']['role'] == 'admin') {
          $scope.loginForm.invalid = true;
          $scope.login_error = true;
          $scope.unconfirmed_error = false;
        }
        else {
          sessionService.saveUserLocal(data)
          if (data['user']['role'] == 'admin' || data['user']['role'] == 'sales')
            $state.go('home.admin');
          else
            $state.go('home.servers')
        }

        $rootScope.detectLanguage();
      }).error(function (response, status, headers, config) {
        $scope.loginForm.invalid = true;

        if (response.errors && response.errors.length > 0 && response['errors'].indexOf('unconfirmed') != -1) {
          $scope.state = 'unconfirmed';
        } else if (status == 422 && response && response.errors && response.errors.user && response.errors.user.indexOf("migrated") >= 0) {
          console.log('User migrated');
          $scope.loginForm.invalid = false;
          data = operatingSystemService.detectOperatingSystem();
          $scope.show_android = data.android;
          $scope.show_ios = data.ios;
          $scope.new_cloud_message = true;
        }
        else {
          $scope.login_error = true;
          $scope.unconfirmed_error = false;
        }
      });
    } else {
      $scope.loginForm.invalid = true;
    }
  };

  /*
   * POST change_password request to server and proccess response
   */
  $scope.submitPasswordForm = function () {
    if ($scope.passwordForm.$valid) {
      if (MOBILE_APP) {
        var data = {
          user: {
            email: this.password.email
          }
        }
      } else {
        data = {
          user: {
            email: this.password.email,
            gRecaptchaResponse: $scope.password.gRecaptchaResponse
          }
        }
      }
      $http({
        method: 'POST',
        url: url_path + 'users/password',
        responseType: 'json',
        data: data
      }).success(function (data, status, headers, config) {
        $scope.state = 'reset_success';
      }).error(function (data, status, headers, config) {
        if (MOBILE_APP) {
          $scope.passwordForm.invalid = true;
          $scope.email_not_exist_error = true;
        } else {
          if (status == 422 && data && data.errors && data.errors.user && data.errors.user.indexOf("migrated") >= 0) {
            console.log('User migrated');
            $scope.loginForm.invalid = false;
            data = operatingSystemService.detectOperatingSystem();
            $scope.show_android = data.android;
            $scope.show_ios = data.ios;
            $scope.new_cloud_message = true;
          } else if (status == 422) {
            $scope.state = 'reset_success';
          } else if (status == 433) {
            $scope.passwordForm.$error.recaptcha_response = true;
          } else if (data === "User migrated.") {

          } else {
            $scope.email_not_exist_error = true;
          }
        }
      });
    } else {
      $scope.passwordForm.invalid = true;
    }
  };

  /*
   * Enter to demo
   */
  $scope.submitDemoform = function () {
    $http({
      method: 'POST',
      url: url_path + 'users/sign_in',
      data: {
        email: DEMO_EMAIL,
        password: 'foobarfoo'
      }
    }).success(function (data, status, headers, config) {
      sessionService.saveUserLocal(data)
      $rootScope.demo = true;
      $state.go('home.servers');
    }).error(function (data, status, headers, config) {
      $scope.loginForm.invalid = true;
    });
  };

  /*
   * Hide & show password function
   */
  $scope.hideShowPassword = function () {
    if ($scope.inputType == 'password') {
      $scope.showPassword = true;
      $scope.inputType = 'text';
    } else {
      $scope.showPassword = false;
      $scope.inputType = 'password';
    }
  };

  /*
  * Redirect to register web
  */
  $scope.goRegister = function () {
    var url = URL_PATH + '#/init/register?info_hide=true';
    openUrl(url, true);
  };

  /*
   * Open url in new window
   */
  function openUrl(url, readerMode) {
    SafariViewController.isAvailable(function (available) {
      if (available) {
        SafariViewController.show({
          url: url,
          hidden: false, // default false. You can use this to load cookies etc in the background (see issue #1 for details).
          animated: true, // default true, note that 'hide' will reuse this preference (the 'Done' button will always animate though)
          transition: 'curl', // (this only works in iOS 9.1/9.2 and lower) unless animated is false you can choose from: curl, flip, fade, slide (default)
          enterReaderModeIfAvailable: readerMode/*, // default false
          tintColor: "#00ffff", // default is ios blue
          barColor: "#0000ff", // on iOS 10+ you can change the background color as well
          controlTintColor: "#ffffff"*/ // on iOS 10+ you can override the default tintColor
        },
          // this success handler will be invoked for the lifecycle events 'opened', 'loaded' and 'closed'
          function (result) {
            if (result.event === 'opened') {
              console.log('opened');
            } else if (result.event === 'loaded') {
              console.log('loaded');
            } else if (result.event === 'closed') {
              console.log('closed');
            }
          },
          function (msg) {
            console.log("KO: " + msg);
          })
      } else {
        // In Android potentially powered by InAppBrowser because that (currently) clobbers window.open
        cordova.InAppBrowser.open(url, '_system', 'location=no');
      }
    })
  }

  /*
   * Open Itunnes Apple
   */
  $scope.openItunesApple = function () {
    $window.open(operatingSystemService.goToNewItunesApple(), '_system', 'location=no');
  }

  /*
   * Open Itunnes Apple
   */
  $scope.openPlayGoogle = function () {
    $window.open(operatingSystemService.goToNewPlayGoogle(), '_system', 'location=no');
  }

  /*
   * Recharge the page when click
   */
  $scope.closeModal = function () {
    $scope.new_cloud_message = false;
  }
}]);
