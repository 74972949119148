Airzone.controller('modalConfigZoneController',
  ['$scope', '$rootScope', 'zoneService', 'eventsService', 'MOBILE_APP',
    function ($scope, $rootScope, zoneService, eventsService, MOBILE_APP) {

      $scope.showHelp = false;

      // Constants
      $scope.heat_air_types = ['air', 'radiant', 'combined'];
      $scope.cold_air_types = ['air', 'radiant', 'combined'];
      $scope.air_angles = ['0', '1', '2', '3'];
      $scope.air_positions = ['0', '1', '2', '3', 'swing'];
      $scope.sleep_modes = ['sleep-off', 'sleep30', 'sleep60', 'sleep90'];
      $scope.modes = ["stop", "cool-air", "heat-radiant", "ventilate", "heat-air", "heat-both", "dehumidify", "not_exit", "cool-radiant", "cool-both"];
      $scope.modes_matching = $scope.modes;
      $scope.available_modes = [];

      // Variables and flags
      $scope.show = false;
      $scope.config = null;
      $scope.name_timeout = null;

      // Config class
      $scope.cold_type_class = null;
      $scope.heat_type_class = null;
      $scope.cold_angle_class = null;
      $scope.heat_angle_class = null;
      $scope.horizontal_position_class = null;
      $scope.vertical_position_class = null;
      $scope.sleep_mode_class = null;

      // Config flags
      $scope.defrosting = null;
      $scope.vla = null;
      $scope.show_vla = false;
      $scope.show_antifreeze = false;
      $scope.broadcast = false;
      $scope.show_adjust_new_firmware = false;
      $scope.antiFreeze_selected = false;

      // Prevent bottom space when keyboard hides
      $scope.scrollToTop = function($event){
        window.scrollTo(0, 0);
      }

      /*
       * Open modal
       */
      $scope.listener1 = $rootScope.$watch('zone_config_target', function (new_target, old_target, scope) {
        if (new_target && new_target.class == 'Zone') {
          $scope.system = null;
          $scope.zone = new_target;
          $scope.show = true;
          $rootScope.modalShow = true;
          $scope.show_vla = false;
          $scope.show_antifreeze = false;
          $scope.antiFreeze_selected = false;
          $scope.broadcast = false;
          $scope.show_adjust_new_firmware = false;
          $scope.show_led = false;
          $scope.propertySleep = false;
          $scope.propertyModeCold = false;
          $scope.propertyDefrost = false;
          $scope.propertyModeHeat = false;
          $scope.propertyAirDirectionCold = false;
          $scope.propertyAirDirectionHeat = false;
          $scope.propertyAirDirectionHorizontal = false;
          $scope.propertyAirDirectionVertical = false;
          $scope.vaf_zone = false;

          $scope.cold_type_class = null;
          $scope.heat_type_class = null;
          $scope.cold_angle_class = null;
          $scope.heat_angle_class = null;
          $scope.horizontal_position_class = null;
          $scope.vertical_position_class = null;
          $scope.sleep_mode_class = null;
          $scope.old_zone_name = $scope.zone.name;  

          // Sleep for ZBS System
          if ($scope.zone.scene) {
            $scope.sleep_modes = ['timer-off', 'timer30', 'timer60', 'timer90'];
            $scope.vaf_zone = true;
          }

          // Demo
          if ($rootScope.demo) {
            $scope.config = $scope.zone.config;
            $scope.config.updated = true;
            initializeZoneConfig();
          }
          else {
            eventsService.createEvent('infouser', $scope.zone, null, null);
            $rootScope.last_zone_number = $scope.zone.zone_number;
          }
        } else if (new_target && new_target.class == 'System') {
          $rootScope.modalShow = true;

          $scope.info_broadcast = {
            device_id: new_target.device_id,
            system_number: new_target.system_number,
            zone_number: 127
          };

          initializeBroadcastZoneConfig(new_target, old_target, scope);
        }
      });

      /*
       * Prevent websocket update
       */
      $scope.listener2 = $rootScope.$watch('zone_config', function (new_val, old_val, scope) {
        if (new_val && $rootScope.zone_config_target) {
          $scope.config = new_val;
          initializeZoneConfig();
        }
      });

      /*
       * Create event when led changes
       */
      $scope.change_led = function () {
        $scope.config.led = !$scope.config.led;
        eventsService.createEvent('moduser', $scope.zone, 'led', +$scope.config.led);
      };

      /**
       * Initialize view with config zone
       */
      function initializeZoneConfig() {
        // Set air types without combined option
        if ($scope.config.heat_values == "2")
          $scope.heat_air_types = ['air', 'radiant'];
        if ($scope.config.cold_values == "2")
          $scope.cold_air_types = ['air', 'radiant'];

        // Get sleep and timer mode class
        $scope.sleep_mode_class = "icon-" + $scope.sleep_modes[parseInt($scope.zone.sleep)];
        //$scope.sleep_mode_class = "icon-sleep-generic";
        // Get air type class
        if ($scope.config.cold_type)
          $scope.cold_type_class = "cold_" + $scope.cold_air_types[parseInt($scope.config.cold_type) - 1];
        else
          $scope.cold_type_class = null;

        if ($scope.config.heat_type)
          $scope.heat_type_class = "heat_" + $scope.heat_air_types[parseInt($scope.config.heat_type) - 1];
        else
          $scope.heat_type_class = null;

        // Get air angle class
        if ($scope.config.cold_angle)
          $scope.cold_angle_class = "icon-v" + $scope.air_angles[parseInt($scope.config.cold_angle)];
        if ($scope.config.heat_angle)
          $scope.heat_angle_class = "icon-v" + $scope.air_angles[parseInt($scope.config.heat_angle)];

        // Get air postions class
        if ($scope.config.swing_horizontal)
          $scope.horizontal_position_class = "icon-h" + $scope.air_positions[parseInt($scope.config.swing_horizontal)];
        if ($scope.config.swing_vertical)
          $scope.vertical_position_class = "icon-v" + $scope.air_positions[parseInt($scope.config.swing_vertical)];

        // Get defrosting flag
        if ($scope.config.antifreeze) {
          $scope.show_antifreeze = true;
          $scope.defrosting = parseInt($scope.config.antifreeze) == 1;
          $scope.antiFreeze_selected = true;
        }

        // Get local ventilation flag
        if ($scope.config.vla != null) {
          $scope.show_vla = true;
          $scope.vla = parseInt($scope.config.vla) == 1;
        }

        if (!$scope.show_adjust_new_firmware && $scope.zone.offset !== null) {
          // System with sondas
          $scope.show_adjust_new_firmware = true;
          // Modes
          if ($scope.zone.modes) {
            $scope.modes_matching = ["stop", "cool", "heat", "ventilate", "heat", "heat", "dehumidify", "not_exit", "cool", "cool"];
            $scope.modes = [];
            for (i = 0; i <= $scope.zone.modes.length; i++) {
              if ($scope.zone.modes[i] == 1 && i != 7)
                $scope.modes.push($scope.modes_matching[i]);
            }
          }

          // Led
          if ($scope.config.led) {
            $scope.show_led = true;
            $scope.config.led = ($scope.config.led === '1');
          }
        }
        $scope.class_mode = $scope.modes_matching[parseInt($scope.zone.mode)];
        $scope.broadcast = false;
      };

      /*
       * Initalize the config zone for broadcast
       */
      function initializeBroadcastZoneConfig(new_target, old_target, scope) {
        $scope.zone = null;
        $scope.show_heat_stages = false;
        $scope.show_cold_stages = false;
        $scope.system = new_target;
        $scope.config = new Object();
        if ($scope.system && (parseFloat($scope.system.firm_ws) > 3.05 || !$scope.system.firm_ws))
          $scope.broadcast = true;
        if ((parseFloat($scope.system.firm_ws) >= 3.12 && $scope.system.heat_stages) || (parseFloat($scope.system.firm_ws) < 3.12 && parseFloat($scope.system.system_fw) > 3.06))
          $scope.show_heat_stages = true;
        if ((parseFloat($scope.system.firm_ws) >= 3.12 && $scope.system.cold_stages) || (parseFloat($scope.system.firm_ws) < 3.12 && parseFloat($scope.system.system_fw) > 3.06))
          $scope.show_cold_stages = true;

        $scope.show = true;
        $scope.vaf_system = false;

        // Heat values for ZBS System
        $scope.sleep_mode_class = "icon-sleep-generic";

        if ($scope.system.scene) {
          $scope.heat_air_types = ['air', 'radiant', 'combo'];
          $scope.vaf_system = true;
          $scope.sleep_mode_class = "icon-run-for";
        }
        $scope.config.antifreeze = '0';
        $scope.config.vla = '0';

        $scope.show_antifreeze = true;
        $scope.antiFreeze_selected = false;
        $scope.defrosting = false;
        $scope.propertySleep = false;
        $scope.propertyModeCold = false;
        $scope.propertyDefrost = false;
        $scope.propertyModeHeat = false;
        $scope.propertyAirDirectionCold = false;
        $scope.propertyAirDirectionHeat = false;
        $scope.propertyAirDirectionHorizontal = false;
        $scope.propertyAirDirectionVertical = false;
        $scope.propertyVMCMode = false;

        $scope.cold_type_class = null;
        $scope.heat_type_class = null;
        $scope.cold_angle_class = null;
        $scope.heat_angle_class = null;
        $scope.horizontal_position_class = null;
        $scope.vertical_position_class = null;
        $scope.show_vla = false;

        // Initialize VMC parameters
        $scope.show_vmc = $scope.system.VMC_mode !== null && !$scope.system.has_purifier ? true : false;
        $scope.VMC_state = $scope.system.VMC_state === '1' ? true : false;

        $scope.old_system_name = $scope.system.name;
      }

      // Create event when change system name
      $scope.change_name = function (type, $event) {
        $scope.scrollToTop($event);
        var new_val = $event.target.value;
        if (type === 'system'){
          var old_name = $scope.old_system_name;
        }else if (type === 'zone'){
          old_name = $scope.old_zone_name;
        }

        if (new_val != old_name && new_val.length > 0 && new_val.length <= 11) {
            if ($scope.name_timeout) {
              clearTimeout($scope.name_timeout);
            }

            if (parseFloat($rootScope.firm_ws) >= 3.0)
              var name = new_val.replace(/[^ÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛÄËÏÖÜÃÕÑÇA-Záéíóúàèìòùâêîôûäëïöüãõñça-z-,\.!¿?()/\ _0-9\s]/g, "").substring(0, 11);
            else
              name = new_val.replace(/[^ÑA-Zña-z-,\./\ _0-9\s]/g, "").substring(0, 11);

            if (type === 'system'){
              $scope.system.name = name;
            }else if (type === 'zone'){
              $scope.zone.name = name;
            }
            // add event to action change name
            $scope.name_timeout = setTimeout(function () {
              if (type === 'system'){
                if (parseFloat($rootScope.firm_ws) >= 3.0)
                  eventsService.createEvent('modsistema', $scope.system, 'name', name);
                else
                  eventsService.createEvent('modsistema', $scope.system, 'name', name.toUpperCase());
              }else if (type === 'zone'){
                if (parseFloat($rootScope.firm_ws) >= 3.0)
                  eventsService.createEvent('modzona', $scope.zone, 'name', $scope.zone.name);
                else
                  eventsService.createEvent('modzona', $scope.zone, 'name', $scope.zone.name.toUpperCase());
              }
   
              if (!MOBILE_APP && type === 'system') {
                $scope.system.reject = true;
              }
            }, 1000);
        }else{
          $event.target.value = old_name;
        }
      }

      // Return if WS has firmare equal o greater than 3.20
      $scope.firm_ws_greater_equal_320 = function () {
        return ($scope.system && $scope.system.firm_ws && parseFloat($scope.system.firm_ws) >= 3.20)
      }

      /*
      * Update consign of zone (add or dedduct value)
      * Activate watch function
      */
      $scope.updateConsign = function (val) {
        var heat_mode = [2, 4, 5].indexOf(parseInt($scope.zone.mode)) > -1;
        var cool_mode = [1, 8, 9].indexOf(parseInt($scope.zone.mode)) > -1;
        if ($scope.zone && $scope.zone.temperature_unit == "1") {
          if (val == 0.5)
            val = 1;
          else if (val == -0.5)
            val = -1;
          var new_consign = parseInt($scope.zone.consign) + parseInt(val);
          var min = 59;
          if (cool_mode)
            min = 64;
        } else {
          var new_consign = (parseFloat($scope.zone.consign) + parseFloat(val)).toFixed(1);
          var min = 15;
          if (cool_mode)
            min = 18;
        }

        if ((new_consign >= min && new_consign <= 30) || (new_consign >= 59 && new_consign <= 86)) {
          if (!heat_mode && !cool_mode ||
            heat_mode && parseFloat(new_consign) <= parseFloat($scope.zone.upper_limit) ||
            cool_mode && parseFloat(new_consign) >= parseFloat($scope.zone.lower_limit)) {

            if ($scope.consign_timeout) {
              clearTimeout($scope.consign_timeout);
            }

            // add create events action to timeout
            $scope.consign_timeout = setTimeout(function () {
              if (parseFloat($rootScope.firm_ws) >= 3.0) {
                eventsService.createEvent('modzona', $scope.zone, 'consign', $scope.zone.consign);
                eventsService.createEvent('modzona', $scope.zone, 'offset', 0);
              } else {
                eventsService.createEvent('modzona', $scope.zone, 'consign', $scope.zone.consign);
              }
              $scope.zone.reject = false;
            }, 1000);

            $scope.zone.consign = new_consign;
            $scope.zone.reject = true; // block replaze zone until send event
          }
        }
      };

      /*
       * Show/Hide the VMC mode options
       */
      $scope.showVMCModeOptions = function () {
        $scope.propertyVMCMode = !$scope.propertyVMCMode;
      }

      /*
       * Change value of VMC mode
       */
      $scope.changeVMCMode = function (value) {
        $scope.propertyVMCMode = false;
        $scope.system.VMC_mode = value;
        $scope.show_VMC_state = ($scope.system.VMC_mode === '1') ? true : false;
        eventsService.createEvent('modsistema', $scope.system, 'VMC_mode', $scope.system.VMC_mode);
      }

      /*
       * Change value of VMC state
       */
      $scope.toggleVMCState = function () {
        $scope.VMC_state = !$scope.VMC_state;
        $scope.system.VMC_state = ($scope.VMC_state) ? '1' : '0';
        eventsService.createEvent('modsistema', $scope.system, 'VMC_state', $scope.system.VMC_state);
      }

      /*
       * Change value of defrosting/antifreeze and create event
       */
      $scope.setDefrost = function (val) {
        $scope.defrosting = val;
        $scope.antiFreeze_selected = true;

        if ($scope.defrosting)
          $scope.config.antifreeze = "1";
        else
          $scope.config.antifreeze = "0";

        if ($scope.broadcast) {
          eventsService.createEvent('moduser', $scope.info_broadcast, 'antifreeze', $scope.config.antifreeze);
        }
        else {
          eventsService.createEvent('moduser', $scope.zone, 'antifreeze', $scope.config.antifreeze);
          $scope.config.last_field = 'antifreeze';
          $scope.config.last_value = $scope.config.antifreeze;
        }
        $scope.propertyDefrost = false;
      };

      /*
       * Change value of vla and create event
       */
      $scope.toggleVla = function () {
        $scope.vla = !$scope.vla;

        if ($scope.vla)
          $scope.config.vla = "1";
        else
          $scope.config.vla = "0";

        if ($scope.broadcast) {
          eventsService.createEvent('moduser', $scope.info_broadcast, 'vla', $scope.config.vla);
        }
        else {
          eventsService.createEvent('moduser', $scope.zone, 'vla', $scope.config.vla);
          $scope.config.last_field = 'vla';
          $scope.config.last_value = $scope.config.vla;
        }
      };

      /*
       * Get Icon Mode
       */
      $scope.getIconMode = function () {
        return ("icon-" + $scope.class_mode);
      };

      /*
       * Set new sleep of zone, update class and hide options
       */
      $scope.setSleep = function (option) {
        if ($scope.broadcast) {
          eventsService.createEvent('modzona', $scope.info_broadcast, 'sleep', ($scope.sleep_modes.indexOf(option)).toString());
          eventsService.createEvent('infosistema', $scope.system, null, null);
        }
        else {
          $scope.zone.sleep = String($scope.sleep_modes.indexOf(option));
          eventsService.createEvent('modzona', $scope.zone, 'sleep', $scope.zone.sleep);
          $scope.zone.last_field = 'sleep';
          $scope.zone.last_value = $scope.zone.sleep;
        }

        $scope.sleep_mode_class = "icon-" + option;
        $scope.propertySleep = false;
      };

      /*
       * Set new cool_type_config of zone, update class and hide options
       */
      $scope.setColdType = function (option_index) {
        if ($scope.broadcast) {
          eventsService.createEvent('moduser', $scope.info_broadcast, 'cold_type', (option_index + 1).toString());
        }
        else {
          $scope.config.cold_type = (option_index + 1).toString();
          eventsService.createEvent('moduser', $scope.zone, 'cold_type', $scope.config.cold_type);
          $scope.config.last_field = 'cold_type';
          $scope.config.last_value = $scope.config.cold_type;
        }

        $scope.cold_type_class = "cold_" + $scope.cold_air_types[option_index];
        $scope.propertyModeCold = false;
      };

      /*
       * Set new heat_type of zone, update class and hide options
       */
      $scope.setHeatType = function (option_index) {
        if ($scope.broadcast) {
          eventsService.createEvent('moduser', $scope.info_broadcast, 'heat_type', (option_index + 1).toString());
        }
        else {
          $scope.config.heat_type = (option_index + 1).toString();
          eventsService.createEvent('moduser', $scope.zone, 'heat_type', $scope.config.heat_type);
          $scope.config.last_field = 'heat_type';
          $scope.config.last_value = $scope.config.heat_type;
        }

        $scope.heat_type_class = "heat_" + $scope.heat_air_types[option_index];
        $scope.propertyModeHeat = false;
      };

      /*
       * Set new cold_angle of zone, update class and hide options
       */
      $scope.setColdAngle = function (option_index) {
        if ($scope.broadcast) {
          eventsService.createEvent('moduser', $scope.info_broadcast, 'cold_angle', option_index.toString());
        }
        else {
          $scope.config.cold_angle = option_index.toString();
          eventsService.createEvent('moduser', $scope.zone, 'cold_angle', $scope.config.cold_angle);
          $scope.config.last_field = 'cold_angle';
          $scope.config.last_value = $scope.config.cold_angle;
        }
        $scope.cold_angle_class = "icon-v" + $scope.air_angles[option_index];
        $scope.propertyAirDirectionCold = false;
      };

      /*
       * Set new heat_angle of zone, update class and hide options
       */
      $scope.setHeatAngle = function (option_index) {
        if ($scope.broadcast) {
          eventsService.createEvent('moduser', $scope.info_broadcast, 'heat_angle', option_index.toString());
        }
        else {
          $scope.config.heat_angle = option_index.toString();
          eventsService.createEvent('moduser', $scope.zone, 'heat_angle', $scope.config.heat_angle);
          $scope.config.last_field = 'heat_angle';
          $scope.config.last_value = $scope.config.heat_angle;
        }

        $scope.heat_angle_class = "icon-v" + $scope.air_angles[option_index];
        $scope.propertyAirDirectionHeat = false;
      };

      /*
       * Set new swing_horizontal of zone, update class and hide options
       */
      $scope.setHorizontalPosition = function (option_index) {
        if ($scope.broadcast) {
          eventsService.createEvent('moduser', $scope.info_broadcast, 'swing_horizontal', String(option_index));
        }
        else {
          $scope.config.swing_horizontal = option_index.toString();
          eventsService.createEvent('moduser', $scope.zone, 'swing_horizontal', $scope.config.swing_horizontal);
          $scope.config.last_field = 'swing_horizontal';
          $scope.config.last_value = $scope.config.swing_horizontal;
        }

        $scope.horizontal_position_class = "icon-h" + $scope.air_positions[option_index];
        $scope.propertyAirDirectionHorizontal = false;
      };

      /*
       * Set new swing_vertical of zone, update class and hide options
       */
      $scope.setVerticalPosition = function (option_index) {
        if ($scope.broadcast) {
          eventsService.createEvent('moduser', $scope.info_broadcast, 'swing_vertical', option_index.toString());
        }
        else {
          $scope.config.swing_vertical = option_index.toString();
          eventsService.createEvent('moduser', $scope.zone, 'swing_vertical', $scope.config.swing_vertical);
          $scope.config.last_field = 'swing_vertical';
          $scope.config.last_value = $scope.config.swing_vertical;
        }

        $scope.vertical_position_class = "icon-v" + $scope.air_positions[option_index];
        $scope.propertyAirDirectionVertical = false;
      };

      /*
       * Return if this is selected sleep index
       */
      $scope.sleepSelected = function (index) {
        if ($scope.zone)
          return index == parseInt($scope.zone.sleep);
        else
          return false;
      };

      /*
       * Return if this is selected cool type index
       */
      $scope.coolTypeSelected = function (index) {
        if ($scope.config)
          return index == parseInt($scope.config.cold_type) - 1;
        else
          return false;
      };

      /*
       * Return if this is selected heat type index
       */
      $scope.heatTypeSelected = function (index) {
        if ($scope.config)
          return index == parseInt($scope.config.heat_type) - 1;
        else
          return false;
      };

      /*
       * Return if this is selected cool angle index
       */
      $scope.coolAngleSelected = function (index) {
        if ($scope.config)
          return index == parseInt($scope.config.cold_angle);
        else
          return false;
      };

      /*
       * Return if this is selected heat angle index
       */
      $scope.heatAngleSelected = function (index) {
        if ($scope.config)
          return index == parseInt($scope.config.heat_angle);
        else
          return false;
      };

      /*
       * Return if this is selected horizontal position
       */
      $scope.horizontalPositionSelected = function (index) {
        if ($scope.config)
          return index == parseInt($scope.config.swing_horizontal);
        else
          return false;
      };

      /*
       * Return if this is selected vertical position
       */
      $scope.verticalPositionSelected = function (index) {
        if ($scope.config)
          return index == parseInt($scope.config.swing_vertical);
        else
          return false;
      };

      /*
       * Toggle property sleep
       */
      $scope.togglePropertySleep = function () {
        closeOptions($scope.propertySleep);
        $scope.propertySleep = !$scope.propertySleep;
      };

      /*
       * Hide or show cold property options
       */
      $scope.togglePropertyCold = function () {
        closeOptions($scope.propertyModeCold);
        $scope.propertyModeCold = !$scope.propertyModeCold;
      };

      $scope.toggleDefrosting = function () {
        closeOptions($scope.propertyDefrost);
        $scope.propertyDefrost = !$scope.propertyDefrost;
      }

      /*
       * Hide or show heat property options
       */
      $scope.togglePropertyHeat = function () {
        closeOptions($scope.propertyModeHeat);
        $scope.propertyModeHeat = !$scope.propertyModeHeat;
      };

      /*
       * Hide or show cool air directions options
       */
      $scope.toggleAirDirectionCold = function () {
        closeOptions($scope.propertyAirDirectionCold);
        $scope.propertyAirDirectionCold = !$scope.propertyAirDirectionCold;
      };


      /*
       * Hide or show horizontal air directions options
       */
      $scope.toggleHorizontalPositions = function () {
        closeOptions($scope.propertyAirDirectionHorizontal);
        $scope.propertyAirDirectionHorizontal = !$scope.propertyAirDirectionHorizontal;
      };

      /*
       * Hide or show vertical air directions options
       */
      $scope.toggleVerticalPositions = function () {
        closeOptions($scope.propertyAirDirectionVertical);
        $scope.propertyAirDirectionVertical = !$scope.propertyAirDirectionVertical;
      };

      /*
       * Hide or show heat air directions options
       */
      $scope.toggleAirDirectionHeat = function () {
        closeOptions($scope.propertyAirDirectionHeat);
        $scope.propertyAirDirectionHeat = !$scope.propertyAirDirectionHeat;
      };

      /*
       * Called when close modal (click out)
       */
      $scope.close = function () {
        $scope.show = false;
        $scope.config = null;

        $rootScope.modalShow = false;
        $rootScope.zone_config_target = null;
        $rootScope.zone_config = null;
        $rootScope.last_zone_number = -1;
      };

      /*
       * Translate strint 't'
       * Solved bug (can't call $rootScope directly)
       */
      $scope.i18n = function (t) {
        return $rootScope.i18n(t);
      };

      /*
       * Close all oppened options
       */
      function closeOptions(no_close_var) {
        if (no_close_var != $scope.propertySleep)
          $scope.propertySleep = false;
        if (no_close_var != $scope.propertyDefrost)
          $scope.propertyDefrost = false;
        if (no_close_var != $scope.propertyModeCold)
          $scope.propertyModeCold = false;
        if (no_close_var != $scope.propertyModeHeat)
          $scope.propertyModeHeat = false;
        if (no_close_var != $scope.propertyAirDirectionCold)
          $scope.propertyAirDirectionCold = false;
        if (no_close_var != $scope.propertyAirDirectionHorizontal)
          $scope.propertyAirDirectionHorizontal = false;
        if (no_close_var != $scope.propertyAirDirectionVertical)
          $scope.propertyAirDirectionVertical = false;
        if (no_close_var != $scope.propertyAirDirectionHeat)
          $scope.propertyAirDirectionHeat = false;
        if (no_close_var != $scope.propertyVMCMode)
          $scope.propertyVMCMode = false;
      };

      /*
       * Remove root scope watchs
       */
      $scope.$on('$destroy', function () {
        $scope.listener1();
        $scope.listener2();
        $scope.propertySleep = false;
        $scope.propertyModeCold = false;
        $scope.propertyDefrost = false;
        $scope.propertyModeHeat = false;
        $scope.propertyAirDirectionCold = false;
        $scope.propertyAirDirectionHeat = false;
        $scope.propertyAirDirectionHorizontal = false;
        $scope.propertyAirDirectionVertical = false;
        $scope.propertyVMCMode = false;
      });
    }]);
