Airzone.controller('adminMapController', ['$scope', '$http', 'sessionService', '$state',
  function ($scope, $http, $sessionService, $state) {
    var styleMap = [
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          { "color": "#1f2326" }
        ]
      }, {
        "featureType": "landscape.natural",
        "stylers": [
          { "color": "#2c3033" }
        ]
      }, {
        "featureType": "landscape.man_made",
        "stylers": [
          { "color": "#2c3033" },
          { "lightness": 6 }
        ]
      }, {
        "featureType": "poi",
        "stylers": [
          { "visibility": "simplified" },
          { "color": "#2c3033" },
          { "lightness": 13 }
        ]
      }, {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          { "color": "#2c3033" },
          { "lightness": 25 }
        ]
      }, {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
          { "visibility": "simplified" },
          { "color": "#2c3033" },
          { "lightness": -10 }
        ]
      }, {
        "elementType": "labels.icon",
        "stylers": [
          { "visibility": "off" }
        ]
      }, {
        "elementType": "labels.text.fill",
        "stylers": [
          { "color": "#adb6bd" },
          { "lightness": -20 }
        ]
      }, {
        "elementType": "labels.text.stroke",
        "stylers": [
          { "color": "#1f2326" },
          { "weight": 2.4 }
        ]
      }, {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
          { "visibility": "on" },
          { "color": "#2c3033" },
          { "weight": 1.5 },
          { "lightness": -20 }
        ]
      }
    ];

    var clusterStyles = [
      {
        textColor: '#2c3033',
        url: 'images/cluster.png',
        height: 30,
        width: 30
      },
      {
        textColor: '#2c3033',
        url: 'images/cluster.png',
        height: 30,
        width: 30
      },
      {
        textColor: '#2c3033',
        url: 'images/cluster.png',
        height: 30,
        width: 30
      }
    ];

    var mapOptions = {
      zoom: 4,
      center: new google.maps.LatLng(40.427505, -3.691210),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      overviewMapControl: false,
      panControl: false,
      scaleControl: false,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.SMALL
      },
      styles: styleMap
    };

    var clusterOptions = {
      //gridSize: 40,
      styles: clusterStyles
    };

    $scope.map = new google.maps.Map(document.getElementById('map'), mapOptions);
    $scope.markers = [];
    $scope.clusterer = new MarkerClusterer($scope.map, $scope.markers, clusterOptions);;
    $scope.status_filter = undefined;

    getDevicesForMap();

    /*
     * Get devices from backend and save them in scope
     * Count each type of devices for counters
     */
    function getDevicesForMap() {
      params = {
        user_token: $sessionService.token(),
        user_email: $sessionService.email(),
        template: "map"
      };

      $http({
        method: 'GET',
        url: url_path + 'spots',
        params: params,
        data: {},
      }).success(function (data, status, headers, config) {
        resetMarkers();
        data.devices.forEach(function (device) {
          $scope.markers.push(createMarker(device));
        });

        $scope.clusterer.addMarkers($scope.markers);
      }).error(function (data, status, headers, config) {
        // Logout when unauthorized
        if (status == 401) {
          sessionService.unauthorize();
        } else if (status == 403) {
          $state.go('init.403');
        } else if (status == 404) {
          $state.go('init.404');
        }
      });
    };

    /*
     * Clean map markers and cluster
     */
    function resetMarkers() {
      $scope.markers.forEach(function (marker) {
        marker.setMap(undefined);
      });

      $scope.clusterer.clearMarkers();
      $scope.markers = [];
    };

    /*
     * Create marker for device in the map
     */
    function createMarker(device) {
      return new google.maps.Marker({
        map: $scope.map,
        position: new google.maps.LatLng(device.location.latitude, device.location.longitude),
        title: device.mac,
        icon: 'images/marker.png'
      });
    };
  }]);
