Airzone.controller('outsideModalsController', ['$scope', '$rootScope', 'eventsService', function ($scope, $rootScope, eventsService) {
  // Constants
  $scope.modes = ["stop", "cool-air", "heat-radiant", "ventilate", "heat-air", "heat-both", "dehumidify", "emergency-heat", "cool-radiant", "cool-both"];
  $scope.ecos = ["eco-off", "eco-m", "eco-a", "eco-aa", "eco-aaa"];
  $scope.velocities = ["0", "3", "2", "1"];
  $scope.scenes = ["stop", "confort", "unocupied", "night", "eco", "vacation"];
  $scope.timer_modes = ['timer-off', 'timer30', 'timer60', 'timer90'];
  $scope.air_flows = ["0", "1", "2"];

  // Modals flags
  $scope.showModeHelp = false;
  $scope.showSpeedHelp = false;
  $scope.showAirFlowHelp = false;
  $scope.showEcolHelp = false;
  $scope.showScenelHelp = false;
  $scope.showTimerlHelp = false;
  $scope.show_modal_mode = false;
  $scope.show_modal_eco = false;
  $scope.show_modal_velocity = false;
  $scope.show_modal_air_flow = false;
  $scope.show_modal_scene = false;
  $scope.show_modal_timer = false;
  $scope.class_modal_mode;
  $scope.class_modal_eco;
  $scope.class_modal_vel;
  $scope.class_modal_air_flow
  $scope.class_modal_scene;
  $scope.class_modal_timer;
  $scope.VAF_supply_heat = null;
  $scope.dehumidifier = null;
  $scope.available_modes = [];
  $rootScope.modalShow = false;


  /*
   * Open mode modal
   */
  $scope.listener1 = $rootScope.$watch('mode_target', function (new_target) {
    if (new_target) {
      $scope.mode_target = new_target;
      $scope.show_modal_mode = true;
      $scope.showModeHelp = false;
      $scope.available_modes = [];

      // Returns the supply heat option
      if (new_target.show_VAF_supply_heat) {
        $scope.VAF_supply_heat = Boolean(Number(new_target.VAF_supply_heat));
      // Returns the dehumidifier option
      } else if (new_target.show_dehumidifier) {
        $scope.dehumidifier = Boolean(Number(new_target.dehumidifier));
        // Filter modes and returns only server modes
      } else if (new_target.modes) {
        //delete $scope.zone.VAF_supply_heat;
        var array_modes = [0, 3, 1, 8, 9, 4, 2, 5, 6];
        if (parseFloat($rootScope.firm_ws) >= 3.0) {
          $scope.modes = ["stop", "cool", "heat", "ventilate", "heat", "heat", "dehumidify", "emergency-heat", "cool", "cool", "not_exit", "not_exit", "not_exit", "auto"];
          if ($scope.mode_target.systems) {
            $scope.mode_target.systems.forEach(function (value) {
              if (value.scene != null) {
                array_modes = [0, 3, 1, 8, 9, 4, 2, 5, 6, 13, 7];
              }
            });
          } else if ($scope.mode_target.scene) {
            array_modes = [0, 3, 1, 8, 9, 4, 2, 5, 6, 13, 7];
          }

          // Order the modes ¬¬
          angular.forEach(array_modes, function (value) {
            if (new_target.modes[value] == 1 && $scope.available_modes.indexOf($scope.modes[value]) < 0)
              $scope.available_modes.push($scope.modes[value]);
          });
        } else {
          angular.forEach(array_modes, function (value) {
            if (new_target.modes[value] == 1)
              $scope.available_modes.push($scope.modes[value]);
          });
        }
      }


      if (new_target.auto === "1"){
        $scope.class_modal_mode = "auto";
      }else{
        if (new_target.mode) {
          $scope.class_modal_mode = $scope.modes[parseInt(new_target.mode)] || "stop";
        } else {
          // Hasn't the selected mode in broadcast
          $scope.class_modal_mode = "";
        }
      }

      $rootScope.mode_target.new_mode = false;
      $rootScope.mode_target.new_eco = false;
      $rootScope.mode_target.new_velocity = false;
      $rootScope.mode_target_air_flow = false;
      $rootScope.mode_target_scene = false;
      $rootScope.modalShow = true;
    }
  });

  /*
   * Open eco modal
   */
  $scope.listener2 = $rootScope.$watch('eco_target', function (new_target) {
    if (new_target) {
      $scope.eco_target = new_target;
      $scope.show_modal_eco = true;
      $scope.showEcolHelp = false;
      $scope.class_modal_eco = $scope.ecos[parseInt(new_target.eco)] || "eco-off";
      $rootScope.modalShow = true;
    }
  });

  /*
   * Open velocity modal
   */
  $scope.listener3 = $rootScope.$watch('velocity_target', function (new_target) {
    if (new_target) {
      $scope.velocity_target = new_target;
      $scope.show_modal_velocity = true;
      $scope.showSpeedHelp = false;

      //velocidades mlp
      if (new_target.velMax) {
        $scope.velocities = [];
        for (var i = 0; i <= parseInt(new_target.velMax, 10); i++) {
          $scope.velocities.push(String(i));
        }
      } else {
        $scope.velocities = ["0", "3", "2", "1"];
      }

      $scope.class_modal_vel = new_target.velocity || "0";
      $rootScope.modalShow = true;
    }
  });

  /*
   * Open air flow modal
   */
  $scope.listener4 = $rootScope.$watch('air_flow_target', function (new_target) {
    if (new_target) {
      $scope.air_flow_target = new_target;
      $scope.show_modal_air_flow = true;
      $scope.showAirFlowHelp = false;
      $scope.class_modal_air_flow = new_target.air_flow || "0";
      $rootScope.modalShow = true;
    }
  });

  /*
   * Open scene modal
   */
  $scope.listener5 = $rootScope.$watch('scene_target', function (new_target) {
    if (new_target) {
      $scope.scene_target = new_target;
      $scope.show_modal_scene = true;
      $scope.showSceneHelp = false;
      $scope.class_modal_scene = $scope.scenes[parseInt(new_target.scene)] || "stop";
      $rootScope.modalShow = true;
    }
  });

  /*
   * Open timer modal
   */
  $scope.listener6 = $rootScope.$watch('timer_target', function (new_target) {
    if (new_target) {
      $scope.timer_target = new_target;
      $scope.show_modal_timer = true;
      $scope.showTimerHelp = false;
      $scope.class_modal_timer = new_target.timer || "0";
      $rootScope.modalShow = true;
    }
  });
  /*
   * Update target with mode selected in modal
   * @params {string} mode to change in the target
   */
  $scope.selectMode = function (class_mode) {
    $scope.show_modal_mode = false;
    $rootScope.mode_target.new_mode = getModeFromClass(class_mode);
    $scope.closeModal();
  };

  $scope.getIconVelocity = function (vel) {
    if ($rootScope.velocity_target && $rootScope.velocity_target.velMax != null && vel !== '0') {
      //iconos nuevos si es mlp y no es auto
      return "icon-vel-mlp-" + vel;
    } else {
      return ("icon-velocity" + vel);
    }
  };

  $scope.getTextVelocity = function (vel) {
    if ($rootScope.velocity_target && $rootScope.velocity_target.velMax != null && vel !== '0' && $rootScope.velocity_target.class !== "System") {
      return $scope.i18n('systems.velocity.velocity-mlp') + ' ' + String(vel);
    } else {
      return $scope.i18n('systems.velocity.velocity-' + vel);
    }
  }

  /*
   * Update target with eco selected in modal
   * @params {string} eco to change
   */
  $scope.selectEco = function (class_eco) {
    $scope.show_modal_eco = false;
    $rootScope.eco_target.new_eco = getEcoFromClass(class_eco);
    $scope.closeModal();
  };

  /*
   * Update target with velocity selected in modal
   * @params {string} velocity to change
   */
  $scope.selectVel = function (class_vel) {
    $scope.show_modal_velocity = false;
    $rootScope.velocity_target.new_velocity = class_vel;
    $scope.closeModal();
  };

  /*
   * Update target with air flow selected in modal
   * @params {string} air flow to change
   */
  $scope.selectFlow = function (class_air_flow) {
    $scope.show_modal_air_flow = false;
    $rootScope.air_flow_target.new_air_flow = class_air_flow;
    $scope.closeModal();
  };

  /*
   * Update target with scene selected in modal
   * @params {string} scene to change in the target
   */
  $scope.selectScene = function (class_scene) {
    $scope.show_modal_scene = false;
    $rootScope.scene_target.new_scene = class_scene;
    $scope.closeModal();
  };

  /*
   * Update target with timer selected in modal
   * @params {string} timer to change in the target
   */
  $scope.selectTimer = function (class_timer) {
    $scope.show_modal_timer = false;
    $rootScope.timer_target.new_timer = $scope.getTimerFromClass(class_timer);
    $scope.closeModal();
  };

  /*
   * It is called when click black screen of modal and it is closed
   * It solve bug when target don't change (modal in not open)
   */
  $scope.closeModal = function () {
    $scope.show_modal_mode = false;
    $scope.show_modal_velocity = false;
    $scope.show_modal_air_flow = false;
    $scope.show_modal_scene = false;
    $scope.show_modal_timer = false;
    $scope.show_modal_eco = false;
    $scope.VAF_supply_heat = null;
    $scope.dehumidifier = null;
    $rootScope.mode_target = null;
    $rootScope.eco_target = null;
    $rootScope.velocity_target = null;
    $rootScope.air_flow_target = null;
    $rootScope.scene_target = null;
    $rootScope.timer_target = null;
    $rootScope.modalShow = false;
  };



  /*
   * Translate strint 't'
   * Solved bug (can't call $rootScope directly)
   */
  $scope.i18n = function (t) {
    return $rootScope.i18n(t);
  };

  /*
   * Toggle mode help
   */
  $scope.toggleModeHelp = function () {
    $scope.showModeHelp = !$scope.showModeHelp;
  };

  /*
   * Toggle speed help
   */
  $scope.toggleSpeedHelp = function () {
    $scope.showSpeedHelp = !$scope.showSpeedHelp;
  };

  /*
   * Toggle air flow help
   */
  $scope.toggleAirFlowHelp = function () {
    $scope.showAirFlowHelp = !$scope.showAirFlowHelp;
  };

  /*
   * Toggle vertical help
   */
  $scope.toggleEcoHelp = function () {
    $scope.showEcoHelp = !$scope.showEcoHelp;
  };

  /*
   * Toggle scene help
   */
  $scope.toggleSceneHelp = function () {
    $scope.showSceneHelp = !$scope.showSceneHelp;
  };

  /*
   * Toggle timer help
   */
  $scope.toggleTimerHelp = function () {
    $scope.showTimerHelp = !$scope.showTimerHelp;
  };

  /*
   * Toggle VAF supply heat
   */
  $scope.toggleVAFSupplyHeat = function () {
    $scope.VAF_supply_heat = !$scope.VAF_supply_heat;
    eventsService.createEvent('modzona', $scope.mode_target, 'VAF_supply_heat', Number($scope.VAF_supply_heat));
  };

  /*
   * Toggle VAF supply heat
   */
  $scope.toggleDehumidifier = function () {
    $scope.dehumidifier = !$scope.dehumidifier;
    eventsService.createEvent('modzona', $scope.mode_target, 'dehumidifier', Number($scope.dehumidifier));
  };

  /*
   * Return mode id for mode class_name
   */
  function getModeFromClass(class_name) {
    return $scope.modes.indexOf(class_name);
  };

  /*
   * Return eco id for eco class_eco
   */
  function getEcoFromClass(class_eco) {
    return $scope.ecos.indexOf(class_eco);
  }

  /*
   * Return timer id for timer class_timer
   */
  $scope.getTimerFromClass = function (class_timer) {
    return $scope.timer_modes.indexOf(class_timer);
  };

   /*
    * Show the mode available in help view
    */
  $scope.showModeInHelp = function (mode) {
    return parseInt($scope.available_modes.indexOf(mode)) >= 0;
  };

  /*
   * Remove root scope watch
   */
  $scope.$on('$destroy', function () {
    $scope.listener1();
    $scope.listener2();
    $scope.listener3();
    $scope.listener4();
  });

}]);
