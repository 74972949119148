Airzone.controller('adminListController', ['$scope', '$rootScope', '$http', 'sessionService', '$state', 'deviceService', 'statsService',
  function ($scope, $rootScope, $http, sessionService, $state, deviceService, statsService) {
    $scope.show_modal = false;
    $scope.modal_status = 'info';
    $scope.current_page = 1;
    $scope.total_pages = 1;
    $scope.total_devices = 0;
    $scope.accordion = 0;
    $scope.is_admin = sessionService.typeUser('admin');
    $scope.forms = {};
    $scope.new_user = {};
    $scope.first_time = true;
    $scope.macErrorMessage = false;
    $scope.locationErrorMessage = false;
    $scope.show_connections_menu = false;
    $scope.show_hour_graphic = false;
    $scope.show_day_graphic = false;
    $scope.show_week_graphic = false;
    $scope.show_signal_day_graphic = false;
    $scope.filterHour = { text: $rootScope.i18n('last_hour_calculated'), value: 0 };
    $scope.filterDay = { text: $rootScope.i18n('last_day_calculated'), value: 0 };

    $scope.statusList = [
      { text: $rootScope.i18n('all'), value: "" },
      { text: $rootScope.i18n('sleeping'), value: "sleeping" },
      { text: $rootScope.i18n('connected'), value: "connected" },
      { text: $rootScope.i18n('activated'), value: "activated" }
    ];

    $scope.filterStatus = { text: $rootScope.i18n('all'), value: "" };

    $scope.getDevices = function () {
      $scope.macErrorMessage = false;
      $scope.locationErrorMessage = false;
      var params = {
        user_token: sessionService.token(),
        user_email: sessionService.email(),
        limit: 10,
        page: $scope.current_page
      };

      if ($scope.filterMac && $scope.filterMac.length > 4) {
        params.mac = $scope.filterMac;
      } else if (!$scope.first_time && $scope.filterMac) {
        $scope.macErrorMessage = true;
      }

      if ($scope.filterStatus && $scope.filterStatus.value.length > 0) {
        params.status = $scope.filterStatus.value;
      }

      if ($scope.filterLocation && $scope.filterLocation.length > 2) {
        params.location = $scope.filterLocation;
      } else if (!$scope.first_time && $scope.filterLocation) {
        $scope.locationErrorMessage = true;
      }

      if ($scope.orderBy) {
        params.order_by = $scope.orderBy;
        params.order = $scope.orderAsc ? "asc" : "desc";
      }

      if ($scope.first_time || $scope.current_page > 1 || (params.status || params.mac || params.location)) {
        $scope.first_time = false;
        $http({
          method: 'GET',
          url: url_path + 'devices',
          params: params,
          data: {},
        }).success(function (data, status, headers, config) {
          $scope.servers = data.devices;
          $scope.total_pages = data.total_pages;
          $scope.total_devices = data.count;
        }).error(function (data, status, headers, config) {
          // Logout when unauthorized
          if (status == 401) {
            sessionService.unauthorize();
          } else if (status == 403) {
            $state.go('init.403');
          } else if (status == 404) {
            $state.go('init.404');
          }
        });
      };
    };

    $scope.getDevices();

    /*
     * Show modal with server info
     */
    $scope.showServerInfo = function (server) {
      $http({
        method: 'GET',
        url: url_path + 'config_systems',
        params: {
          user_email: sessionService.email(),
          user_token: sessionService.token(),
          device_id: server.id
        },
      }).success(function (data, status, headers, config) {
        $scope.config_systems = data.configs;
        deviceService.getDeviceRelations(server.id, true, null, 100).then(function (data) {
          $scope.device_relations = data.device_relations;
        }, function (error) {
          console.error("Error getDeviceRelations: ", error);
        });
        $scope.show_connections_menu = false;
        $scope.show_connections_current = false;
        $scope.show_connections_hour = false;
        $scope.show_connections_day = false;
        $scope.show_connections_week = false;
        $scope.show_data_wifi = false;
        $scope.show_signals_current = false;
        $scope.date = new Date();

        // Get stats of ws with firmware >= 3.16
        if (parseFloat(server.firm_ws) >= 3.16) {
          $scope.show_data_wifi = true;
          var last_day = new Date();
          $scope.firm_ws_332 = parseFloat(server.firm_ws) >= 3.32 ? true : false;
          last_day.setHours(last_day.getDate() - 1);
          statsService.getDeviceSignalLast(server.mac).then(function (data) {
            if (data != "null" && data.conn && data.date) {
              $scope.show_signals_current = true;
              $scope.last_current = data;
              var time = new Date(data.date);
              $scope.last_current.date = ("0" + time.getDate()).slice(-2) + "/" + ("0" + (time.getMonth() + 1)).slice(-2) + "/" + time.getFullYear() + " " + ("0" + time.getHours()).slice(-2) + ":" + ("0" + time.getMinutes()).slice(-2);
            }
          }, function (error) {
            console.error("Error getDeviceSignalLast: ", error);
          });
        } else {
          // Get stats of ws with firmware < 3.16
          var hour_ago = new Date();
          hour_ago.setHours(hour_ago.getHours() - 1);
          var day_ago = new Date();
          day_ago.setDate(day_ago.getDate() - 1);
          var week_ago = new Date();
          week_ago.setDate(week_ago.getDate() - 7);

          statsService.getDeviceConnectionLast(server.mac).then(function (data) {
            if (data != "null" && data.conn && data.date) {
              $scope.show_connections_menu = true;
              $scope.show_connections_current = true;
              $scope.last_current = data;
              var time = new Date(data.date);
              $scope.last_current.date = ("0" + time.getDate()).slice(-2) + "/" + ("0" + (time.getMonth() + 1)).slice(-2) + "/" + time.getFullYear() + " " + ("0" + time.getHours()).slice(-2) + ":" + ("0" + time.getMinutes()).slice(-2);
            }
          }, function (error) {
            console.error("Error getDeviceConnectionLast: ", error);
          });

          statsService.getDeviceConnectionStats(server.mac, hour_ago.toISOString(), $scope.date.toISOString()).then(function (data) {
            if (data !== "null" && data.length > 0) {
              $scope.show_connections_menu = true;
              $scope.show_connections_hour = true;
              var result = data[0];
              $scope.avg_last_hour = (result.avg).toFixed(1);
              $scope.deviation_last_hour = (result.deviation).toFixed(2);
            }
          }, function (error) {
            console.error("Error getDeviceConnectionStats hour ago: ", error);
          });
          statsService.getDeviceConnectionStats(server.mac, day_ago.toISOString(), $scope.date.toISOString()).then(function (data) {
            if (data !== "null" && data.length > 0) {
              $scope.show_connections_menu = true;
              $scope.show_connections_day = true;
              var result = data[0];
              $scope.avg_last_day = (result.avg).toFixed(1);
              $scope.deviation_last_day = (result.deviation).toFixed(2);
            }
          }, function (error) {
            console.error("Error getDeviceConnectionStats five hours ago: ", error);
          });
          statsService.getDeviceConnectionStats(server.mac, week_ago.toISOString(), $scope.date.toISOString()).then(function (data) {
            if (data !== "null" && data.length > 0) {
              $scope.show_connections_menu = true;
              $scope.show_connections_week = true;
              var result = data[0];
              $scope.avg_last_week = (result.avg).toFixed(1);
              $scope.deviation_last_week = (result.deviation).toFixed(2);
            }
          }, function (error) {
            console.error("Error getDeviceConnectionStats week ago: ", error);
          });
        }
      }).error(function (error) {
        console.error("Error getConfigSystems: ", error);
      });

      $scope.show_modal = true;
      $rootScope.modalShow = true;
      $scope.accordion = 0;
      $scope.server = server;

      // When enabled option is changed, device is updated
      $scope.listener = $scope.$watch('server.enabled', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          $http({
            method: 'PUT',
            url: url_path + 'devices/' + $scope.server.id,
            data: {
              user_email: sessionService.email(),
              user_token: sessionService.token(),
              device: {
                enabled: $scope.server.enabled
              }
            }
          }).success(function (data, status, headers, config) {
            // success
          }).error(function (data, status, headers, config) {
            // error
          });
        }
      });
    };

    /*
     * Update the webserver firmware to release version
     */
    $scope.updateReleaseVersion = function () {
      $http({
        method: 'GET',
        url: url_path + 'remote_controls/' + $scope.server.id + '/edit?mac=' + $scope.server.mac,
        params: {
          user_token: sessionService.token(),
          user_email: sessionService.email()
        }
      }).success(function (data, status, headers, config) {
        $scope.modal_status = 'update_success';
      }).error(function (error) {
        console.error("Error updateReleaseVersion: ", error);
      });
    };

    /*
     * Remove server and update list
     */
    $scope.removeDevice = function () {
      $http({
        method: 'DELETE',
        url: url_path + 'devices/' + $scope.server.id,
        params: {
          user_token: sessionService.token(),
          user_email: sessionService.email()
        }
      }).success(function (data, status, headers, config) {
        var index = $scope.servers.indexOf($scope.server);
        $scope.servers.splice(index, 1);
        $scope.modal_status = 'remove_success';
      }).error(function (error) {
        console.error("Error deleteDevices: ", error);
      });
    };

    /*
     * Close server info modal
     */
    $scope.goToList = function () {
      $scope.show_modal = false;
      $rootScope.modalShow = false;
      $scope.modal_status = 'info';
      $scope.listener(); // remove $wath
    };

    /*
     * Reset server to init (factory) configuration
     */
    $scope.restoreToFactory = function () {
      $http({
        method: 'PUT',
        url: url_path + 'devices/' + $scope.server.id,
        data: {
          user_email: sessionService.email(),
          user_token: sessionService.token(),
          device: {
            status: 'sleeping'
          }
        }
      }).success(function (data, status, headers, config) {
        $scope.modal_status = 'restore_success'
        $scope.server.status = 'sleeping';
      }).error(function (error) {
        console.error("Error putDevices: ", error);
      });
    };

    $scope.setModalStatus = function (status) {
      if (status == 'invite_confirmation')
        $scope.new_user = {};
      $scope.modal_status = status;
    };

    $scope.changeAccordion = function (index) {
      $scope.accordion = $scope.accordion == index ? -1 : index;
    };

    /*
     * Send hide invitation
     */
    $scope.autoInvite = function () {
      if ($scope.forms.addEmailForm.$valid) {
        var params = {
          type: "advanced",
          name: "sac",
          email: $scope.new_user.email.toLowerCase(),
          surname: "airzone",
          hide_invitation: true,
          pin: $scope.server.pin,
          mac: $scope.server.mac
        }
        deviceService.createDeviceRelation(params).then(
          function (data) {
            $scope.setModalStatus('invite_success');
          }, function (data) {
            $scope.forms.addEmailForm.email.$error.taken = true;
          });
      }
    };

    /*
     * Initialize the date list of the charts views
     */
    function initDateList() {
      $scope.hourList = [];
      $scope.hourList.push({ text: $rootScope.i18n('last_hour_calculated'), value: 0 });
      for (var i = 1; i <= 24; i++) {
        var hour = $scope.date.getHours() - i;
        if (hour < 0) {
          hour = hour + 24
        }
        $scope.hourList.push({ text: "A las " + hour + ":00", value: i });
      }

      $scope.dayList = [];
      $scope.dayList.push({ text: $rootScope.i18n('last_day_calculated'), value: 0 });
      for (var j = 1; j <= 14; j++) {
        var day = new Date();
        day.setDate(day.getDate() - j);
        $scope.dayList.push({ text: day.toLocaleDateString(), value: j });
      }
    }

    /*
     * Set the start and end time Graphic
     */
    function setStartEndTime(count, type, until) {
      $scope.time_end = new Date();
      if (type === 'hour') {
        $scope.time_end.setHours($scope.time_end.getHours() - (count - 1));
      } else if (!(type === 'day' && until && count === 7)) {
        $scope.time_end.setDate($scope.time_end.getDate() - (count - 1));
      } else if (type === 'day' && until && count === 7) {
        $scope.time_end.setDate($scope.time_end.getDate() + 1);
      }

      $scope.time_start = new Date();
      if (type === 'hour') {
        $scope.time_start.setHours($scope.time_start.getHours() - count);
      } else {
        $scope.time_start.setDate($scope.time_start.getDate() - count);
      }

      // Initialize the time or day
      $scope.time_start.setMinutes(0);
      $scope.time_end.setMinutes(0);
      $scope.time_start.setSeconds(0);
      $scope.time_end.setSeconds(0);
      $scope.time_start.setMilliseconds(0);
      $scope.time_end.setMilliseconds(0);
      if (type === 'day') {
        $scope.time_start.setHours(0);
        $scope.time_end.setHours(0);
      }
    }

    /*
     * Set the maximum and minimum values to show in the graphic
     */
    function setMaxMinChart(start, end) {
      if ($scope.show_signal_hour_graphic) {
        if (!$scope.until) {
          $scope.time_start_moment = moment(start).add(5, "minutes");
        } else {
          var minutes = moment(start).minute() % 5;
          console.log(minutes);
          $scope.time_start_moment = moment(start).add(minutes, "minutes");
        }
      } else {
        $scope.time_start_moment = moment(start);
      }
      var date_utc_start = moment($scope.time_start_moment._d).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      $scope.min = moment(date_utc_start).valueOf();
      $scope.time_end_moment = moment(end);
      var date_utc_end = moment($scope.time_end_moment._d).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      $scope.max = moment(date_utc_end).valueOf();
    }

    /*
     * Show the Hour Graphic
     */
    $scope.showConnectionsChart = function (mac, precision, count, type, until) {
      initDateList();
      setStartEndTime(count, type, until);

      statsService.getDeviceConnectionLogs(mac, precision, $scope.time_start.toISOString(), $scope.time_end.toISOString()).then(function (data) {
        if (type == "day" && count == 7 && until === true) {
          $scope.show_week_graphic = true;
        } else if (type == "day" && count <= 14) {
          $scope.show_day_graphic = true;
        } else if (type == "hour") {
          $scope.show_hour_graphic = true;
        }

        setMaxMinChart($scope.time_start, $scope.time_end);
        initConnectionsChart(data);
      }, function (error) {
        console.error("Error getDeviceConnectionLogs: ", error);
      });
    };

    /*
     * Show the Hour Graphic
     */
    $scope.showSignalsChart = function (mac, precision, count, type, until) {
      initDateList();
      setStartEndTime(count, type, until);
      $scope.until = until;

      statsService.getDeviceSignalLogs(mac, precision, $scope.time_start.toISOString(), $scope.time_end.toISOString()).then(function (data) {
        if (type == "day" && count == 7 && until === true) {
          $scope.show_signal_week_graphic = true;
        } else if (type == "day" && count <= 14) {
          $scope.show_signal_day_graphic = true;
        } else if (type == "hour") {
          $scope.show_signal_hour_graphic = true;
        }

        setMaxMinChart($scope.time_start, $scope.time_end);
        initConnectionsChart(data);
      }, function (error) {
        console.error("Error getDeviceSignalLogs: ", error);
      });
    }

    /*
     * Initialize the hour connections chart
     */
    function initConnectionsChart(connections_data) {
      var j = 0;
      var itemArray = [];
      var dataArray = [];
      $scope.mydata = [];
      var color = "#56722a";

      if ($scope.show_hour_graphic || $scope.show_signal_hour_graphic) {
        var name = $scope.filterHour.text;
      } else if ($scope.show_week_graphic || $scope.show_signal_week_graphic) {
        name = $rootScope.i18n('last_week_calculated');
      } else {
        name = $scope.filterDay.text;
      }
      for (var i = 0; i < connections_data.length; i++) {
        var date = moment(connections_data[i]["date"]);
        var date_utc = moment(date._d).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";

        itemArray.push(moment(date_utc).valueOf());
        itemArray.push(parseInt(connections_data[i]["conn"]));

        //console.log(connections_data[i]["date"]);
        dataArray.splice(j, 0, itemArray);
        itemArray = [];
        j++;
      }

      $scope.mydata.push({ "color": color, "name": name, "data": dataArray });
      //console.log($scope.mydata);
    }

    /*
     * Close the modal Graphic
     */
    $scope.closeGraphic = function () {
      $scope.server.chart = null;
      $scope.show_hour_graphic = false;
      $scope.show_day_graphic = false;
      $scope.show_week_graphic = false;
      $scope.show_signal_hour_graphic = false;
      $scope.show_signal_day_graphic = false;
      $scope.show_signal_week_graphic = false;
      $scope.filterHour = { text: $rootScope.i18n('last_hour_calculated'), value: 0 };
      $scope.filterDay = { text: $rootScope.i18n('last_day_calculated'), value: 0 };
    };

  }
]);
