
var mobile_env = false;
var defaultHost = "dev.airzonecloud.com";
var checkStatusApp = "https://azapi.airzonecloud.com/api/v1/app_manager/";
var logStatsUrl = "https://devaz.airzonecloud.com/api/v1/az/stats/";
var pusherUrl = "slangertest2.airzonecloud.com";
var pusherPort = "8080";
var googleMapsKey = "AIzaSyDNU88FLTG27SiQH_BtDi38cktfuX7JXWQ";
var itunesApple = "airzone-cloud/id968067256";
var googlePlay = "es.altra.airzone";

