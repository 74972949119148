Airzone.directive('modalInfoServer', ['$rootScope', function ($rootScope) {
  return {
    restrict: 'E',
    scope: {
      show: '=',
      close: '&'
    },
    replace: true, // Replace with the template below
    transclude: true, // we want to insert custom content inside the directive
    link: function (scope, element, attrs) {
      scope.dialogStyle = {};

      if (attrs.width) {
        scope.dialogStyle.width = attrs.width;
      }
      if (attrs.height) {
        scope.dialogStyle.height = attrs.height;
      }

      scope.hideModal = function () {
        scope.close();
        scope.show = false;
      };
    },
    templateUrl: template_path + 'modal_info_servers.html'
  };
}]);
