angular.module('AngularChart', []).directive('chart', ['$rootScope', function ($rootScope) {
    return {
        restrict:'E',
        template:'<div></div>',
        transclude:true,
        replace:true,
        scope: '=',
        link:function (scope, element, attrs) {
            var opt = {
                credits: { enabled: false },
                exporting: { enabled: false },
                chart:{
                    renderTo:element[0],
                    type:'area',
                    spacingLeft: 10,
                    marginRight:10,
                    marginBottom:70,
                    height: 275,
                    borderRadius: 5,
                    backgroundColor:'rgba(255, 255, 255, 0.05)',
                    events: {
                      load: function () {
                        var chart = this;
                        setTimeout(function () { chart.reflow(); }, 0);
                      }
                    }
                },
                lang: {
                  viewFullscreen: $rootScope.i18n('full_page'),
                  printChart: $rootScope.i18n('print_chart'),
                  downloadPNG: $rootScope.i18n('download_png'),
                  downloadJPEG: $rootScope.i18n('download_jpg'),
                  downloadPDF: $rootScope.i18n('download_pdf'),
                  downloadSVG: $rootScope.i18n('download_avg'),
                  downloadCSV: $rootScope.i18n('download_csv'),
                  downloadXLS: $rootScope.i18n('download_xls'),
                  viewData: $rootScope.i18n('view_data_table'),
                  contextButtonTitle: ''
                },
                title:{
                    text:attrs.title,
                    x: -75,
                    y: 12,
                    style: {
                      fontFamily: 'HelveticaNeueLTStd-Cn, sans-serif',
                      color: '#b7c0c7',
                      fontSize: 12
                    }
                },
                subtitle:{
                    text:attrs.subtitle,
                    x:-25
                },
                xAxis:  {
                    categories:['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    tickInterval:1,
                    title:{
                        text:attrs.xname
                    },
                },
                plotOptions:{
                    lineWidth: 0.9,
                    column: {
                      pointPadding: 0.9,
                      borderWidth: 0,
                      groupPadding: 0.95,
                      shadow: true
                    },
                    series: {
                      events: {
                        legendItemClick: function () {
                          return false;
                        }
                      },
                      turboThreshold: 2000,
                      allowPointSelect: true,
                      point: {
                        events: {
                          click: function () {

                            var chart = this.series.chart.xAxis[0];

                            chart.removePlotLine('plotline-1');
                            chart.addPlotLine({
                                    value: this.x,
                                    color: '#FF0000',
                                    width: 2,
                                    id: 'plotline-1'
                                });
                          }
                        }
                      },
                      color: "rgba(173, 182, 189, 0)",
                      lineWidth: 2,
                      lineColor: "#b7c0c7",
                      fillOpacity: 1,
                      zIndex: 0,
                      marker: { lineWidth: 1 },
                      animation: {
                        duration: 2500,
                        easing: 'easeOutBounce'
                      }
                    }
                },
                yAxis:{
                    labels: {
                      style: { color: '#b7c0c7', fontFamily: 'HelveticaNeueLTStd-Cn, sans-serif' }
                    },
                    title:{
                        text:attrs.yname
                    },
                    tickInterval:(attrs.yinterval)?new Number(attrs.yinterval):null,
                    max: attrs.ymax,
                    min: attrs.ymin,
                    plotLines:[
                       {
                           value:0,
                           width:1,
                           color:'#808080'
                       }
                    ]
                },
                tooltip:{
                    formatter:scope[attrs.formatter]||function () {
                      var x_value = this.x;

                      if (scope.show_consumption_chart){
                        var tag = " kWh";
                        if (scope.date_type === 'day'){
                          x_value = (moment.utc(this.x)).format('HH:mm');
                        }else if (scope.date_type === 'month'){
                          if (scope.show_filters_chart){
                            x_value = (moment.utc(this.x)).format('DD/MM');
                          }else{
                            x_value = (moment.utc(this.x)).format('DD');
                          }
                        }else if (scope.date_type === 'year'){
                          if (scope.show_filters_chart){
                            x_value = (moment.utc(this.x)).format('MM/YYYY');
                          }else{
                            x_value = (moment.utc(this.x)).format('MM');
                          }
                        }
                      }else if(scope.show_hour_graphic){
                        x_value = (moment.utc(this.x)).format('HH:mm');
                        tag = " conexiones/5 minutos";
                      }else if(scope.show_signal_hour_graphic || scope.show_signal_day_graphic){
                        x_value = (moment.utc(this.x)).format('HH:mm');
                        tag = " nivel de señal";
                      }else if(scope.show_signal_week_graphic){
                        x_value = (moment.utc(this.x)).format('HH:mm DD/MM/YYYY');
                        tag = "";
                      }else if (scope.show_day_graphic || scope.show_week_graphic){
                        x_value = (moment.utc(this.x)).format('HH:mm DD/MM/YYYY');
                        tag = " conexiones/hora";
                      }else{
                        tag = " €/kWh";
                      }
                      
                        return '<b>' + x_value + '</b><br/ ><b>' + this.y + '</b>' + tag;
                    }
                },
                legend:{
                    itemStyle: {
                      fontFamily: 'HelveticaNeueLTStd-Cn, sans-serif',
                      fontSize: 12,
                      fontWeight: 'normal',
                      color: '#b7c0c7'
                    },
                    itemHoverStyle: {
                       color: '#A0A0A0'
                    },
                    itemHiddenStyle: {
                       color: '#444'
                    },
                    layout:'vertical',
                    align:'right',
                    verticalAlign:'top',
                    x:10,
                    y:-5,
                    borderWidth:0
                },
                series: [{
                    name: 'John',
                    data: [null, null, 0.3, 0.3, 0.3, null, null, null]
                  }, {
                    name: 'Jane',
                    data: [0.1, null, 0.3, null, 0.3, 0.1, 0.2, 0.8]
                  }, {
                    name: 'Smith',
                    data: [null, 0.1, null, null, null, null, null, null]
                  }, {
                    type: 'spline',
                    name: 'Average',
                    data: [3, 2.67, 3, 6.33, 3.33],
                    marker: {
                      lineWidth: 2,
                      lineColor: Highcharts.getOptions().colors[3],
                      fillColor: 'white'
                  }
                }]
            };

            //Update when charts data changes
            scope.$watch(function (scope) {
                var date = new Date();

                if(scope.server && scope.server.electricity_prices && scope.server.electricity_prices["tomorrow"] && scope.server.electricity_prices["tomorrow"].length != 0){
                  var plotline = 0;
                }else{
                  plotline = Number(date.getHours()).toFixed(0);
                }

                if (scope.show_hour_graphic || scope.show_day_graphic || scope.show_week_graphic || scope.show_signal_hour_graphic || scope.show_signal_day_graphic || scope.show_signal_week_graphic || scope.show_consumption_chart){
                  if (scope.show_consumption_chart){
                    if (scope.date_type === 'day'){
                      if (scope.query_range){
                        var dateTimeLabelFormats = {
                          day: '%d/%m/%Y'
                        }
                      }else{
                        dateTimeLabelFormats = {
                          day: '%H:%M'
                        }
                      }
                    }else if (scope.date_type === 'month'){
                      if (scope.show_filters_chart){
                        dateTimeLabelFormats = {
                          day: '%d/%m',
                          week: '%d/%m',
                          month: '%d/%m'
                        }
                      }else{
                        dateTimeLabelFormats = {
                          day: '%d',
                          week: '%d'
                        }
                      }
                    }else{
                      if (scope.show_filters_chart){
                        dateTimeLabelFormats = {
                          month: '%m/%Y'
                        }
                      }else{
                        dateTimeLabelFormats = {
                          month: '%m'
                        }
                      } 
                    }
                  }else if (scope.show_signal_hour_graphic || scope.show_signal_day_graphic || scope.show_signal_week_graphic || scope.show_hour_graphic || scope.show_day_graphic || scope.show_week_graphic){
                    dateTimeLabelFormats = {
                      day: '%e/%m/%Y %H:%M'
                    }
                  }
                  var plotLines = [];
                  var xAxis = {
                    type: 'datetime',
                    plotLines: plotLines,
                    dateTimeLabelFormats: dateTimeLabelFormats,
                    ordinal : false,
                    min : scope.min, // calculated start time in UTC
                    max : scope.max, // calculated end time in UTC
                    labels: {
                      style: { color: '#b7c0c7', fontFamily: 'HelveticaNeueLTStd-Cn, sans-serif' },
                      rotation: 0,
                      x: 1,
                      y: 25,
                      step: 1
                    }
                  };
                  var series = scope.mydata
                }else{
                  plotLines = [{
                    color: '#FF0000',
                    width: 2,
                    value: plotline,
                    id: 'plotline-1'
                  }];
                  xAxis = {
                    categories:scope[attrs.xdata],
                    tickmarkPlacement: 'on',
                    plotLines: plotLines,
                    labels: {
                      style: { color: '#b7c0c7', fontFamily: 'HelveticaNeueLTStd-Cn, sans-serif' },
                      rotation: 0,
                      x: 3,
                      y: 25,
                      step: 3
                    }
                  };
                  series = scope[attrs.ydata];
                }
                return JSON.stringify({                  
                    xAxis: xAxis,
                    series: series
                });
            }, function (news) {
                var timeout = 500;
                news = JSON.parse(news);
                if (!news.series)return;
                angular.extend(opt,news);

                if (scope.show_hour_graphic || scope.show_day_graphic || scope.show_week_graphic || scope.show_signal_hour_graphic || scope.show_signal_day_graphic || scope.show_signal_week_graphic || scope.show_consumption_chart){
                  if ($rootScope.isMobile){
                    opt["exporting"] = { enabled: false };
                  }else{
                    opt["exporting"] = { 
                      enabled: true, 
                      csv: {
                        itemDelimiter: ';',
                        columnHeaderFormatter: function(item, key) {
                          if (!key) {
                              return "Airzonecloud";
                          }
                          return false
                        }
                      },
                      tableCaption: false,//"Airzonecloud",
                      buttons: {
                        contextButton: {
                          menuItems: Highcharts.getOptions().exporting.buttons.contextButton.menuItems.filter(function(item){ return item !== 'openInCloud' }),
                          x: -10
                        }
                      }
                    };
                  }
                  opt["chart"]["type"] = 'column';
                  timeout = 0;
                  opt["plotOptions"]["series"]["point"] = {};
                  opt["legend"] = {
                    visible: true,
                    verticalAlign: 'center',
                    itemStyle: {
                      fontFamily: 'HelveticaNeueLTStd-Cn, sans-serif',
                      fontSize: 12,
                      fontWeight: 'normal',
                      color: '#b7c0c7'
                    },
                    itemHoverStyle: {
                       color: '#A0A0A0'
                    },
                    itemHiddenStyle: {
                       color: '#444'
                    }
                  };
                }else{
                  opt["exporting"] = { enabled: false };
                }

                if (scope.show_week_graphic || scope.show_day_graphic || scope.show_signal_day_graphic){
                  opt["xAxis"]["labels"] = {
                    rotation: -45, 
                    style:{ color: '#b7c0c7', fontSize: 9 }
                  };
                }

                if (scope.show_day_graphic || scope.show_week_graphic || scope.show_signal_day_graphic){
                  opt["chart"]["marginBottom"] = 80;
                  opt["chart"]["spacingLeft"] = 20;
                }

                if (scope.show_consumption_chart){
                  opt["chart"]["marginTop"] = 50;
                  opt["chart"]["marginBottom"] = 50;
                }

                if (scope.show_hour_graphic || scope.show_day_graphic || scope.show_week_graphic || scope.show_signal_day_graphic ){
                  opt["chart"]["marginTop"] = 50;
                }

                if (scope.show_day_graphic || scope.show_week_graphic || scope.show_signal_day_graphic ){
                  opt["chart"]["marginBottom"] = 80;
                  opt["chart"]["spacingLeft"] = 20;
                }

                if (scope.show_consumption_chart){
                  opt["xAxis"]["tickmarkPlacement"] = "between";
                  if (opt["series"].length === 2){
                    var all_data = opt["series"][0]["data"].concat(opt["series"][1]["data"]);
                    var max_chart = Math.max.apply(null, all_data.map(function(a){return a[1]}));
                  }else{
                    max_chart = Math.max.apply(null, opt["series"][0]["data"].map(function(a){return a[1]}));
                  }

                  if (max_chart < 2){
                    opt["yAxis"]["max"] = 4;
                  }else{
                    opt["yAxis"]["max"] = max_chart;
                  }
                  opt["yAxis"]["min"] = 0;

                  if (!scope.show_filters_chart){
                    opt["plotOptions"]["column"] = {pointPadding: 0, borderWidth: 0};
                  }
                }
                
                //console.log("opt: ", opt);
                //console.log("scope: ", scope);
                setTimeout(function () {
                  new Highcharts.Chart(opt);
                }, timeout);
            });
        }
    };

}]);
