Airzone.directive('modalAcsRadiantSchedules', function () {
  return {
    restrict: 'E',
    scope: {
      close: '&'
    },
    controller: 'modalAcsRadiantSchedulesController',
    templateUrl: template_path + 'modal_acs_radiant_schedules.html',
    link: function (scope, element, attrs) {
      scope.dialogStyle = {};

      if (attrs.width) {
        scope.dialogStyle.width = attrs.width;
      }
      if (attrs.height) {
        scope.dialogStyle.height = attrs.height;
      }

      scope.hideModal = function () {
        scope.close();
      };
    }
  };
});
