Airzone.controller('modalConfigSystemController',
  ['$scope', '$rootScope', 'SystemUtilsService', 'eventsService', 'purifierService',
    function ($scope, $rootScope, SystemUtilsService, eventsService, purifierService) {

      $scope.info_properties = [
        'system_fw',
        'firm_ws',
        'firmMC',
        'setup_type',
        'radio_number',
        'blueface_number',
        'tact_number',
        'probe_number',
        'heat_rele_board_number',
        'cold_rele_board_number',
        'combi_rele_board_number',
        'rele_fw_1',
        'rele_fw_2',
        'rele_fw_3',
        'rele_fw_4',
        'rele_fw_5',
        'rele_fw_6',
        'rele_fw_7',
        'rele_fw_8',
        'fancoil_gw',
        'vent_gw',
        'dehum_gw',
        'heat_eme_gw',
        'heat_aux_gw',
        'knx_gw',
        'radio_switchboard_fw'
      ];


      var TIMEOUT_CONSTANT = 100;

      var timeouts = {
        min_limit_timeout: null,
        max_limit_timeout: null,
        cold_mixing_valve_timeout: null,
        boiler_temp_timeout: null,
        cold_return_timeout: null,
        testRocioTimeout: null,
        histRocioTimeout: null,
        heat_return_timeout: null,
        cool_hysteresis_timeout: null,
        hysteresis_timeout: null,
        eq_aperture_timeout: null,
        minimum_air_enable_timeout: null,
        master_setup_timeout: null,
        qadapt_c_timeout: null,
        qadapt_d_timeout: null,
        v_min_timeout: null,
        v_max_timeout: null,
        weight_ventilation_timeout: null,
        ventilation_type_timeout: null,
        climate_and_vent_timeout: null,
        plenum_mode_timeout: null,
        hitachi_prot_timeout: null,
        wsccp_installation_type_timeout: null,
        min_battery_temperature_timeout: null,
        max_battery_temperature_timeout: null,
        auto_mode_battery_temperature_timeout: null,
        deshumidifier_state_timeout: null,
        deshumidifier_value_timeout: null,
        standby_mode_timeout: null,
        standby_mode_hysteresis_timeout: null,
        wsccp_activation_delay_timeout: null,
        wsccp_desactivation_acs_timeout: null,
        wsccp_cold_tai_air_timeout: null,
        wsccp_cold_tai_rad_timeout: null,
        wsccp_heat_tai_air_timeout: null,
        wsccp_heat_tai_rad_timeout: null
      }

      modalConfigSystem();

      function modalConfigSystem() {
        /*
         * Open modal
         */
        $scope.listener1 = $rootScope.$watch('system_config_target', function (new_target, old_target, scope) {
          // Variables and flags
          $scope.show = false;
          $scope.config = null;

          $scope.showHelp = false;
          $scope.config = null;

          $scope.rangeColdMode = null;
          $scope.rangeHotMode = null;
          $scope.etapeColdMode = null;
          $scope.etapeHotMode = null;
          $scope.show_etapeHotMode = null;
          $scope.tempProtectBoiler = false;
          $scope.tempProtectAirHot = false;
          $scope.tempProtectAirCold = false;
          $scope.qAdapt = false;
          $scope.qAdaptD = false;
          $scope.ventilationMode = false;
          $scope.climatizarVentilate = null;
          $scope.ventilateGlobal = null;
          $scope.ventilateGlobalValue = false;
          $scope.freeCoolingBusy = null;
          $scope.freeCoolingVoid = null;
          $scope.input = false;
          $scope.output = false;
          $scope.global_ventilation_options = null;
          $scope.show_global_ventilation_every_list = false;
          $scope.show_global_ventilation_run_for_list = false;
          $scope.available_global_ventilation_intervals = ["5", "10", "15", "20", "25", "30", "35", "40"];
          $scope.available_global_ventilation_durations = ["5", "10", "15", "20"];
          $scope.show_available_stages_options = true;
          $scope.show_first_heating_stage_options = false;
          $scope.show_fan_configuration_options = false;
          $scope.select_available_stage_first = false;
          $scope.select_available_stage_second = false;
          $scope.show_available_stage_first_differential = false;
          $scope.show_available_stage_first_hysteresis = false;
          $scope.show_available_stage_first_min_time = false;
          $scope.select_available_stage_first_differential = false;
          $scope.select_available_stage_first_hysteresis = false;
          $scope.select_available_stage_first_min_time = false;
          $scope.show_setpoint_differential_options = false;
          $scope.select_available_stages = false;
          $scope.select_first_stage = false;
          $scope.select_second_stage = false;
          $scope.available_auxiliary_heat_min_time = ["0", "45", "60", "120"];
          $scope.available_auxiliary_heat_fan_delay = ["0", "45", "60", "120"];
          $scope.show_mode_switching_protection_options = false;
          $scope.available_mode_swithching_protections = ["15", "30", "60", "90"];
          $scope.show_heat_ovr_temp_options = false;
          $scope.away_mode_settings_unocupied = true;
          $scope.show_away_mode_settings_hysteresis_options = false;
          $scope.show_away_mode_settings_override_time_options = false;
          $scope.available_overrides = ["10", "20", "30", "40", "50", "60", "70", "80", "90", "100", "110", "120"];
          $scope.show_heating_config_gap_options = false;
          $scope.rocioHistOptsActive = false;
          $scope.show_heating_config_first_stage_options = false;
          $scope.tempReturnHeatVAF = {
            celsius: [38, 46, 54, 62, 70],
            fah: [100, 114, 129, 143, 158]
          }
          $scope.show_wsccp_installation_type_options = false;
          $scope.show_wsccp_desactivation_type_options = false;
          $scope.array_wsccp_activation_delay = ["0", "1", "2", "3", "4", "5", "6", "7"]
          $scope.wsccp_cold_heat_tai = false;
          $scope.array_installation_type = ["0", "1", "2", "3"];
          $scope.histRocioData = [
            { id: "4", text: $scope.i18n('system_config.rocio_hist_very_high') },
            { id: "3", text: $scope.i18n('system_config.rocio_hist_high') },
            { id: "2", text: $scope.i18n('system_config.rocio_hist_med') },
            { id: "1", text: $scope.i18n('system_config.rocio_hist_low') },
            { id: "0", text: $scope.i18n('system_config.rocio_hist_very_low') },
          ]

          //indice del objeto histRocioData;
          $scope.selectedHistRocio = null;
          $scope.show_fan_delay_options = false;

          // available standby mode
          $scope.available_standby_mode = ["0", "1"];
          $scope.show_cold_standby_options = false;
          $scope.show_heat_standby_options = false;

          // initialize purifier data
          $scope.units_type_purifier = [{id: 0, label: $rootScope.i18n('system_config.hours')}, {id: 1, label: $rootScope.i18n('system_config.days')}];
          $scope.showPurifier = false; // show/hide purifier popup to create new params
          $scope.show_units_purifier = false; // show/hide options of units select
          $scope.selected_units =  $scope.units_type_purifier[0];
          $scope.purifier_data_unit = true;  // show purifier units in hours (default) or days
          $scope.array_purifier_days = ["15", "30", "60", "90", "180", "365", "730"];
          $scope.array_purifier_hours = [];
          
          var j = 500;
          for (var i = 0; i < 20; i++) {
            j += 500;
            $scope.array_purifier_hours.push(String(j));
          };
          
          initPurifierData();

          if (new_target) {
            $scope.system = new_target;
            $scope.show = true;
            $rootScope.modalShow = true;

            // Get last system info configuration (websockets)
            $rootScope.system_config = false;
            eventsService.createEvent('infoconfsistema', $scope.system, null, null);

            /*
             * Prevent websocket update
             */
            $scope.listener2 = $rootScope.$watch('system_config', function (new_val, old_val, scope) {
              if (new_val) {
                // this conditional solve bug when change system option, close and open other system
                if (new_val.system_id == $scope.system.id) {
                  $scope.config = new_val;
                  if ($scope.config.heat_return) {
                    if ($scope.config.setup_type != '6') {
                      $scope.config.heat_return = parseInt($scope.config.heat_return) + 32;
                    } else {
                      $scope.config.heat_return = parseInt($scope.config.heat_return);
                    }
                  }

                  if ($scope.config.testRocio !== null) {
                    if ($scope.config.testRocio == '1') $scope.config.testRocio = true;
                    else $scope.config.testRocio = false;
                  }

                  if ($scope.config.histRocio !== null) {
                    for (var i = 0; i < $scope.histRocioData.length; i++) {
                      if ($scope.histRocioData[i].id === $scope.config.histRocio) {
                        $scope.selectedHistRocio = i;
                        break;
                      }
                    }
                  }

                  initialize();
                }
                else {
                  $rootScope.system_config = false;
                }
              }
            });

            $scope.listener_error = $rootScope.$watch('error_device', function (new_val, old_val, scope) {
              if (new_val) {
                $scope.close();
              }
            });
          };
        });
      };

      // Funtion to intialize values in view
      function initialize() {
        //Initialize available options, min and max temp limit
        if ($scope.system.temperature_unit == "1") {
          $scope.rangeColdMode = (parseInt($scope.config.min_limit) != '80');
          $scope.rangeHotMode = (parseInt($scope.config.max_limit) != '64');
          $scope.available_hysteresis = ["2", "3", "4", "5", "6", "7"];
          $scope.available_setpoint_differentials = ["0", "1", "2", "3", "4", "5", "6", "7"];
          $scope.available_heat_ovr_temperatures = ["3", "4", "5", "6", "7", "8"];
          $scope.available_auxiliary_heat_differentials = ["2", "3", "4", "5", "6", "7", "8", "9", "10"];
          $scope.available_heating_config_gaps = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
        } else {
          $scope.rangeColdMode = (parseFloat($scope.config.min_limit).toFixed(1) != '27.0');
          $scope.rangeHotMode = (parseFloat($scope.config.max_limit).toFixed(1) != '18.0');
          $scope.available_hysteresis = ["1", "1.5", "2", "2.5", "3", "3.5"];
          $scope.available_setpoint_differentials = ["0", "0.5", "1", "1.5", "2", "2.5", "3", "3.5"];
          $scope.available_heat_ovr_temperatures = ["1.5", "2", "2.5", "3", "3.5", "4"];
          $scope.available_auxiliary_heat_differentials = ["1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5"];
          $scope.available_heating_config_gaps = ["0", "0.5", "1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5"];
        }

        //Initialize available values range for ZBS auxiliary heat stages hysteresis
        if ($scope.config.auxiliary_stages_first_differential != null)
          setAvailablesAuxiliaryHeatHysteresis('first', $scope.available_auxiliary_heat_differentials[parseInt($scope.config.auxiliary_stages_first_differential)]);
        if ($scope.config.auxiliary_stages_second_differential != null && $scope.config.auxiliary_heat_stages == '2')
          setAvailablesAuxiliaryHeatHysteresis('second', $scope.available_auxiliary_heat_differentials[parseInt($scope.config.auxiliary_stages_second_differential)]);

        //Initialize Flag show etapeHotMode for WS Firmware < 3.0
        $scope.show_etapeHotMode = !($rootScope.system_config.firm_ws);

        //Initialize Cold Difference and Heat Difference
        $scope.etapeColdMode = ($scope.config.cold_difference != "11");
        $scope.etapeHotMode = ($scope.config.heat_difference != "11");

        // Eq aperture
        if ($scope.config.eq_aperture != null)
          $scope.config.eq_aperture = ($scope.config.eq_aperture == '1');

        // Min air
        if ($scope.config.minimum_air_enable != null)
          $scope.config.minimum_air_enable = ($scope.config.minimum_air_enable == '1');

        // Master installation
        $scope.masterInstallation = ($scope.config.master_setup == '1');

        // Climate and Ventilation
        $scope.climatizarVentilate = ($scope.config.climate_and_vent == '1');

        // Busy and unoccupied freecoling
        $scope.freeCoolingBusy = ($scope.config.busy_freecoling == '1');
        $scope.freeCoolingVoid = ($scope.config.unoccupied_freecoling == '1');

        // Ventilation Global contact CO2
        $scope.ventilateGlobal = ($scope.config.contact_co2 != '0');

        // Global Ventilation ZBS
        if ($scope.config.global_ventilation_on != null) {
          $scope.config.global_ventilation_on = ($scope.config.global_ventilation_on == '1');
          if ($scope.config.global_ventilation_on)
            $scope.global_ventilation_options = true;
        }

        // Antifreeze ZBS
        if ($scope.config.config_ZBS_antifreeze_on != null)
          $scope.config.config_ZBS_antifreeze_on = ($scope.config.config_ZBS_antifreeze_on == '1');

        // Auxiliary Heat ZBS
        if ($scope.config.auxiliary_heat_on != null)
          $scope.config.auxiliary_heat_on = ($scope.config.auxiliary_heat_on == '1');

        // Auxiliary Heat First ZBS
        if ($scope.config.auxiliary_heat_first != null)
          $scope.config.auxiliary_heat_first = ($scope.config.auxiliary_heat_first == '1');

        // Auxiliary Heat Fan Configuration On ZBS
        if ($scope.config.auxiliary_heat_fan_on != null)
          $scope.config.auxiliary_heat_fan_on = ($scope.config.auxiliary_heat_fan_on == '1');

        // Autochange ZBS
        if ($scope.config.autochange_factor != null)
          $scope.config.autochange_factor = ($scope.config.autochange_factor == '1');

        // Room Temperature ZBS
        if ($scope.config.config_ZBS_visible_environment != null)
          $scope.config.config_ZBS_visible_environment = ($scope.config.config_ZBS_visible_environment == '1');

        // Plenum Mode
        if ($scope.config.plenum_mode != null)
          $scope.config.plenum_mode = ($scope.config.plenum_mode == '1');

        // Hitachi Protection
        if ($scope.config.hitachi_prot != null)
          $scope.config.hitachi_prot = ($scope.config.hitachi_prot == '1');

        // System Errors
        if ($scope.config.system_errors)
          $scope.errors_array = $scope.config.system_errors.split('');

        // Auto Mode Battery Temperature
        if ($scope.config.auto_mode_battery_temperature != null)
          $scope.config.auto_mode_battery_temperature = ($scope.config.auto_mode_battery_temperature == '1');

        // Deshumidifier State
        if ($scope.config.deshumidifier_state != null)
          $scope.config.deshumidifier_state = ($scope.config.deshumidifier_state == '1');

        // Air Supply
        if ($scope.config.VAF_air_supply_type != null)
          $scope.class_air_supply_state = ($scope.config.VAF_air_supply_type == '1');

        // Mixing valve in cold mode of DI6
        if ($scope.config.cold_mixing_valve != null)
          $scope.config.cold_mixing_valve = Boolean(Number($scope.config.cold_mixing_valve));

        // WSCCP Desactivation ACS
        if ($scope.config.wsccp_desactivation_acs != null)
          $scope.class_wsccp_desactivation_acs = ($scope.config.wsccp_desactivation_acs == '0');

        // WSCCP cold range TAI
        if ($scope.config.wsccp_cold_tai_down && $scope.config.wsccp_cold_tai_up) {
          $scope.array_cold_tai = [];

          for (var i = Number($scope.config.wsccp_cold_tai_down); i <= Number($scope.config.wsccp_cold_tai_up); i++) {
            $scope.array_cold_tai.push(String(i));
          }
          console.log($scope.array_cold_tai);
        }

        // WSCCP heat range TAI
        if ($scope.config.wsccp_heat_tai_down && $scope.config.wsccp_heat_tai_up) {
          $scope.array_heat_tai = [];

          for (var i = Number($scope.config.wsccp_heat_tai_down); i <= Number($scope.config.wsccp_heat_tai_up); i++) {
            $scope.array_heat_tai.push(String(i));
          }
          console.log($scope.array_heat_tai);
        }

        $scope.has_VMC_manual = $scope.system.VMC_mode === "1" ? true : false;


        if ($scope.config.purifier_control && !$scope.has_VMC_manual){
          purifierService.find($scope.config.device_id, $scope.config.system_number).then(function(data){
            if (angular.isObject(data) ){
              $scope.config.switch_purifier = true;
              $scope.purifier = data.purifier;
              if ($scope.purifier.time_unit === 'hours'){
                $scope.purifier_data_unit = true;
                $scope.selected_units =  {id: 0, label: $rootScope.i18n('system_config.hours')};
                $scope.purifierFixedTime = $scope.purifier.warning_time;
                $scope.purifierTimeCounter = $scope.purifier.acc;
              }else if ($scope.purifier.time_unit === 'days'){
                $scope.purifier_data_unit = false;
                $scope.selected_units = {id: 1, label: $rootScope.i18n('system_config.days')};
                $scope.purifierFixedTime = $scope.purifier.warning_time;
                $scope.purifierTimeCounter = moment.utc().diff(moment($scope.purifier.start_date), 'days');
              }
            }else{
              $scope.no_purifier_data = true;
              $scope.config.switch_purifier = false;
            }
          }).catch(function(error){
            console.log("Error: ", error);
          });
        }
      }

      // Return if WS has firmare equal o greater than 3.00
      $scope.firm_ws_2016 = function () {
        return ($scope.config && $scope.config.firm_ws && parseFloat($scope.config.firm_ws) >= 3.0)
      }

      // Return if WS has firmare less than 3.17
      $scope.firm_ws_less_317 = function () {
        return ($scope.config && $scope.config.firm_ws && parseFloat($scope.config.firm_ws) < 3.17)
      }

      // Return if WS has firmare equal o greater than 3.20
      $scope.firm_ws_greater_equal_320 = function () {
        return ($scope.config && $scope.config.firm_ws && parseFloat($scope.config.firm_ws) >= 3.20)
      }

      //Change min value of temperature
      $scope.min_limit_change = function (value) { // a 0 si le he dado al toggle
        if ($scope.system.temperature_unit == "1") {
          var range = 2;
          if (value > 0)
            value = 2;
          else if (value < 0)
            value = -2;

          if (value > 0 && (parseInt($scope.config.min_limit) + range <= 79)) {
            $scope.config.min_limit = (parseInt($scope.config.min_limit) + range).toString();

          } else if (value < 0 && (parseInt($scope.config.min_limit) - range >= 64)) {
            $scope.config.min_limit = (parseInt($scope.config.min_limit) - range).toString();

          } else if (value == 0) {
            if ($scope.rangeColdMode) {
              $scope.config.min_limit = '64';
            } else {
              $scope.config.min_limit = '80';
            }
          }
        } else {
          var range = 1;
          if (value > 0 && (parseFloat($scope.config.min_limit) + range <= 26)) {
            $scope.config.min_limit = (parseFloat(parseFloat($scope.config.min_limit) + range).toFixed(1)).toString();

          } else if (value < 0 && (parseFloat($scope.config.min_limit) - range >= 18)) {
            $scope.config.min_limit = (parseFloat(parseFloat($scope.config.min_limit) - range).toFixed(1)).toString();

          } else if (value == 0) {
            if ($scope.rangeColdMode) {
              $scope.config.min_limit = '18.0';
            } else {
              $scope.config.min_limit = '27.0';
            }
          }
        }


        timeouts.min_limit_timeout = SystemUtilsService.restartTimeout(timeouts.min_limit_timeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'min_limit', $scope.config.min_limit);
        }, TIMEOUT_CONSTANT);
      };

      //Change max value of temperature
      $scope.max_limit_change = function (value) {
        if ($scope.system.temperature_unit == "1") {
          var range = 2;
          if (value > 0)
            value = 2;
          else if (value < 0)
            value = -2;

          if (value > 0 && (parseInt($scope.config.max_limit) + range <= 86)) {
            $scope.config.max_limit = (parseInt(parseInt($scope.config.max_limit) + range)).toString();

          } else if (value < 0 && (parseInt($scope.config.max_limit) - range >= 66)) {
            $scope.config.max_limit = (parseInt(parseInt($scope.config.max_limit) - range)).toString();

          } else if (value == 0) {
            if ($scope.rangeHotMode) {
              $scope.config.max_limit = '86';
            } else {
              $scope.config.max_limit = '64';
            }
          }
        } else {
          var range = 1;
          if (value > 0 && (parseFloat($scope.config.max_limit) + range <= 30)) {
            $scope.config.max_limit = (parseFloat(parseFloat($scope.config.max_limit) + range).toFixed(1)).toString();

          } else if (value < 0 && (parseFloat($scope.config.max_limit) - range >= 19)) {
            $scope.config.max_limit = (parseFloat(parseFloat($scope.config.max_limit) - range).toFixed(1)).toString();

          } else if (value == 0) {
            if ($scope.rangeHotMode) {
              $scope.config.max_limit = '30.0';
            } else {
              $scope.config.max_limit = '18.0';
            }
          }
        }

        timeouts.max_limit_timeout = SystemUtilsService.restartTimeout(timeouts.max_limit_timeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'max_limit', $scope.config.max_limit);
        }, TIMEOUT_CONSTANT);

      };

      // Listener for min_limit on/off
      $scope.toggleMinLimit = function () {
        $scope.rangeColdMode = !$scope.rangeColdMode;
        if ($scope.config != null) {
          $scope.min_limit_change(0);
        }
      }

      // Listener for max_limit on/off
      $scope.toggleMaxLimit = function () {
        $scope.rangeHotMode = !$scope.rangeHotMode;
        if ($scope.config != null) {
          $scope.max_limit_change(0);
        }
      }

      //Change cold difference of temperature
      $scope.cold_difference_change = function (value) {
        var range = 1;
        if (value > 0 && (parseFloat($scope.config.cold_difference) + range <= 10)) {
          $scope.config.cold_difference = (parseFloat($scope.config.cold_difference) + range).toString();
        } else if (value < 0 && (parseFloat($scope.config.cold_difference) - range >= 0)) {
          $scope.config.cold_difference = (parseFloat($scope.config.cold_difference) - range).toString();
        } else if (value == 0) {
          if ($scope.etapeColdMode) {
            if (!$scope.show_etapeHotMode)
              $scope.config.cold_difference = '0';
            else
              $scope.config.cold_difference = '1';
          } else {
            $scope.config.cold_difference = '11';
          }
        }

        eventsService.createEvent('modconfsistema', $scope.config, 'cold_difference', $scope.config.cold_difference);
      };

      //Change heat difference of temperature
      $scope.heat_difference_change = function (value) {
        var range = 1;
        if (value > 0 && (parseFloat($scope.config.heat_difference) + range <= 10)) {
          $scope.config.heat_difference = (parseFloat($scope.config.heat_difference) + range).toString();
        } else if (value < 0 && (parseFloat($scope.config.heat_difference) - range >= 0)) {
          $scope.config.heat_difference = (parseFloat($scope.config.heat_difference) - range).toString();
        } else if (value == 0) {
          if ($scope.etapeHotMode) {
            if (!$scope.show_etapeHotMode)
              $scope.config.heat_difference = '0';
            else
              $scope.config.heat_difference = '1';
          } else {
            $scope.config.heat_difference = '11';
          }
        }

        eventsService.createEvent('modconfsistema', $scope.config, 'heat_difference', $scope.config.heat_difference);
      };

      // Listener for cold_difference on/off
      $scope.toggleColdDifference = function () {
        $scope.etapeColdMode = !$scope.etapeColdMode;
        if ($scope.config != null) {
          $scope.cold_difference_change(0);
        }
      }

      // Listener for heat_difference on/off
      $scope.toggleHeatDifference = function () {
        $scope.etapeHotMode = !$scope.etapeHotMode;
        if ($scope.config != null) {
          $scope.heat_difference_change(0);
        }
      }

      // Listener for boiler temp
      $scope.toogleBoilerTemp = function () {
        closeOptions($scope.tempProtectBoiler);
        $scope.tempProtectBoiler = !$scope.tempProtectBoiler;
      }

      // Listener for cold mixing valve
      $scope.changeColdMixingValve = function () {
        $scope.cold_mixing_valve_options = !$scope.cold_mixing_valve_options;
        $scope.config.cold_mixing_valve = !$scope.config.cold_mixing_valve;

        timeouts.cold_mixing_valve_timeout = SystemUtilsService.restartTimeout(timeouts.cold_mixing_valve_timeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'cold_mixing_valve', Number($scope.config.cold_mixing_valve));
        }, TIMEOUT_CONSTANT);

      };

      $scope.changeBoilerTemp = function (value) {
        $scope.config.boiler_temp = value;
        $scope.tempProtectBoiler = false;

        timeouts.boiler_temp_timeout = SystemUtilsService.restartTimeout(timeouts.boiler_temp_timeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'boiler_temp', $scope.config.boiler_temp);
        }, TIMEOUT_CONSTANT);

        $scope.tempProtectBoiler = false;
      };

      // Listener for cold return (show options)
      $scope.toggleColdReturn = function () {
        closeOptions($scope.tempProtectAirCold);
        $scope.tempProtectAirCold = !$scope.tempProtectAirCold;
      }

      // Set new cold return value
      $scope.changeColdReturn = function (value) {
        $scope.config.cold_return = value;
        $scope.tempProtectAirCold = false;

        timeouts.cold_return_timeout = SystemUtilsService.restartTimeout(timeouts.cold_return_timeout, function () {
          // Create event setting cold return value 6 -> 0, 8 -> 2, 10 -> 4
          eventsService.createEvent('modconfsistema', $scope.config, 'cold_return', parseInt($scope.config.cold_return) - 6);
        }, TIMEOUT_CONSTANT);

        $scope.tempProtectAirCold = false;
      };

      // Listener for heat return (show options)
      $scope.toggleHeatReturn = function () {
        closeOptions($scope.tempProtectAirHeat);
        $scope.tempProtectAirHeat = !$scope.tempProtectAirHeat;
      }

      $scope.toggleTestRocio = function () {
        $scope.config.testRocio = !$scope.config.testRocio;

        //cerramos popup en caso de que esté abierto
        $scope.rocioHistOptions(false);

        timeouts.testRocioTimeout = SystemUtilsService.restartTimeout(timeouts.testRocioTimeout, function () {
          // booleano
          eventsService.createEvent('modconfsistema', $scope.config, 'testRocio', $scope.config.testRocio ? "1" : "0");
        }, TIMEOUT_CONSTANT);

      }

      $scope.changeHistRocio = function (newHistindex) {
        $scope.rocioHistOptsActive = false;
        $scope.selectedHistRocio = newHistindex;
        $scope.config.histRocio = $scope.histRocioData[newHistindex].id;

        timeouts.histRocioTimeout = SystemUtilsService.restartTimeout(timeouts.histRocioTimeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'histRocio', $scope.config.histRocio);
        }, TIMEOUT_CONSTANT);

      }

      // Set new heat return value
      $scope.changeHeatReturn = function (value) {
        console.log(value);
        $scope.config.heat_return = value;
        $scope.tempProtectAirHeat = false;

        timeouts.heat_return_timeout = SystemUtilsService.restartTimeout(timeouts.heat_return_timeout, function () {
          // Create event setting heat return value 32 -> 0, 34 -> 2, 36 -> 4
          if ($scope.config.setup_type != '6') {
            //normal
            eventsService.createEvent('modconfsistema', $scope.config, 'heat_return', parseInt($scope.config.heat_return) - 32);
          } else {
            //vaf
            eventsService.createEvent('modconfsistema', $scope.config, 'heat_return', parseInt($scope.config.heat_return));
          }
        }, TIMEOUT_CONSTANT);

        $scope.tempProtectAirHeat = false;
      };

      //Change hysteresis of temperature
      $scope.cool_hysteresis_change = function (value) {
        var range = value * 0.5;
        if ((parseFloat($scope.config.cool_hysteresis) + range >= 0) && (parseFloat($scope.config.cool_hysteresis) + range <= 5))
          $scope.config.cool_hysteresis = (parseFloat($scope.config.cool_hysteresis) + range).toString();

        timeouts.cool_hysteresis_timeout = SystemUtilsService.restartTimeout(timeouts.cool_hysteresis_timeout, function () {
          var value = Math.abs($scope.config.cool_hysteresis * 2);
          eventsService.createEvent('modconfsistema', $scope.config, 'cool_hysteresis', value);
        }, TIMEOUT_CONSTANT);

      };

      //Change hysteresis of temperature
      $scope.hysteresis_change = function (value) {
        var range = value * 0.5;
        if ((parseFloat($scope.config.hysteresis) + range >= 0) && (parseFloat($scope.config.hysteresis) + range <= 5))
          $scope.config.hysteresis = (parseFloat($scope.config.hysteresis) + range).toString();

        timeouts.hysteresis_timeout = SystemUtilsService.restartTimeout(timeouts.hysteresis_timeout, function () {
          var value = Math.abs($scope.config.hysteresis * 2);
          eventsService.createEvent('modconfsistema', $scope.config, 'hysteresis', value);
        }, TIMEOUT_CONSTANT);
      };

      // Listener for eq aperture
      $scope.toggleEqAperture = function () {
        $scope.config.eq_aperture = !$scope.config.eq_aperture;

        timeouts.eq_aperture_timeout = SystemUtilsService.restartTimeout(timeouts.eq_aperture_timeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'eq_aperture', Number($scope.config.eq_aperture));
        }, TIMEOUT_CONSTANT);

      };

      // Listener for air min
      $scope.toggleMinAir = function () {
        $scope.config.minimum_air_enable = !$scope.config.minimum_air_enable;

        timeouts.minimum_air_enable_timeout = SystemUtilsService.restartTimeout(timeouts.minimum_air_enable_timeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'minimum_air_enable', Number($scope.config.minimum_air_enable));
        }, TIMEOUT_CONSTANT);

      };

      // Show Standby Options
      $scope.showStandbyOptions = function (type) {
        if (type === 'cold') {
          closeOptions($scope.show_cold_standby_options);
          $scope.show_cold_standby_options = !$scope.show_cold_standby_options;
        } else {
          closeOptions($scope.show_heat_standby_options);
          $scope.show_heat_standby_options = !$scope.show_heat_standby_options;
        }
      }

      // Check standby mode selected
      $scope.selectStandbyMode = function (type, value) {
        if (type === 'cold') {
          return $scope.config.cold_standby === String(value);
        } else {
          return $scope.config.heat_standby === String(value);
        }
      }

      // Listener for standby mode
      $scope.changeStandbyMode = function (type, value) {
        $scope.show_cold_standby_options = false;
        $scope.show_heat_standby_options = false;

        if (type === 'cold') {
          $scope.config.cold_standby = String(value);
          timeouts.standby_mode_timeout = SystemUtilsService.restartTimeout(timeouts.standby_mode_timeout, function () {
            eventsService.createEvent('modconfsistema', $scope.config, 'cold_standby', $scope.config.cold_standby);
          }, TIMEOUT_CONSTANT);
        } else {
          $scope.config.heat_standby = String(value);
          timeouts.standby_mode_timeout = SystemUtilsService.restartTimeout(timeouts.standby_mode_timeout, function () {
            eventsService.createEvent('modconfsistema', $scope.config, 'heat_standby', $scope.config.heat_standby);
          }, TIMEOUT_CONSTANT);
        }
      }

      // Listener for standby mode
      $scope.changeHysteresisStandbyMode = function (type, value) {
        var range = value * 0.5;

        if (type === 'cold') {
          if ((parseFloat($scope.config.cold_standby_hysteresis) + range >= 0) && (parseFloat($scope.config.cold_standby_hysteresis) + range <= 5))
            $scope.config.cold_standby_hysteresis = (parseFloat($scope.config.cold_standby_hysteresis) + range).toString();

          timeouts.standby_mode_hysteresis_timeout = SystemUtilsService.restartTimeout(timeouts.standby_mode_hysteresis_timeout, function () {
            var value = Math.abs($scope.config.cold_standby_hysteresis * 2);
            eventsService.createEvent('modconfsistema', $scope.config, 'cold_standby_hysteresis', value);
          }, TIMEOUT_CONSTANT);
        } else {
          if ((parseFloat($scope.config.heat_standby_hysteresis) + range >= 0) && (parseFloat($scope.config.heat_standby_hysteresis) + range <= 5))
            $scope.config.heat_standby_hysteresis = (parseFloat($scope.config.heat_standby_hysteresis) + range).toString();

          timeouts.standby_mode_hysteresis_timeout = SystemUtilsService.restartTimeout(timeouts.standby_mode_hysteresis_timeout, function () {
            var value = Math.abs($scope.config.heat_standby_hysteresis * 2);
            eventsService.createEvent('modconfsistema', $scope.config, 'heat_standby_hysteresis', value);
          }, TIMEOUT_CONSTANT);
        }
      }

      // Listener for master setup
      $scope.toggleSetupMaster = function () {
        $scope.masterInstallation = !Boolean($scope.masterInstallation);

        timeouts.master_setup_timeout = SystemUtilsService.restartTimeout(timeouts.master_setup_timeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'master_setup', Number($scope.masterInstallation));
        }, TIMEOUT_CONSTANT);
      }

      // Listener for qadapt_c (show options)
      $scope.toggleQAdaptC = function () {
        closeOptions($scope.qAdapt);
        $scope.qAdapt = !$scope.qAdapt;
      }

      $scope.changeQAdaptC = function (value) {
        $scope.config.qadapt_c = value;
        $scope.qAdapt = false;

        if ($scope.show_etapeHotMode)
          var qAdapt = 'qadapt';
        else
          var qAdapt = 'qadapt_c';

        timeouts.qadapt_c_timeout = SystemUtilsService.restartTimeout(timeouts.qadapt_c_timeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, qAdapt, $scope.config.qadapt_c);
        }, TIMEOUT_CONSTANT);

        $scope.qAdapt = false;
      };

      // Listener for qadapt_d return
      $scope.toggleQAdaptD = function () {
        closeOptions($scope.qAdaptD);
        $scope.qAdaptD = !$scope.qAdaptD;
      }

      // Listener for qadapt_d return
      $scope.toggleColdMixingValve = function () {
        closeOptions($scope.cold_mixing_valve_options);
        $scope.cold_mixing_valve_options = !$scope.cold_mixing_valve_options;
      }

      $scope.changeQAdaptD = function (value) {
        $scope.config.qadapt_d = value;
        $scope.qAdaptD = false;

        if ($scope.show_etapeHotMode)
          var qAdapt = 'qadapt';
        else
          var qAdapt = 'qadapt_d';

        timeouts.qadapt_d_timeout = SystemUtilsService.restartTimeout(timeouts.qadapt_d_timeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, qAdapt, $scope.config.qadapt_d);
        }, TIMEOUT_CONSTANT);

        $scope.qAdaptD = false;
      };

      // Get v_min value
      $scope.get_v_min = function () {
        if ($scope.config != null && $scope.config.v_min != null)
          return (parseFloat($scope.config.v_min) / 10.0);
        return ''
      };

      // Get v_max value
      $scope.get_v_max = function () {
        if ($scope.config != null && $scope.config.v_max != null)
          return (parseFloat($scope.config.v_max) / 10.0);
        return ''
      };

      //Change v_min
      $scope.v_min_change = function (value) {
        var range = value * 5;
        if (parseFloat($scope.config.v_min) + range <= parseFloat($scope.config.v_max)) {
          if ((parseFloat($scope.config.v_min) + range >= 0 && parseFloat($scope.config.v_min) + range <= 100))
            $scope.config.v_min = (parseFloat($scope.config.v_min) + range).toString();

          timeouts.v_min_timeout = SystemUtilsService.restartTimeout(timeouts.v_min_timeout, function () {
            eventsService.createEvent('modconfsistema', $scope.config, 'v_min', $scope.config.v_min);
          }, TIMEOUT_CONSTANT);
        }
      };

      //Change v_max
      $scope.v_max_change = function (value) {
        var range = value * 5;
        if (parseFloat($scope.config.v_max) + range >= parseFloat($scope.config.v_min)) {
          if ((parseFloat($scope.config.v_max) + range >= 0) && (parseFloat($scope.config.v_max) + range <= 100))
            $scope.config.v_max = (parseFloat($scope.config.v_max) + range).toString();

          timeouts.v_max_timeout = SystemUtilsService.restartTimeout(timeouts.v_max_timeout, function () {
            eventsService.createEvent('modconfsistema', $scope.config, 'v_max', $scope.config.v_max);
          }, TIMEOUT_CONSTANT);

        }
      };

      // Change Weight ventilation
      $scope.weight_ventilation_change = function (value) {
        var range = value * 1;
        if (parseFloat($scope.config.weight_ventilation) + range >= 0 && (parseFloat($scope.config.weight_ventilation) + range <= 100))
          $scope.config.weight_ventilation = (parseFloat($scope.config.weight_ventilation) + range).toString();

        timeouts.weight_ventilation_timeout = SystemUtilsService.restartTimeout(timeouts.weight_ventilation_timeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'weight_ventilation', $scope.config.weight_ventilation);
        }, TIMEOUT_CONSTANT);
      };

      // Listener for ventilation type
      $scope.toggleVentilationType = function () {
        closeOptions($scope.ventilationMode);
        $scope.ventilationMode = !$scope.ventilationMode;
      }

      $scope.changeVentilationType = function (value) {
        $scope.config.ventilation_type = value;
        $scope.ventilationMode = false;

        timeouts.ventilation_type_timeout = SystemUtilsService.restartTimeout(timeouts.ventilation_type_timeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'ventilation_type', $scope.config.ventilation_type);
        }, TIMEOUT_CONSTANT);

        $scope.ventilationMode = false;
      };

      // Listener for climate and vent
      $scope.toggleClimateAndVent = function () {
        $scope.config.climate_and_vent = !$scope.config.climate_and_vent;

        timeouts.climate_and_vent_timeout = SystemUtilsService.restartTimeout(timeouts.climate_and_vent_timeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'climate_and_vent', Number($scope.config.climate_and_vent));
        }, TIMEOUT_CONSTANT);
      }

      // Listener for contact co2
      $scope.toggleContactCO2 = function () {
        $scope.ventilateGlobal = !$scope.ventilateGlobal;

        if ($scope.ventilateGlobal) {
          $scope.config.contact_co2 = '1';
        }
        else {
          $scope.config.contact_co2 = '0';
        }

        if ($scope.contact_co2_timeout) {
          clearTimeout($scope.contact_co2_timeout);
        }

        $scope.contact_co2_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'contact_co2', $scope.config.contact_co2);
        }, TIMEOUT_CONSTANT);

        if ($scope.contact_co2 == '0')
          $scope.ventilateGlobal = false;
        $scope.ventilateGlobalValue = false;
      }

      // Listener for freeCoolingBusy
      $scope.toggleBusyFreecooling = function () {
        $scope.freeCoolingBusy = !$scope.freeCoolingBusy;

        if ($scope.freeCoolingBusy) {
          $scope.config.busy_freecoling = '1';

          if (!$scope.config.delta_freecoling) {
            $scope.config.delta_freecoling = 2;
            eventsService.createEvent('modconfsistema', $scope.config, 'delta_freecoling', 0);
          }
        }
        else {
          $scope.config.busy_freecoling = '0';
        }

        if ($scope.busy_freecoling_timeout) {
          clearTimeout($scope.busy_freecoling_timeout);
        }

        $scope.busy_freecoling_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'busy_freecoling', $scope.config.busy_freecoling);
        }, TIMEOUT_CONSTANT);
      }

      // Listener for freeCoolingVoid
      $scope.toggleUnoccupiedFreecoling = function () {
        $scope.freeCoolingVoid = !$scope.freeCoolingVoid;

        if ($scope.freeCoolingVoid) {
          $scope.config.unoccupied_freecoling = '1';
        }
        else {
          $scope.config.unoccupied_freecoling = '0';
        }

        if ($scope.unoccupied_freecoling_timeout) {
          clearTimeout($scope.unoccupied_freecoling_timeout);
        }

        $scope.unoccupied_freecoling_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'unoccupied_freecoling', $scope.config.unoccupied_freecoling);

          if ($scope.freeCoolingVoid && !$scope.config.ext_temp) {
            eventsService.createEvent('infoconfsistema', $scope.system, null, null);
            $rootScope.system_config = null;
            $scope.config = null;
          }
        }, TIMEOUT_CONSTANT);
      }

      //Change delta_freecoling
      $scope.delta_freecoling_change = function (value) {
        var range = value * 1;
        var new_val = false;

        if ((parseFloat($scope.config.delta_freecoling) + range >= 2) && (parseFloat($scope.config.delta_freecoling) + range <= 6))
          new_val = (parseFloat($scope.config.delta_freecoling) + range).toString();

        if (new_val && new_val != $scope.config.delta_freecoling) {
          $scope.config.delta_freecoling = new_val;

          if ($scope.delta_freecoling_timeout) {
            clearTimeout($scope.delta_freecoling_timeout);
          }

          $scope.delta_freecoling_timeout = setTimeout(function () {
            new_val = parseInt(new_val) - 2;
            eventsService.createEvent('modconfsistema', $scope.config, 'delta_freecoling', new_val);
          }, TIMEOUT_CONSTANT);
        }
      };

      //Change ext_temp
      $scope.ext_temp_change = function (value) {
        var range = value * 1;
        if ((parseFloat($scope.config.ext_temp) + range >= 0) && (parseFloat($scope.config.ext_temp) + range <= 10))
          $scope.config.ext_temp = (parseFloat($scope.config.ext_temp) + range).toString();

        if ($scope.ext_temp_timeout) {
          clearTimeout($scope.ext_temp_timeout);
        }

        $scope.ext_temp_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'ext_temp', $scope.config.ext_temp);
        }, TIMEOUT_CONSTANT);
      };

      //Change int_temp
      $scope.int_temp_change = function (value) {
        var range = value * 1;
        if ((parseFloat($scope.config.int_temp) + range >= 0) && (parseFloat($scope.config.int_temp) + range <= 5))
          $scope.config.int_temp = (parseFloat($scope.config.int_temp) + range).toString();

        if ($scope.int_temp_timeout) {
          clearTimeout($scope.int_temp_timeout);
        }

        $scope.int_temp_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'int_temp', $scope.config.int_temp);
        }, TIMEOUT_CONSTANT);
      };

      // Get ext_temp
      $scope.get_ext_temp = function () {
        if ($scope.config != null && $scope.config.ext_temp != null)
          return (parseFloat($scope.config.ext_temp) + 10);
        return '';
      };

      // Get int_temp
      $scope.get_int_temp = function () {
        if ($scope.config != null && $scope.config.int_temp != null)
          return (parseFloat($scope.config.int_temp) + 21);
        return '';
      };

      // Listener for conf_input
      $scope.toggleConfInput = function () {
        closeOptions($scope.input);
        $scope.input = !$scope.input;
      };

      $scope.changeConfInput = function (value) {
        $scope.config.conf_input = value;
        $scope.input = false;

        if ($scope.conf_input_timeout) {
          clearTimeout($scope.conf_input_timeout);
        }

        $scope.conf_input_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'conf_input', $scope.config.conf_input);
        }, TIMEOUT_CONSTANT);
        $scope.input = false;
      };

      // Listener for relay
      $scope.toggleRelay = function () {
        closeOptions($scope.output);
        $scope.output = !$scope.output;
      };

      $scope.changeRelay = function (value) {
        $scope.config.relay = value;
        $scope.output = false;

        if ($scope.relay_timeout) {
          clearTimeout($scope.relay_timeout);
        }

        $scope.relay_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'relay', $scope.config.relay);
        }, TIMEOUT_CONSTANT);

        if ($scope.modal_config_system_timeout) {
          clearTimeout($scope.modal_config_system_timeout);
        }

        $scope.modal_config_system_timeout = setTimeout(function () {
          modalConfigSystem();
        }, TIMEOUT_CONSTANT);

        $scope.output = false;
      };

      /*
       * Open modal to create purifier data
       */
      $scope.showModalPurifier = function(){
        $scope.showPurifier = !$scope.showPurifier;
      }

      /*
       * Close modal to create or update purifier data
       */
      $scope.closeModalPurifier = function(){
        $scope.showPurifier = false;
        
        // turn off purifier control if data aren't created
        if (!$scope.purifier){
          $scope.config.switch_purifier = false;
        }
      }

      /*
       * Turn On/Off Purifier Control and show/hide settings
       */
      $scope.togglePurifier = function () {
        closeOptions($scope.config.switch_purifier);
        console.log($scope.config.switch_purifier);

        $scope.config.switch_purifier = !$scope.config.switch_purifier;
        
        // Remove and initialize purifier data if turn off
        if (!$scope.config.switch_purifier){
          initPurifierData();
          purifierService.destroy($scope.purifier);
          $scope.system.purifier_warning = false;
          purifierService.updateSystem($scope.system);
          $scope.purifier = null;
          $scope.no_purifier_data = true;
        }else if ($scope.config.switch_purifier && $scope.no_purifier_data){
          $scope.showPurifier = true;
        }
      }

      /*
       * Initialize data of purifier control
       */
      function initPurifierData(){
        $scope.show_units_purifier = false;
        $scope.purifierTimeCounter = 0;
        $scope.purifierFixedTime = $scope.purifier_data_unit ? $scope.array_purifier_hours[8] : $scope.array_purifier_days[5];
      }

      $scope.toggleUnitsPurifier = function(){
        closeOptions($scope.show_units_purifier);
        $scope.show_units_purifier = !$scope.show_units_purifier;
      }

      /*
       * Pick the unit of time to set purifier control
       */
      $scope.chooseUnitPurifier = function( unit ){
        $scope.show_units_purifier = false;
        if ($scope.selected_units.id !== unit){
          $scope.selected_units = $scope.units_type_purifier[unit];
          $scope.purifier_data_unit = !$scope.purifier_data_unit;
          initPurifierData();
        }
      }

      /*
       * Change the time of nofiticaction to check the purifier
       */
      $scope.changeTimePurifierControl = function( value ){
        $scope.show_units_purifier = false;
        if ( $scope.purifier_data_unit ){
          var index = $scope.array_purifier_hours.indexOf(String($scope.purifierFixedTime)) + value;
          if ( (Number($scope.array_purifier_hours[index]) <= 10000) && (Number($scope.array_purifier_hours[index]) >= 1000) ){
            $scope.purifierFixedTime = $scope.array_purifier_hours[index];
          }
        }else{
          index = $scope.array_purifier_days.indexOf(String($scope.purifierFixedTime)) + value;
          if ( (Number($scope.array_purifier_days[index]) <= 730) && (Number($scope.array_purifier_days[index]) >= 15) ){
            $scope.purifierFixedTime = $scope.array_purifier_days[index];
          }
        }
      }

      /*
       * Reset the purifier data
       */
      $scope.resetPurifierData = function(){
        $scope.purifierTimeCounter = 0;
        $scope.purifier.acc = 0;
        purifierService.update($scope.purifier);
        $scope.system.purifier_warning = false;
        purifierService.updateSystem($scope.system);
      }
      
      /*
       * Set the purifier data
       */
      $scope.setPurifierData = function(){
        $scope.show_units_purifier = false;
        var time_unit = $scope.purifier_data_unit ? 'hours' : 'days';
        // update purifier data
        if ($scope.purifier){
          $scope.purifier.time_unit = time_unit;
          $scope.purifier.warning_time = $scope.purifierFixedTime;
          $scope.purifierTimeCounter = 0;

          purifierService.update($scope.purifier).then(function(data){
            console.log("updated: ", data);
            $scope.showPurifier = false;
          }).catch(function(error){
            console.log("Error: ", error);
          });
        }else{
          // save purifier data
          var purifier_data = {
            device_id: $scope.config.device_id,
            system_number: $scope.config.system_number,
            time_unit: time_unit,
            warning_time: $scope.purifierFixedTime
          }

          console.log("purifier_data: ", purifier_data);
          purifierService.create(purifier_data).then(function(data){
            console.log("created: ", data);
            $scope.purifier = data.purifier;
            $scope.showPurifier = false;
          }).catch(function(error){
            console.log("Error: ", error);
          });
        }
      }

      /*
       * Listener for global ventilation on ZBS
       */
      $scope.toggleGlobalVentilationOn = function () {
        $scope.config.global_ventilation_on = !$scope.config.global_ventilation_on;
        $scope.global_ventilation_options = !$scope.global_ventilation_options;

        if ($scope.global_ventilation_options && !$scope.show_global_ventilation_run_for_list)
          $scope.show_global_ventilation_every_list = true;

        if ($scope.global_ventilation_on_timeout) {
          clearTimeout($scope.global_ventilation_on_timeout);
        }

        $scope.global_ventilation_on_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'global_ventilation_on', Number($scope.config.global_ventilation_on));
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Show/Hide global ventilation every options list
       */
      $scope.globalVentilationEveryOptions = function () {
        closeOptions($scope.show_global_ventilation_every_list);
        $scope.show_global_ventilation_every_list = !$scope.show_global_ventilation_every_list;
      }

      /*
       * Show/Hide global ventilation run for options list
       */
      $scope.globalVentilationRunForOptions = function () {
        closeOptions($scope.show_global_ventilation_run_for_list);
        $scope.show_global_ventilation_run_for_list = !$scope.show_global_ventilation_run_for_list;
      };

      /*
       * Select Global Ventilation Interval of the options
       */
      $scope.selectGlobalVentilationInterval = function (index) {
        return ($scope.config.global_ventilation_interval == index.toString());
      };

      /*
       * Select Global Ventilation Duration of the options
       */
      $scope.selectGlobalVentilationDuration = function (index) {
        return ($scope.config.global_ventilation_duration == index.toString());
      };

      /*
       * Change Global Ventilation Options
       */
      $scope.changeGlobalVentilation = function (type, value) {
        $scope.show_global_ventilation_every_list = false;
        $scope.show_global_ventilation_run_for_list = false;

        if (type == 'interval') {
          $scope.config.global_ventilation_interval = value.toString();

          if ($scope.global_ventilation_interval_timeout) {
            clearTimeout($scope.global_ventilation_interval_timeout);
          }

          $scope.global_ventilation_interval_timeout = setTimeout(function () {
            eventsService.createEvent('modconfsistema', $scope.config, 'global_ventilation_interval', value.toString());
          }, TIMEOUT_CONSTANT);
        } else if (type == 'duration') {
          $scope.config.global_ventilation_duration = value.toString();

          if ($scope.global_ventilation_duration_timeout) {
            clearTimeout($scope.global_ventilation_duration_timeout);
          }

          $scope.global_ventilation_duration_timeout = setTimeout(function () {
            eventsService.createEvent('modconfsistema', $scope.config, 'global_ventilation_duration', value.toString());
          }, TIMEOUT_CONSTANT);
        }
      };

      /*
       * Listener for auxiliary heat ZBS
       */
      $scope.toggleAuxiliaryHeatOn = function () {
        $scope.config.auxiliary_heat_on = !$scope.config.auxiliary_heat_on;

        if ($scope.show_available_stages_options)
          closeOptions(!$scope.show_available_stages_options);
        else
          closeOptions($scope.show_available_stages_options);

        if ($scope.auxiliary_heat_on_timeout) {
          clearTimeout($scope.auxiliary_heat_on_timeout);
        }

        $scope.auxiliary_heat_on_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'auxiliary_heat_on', Number($scope.config.auxiliary_heat_on));
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Show/Hide Available Stages Options ZBS
       */
      $scope.availableStagesOptions = function () {
        closeOptions($scope.show_available_stages_options);
        $scope.show_available_stages_options = true;
        $scope.show_first_heating_stage_options = false;
        $scope.show_fan_configuration_options = false;
      };

      /*
       * Show/Hide First Heating Stage Options ZBS
       */
      $scope.firstHeatingStageOptions = function () {
        closeOptions($scope.show_first_heating_stage_options);
        $scope.show_first_heating_stage_options = true;
        $scope.show_available_stages_options = false;
        $scope.show_fan_configuration_options = false;
      };

      /*
       * Show/Hide Fan Configuration Options ZBS
       */
      $scope.fanConfigurationOptions = function () {
        closeOptions($scope.show_fan_configuration_options);
        $scope.show_fan_configuration_options = true;
        $scope.show_first_heating_stage_options = false;
        $scope.show_available_stages_options = false;
      };

      /*
       * Show/Hide Setpoint Differential Options ZBS
       */
      $scope.auxiliaryHeatAvailableStageOptions = function () {
        closeOptions($scope.select_available_stages);
        $scope.select_available_stages = !$scope.select_available_stages;
      };

      /*
       * Change auxiliary heat available stages ZBS
       */
      $scope.changeAuxiliaryHeatAvailableStages = function (value) {
        $scope.config.auxiliary_heat_stages = value;
        $scope.select_available_stages = false;
        if (value == "2")
          setAvailablesAuxiliaryHeatHysteresis('second', $scope.config.auxiliary_stages_second_differential);

        if ($scope.auxiliary_heat_stages_timeout) {
          clearTimeout($scope.auxiliary_heat_stages_timeout);
        }

        $scope.auxiliary_heat_stages_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'auxiliary_heat_stages', value);
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Show/Hide Auxiliary Heat Available Stages Options ZBS
       */
      $scope.showAuxiliaryHeatAvailableStagesOptions = function (value) {
        if (value == 'first') {
          $scope.select_available_stage_first_differential = false;
          $scope.select_available_stage_first_hysteresis = false;
          $scope.select_available_stage_first_min_time = false;
          closeOptions($scope.select_available_stage_first);
          $scope.select_available_stage_first = !$scope.select_available_stage_first;
          if ($scope.select_available_stage_first) {
            $scope.select_first_stage = true;
            $scope.show_available_stage_first_differential = true;
            $scope.show_available_stage_first_hysteresis = true;
            $scope.show_available_stage_first_min_time = true;
          } else {
            $scope.select_first_stage = false;
          }
        } else if (value == 'second') {
          $scope.select_available_stage_second_differential = false;
          $scope.select_available_stage_second_hysteresis = false;
          $scope.select_available_stage_second_min_time = false;
          closeOptions($scope.select_available_stage_second);
          $scope.select_available_stage_second = !$scope.select_available_stage_second;
          if ($scope.select_available_stage_second) {
            $scope.select_second_stage = true;
            $scope.show_available_stage_second_differential = true;
            $scope.show_available_stage_second_hysteresis = true;
            $scope.show_available_stage_second_min_time = true;
          } else {
            $scope.select_second_stage = false;
          }
        }
      };

      /*
       * Show/Hide Auxiliary Heat Available Stages First Differential ZBS
       */
      $scope.showAuxiliaryHeatAvailableStagesFirstDifferential = function () {
        $scope.select_available_stage_first_differential = !$scope.select_available_stage_first_differential;
        if ($scope.select_available_stage_first_differential) {
          $scope.select_available_stage_first_hysteresis = false;
          $scope.select_available_stage_first_min_time = false;
        }
      };

      /*
       * Show/Hide Auxiliary Heat Available Stages First Hysteresis ZBS
       */
      $scope.showAuxiliaryHeatAvailableStagesFirstHysteresis = function () {
        $scope.select_available_stage_first_hysteresis = !$scope.select_available_stage_first_hysteresis;
        if ($scope.select_available_stage_first_hysteresis) {
          $scope.select_available_stage_first_differential = false;
          $scope.select_available_stage_first_min_time = false;
        }
      };

      /*
       * Show/Hide Auxiliary Heat Available Stages First Minimum Time ZBS
       */
      $scope.showAuxiliaryHeatAvailableStagesFirstMinTime = function () {
        $scope.select_available_stage_first_min_time = !$scope.select_available_stage_first_min_time;
        if ($scope.select_available_stage_first_min_time) {
          $scope.select_available_stage_first_differential = false;
          $scope.select_available_stage_first_hysteresis = false;
        }
      };

      /*
       * Show/Hide Auxiliary Heat Available Stages Second Differential ZBS
       */
      $scope.showAuxiliaryHeatAvailableStagesSecondDifferential = function () {
        $scope.select_available_stage_second_differential = !$scope.select_available_stage_second_differential;
        if ($scope.select_available_stage_second_differential) {
          $scope.select_available_stage_second_hysteresis = false;
          $scope.select_available_stage_second_min_time = false;
        }
      };

      /*
       * Show/Hide Auxiliary Heat Available Stages Second Hysteresis ZBS
       */
      $scope.showAuxiliaryHeatAvailableStagesSecondHysteresis = function () {
        $scope.select_available_stage_second_hysteresis = !$scope.select_available_stage_second_hysteresis;
        if ($scope.select_available_stage_second_hysteresis) {
          $scope.select_available_stage_second_differential = false;
          $scope.select_available_stage_second_min_time = false;
        }
      };

      /*
       * Show/Hide Auxiliary Heat Available Stages Second Minimum Time ZBS
       */
      $scope.showAuxiliaryHeatAvailableStagesSecondMinTime = function () {
        $scope.select_available_stage_second_min_time = !$scope.select_available_stage_second_min_time;
        if ($scope.select_available_stage_second_min_time) {
          $scope.select_available_stage_second_differential = false;
          $scope.select_available_stage_second_hysteresis = false;
        }
      };

      /*
       * Select Auxiliary Heat Available Stage
       */
      $scope.selectAuxiliaryStages = function (stage, type, value) {
        if (stage == "first") {
          switch (type) {
            case "differential":
              return $scope.config.auxiliary_stages_first_differential == $scope.available_auxiliary_heat_differentials.indexOf(value);
            case "hysteresis":
              return $scope.config.auxiliary_stages_first_hysteresis == $scope.availables_auxiliary_stage_first_hysteresis.indexOf(value);
            case "min_time":
              return $scope.config.auxiliary_stages_first_time == value.toString();
          }
        } else if (stage == "second") {
          switch (type) {
            case "differential":
              return $scope.config.auxiliary_stages_second_differential == $scope.available_auxiliary_heat_differentials.indexOf(value);
            case "hysteresis":
              return $scope.config.auxiliary_stages_second_hysteresis == $scope.availables_auxiliary_stage_second_hysteresis.indexOf(value);
            case "min_time":
              return $scope.config.auxiliary_stages_second_time == value.toString();
          }
        }
      };

      /*
       * Listener for auxiliary stages ZBS
       */
      $scope.changeAuxiliaryStages = function (stage, type, value) {
        closeOptions($scope.select_available_stage_first);
        closeOptions($scope.select_available_stage_second);

        if ($scope.auxiliary_stages_timeout) {
          clearTimeout($scope.auxiliary_stages_timeout);
        }

        $scope.auxiliary_stages_timeout = setTimeout(function () {
          if (stage == "first") {
            switch (type) {
              case "differential":
                $scope.config.auxiliary_stages_first_differential = $scope.available_auxiliary_heat_differentials.indexOf(value);
                eventsService.createEvent('modconfsistema', $scope.config, 'auxiliary_stages_first_differential', $scope.available_auxiliary_heat_differentials.indexOf(value));
                setAvailablesAuxiliaryHeatHysteresis('first', value);
                checkAuxiliaryHeatHysteresisValue('first');
                break;
              case "hysteresis":
                $scope.config.auxiliary_stages_first_hysteresis = $scope.availables_auxiliary_stage_first_hysteresis.indexOf(value);
                eventsService.createEvent('modconfsistema', $scope.config, 'auxiliary_stages_first_hysteresis', $scope.availables_auxiliary_stage_first_hysteresis.indexOf(value));
                break;
              case "min_time":
                $scope.config.auxiliary_stages_first_time = value.toString();
                eventsService.createEvent('modconfsistema', $scope.config, 'auxiliary_stages_first_time', value.toString());
                break;
            }
          } else if (stage == "second") {
            switch (type) {
              case "differential":
                $scope.config.auxiliary_stages_second_differential = $scope.available_auxiliary_heat_differentials.indexOf(value);
                eventsService.createEvent('modconfsistema', $scope.config, 'auxiliary_stages_second_differential', $scope.available_auxiliary_heat_differentials.indexOf(value));
                setAvailablesAuxiliaryHeatHysteresis('second', value);
                checkAuxiliaryHeatHysteresisValue('second');
                break;
              case "hysteresis":
                $scope.config.auxiliary_stages_second_hysteresis = $scope.availables_auxiliary_stage_first_hysteresis.indexOf(value);
                eventsService.createEvent('modconfsistema', $scope.config, 'auxiliary_stages_second_hysteresis', $scope.availables_auxiliary_stage_second_hysteresis.indexOf(value));
                break;
              case "min_time":
                $scope.config.auxiliary_stages_second_time = value.toString();
                eventsService.createEvent('modconfsistema', $scope.config, 'auxiliary_stages_second_time', value.toString());
                break;
            }
          }
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Change auxiliary heat available stages ZBS
       */
      $scope.changeAuxiliaryHeatFirstHeatingStage = function () {
        $scope.config.auxiliary_heat_first = !$scope.config.auxiliary_heat_first;

        if ($scope.auxiliary_heat_first_timeout) {
          clearTimeout($scope.auxiliary_heat_first_timeout);
        }

        $scope.auxiliary_heat_first_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'auxiliary_heat_first', Number($scope.config.auxiliary_heat_first));
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Change auxiliary heat fan configuration ZBS
       */
      $scope.changeAuxiliaryHeatFanConfigurationOn = function () {
        $scope.config.auxiliary_heat_fan_on = !$scope.config.auxiliary_heat_fan_on;

        if ($scope.auxiliary_heat_fan_on_timeout) {
          clearTimeout($scope.auxiliary_heat_fan_on_timeout);
        }

        $scope.auxiliary_heat_fan_on_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'auxiliary_heat_fan_on', Number($scope.config.auxiliary_heat_fan_on));
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Select Auxiliary Heat Delay Fan Configuration of the options
       */
      $scope.selectAuxiliaryHeatFanDelay = function (index) {
        return ($scope.config.auxiliary_heat_delay == index.toString());
      };

      /*
       * Change auxiliary heat fan configuration delay ZBS
       */
      $scope.changeAuxiliaryHeatFanConfigurationDelay = function (value) {
        $scope.show_fan_configuration_options = false;
        $scope.config.auxiliary_heat_delay = value;

        if ($scope.auxiliary_heat_delay_timeout) {
          clearTimeout($scope.auxiliary_heat_delay_timeout);
        }

        $scope.auxiliary_heat_delay_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'auxiliary_heat_delay', value);
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Set available values range for ZBS auxiliary heat hysteresis
       */
      function setAvailablesAuxiliaryHeatHysteresis(stage, differential) {
        if (stage == 'first')
          $scope.availables_auxiliary_stage_first_hysteresis = [];
        else
          $scope.availables_auxiliary_stage_second_hysteresis = [];

        if ($scope.system.temperature_unit == "1") {
          var value = 1;
          var maximum = parseInt(differential);
        } else {
          var value = 0.5;
          var maximum = parseFloat(differential);
        }

        for (i = value; i <= maximum; i += value) {
          if (stage == 'first')
            $scope.availables_auxiliary_stage_first_hysteresis.push(i.toString());
          else
            $scope.availables_auxiliary_stage_second_hysteresis.push(i.toString());
        }
      };

      /*
       * Check and set if hysteresis value is out of range
       */
      function checkAuxiliaryHeatHysteresisValue(stage) {
        if ($scope.system.temperature_unit == "1") {
          if (stage == 'first') {
            if (parseInt($scope.config.auxiliary_stages_first_hysteresis) > parseInt($scope.config.auxiliary_stages_first_differential))
              $scope.changeAuxiliaryStages('first', 'hysteresis', $scope.available_auxiliary_heat_differentials[$scope.config.auxiliary_stages_first_differential]);
          } else {
            if (parseInt($scope.config.auxiliary_stages_second_hysteresis) > parseInt($scope.config.auxiliary_stages_second_differential))
              $scope.changeAuxiliaryStages('second', 'hysteresis', $scope.available_auxiliary_heat_differentials[$scope.config.auxiliary_stages_second_differential]);
          }
        } else {
          if (stage == 'first') {
            if (parseFloat($scope.config.auxiliary_stages_first_hysteresis) > parseFloat($scope.config.auxiliary_stages_first_differential))
              $scope.changeAuxiliaryStages('first', 'hysteresis', $scope.available_auxiliary_heat_differentials[$scope.config.auxiliary_stages_first_differential]);
          } else {
            if (parseFloat($scope.config.auxiliary_stages_second_hysteresis) > parseFloat($scope.config.auxiliary_stages_second_differential))
              $scope.changeAuxiliaryStages('second', 'hysteresis', $scope.available_auxiliary_heat_differentials[$scope.config.auxiliary_stages_second_differential]);
          }
        }
      };

      /*
       * Show/Hide Setpoint Differential Options ZBS
       */
      $scope.autochangeDifferentialOptions = function () {
        closeOptions($scope.show_setpoint_differential_options);
        $scope.show_setpoint_differential_options = !$scope.show_setpoint_differential_options;
      };

      /*
       * Show/Hide Mode Switching Protection Options ZBS
       */
      $scope.autochangeTimeOptions = function () {
        closeOptions($scope.show_mode_switching_protection_options);
        $scope.show_mode_switching_protection_options = !$scope.show_mode_switching_protection_options;
      };

      /*
       * Show/Hide Heat ovr temperatures Options ZBS
       */
      $scope.autochangeFactorOptions = function () {
        closeOptions($scope.show_heat_ovr_temp_options);
        $scope.show_heat_ovr_temp_options = !$scope.show_heat_ovr_temp_options;
      };

      /*
       * Select Heat ovr temperatures of the options
       */
      $scope.selectHeatOvrTemp = function (index) {
        return ($scope.config.autochange_temperature == index.toString());
      };

      /*
       * Listener for autochange factor ZBS
       */
      $scope.toggleAutochangeFactor = function () {
        $scope.config.autochange_factor = !$scope.config.autochange_factor;

        if ($scope.autochange_factor_timeout) {
          clearTimeout($scope.autochange_factor_timeout);
        }

        $scope.autochange_factor_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'autochange_factor', Number($scope.config.autochange_factor));
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Listener for autochange temperature ZBS
       */
      $scope.toggleAutochangeTemp = function (value) {
        $scope.show_heat_ovr_temp_options = false;
        $scope.config.autochange_temperature = value;

        if ($scope.autochange_temp_timeout) {
          clearTimeout($scope.autochange_temp_timeout);
        }

        $scope.autochange_temp_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'autochange_temperature', Number($scope.config.autochange_temperature));
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Select Setpoint Differential of the options
       */
      $scope.selectSetpointDifferential = function (index) {
        return ($scope.available_setpoint_differentials[index] == $scope.config.autochange_differential);
      };

      /*
       * Listener for autochange Setpoint differential
       */
      $scope.changeAutochangeSetpointDifferential = function (value) {
        $scope.show_setpoint_differential_options = false;
        $scope.config.autochange_differential = value;

        if ($scope.autochange_differential_timeout) {
          clearTimeout($scope.autochange_differential_timeout);
        }

        $scope.autochange_differential_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'autochange_differential', $scope.available_setpoint_differentials.indexOf(value));
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Select Mode Switching Protection of the options
       */
      $scope.selectModeSwitchingProtection = function (value) {
        return ($scope.config.autochange_time == value.toString());
      };

      /*
       * Listener for autochange Mode Switching Protection
       */
      $scope.changeAutochangeModeSwitchingProtection = function (index) {
        $scope.show_mode_switching_protection_options = false;
        $scope.config.autochange_time = index.toString();

        if ($scope.autochange_time_timeout) {
          clearTimeout($scope.autochange_time_timeout);
        }

        $scope.autochange_time_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'autochange_time', index.toString());
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Listener for antifreeze ZBS
       */
      $scope.toggleAntifreezeZBS = function () {
        $scope.config.config_ZBS_antifreeze_on = !$scope.config.config_ZBS_antifreeze_on;

        if ($scope.config_ZBS_antifreeze_on_timeout) {
          clearTimeout($scope.config_ZBS_antifreeze_on_timeout);
        }

        $scope.config_ZBS_antifreeze_on_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'config_ZBS_antifreeze_on', Number($scope.config.config_ZBS_antifreeze_on));
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Set Away Mode Settings ZBS
       */
      $scope.setAwayModeSettings = function () {
        $scope.away_mode_settings_unocupied = !$scope.away_mode_settings_unocupied;
      };

      /*
       * Show/Hide Away Mode Settings Hysteresis Options ZBS
       */
      $scope.awayModeSettingsHysteresisOptions = function () {
        closeOptions($scope.show_away_mode_settings_hysteresis_options);
        $scope.show_away_mode_settings_hysteresis_options = !$scope.show_away_mode_settings_hysteresis_options;
      };

      /*
       * Show/Hide Away Mode Settings Override Time Options ZBS
       */
      $scope.awayModeSettingsOverrideTimeOptions = function () {
        closeOptions($scope.show_away_mode_settings_override_time_options);
        $scope.show_away_mode_settings_override_time_options = !$scope.show_away_mode_settings_override_time_options;
      };

      /*
       * Select Away Mode Settings hysteresis of the options
       */
      $scope.selectAwayModeSettingsHysteresis = function (index) {
        return (($scope.away_mode_settings_unocupied && $scope.config.away_mode_settings_unocupied_hysteresis == index.toString()) || (!$scope.away_mode_settings_unocupied && $scope.config.away_mode_settings_vacation_hysteresis == index.toString()));
      }

      /*
       * Select Away Mode Settings override time of the options
       */
      $scope.selectAwayModeSettingsOverrideTime = function (over) {
        return (($scope.away_mode_settings_unocupied && $scope.config.away_mode_settings_unocupied_over == over) || (!$scope.away_mode_settings_unocupied && $scope.config.away_mode_settings_vacation_over == over));
      }

      /*
       * change Away Mode Settings
       */
      $scope.changeAwayModeSettings = function (type, value) {
        $scope.show_away_mode_settings_hysteresis_options = false;
        $scope.show_away_mode_settings_override_time_options = false;
        if ($scope.away_mode_settings_unocupied) {
          if (type == 'hysteresis') {
            $scope.config.away_mode_settings_unocupied_hysteresis = value;

            if ($scope.ams_unocupied_hysteresis_timeout) {
              clearTimeout($scope.ams_unocupied_hysteresis_timeout);
            }

            $scope.ams_unocupied_hysteresis_timeout = setTimeout(function () {
              eventsService.createEvent('modconfsistema', $scope.config, 'away_mode_settings_unocupied_hysteresis', value);
            }, TIMEOUT_CONSTANT);
          } else if (type == 'override_time') {
            $scope.config.away_mode_settings_unocupied_over = value.toString();

            if ($scope.ams_unocupied_override_timeout) {
              clearTimeout($scope.ams_unocupied_override_timeout);
            }

            $scope.ams_unocupied_override_timeout = setTimeout(function () {
              eventsService.createEvent('modconfsistema', $scope.config, 'away_mode_settings_unocupied_over', value.toString());
            }, TIMEOUT_CONSTANT);
          }
        } else {
          if (type == 'hysteresis') {
            $scope.config.away_mode_settings_vacation_hysteresis = value;

            if ($scope.ams_vacation_hysteresis_timeout) {
              clearTimeout($scope.ams_vacation_hysteresis_timeout);
            }

            $scope.ams_vacation_hysteresis_timeout = setTimeout(function () {
              eventsService.createEvent('modconfsistema', $scope.config, 'away_mode_settings_vacation_hysteresis', value);
            }, TIMEOUT_CONSTANT);
          } else if (type == 'override_time') {
            $scope.config.away_mode_settings_vacation_over = value.toString();

            if ($scope.ams_vacation_override_timeout) {
              clearTimeout($scope.ams_vacation_override_timeout);
            }

            $scope.ams_vacation_override_timeout = setTimeout(function () {
              eventsService.createEvent('modconfsistema', $scope.config, 'away_mode_settings_vacation_over', value.toString());
            }, TIMEOUT_CONSTANT);
          }
        }
      };

      /*
       * Show/Hide Fan Delay Options of Air Supply in ZBS
       */
      $scope.fanDelayOptions = function () {
        closeOptions($scope.show_fan_delay_options);
        $scope.show_fan_delay_options = !$scope.show_fan_delay_options;
      };

      /*
       * Is it Selected Fan Delay of Air Supply in ZBS
       */
      $scope.selectFanDelayAirSupply = function (index) {
        return ($scope.config.VAF_fan_delay == index.toString());
      }

      /*
       * Change Air Supply State in ZBS
       */
      $scope.changeAirSupplyState = function () {
        $scope.class_air_supply_state = !$scope.class_air_supply_state;
        $scope.config.VAF_air_supply_type = (Number($scope.class_air_supply_state)).toString();

        if ($scope.air_supply_state_timeout) {
          clearTimeout($scope.air_supply_state_timeout);
        }

        $scope.air_supply_state_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'VAF_air_supply_type', $scope.config.VAF_air_supply_type);
        }, TIMEOUT_CONSTANT);
      }

      /*
       * Change Fan Delay of Air Supply in ZBS
       */
      $scope.changeFanDelayAirSupply = function (index) {
        $scope.show_fan_delay_options = false;
        $scope.config.VAF_fan_delay = index.toString();

        if ($scope.fan_delay_timeout) {
          clearTimeout($scope.fan_delay_timeout);
        }

        $scope.fan_delay_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'VAF_fan_delay', $scope.config.VAF_fan_delay);
        }, TIMEOUT_CONSTANT);
      }

      /*
       * Listener for room temperature ZBS
       */
      $scope.toggleRoomTempZBS = function () {
        $scope.config.config_ZBS_visible_environment = !$scope.config.config_ZBS_visible_environment;

        if ($scope.config_ZBS_visible_environment_timeout) {
          clearTimeout($scope.config_ZBS_visible_environment_timeout);
        }

        $scope.config_ZBS_visible_environment_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'config_ZBS_visible_environment', Number($scope.config.config_ZBS_visible_environment));
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Show/Hide Heating Config Gap Options ZBS
       */
      $scope.heatingConfigGapOptions = function () {
        closeOptions($scope.show_heating_config_gap_options);
        $scope.show_heating_config_gap_options = !$scope.show_heating_config_gap_options;
      };

      $scope.rocioHistOptions = function (val) {
        closeOptions($scope.rocioHistOptsActive);
        if (val !== undefined) {
          $scope.rocioHistOptsActive = val;
        } else {
          $scope.rocioHistOptsActive = !$scope.rocioHistOptsActive;
        }
      }

      /*
       * Listener for heating config gap ZBS
       */
      $scope.changeHeatingConfigGap = function (value) {
        $scope.show_heating_config_gap_options = false;
        $scope.config.heating_config_gap = $scope.available_heating_config_gaps.indexOf(value);

        if ($scope.heating_config_gap_timeout) {
          clearTimeout($scope.heating_config_gap_timeout);
        }

        $scope.heating_config_gap_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'heating_config_gap', $scope.config.heating_config_gap);
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Show/Hide Heating Config First Stage Options ZBS
       */
      $scope.heatingConfigFirstStageOptions = function () {
        closeOptions($scope.show_heating_config_first_stage_options);
        $scope.show_heating_config_first_stage_options = !$scope.show_heating_config_first_stage_options;
      };

      /*
       * Listener for heating config first stage ZBS
       */
      $scope.changeHeatingConfigFirstStage = function (value) {
        $scope.show_heating_config_first_stage_options = false;
        $scope.config.heating_config_first_stage = value;

        if ($scope.heating_config_first_stage_timeout) {
          clearTimeout($scope.heating_config_first_stage_timeout);
        }

        $scope.heating_config_first_stage_timeout = setTimeout(function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'heating_config_first_stage', $scope.config.heating_config_first_stage);
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Change plenum mode
       */
      $scope.changePlenumMode = function () {
        $scope.config.plenum_mode = !$scope.config.plenum_mode;

        timeouts.plenum_mode_timeout = SystemUtilsService.restartTimeout(timeouts.plenum_mode_timeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'plenum_mode', Number($scope.config.plenum_mode));
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Change hitachi protection
       */
      $scope.changeHitachiProtection = function () {
        $scope.config.hitachi_prot = !$scope.config.hitachi_prot;
        timeouts.hitachi_prot_timeout = SystemUtilsService.restartTimeout(timeouts.hitachi_prot_timeout, function () {
          // Send the inverse logic in hitachi protection mode: 0 -> ON, 1 -> OFF
          eventsService.createEvent('modconfsistema', $scope.config, 'hitachi_prot', Number(!$scope.config.hitachi_prot));
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Show/Hide Heating Config First Stage Options ZBS
       */
      $scope.toggleWSCCPActivationDelay = function () {
        closeOptions($scope.show_wsccp_activation_delay_options);
        $scope.show_wsccp_activation_delay_options = !$scope.show_wsccp_activation_delay_options;
      };

      /*
       * Change WSCCP Installation Type
       */
      $scope.changeWSCCPActivationDelay = function (value) {
        $scope.show_wsccp_activation_delay_options = !$scope.show_wsccp_activation_delay_options;
        $scope.config.wsccp_activation_delay = value;

        timeouts.wsccp_activation_delay_timeout = SystemUtilsService.restartTimeout(timeouts.wsccp_activation_delay_timeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'wsccp_activation_delay', $scope.config.wsccp_activation_delay);
        }, TIMEOUT_CONSTANT);
      }

      /*
       * Change WSCCP Installation Type
       */
      $scope.toggleWSCCPDesactivationACS = function () {
        $scope.config.wsccp_desactivation_acs = String(Number($scope.class_wsccp_desactivation_acs));
        $scope.class_wsccp_desactivation_acs = !$scope.class_wsccp_desactivation_acs;
        timeouts.wsccp_desactivation_acs_timeout = SystemUtilsService.restartTimeout(timeouts.wsccp_desactivation_acs_timeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'wsccp_desactivation_acs', $scope.config.wsccp_desactivation_acs);
        }, TIMEOUT_CONSTANT);
      }

      /*
       * Change WSCCP Installation Type
       */
      $scope.toggleWSCCPTai = function () {
        $scope.wsccp_cold_heat_tai = !$scope.wsccp_cold_heat_tai;
      }

      /*
       * Change WSCCP Installation Type
       */
      $scope.changeWSCCPTai = function (type, value) {
        if ($scope.wsccp_cold_heat_tai) {
          if (type === 'air') {
            var new_value = Number($scope.config.wsccp_cold_tai_air) + Number(value);
            if (new_value >= Number($scope.config.wsccp_cold_lim_min) && new_value <= Number($scope.config.wsccp_cold_lim_max)) {
              $scope.config.wsccp_cold_tai_air = String(new_value);
              timeouts.wsccp_cold_tai_air_timeout = SystemUtilsService.restartTimeout(timeouts.wsccp_cold_tai_air_timeout, function () {
                eventsService.createEvent('modconfsistema', $scope.config, 'wsccp_cold_tai_air', $scope.config.wsccp_cold_tai_air);
              }, TIMEOUT_CONSTANT);
            }
          } else if (type === 'radiant') {
            new_value = Number($scope.config.wsccp_cold_tai_rad) + Number(value);
            if (new_value >= Number($scope.config.wsccp_cold_lim_min) && new_value <= Number($scope.config.wsccp_cold_lim_max)) {
              $scope.config.wsccp_cold_tai_rad = String(new_value);
              timeouts.wsccp_cold_tai_rad_timeout = SystemUtilsService.restartTimeout(timeouts.wsccp_cold_tai_rad_timeout, function () {
                eventsService.createEvent('modconfsistema', $scope.config, 'wsccp_cold_tai_rad', $scope.config.wsccp_cold_tai_rad);
              }, TIMEOUT_CONSTANT);
            }
          }
        } else {
          if (type === 'air') {
            new_value = Number($scope.config.wsccp_heat_tai_air) + Number(value);
            if (new_value >= Number($scope.config.wsccp_heat_lim_min) && new_value <= Number($scope.config.wsccp_heat_lim_max)) {
              $scope.config.wsccp_heat_tai_air = String(Number($scope.config.wsccp_heat_tai_air) + Number(value));
              timeouts.wsccp_heat_tai_air_timeout = SystemUtilsService.restartTimeout(timeouts.wsccp_heat_tai_air_timeout, function () {
                eventsService.createEvent('modconfsistema', $scope.config, 'wsccp_heat_tai_air', $scope.config.wsccp_heat_tai_air);
              }, TIMEOUT_CONSTANT);
            }
          } else if (type === 'radiant') {
            new_value = Number($scope.config.wsccp_heat_tai_rad) + Number(value);
            if (new_value >= Number($scope.config.wsccp_heat_lim_min) && new_value <= Number($scope.config.wsccp_heat_lim_max)) {
              $scope.config.wsccp_heat_tai_rad = String(Number($scope.config.wsccp_heat_tai_rad) + Number(value));
              timeouts.wsccp_heat_tai_rad_timeout = SystemUtilsService.restartTimeout(timeouts.wsccp_heat_tai_rad_timeout, function () {
                eventsService.createEvent('modconfsistema', $scope.config, 'wsccp_heat_tai_rad', $scope.config.wsccp_heat_tai_rad);
              }, TIMEOUT_CONSTANT);
            }
          }
        }
      }

      /*
       * Listener for WSCCP (show options)
       */
      $scope.toggleWSCCPType = function () {
        closeOptions($scope.show_wsccp_installation_type_options);
        $scope.show_wsccp_installation_type_options = !$scope.show_wsccp_installation_type_options;
      }

      /*
       * Change WSCCP Installation Type
       */
      $scope.changeWSCCPInstallationType = function (type) {
        $scope.show_wsccp_installation_type_options = !$scope.show_wsccp_installation_type_options;
        $scope.config.wsccp_installation_type = type;

        timeouts.wsccp_installation_type_timeout = SystemUtilsService.restartTimeout(timeouts.wsccp_installation_type_timeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'wsccp_installation_type', $scope.config.wsccp_installation_type);
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Change minimum battery temperature
       */
      $scope.min_battery_temperature_change = function (value) {
        if ((parseInt($scope.config.min_battery_temperature) + value >= 33 && parseInt($scope.config.min_battery_temperature) + value <= 45)) {
          $scope.config.min_battery_temperature = (parseInt($scope.config.min_battery_temperature) + value).toString();

          timeouts.min_battery_temperature_timeout = SystemUtilsService.restartTimeout(timeouts.min_battery_temperature_timeout, function () {
            eventsService.createEvent('modconfsistema', $scope.config, 'min_battery_temperature', $scope.config.min_battery_temperature);
          }, TIMEOUT_CONSTANT);
        }
      };

      /*
       * Change maximum battery temperature
       */
      $scope.max_battery_temperature_change = function (value) {
        if ((parseInt($scope.config.max_battery_temperature) + value >= 10) && (parseInt($scope.config.max_battery_temperature) + value <= 21)) {
          $scope.config.max_battery_temperature = (parseInt($scope.config.max_battery_temperature) + value).toString();

          timeouts.max_battery_temperature_timeout = SystemUtilsService.restartTimeout(timeouts.max_battery_temperature_timeout, function () {
            eventsService.createEvent('modconfsistema', $scope.config, 'max_battery_temperature', $scope.config.max_battery_temperature);
          }, TIMEOUT_CONSTANT);

        }
      };

      /*
       * Change Auto Mode Battery Temperature
       */
      $scope.toggleAutoModeBatteryTemperature = function () {
        $scope.config.auto_mode_battery_temperature = !$scope.config.auto_mode_battery_temperature;

        timeouts.auto_mode_battery_temperature_timeout = SystemUtilsService.restartTimeout(timeouts.auto_mode_battery_temperature_timeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'auto_mode_battery_temperature', Number($scope.config.auto_mode_battery_temperature));
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Change Deshumidifier State
       */
      $scope.toggleDeshumidifierState = function () {
        $scope.config.deshumidifier_state = !$scope.config.deshumidifier_state;

        timeouts.deshumidifier_state_timeout = SystemUtilsService.restartTimeout(timeouts.deshumidifier_state_timeout, function () {
          eventsService.createEvent('modconfsistema', $scope.config, 'deshumidifier_state', Number($scope.config.deshumidifier_state));
        }, TIMEOUT_CONSTANT);
      };

      /*
       * Change Deshumidifier Value
       */
      $scope.deshumidifier_value_change = function (value) {
        if ((parseInt($scope.config.deshumidifier_value) + value >= 30) && (parseInt($scope.config.deshumidifier_value) + value <= 70)) {
          $scope.config.deshumidifier_value = (parseInt($scope.config.deshumidifier_value) + value).toString();

          timeouts.deshumidifier_value_timeout = SystemUtilsService.restartTimeout(timeouts.deshumidifier_value_timeout, function () {
            eventsService.createEvent('modconfsistema', $scope.config, 'deshumidifier_value', $scope.config.deshumidifier_value);
          }, TIMEOUT_CONSTANT);

        }
      };

      /*
       * Called when close modal (click out)
       */
      $scope.close = function () {
        $scope.show = false;
        $scope.config = null;
        $rootScope.modalShow = false;
        $rootScope.system_config_target = null;
        $rootScope.system_config = null;
        $scope.showHelp = false;
        $scope.errors_array = null;

        // remove watchers
        if ($scope.listener2) $scope.listener2();
        if ($scope.listener_error) $scope.listener_error();
      };

      /*
       * Translate strint 't'
       * Solved bug (can't call $rootScope directly)
       */
      $scope.i18n = function (t) {
        return $rootScope.i18n(t);
      };

      /*
       * Close all oppened options
       */
      function closeOptions(no_close_var) {
        if (no_close_var != $scope.rocioHistOptsActive)
          $scope.rocioHistOptsActive = false;
        if (no_close_var != $scope.ventilationMode)
          $scope.ventilationMode = false;
        if (no_close_var != $scope.qAdapt)
          $scope.qAdapt = false;
        if (no_close_var != $scope.qAdaptD)
          $scope.qAdaptD = false;
        if (no_close_var != $scope.input)
          $scope.input = false;
        if (no_close_var != $scope.output)
          $scope.output = false;
        if (no_close_var != $scope.tempProtectBoiler)
          $scope.tempProtectBoiler = false;
        if (no_close_var != $scope.tempProtectAirCold)
          $scope.tempProtectAirCold = false;
        if (no_close_var != $scope.tempProtectAirHeat)
          $scope.tempProtectAirHeat = false;
        if (no_close_var != $scope.show_global_ventilation_every_list)
          $scope.show_global_ventilation_every_list = false;
        if (no_close_var != $scope.show_global_ventilation_run_for_list)
          $scope.show_global_ventilation_run_for_list = false;
        if (no_close_var != $scope.show_mode_switching_protection_options)
          $scope.show_mode_switching_protection_options = false;
        if (no_close_var != $scope.show_setpoint_differential_options)
          $scope.show_setpoint_differential_options = false;
        if (no_close_var != $scope.show_heat_ovr_temp_options)
          $scope.show_heat_ovr_temp_options = false;
        if (no_close_var != $scope.show_away_mode_settings_hysteresis_options)
          $scope.show_away_mode_settings_hysteresis_options = false;
        if (no_close_var != $scope.show_away_mode_settings_override_time_options)
          $scope.show_away_mode_settings_override_time_options = false;
        if (no_close_var != $scope.select_available_stages)
          $scope.select_available_stages = false;
        if (no_close_var != $scope.show_first_heating_stage_options)
          $scope.show_first_heating_stage_options = false;
        if (no_close_var != $scope.show_fan_configuration_options)
          $scope.show_fan_configuration_options = false;
        if (no_close_var != $scope.select_available_stage_first) {
          $scope.select_available_stage_first = false;
          $scope.select_first_stage = false;
        }
        if (no_close_var != $scope.select_available_stage_second) {
          $scope.select_available_stage_second = false;
          $scope.select_second_stage = false;
        }
        if (no_close_var != $scope.show_heating_config_gap_options)
          $scope.show_heating_config_gap_options = false;
        if (no_close_var != $scope.show_heating_config_first_stage_options)
          $scope.show_heating_config_first_stage_options = false;
        if (no_close_var != $scope.show_wsccp_installation_type_options) {
          $scope.show_wsccp_installation_type_options = false;
        }
        if (no_close_var != $scope.show_fan_delay_options) {
          $scope.show_fan_delay_options = false;
        }
        if (no_close_var != $scope.show_cold_standby_options) {
          $scope.show_cold_standby_options = false;
        }
        if (no_close_var != $scope.show_heat_standby_options) {
          $scope.show_heat_standby_options = false;
        }
        if (no_close_var != $scope.show_wsccp_activation_delay_options) {
          $scope.show_wsccp_activation_delay_options = false;
        }
        if (no_close_var != $scope.cold_mixing_valve_options) {
          $scope.cold_mixing_valve_options = false;
        }
        if (no_close_var != $scope.show_units_purifier) {
          $scope.show_units_purifier = false;
        }
      }

      /*
       * Remove root scope watchs
       */
      $scope.$on('$destroy', function () {
        $scope.listener1();
        if ($scope.listener2) $scope.listener2();
        if ($scope.listener_error) $scope.listener_error();
      });
    }]);
