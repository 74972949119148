Airzone.controller('userServerController', ['$scope', '$rootScope', 'deviceService', 'sessionService', 'websocketsService', 'DEMO_EMAIL', 'MOBILE_APP', '$timeout',
  function ($scope, $rootScope, deviceService, sessionService, websocketsService, DEMO_EMAIL, MOBILE_APP, $timeout) {
    // if (MOBILE_APP){
    //   var notificationsService = $injector.get('notificationsService');
    // }

    /*
      Variable control select in mobile or web app
      false = When it's web app
      true = When it's mobile app
     */
    $scope.eolo = __eolo;

    $scope.showFilter = false;
    /*
      Variable para controlar cuando no hay servidores listados
      - Muestra un texto cuando no hay servidores.
      - Modifica el estilo del boton de añadir servidor para llamar la atención
      - Oculta el boton de editar.
      - Oculta la lista de servidores.
  
      false = Cuando existan servidores.
      true = Cuando no existan servidores.
    */
    $scope.noServer = false;
    $scope.loader = true;

    /*
      Variable para controlar cuando esta en modo edicion
      - Muestra/oculta los botones de edicion
  
      Se modifica en la plantilla automáticamente al hacer click en el boton de editar
    */
    $rootScope.editServer = false;

    /*
     * Set 'modalShow' to false to close any modal opened before.
     * So it fixes the issue of the lock to do scroll in this view.
     */
    $rootScope.modalShow = false;

    /*
     * Show edit button in home
     */
    $rootScope.show_edit_servers_btn = true;

    /*
     * Show notifications button in adjust
     */
    $rootScope.has_device_in_spain = false;

    /*
     * Show prices chart on push notification
     */
    $rootScope.show_pvpc = false;

    /*
      Variable para controlar que mensaje se mostrara:
      - Cuando no se muestre ningún mesaje asignar el valor false
      - Para mostrar un mensaje, asignar el mismo valor que tenga el mensaje en
        "ng-show"
    */
    $scope.messageServer = false;

    // Destroy websocket
    websocketsService.destroy();

    var timeoutSearch = null;

    // Prevent bottom space when keyboard hides
    $scope.scrollToTop = function($event){
      window.scrollTo(0, 0);
    }

    /*
      Variables para inicializar filtros
    */
    if (!$rootScope.current_page) {
      $rootScope.current_page = 1;
    }
    $scope.current_page = $rootScope.current_page;
    $scope.total_pages = 1;
    $scope.total_devices = 0;
    $scope.get_device_relations = get_device_relations;
    $scope.initFilter = initFilter;

    // show/hide notification alert
    $rootScope.alert_notification = false;
    $rootScope.not_show_alert_notification = false;
    activate();

    // Search devices with filters
    $scope.statusList = [
      { text: $rootScope.i18n('name'), value: "name" },
      { text: $rootScope.i18n('admin_users.device_modal.location'), value: "location" },
      { text: $rootScope.i18n('mac'), value: "mac" }
    ];

    $scope.filterStatus = $scope.statusList[0];

    /*
     * Retreive information from server
     */
    function activate() {
      // set user data to scope user
      sessionService.user().then(
        function (data) {
          $scope.user = data;
        }, function (err) {
          sessionService.unauthorize();
        }
      );
      get_device_relations();
    }

    /*
     * Set list of filters to fix issue when select one option
     */
    function initFilter(){
      $scope.statusList = [
        { text: $rootScope.i18n('name'), value: "name" },
        { text: $rootScope.i18n('admin_users.device_modal.location'), value: "location" },
        { text: $rootScope.i18n('mac'), value: "mac" }
      ];

      get_device_relations();
    }

    /*
     * Get user's devices
     */
    function get_device_relations() {
      $rootScope.current_page = $scope.current_page;
      var dev_rel_final = [];
      $scope.macErrorMessage = false;
      $scope.locationErrorMessage = false;

      // Minimum, maximum of devices in every page
      var i = ($rootScope.current_page * 10) - 10;
      var j = $rootScope.current_page * 10;

      if ($scope.filter && $scope.filterStatus.value){
        var filterParam = {};
        filterParam[$scope.filterStatus.value] = $scope.filter;
      }

      deviceService.getDeviceRelations(null, true, $rootScope.current_page, 10, filterParam).then(function (data) {
          $scope.loader = false;
          $scope.total_pages = data.total_pages;

          if ($scope.user && $scope.user.email == DEMO_EMAIL) {
            data.device_relations.forEach(function (dr) {
              if (dr.type == 'advanced' || dr.permitted_zones.length > 0) {
                if (dr.device.country_code == "ES" && $rootScope.language == "es")
                  dev_rel_final.push(dr);
                if (dr.device.country_code == "EN" && $rootScope.language == "en")
                  dev_rel_final.push(dr);
                if (dr.device.country_code == "FR" && $rootScope.language == "fr")
                  dev_rel_final.push(dr);
                if (dr.device.country_code == "IT" && $rootScope.language == "it")
                  dev_rel_final.push(dr);
                if (dr.device.country_code == "PT" && $rootScope.language == "pt")
                  dev_rel_final.push(dr);
                if (dr.device.country_code == "DE" && $rootScope.language == "de")
                  dev_rel_final.push(dr);
              }
            })
            $scope.total_pages = 1;
            $rootScope.demo = true;
          } else {
            var dr_permitted = [];
            data.device_relations.forEach(function (dr) {
              if (dr.type == 'advanced' || dr.permitted_zones.length > 0) {
                dr_permitted.push(dr);
              }
            })

            dev_rel_final = dr_permitted;
          }

          // Manage show the field form
          if ($rootScope.current_page === 1 && ($scope.total_pages > 1 || $scope.filter)) {
            $scope.showFilter = true;
          }else{
            $scope.showFilter = false;
          }

          setValuesDevicesRelationPage(dev_rel_final);

          return $rootScope.device_relations;
      })
    }


    $scope.getDelayedDeviceRelations = function() {
      if(timeoutSearch) {
        $timeout.cancel(timeoutSearch);
      }
      timeoutSearch = $timeout(get_device_relations, 300)
    }

    /*
     * Set if it has servers or not and which are
     */
    function setValuesDevicesRelationPage(dev_rel) {
      if (dev_rel.length > 0)
        $scope.noServer = false;
      else
        $scope.noServer = true;

      $rootScope.device_relations = dev_rel;
      $scope.device_relations = $rootScope.device_relations;
    }

    /*
     * Get the correct icon
     */
    $scope.getIcon = function (number) {
      return "icon icon-build" + number
    };

    /*
     * Hide edit servers home btn
     */
    $scope.$on('$destroy', function () {
      $rootScope.show_edit_servers_btn = false;
      if (timeoutSearch) {
        $timeout.cancel(timeoutSearch);
      }
    });
  }]);
