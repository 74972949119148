Airzone.controller('demoController', ['initSubmitDemoForm', '$rootScope', '$state', 'sessionService', function (initSubmitDemoForm, $rootScope, $state, sessionService) {
  initialize();

  /*
   * Redirect to demo servers
   */
  function initialize() {
    if (initSubmitDemoForm.data.user) {
      sessionService.saveUserLocal(initSubmitDemoForm.data);
      $rootScope.demo = true;
      $state.go('home.servers');
    } else {
      $state.go('init.login');
    }
  };
}]);
