Airzone.controller('newCloudModalController', ['$scope', '$rootScope', '$window', 'MOBILE_APP', 'GOOGLE_PLAY', 'ITUNES_APPLE',
  function ($scope, $rootScope, $window, MOBILE_APP, GOOGLE_PLAY, ITUNES_APPLE) {
    console.log("Migrated Message");
    $scope.show_android = false;
    $scope.show_android = false;

    if (MOBILE_APP) {
      $scope.isMobile = true;

      if (device.platform === 'Android') {
        $scope.show_android = true;
      }
      if (device.platform === 'iOS') {
        $scope.show_ios = true;
      }
    } else {
      if (getMobileOperatingSystem() === 'Android') {
        $scope.show_android = true;
      } else if (getMobileOperatingSystem() === 'iOS') {
        $scope.show_ios = true;
      } else {
        $scope.show_android = true;
        $scope.show_ios = true;
      }
    }

    /**
     * Determine the mobile operating system.
     * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
     *
     * @returns {String}
     */
    function getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

          // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    /*
     * Translate strint 't'
     * Solved bug (can't call $rootScope directly)
     */
    $scope.i18n = function (t) {
      return $rootScope.i18n(t);
    };

    /*
     * Redirect to new itunes apple airzonecloud
     */
    $scope.goToNewItunesApple = function () {
      if ($rootScope.language === 'en') {
        var lang = 'us';
      } else {
        lang = $rootScope.language;
      }
      var url = 'https://itunes.apple.com/' + lang + '/app/' + ITUNES_APPLE;
      $window.open(url, '_system', 'location=no');
    };

    /*
     * Redirect to new play google airzonecloud
     */
    $scope.goToNewPlayGoogle = function () {
      var url = 'https://play.google.com/store/apps/details?id=' + GOOGLE_PLAY;
      $window.open(url, '_system', 'location=no');
    };

    /*
     * Recharge the page when click
     */
    $scope.closeModal = function () {
      $rootScope.new_cloud_message = false;
    }

  }]);
