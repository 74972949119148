Airzone.controller('userAdvancedAdjustController',
  ['$scope', '$rootScope', '$state', '$stateParams', '$http', 'deviceService', 'systemService', 'sessionService', 'zoneService', 'websocketsService',
    function ($scope, $rootScope, $state, $stateParams, $http, deviceService, systemService, sessionService, zoneService, websocketsService) {

      initialize();

      function initialize() {
        $scope.loader = true;
        $scope.systems = [];
        $scope.device_relation_id = $stateParams.device_relation_id;
        $scope.status = 'list';
        $rootScope.system_config_target = false;
        $rootScope.zone_adjust_target = false;

        websocketsService.init();

        sessionService.user().then(
          function (data) {
            $scope.user = data.user;
          }, function (err) {
            sessionService.unauthorize();
          }
        );

        deviceService.getDeviceRelation($scope.device_relation_id, true).then(function (data) {
          $scope.device_relation = data;
          //websocketsService.destroyChannels();
          websocketsService.createServerChannel(data.device.id, null);

          systemService.getSystemsWithZones($scope.device_relation.device.id, false).then(function (systems) {
            $scope.systems = systems;
            $scope.loader = false;
          });
        });
      };

      /*
       * Open config system modal
       */
      $scope.showConfigModal = function (sys) {
        $scope.loader = true;
        systemService.getSystem(sys.id).then(function (data) {
          $rootScope.system_config_target = data;
          $scope.loader = false;
        }, function () {
          $scope.loader = false;
        });
      };

      /*
       * Open adjust zone modal
       */
      $scope.showAdjustZoneModal = function (zone) {
        $scope.loader = true;
        $rootScope.zones = null;
        zoneService.getZone(zone.id).then(function (data) {
          $rootScope.zone_adjust_target = data;
          $scope.loader = false;
        }, function () {
          $scope.loader = false;
        });
      };

      /*
       * Open adjust zone broadcast modal
       */
      $scope.showBroadcastAdjustZoneModal = function (system) {
        $rootScope.zone_adjust_target = {
          zone_number: 127,
          system_number: system.system_number,
          device_id: system.device_id
        };
      };

      /*
       * Show update confirmation modal
       */
      $scope.showUpdateConfirmation = function () {
        $scope.status = 'update_confirmation';
      };

      /*
       * Update server topology
       */
      $scope.updateServer = function () {
        $scope.status = 'waiting';

        $http({
          method: 'PUT',
          url: url_path + 'devices/' + $scope.device_relation.device.id,
          data: {
            user_token: sessionService.token(),
            user_email: sessionService.email(),
            reset_date: true
          }
        }).success(function (data, status, headers, config) {
        }).error(function (data, status, headers, config) {
          // Logout when unauthorized
          if (status == 401) {
            sessionService.unauthorize();
          } else if (status == 403) {
            $state.go('init.403');
          } else if (status == 404) {
            $state.go('init.404');
          }

        });
      };

      /*
       * Hide update confirmation modal and show list
       */
      $scope.showList = function () {
        $scope.status = 'list';
      };

      /*
       * Called when update topology is finished
       */
      $scope.listener1 = $rootScope.$watch('server_updated', function (new_val, old_val, scope) {
        if (new_val != old_val && new_val) {
          $rootScope.server_updated = false;
          systemService.destroy();
          zoneService.destroy();

          if (!$scope.systems) {
            $rootScope.disconnected_device = true;
          }
          initialize();
        }
      });

      /*
       * Remove all systems and zones from rootScope
       */
      $scope.$on('$destroy', function () {
        $scope.listener1();
        if ($scope.device_relation && $scope.device_relation.device) {
          zoneService.deleteDeviceZones($scope.device_relation.device.id);
          systemService.deleteDeviceSystems($scope.device_relation.device.id);
        }
      });
    }]);
