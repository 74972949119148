Airzone.controller('editServerController', ['$scope', '$http', 'sessionService', '$stateParams', 'deviceService', '$state', 'eventsService', 'weatherService', '$rootScope', 'operatingSystemService', '$window',
  function ($scope, $http, sessionService, $stateParams, deviceService, $state, eventsService, weatherService, $rootScope, operatingSystemService, $window) {
    // Flag to show or hide error messages
    $scope.show_error = false;
    $scope.show_success = false;
    $scope.show_sync_success = false;
    $scope.taken_error = false;
    $scope.mac_error = false;
    $scope.pin_error = false;
    $scope.messageServer = false;
    $scope.device_relation_id = '';


    activate();

    // Prevent bottom space when keyboard hides
    $scope.scrollToTop = function ($event) {
      window.scrollTo(0, 0);
    }

    /*
     * Called when controller is loaded
     */
    function activate() {
      // Load device data to edit
      deviceService.getDeviceRelation($stateParams.id).then(function (dr) {
        $scope.server = {};
        $scope.server.mac = dr.device.mac;
        $scope.server.pin = dr.device.pin;
        $scope.server.name = dr.device.name;
        $scope.server.icon = dr.device.icon;
        $scope.server.sync_datetime = dr.device.sync_datetime;
        $scope.server.warranty = dr.device.warranty;
        $scope.server.spot_name = dr.device.spot_name;
        if (dr.device.complete_name && dr.device.complete_name[$rootScope.language])
          $scope.server.complete_name = dr.device.complete_name[$rootScope.language];
        else
          $scope.server.complete_name = dr.device.complete_name;
        $scope.server.spot_location = dr.device.spot_location;
        $scope.device_id = dr.device.id;
        $scope.device_relation = dr;
      });
    };

    /*
     * POST register request to server and proccess response
     */
    $scope.submitEditServerForm = function () {
      if ($scope.editServerForm.$valid) {
        $http({
          method: 'PUT',
          url: url_path + 'devices/' + $scope.device_id,
          responseType: 'json',
          data: {
            device: {
              name: $scope.server.name,
              icon: $scope.server.icon,
              sync_datetime: $scope.server.sync_datetime,
              warranty: $scope.server.warranty,
              spot_location: $scope.server.spot_location,
              spot_complete_name: $scope.server.spot_complete_name,
              spot_google_id: $scope.server.spot_google_id,
              spot_google_reference: $scope.server.spot_google_reference,
              spot_country_code: $scope.server.spot_country_code
            },
            user_email: sessionService.email(),
            user_token: sessionService.token(),
          }
        }).success(function (data, status, headers, config) {
          $scope.show_success = true;
        }).error(function (data) {
          console.log('Error:', data);
          if (data && data.errors && data.errors.length > 0
            && data.errors[0] === 'migrated') {
            console.log('Invitation of migrated user');
            let type = operatingSystemService.detectOperatingSystem();
            $scope.show_android = type.android;
            $scope.show_ios = type.ios;
            $scope.user_migrated_invitation = true;
          }
          // Logout when unauthorized
          if (status == 401) {
            sessionService.unauthorize();
          } else if (status == 403) {
            $state.go('init.403');
          } else if (status == 404) {
            $state.go('init.404');
          }

        });
      }
      else {
        $scope.editServerForm.invalid = true;
      }
    };

    /*
     * Hide error message and show form
     */
    $scope.acceptError = function () {
      $scope.show_error = false;
      $scope.taken_error = false;
      $scope.mac_error = false;
      $scope.pin_error = false;
    };

    /*
     * Show delete confirmation of a server
     */
    $scope.deleteConfirmation = function (device_relation) {
      $scope.to_remove = device_relation;
      $scope.messageServer = 'deleteServerConfirm';
    };

    /*
     * Remove a device_relation form the server and the list
     */
    $scope.removeDeviceRelation = function () {
      return deviceService.deleteDeviceRelation($scope.device_relation).then(function (data) {
        $scope.messageServer = 'deleteSuccess';
      }, function (data) {
        if (data === "User migrated.") {
          console.log('Invitation of migrated user');
          let type = operatingSystemService.detectOperatingSystem();
          $scope.show_android = type.android;
          $scope.show_ios = type.ios;
          $scope.user_migrated_invitation = true;
        }
        $scope.messageServer = 'errorServer';
      });
    };

    /*
     * Syncronize Date and Time in the server
     */
    $scope.syncDateTime = function () {
      weatherService.getTimezone($scope.device_relation.device.location).then(function (data, status, headers, config) {
        if (!data.data.error) {
          // actual date and time
          var datetime = data.data.time_zone[0].localtime;
          var week_day = new Date();
          var date = datetime.split(' ')[0];
          var time = datetime.split(' ')[1];
          var year = date.split('-')[0].substr(2, 2);
          var month = date.split('-')[1];
          var day = date.split('-')[2];
          var hour = time.split(':')[0];
          var minute = time.split(':')[1];
          if (week_day.getDay() == 0)
            var weekday = 7;
          else
            var weekday = week_day.getDay();

          var utcOffset = data.data.time_zone[0].utcOffset;
          var timezone = data.data.time_zone[0].zone;
          var timezoneAbbr = moment.tz(datetime, timezone).format('z');
          var utcDatetime = moment.utc(week_day).format()

          var info = {
            device_id: $scope.device_relation.device.id,
            system_number: 0
          };

          eventsService.createEvent('modclima', info, 'year', year + '&month=' + month + '&day=' + day + '&dayweek=' + weekday + '&hour=' + hour + '&minute=' + minute + '&utcOffset=' + utcOffset + '&timezone=' + timezone + '&utcDatetime=' + utcDatetime + '&timezoneAbbr=' + timezoneAbbr).then(function (data) {
            $scope.show_sync_success = true;
          }, function (error) {
            console.log("Error syncronizing datetime: ", error);
          });

        }
      }, function (data, status, headers, config) {
        console.log("Error al obtener la fecha con la api del tiempo.");
      });
    };

    /*
     * Syncronize Date and Time in the server
     */
    $scope.toggleWarranty = function () {
      $scope.server.warranty = !$scope.server.warranty;
      $scope.submitEditServerForm();
    };

    /*
* Open Itunnes Apple
*/
    $scope.openItunesApple = function () {
      $window.open(operatingSystemService.goToNewItunesApple(), '_system', 'location=no');
    }

    /*
     * Open Itunnes Apple
     */
    $scope.openPlayGoogle = function () {
      $window.open(operatingSystemService.goToNewPlayGoogle(), '_system', 'location=no');
    }

    /*
     * Recharge the page when click
     */
    $scope.closeModal = function () {
      $scope.user_migrated_invitation = false;
    }
  }]);
