// eslint-disable-next-line no-undef
Airzone.controller('newcloudController', ['$scope', '$rootScope', '$state', '$http', '$window', 'MOBILE_APP', 'GOOGLE_PLAY', 'ITUNES_APPLE',
  function ($scope, $rootScope, $state, $http, $window, MOBILE_APP, GOOGLE_PLAY, ITUNES_APPLE) {

    

    /*
     * Reload login page
     */
    $scope.reloadLogin = function () {
      $state.go('init.login', {}, { reload: true });
    };

    initialize();

    function initialize() {
      // eslint-disable-next-line no-undef
      if (!__eolo && !$rootScope.browser_continue) {
        $http({
          method: 'POST',
          // eslint-disable-next-line no-undef
          url: url_path + 'browsers/',
          data: {},
          params: { format: 'json' }
        }).success(function (data) {
          if (!data.supported)
            $state.go('init.browsers');
        });
      }
    }
  }]);
