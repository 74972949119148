Airzone.controller('notConnectedDeviceModalController', ['$scope', '$rootScope', '$state',
  function ($scope, $rootScope, $state) {

    // Variable to manage the modal of internet
    $rootScope.disconnected_device = false;

    /*
     * Translate strint 't'
     * Solved bug (can't call $rootScope directly)
     */
    $scope.i18n = function (t) {
      return $rootScope.i18n(t);
    };

    /*
     * Recharge the page when click
     */
    $scope.refreshPage = function () {
      $rootScope.disconnected_device = false;
      $rootScope.error_device = false;
      $state.go("home.servers", {}, { reload: true });
    };

    /*
     * Close modal
     */
    $scope.close = function () {
      $rootScope.disconnected_device = false;
      $rootScope.error_device = false;
    };

    /*
     * Close modal not systems
     */
    $scope.closeModal = function () {
      $rootScope.not_systems = false;
      $rootScope.show_alerts = false;
      $rootScope.auto_mode_battery_temperature = false;
    };

  }]);
