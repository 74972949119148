Airzone.controller('confirmationController', ['$scope', '$http', '$state', '$stateParams', 'sessionService',
  function ($scope, $http, $state, $stateParams, sessionService) {
    // Flag to show or hide error messages
    $scope.confirmation_error = false;
    $scope.confirmation_success = false;
    $scope.error_token = false;
    $scope.error_from_server = '';

    if ($stateParams.invalid_token) {
      $scope.error_token = true;
    }

    /*
     */
    $scope.submitConfirmationForm = function () {
      if ($scope.confirmationForm.$valid) {
        $http({
          method: 'POST',
          url: url_path + 'users/confirmation',
          responseType: 'json',
          data: {
            user: {
              email: this.confirmation.email,
            }
          }
        }).success(function (data, status, headers, config) {
          $scope.confirmation_success = true;
        }).error(function (data, status, headers, config) {
          $scope.confirmationForm.invalid = true;
          $scope.error_from_server = data.errors.email[0]
          $scope.confirmation_error = true;
        });
      } else {
        $scope.confirmationForm.invalid = true;
        // Logout when unauthorized
        if (status == 401) {
          sessionService.unauthorize();
        } else if (status == 403) {
          $state.go('init.403');
        } else if (status == 404) {
          $state.go('init.404');
        }
      }
    };
  }]);
