Airzone.controller('modalServerSchedulesController',
  ['$scope', '$rootScope', '$window', 'zoneService', 'eventsService', 'programService', 'systemService', 'operatingSystemService',
    function ($scope, $rootScope, $window, zoneService, eventsService, programService, systemService, operatingSystemService) {

      $scope.eolo = __eolo;
      $scope.showHelp = false;
      $scope.schedule_modes = ["", "", "", "ventilate", "", "heat", "", "", "", "cool"];

      $scope.show_view = 'list';
      $scope.class_modal_mode = null;
      $scope.available_modes = [];
      $scope.error_day = false;
      $scope.block_update = false;
      $scope.not_firm_ws = true;
      $scope.has_vmc_control = false;

      $scope.array_months = [];
      for (var i = 1; i <= 12; i++) {
        $scope.array_months.push({ id: i, label: $rootScope.i18n('server.months.' + (i - 1)) });
      }

      /*
       * Translate strint 't'
       * Solved bug (can't call $rootScope directly)
       */
      $scope.i18n = function (t) {
        return $rootScope.i18n(t);
      };

      /*
       * Open modal ZBS schedules
       */
      $scope.listener1 = $rootScope.$watch('server_schedules_target', function (new_target) {
        if (new_target)
          initialize(new_target);
      });

      /*
       * Refresh the list of new schedules
       */
      function initialize(new_target) {
        $scope.loader = false;
        if ($rootScope.show_calendar_schedules) {
          $scope.show_view = 'calendar_schedules';
        } else {
          $scope.show_view = 'server_schedules';
        }
        $scope.target = new_target;
        console.log($scope.target);
        $scope.schedules = null;
        $scope.virtual_schedule = null;
        $scope.schedule_days = (moment().isoWeekday() - 1);

        if ($scope.target.systems.length > 0 && $scope.target.systems[0].temperature_unit !== undefined) {
          $scope.unit = parseInt(new_target.temperature_unit);
        }

        $scope.show = true;
        $rootScope.modalShow = true;

        if ($rootScope.demo) {
          $rootScope.update_new_schedules = true;
        } else {
          createEventInfoProg2();
        }
      }

      /*
       * Create event infoprog2 to request the server schedules
       */
      function createEventInfoProg2() {
        if ($scope.target.systems[0]) {
          $scope.info = {
            device_id: $scope.target.id,
            system_number: $scope.target.systems[0].system_number
          };

          eventsService.createEvent('infoprog2', $scope.info, null, null).then(function () {
            console.log("Event created");
          }).catch(function (error) {
            console.log("Error requesting schedules: ", error);
          });
        }
      }

      /*
       * Create event infoprogramacion to request the server schedules
       */
      function createEventInfoProg(schedule_number) {
        $scope.info = {
          device_id: $scope.target.id,
          schedule_number: schedule_number
        };

        eventsService.createEvent('infoprogramacion', $scope.info, null, null).then(function () {
          console.log("Event created");
        }).catch(function (error) {
          console.log("Error requesting schedules: ", error);
        });
      }

      /*
       * When change update_new_schedules from websocket channel then it request and show calendar or week schedules
       */
      $scope.listener2 = $rootScope.$watch('update_new_schedules', function (new_value, old_value) {
        if (!$scope.block_update && new_value != old_value && new_value) {
          // Request the server schedules
          programService.getPrograms($scope.target.id).then(function (data) {
            $scope.loader = true;
            $scope.schedules = data;
            $scope.weekday_schedules = [];
            $scope.calendar_schedules = [];
            if ($scope.schedules && $scope.schedules.length > 0) {
              $scope.monday_schedules = [];
              $scope.tuesday_schedules = [];
              $scope.wednesday_schedules = [];
              $scope.thursday_schedules = [];
              $scope.friday_schedules = [];
              $scope.saturday_schedules = [];
              $scope.sunday_schedules = [];

              // Iterate schedules to show calendar or week schedules
              $scope.schedules.forEach(function (schedule) {
                schedule.show_systems_selected = false;
                if (schedule.start_date) {
                  $scope.calendar_schedules.push(schedule);
                } else if (schedule.days && schedule.systems[0] && schedule.systems[0].system_number !== '0') {
                  // Organize every schedule by week day
                  for (var i = 0; i < 7; i++) {
                    if (schedule.days[i] == "1") {
                      switch (i) {
                        case 0:
                          $scope.monday_schedules.push(schedule);
                          break;
                        case 1:
                          $scope.tuesday_schedules.push(schedule);
                          break;
                        case 2:
                          $scope.wednesday_schedules.push(schedule);
                          break;
                        case 3:
                          $scope.thursday_schedules.push(schedule);
                          break;
                        case 4:
                          $scope.friday_schedules.push(schedule);
                          break;
                        case 5:
                          $scope.saturday_schedules.push(schedule);
                          break;
                        case 6:
                          $scope.sunday_schedules.push(schedule);
                          break;
                      }
                    }
                  }
                }
              })
              setWeekDaySchedules($scope.schedule_days);
            } else {
              $rootScope.schedules = [];
            }
          }, function (error) {
            console.log("Error get schedules: ", error)
          });
        }
        $rootScope.update_new_schedules = false;
      });

      /*
       * Show or hide the systems selected
       */
      $scope.showHideSystemsSelected = function (schedule) {
        schedule.show_systems_selected = !schedule.show_systems_selected;
        if (schedule.show_systems_selected && !schedule.enabled_zones_number) {
          var enabled_zones_number = 0;
          var enabled_systems_number = 0;
          schedule.systems.forEach(function (sys) {
            var system = $scope.target.systems.find(function (system) { return system.system_number === sys.system_number });
            if (system) {
              sys.name = system.name;
              var aux_zones_selected = [];
              var all_zones = true;
              // If it has some zone enabled then count the selected systems
              if (sys.zones_enabled.length > 0) {
                enabled_systems_number += 1;
              }
              sys.zones_enabled.forEach(function (zone_number) {
                var zone = system.zones.find(function (zone) { return zone.zone_number === zone_number });
                if (zone) {
                  aux_zones_selected.push({ zone_number: zone.zone_number, zone_name: zone.name, zone_selected: true });
                  enabled_zones_number += 1;
                } else {
                  all_zones = false;
                }
              })
              sys.zones_selected = aux_zones_selected;
              sys.system_selected = all_zones;
            }
          })
          schedule.enabled_systems_number = enabled_systems_number;
          schedule.enabled_zones_number = enabled_zones_number;
        }
      }

      function setZonesSelected(schedule) {
        var all_systems = true;
        var edit_zones_selected = [];
        // Set selected zones and fill by default the zones not selected
        $scope.target.systems.forEach(function (system) {
          // Check if the system has vmc control
          if (system.firm_ws && parseFloat(system.firm_ws) >= 3.20 && system.VMC_mode && system.VMC_mode == "1" && !$rootScope.show_calendar_schedules) {
            $scope.has_vmc_control = true;
          }
          // Find system number of the schedule in all systems for fill zones disabled by default
          var sys = schedule.systems.find(function (sys) { return sys.system_number === system.system_number });
          if (sys) {
            sys.name = system.name;
            var aux_zones_selected = [];
            // Check if it's a system with zones to enable/disable the system button
            if (system.zones.length > 0) {
              var all_zones = true;
            } else {
              all_systems = false;
              all_zones = false;
            }

            system.zones.forEach(function (zone) {
              var z = sys.zones_enabled.find(function (item) { return item === zone.zone_number });
              if (z) {
                aux_zones_selected.push({ zone_number: zone.zone_number, zone_name: zone.name, zone_selected: true });
              } else {
                aux_zones_selected.push({ zone_number: zone.zone_number, zone_name: zone.name, zone_selected: false });
                all_systems = false;
                all_zones = false;
              }
            })
            sys.zones_selected = aux_zones_selected;
            sys.system_selected = all_zones;
            edit_zones_selected.push(sys);
          } else {
            var zones_selected = [];
            var item = {};
            //check if it's a system with zones to enable/disable the system button
            if (system.zones.length > 0) {
              all_zones = true;
            } else {
              all_systems = false;
              all_zones = false;
            }
            item.name = system.name;
            item.system_number = system.system_number;
            item.system_selected = false;
            // Set the values to new array of zones selected
            system.zones.forEach(function (z) {
              zones_selected.push({ zone_number: z.zone_number, zone_name: z.name, zone_selected: false });
            })

            item.zones_enabled = zones_selected;
            item.zones_selected = zones_selected;
            edit_zones_selected.push(item);
          }
        })

        return { 'zones_selected': edit_zones_selected, 'all_systems': all_systems };
      }

      /*
       * Seek free schedule number
       */
      function seekFreeScheduleNumber() {
        if (!$scope.schedules || $scope.schedules.length == 0)
          $scope.last_schedule_number = 1;
        else {
          var i = 0;
          var not_found = true;
          while (i < $scope.schedules.length && not_found) {
            if ((i + 1).toString() != $scope.schedules[i].schedule_number) {
              $scope.last_schedule_number = i + 1;
              not_found = false;
            }
            i += 1;
          }

          if (not_found)
            $scope.last_schedule_number = parseInt($scope.schedules[$scope.schedules.length - 1].schedule_number) + 1;
        }
      }

      /*
       * Change the view of the modal
       */
      $scope.changeToNewView = function () {
        if (typeof $scope.target.ws_scheds_active !== 'boolean') {
          seekFreeScheduleNumber();
          var n_name = parseInt($scope.last_schedule_number);

          if (n_name < 10) {
            n_name = '0' + n_name;
          }
          // Prepare zones selected for new schedule
          var new_zones_selected = [];
          var enabled_zones_number = 0;
          var enabled_systems_number = 0;
          $scope.target.systems.forEach(function (system) {
            var zones_selected = [];
            var item = {};

            // Check if the system has vmc control
            if (system.firm_ws && parseFloat(system.firm_ws) >= 3.20 && system.VMC_mode && system.VMC_mode == "1" && !$rootScope.show_calendar_schedules) {
              $scope.has_vmc_control = true;
              enabled_systems_number += 1;
            }

            // Set the values to new arrayenabled_zones_number selected
            system.zones.forEach(function (z) {
              zones_selected.push({ zone_number: z.zone_number, zone_name: z.name, zone_selected: true });
              enabled_zones_number += 1;
            })

            item.zones_enabled = zones_selected;
            item.zones_selected = zones_selected;
            item.system_number = system.system_number;
            item.name = system.name;
            item.system_selected = true;
            new_zones_selected.push(item);
          })

          if ($scope.unit) {
            var temp = 68;
          } else {
            temp = 20;
          }

          // Check maximum and minimum limits of systems
          maxSystems($scope.unit);
          minSystems($scope.unit);

          // Set temperature minimum of systems if it's less
          if (temp < $scope.min_systems) {
            temp = Number($scope.min_systems);
          }

          var VMC_state = $scope.has_vmc_control ? false : null;

          $scope.virtual_schedule = {
            enabled: '1',
            VMC_state: VMC_state,
            state: false,
            temp: temp,
            mode: 9,
            name: 'PROG. ' + n_name,
            device_id: $scope.target.id,
            schedule_number: parseInt($scope.last_schedule_number),
            all_systems: true,
            enabled_systems_number: enabled_systems_number,
            enabled_zones_number: enabled_zones_number,
            zones_selected: new_zones_selected
          };

          if ($rootScope.show_calendar_schedules) {
            setDefaultsDays(moment(), moment());
          } else {
            $scope.virtual_schedule.days = ['0', '0', '0', '0', '0', '0', '0'];
            //$scope.virtual_schedule.days[$scope.schedule_days] = '1';
            $scope.virtual_schedule.hour = '00';
            $scope.virtual_schedule.minute = '00';
          }

          // Prepare views
          $scope.new_edit_type = 'new';
          $scope.show_view = 'new';
          $scope.show_temp = true;
          $scope.show_power = false;
          $scope.show_vmc = false;
          $scope.block_update = false;
          //$scope.new_schedule_modified = true;
        } else {
          migratedInstallationMessage();
        }
      }

      /*
       * Change to edit view
       */
      $scope.changeToEditView = function (schedule) {
        if (typeof $scope.target.ws_scheds_active !== 'boolean') {
          // Initialize views
          $scope.show_power = false;
          $scope.show_temp = false;
          $scope.show_vmc = false;

          // Check if it has systems selected
          if (!schedule.enabled_zones_number) {
            $scope.showHideSystemsSelected(schedule);
          }
          schedule.show_systems_selected = false;

          var object = setZonesSelected(schedule);

          $scope.virtual_schedule = {
            enabled: schedule.enabled,
            VMC_state: schedule.VMC_state,
            state: schedule.state,
            temp: schedule.temp,
            mode: schedule.mode,
            hour: schedule.hour,
            minute: schedule.minute,
            days: schedule.days,
            name: schedule.name,
            enabled_systems_number: schedule.enabled_systems_number,
            enabled_zones_number: schedule.enabled_zones_number,
            device_id: schedule.device_id,
            schedule_number: schedule.schedule_number,
            zones_selected: object.zones_selected,
            all_systems: object.all_systems
          };

          // Set the dates in the calendar inputs
          if ($rootScope.show_calendar_schedules) {
            setDefaultsDays(moment(schedule.start_date, "MM-DD"), moment(schedule.end_date, "MM-DD"));
          } else {
            // Normalize hour
            if ($scope.virtual_schedule.hour.length == 1)
              $scope.virtual_schedule.hour = '0' + $scope.virtual_schedule.hour
            if ($scope.virtual_schedule.minute.length == 1)
              $scope.virtual_schedule.minute = '0' + $scope.virtual_schedule.minute
          }

          // Show the type of schedule
          if ($scope.virtual_schedule.temp)
            $scope.show_temp = true;
          if ($scope.virtual_schedule.state || $scope.virtual_schedule.state === false)
            $scope.show_power = true;
          if ($scope.virtual_schedule.VMC_state || $scope.virtual_schedule.VMC_state === false)
            $scope.show_vmc = true;

          // Prepare edit view
          $scope.new_edit_type = 'edit';
          $scope.show_view = 'new';
          $scope.block_update = false;
        } else {
          migratedInstallationMessage();
        }
      }

      /*
       * Show message of migrated installation
       */
      function migratedInstallationMessage() {
        console.log('Schedules migrated');
        let type = operatingSystemService.detectOperatingSystem();
        $scope.show_android = type.android;
        $scope.show_ios = type.ios;
        $scope.migrated_installation = true;
      }

      /*
       * Open Itunnes Apple
       */
      $scope.openItunesApple = function () {
        $window.open(operatingSystemService.goToNewItunesApple(), '_system', 'location=no');
      }

      /*
       * Open Itunnes Apple
       */
      $scope.openPlayGoogle = function () {
        $window.open(operatingSystemService.goToNewPlayGoogle(), '_system', 'location=no');
      }

      /*
       * Recharge the page when click
       */
      $scope.closeModal = function () {
        $scope.migrated_installation = false;
      }

      /*
       * Set the schedules of the weekday
       */
      function setWeekDaySchedules(day) {
        switch (day) {
          case 0:
            $scope.weekday_schedules = $scope.monday_schedules;
            break;
          case 1:
            $scope.weekday_schedules = $scope.tuesday_schedules;
            break;
          case 2:
            $scope.weekday_schedules = $scope.wednesday_schedules;
            break;
          case 3:
            $scope.weekday_schedules = $scope.thursday_schedules;
            break;
          case 4:
            $scope.weekday_schedules = $scope.friday_schedules;
            break;
          case 5:
            $scope.weekday_schedules = $scope.saturday_schedules;
            break;
          case 6:
            $scope.weekday_schedules = $scope.sunday_schedules;
            break;
        }
      }

      /*
       * Toggle Week Day
       */
      $scope.toggleWeekDay = function (day) {
        $scope.weekday_schedules.forEach(function (schedule) {
          schedule.show_systems_selected = false;
        })
        $scope.schedule_days = day;
        // List schedules of this day
        setWeekDaySchedules(day);
      }

      /*
       * Enable flag when it modifies any field 
       */
      $scope.enableModified = function () {
        if ($scope.new_edit_type === 'new') {
          $scope.new_schedule_modified = true;
        } else if ($scope.new_edit_type === 'edit') {
          $scope.edit_schedule_modified = true;
        }
      }

      /*
       * Set the quantity of days according the month in the options of days
       */
      function setDaysMonth(date, is_begin) {
        if (is_begin) {
          $scope.array_days_begin = [];
          for (var i = 1; i < date.daysInMonth() + 1; i++) {
            $scope.array_days_begin.push(i);
          }
        } else {
          $scope.array_days_end = [];
          for (i = 1; i < date.daysInMonth() + 1; i++) {
            $scope.array_days_end.push(i);
          }
        }
      }

      /*
       * Set the numbers to the fields form to edit or create
       */
      function setDefaultsDays(date_start, date_end) {
        setDaysMonth(date_start, true);
        setDaysMonth(date_end, false);

        $scope.selected_month_begin = $scope.array_months[(parseInt((date_start).format('MM')) - 1)];
        $scope.selected_month_end = $scope.array_months[(parseInt((date_end).format('MM')) - 1)];

        $scope.selected_day_begin = parseInt((date_start).format('DD'));
        $scope.selected_day_end = parseInt((date_end).format('DD'));
      }

      /*
       * Set dates in the corresponding fields and the correct format
       */
      function setDates() {
        var date_begin = moment().date($scope.selected_day_begin).month($scope.selected_month_begin.id - 1);
        var date_end = moment().date($scope.selected_day_end).month($scope.selected_month_end.id - 1);

        $scope.virtual_schedule.start_date = moment(date_begin).format("MM-DD");
        $scope.virtual_schedule.end_date = moment(date_end).format("MM-DD");
      }

      /*
       * Format Date
       */
      $scope.formatDate = function (date) {
        return moment(date, "MM-DD").format("D MMMM");
      }

      /*
       * Set month or day and close the toggle
       */
      $scope.chooseDate = function (date, date_type, is_begin) {
        if (date_type === 'month') {
          if (is_begin) {
            $scope.selected_month_begin = date;
            $scope.show_month_start_select = false;
          } else {
            $scope.selected_month_end = date;
            $scope.show_month_end_select = false;
          }
          // Fill the list of the days month
          setDaysMonth(moment([2020, (date.id - 1), 1]), is_begin);
        } else {
          if (is_begin) {
            $scope.selected_day_begin = date;
            $scope.show_day_start_select = false;
          } else {
            $scope.selected_day_end = date;
            $scope.show_day_end_select = false;
          }
        }

        // Mark the flag the modified field
        $scope.enableModified();
      };

      /*
       * Close all popup  
       */
      function closeToggleDate(is_begin, date_type) {
        if (date_type === 'day' && is_begin) {
          $scope.show_month_start_select = false;
          $scope.show_month_end_select = false;
          $scope.show_day_end_select = false;
        } else if (date_type === 'day' && !is_begin) {
          $scope.show_month_start_select = false;
          $scope.show_month_end_select = false;
          $scope.show_day_start_select = false;
        } else if (date_type === 'month' && is_begin) {
          $scope.show_month_end_select = false;
          $scope.show_day_start_select = false;
          $scope.show_day_end_select = false;
        } else if (date_type === 'month' && !is_begin) {
          $scope.show_month_start_select = false;
          $scope.show_day_start_select = false;
          $scope.show_day_end_select = false;
        }
      }

      /*
       * Open or close the options of months
       */
      $scope.toggleMonth = function (is_begin) {
        closeToggleDate(is_begin, 'month');
        if (is_begin) {
          $scope.show_month_start_select = !$scope.show_month_start_select
        } else {
          $scope.show_month_end_select = !$scope.show_month_end_select;
        }
      };

      /*
       * Open or close the options of days
       */
      $scope.toggleDay = function (is_begin) {
        closeToggleDate(is_begin, 'day');
        if (is_begin) {
          $scope.show_day_start_select = !$scope.show_day_start_select;
        } else {
          $scope.show_day_end_select = !$scope.show_day_end_select;
        }
      };

      /*
       * Get mode class
       */
      $scope.getModeClass = function (class_mode) {
        if (class_mode != null)
          return $scope.schedule_modes[parseInt(class_mode)];
      };

      /*
       * Change the type of the schedule
       */
      $scope.changeType = function (type) {
        // If change from vmc type or to vmc type then it initializes to all zones selected
        if ((type === 'vmc' || $scope.show_vmc) && !(type === 'vmc' && $scope.show_vmc)) {
          $scope.virtual_schedule.all_systems = false;
          $scope.toggleAllSystems();
        }
        if (type === 'temp') {
          if ($scope.unit) {
            $scope.virtual_schedule.temp = 68;
          } else {
            $scope.virtual_schedule.temp = 20;
          }
          $scope.show_temp = true;
          $scope.show_power = false;
          $scope.show_vmc = false;
        } else if (type == 'power') {
          $scope.show_vmc = false;
          $scope.show_temp = false;
          $scope.show_power = true;
        } else if (type == 'vmc') {
          $scope.show_vmc = true;
          $scope.show_temp = false;
          $scope.show_power = false;
        }
        $scope.enableModified();
      };

      /*
       * Change Power
       */
      $scope.togglePower = function () {
        $scope.virtual_schedule.state = !$scope.virtual_schedule.state;
        $scope.enableModified();
      };

      /*
       * Change VMC
       */
      $scope.toggleVMC = function () {
        $scope.virtual_schedule.VMC_state = !$scope.virtual_schedule.VMC_state;
        $scope.enableModified();
      };

      /*
       * ToggleDays
       */
      $scope.toggleDays = function (day) {
        $scope.enableModified();
        if ($scope.virtual_schedule.days[day] == '0')
          $scope.virtual_schedule.days[day] = '1';
        else
          $scope.virtual_schedule.days[day] = '0';
      };

      /*
       * Launch select of hour
       */
      $scope.selectNew = function (type) {
        $scope.select_type = type;
        if (type == 'hour') {
          $scope.preview_show = 'new';
          $scope.show_view = 'hour';
        } else if (type == 'temp') {
          $scope.preview_show = 'new';
          $scope.show_view = 'temp';
        } else if (type == 'mode') {
          $scope.available_modes = ['cool', 'heat', 'ventilate'];
          $scope.preview_show = 'new';
          $scope.show_view = 'modes';
          $scope.class_modal_mode = $scope.schedule_modes[parseInt($scope.virtual_schedule.mode)];
        }
      }

      /*
       * Update target with mode selected in modal
       * @params {string} mode to change in the target
       */
      $scope.selectMode = function (class_mode) {
        $scope.enableModified();
        $scope.virtual_schedule.mode = getModeFromClass(class_mode);
        maxSystems($scope.unit);
        minSystems($scope.unit);
        if ($scope.virtual_schedule.mode === 9 && $scope.virtual_schedule.temp < $scope.min_systems) {
          $scope.virtual_schedule.temp = Number($scope.min_systems);
        } else if ($scope.virtual_schedule.mode === 5 && $scope.virtual_schedule.temp > $scope.max_systems) {
          $scope.virtual_schedule.temp = Number($scope.max_systems);
        }
        $scope.class_modal_mode = class_mode;
        $scope.show_view = 'new';
      };

      /*
       * Return mode id for mode class_name
       */
      function getModeFromClass(class_name) {
        return $scope.schedule_modes.indexOf(class_name);
      }

      /*
       * Return to
       */
      $scope.returnToNew = function () {
        $scope.show_view = 'new';
      };

      /*
       * Change Hour
       */
      $scope.changeHour = function (type, value) {
        var max = 24;
        if (type == 'hour') {
          $scope.enableModified();
          if (parseInt($scope.virtual_schedule.hour) == 0 && value < 0)
            $scope.virtual_schedule.hour = (max - 1).toString();
          else
            $scope.virtual_schedule.hour = ((parseInt($scope.virtual_schedule.hour) + value) % max).toString();
          if ($scope.virtual_schedule.hour == '23' && $scope.virtual_schedule.minute == '59')
            $scope.virtual_schedule.minute = '58';
          // Format Result
          if ($scope.virtual_schedule.hour.length == 1)
            $scope.virtual_schedule.hour = "0" + $scope.virtual_schedule.hour;
        }
      }

      /*
       * Change Minute
       */
      $scope.changeMinute = function (type, value) {
        var max = 60;
        if (type == 'hour') {
          $scope.enableModified();
          if (parseInt($scope.virtual_schedule.minute) == 0 && value < 0)
            $scope.virtual_schedule.minute = (max - ($scope.virtual_schedule.hour == '23' && $scope.not_firm_ws ? 2 : 1)).toString();
          else {
            $scope.virtual_schedule.minute = ((parseInt($scope.virtual_schedule.minute) + value) % max).toString();
            if (($scope.virtual_schedule.hour == '23' && $scope.virtual_schedule.minute == '59') && $scope.not_firm_ws)
              $scope.virtual_schedule.minute = '00';
          }
          // Format Result
          if ($scope.virtual_schedule.minute.length == 1)
            $scope.virtual_schedule.minute = "0" + $scope.virtual_schedule.minute;
        }
      }

      /*
       * Method to return if buttons plus and minus should be hidden or not
       */
      $scope.showTempPlusMinus = function (isMinTemp) {
        var bool;
        if (!$scope.virtual_schedule) {
          return false;
        }

        if ($scope.unit) {
          if ($scope.virtual_schedule.mode === 9) {
            isMinTemp ? bool = ($scope.virtual_schedule.temp != $scope.min_systems) : bool = ($scope.virtual_schedule.temp != 86);
          } else if ($scope.virtual_schedule.mode === 5) {
            isMinTemp ? bool = ($scope.virtual_schedule.temp != 60) : bool = ($scope.virtual_schedule.temp != $scope.max_systems);
          } else {
            isMinTemp ? bool = ($scope.virtual_schedule.temp != 60) : bool = ($scope.virtual_schedule.temp != 86);
          }
        } else {
          if ($scope.virtual_schedule.mode === 9) {
            isMinTemp ? bool = ($scope.virtual_schedule.temp != $scope.min_systems) : bool = ($scope.virtual_schedule.temp != 30);
          } else if ($scope.virtual_schedule.mode === 5) {
            isMinTemp ? bool = ($scope.virtual_schedule.temp != 15) : bool = ($scope.virtual_schedule.temp != $scope.max_systems);
          } else {
            isMinTemp ? bool = ($scope.virtual_schedule.temp != 15) : bool = ($scope.virtual_schedule.temp != 30);
          }
        }
        return bool;
      };

      /*
       * Change Temp
       */
      $scope.changeTemp = function (value) {
        var maxMin, consignaFinal;

        consignaFinal = parseInt($scope.virtual_schedule.temp) + value;
        if ($scope.unit) {
          // farenheit
          maxMin = { min: 60, max: 86 };
          // Change minimum value if it's cold mode
          if ($scope.virtual_schedule.mode === 9) {
            maxMin.min = $scope.min_systems;//64;
          } else if ($scope.virtual_schedule.mode === 5) {
            maxMin.max = $scope.max_systems;
          }
        } else {
          //centigrados
          maxMin = { min: 15, max: 30 };
          // Change minimum value if it's cold mode
          if ($scope.virtual_schedule.mode === 9) {
            maxMin.min = $scope.min_systems;//18;
          } else if ($scope.virtual_schedule.mode === 5) {
            maxMin.max = $scope.max_systems;
          }
        }

        if (consignaFinal >= maxMin.min && consignaFinal <= maxMin.max) {
          $scope.enableModified();
          $scope.virtual_schedule.temp = consignaFinal;
        }
      };

      /*
       * Calculate maximum limit of systems in heat mode
       */
      function maxSystems(is_farenheit) {
        if (is_farenheit) {
          var max = 86;
        } else {
          max = 30;
        }
        $rootScope.systems.forEach(function (system) {
          if (system.system_number !== "0" && Number(system.max_limit) < max) {
            max = system.max_limit
          }
        })

        $scope.max_systems = max;
      }

      /*
       * Calculate minimum limit of systems in cold mode
       */
      function minSystems(is_farenheit) {
        if (is_farenheit) {
          var min = 64;
        } else {
          min = 18;
        }
        $rootScope.systems.forEach(function (system) {
          // Set the limit if it isn't alhterma system and it has cold mode and it's more restrictive
          if (system.system_number !== "0" && Number(system.min_limit) < 27 && Number(system.min_limit) > min) {
            min = system.min_limit
          }
        })

        $scope.min_systems = min;
      }

      /*
       * Toggle all zones and systems in schedule
       */
      $scope.toggleAllSystems = function () {
        $scope.enableModified();
        $scope.virtual_schedule.all_systems = !$scope.virtual_schedule.all_systems;
        if ($scope.virtual_schedule.all_systems) {
          var enabled_zones_number = 0;
          $scope.virtual_schedule.zones_selected.forEach(function (object) {
            object.system_selected = true;
            object.zones_selected.forEach(function (obj) {
              obj.zone_selected = true;
              enabled_zones_number += 1;
            })
          })
          // if it's control vmc then count all systems like enabled
          if ($scope.virtual_schedule.VMC_state !== null) {
            $scope.virtual_schedule.enabled_systems_number = $scope.virtual_schedule.zones_selected.length;
          }
          $scope.virtual_schedule.enabled_zones_number = enabled_zones_number;
        } else {
          $scope.virtual_schedule.zones_selected.forEach(function (object) {
            object.system_selected = false;
            object.zones_selected.forEach(function (obj) {
              obj.zone_selected = false;
            })
          })
          // if it's control vmc then enable the first system
          if ($scope.show_vmc) {
            $scope.virtual_schedule.zones_selected[0].system_selected = true;
            $scope.virtual_schedule.enabled_systems_number = 1;
          } else {
            // else enable the first zone 
            $scope.virtual_schedule.zones_selected[0].zones_selected[0].zone_selected = true;
            $scope.virtual_schedule.enabled_zones_number = 1;
          }
        }
      };

      /*
       * Check last system selected to disable the switch
       */
      $scope.checkLastSystemSelected = function (system) {
        $scope.enableModified();
        system.system_selected = !system.system_selected;
        if (system.system_selected) {
          $scope.virtual_schedule.enabled_systems_number += 1;
          // Enable all zones
          system.zones_selected.forEach(function (zone) {
            if (!zone.zone_selected) {
              zone.zone_selected = true;
              $scope.virtual_schedule.enabled_zones_number += 1;
            }
          })
          checkEnableAllSystems();
        } else {
          $scope.virtual_schedule.all_systems = false;
          // if it isn't the last system enabled
          if (!$scope.show_vmc || $scope.virtual_schedule.enabled_systems_number >= 2) {
            $scope.virtual_schedule.enabled_systems_number -= 1;
          }
          // Disable all zones
          system.zones_selected.forEach(function (zone) {
            if (zone.zone_selected) {
              if ($scope.virtual_schedule.enabled_zones_number >= 2) {
                zone.zone_selected = false;
                $scope.virtual_schedule.enabled_zones_number -= 1;
              }
            }
          })
        }
      }

      /*
       * Check last zone selected for restrict the disable
       */
      $scope.checkLastZoneSelected = function (system, zone) {
        $scope.enableModified();
        if (zone.zone_selected) {
          // check if it's last zone to turn off the zone
          if ($scope.virtual_schedule.enabled_zones_number >= 2) {
            zone.zone_selected = false;
            $scope.virtual_schedule.enabled_zones_number -= 1;
            checkSystemSelected(system);
          }
        } else {
          zone.zone_selected = true;
          $scope.virtual_schedule.enabled_zones_number += 1;
          checkSystemSelected(system);
        }
      };

      /*
       * Check to enable the system button if all zones are enabled
       */
      function checkSystemSelected(sys) {
        var zone_unselected = false;
        sys.zones_selected.forEach(function (z) {
          if (!z.zone_selected) {
            zone_unselected = true;
          }
        })
        // Enable its system
        if (zone_unselected) {
          if (sys.system_selected) {
            $scope.virtual_schedule.enabled_systems_number -= 1;
          }
          sys.system_selected = false;
        } else {
          if (!sys.system_selected) {
            $scope.virtual_schedule.enabled_systems_number += 1;
          }
          sys.system_selected = true;
        }
        checkEnableAllSystems();
      }

      /*
       * Check to enable all systems button if last system is enabled
       */
      function checkEnableAllSystems() {
        var system_unselected = false;
        $scope.virtual_schedule.zones_selected.forEach(function (sys) {
          if (!sys.system_selected) {
            system_unselected = true;
          }
        })
        if (!system_unselected) {
          $scope.virtual_schedule.all_systems = true;
        } else {
          $scope.virtual_schedule.all_systems = false;
        }
      }

      /*
       * Toggle Schedule enable flag
       */
      $scope.toggleEnabled = function (sche) {
        if (sche.enabled === "1") {
          sche.enabled = "0";
        } else {
          sche.enabled = "1";
        }
        $scope.virtual_schedule = sche;
        if (!$scope.virtual_schedule.zones_selected) {
          var object = setZonesSelected(sche);
          $scope.virtual_schedule.zones_selected = object.zones_selected;
          $scope.virtual_schedule.all_systems = object.all_systems;
        }
        var schedule = checkScheduleData();

        eventsService.createEventModel("modprog2", schedule, 'exist', '1');
      }

      function setZonesEnabled() {
        angular.forEach($scope.virtual_schedule.zones_selected, function (system) {
          var array_zones_number = [];
          system.zones_selected.forEach(function (zone) {
            if (zone.zone_selected) {
              array_zones_number.push(zone.zone_number);
            }
          })
          system.zones_enabled = array_zones_number;
        });
      }

      /*
       * Check if the dates range is set in some schedule with the same zone selected
       */
      function isInvalidDatesRange() {
        var invalid = false;
        if ($rootScope.show_calendar_schedules) {
          setDates();
          $scope.calendar_schedules.forEach(function (schedule) {
            if (!invalid && $scope.virtual_schedule.schedule_number !== schedule.schedule_number) {
              // Check that the new range match with some of the ranges, or it's intro in some of the limits
              if (((schedule.start_date <= schedule.end_date) &&
                (
                  ($scope.virtual_schedule.start_date >= schedule.start_date && $scope.virtual_schedule.start_date <= schedule.end_date) ||
                  ($scope.virtual_schedule.end_date >= schedule.start_date && $scope.virtual_schedule.end_date <= schedule.end_date)
                )
              ) ||
                ((schedule.start_date > schedule.end_date) &&
                  !(
                    ($scope.virtual_schedule.start_date > schedule.end_date && $scope.virtual_schedule.start_date < schedule.start_date) &&
                    ($scope.virtual_schedule.end_date > schedule.end_date && $scope.virtual_schedule.end_date < schedule.start_date)
                  )
                )
              ) {
                setZonesEnabled();
                // Check if some zone match with some of the zones selected
                schedule.systems.forEach(function (obj) {
                  $scope.virtual_schedule.zones_selected.forEach(function (item) {
                    if (obj.system_number === item.system_number) {
                      if (obj.zones_enabled.some(function (x) { return item.zones_enabled.includes(x) })) {
                        invalid = true;
                      }
                    }
                  })
                })
              }
            }
          })
        } else {
          // de semana
          if ($scope.virtual_schedule && angular.equals($scope.virtual_schedule.days, ["0", "0", "0", "0", "0", "0", "0"])) {
            invalid = true;
          }

        }
        return invalid;
      }

      /*
       * Check schedule data before to save
       */
      function checkScheduleData() {
        if ($scope.virtual_schedule && angular.equals($scope.virtual_schedule.days, ["0", "0", "0", "0", "0", "0", "0"]))
          $scope.error_day = true;
        else
          $scope.error_day = false;

        if (!$scope.error_day && $scope.virtual_schedule) {
          // Convert array of days in hexadecimal
          if ($scope.virtual_schedule.days) {
            $scope.virtual_schedule.days = $scope.virtual_schedule.days.reverse();
            $scope.virtual_schedule.days = $scope.virtual_schedule.days.join("");
            $scope.virtual_schedule.days = binaryToHex($scope.virtual_schedule.days).result;
          }

          // Covert enabled value to number
          $scope.virtual_schedule.enabled = String(Number(($scope.virtual_schedule.enabled)));

          // Covert values according to the schedule type
          if ($scope.show_power) {
            $scope.virtual_schedule.mode = null;
            $scope.virtual_schedule.temp = null;
            $scope.virtual_schedule.VMC_state = null;
            $scope.virtual_schedule.state = String(Number(($scope.virtual_schedule.state)));
          } else if ($scope.show_temp) {
            $scope.virtual_schedule.state = null;
            $scope.virtual_schedule.VMC_state = null;
            $scope.virtual_schedule.temp = parseInt($scope.virtual_schedule.temp);
          } else if ($scope.show_vmc) {
            $scope.virtual_schedule.mode = null;
            $scope.virtual_schedule.temp = null;
            $scope.virtual_schedule.state = null;
            $scope.virtual_schedule.VMC_state = String(Number(($scope.virtual_schedule.VMC_state)));
          }

          // Convert array of zones selected in hexadecimal values
          $scope.virtual_schedule.systems = [];
          angular.forEach($scope.virtual_schedule.zones_selected, function (system) {
            var zones_selected = 0;
            system.zones_selected.forEach(function (zone) {
              if (zone.zone_selected) {
                zones_selected += 1 << (parseInt(zone.zone_number) - 1);
              }
            })
            $scope.virtual_schedule.systems.push({ system_number: system.system_number, zones_enabled: zones_selected.toString(16) });
          });

          // Copy to schedule into auxiliar object to delete some keys
          var virtual_schedule = angular.copy($scope.virtual_schedule);
          delete virtual_schedule.zones_selected;
          delete virtual_schedule.all_systems;
          delete virtual_schedule.enabled_zones_number;
          delete virtual_schedule.enabled_systems_number;

          return virtual_schedule;
        }
      }

      /*
       * Save Schedule
       */
      $scope.saveSchedule = function () {
        if (!$scope.new_schedule_modified && !$scope.edit_schedule_modified) {
          $scope.closeScheduleModal();
        } else {
          if (isInvalidDatesRange()) {
            if ($rootScope.show_calendar_schedules) {
              $scope.show_view = 'invalid_dates_range';
            } else {
              $scope.error_day = true;
              if (($scope.show_view === 'save_confirm' && $scope.new_schedule_modified) || ($scope.show_view === 'edit_confirm' && $scope.edit_schedule_modified)) {
                $scope.show_view = 'new';
              }
            }
          } else {
            var schedule = checkScheduleData();
            $scope.loader = false;

            if ($rootScope.demo) {
              $scope.new_schedule_modified = false;
              $scope.edit_schedule_modified = false;
              $scope.closeScheduleModal();
              $rootScope.update_new_schedules = true;
            } else {
              eventsService.createEventModel("modprog2", schedule, 'exist', '1').then(function () {
                if ($scope.new_schedule_modified) {
                  createEventInfoProg2();
                } else if ($scope.edit_schedule_modified) {
                  createEventInfoProg($scope.virtual_schedule.schedule_number);
                }
                $scope.new_schedule_modified = false;
                $scope.edit_schedule_modified = false;
                $scope.closeScheduleModal();
              }).catch(function (error) {
                console.log("Error saving schedules:", error);
              });
            }
          }
        }
      }

      /*
       * Binary to Hex.
       */
      function binaryToHex(s) {
        var i, k, part, accum, ret = '';
        for (i = s.length - 1; i >= 3; i -= 4) {
          // extract out in substrings of 4 and convert to hex
          part = s.substr(i + 1 - 4, 4);
          accum = 0;
          for (k = 0; k < 4; k += 1) {
            if (part[k] !== '0' && part[k] !== '1') {
              // invalid character
              return { valid: false };
            }
            // compute the length 4 substring
            accum = accum * 2 + parseInt(part[k], 10);
          }
          if (accum >= 10) {
            // 'A' to 'F'
            ret = String.fromCharCode(accum - 10 + 'A'.charCodeAt(0)) + ret;
          } else {
            // '0' to '9'
            ret = String(accum) + ret;
          }
        }
        // remaining characters, i = 0, 1, or 2
        if (i >= 0) {
          accum = 0;
          // convert from front
          for (k = 0; k <= i; k += 1) {
            if (s[k] !== '0' && s[k] !== '1') {
              return { valid: false };
            }
            accum = accum * 2 + parseInt(s[k], 10);
          }
          // 3 bits, value cannot exceed 2^3 - 1 = 7, just convert
          ret = String(accum) + ret;
        }
        return { valid: true, result: ret };
      }

      /*
       * Show the remove confirmation
       */
      $scope.showRemoveConfirmation = function () {
        $scope.show_view = 'remove_confirm';
      };

      /*
       * Show the edit view again
       */
      $scope.showEdit = function () {
        $scope.show_view = 'new';
      };

      /*
       * Remove schedule
       */
      $scope.removeSchedule = function () {
        eventsService.createEvent("modprog2", $scope.virtual_schedule, 'exist', '0').then(function () {
          $scope.calendar_schedules = null;
          $scope.edit_schedule_modified = false;
          initialize($scope.target);
        });
      }

      /*
       * Exit
       */
      $scope.exitModal = function () {
        $scope.exit ? $scope.close() : $scope.closeScheduleModal();
      }

      $scope.checkModifications = function (exit) {
        $scope.exit = exit;
        if ($scope.new_schedule_modified) {
          $scope.show_view = 'save_confirm';
        } else if ($scope.edit_schedule_modified) {
          $scope.show_view = 'edit_confirm';
        } else if (exit) {
          $scope.close();
        } else {
          $scope.closeScheduleModal();
        }
      }

      // Inside the controller
      $scope.isBoolean = function (value) {
        return typeof value === 'boolean';
      };

      /*
       * close
       */
      $scope.closeScheduleModal = function () {
        $scope.new_schedule_modified = false;
        $scope.edit_schedule_modified = false;
        if ($rootScope.show_calendar_schedules) {
          $scope.show_month_start_select = false;
          $scope.show_month_end_select = false;
          $scope.show_day_end_select = false;
          $scope.show_day_start_select = false;
          $scope.show_view = 'calendar_schedules';
        } else {
          $scope.show_view = 'server_schedules';
        }
      }

      /*
       * Called when close modal (click out)
       */
      $scope.close = function () {
        $scope.loader = false;
        $scope.schedules = null;
        $scope.calendar_schedules = null;
        $scope.weekday_schedules = null;
        $scope.target = null;
        $scope.show = false;
        $scope.show_view = 'list';
        $rootScope.modalShow = false;
        $rootScope.server_schedules_target = null;
        $rootScope.show_calendar_schedules = false;
        $scope.error_day = false;
        $scope.block_update = false;
        $scope.has_vmc_control = false;
        $scope.new_schedule_modified = false;
        $scope.edit_schedule_modified = false;
        $scope.show_month_start_select = false;
        $scope.show_month_end_select = false;
        $scope.show_day_end_select = false;
        $scope.show_day_start_select = false;
      };

      /*
       * Remove root scope watch
       */
      $scope.$on('$destroy', function () {
        $scope.listener1();
        $scope.listener2();
      });
    }]);
