Airzone.controller('completeRegistrationController', ['$scope', '$rootScope', '$http', '$stateParams',
  function ($scope, $rootScope, $http, $stateParams) {

    $scope.register_error = false;
    $scope.register_success = false;
    $scope.register = {};
    $scope.register.email = $stateParams.email;
    $scope.register.name = $stateParams.name;
    $scope.register.surname = $stateParams.surname;

    $scope.state = 'register'
    $scope.invalid = false;

    $scope.htmlString = $rootScope.i18n('terms.body');
    $http.get("/terms.json").success(function (response) {
      var terms = response[$rootScope.language];
      if (!terms) { terms = response.en; }
      $scope.terms = terms;
    });

    // Prevent bottom space when keyboard hides
    $scope.scrollToTop = function($event){
      window.scrollTo(0, 0);
    }
    
    /*
     * PUT information of user
     */
    $scope.submitRegisterForm = function () {
      if ($scope.registerForm.$valid) {
        var publicity = this.register.publicity ? this.register.publicity : false;
        $http({
          method: 'PUT',
          url: 'users/password',
          responseType: 'json',
          data: {
            user: {
              name: $scope.register.name,
              surname: $scope.register.surname,
              email: $stateParams.email,
              password: $scope.register.password,
              password_confirmation: $scope.register.password_confirmation,
              reset_password_token: $stateParams.reset_password_token,
              autoconfirmation: false,
              tos: true,
              publicity: publicity,
              language: $rootScope.language
            }
          }
        }).success(function (data, status, headers, config) {
          $scope.state = 'register_success';
        }).error(function (data, status, headers, config) {
          $scope.registerForm.invalid = true;
          $scope.register_error = true;
        });
      } else {
        $scope.registerForm.invalid = true;
      }
    };
  }]);
