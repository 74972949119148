Airzone.service( 'userService', [ '$http', '$q', '$rootScope', 'sessionService', '$state',
  function( $http, $q, $rootScope, sessionService, $state ) {
    return({
      getUsers: getUsersFromServer,
      getInstallationUsers: getInstallationUsers,
      updateUser: updateUserInServer
    })

    //
    // Public methods
    //

    /*
     * Get users list from the server
     */
    function getUsersFromServer( params, forceUpdate ){
      forceUpdate = typeof forceUpdate !== 'undefined' ? forceUpdate : false;

      // search for system of device in rootScope
      if( $rootScope && $rootScope.users && !forceUpdate){
        var users = $rootScope.users;

        if( users.length > 0 ) {
          return $q.when(users);
        }
      }

      var request = $http({
        method: "get",
        url: url_path + "users/",
        params: params
      });

      return( request.then( handleIndexSuccess, handleError ) );
    };

    /*
     * Get users from installation
     */
    function getInstallationUsers( installation_id ){
      var request = $http({
        method: "get",
        url: url_path + "users/",
        params: {
          installation_id: installation_id,
          user_email: sessionService.email(),
          user_token: sessionService.token(),
          format: 'json'
        }
      });

      return( request.then( handleIndexSuccess, handleError ) );
    };

    function handleIndexSuccess( response ) {
      return( response.data );
    };

    /*
     * Update the user in the database
     */
    function updateUserInServer(user_id, params){
      var request = $http({
        method: "PUT",
        url: url_path + "users/" + user_id,
        data: params
      });

      return( request.then( handleUpdateSuccess, handleErrorUpdate ) );
    };

     function handleUpdateSuccess( response ) {
    return( response.data );
  };

  function handleError( response ) {
    // Logout when unauthorized
    if( response.status != 401 ){
        sessionService.unauthorize();
    } else if( response.status == 403 ){
      $state.go('init.403');
      return( $q.reject( "An unknown error occurred." ) );
    } else if( response.status == 404 ){
      $state.go('init.404');
      return( $q.reject( "An unknown error occurred." ) );
    }

    if ( ! angular.isObject( response.data ) || ! response.data.message){
      return( $q.reject( "An unknown error occurred." ) );
    }

    return( $q.reject( response.data.message ) );
  };

  function handleErrorUpdate( response ) {
    return( $q.reject( response ) );
  };
} ] );
