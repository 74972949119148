Airzone.service('weatherService',
  ['$http', '$q', '$rootScope', 'sessionService',
    function ($http, $q, $rootScope, sessionService) {

      return ({
        getTimezone: getTimezone,
      });

      function getTimezone(location) {
        var url = 'https://api.worldweatheronline.com/premium/v1/tz.ashx?format=json&';

        url += 'q=' + location.latitude + ',' + location.longitude;
        url += '&key=eaf291cc04ff4b0baa2104500173003';

        var request = $http({
          method: "get",
          url: url,
          crossDomain: true,
          headers: {
            'X-Requested-With': undefined
          }
        });

        return (request.then(handleSuccess, handleError));
      };

      function handleSuccess(response) {
        return (response.data);
      };

      function handleError(response) {
        return ($q.reject(response.data.message));
      };
    }]);
