Airzone.controller('passwordController', ['$scope', '$http', '$state', '$stateParams', function ($scope, $http, $state, $stateParams) {
  // Flag to show or hide error messages
  $scope.password_error = false;
  $scope.password_confirmation_error = false;
  $scope.token_error = false;
  $scope.success_update = false;
  $scope.showPassword1 = false;
  $scope.inputType1 = 'password';
  $scope.showPassword2 = false;
  $scope.inputType2 = 'password';

  // Prevent bottom space when keyboard hides
  $scope.scrollToTop = function($event){
    window.scrollTo(0, 0);
  }

  /*
   * Hide & show password function
   */
  $scope.hideShowPassword = function (type) {
    if (type === 1) {
      if ($scope.inputType1 == 'password') {
        $scope.showPassword1 = true;
        $scope.inputType1 = 'text';
      } else {
        $scope.showPassword1 = false;
        $scope.inputType1 = 'password';
      }
    }else if (type === 2) {
      if ($scope.inputType2 == 'password') {
        $scope.showPassword2 = true;
        $scope.inputType2 = 'text';
      } else {
        $scope.showPassword2 = false;
        $scope.inputType2 = 'password';
      }
    }
  };

  /*
   * Init session with email and password
   */
  $scope.sendPassword = function () {
    $scope.password_error = false;
    $scope.password_confirmation_error = false;
    $scope.token_error = false;

    if ($scope.passwordForm.$valid) {
      $http({
        method: 'PUT',
        url: url_path + 'users/password',
        data: {
          user: {
            password: this.password,
            password_confirmation: this.password_confirmation,
            reset_password_token: $stateParams.reset_password_token
          }
        }
      }).success(function (data, status, headers, config) {
        $scope.success_update = true;
      }).error(function (data, status, headers, config) {
        if (data.errors.reset_password_token) {
          $scope.passwordForm.invalid = true;
          $scope.token_error = true;
        }
      });
    }
    else {
      $scope.passwordForm.invalid = true;
    }
  };
}]);
