Airzone.service('sessionService',
  ['$http', '$q', 'localStorageService', '$state', '$timeout', '$rootScope', 'DEMO_EMAIL',
    function ($http, $q, localStorageService, $state, $timeout, $rootScope, DEMO_EMAIL) {

      var user = null;

      //public API of the service
      return ({
        user: getUser,
        unauthorize: unauthorize,
        logout: logout,
        typeUser: typeUser,
        id: id,
        name: name,
        surname: surname,
        language: language,
        email: email,
        token: token,
        saveUserLocal: saveUserLocal,
        destroyUser: destroyUser,
        unit_format: getUnitFormat,
        role: role,
        checkUser: checkUser,
        checkSession: checkSession,
        requestUserData: requestUserData
      });

      //
      // Public methods
      //

      function checkUser() {
        return user;
      }

      function checkSession() {
        return (localStorageService.get('session') !== null)
      }

      /*
       * Get the role of the user
       */
      function typeUser(type) {
        if (user)
          return user.role == type;
        else
          return false;
      };

      /*
       * Return user id
       */
      function id() {
        var session = localStorageService.get('session');
        if (session)
          return session.id;
        else
          return null;
      };

      function role() {
        if (user) {
          return user.role;
        }
        return null;
      };

      /*
       * Return user email
       */
      function email() {
        var session = localStorageService.get('session');
        if (session)
          return session.email;
        else
          return null;
      };

      /*
       * Return user authentication_token
       */
      function token() {
        var session = localStorageService.get('session');
        if (session)
          return session.authentication_token;
        else
          return null;
      };

      /*
       * Return user language
       */
      function language() {
        if (user) {
          return user.language;
        }
        return null;
      }

      /*
       * Return user name
       */
      function name() {
        if (user) {
          return user.name;
        }
        return null;
      }

      /*
       * Return user surname
       */
      function surname() {
        if (user) {
          return user.surname;
        }
        return null;
      }

      function getUnitFormat() {
        if (user) {
          return user.unit_format;
        }
        return null;
      }


      function requestUserData() {
        var request = $http({
          method: "get",
          url: url_path + "users/" + id(),
          params: {
            user_email: email(),
            user_token: token()
          },
          data: {}
        });

        return request.then(function (data) {
          $rootScope.user = data.data.user;
          user = data.data.user;
          $rootScope.detectLanguage();
          user = data.data.user;
          return angular.copy(user);
        }, handleError);
      }

      /*
       * Get user information of the backend
       */
      function getUser() {
        // If it is stored return stored
        if (checkSession()) {
          if (user)
            return $q.when(angular.copy(user));
          else {
            return requestUserData();
          }
        } else {
          return $q.reject();
        }
      };

      // unauthorize process
      function unauthorize() {
        user = null;
        $state.go('init.login');
      };

      /*
       * Logout user from session in server
       */
      function logout() {
        var request = $http({
          method: "delete",
          url: url_path + "/users/sign_out",
          params: {
            user_email: email(),
            user_token: token()
          },
          data: {
          }
        });

        return (request.then(handleSuccess, handleError));
      };

      /*
       * save the user in local storage and in rootScope
       */
      function saveUserLocal(data) {
        user = data.user;
        var sessionData = {
          authentication_token: data.user.authentication_token,
          email: data.user.email,
          id: data.user.id
        };
        localStorageService.set('session', sessionData);
        $rootScope.user = data['user'];
      };

      /*
       * Remove the user from the backend
       */
      function destroyUser() {
        var request = $http({
          method: "delete",
          url: url_path + "/users/" + id(),
          params: {
            user_email: email(),
            user_token: token()
          },
          data: {}
        });

        return (request.then(handleSuccess, handleError));

      }

      //
      // Private methods
      //

      // I transform the error response, unwrapping the application dta from
      // the API response payload.
      function handleError(response) {
        // Logout when unauthorized
        if (response.status == 401) {
          unauthorize();
        } else if (response.status == 403) {
          $state.go('init.403');
          return ($q.reject("An unknown error occurred."));
        } else if (response.status == 404) {
          $state.go('init.404');
          return ($q.reject("An unknown error occurred."));
        }

        // The API response from the server should be returned in a
        // nomralized format. However, if the request was not handled by the
        // server (or what not handles properly - ex. server error), then we
        // may have to normalize it on our end, as best we can.
        if (!angular.isObject(response.data) || !response.data.message) {
          return ($q.reject("An unknown error occurred."));
        }

        // Otherwise, use expected error message.
        return ($q.reject(response.data.message));
      };

      // I transform the successful response, unwrapping the application data
      // from the API response payload.
      function handleSuccess(response) {
        if (response.config.params.user_email === DEMO_EMAIL){
          localStorageService.set('session', '');
        }
        return (response.data);
      };

    }]);
