Airzone.service( 'statsService',
  [ '$http', '$q', 'sessionService', '$state', 'STATS_URL',
  function( $http, $q, sessionService, $state, STATS_URL ) {

    return({
      getDeviceConnectionLast: getDeviceConnectionLast,
      getDeviceConnectionStats: getDeviceConnectionStats,
      getDeviceConnectionLogs: getDeviceConnectionLogs,
      getDeviceSignalLast: getDeviceSignalLast,
      getDeviceSignalLogs: getDeviceSignalLogs
    });

    //
    // Public methods
    //

    /**
     * Get device current connections
     */
    function getDeviceConnectionLast( mac ) {
      var mac_formatted = mac.replace(/:/g, "");

      var request = $http({
        method: "get",
        url: STATS_URL + "last?mac=" + mac_formatted,
        params: {
          user_email: sessionService.email(),
          user_token: sessionService.token(),
          format: 'json'
        }
      });

      return( request.then( handleIndexSuccess, handleError ) );
    }

    /**
     * Get device connections
     */
    function getDeviceConnectionStats( mac, startDate, finishDate ) {
      var mac_formatted = mac.replace(/:/g, "");

      var request = $http({
        method: "get",
        url: STATS_URL + "stats?mac=" + mac_formatted + "&startDate=" + startDate + "&finishDate=" + finishDate,
        params: {
          user_email: sessionService.email(),
          user_token: sessionService.token(),
          format: 'json'
        }
      });

      return( request.then( handleIndexSuccess, handleError ) );
    }

    /**
     * Get device connections
     */
    function getDeviceConnectionLogs( mac, time_type, startDate, finishDate ) {
      var type = time_type !== null ? time_type: false;
      var mac_formatted = mac.replace(/:/g, "");

      var request = $http({
        method: "get",
        url: STATS_URL + "logs?mac=" + mac_formatted + "&precision=" + type + "&startDate=" + startDate + "&finishDate=" + finishDate,
        params: {
          user_email: sessionService.email(),
          user_token: sessionService.token(),
          format: 'json'
        }
      });

      return( request.then( handleIndexSuccess, handleError ) );
    }

    /**
     * Get device current connections
     */
    function getDeviceSignalLast( mac ) {
      var mac_formatted = mac.replace(/:/g, "");

      var request = $http({
        method: "get",
        url: STATS_URL + "open/last?mac=" + mac_formatted,
        params: {
          user_email: sessionService.email(),
          user_token: sessionService.token(),
          format: 'json'
        }
      });

      return( request.then( handleIndexSuccess, handleError ) );
    }

    /**
     * Get device connections
     */
    function getDeviceSignalLogs( mac, time_type, startDate, finishDate ) {
      var type = time_type !== null ? time_type: false;
      var mac_formatted = mac.replace(/:/g, "");

      var request = $http({
        method: "get",
        url: STATS_URL + "open/logs?mac=" + mac_formatted + "&precision=" + type + "&startDate=" + startDate + "&finishDate=" + finishDate,
        params: {
          user_email: sessionService.email(),
          user_token: sessionService.token(),
          format: 'json'
        }
      });

      return( request.then( handleIndexSuccess, handleError ) );
    }

    function handleIndexSuccess( response ) {
      return( response.data );
    }

    function handleError( response ) {
      // Logout when unauthorized
      if( response.status == 403 || response.status == 404){
        console.error("Error:", response.body);
        return false;
      }

      if ( ! angular.isObject( response.data ) || ! response.data.message){
        console.log("An unknown error occurred: not data response" );
        return false;
      }

      return false;
    }

}]);
