Airzone.controller('homeController',
  ['$scope', '$rootScope', '$state', 'sessionService', 'websocketsService', 'zoneService', 'systemService', 'deviceService', 'MOBILE_APP',
    function ($scope, $rootScope, $state, sessionService, websocketsService, zoneService, systemService, deviceService, MOBILE_APP) {

      /*
        - Variable que muestra/oculta el menu en los dispositivos móviles
        - Como el cambio de estado es antes que cualquier acción que pueda añadir al
          enlace, utilizo $stateChangeStart para cerrar el menu cada vez que se
          cambie de estado == pulsar un botón del menu
      */
      $rootScope.menuTrigger = false;
      $rootScope.inside = true;

      // Detect iPhone X for custom design
      $scope.device_phone_x = false;
      if (MOBILE_APP) {
        $scope.info_hide = true;
        // eslint-disable-next-line no-undef
        if (device && device.model.indexOf('iPhone10,3') >= 0 || device.model.indexOf('iPhone10,6') >= 0 || device.model.indexOf('iPhone11,8') >= 0 || device.model.indexOf('iPhone11,2') >= 0 || device.model.indexOf('iPhone11,6') >= 0 || device.model.indexOf('iPhone11,4') >= 0
          // eslint-disable-next-line no-undef
          || device.model.indexOf('iPhone12,1') >= 0 || device.model.indexOf('iPhone12,3') >= 0 || device.model.indexOf('iPhone12,5') >= 0 || device.model.indexOf('iPhone12,8') >= 0) {
          $scope.device_iphone_x = true;
        }
      }

      if (sessionService.typeUser('demo')) {
        $rootScope.demo = true;
      }

      initialize();
      moment.locale($rootScope.language);
      if (sessionService.typeUser('demo'))
        $rootScope.demo = true;

      function initialize() {
        if ($state.current.name == 'home.zone')
          $rootScope.showMenuBtn = false;
        else
          $rootScope.showMenuBtn = true;
      }

      /*
       * View electricity prices info
       */
      $scope.show_electricity_prices_info = function () {
        $rootScope.show_climate_forecast = false;
        $rootScope.show_electricity_prices = true;
        $rootScope.show_climate = true;
      };

      /*
       * View climate forecast info
       */
      $scope.show_climate_forecast_info = function () {
        $rootScope.show_electricity_prices = false;
        $rootScope.show_climate_forecast = true;
        $rootScope.show_climate = !$rootScope.show_climate;
      };

      /*
       * Logout from session
       */
      $scope.logout = function () {
        sessionService.logout().then(sessionService.unauthorize(), sessionService.unauthorize());
        websocketsService.destroy();
        $rootScope.demo = false;
        zoneService.destroy();
        systemService.destroy();
        deviceService.destroy();
        $rootScope.inside = false;
      };

      /*
       * Get type of menu
       */
      $scope.typeOfUser = function (type) {
        return sessionService.typeUser(type);
      };

      $scope.toggleEditServers = function () {
        $rootScope.editServer = !$rootScope.editServer;
      };
    }]);
