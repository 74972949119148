Airzone.controller('modalSystemErrorsController',
  ['$scope', '$rootScope',
    function ($scope, $rootScope) {

      /*
       * Translate strint 't'
       * Solved bug (can't call $rootScope directly)
       */
      $scope.i18n = function (t) {
        return $rootScope.i18n(t);
      };

      /*
       * Open modal ZBS schedules
       */
      $scope.listener1 = $rootScope.$watch('system_errors_target', function (new_target) {
        if (new_target) {
          $scope.show = true;
          $rootScope.modalShow = true;
          $scope.more_errors = false;
          console.log(new_target);
          if (new_target.system_errors) {
            $scope.errors_array = new_target.system_errors.split('');
            // Check if there is more of one error
            if (new_target.system_errors.split('1').length - 1 > 1){
              $scope.more_errors = true;
            }
            if (new_target.machine_error_code) {
              $scope.machine_error_code = new_target.machine_error_code;
            }
          } else if (new_target.without_consumption_data) {
            $scope.without_consumption_data = true;
          }

          if (new_target.purifier_warning){
            $scope.purifier_warning = new_target.purifier_warning;
          }

          if (new_target.errorV01){
            $scope.errorV01 = new_target.errorV01;
          }
        }
      });

      /*
       * Called when close modal (click out)
       */
      $scope.close = function () {
        $scope.show = false;
        $rootScope.modalShow = false;
        $scope.errors_array = null;
        $scope.without_consumption_data = null;
        $rootScope.system_errors_target = null;
      }

      /*
       * Remove root scope watch
       */
      $scope.$on('$destroy', function () {
        $scope.listener1();
      });
    }]);
