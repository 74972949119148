Airzone.service('programService', ['$http', '$q', '$rootScope', 'sessionService', '$state',
  function ($http, $q, $rootScope, sessionService, $state) {
    return ({
      getPrograms: getProgramsFromServer
    });

    /*
     * Get programs from server or rootScope
     *
     * @param boolean forceUpdate - Force ajax request and not return $rootScope result
     */
    function getProgramsFromServer(device_id) {
      // define the url to get schedules by system or zone_id
      var request = $http({
        method: "get",
        url: url_path + "programs/",
        params: {
          user_email: sessionService.email(),
          user_token: sessionService.token(),
          format: 'json',
          device_id: device_id
        }
      });

      return (request.then(handleIndexSuccess, handleError));
    }

    function handleIndexSuccess(response) {
      if (!$rootScope.schedules) {
        $rootScope.schedules = [];
      }

      $rootScope.schedules.push.apply($rootScope.schedules, response.data['programs']);
      return (response.data['programs']);
    }

    function handleError(response) {
      // Logout when unauthorized
      if (response.status == 401) {
        sessionService.unauthorize();
      } else if (response.status == 403) {
        $state.go('init.403');
        return ($q.reject("An unknown error occurred."));
      } else if (response.status == 404) {
        $state.go('init.404');
        return ($q.reject("An unknown error occurred."));
      }

      if (!angular.isObject(response.data) || !response.data.message) {
        return ($q.reject("An unknown error occurred."));
      }

      return ($q.reject(response.data.message));
    }
  }]);
