Airzone.controller('modalConsumptionController', ['$scope', '$rootScope', '$timeout', 'systemService', 'MOBILE_APP',
  function ($scope, $rootScope, $timeout, systemService, MOBILE_APP) {


    initializeChartModal();

    /*
     * Initialize the initial parameters of graphic 
     */
    function initializeChartModal() {
      $scope.show_consumption_chart = false;
      $scope.without_consumptions_data = false;
      $scope.show_filters_chart = true;
      $scope.date_type = 'day';
      $scope.query_range = false;
      closeToggleDate();
      // Date object from calendar
      $scope.min_date = moment().subtract(2, 'years');
      $scope.max_date = moment().add(1, "days");
      $scope.date_begin = moment();
      $scope.date_end = moment();
      // Parameter passed to api query
      $scope.begin = (moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS')) + 'Z'
      $scope.array_months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      var year = parseInt(($scope.date_begin).format('YYYY'));
      $scope.array_years = [year - 2, year - 1, year];
      $scope.month_selected_begin = parseInt(($scope.date_begin).format('MM'));
      $scope.month_selected_end = parseInt(($scope.date_begin).format('MM'));
    }

    /*
     * Set system scene icon
     */
    $scope.listener1 = $rootScope.$watch('consumption_target', function (new_val, old_val, scope) {
      if (new_val) {
        // Detect iOS platform for custom design
        $scope.device_platform_ios = false;

        if (MOBILE_APP) {
          if (device && device.platform === 'iOS') {
            $scope.device_platform_ios = true;
          }
        }

        // Detect safari browser
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') != -1) {
          if (ua.indexOf('chrome') > -1) {
            console.log("chrome") // Chrome
          } else {
            $scope.safari_browser = true; // Safari
          }
        }
        // set the system to requests 
        $scope.sys = $rootScope.consumption_target;
        // call to chart of today
        $scope.setDateType('day');
      }
    });

    $scope.i18n = function (t) {
      return $rootScope.i18n(t);
    };

    /*
     * Refresh graphic  
     */
    function refreshGraphic() {
      closeToggleDate();
      $scope.loader = true;
      $timeout(function () {
        $scope.setChartType();
        $scope.loader = false;
      }, 200);
    }

    /*
     * Swicth the graphics type
     */
    $scope.switchChartType = function () {
      $scope.show_filters_chart = !$scope.show_filters_chart;
      if (!$scope.show_filters_chart) {
        $scope.query_range = false;
      }
      setDateDefault();
      refreshGraphic();
    };

    /*
     * Swicth the query by range the datetimes or select one query of datetime with ranges  
     */
    $scope.toggleQueryRange = function () {
      $scope.query_range = !$scope.query_range;
      setDateDefault();
      refreshGraphic();
    }

    /*
     * Swicth the query by days, months, or year
     */
    $scope.setDateType = function (type) {
      $scope.date_type = type;
      setDateDefault();
      refreshGraphic();
    };

    /*
     * Set the default dates 
     */
    function setDateDefault() {
      if ($scope.query_range || !$scope.show_filters_chart) {
        // Select date from one day ago
        if ($scope.date_type === 'day') {
          $scope.begin = (moment().subtract(1, 'days')).startOf('day');
          $scope.end = (moment().add(1, 'days')).startOf('day');
          $scope.date_begin = $scope.begin;
          $scope.date_end = moment();
        } else {
          // Select date from one month ago or one year ago
          if ($scope.date_type === 'month') {
            $scope.begin = (moment().subtract(1, 'months')).startOf('month');
            $scope.end = moment().endOf('month');
            $scope.month_selected_begin = parseInt(($scope.begin).format('MM'));
            $scope.month_selected_end = parseInt(($scope.end).format('MM'));
            $scope.selected_month_begin = $scope.month_selected_begin;
            $scope.selected_month_end = $scope.month_selected_end;
          } else if ($scope.date_type === 'year') {
            $scope.begin = (moment().subtract(1, 'years')).startOf('year');
            $scope.end = moment().endOf('year');
            $scope.date_begin = $scope.begin;
            $scope.date_end = $scope.end;
          }
          $scope.year_selected_begin = ($scope.begin).format("YYYY");
          $scope.year_selected_end = ($scope.end).format("YYYY");
          $scope.selected_year_begin = $scope.year_selected_begin;
          $scope.selected_year_end = $scope.year_selected_end;
        }
      } else {
        // Select date today
        if ($scope.date_type === 'day') {
          $scope.begin = moment().startOf('day');
          $scope.date_begin = $scope.begin;
          $scope.end = moment().endOf('day');
        } else {
          // Select date this month or this year
          if ($scope.date_type === 'month') {
            $scope.month_selected_begin = moment().format('MM');
            $scope.selected_month_begin = $scope.month_selected_begin;
            $scope.month_selected_end = moment().add(1, 'month').format('MM');
            $scope.selected_month_end = $scope.month_selected_end;
          }
          $scope.year_selected_begin = moment().format("YYYY");
          $scope.selected_year_begin = $scope.year_selected_begin;
          $scope.year_selected_end = $scope.year_selected_begin;
          $scope.begin = moment().startOf($scope.date_type);
          $scope.end = moment().endOf($scope.date_type);
          $scope.date_begin = $scope.begin;
          $scope.date_end = $scope.end;
        }
      }
      setDateFormatApi();
    };

    /*
     *  Set dates automatically and correctly for api request to backend
     */
    $scope.setDates = function (date_begin, date_end) {
      if ($scope.date_type === 'day') {
        // If it's range of days or comparison
        if ($scope.query_range || !$scope.show_filters_chart) {
          var aux_end = moment(date_end._d).add(1, 'days');
          $scope.end = aux_end.startOf('day');
        } else {
          var aux_begin = moment(date_begin._d).add(1, 'days');
          $scope.date_end = aux_begin.startOf('day');
          $scope.end = $scope.date_end;
        }
        $scope.date_begin = date_begin;
        $scope.begin = date_begin;
      }
      setDateFormatApi();
      refreshGraphic();
    };

    /*
     * Change the moment object to the datetime api format
     */
    function setDateFormatApi() {
      // Choose the datetime api format
      $scope.begin = (moment.utc($scope.begin).format('YYYY-MM-DDTHH:mm:ss.SSS')) + 'Z';
      $scope.end = (moment.utc($scope.end).format('YYYY-MM-DDTHH:mm:ss.SSS')) + 'Z';
    }

    /*
     * Set maximum and minimum dates in the timestamp format
     */
    function momentToMaxMin(start, end) {
      var date_utc_start = moment(start._d).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      $scope.min = moment(date_utc_start).valueOf();
      var date_utc_end = moment(end._d).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      $scope.max = moment(date_utc_end).valueOf();
    }

    /*
     * Set maximum and minimum dates in the chart
     */
    function setMaxMinDates() {
      // Filter one day
      if ($scope.date_type === 'day' && !$scope.query_range && $scope.show_filters_chart) {
        var time_start_moment = moment($scope.date_begin);
        var time_end_moment = moment($scope.date_begin).endOf('day').subtract(1, 'hour');
        // Filter one month or one year 
      } else if ($scope.date_type !== 'day' && !$scope.query_range && $scope.show_filters_chart) {
        time_start_moment = moment($scope.date_begin);
        time_end_moment = moment($scope.date_end).subtract(1, 'hour');
        // Range of two days 
      } else if ($scope.date_type === 'day' && $scope.query_range) {
        time_start_moment = moment($scope.date_begin);
        time_end_moment = moment($scope.end).subtract(1, 'hour');
        // Range of two months 
      } else if ($scope.date_type === 'month' && $scope.query_range) {
        time_start_moment = moment($scope.begin);
        time_end_moment = moment($scope.end);
        // Range two years
      } else if ($scope.date_type === 'year' && $scope.query_range) {
        time_start_moment = moment($scope.date_begin);
        time_end_moment = moment($scope.date_end).startOf('month');
        // Compare two days
      } else if ($scope.date_type === 'day' && !$scope.show_filters_chart) {
        time_start_moment = moment($scope.date_end).startOf('day');
        time_end_moment = moment($scope.date_end).endOf('day').subtract(1, 'hour');
        // Compare two months
      } else if ($scope.date_type === 'month' && !$scope.show_filters_chart) {
        if (moment($scope.begin).daysInMonth() > moment($scope.end).daysInMonth()) {
          time_start_moment = moment($scope.begin);
          time_end_moment = moment($scope.begin).endOf('month');
        } else {
          time_start_moment = moment($scope.end).startOf('month');
          time_end_moment = moment($scope.end);
        }
        // Compare two years
      } else if ($scope.date_type === 'year' && !$scope.show_filters_chart) {
        time_start_moment = moment($scope.end).startOf('year');
        time_end_moment = moment($scope.end).startOf('month');
      }
      momentToMaxMin(time_start_moment, time_end_moment);
    }

    /*
     * Set the type of chart to show
     */
    $scope.setChartType = function () {
      // Remove data table 
      var tableDiv = document.querySelector('.highcharts-data-table');
      if (tableDiv) {
        tableDiv.remove();
      }
      setMaxMinDates();
      if ($scope.show_filters_chart) {
        if ($scope.query_range) {
          showRangeConsumptionChart($scope.begin, $scope.end);
        } else {
          if ($scope.date_type !== 'day') {
            showRangeConsumptionChart($scope.begin, $scope.end);
          } else {
            showConsumptionChart($scope.begin, $scope.end);
          }
        }
      } else {
        if ($scope.date_type !== 'day') {
          showMonthsYearsCompareConsumptionChart($scope.begin, $scope.end);
        } else {
          showCompareConsumptionChart($scope.begin, $scope.end);
        }
      }
    };

    /*
     * Set the values empty 
     */
    function setValuesEmpty() {
      $scope.without_consumptions_data = true;
      $scope.maximum = "";
      $scope.minimum = "";
      $scope.mode_chart = "";
    };

    /*
     * Show Consumption Chart comparing years
     */
    function showMonthsYearsCompareConsumptionChart(date_begin, date_end) {
      if (date_end) {
        $scope.compare_values = [];
        $scope.without_consumptions_data = false;
        $scope.date_begin_year = (moment(date_begin).startOf($scope.date_type))._d;
        $scope.date_end_year = (moment(date_begin).endOf($scope.date_type))._d;
        $scope.last_date_begin_year = (moment(date_end).startOf($scope.date_type))._d;
        $scope.last_date_end_year = (moment(date_end).endOf($scope.date_type))._d;

        systemService.getRangeSystemConsumptions($scope.sys.device_id, $scope.sys.system_number, $scope.date_begin_year, $scope.date_end_year, $scope.date_type).then(function (data) {
          if (data && data["data"]) {
            $scope.compare_values.push(data["data"]);
            systemService.getRangeSystemConsumptions($scope.sys.device_id, $scope.sys.system_number, $scope.last_date_begin_year, $scope.last_date_end_year, $scope.date_type).then(function (data) {
              $scope.show_consumption_chart = true;
              if (data && data["data"]) {
                $scope.compare_values.push(data["data"]);
                if ($scope.compare_values[0].length > 0 || $scope.compare_values[1].length > 0) {
                  maxMinModeRangeDays(($scope.compare_values[0]).concat($scope.compare_values[1]));
                  $scope.date_begin = moment($scope.last_date_begin_year);
                  $scope.date_end = moment($scope.date_end_year);
                  initConsumptionsChartCompare($scope.date_begin_year, $scope.last_date_end_year);
                } else {
                  setValuesEmpty();
                }
              }
            }, function (error) {
              console.log("Error second getRangeSystemConsumptions: ", error);
            });
          } else {
            $scope.without_consumptions_data = true;
          }
        }, function (error) {
          console.log("Error first getCompareSystemConsumptions: ", error);
        });
      }
    }

    /*
     * Show Consumption Chart comparing days
     */
    function showCompareConsumptionChart(date_begin, date_end) {
      if (date_end) {
        $scope.compare_values = [];
        $scope.without_consumptions_data = false;
        var date_begin_until = moment(date_begin).add(1, 'days').format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        var date_end_from = moment.utc(date_end).subtract(1, 'day').format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        systemService.getSystemConsumptions($scope.sys.device_id, $scope.sys.system_number, date_begin, date_begin_until, $scope.date_type).then(function (data) {
          if (data && data["data"] && data["data"].length > 0) {
            $scope.compare_values.push(data["data"]);
          } else {
            $scope.compare_values.push([]);
          }
          systemService.getSystemConsumptions($scope.sys.device_id, $scope.sys.system_number, date_end_from, date_end, $scope.date_type).then(function (data) {
            $scope.show_consumption_chart = true;
            if (data && data["data"] && data["data"].length > 0 || $scope.compare_values.length > 0) {
              if (data && data["data"] && data["data"].length > 0) {
                $scope.compare_values.push(data["data"]);
              } else {
                $scope.compare_values.push([]);
              }
              maxMinModeCompare();
              initConsumptionsChartCompare(date_begin, date_end);
            } else {
              setValuesEmpty();
            }
          }, function (error) {
            console.log("Error second getRangeSystemConsumptions: ", error);
          });
        }, function (error) {
          console.log("Error first getCompareSystemConsumptions: ", error);
        });
      }
    };

    /*
     * Show Consumption Chart by range
     */
    function showRangeConsumptionChart(date_begin, date_end) {
      if (date_end) {
        $scope.without_consumptions_data = false;
        systemService.getRangeSystemConsumptions($scope.sys.device_id, $scope.sys.system_number, date_begin, date_end, $scope.date_type).then(function (data) {
          $scope.show_consumption_chart = true;
          if (data && data["data"] && data["data"].length > 0) {
            maxMinModeRangeDays(data["data"]);
            initConsumptionsChart(data["data"]);
          } else {
            setValuesEmpty();
          }
        }, function (error) {
          console.log("Error getRangeSystemConsumptions: ", error);
        });
      }
    };

    /*
     * Show Consumption Chart
     */
    function showConsumptionChart(date_begin, date_end) {
      $scope.without_consumptions_data = false;
      systemService.getSystemConsumptions($scope.sys.device_id, $scope.sys.system_number, date_begin, date_end, $scope.date_type).then(function (data) {
        $scope.show_consumption_chart = true;
        if (data && data["data"] && data["data"].length > 0) {
          maxMinModeRangeDays(data["data"]);
          initConsumptionsChart(data["data"]);
        } else if (data && data["data"] && data["data"].length === 0) {
          setValuesEmpty();
        } else {
          var object = { "without_consumption_data": true };
          $rootScope.system_errors_target = object;
        }
      }, function (error) {
        console.log("Error getSystemConsumptions: ", error);
        var object = { "without_consumption_data": true };
        $rootScope.system_errors_target = object;
      });

    }

    /*
     * Calculate mode of one array of values
     */
    function simpleModeRangeDays(arr) {
      var frequency = {};  // array of frequency.
      var max = 0;  // holds the max frequency.
      var result;   // holds the max frequency element.
      for (var v in arr) {
        frequency[arr[v]] = (frequency[arr[v]] || 0) + 1; // increment frequency.
        var value = typeof result === 'object' ? arr[v].value : arr[v];
        if (frequency[arr[v]] > max && frequency[arr[v]] != 0 && value != 0 && value != -1) { // is this frequency > max so far ?
          max = frequency[arr[v]];  // update max.
          result = arr[v];          // update result.
        }
      }
      if (typeof result === 'object') {

        return result.value + " kWh ";
      } else if (result !== undefined) {
        return parseFloat(result).toFixed(1) + " kWh";
      } else {
        return "";
      }
    }

    /*
     * Remove invalid values of array
     */
    function removeInvalidValuesArray(array) {
      var myarray = array;
      var indicesToRemove = new Array();

      for (var i = 0; i < myarray.length; i++) {
        if (myarray[i] === -1) { //let's say u wud like to remove all -1
          indicesToRemove.push(i); //getting the indices and pushing it in a new array
        }
      }

      for (var j = indicesToRemove.length - 1; j >= 0; j--) {
        myarray.splice(indicesToRemove[j], 1);
      }

      return myarray;
    }

    /*
     * Set the date in maximum and minimum of arrayconnections
     */
    function setMaxMin(array, value, index, is_max) {
      if (index >= 0) {
        var full_date = array[index].date;
        if ($scope.date_type === 'day') {
          var date_max = moment(full_date).subtract(1, 'hour').format('HH:mm');
        } else if ($scope.date_type === 'month') {
          date_max = moment(full_date).format('DD/MM/YYYY');
        } else if ($scope.date_type === 'year') {
          date_max = moment(full_date).format('MM/YYYY');
        }
        if (is_max) {
          $scope.maximum = date_max + " - " + value + " kWh";
        } else {
          $scope.minimum = date_max + " - " + value + " kWh";
        }
      }
    }

    /*
     * Calculate maximum, minimum and mode of object
     */
    function maxMinModeRangeDays(values) {
      $scope.maximum = "";
      $scope.minimum = "";
      var array_values = values.map(function (object) { return object.value });
      array_values = removeInvalidValuesArray(array_values);
      var maximum_value = Math.max.apply(null, array_values.filter(Boolean));
      var maximum_index = array_values.indexOf(maximum_value);
      maximum_value = parseFloat(maximum_value).toFixed(1);
      $scope.maximum_value = maximum_value;
      $scope.maximum_index = maximum_index;
      setMaxMin(values, maximum_value, maximum_index, true);
      var minimum_value = Math.min.apply(null, array_values.filter(Boolean));
      var minimum_index = array_values.indexOf(minimum_value);
      minimum_value = parseFloat(minimum_value).toFixed(1);
      $scope.minimum_value = minimum_value;
      $scope.minimum_index = minimum_index;
      setMaxMin(values, minimum_value, minimum_index, false);
      $scope.mode_chart = simpleModeRangeDays(array_values);
    }

    /*
     * Calculate maximum, minimum and mode of two arrays of values array
     */
    function maxMinModeCompare() {
      var array_one = [];
      var array_two = [];
      $scope.maximum_value = "";
      $scope.minimum_value = "";
      if ($scope.compare_values[0]) {
        array_one = $scope.compare_values[0].filter(function (x, i) { if (x < 0) $scope.compare_values[0][i] = 0 })
        maxMinModeRangeDays(array_one);
        var maximum_value = parseFloat($scope.maximum_value);
        var maximum_index = $scope.maximum_index;
        var minimum_value = parseFloat($scope.minimum_value);
        var minimum_index = $scope.minimum_index;
      } else {
        maximum_value = 0;
        minimum_value = 10000;
      }
      if ($scope.compare_values[1]) {
        array_two = $scope.compare_values[1].filter(function (x, i) { if (x < 0) $scope.compare_values[1][i] = 0 })
        maxMinModeRangeDays(array_two);
      } else {
        $scope.maximum_value = 0;
        $scope.minimum_value = 10000;
      }
      if ($scope.maximum_value && maximum_value) {
        if (parseFloat($scope.maximum_value) > maximum_value) {
          $scope.maximum = ("0" + $scope.maximum_index).slice(-2) + ":00 - " + $scope.maximum_value + " kWh";
        } else {
          $scope.maximum = ("0" + maximum_index).slice(-2) + ":00 - " + maximum_value + " kWh";
        }
      }
      if ($scope.minimum_value && minimum_value) {
        if (parseFloat($scope.minimum_value) < minimum_value) {
          $scope.minimum = ("0" + $scope.minimum_index).slice(-2) + ":00 - " + $scope.minimum_value + " kWh";
        } else {
          $scope.minimum = ("0" + minimum_index).slice(-2) + ":00 - " + minimum_value + " kWh";
        }
      }
      var simple_mode = simpleModeRangeDays((array_one).concat(array_two));
      if (simple_mode) {
        $scope.mode_chart = simple_mode.value + " kWh";
      }
    }

    /*
     * Initialize the graphic to compare of consumptions between days
     */
    function initConsumptionsChartCompare(date_begin, date_end) {
      var itemArray = [];
      $scope.mydata = [];

      if ($scope.date_type === 'year') {
        var date_chart = moment(date_end);
      } else if ($scope.date_type === 'month') {
        if (moment(date_begin).daysInMonth() > moment(date_end).daysInMonth()) {
          date_chart = moment(date_begin);
        } else {
          date_chart = moment(date_end);
        }
      } else {
        date_chart = moment($scope.date_end);
      }

      for (var k = 0; k < $scope.compare_values.length; k++) {
        var j = 0;
        if (k === 0) {
          var color = "#56722a";
          if ($scope.date_type === 'day') {
            var name = moment(date_begin).format('DD/MM/YYYY');
          } else if ($scope.date_type === 'month') {
            name = moment(date_begin).format('MM/YYYY');
          } else if ($scope.date_type === 'year') {
            name = moment(date_begin).format('YYYY');
          }
        } else if (k === 1) {
          color = "#1d9cbd";
          if ($scope.date_type === 'day') {
            name = moment.utc(date_end).format('DD/MM/YYYY');
          } else if ($scope.date_type === 'month') {
            name = moment.utc(date_end).format('MM/YYYY');
          } else if ($scope.date_type === 'year') {
            name = moment.utc(date_end).format('YYYY');
          }
        }
        var dataArray = [];
        for (var i = 0; i < $scope.compare_values[k].length; i++) {
          var date = moment($scope.compare_values[k][i].date).subtract(1, 'hour');
          if ($scope.date_type === 'year') {
            var custom_date = date.set('year', date_chart.year());
          } else if ($scope.date_type === 'month') {
            custom_date = date.set({ month: date_chart.month(), year: date_chart.year() });
          } else {
            custom_date = date.set({ date: date_chart.date(), month: date_chart.month(), year: date_chart.year() });
          }
          var date_utc = moment(custom_date._d).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
          itemArray.push(moment(date_utc).valueOf());
          itemArray.push(Number(($scope.compare_values[k][i].value).toFixed(1)));
          dataArray.splice(j, 0, itemArray);
          itemArray = [];
          j++;
        }
        $scope.mydata.push({ "color": color, "name": name, "data": dataArray });
      }
    }

    /*
     * Initialize the graphic of consumptions in one day
     */
    function initConsumptionsChart(consumptions_data) {
      var j = 0;
      var itemArray = [];
      var dataArray = [];
      $scope.mydata = [];

      var color = "#56722a";
      if ($scope.date_type === 'day') {
        var name = moment($scope.begin).format('DD/MM/YYYY');
      } else if ($scope.date_type === 'month') {
        name = moment($scope.begin).format('MM/YYYY');
      } else if ($scope.date_type === 'year') {
        name = moment($scope.begin).format('YYYY');
      }
      if ($scope.query_range) {
        if ($scope.date_type === 'day') {
          name += " - " + moment.utc($scope.end).format('DD/MM/YYYY');
        } else if ($scope.date_type === 'month') {
          name += " - " + moment($scope.end).format('MM/YYYY');
        } else if ($scope.date_type === 'year') {
          name += " - " + moment($scope.end).format('YYYY');
        }
      }
      for (var i = 0; i < consumptions_data.length; i++) {
        var date = moment(consumptions_data[i]["date"]).subtract(1, 'hour');
        var date_utc = moment(date._d).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        itemArray.push(moment(date_utc).valueOf());
        itemArray.push(Number((consumptions_data[i]["value"]).toFixed(1)));

        //console.log(itemArray);
        dataArray.splice(j, 0, itemArray);
        itemArray = [];
        j++;
      }
      $scope.mydata.push({ "color": color, "name": name, "data": dataArray });
      //console.log($scope.mydata);
    }

    /*
     * Choose month or year
     */
    $scope.chooseDate = function (date, date_type, is_begin) {
      var is_valid = true;
      // check if date is valid
      if (date_type === 'month' && (parseInt($scope.year_selected_begin) === parseInt($scope.year_selected_end))) {
        if ((is_begin && (('0' + date).slice(-2) + '/' + $scope.year_selected_begin > moment().format('MM/YYYY') || $scope.query_range && date >= $scope.month_selected_end) || (!is_begin && ($scope.query_range && date <= $scope.month_selected_begin || date > moment().format('MM'))))) {
          is_valid = false;
        }
      } else if (date_type === 'year') {
        if (is_begin && (('0' + $scope.month_selected_begin).slice(-2) + '/' + date > moment().format('MM/YYYY') || $scope.query_range && (date > parseInt($scope.year_selected_end) || (date === parseInt($scope.year_selected_end) && $scope.month_selected_end <= $scope.month_selected_begin)))) {
          is_valid = false;
        }
        if (!is_begin && (('0' + $scope.month_selected_end).slice(-2) + '/' + date > moment().format('MM/YYYY') || $scope.query_range && (date < parseInt($scope.year_selected_begin) || (date === parseInt($scope.year_selected_begin) && $scope.month_selected_end <= $scope.month_selected_begin)))) {
          is_valid = false;
        }
      }

      if (is_valid) {
        if (date_type === 'month') {
          if (is_begin) {
            $scope.month_selected_begin = date;
            $scope.selected_month_begin = date;
          } else {
            $scope.month_selected_end = date;
            $scope.selected_month_end = date;
          }
        } else {
          if (is_begin) {
            $scope.year_selected_begin = date;
            $scope.selected_year_begin = date;
          } else {
            $scope.year_selected_end = date;
            $scope.selected_year_end = date;
          }
        }

        // Set the month to parse the time in moment object 
        var month_begin = $scope.month_selected_begin - 1;
        var month_end = $scope.month_selected_end - 1;

        // Set date of begin and end for the graphic 
        if (!$scope.show_filters_chart || $scope.query_range) {
          var begin_date = moment([$scope.year_selected_begin, month_begin, 1]);
          var end_date = moment([$scope.year_selected_end, month_end, 1]); // one way to get 21/05/2017
        } else {
          begin_date = moment([$scope.year_selected_begin, month_begin, 1]);
          end_date = moment([$scope.year_selected_begin, month_begin, 1]);
        }

        // If query is by range then order dates and set the begin and end of the day, month or year
        $scope.begin = begin_date.clone().startOf($scope.date_type);
        $scope.end = end_date.clone().endOf($scope.date_type);
        $scope.date_begin = $scope.begin;
        $scope.date_end = $scope.end;

        setDateFormatApi();
        // Call to render graphic and close popup
        $scope.setChartType();
        closeToggleDate();
      }
    };

    /*
     * Close all popup  
     */
    function closeToggleDate() {
      $scope.show_month_start_select = false;
      $scope.show_month_end_select = false;
      $scope.show_year_start_select = false;
      $scope.show_year_end_select = false;
    };

    /*
     * Open or close the options of months
     */
    $scope.toggleMonth = function (is_begin) {
      $scope.show_year_start_select = false;
      $scope.show_year_end_select = false;
      if (is_begin) {
        $scope.show_month_start_select = !$scope.show_month_start_select
        $scope.show_month_end_select = false;
      } else {
        $scope.show_month_end_select = !$scope.show_month_end_select;
      }
    };

    /*
     * Open or close the options of years
     */
    $scope.toggleYear = function (is_begin) {
      $scope.show_month_start_select = false;
      $scope.show_month_end_select = false;
      if (is_begin) {
        $scope.show_year_start_select = !$scope.show_year_start_select;
      } else {
        $scope.show_year_end_select = !$scope.show_year_end_select;
      }
    };

    /*
     * Close the modal Graphic
     */
    $scope.closeConsumptionChart = function () {
      $scope.show_consumption_chart = false;
      $rootScope.consumption_target = null;
      $scope.mydata = null;
      initializeChartModal();

      if ($scope.name_listener1) $scope.name_listener1();
    };

    /*
     * Close modal on destroy
     */
    $scope.$on('$destroy', function () {
      $scope.listener1();
      $scope.closeConsumptionChart();
    });
  }]);
