Airzone.controller('purifierWarningModalController', ['$scope', '$rootScope', 'purifierService',
  function ($scope, $rootScope, purifierService) {

    /*
     * Translate strint 't'
     * Solved bug (can't call $rootScope directly)
     */
    $scope.i18n = function (t) {
      return $rootScope.i18n(t);
    };

    /*
     * Update notifies and close when click
     */
    $scope.close = function () {
      $rootScope.array_purifier_warning.forEach(function(purifier){
        purifierService.update(purifier).then(function(data){
          console.log("Updated: ", data); 
        }).catch(function(error){
          console.log("Error: ", error);
        });
      });
      $rootScope.purifier_warning = false;
    }

  }]);