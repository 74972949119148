Airzone.directive('lazyLoad', ['$window', '$q', '$rootScope', 'localStorageService', 'GOOGLE_MAPS_KEY', function ($window, $q, $rootScope, localStorageService, GOOGLE_MAPS_KEY) {
  /*
   * Load scpript in the DOM
   */
  function load_script() {
    var s = document.createElement('script'); // use global document since Angular's $document is weak
    localStorageService.set('googleApiLang', $rootScope.language);
    s.src = 'https://maps.googleapis.com/maps/api/js?key=' + GOOGLE_MAPS_KEY + '&v=3.exp&libraries=places&language=' + $rootScope.language + '&callback=initialize';
    document.body.appendChild(s);
  };

  /*
   * Defer the return until the promise request is resolved
   */
  function lazyLoadApi() {
    var deferred = $q.defer();
    // The promise request is resolved with the api callback
    $window.initialize = function () {
      deferred.resolve();
    };
    load_script();
    return deferred.promise;
  };

  /*
   * Set places parameters which are returned by the api to scope
   */
  function setSpotParams(scope) {
    var input = document.getElementById('searchTextField');
    var autocomplete = new google.maps.places.Autocomplete(input, { types: ['(cities)'] });
    autocomplete.setFields(['address_components', 'geometry', 'name', 'place_id' ]);

    // Update scope spot info when user select a place
    autocomplete.addListener('place_changed', function () {
      var place = autocomplete.getPlace();
      var location = place.geometry.location;

      var top_spots = place.address_components.filter(function (x) {
        return (x.types.indexOf('administrative_area_level_2') > -1 ||  // locality
          x.types.indexOf('administrative_area_level_1') > -1 ||  // community
          x.types.indexOf('country') > -1);                      // country
      });

      var country_code = top_spots[top_spots.length - 1].short_name;
      var complete_name = place.name + ",";
      complete_name += top_spots.map(function (x) { return x.long_name; }).join();

      scope.server.spot_location = [location.lat(), + location.lng()];
      scope.server.spot_complete_name = complete_name;
      scope.server.spot_google_reference = place.place_id;
      scope.server.spot_country_code = country_code;
    });
  };

  return {
    restrict: 'E',
    scope: {
      server: '=server',
      messageServer: '=messageServer'
    },
    link: function (scope, element, attrs) {
      // When the promises are resolved
      if ($window.google && $window.google.maps && $rootScope.language == localStorageService.get('googleApiLang')) {
        setSpotParams(scope);
      } else {
        if ($window.google && $window.google.maps)
          delete $window.google.maps; // Fixes mistake calling same objects google multiple times
        lazyLoadApi().then(function () {
          if ($window.google && $window.google.maps) {
            setSpotParams(scope);
          } else {
            console.log('google maps not loaded');
            scope.messageServer = 'errorServer';
          }
        }, function () {
          console.log('google maps by promise rejected');
          scope.messageServer = 'errorServer';
        });
      }
    }
  };
}]);
