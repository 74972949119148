Airzone.service('zoneService',
  ['$http', '$q', '$rootScope', '$state', 'sessionService',
    function ($http, $q, $rootScope, $state, sessionService) {
      return ({
        getZones: getZonesFromServer,
        getZone: getZoneById,
        updateZone: putZone,
        replaceZone: replaceZone,
        replaceConfig: replaceConfig,
        deleteDeviceZones: deleteDeviceZones,
        destroy: destroy
      });

      //
      // Public methods
      //

      /*
       * Get zones from server or rootScope
       *
       * @param boolean forceUpdate - Force ajax request and not return $rootScope result
       */
      function getZonesFromServer(system_id, forceUpdate) {
        forceUpdate = typeof forceUpdate !== 'undefined' ? forceUpdate : false;

        // search for system of device in rootScope
        if ($rootScope.zones && !forceUpdate) {
          var zones = $rootScope.zones.filter(function (x) { return x.system_id === system_id; });

          if (zones.length > 0) {
            return $q.when(zones);
          }
        }

        var request = $http({
          method: "get",
          url: url_path + "zones/",
          params: {
            user_email: sessionService.email(),
            user_token: sessionService.token(),
            system_id: system_id,
            format: 'json'
          }
        });

        return (request.then(handleIndexSuccess, handleError));
      };

      /*
       * Get a zone given an id
       *
       * @params string id - Id of the zone
       */
      function getZoneById(id) {

        function filterZone(id) {
          //y devolvemos la zona
          var zones = $rootScope.zones.filter(function (x) { return x.id == id });

          if (zones.length > 0) {
            return $q.when(zones[0]);
          } else {
            return $q.when(null);
          }
        }


        if ($rootScope.zones) {
          return filterZone(id);
        } else {

          var request = $http({
            method: "get",
            url: url_path + "zones/" + id,
            responseType: 'json',
            params: {
              user_email: sessionService.email(),
              user_token: sessionService.token(),
            },
            data: {
              id: id
            }
          });

          return (request.then(function (response) {
            var zone = response.data['zone'];

            //como no hay zonas, las pido
            return getZonesFromServer(zone.system_id).then(function(){
              //y devuelvo su zona
              return filterZone(id);
            })

          }, handleError));

        }


      };

      function getDatabaseZone(id) {
        if ($rootScope.zones) {
          var zones = $rootScope.zones.filter(function (x) { return x.id == id });

          if (zones.length > 0) {
            return zones[0];
          }
        }

        return false;
      };

      /*
       * Do a PUT http to zone
       */
      function putZone(id, field, value) {
        var data = {}
        data['zone'] = {}
        data['zone'][field] = value

        var request = $http({
          method: "PUT",
          url: url_path + "zones/" + id,
          responseType: 'json',
          params: {
            user_email: sessionService.email(),
            user_token: sessionService.token(),
          },
          data: data
        });

        return (request.then(handlePutSuccess, handleError));
      };

      /*
       * Update zone with new zone fields values
       */
      function replaceZone(zone, replace) {
        var db_zone = getDatabaseZone(zone.id);
        //console.log(zone);
        if (db_zone) {
          if (!db_zone.reject) {
            if (replace) {
              Object.keys(zone).forEach(function (key) {
                if (key != 'config')
                  db_zone[key] = zone[key];
              });

              db_zone.updated = true;
              db_zone.last_field = false;
              db_zone.replace_retry = 0;
              $rootScope.$apply();
            } else {
              return angular.copy(zone);
            }
          }
          else {
            db_zone.reject = false;
            $rootScope.$apply();
            if (!replace) return null;
          }
        } else {
          return null;
        }
      };

      /*
       * Update zone with new zone fields values
       */
      function replaceConfig(config) {
        if (!$rootScope.zone_config && $rootScope.last_zone_number == config.zone_number) {
          $rootScope.zone_config = config;
          $rootScope.$apply();
        }
      };

      /*
       * Delete all zones of device
       */
      function deleteDeviceZones(device_id) {
        if ($rootScope.zones) {
          var zones = $rootScope.zones.filter(function (zone) {
            return zone.device_id == device_id;
          });

          zones.forEach(function (zone) {
            var index = $rootScope.zones.indexOf(zone);
            $rootScope.zones.splice(index, 1);
          });
        }
      };

     
      function handleIndexSuccess(response) {
        if (!$rootScope.zones) {
          $rootScope.zones = [];
        }

        $rootScope.zones.push.apply($rootScope.zones, response.data['zones']);
        return (response.data['zones']);
      };

      function handlePutSuccess(response) {
        return (response.data);
      };

      function handleError(response) {
        // Logout when unauthorized
        if (response.status == 401) {
          sessionService.unauthorize();
        } else if (response.status == 403) {
          $state.go('init.403');
          return ($q.reject("An unknown error occurred."));
        } else if (response.status == 404) {
          $state.go('init.404');
          return ($q.reject("An unknown error occurred."));
        }

        if (!angular.isObject(response.data) || !response.data.message) {
          return ($q.reject("An unknown error occurred."));
        }

        return ($q.reject(response.data.message));
      };

      function destroy() {
        $rootScope.zones = null;
      };
    }]);
