Airzone.service( 'systemService', [ '$http', '$q', '$rootScope', 'sessionService', '$state', 'STATS_URL',
  function( $http, $q, $rootScope, sessionService, $state, STATS_URL ) {
    return({
      getSystems: getSystems,
      getSystemsWithZones: getSystemsWithZones,
      getSystem: getSystem,
      getDatabaseSystem: getDatabaseSystem,
      replaceSystem: replaceSystem,
      replaceConfig: replaceConfig,
      deleteDeviceSystems: deleteDeviceSystems,
      getSystemConsumptions: getSystemConsumptions,
      getRangeSystemConsumptions: getRangeSystemConsumptions,
      getCompareSystemConsumptions: getCompareSystemConsumptions,
      destroy: destroy
    });

    //
    // Public methods
    //

    /*
     * Get systems from server or rootScope
     *
     * @param boolean forceUpdate - Force ajax request and not return $rootScope result
     */
    function getSystems( device_id, forceUpdate ) {
      forceUpdate = typeof forceUpdate !== 'undefined' ? forceUpdate : false;

      // search for system of device in rootScope
      if( $rootScope && $rootScope.systems && !forceUpdate){
        var systems = $rootScope.systems.filter( function(x) {return x.device_id == device_id;} );

        if( systems.length > 0 ) {
          return $q.when(systems);
        }
      }

      var request = $http({
        method: "get",
        url: url_path + "systems/",
        params: {
          user_email: sessionService.email(),
          user_token: sessionService.token(),
          device_id: device_id,
          format: 'json'
        }
      });

      return( request.then( handleIndexSuccess, handleError ) );
    };

    /*
     * Get systems from server or rootScope
     *
     * @param boolean forceUpdate - Force ajax request and not return $rootScope result
     */
    function getSystemsWithZones( device_id ) {
      var request = $http({
        method: "get",
        url: url_path + "systems/",
        params: {
          user_email: sessionService.email(),
          user_token: sessionService.token(),
          device_id: device_id,
          template: 'with_zones',
          format: 'json'
        }
      });

      return( request.then( function(response){
        return response.data['systems'];
      }, handleError ) );
    };

    /*
     * Get a system given an id
     *
     * @params string id - Id of the system
     */
    function getSystem( id ) {
      if( $rootScope.systems ) {
        var systems = $rootScope.systems.filter(function(x) {return x.id == id});

        if( systems.length > 0 ) {
          return $q.when(systems[0]);
        }
      }
      var request = $http({
        method: "get",
        url: url_path + "systems/" + id,
        responseType: 'json',
        params: {
          user_email: sessionService.email(),
          user_token: sessionService.token(),
        },
        data: {
          id: id
        }
      });

      return( request.then( handleGetSuccess, handleError ) );
    };

    function getDatabaseSystem( id ) {
      if( $rootScope.systems ) {
        var systems = $rootScope.systems.filter(function(x) {return x.id == id});

        if( systems.length > 0 ) {
          return systems[0];
        }
      }

      return false;
    };

    /*
     * Update system with new system fields values
     */
    function replaceSystem(system) {
      var db_system = getDatabaseSystem( system.id );

      if( db_system ) {
        if( !db_system.reject ) {
          Object.keys(system).forEach(function (key) {
            db_system[key] = system[key];
          });

          db_system.updated = true;
          db_system.last_field = false;
          db_system.replace_retry = 0;
          $rootScope.$apply();
        }
        else {
          db_system.reject = false;
          $rootScope.$apply();
        }
      };
    };

    /*
     * Update zone with new zone fields values
     */
    function replaceConfig(config) {
      if( !$rootScope.system_config ) {
        $rootScope.system_config = config;
        $rootScope.$apply();
      }
    };

    /*
     * Delete all systems of device
     */
    function deleteDeviceSystems(device_id) {
      if( $rootScope.systems ) {
        var systems = $rootScope.systems.filter(function(system){
          return system.device_id == device_id;
        });

        systems.forEach(function(system) {
          var index = $rootScope.systems.indexOf(system);
          $rootScope.systems.splice(index, 1);
        });
      }
    };

    function handleIndexSuccess( response ) {
      $rootScope.systems = [];

      $rootScope.systems.push.apply( $rootScope.systems, response.data['systems'] );
      return( response.data['systems'] );
    };

    function handleGetSuccess( response ) {
      var system = response.data['system'];

      if( !$rootScope.systems ) {
        $rootScope.systems = [system];
      }
      else if( $rootScope.systems.indexOf(system) == -1 && system.system_number !== "0") {
        $rootScope.systems.push(system);
      }

      return system;
    };

    function handleSuccess( response ) {
      return( response );
    };

    function handleError( response ) {
      // Logout when unauthorized
      if( response.status == 401 ){
        sessionService.unauthorize();
      } else if( response.status == 403 ){
        $state.go('init.403');
        return( $q.reject( "An unknown error occurred." ) );
      } else if( response.status == 404 ){
        $state.go('init.404');
        return( $q.reject( "An unknown error occurred." ) );
      }

      if ( ! angular.isObject( response.data ) || ! response.data.message){
        return( $q.reject( "An unknown error occurred." ) );
      }

      return( $q.reject( response.data.message ) );
    };

    /**
     * Get Range System consumptions
     */
    function getRangeSystemConsumptions( device_id, system_number, date_begin, date_end, date_type ) {
      var precision = getPrecision(date_type);
      var tz = moment.tz.guess(true);
      var request = $http({
        method: "get",
        url: STATS_URL + "power?device_id=" + device_id + "&system_number=" + system_number + "&startDate=" + date_begin + "&finishDate=" + date_end + "&precision=" + precision + "&tz=" + tz,
        params: {
          user_email: sessionService.email(),
          user_token: sessionService.token(),
          format: 'json'
        }
      });

      return( request.then( handleSuccess, handleError ) );
    }

    /**
     * Get System consumptions
     */
    function getCompareSystemConsumptions( device_id, system_number, date, date_type ) {
      var precision = getPrecision(date_type);
      var tz = moment.tz.guess(true);
      var request = $http({
        method: "get",
        url: STATS_URL + "power?device_id=" + device_id + "&system_number=" + system_number + "&date=" + date + "&precision=" + precision + "&tz=" + tz,
        params: {
          user_email: sessionService.email(),
          user_token: sessionService.token(),
          format: 'json'
        }
      });

      return( request.then( handleSuccess, handleError ) );
    }

    /**
     * Get System consumptions
     */
    function getSystemConsumptions( device_id, system_number, date_begin, date_end, date_type ) {
      var precision = getPrecision(date_type);
      var tz = moment.tz.guess(true);
      var request = $http({
        method: "get",
        url: STATS_URL + "power?device_id=" + device_id + "&system_number=" + system_number + "&startDate=" + date_begin + "&finishDate=" + date_end + "&precision=" + precision + "&tz=" + tz,
        params: {
          user_email: sessionService.email(),
          user_token: sessionService.token(),
          format: 'json'
        }
      });

      return( request.then( handleSuccess, handleError ) );
    }

    /**
     * Get precision from type of query
     */
    function getPrecision(type){
      switch(type){
        case 'day':
          return 'hour';
        case 'month':
          return 'day';
        case 'year':
          return 'month';
      }
    }

    /**
     * Set systems to null for initialize it
     */
    function destroy() {
      $rootScope.systems = null;
    }
}]);
